import { useEffect, useState } from "react";
import { CiUser } from "react-icons/ci";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchClubs } from "../../features/auth/AuthSlice";
interface customSelectProps {
  name: string;
  setSelected: any;
  selected: any;
}

const CustomSelect = ({ name, setSelected, selected }: customSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth);
  useEffect(() => {
    dispatch(fetchClubs());
  }, []);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const selectOption = (option: any) => {
    setSelected(option);
    setIsOpen(false);
  };
  let allClubs = user?.allClubs;

  return (
    <div className="flex flex-col w-full relative">
      <div
        className="bg-neutral-200 flex px-4 text-black justify-between py-3 rounded-md"
        onClick={toggleOpen}
      >
        <CiUser className="text-[#AFAFAF]" />
        <p className="capitalize">{selected ? selected.name : name}</p>
        <div className="flex items-center justify-center">
          {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </div>
      </div>
      {isOpen && (
        <div className="bg-[#e5e5e5] text-[#333] rounded-md shadow-md regular text-md w-full top-12 absolute left-0 z-50">
          <div className="flex flex-col p-4">
            {allClubs?.map((option: any, index: number) => (
              <div
                key={index}
                className="flex items-center justify-between px-4 py-2 text-md regular "
                onClick={() => selectOption(option)}
              >
                <p className="text-sm regular text-[#333] capitalize">
                  {option.name}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
