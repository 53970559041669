interface textSize {
  text: string;
  className?: string;
}

export const PageTitle = ({ text, className }: textSize) => {
  return (
    <h1
      className={`text-[24px] leading-[32px] font-semibold  text-black  ${className} `}
    >
      {text}
    </h1>
  );
};
export const SectionTitle = ({ text, className }: textSize) => {
  return (
    <h1
      className={`text-lg leading-[26px] font-[600]  text-secondary-100  ${className} `}
    >
      {text}
    </h1>
  );
};
