import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import sentMark from "../../../assets/svgs/sent-mark.svg";

import DashboardLayout from "../../../Layouts/DashboardLayout";
import {
  addMatch,
  getAllAcceptedPlayer,
  getMatchInTournament,
  resetComptition,
} from "../../../features/auth/TournamentSlice";
import { AllPlayersRawData } from "../../../utils/sampData";
import { Btn, OutlinedBtn } from "../../Button";
import ReUseModal from "../../Modal/ReUseModal";
import PrimarySelect from "../../Selects/PrimarySelect";
import SearchSelect from "../../Selects/SearchSelect";
import Toggler from "../../Togglers";
import Back from "../../Button/back-btn";
import SingleMatch from "./SingleMatch";
import { AdminDateTimeInput } from "../../Input/DateInput";
interface singleItemProps {
  challenger: string;
  challenged: string;
  court: string;
  time: string;
  id?: number;
  isDouble: boolean;
  challengerB: string | null;
  challengedB: string | null;
}

const AddNewMatch = () => {
  const [filterArray, setFilterArray] = useState(AllPlayersRawData);
  const [selectedPlayer] = useState<any>();
  useEffect(() => {
    let newPlayerList = filterArray.filter(
      (p) => p?._id !== selectedPlayer?._id
    );
    setFilterArray(newPlayerList);
  }, [selectedPlayer]);

  return (
    <>
      <DashboardLayout current={3}>
        <div className="mt-[39px]">
          <Back className="pl-[30px]" />
        </div>
        <div className="flex flex-col">
          <Schedule />
        </div>
      </DashboardLayout>
    </>
  );
};

export default AddNewMatch;

const Schedule = () => {
  const navigate = useNavigate();
  const tournament = useAppSelector((state) => state.tournament);
  const dispatch = useAppDispatch();
  const [, setOpenRules] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [matchNo, setMatchNo] = useState<any>(0);
  const [arrays, setArrays] = useState<any>([0]);
  const [allMatching, setAllMatching] = useState<singleItemProps[]>([]);
  const [active, setActive] = useState(false);
  const id = useParams();

  useEffect(() => {
    dispatch(getAllAcceptedPlayer({ id: id.id }));
    dispatch(getMatchInTournament({ id: id.id }));
  }, []);

  const playersArray = tournament.allAcceptedPlayers;

  const startMatch = () => {
    const dataPassed = {
      id: id.id,
      data: {
        match: allMatching,
      },
    };

    dispatch(addMatch(dataPassed));
  };

  useEffect(() => {
    if (tournament.addMatchSuccess) {
      setOpenModal(true);

      setTimeout(() => {
        dispatch(resetComptition());
        navigate("/admin/tournament");
      }, 1000);
    } else {
      setOpenModal(false);
    }
  }, [tournament.addMatchSuccess]);

  const handleDelete = (id: number) => {
    const existingMatches = allMatching?.map((elem: any) => {
      return Object.assign({}, elem);
    });
    const findIndex = existingMatches.findIndex(
      (match: any) => match?.id === id
    );
    findIndex !== -1 && existingMatches.splice(findIndex, 1);

    setAllMatching(existingMatches);

    // const filteredResult = allMatching?.filter((match: singleItemProps) => {

    //   return match?.id !== id;
    // });
    // console.log(filteredResult);
    // console.log(allMatching);
    // setAllMatching(filteredResult);
  };
  const matches = tournament?.matchInTournament?.match;
  useEffect(() => {
    if (
      allMatching?.length &&
      allMatching[0]?.challenged &&
      allMatching[0].challenger &&
      allMatching[0].court &&
      allMatching[0]?.time
    ) {
      setActive(true);
    } else setActive(false);
  }, [allMatching]);

  const addNew = () => {
    const newId = allMatching.length;

    const data = {
      challenger: "",
      challenged: "",
      court: "",
      time: "",
      id: newId,
      isDouble: false,
      challengerB: null,
      challengedB: null,
    };
    setAllMatching([...allMatching, data]);
  };
  const handleSave = (id: number, newData: singleItemProps) => {
    setAllMatching((prevMatches) =>
      prevMatches.map((item) => (item.id === id ? newData : item))
    );
  };

  return (
    <div className="bg-white rounded-[20px] flex flex-col h-full mt-6 flow-hide ml-[25px]   ">
      <div className=" flex justify-between pr-8 h-auto items-center">
        <div className="flex items-center pb-3 pt-[22px]">
          <h1 className="pl-5 text-customBlack font-semibold text-sm   ">
            Add Players to the competition
          </h1>
        </div>
      </div>
      {/* breaker line */}
      <div className="w-full h-[1px] bg-[#cfcfcf] " />
      <div className="w-full flex flex-col pl-5">
        <p className="mt-3 text-neutral-600 ">
          Kindly input the name of the players, time and court of the match
        </p>
        {matches?.length !== 0 && (
          <div className="w-full flex flex-col">
            {matches?.map((item: any, index: number) => {
              return <SingleMatch item={item} key={index} />;
            })}
          </div>
        )}
        {/* form area */}
        <div className="w-full flex flex-col">
          {allMatching?.map((item: singleItemProps, index: number) => {
            return (
              <SingleItem
                {...item}
                playersList={playersArray}
                key={index}
                onSave={handleSave}
                setAllMatching={setAllMatching}
                onDelete={handleDelete}
                data={item}
              />
            );
          })}
        </div>
        {/* end of form area */}
        <div className="flex justify-start mt-3">
          {/* <OutlinedBtn /> */}
          <button
            className="outline-none border-none text-[#82B020] cursor-pointer font-semibold"
            onClick={addNew}
          >
            Add new match
          </button>
        </div>

        <div className="w-4/12 mt-12 mx-auto mb-12 ">
          <Btn
            active={active}
            name="Create Match"
            onClick={startMatch}
            loading={tournament.loading}
          />
        </div>

        <ReUseModal open={openModal} setOpen={setOpenModal}>
          <img src={sentMark} alt="sent" className="mx-auto mt-6" />
          <h1 className="text-cutomBlack text-[24px] font-bold text-center ">
            Match added successfully
          </h1>
          <p className="text-center w-10/12 mx-auto text-neutral-500 ">
            The matches has been added successfully and the players will notifed
          </p>
          <Btn
            active={true}
            name="Continue"
            onClick={() => {
              setOpenRules(false);
              setOpenModal(false);
              navigate(`/admin/tournament`);
            }}
            className="mt-[25px] mb-3"
          />
        </ReUseModal>
      </div>
    </div>
  );
};

const SingleItem = ({ onSave, onDelete, data, playersList }: any) => {
  const tournament = useAppSelector((state) => state.tournament);

  const [date, setDate] = useState<string>("");
  const [fields, setFields] = useState<singleItemProps>({
    challenger: "",
    challenged: "",
    court: "",
    time: date,
    isDouble: false,
    challengerB: null,
    challengedB: null,
  });
  const [challengedObj, setChallengedObj] = useState(null);
  const [challengedBObj, setChallengedBObj] = useState(null);
  const [challengerObj, setChallengerObj] = useState(null);
  const [challengerBObj, setChallengerBObj] = useState(null);
  const [selectedCourt, setSelectedCourt] = useState(null);
  let allCourt = tournament?.allCourts;

  useEffect(() => {
    onSave(data.id, { ...fields, id: data.id });
  }, [fields]);
  console.log(fields);

  return (
    <div className="flex flex-col pb-6  border-dashed pt-6 border-border border-b-[2px]">
      {/* upper sessiom */}
      <div className="flex space-x-6 items-center">
        {/* side a */}
        <div className="flex items-center space-x-3">
          <p className="text-neutral-600">
            {" "}
            Player <span className="font-bold text-customBlack">A</span>:
          </p>
          {/* <p className="outline-none h-[30px] rounded-[5px] border border-border w-[230px] px-2 ">
            {playerA ? playerA : "Ayeni Daniel"}
          </p> */}
          <SearchSelect
            options={playersList}
            setSelectedPerson={(e: any) => {
              setChallengerObj(e);
              setFields({ ...fields, challenger: e._id });
            }}
            selectedPerson={challengerObj}
          />
        </div>
        {/* sideb */}
        <p className="text-xs text-customBlack">VS</p>
        <div className="flex items-center space-x-3">
          <p className="text-neutral-600">
            {" "}
            Player <span className="font-bold text-customBlack">B</span>:
          </p>
          <SearchSelect
            options={playersList}
            setSelectedPerson={(opt: any) => {
              setChallengedObj(opt);
              setFields({ ...fields, challenged: opt._id });
            }}
            selectedPerson={challengedObj}
          />
        </div>
      </div>

      {/* show double */}
      {fields.isDouble && (
        <div className="flex space-x-6 items-center mt-3">
          {/* side a */}
          <div className="flex items-center space-x-3">
            <p className="text-neutral-600">
              {" "}
              Player <span className="font-bold text-customBlack">C</span>:
            </p>
            {/* <p className="outline-none h-[30px] rounded-[5px] border border-border w-[230px] px-2 ">
            {playerA ? playerA : "Ayeni Daniel"}
          </p> */}
            <SearchSelect
              options={playersList}
              setSelectedPerson={(e: any) => {
                setChallengerBObj(e);
                setFields({ ...fields, challengerB: e._id });
              }}
              selectedPerson={challengerBObj}
            />
          </div>
          {/* sideb */}
          <p className="text-xs text-customBlack">VS</p>
          <div className="flex items-center space-x-3">
            <p className="text-neutral-600">
              {" "}
              Player <span className="font-bold text-customBlack">D</span>:
            </p>
            <SearchSelect
              options={playersList}
              setSelectedPerson={(opt: any) => {
                setChallengedBObj(opt);
                setFields({ ...fields, challengedB: opt._id });
              }}
              selectedPerson={challengedBObj}
            />
          </div>
        </div>
      )}
      {/* END OF double */}
      {/* lower session ###################3 */}
      <div className="flex space-x-6 items-center mt-4">
        {/* start of a sec */}
        <div className="flex items-center space-x-3">
          <p className="text-neutral-600 min-w-max">Match Date:</p>

          <AdminDateTimeInput
            dateTime={fields?.time}
            onChange={(e: any) =>
              setFields({ ...fields, time: e.target.value })
            }
          />
        </div>

        {/* enf of a select */}

        {/* start of a sec */}
        <div className="flex items-center space-x-3">
          <p className="text-neutral-600">Court:</p>
          <div className="w-[230px]">
            <PrimarySelect
              selected={selectedCourt}
              setSelected={(e: any) => {
                setSelectedCourt(e);
                setFields({ ...fields, court: e._id });
              }}
              name="Select"
              data={allCourt}
              height="h-[30px] flex items-center bg-[#ffffff]"
              label=""
            />
          </div>
        </div>

        {/* enf of a select */}
      </div>
      <div className="flex  mt-8 items-cente space-x-[210px]">
        <div className="flex    items-center space-x-[50px] w-[383px]">
          <p className="text-neutral-600">Switch to Double</p>

          <Toggler
            enabled={fields.isDouble}
            setEnabled={() =>
              setFields({ ...fields, isDouble: !fields.isDouble })
            }
          />
        </div>
        {/* <p
          className="text-[#B00020] text-sm cursor-pointer font-semibold "
          onClick={() => onDelete(data?.id)}
        >
          Delete Match Details
        </p> */}
      </div>
    </div>
  );
};
