import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowUp } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { More } from "../../assets/svgs/court";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getAllLadderPlayerUser,
  getSingleLadderDetailUser,
} from "../../features/auth/ladder-slice";

import { DarkSearchIcon } from "../../assets/svgs";
import LoadingComponent from "../../components/Loading/Loader";
import PlayerListRank from "../../components/Club/PlayerListRank";
import PlayerListPoint from "../../components/Club/PlayerListPoint";

interface Person {
  _id: string;
  firstname: string;
  lastname: string;
  image: string;
  rank: number;
}

const LadderBoard = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ladder = useAppSelector((state) => state.ladder);

  // const id = useParams();

  return (
    <div className="w-full md:h-screen md:max-w-[428px] md:mx-auto flex flex-col px-4 py-6 h-full ">
      <div className="flex justify-between  mb-[30px] items-center">
        <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
        <p className="text-customBlack font-bold text-base truncate ">
          Ladder Board
        </p>
        <div className="more-shadow opacity-0">
          <More />
        </div>
      </div>

      {ladder?.singleLadderDetail?._type === "rank" && <PlayerListRank />}
      {ladder?.singleLadderDetail?._type === "point" && <PlayerListPoint />}
    </div>
  );
};

export default LadderBoard;
