import React from "react";
interface linkprops {
  name: string;
  onClick: any;
  icon: any;
  background?: string;
}
const LinkCard = ({ icon, name, onClick, background }: linkprops) => {
  return (
    <div
      className={`w-full flex flex-col gap-2 justify-center min-h-[107px]  cursor-pointer rounded-[12px] p-4 ${
        background ? background : "bg-white"
      }  `}
      onClick={onClick}
    >
      <span>{icon}</span>
      <p className="text-base text-[#13170E] font-semibold">{name}</p>
    </div>
  );
};

export default LinkCard;
