import React from "react";
import { ActiveCircle, EmptyCircle } from "../../assets/svgs";

const Radio = ({
  active,
  onClick,
}: {
  active: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <div className="" onClick={onClick}>
      {active ? (
        <span>
          {" "}
          <ActiveCircle />{" "}
        </span>
      ) : (
        <span>
          {" "}
          <EmptyCircle />{" "}
        </span>
      )}
    </div>
  );
};

export default Radio;
