export const CalenderIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66699 1.66797V4.16797"
        stroke="#AFAFAF"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.333 1.66797V4.16797"
        stroke="#AFAFAF"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.91699 7.57422H17.0837"
        stroke="#AFAFAF"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5 7.08464V14.168C17.5 16.668 16.25 18.3346 13.3333 18.3346H6.66667C3.75 18.3346 2.5 16.668 2.5 14.168V7.08464C2.5 4.58464 3.75 2.91797 6.66667 2.91797H13.3333C16.25 2.91797 17.5 4.58464 17.5 7.08464Z"
        stroke="#AFAFAF"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.0791 11.4167H13.0866"
        stroke="#AFAFAF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.0791 13.9167H13.0866"
        stroke="#AFAFAF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99607 11.4167H10.0036"
        stroke="#AFAFAF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99607 13.9167H10.0036"
        stroke="#AFAFAF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.91209 11.4167H6.91957"
        stroke="#AFAFAF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.91209 13.9167H6.91957"
        stroke="#AFAFAF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const CautionIcon = () => {
  return (
    <svg
      width="83"
      height="81"
      viewBox="0 0 83 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M77.3945 18.1094H81.9998"
        stroke="#2C3E50"
        stroke-width="1.6096"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M79.6973 15.8047V20.4099"
        stroke="#2C3E50"
        stroke-width="1.6096"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.66113 73.7148L1.18556 76.086"
        stroke="#2C3E50"
        stroke-width="0.804799"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0.738281 74.1641L3.10942 75.6396"
        stroke="#2C3E50"
        stroke-width="0.804799"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="5.49907"
        cy="3.46391"
        r="2.14613"
        stroke="#FF5F00"
        stroke-width="1.07307"
      />
      <circle cx="67.201" cy="79.116" r="1.07307" fill="#FF5F00" />
      <path
        d="M40.9997 74.1654C59.7913 74.1654 75.1663 58.7904 75.1663 39.9987C75.1663 21.207 59.7913 5.83203 40.9997 5.83203C22.208 5.83203 6.83301 21.207 6.83301 39.9987C6.83301 58.7904 22.208 74.1654 40.9997 74.1654Z"
        stroke="#B00020"
        stroke-width="5.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M41 26.332V43.4154"
        stroke="#B00020"
        stroke-width="5.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40.9805 53.668H41.0112"
        stroke="#B00020"
        stroke-width="6.83333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const AddCircle = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0013 18.3334C14.5846 18.3334 18.3346 14.5834 18.3346 10.0001C18.3346 5.41675 14.5846 1.66675 10.0013 1.66675C5.41797 1.66675 1.66797 5.41675 1.66797 10.0001C1.66797 14.5834 5.41797 18.3334 10.0013 18.3334Z"
        stroke="#9CD326"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66797 10H13.3346"
        stroke="#9CD326"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 13.3334V6.66675"
        stroke="#9CD326"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DeleteIcon = () => {
  return (
    <svg
      width="91"
      height="90"
      viewBox="0 0 91 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="45.5" cy="45" r="45" fill="#F7E6E9" />
      <path
        d="M62.5051 31.8023C59.4864 31.5023 56.4676 31.2773 53.4301 31.1086V31.0898L53.0176 28.6523C52.7364 26.9273 52.3239 24.3398 47.9364 24.3398H43.0239C38.6551 24.3398 38.2426 26.8148 37.9426 28.6336L37.5489 31.0336C35.8051 31.1461 34.0614 31.2586 32.3176 31.4273L28.4926 31.8023C27.7051 31.8773 27.1426 32.5711 27.2176 33.3398C27.2926 34.1086 27.9676 34.6711 28.7551 34.5961L32.5801 34.2211C42.4051 33.2461 52.3051 33.6211 62.2426 34.6148C62.2989 34.6148 62.3364 34.6148 62.3926 34.6148C63.1051 34.6148 63.7239 34.0711 63.7989 33.3398C63.8551 32.5711 63.2926 31.8773 62.5051 31.8023Z"
        fill="#B00020"
      />
      <path
        d="M59.0565 37.2664C58.6065 36.7977 57.9878 36.5352 57.3503 36.5352H33.6503C33.0128 36.5352 32.3753 36.7977 31.944 37.2664C31.5128 37.7352 31.269 38.3727 31.3065 39.0289L32.469 58.2664C32.6753 61.1164 32.9378 64.6789 39.4815 64.6789H51.519C58.0628 64.6789 58.3253 61.1352 58.5315 58.2664L59.694 39.0477C59.7315 38.3727 59.4878 37.7352 59.0565 37.2664ZM48.6128 55.2852H42.369C41.6003 55.2852 40.9628 54.6477 40.9628 53.8789C40.9628 53.1102 41.6003 52.4727 42.369 52.4727H48.6128C49.3815 52.4727 50.019 53.1102 50.019 53.8789C50.019 54.6477 49.3815 55.2852 48.6128 55.2852ZM50.1878 47.7852H40.8128C40.044 47.7852 39.4065 47.1477 39.4065 46.3789C39.4065 45.6102 40.044 44.9727 40.8128 44.9727H50.1878C50.9565 44.9727 51.594 45.6102 51.594 46.3789C51.594 47.1477 50.9565 47.7852 50.1878 47.7852Z"
        fill="#B00020"
      />
    </svg>
  );
};

export const EmptyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="83"
      height="83"
      viewBox="0 0 83 83"
      fill="none"
    >
      <path
        d="M23.2739 68.1305C26.1098 65.0871 30.4327 65.3292 32.9227 68.6492L36.4156 73.318C39.2168 77.0184 43.7473 77.0184 46.5485 73.318L50.0414 68.6492C52.5314 65.3292 56.8543 65.0871 59.6902 68.1305C65.846 74.7013 70.8606 72.5226 70.8606 63.3234V24.348C70.8952 10.4109 67.6443 6.91797 54.5718 6.91797H28.4268C15.3543 6.91797 12.1035 10.4109 12.1035 24.348V63.2888C12.1035 72.5226 17.1527 74.6667 23.2739 68.1305Z"
        stroke="#878787"
        strokeWidth="5.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.666 24.207H55.3327"
        stroke="#878787"
        strokeWidth="5.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.125 38.043H51.875"
        stroke="#878787"
        strokeWidth="5.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
