import { useState, useEffect } from "react";
import { StopWatch } from "../../../assets/svgs/Icons";
import ReUseModal from "../../Modal/ReUseModal";
import { OutlinedBtn, Btn } from "../../Button";
import sentMark from "../../../assets/svgs/sent-mark.svg";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  deleteMatch,
  getAllAcceptedPlayer,
  getMatchInTournament,
  resetDeleteMatch,
} from "../../../features/auth/TournamentSlice";
import { useParams } from "react-router-dom";
const SingleMatch = ({ item }: any) => {
  const dispatch = useAppDispatch();
  const tournament = useAppSelector((state) => state.tournament);

  const [open, setOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { challenged, challenger, court, time, _id } = item;
  function formatTime(timestamp: string) {
    const options: any = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    };

    const date = new Date(timestamp);
    const formattedTime = date.toLocaleString("en-US", options);
    return formattedTime;
  }
  const matchTime = formatTime(time);
  const courtName = court?.name;

  const handleDelete = () => {
    dispatch(deleteMatch({ id: _id }));
  };
  const id = useParams();

  useEffect(() => {
    if (tournament?.deleteMatchSuccess) {
      setIsSuccess(true);
      dispatch(resetDeleteMatch());
      dispatch(getAllAcceptedPlayer({ id: id.id }));
      dispatch(getMatchInTournament({ id: id.id }));
    }
  }, [tournament?.deleteMatchSuccess]);

  return (
    <div className="flex items-center 2xl:space-x-[203px] xl:space-x-[150px] lg:space-x-[32px] pb-6  border-dashed pt-6 border-border border-b-[1px]">
      {/* match info */}
      <div className="flex items-center space-x-5 w-full max-w-[790px]">
        <div className="w-5/12 flex flex-col space-y-[13px]">
          {/* single item */}
          <div className="flex justify-between items-center">
            <div className="">
              <p className="text-sm text-neutral-700">Player 1:</p>
            </div>
            <div className="flex min-w-[230px] rounded-[5px] border  border-[#afafaf] h-[30px] items-center pl-3">
              {`${challenger?.firstname} ${
                challenger?.lastname !== null || undefined
                  ? challenger?.lastname
                  : ""
              }`}
            </div>
          </div>

          {/* end of single item */}
          {/* single item */}
          {item?.challengerB && (
            <div className="flex space-x-[35px] items-center">
              <div className="min-w-[82px]">
                <p className="text-sm text-neutral-700">Player 3:</p>
              </div>
              <div className="flex min-w-[230px] rounded-[5px] border  border-[#afafaf] h-[30px] items-center pl-3">
                {`${item?.challengerB?.firstname} ${item?.challengerB?.lastname}`}
              </div>
            </div>
          )}
          {/* end of single item */}
          {/* single item */}
          <div className="flex justify-between w-full items-center">
            <div className="">
              <p className="text-sm text-neutral-700  ">Match Time:</p>
            </div>
            <div className="flex min-w-[230px] rounded-[5px] border justify-between pr-3 border-[#afafaf] h-[30px] items-center pl-3  ">
              <span>{`${matchTime} `}</span>
              <span>
                <StopWatch />
              </span>
            </div>
          </div>

          {/* end of single item */}
        </div>
        <div className="w-fit items-center flex ">
          <span className="text-[12px] text-[#0e0e0e]">VS</span>
        </div>
        {/* RIGHT HAND SIDE */}
        <div className="w-5/12 flex flex-col space-y-[13px]">
          {/* single item */}
          <div className="flex justify-between items-center">
            <div className="">
              <p className="text-sm text-neutral-700">Player 2:</p>
            </div>
            <div className="flex min-w-[230px] rounded-[5px] border  border-[#afafaf] h-[30px] items-center pl-3">
              {`${challenged?.firstname} ${
                challenged?.lastname !== null || undefined
                  ? challenged?.lastname
                  : ""
              }`}
            </div>
          </div>

          {/* end of single item */}
          {/* single item */}
          {item?.challengedB && (
            <div className="flex space-x-[35px] items-center">
              <div className="min-w-[82px]">
                <p className="text-sm text-neutral-700">Player 4:</p>
              </div>
              <div className="flex min-w-[230px] rounded-[5px] border  border-[#afafaf] h-[30px] items-center pl-3">
                {`${item?.challengedB?.firstname} ${item?.challengedB?.lastname}`}
              </div>
            </div>
          )}
          {/* end of single item */}
          {/* single item */}
          <div className="flex w-full justify-between items-center">
            <div className="">
              <p className="text-sm text-neutral-700">Court:</p>
            </div>
            <div className="flex min-w-[230px] rounded-[5px] border justify-between pr-3 border-[#afafaf] h-[30px] items-center pl-3  ">
              <span>{courtName}</span>
            </div>
          </div>

          {/* end of single item */}
        </div>

        {/* END OF RIGHT HAND SIDE */}
      </div>
      {/* end  of match info */}
      <div className="flex items-center">
        <button
          className="border-none outline-none text-[#B00020] font-semibold text-sm cursor-pointer"
          onClick={() => setOpen(true)}
        >
          Delete Match Detail
        </button>
      </div>
      <ReUseModal open={open} setOpen={setOpen}>
        {isSuccess ? (
          <div className="w-full flex flex-col">
            <img src={sentMark} alt="sent" className="mx-auto mt-6" />
            <h1 className="text-cutomBlack text-[22px] font-bold text-center mt-[14px]">
              Match Deleted Successfully
            </h1>
            <p className="text-center w-10/12 mx-auto text-neutral-500 text-sm ">
              You’ve successfully deleted the match
            </p>
            <Btn
              active
              name="Continue"
              onClick={() => {
                setIsSuccess(false);
                setOpen(false);
              }}
              className="mt-[73px] mb-3"
            />
          </div>
        ) : (
          <div className="w-full flex flex-col items-center">
            <h1 className="mt-12 text-[22px] leading-[30px] font-bold text-customBlack text-center max-w-[250px]">
              Are you sure you want to delete this match?
            </h1>
            <div className="mt-[60px] pb-8 flex items-center space-x-8 w-full">
              <span className="w-1/2">
                <OutlinedBtn
                  name="No cancel"
                  active
                  onClick={() => {
                    setIsSuccess(false);
                    setOpen(false);
                  }}
                />
              </span>
              <span className="w-1/2">
                <Btn
                  name="Yes, Go ahead"
                  loading={tournament?.loading}
                  active
                  onClick={handleDelete}
                />
              </span>
            </div>
          </div>
        )}
      </ReUseModal>
    </div>
  );
};

export default SingleMatch;
