import { useState } from "react";

interface HoverState {
  isHovered: boolean;
  isActive: boolean; // Add isActive property to the HoverState interface
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: () => void;
}

const useHover = (): HoverState => {
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    setIsActive((prevState) => !prevState);
  };

  return {
    isHovered,
    isActive,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    onClick: handleClick,
  };
};

export default useHover;
