import DashboardLayout from "../../../Layouts/DashboardLayout";

import { useNavigate } from "react-router-dom";
import { NavigationArrow } from "../../../assets/svgs/admin";
import PointPositionTable from "../../../components/Tables/AdminTables/TournamentTables/PointPositionTable";
const PointPlayerPosition = () => {
  const navigate = useNavigate();
  return (
    <DashboardLayout current={2}>
      <div className="w-full bg-[#f4f4f4] flex flex-col p-[30px] 2xl:p-8">
        <div className="flex w-full justify-between items-center">
          <div
            className="flex w-fit cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <NavigationArrow />
          </div>
        </div>
        <PointPositionTable className="mt-6" />
      </div>
    </DashboardLayout>
  );
};

export default PointPlayerPosition;
