import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSimplifiedError } from "../../utils";
import { APIService, nAPIService } from "../../utils/APIServices";
import { url } from "../../utils/endpoints";
import { error } from "console";
export interface ladderState {
  loading: boolean;
  fetchLoading: boolean;
  error: any;
  createLadderSuccess: boolean;
  allLadders: any;
  singleLadder: any;
  ladderDeleteSuccess: boolean;
  playerRemoveSuccess: boolean;
  allLadderMembers: any;
  editLadderSuccess: boolean;
  addLadderPlayerSuccess: boolean;
  allLaddersUser: any;
  allLadderPlayersUser: any;
  joinLadderSuccess: boolean;
  leaveLadderSuccess: boolean;
  singlePlayerDetail: any;
  challengePlayerSuccess: boolean;
  singleLadderDetail: any;
  ladderMatchesUser: any;
  myLadderMatches: any;
  singleLadderMatchUser: any;
  selectedLadder: any;
  allCourts: any;
  startLadderSuccess: boolean;
  allInvitations: any;
  singleInvitation: any;
  acceptLadderInviteSuccess: boolean;
  rejectLadderInviteSuccess: boolean;
  singleLadderMatches: any;
  scoreMatchSuccess: boolean;
  editPointSuccess: boolean;
  ladderAdmin: any;

  editRankSuccess: boolean;
  updateMatchTimeSuccess: boolean;
  allAuditTrail: any;
}

const initialState: ladderState = {
  loading: false,
  fetchLoading: false,
  error: "",
  createLadderSuccess: false,
  allLadders: [],
  singleLadder: {},
  ladderDeleteSuccess: false,
  playerRemoveSuccess: false,
  editLadderSuccess: false,
  allLadderMembers: [],
  addLadderPlayerSuccess: false,
  allLaddersUser: [],
  allLadderPlayersUser: [],
  joinLadderSuccess: false,
  leaveLadderSuccess: false,
  singlePlayerDetail: {},
  challengePlayerSuccess: false,
  singleLadderDetail: {},
  ladderMatchesUser: [],
  myLadderMatches: [],
  singleLadderMatchUser: {},
  selectedLadder: {},
  allCourts: [],
  startLadderSuccess: false,
  allInvitations: [],
  singleInvitation: {},
  acceptLadderInviteSuccess: false,
  rejectLadderInviteSuccess: false,
  singleLadderMatches: [],
  scoreMatchSuccess: false,
  editPointSuccess: false,
  ladderAdmin: {},
  editRankSuccess: false,
  updateMatchTimeSuccess: false,
  allAuditTrail: [],
};

export const ladderSlice = createSlice({
  name: "ladder",
  initialState,
  reducers: {
    restoreDefault: (state) => {
      state.loading = false;
    },
    restoreSingleLadder: (state) => {
      state.singleLadder = {};
    },
    resetAllLadderState: (state) => {
      state.loading = false;
      state.addLadderPlayerSuccess = false;
      state.editLadderSuccess = false;
      state.createLadderSuccess = false;
      state.playerRemoveSuccess = false;
      state.ladderDeleteSuccess = false;
      state.joinLadderSuccess = false;
      state.leaveLadderSuccess = false;
      state.challengePlayerSuccess = false;
      state.startLadderSuccess = false;
      state.acceptLadderInviteSuccess = false;
      state.rejectLadderInviteSuccess = false;
      state.scoreMatchSuccess = false;
      state.editPointSuccess = false;
      state.editRankSuccess = false;
      state.updateMatchTimeSuccess = false;
    },
    setSelectedLadder: (state, action: PayloadAction<any>) => {
      state.selectedLadder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLadder.pending, (state) => {
        state.loading = true;
      })
      .addCase(createLadder.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.createLadderSuccess = true;
      })
      .addCase(createLadder.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(addUserLadder.pending, (state) => {
        state.loading = false;
      })
      .addCase(addUserLadder.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.addLadderPlayerSuccess = true;
      })
      .addCase(addUserLadder.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(getAllLadder.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllLadder.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allLadders = payload?.data;
      })
      .addCase(getAllLadder.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(getSingleLadderDetail.pending, (state) => {
        state.loading = true;
        state.fetchLoading = true;
      })
      .addCase(getSingleLadderDetail.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.fetchLoading = false;
        state.singleLadder = payload?.data;
        state.singleLadderMatches = payload?.matches;
      })
      .addCase(getSingleLadderDetail.rejected, (state, { payload }) => {
        state.loading = false;
        state.fetchLoading = false;
      })
      .addCase(getLadderMembers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLadderMembers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allLadderMembers = payload?.data;
      })
      .addCase(getLadderMembers.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(editLadder.pending, (state) => {
        state.loading = true;
      })
      .addCase(editLadder.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.editLadderSuccess = true;
      })
      .addCase(editLadder.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(deleteLadder.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteLadder.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.ladderDeleteSuccess = true;
      })
      .addCase(deleteLadder.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(removePlayer.pending, (state) => {
        state.loading = true;
      })
      .addCase(removePlayer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.playerRemoveSuccess = true;
      })
      .addCase(removePlayer.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(joinLadder.pending, (state) => {
        state.loading = true;
      })
      .addCase(joinLadder.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.joinLadderSuccess = true;
      })
      .addCase(joinLadder.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(leaveLadder.pending, (state) => {
        state.loading = true;
      })
      .addCase(leaveLadder.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.leaveLadderSuccess = true;
      })
      .addCase(leaveLadder.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getSinglePlayerDetail.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(getSinglePlayerDetail.fulfilled, (state, { payload }) => {
        state.fetchLoading = false;
        state.singlePlayerDetail = payload;
      })
      .addCase(getSinglePlayerDetail.rejected, (state, { payload }) => {
        state.fetchLoading = false;
        state.error = payload;
      })
      .addCase(challengePlayer.pending, (state) => {
        state.loading = true;
      })
      .addCase(challengePlayer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.challengePlayerSuccess = true;
      })
      .addCase(challengePlayer.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getAllLadderUser.pending, (state) => {
        state.loading = true;
        state.fetchLoading = true;
      })
      .addCase(getAllLadderUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allLaddersUser = payload?.data;
        state.fetchLoading = false;
      })
      .addCase(getAllLadderUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.fetchLoading = false;
      })
      .addCase(getAllLadderPlayerUser.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(getAllLadderPlayerUser.fulfilled, (state, { payload }) => {
        state.fetchLoading = false;
        state.allLadderPlayersUser = payload?.data;
      })
      .addCase(getAllLadderPlayerUser.rejected, (state, { payload }) => {
        state.fetchLoading = false;
        state.error = payload;
      })
      .addCase(getSingleLadderDetailUser.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(getSingleLadderDetailUser.fulfilled, (state, { payload }) => {
        state.fetchLoading = false;
        state.singleLadderDetail = payload?.data;
        state.ladderAdmin = payload?.admin;
      })
      .addCase(getSingleLadderDetailUser.rejected, (state, { payload }) => {
        state.fetchLoading = false;
        state.error = payload;
      })
      .addCase(getLadderMatches.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLadderMatches.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.ladderMatchesUser = payload?.data;
      })
      .addCase(getLadderMatches.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getMyLadderMatches.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMyLadderMatches.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.myLadderMatches = payload?.data;
      })
      .addCase(getMyLadderMatches.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getSingleLadderMatch.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleLadderMatch.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.singleLadderMatchUser = payload?.data;
      })
      .addCase(getSingleLadderMatch.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getAllCourtUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCourtUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allCourts = payload?.data;
      })
      .addCase(getAllCourtUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(startLadder.pending, (state) => {
        state.loading = true;
      })
      .addCase(startLadder.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.startLadderSuccess = true;
      })
      .addCase(startLadder.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getInvitationsUser.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(getInvitationsUser.fulfilled, (state, { payload }) => {
        state.fetchLoading = false;
        state.allInvitations = payload?.data;
      })
      .addCase(getInvitationsUser.rejected, (state, { payload }) => {
        state.fetchLoading = false;
        state.error = payload;
      })
      .addCase(getSingleInvitationUser.pending, (state) => {
        state.loading = true;
        state.fetchLoading = true;
      })
      .addCase(getSingleInvitationUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.singleInvitation = payload?.data;
        state.fetchLoading = false;
      })
      .addCase(getSingleInvitationUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.fetchLoading = false;
      })
      .addCase(acceptChallengeInvite.pending, (state) => {
        state.loading = true;
      })
      .addCase(acceptChallengeInvite.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.acceptLadderInviteSuccess = true;
      })
      .addCase(acceptChallengeInvite.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(rejectChallengeInvite.pending, (state) => {
        state.loading = true;
      })
      .addCase(rejectChallengeInvite.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.rejectLadderInviteSuccess = true;
      })
      .addCase(rejectChallengeInvite.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(updateMatchScoreLadder.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMatchScoreLadder.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.scoreMatchSuccess = true;
      })
      .addCase(updateMatchScoreLadder.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(editUserPoint.pending, (state) => {
        state.loading = true;
      })
      .addCase(editUserPoint.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.editPointSuccess = true;
      })
      .addCase(editUserPoint.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(editUserRank.pending, (state) => {
        state.loading = true;
      })
      .addCase(editUserRank.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.editRankSuccess = true;
      })
      .addCase(editUserRank.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getAllAuditTrail.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(getAllAuditTrail.fulfilled, (state, { payload }) => {
        state.fetchLoading = false;
        state.allAuditTrail = payload;
      })
      .addCase(getAllAuditTrail.rejected, (state, { payload }) => {
        state.fetchLoading = false;
        state.error = payload;
      })
      .addCase(updateMatchTime.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMatchTime.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.updateMatchTimeSuccess = true;
      })
      .addCase(updateMatchTime.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const createLadder = createAsyncThunk(
  "createLadder",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(`${url.ladder}`, payload, {
        headers: {
          Authorization: `Bearer ${adminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const updateMatchScoreLadder = createAsyncThunk(
  "updateMatchScoreLadder",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.put(
        `${url.ladder}/${payload.id}/match/score`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const editUserPoint = createAsyncThunk(
  "editUserPoint",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.put(
        `${url.ladder}/${payload.id}/player/point`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const editUserRank = createAsyncThunk(
  "editUserRank",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.put(
        `${url.ladder}/${payload.id}/player/rank`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const addUserLadder = createAsyncThunk(
  "addUserToLadder",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.ladder}/${payload.id}/players`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const getAllLadder = createAsyncThunk(
  "getAllLadder",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.ladder}?page=${payload.page}&size=${payload?.size}`,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const getSingleLadderDetail = createAsyncThunk(
  "getSingleLadderDetail",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.ladder}/${payload.id}`, {
        headers: {
          Authorization: `Bearer ${adminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getLadderMembers = createAsyncThunk(
  "getLadderMembers",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.ladder}/${payload.id}/players`,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getAllAuditTrail = createAsyncThunk(
  "getAllAuditTrail",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.ladder}/u/${payload.id}/audit/true`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const updateMatchTime = createAsyncThunk(
  "updateMatchTime",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.ladder}/u/${payload?.id}/set`,
        payload?.data,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const editLadder = createAsyncThunk(
  "editLadder",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.put(
        `${url.ladder}/${payload.id}`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const deleteLadder = createAsyncThunk(
  "deleteLadder",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.delete(`${url.ladder}/${payload.id}`, {
        headers: {
          Authorization: `Bearer ${adminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const removePlayer = createAsyncThunk(
  "removePlayer",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.put(
        `${url.ladder}/${payload.id}/player`,
        payload?.data,

        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

// ################# USER SIDE BEGINS ################################

export const joinLadder = createAsyncThunk(
  "joinLadder",
  async (payload: any, { rejectWithValue, getState, dispatch }) => {
    const { auth }: any = getState();

    try {
      let req = {
        method: "post",
        address: `${url.ladder}/u/${payload.id}/join`,
        header: `Authorization: Bearer ${auth?.token}`,
      };
      const { data } = await nAPIService(req.method, req.address, req.header);

      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const leaveLadder = createAsyncThunk(
  "leaveLadder",
  async (payload: any, { rejectWithValue, getState, dispatch }) => {
    const { auth }: any = getState();

    try {
      let req = {
        method: "delete",
        address: `${url.ladder}/u/${payload.id}/leave`,
        header: `Authorization: Bearer ${auth?.token}`,
      };
      const { data } = await nAPIService(req.method, req.address, req.header);

      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const acceptChallengeInvite = createAsyncThunk(
  "acceptChallengeInvite",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.ladder}/u/invite/${payload.id}`,
        payload?.data,

        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const rejectChallengeInvite = createAsyncThunk(
  "rejectChallengeInvite",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.ladder}/u/invite/${payload.id}`,
        payload?.data,

        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const getSinglePlayerDetail = createAsyncThunk(
  "getSinglePlayerDetail",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.ladder}/u/player/${payload.id}`,

        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getAllCourtUser = createAsyncThunk(
  "getAllCourtUser",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.getCourt}`,

        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const challengePlayer = createAsyncThunk(
  "challengePlayer",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.ladder}/u/${payload.id}/challenge`,
        payload?.data,

        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const getAllLadderUser = createAsyncThunk(
  "getAllLadderUser",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.ladder}/u/all`,

        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getInvitationsUser = createAsyncThunk(
  "getAllInvitationsUser",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url?.ladder}/u/invite/all`,

        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getSingleInvitationUser = createAsyncThunk(
  "getSingleInvitationsUser",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url?.ladder}/u/invite/${payload.id}`,

        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const getAllLadderPlayerUser = createAsyncThunk(
  "getAllLadderPlayerUser",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.ladder}/u/${payload.id}/players`,

        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const getSingleLadderDetailUser = createAsyncThunk(
  "getSingleLadderDetailUser",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.ladder}/u/${payload.id}`,

        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const getLadderMatches = createAsyncThunk(
  "getLadderMatches",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.ladder}/u/${payload.id}/matches`,

        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getMyLadderMatches = createAsyncThunk(
  "getMyLadderMatches",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.ladder}/u/${payload.id}/my-match`,

        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const startLadder = createAsyncThunk(
  "startLadder",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.ladder}/${payload.id}/start`,

        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getSingleLadderMatch = createAsyncThunk(
  "getSingleLadderMatch",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.ladder}/u/${payload.id}/match/${payload?.matchId}`,

        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const {
  restoreDefault,
  restoreSingleLadder,
  resetAllLadderState,
  setSelectedLadder,
} = ladderSlice.actions;
export default ladderSlice.reducer;
