import { useEffect, useState, KeyboardEvent } from "react";
import { IoIosArrowBack } from "react-icons/io";

import { useNavigate } from "react-router-dom";
import Options from "../../../../components/Selects/Options";
import { AddCircle, SearchIcon } from "../../../../assets/svgs";
import { InviteIcon } from "../../../../assets/svgs";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  restoreDefault,
  searchForPlayer,
} from "../../../../features/auth/playerslice";
import LoadingComponent from "../../../../components/Loading/Loader";

const AllPlayersUser = () => {
  const dispatch = useAppDispatch();
  const player = useAppSelector((state) => state?.player);
  const auth = useAppSelector((state) => state?.auth);
  const [keyword, setKeyword] = useState("");
  const userId = auth?.userData?.user?._id;
  const [filteredPlayers, setFilteredPlayers] = useState<any>([]);
  const navigate = useNavigate();
  const [showResult, setShowResult] = useState(false);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      dispatch(searchForPlayer(keyword));
    }
  };
  useEffect(() => {
    dispatch(restoreDefault());
  }, []);

  useEffect(() => {
    setFilteredPlayers(player?.playerSearchResult);
    setShowResult(true);
  }, [player?.playerSearchResult]);
  useEffect(() => {
    setShowResult(false);
  }, [keyword]);

  return (
    <div>
      <>
        <div className="w-full flex flex-col px-4 py-6 md:max-w-[428px] md:mx-auto md:h-screen">
          <div className="flex justify-between mt-6 items-center">
            <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
            <p className="text-[#303030] font-bold text-[15px] ">All Players</p>
            <div className="">
              <Options isLast={false}>
                <div
                  className="w-full flex p-4 pr-8 rounded-[8px]  gap-4 bg-white min-w-[267px] cursor-pointer"
                  onClick={() => navigate("/invite-player/external")}
                >
                  <span>
                    <AddCircle />
                  </span>
                  <p className="leading-[17px] text-sm text-customBlack ">
                    Invite a friend that is not on the app
                  </p>
                </div>
              </Options>
            </div>
          </div>
          <div className="mt-12">
            <div className="w-full bg-[#f4f4f4] h-[50px] rounded-[16px] px-2 flex items-center gap-2">
              <span className="min-w-[16px]">
                <SearchIcon />
              </span>
              <input
                type="text"
                className="border-none outline-none focus:outline-none flex-grow placeholder:text-xs bg-transparent placeholder:text-[#c6c6c6]"
                placeholder="Search player"
                onChange={(e) => setKeyword(e.target?.value)}
                value={keyword}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <div className="w-full flex flex-col">
            {player?.fetchLoading ? (
              <div className="mt-8 px-4">
                <LoadingComponent />
              </div>
            ) : (
              <div className="w-full flex flex-col">
                {
                  <div className="w-full flex flex-col">
                    {filteredPlayers?.length === 0 && !keyword && (
                      <p className="text-sm text-customBlack mt-6">
                        Kindly input the name of the player you want to search
                        for in the search box above
                      </p>
                    )}
                    {filteredPlayers?.length === 0 && keyword && showResult && (
                      <p className="text-sm text-customBlack mt-6">
                        This player is not found.
                      </p>
                    )}
                  </div>
                }
                <div className="w-full flex flex-col gap-4 mt-8">
                  {filteredPlayers?.map((item: any, index: number) => {
                    return (
                      <div
                        className="w-full items-center justify-between flex "
                        key={index}
                      >
                        {/* left */}
                        <div className="flex items-center gap-4">
                          <div>
                            <img
                              src={item?.image}
                              alt={item.firstname}
                              className="w-[50px] h-[50px] rounded-[10px]"
                            />
                          </div>
                          <div className="flex flex-col">
                            <p className="text-customBlack font-bold  text-sm capitalize ">
                              {item?.firstname + " " + item?.lastname}
                            </p>
                            <p className="text-xs text-neutral-500 font-medium flex items-center gap-3">
                              {`Rank: ${item?.rank}`}
                            </p>
                          </div>
                        </div>

                        {/* riggt */}
                        {userId !== item?._id && (
                          <span
                            onClick={() =>
                              navigate(`/match-invite/${item?._id}`)
                            }
                          >
                            <InviteIcon />
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default AllPlayersUser;
