import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../Layouts/DashboardLayout";
import { NavigationArrow } from "../../../assets/svgs/admin";
import { useNavigate } from "react-router-dom";
import Prefill from "../../../components/Others/Knockout/Prefill";
import FirstCome from "../../../components/Others/Knockout/FirstCome";
import { getAllAcceptedPlayer } from "../../../features/auth/TournamentSlice";
import { useAppDispatch } from "../../../app/hooks";
const KnockoutTournament = () => {
  const id = useParams();
  const navigate = useNavigate();
  const numbers = useParams();
  const dispatch = useAppDispatch();

  const [show, setShow] = useState<boolean>(false);
  let filterId = numbers.number;
  useEffect(() => {
    if (id.id === "prefill") {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [id.id]);

  useEffect(() => {
    dispatch(getAllAcceptedPlayer({ id: filterId }));
  }, []);

  return (
    <div>
      <DashboardLayout current={3}>
        <div className="w-full flex flex-col bg-[#f4f4f4] h-full p-[30px] 2xl:p-8 2xl:pr-[50px] ">
          <div className="flex w-fit" onClick={() => navigate(-1)}>
            <NavigationArrow />
          </div>
          {show ? <Prefill /> : <FirstCome />}
        </div>
      </DashboardLayout>
    </div>
  );
};

export default KnockoutTournament;
