import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSimplifiedError } from "../../utils";
import { APIService } from "../../utils/APIServices";
import { url } from "../../utils/endpoints";
export interface PlayerState {
  loading: boolean;
  fetchLoading: boolean;
  currentUserData: any;
  playerSearchResult: object[];
  singlePlayer: {};
  sendInvitationSuccess: boolean;
  invitePlayerSuccess: boolean;
  allSentInvitation: [];
  allFriendlyInvitations: [];
  allReceivedInvitation: [];
  acceptInviteSuccess: boolean;
  scoreMatchSuccess: boolean;
  allInvitations: object[];
  deleteInvitationSuccess: boolean;
  seenSuccess: boolean;
  manageSuccess: boolean;
}

const initialState: PlayerState = {
  loading: false,
  fetchLoading: false,
  currentUserData: {},
  playerSearchResult: [],
  singlePlayer: {},
  sendInvitationSuccess: false,
  invitePlayerSuccess: false,
  allSentInvitation: [],
  allFriendlyInvitations: [],
  allReceivedInvitation: [],
  acceptInviteSuccess: false,
  scoreMatchSuccess: false,
  allInvitations: [],
  deleteInvitationSuccess: false,
  seenSuccess: false,
  manageSuccess: false,
};

export const playerSlice = createSlice({
  name: "Players",
  initialState,
  reducers: {
    restoreDefault: (state) => {
      state.loading = false;
      state.fetchLoading = false;
      state.invitePlayerSuccess = false;
      state.seenSuccess = false;
      state.manageSuccess = false;
      state.scoreMatchSuccess = false;
      state.acceptInviteSuccess = false;
      state.deleteInvitationSuccess = false;
      state.sendInvitationSuccess = false;
      state.playerSearchResult = [];
    },
    setCurrentUser: (state, action: PayloadAction<any>) => {
      state.currentUserData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchForPlayer.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(searchForPlayer.fulfilled, (state, { payload }) => {
        state.fetchLoading = false;
        state.playerSearchResult = payload?.data;
      })
      .addCase(searchForPlayer.rejected, (state, { payload }) => {
        state.fetchLoading = false;
      })
      .addCase(viewPlayer.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(viewPlayer.fulfilled, (state, { payload }) => {
        state.fetchLoading = false;
        state.singlePlayer = payload?.data;
      })
      .addCase(viewPlayer.rejected, (state, { payload }) => {
        state.fetchLoading = false;
      })
      .addCase(sendEmailInvite.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendEmailInvite.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.sendInvitationSuccess = true;
      })
      .addCase(sendEmailInvite.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(invitePlayer.pending, (state) => {
        state.loading = true;
      })
      .addCase(invitePlayer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.invitePlayerSuccess = true;
      })
      .addCase(invitePlayer.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(getSentInvitation.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(getSentInvitation.fulfilled, (state, { payload }) => {
        state.fetchLoading = false;
        state.allSentInvitation = payload?.data;
      })
      .addCase(getSentInvitation.rejected, (state, { payload }) => {
        state.fetchLoading = false;
      })
      .addCase(getAllFriendlyInvitation.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(getAllFriendlyInvitation.fulfilled, (state, { payload }) => {
        state.fetchLoading = false;
        state.allFriendlyInvitations = payload?.data;
      })
      .addCase(getAllFriendlyInvitation.rejected, (state, { payload }) => {
        state.fetchLoading = false;
      })
      .addCase(getAllReceivedInvitation.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(getAllReceivedInvitation.fulfilled, (state, { payload }) => {
        state.fetchLoading = false;
        state.allReceivedInvitation = payload?.data;
      })
      .addCase(getAllReceivedInvitation.rejected, (state, { payload }) => {
        state.fetchLoading = false;
      })
      .addCase(manageInvite.pending, (state) => {
        state.loading = true;
      })
      .addCase(manageInvite.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.manageSuccess = true;
      })
      .addCase(manageInvite.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(scoreFriendlyMatch.pending, (state) => {
        state.loading = true;
      })
      .addCase(scoreFriendlyMatch.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.scoreMatchSuccess = true;
      })
      .addCase(scoreFriendlyMatch.rejected, (state, { payload }) => {
        state.loading = false;
      });
  },
});

export const searchForPlayer = createAsyncThunk(
  "searchForPlayer",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.searchPlayer}${payload}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const viewPlayer = createAsyncThunk(
  "viewPlayer",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.viewPlayer}${payload}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const sendEmailInvite = createAsyncThunk(
  "sendEmailInvite",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.sendIEmailInvitation}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const invitePlayer = createAsyncThunk(
  "invitePlayer",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(`${url.invitePlayer}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const getSentInvitation = createAsyncThunk(
  "getSentInvitation",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.sentInvitation}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getAllFriendlyInvitation = createAsyncThunk(
  "getAllFriendlyInvitation",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.allInvitations}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getAllReceivedInvitation = createAsyncThunk(
  "getAllReceivedInvitation",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.receivedInvitation}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const manageInvite = createAsyncThunk(
  "manageInvite",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.put(
        `${url.manageInvite}${payload?.id}`,
        payload?.data,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const scoreFriendlyMatch = createAsyncThunk(
  "scoreFriendlyMatch",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.put(
        `${url.scoreFriendlyMatch}${payload?.id}/score`,
        payload?.data,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const authSelector = (state: any) => state.auth;

export const { restoreDefault, setCurrentUser } = playerSlice.actions;
export default playerSlice.reducer;
