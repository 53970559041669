import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import sentMark from "../../../assets/svgs/sent-mark.svg";

import DashboardLayout from "../../../Layouts/DashboardLayout";
import {
  getAllAcceptedPlayer,
  resetComptition,
  startCompetion,
} from "../../../features/auth/TournamentSlice";
import { AllPlayersRawData } from "../../../utils/sampData";
import { Btn } from "../../Button";
import ReUseModal from "../../Modal/ReUseModal";
import PrimarySelect from "../../Selects/PrimarySelect";
import SearchSelect from "../../Selects/SearchSelect";
import Toggler from "../../Togglers";

const Competition = () => {
  const [filterArray, setFilterArray] = useState(AllPlayersRawData);
  const [selectedPlayer] = useState<any>();
  useEffect(() => {
    let newPlayerList = filterArray.filter(
      (p) => p?._id !== selectedPlayer?._id
    );
    setFilterArray(newPlayerList);
  }, [selectedPlayer]);

  return (
    <>
      <DashboardLayout current={3}>
        <div className="flex flex-col">
          <Schedule />
        </div>
      </DashboardLayout>
    </>
  );
};

export default Competition;

const Schedule = () => {
  const navigate = useNavigate();
  const tournament = useAppSelector((state) => state.tournament);
  const dispatch = useAppDispatch();
  const [, setOpenRules] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [matchNo, setMatchNo] = useState<any>(0);
  const [allMatching, setAllMatching] = useState([]);

  const id = useParams();

  useEffect(() => {
    dispatch(getAllAcceptedPlayer({ id: id.id }));
  }, []);
  const playersArray = tournament.allAcceptedPlayers;

  const startMatch = () => {
    const dataPassed = {
      id: id.id,
      data: {
        match: allMatching,
      },
    };

    dispatch(startCompetion(dataPassed));
  };

  useEffect(() => {
    if (tournament.startCompetionSuccess) {
      setOpenModal(true);

      setTimeout(() => {
        dispatch(resetComptition());
        navigate("/admin/tournament");
      }, 1000);
    } else {
      setOpenModal(false);
    }
  }, [tournament.startCompetionSuccess]);

  const [arrays, setArrays] = useState<any>([]);

  useEffect(() => {
    let actual = matchNo;
    let arrays = [];
    for (let i = 0; i < actual; i++) {
      arrays.push(["a"]);
    }

    setArrays(arrays);
  }, [matchNo]);

  return (
    <div className="bg-white rounded-[20px] flex flex-col h-full mt-6 flow-hide ml-[25px]  ">
      <div className=" flex justify-between pr-8 h-auto items-center">
        <div className="flex items-center h-[51px]">
          <h1 className="pl-5 text-customBlack font-semibold text-sm   ">
            Competition Tournament
          </h1>
        </div>
      </div>
      {/* breaker line */}
      <div className="w-full h-[1px] bg-[#cfcfcf] " />
      <div className="w-full flex flex-col pl-5">
        <p className="mt-3 text-neutral-600 ">
          Kindly input the name of the players, time and court of the match
        </p>

        {/* form area */}
        <div className="w-full flex flex-col">
          <div className="flex space-x-3 items-center mt-4">
            <p className="text-base text-neutral-600  font-bold ">
              Number Of Matches:
            </p>
            <input
              type="number"
              className="outline-none h-[30px] rounded-[5px] border border-neutral-400 w-[230px] px-2"
              onChange={(e: any) => setMatchNo(e.target.value)}
              value={matchNo}
            />
          </div>
          {arrays?.map((item: any, index: number) => {
            return (
              <SingleItem
                {...item}
                playersList={playersArray}
                key={index}
                allMatching={allMatching}
                setAllMatching={setAllMatching}
                index={index}
              />
            );
          })}
        </div>
        {/* end of form area */}
        <div className="w-4/12 mt-12 mx-auto mb-12">
          <Btn
            active={true}
            name="Send"
            onClick={startMatch}
            loading={tournament.loading}
          />

          {/* <Btn
            active={true}
            name="Send"
            onClick={startMatch}
            loading={tournament.loading}
          /> */}
        </div>

        <ReUseModal open={openModal} setOpen={setOpenModal}>
          <img src={sentMark} alt="sent" className="mx-auto mt-6" />
          <h1 className="text-cutomBlack text-[24px] font-bold text-center">
            Tournament Created
          </h1>
          <p className="text-center w-10/12 mx-auto text-neutral-500 ">
            A Competition tournament has been created. All the players will
            receive the notification for the matches
          </p>
          <Btn
            active={true}
            name="Continue"
            onClick={() => {
              setOpenRules(false);
              setOpenModal(false);
              navigate(`/admin/tournament`);
            }}
            className="mt-[25px] mb-3"
          />
        </ReUseModal>
      </div>
    </div>
  );
};

const SingleItem = ({
  allMatching,
  setAllMatching,
  index,
  playersList,
}: any) => {
  const tournament = useAppSelector((state) => state.tournament);
  const [selectedCourt, setSelectedCourt] = useState<any>("");
  const [selectedPerson, setSelectedPerson] = useState<any>("");
  const [selectedPersonB, setSelectedPersonB] = useState<any>("");
  const [selectedPersonC, setSelectedPersonC] = useState<any>("");
  const [selectedPersonD, setSelectedPersonD] = useState<any>("");
  const [date, setDate] = useState("");
  const [showdouble, setShowDouble] = useState(false);
  let data = {
    challenger: selectedPerson?._id,
    challenged: selectedPersonB?._id,
    court: selectedCourt?._id,
    time: date,
    id: index,
    isDouble: showdouble,
    challengerB: selectedPersonC?._id,
    challengedB: selectedPersonD?._id,
  };

  const saveNow = () => {
    if (selectedCourt && date && selectedPerson && selectedPersonB) {
      let findResult = allMatching.find((_: any, index: any) => {
        return index === data.id;
      });
      if (findResult) {
        let editData = allMatching.map((item: any, index: number) => {
          if (index === data.id) {
            return {
              challenger: data.challenger,
              challenged: data.challenged,
              court: data.court,
              time: data.time,
              challengerB: data.challengerB,
              challengedB: data.challengedB,
              id: data.id,
            };
          } else return item;
        });
        setAllMatching(editData);
      } else {
        let newArray = [...allMatching, data];
        setAllMatching(newArray);
      }
    } else {
      toast.error("Please input all fields");
    }
  };

  useEffect(() => {
    if (selectedCourt && date && selectedPerson && selectedPersonB) {
      saveNow();
    }
  }, [
    selectedCourt,
    date,
    selectedPerson,
    selectedPersonB,
    selectedPersonC,
    selectedPersonD,
    showdouble,
  ]);
  let allCourt = tournament?.allCourts;

  return (
    <div className="flex flex-col pb-6  border-dashed pt-6 border-border border-b-[2px]">
      {/* upper sessiom */}
      <div className="flex space-x-6 items-center">
        {/* side a */}
        <div className="flex items-center space-x-3">
          <p className="text-neutral-600">
            {" "}
            Player <span className="font-bold text-customBlack">A</span>:
          </p>
          {/* <p className="outline-none h-[30px] rounded-[5px] border border-border w-[230px] px-2 ">
            {playerA ? playerA : "Ayeni Daniel"}
          </p> */}
          <SearchSelect
            options={playersList}
            setSelectedPerson={(e: any) => {
              setSelectedPerson(e);
            }}
            selectedPerson={selectedPerson}
          />
        </div>
        {/* sideb */}
        <p className="text-xs text-customBlack">VS</p>
        <div className="flex items-center space-x-3">
          <p className="text-neutral-600">
            {" "}
            Player <span className="font-bold text-customBlack">B</span>:
          </p>
          <SearchSelect
            options={playersList}
            setSelectedPerson={(opt: any) => {
              setSelectedPersonB(opt);
            }}
            selectedPerson={selectedPersonB}
          />
        </div>
      </div>

      {/* show double */}
      {showdouble && (
        <div className="flex space-x-6 items-center mt-3">
          {/* side a */}
          <div className="flex items-center space-x-3">
            <p className="text-neutral-600">
              {" "}
              Player <span className="font-bold text-customBlack">C</span>:
            </p>
            {/* <p className="outline-none h-[30px] rounded-[5px] border border-border w-[230px] px-2 ">
            {playerA ? playerA : "Ayeni Daniel"}
          </p> */}
            <SearchSelect
              options={playersList}
              setSelectedPerson={(e: any) => {
                setSelectedPersonC(e);
              }}
              selectedPerson={selectedPersonC}
            />
          </div>
          {/* sideb */}
          <p className="text-xs text-customBlack">VS</p>
          <div className="flex items-center space-x-3">
            <p className="text-neutral-600">
              {" "}
              Player <span className="font-bold text-customBlack">D</span>:
            </p>
            <SearchSelect
              options={playersList}
              setSelectedPerson={(opt: any) => {
                setSelectedPersonD(opt);
              }}
              selectedPerson={selectedPersonD}
            />
          </div>
        </div>
      )}
      {/* END OF double */}
      {/* lower session ###################3 */}
      <div className="flex space-x-6 items-center mt-4">
        {/* start of a sec */}
        <div className="flex items-center space-x-3">
          <p className="text-neutral-600">Match Date:</p>
          <input
            type="datetime-local"
            onChange={(e: any) => setDate(e.target.value)}
            className="outline-none h-[30px] rounded-[5px] border border-border w-[230px] px-2 "
          />
        </div>

        {/* enf of a select */}

        {/* start of a sec */}
        <div className="flex items-center space-x-3">
          <p className="text-neutral-600">Court:</p>
          <div className="w-[230px]">
            <PrimarySelect
              selected={selectedCourt}
              setSelected={setSelectedCourt}
              name="Select"
              data={allCourt}
              height="h-[30px] flex items-center bg-[#ffffff]"
              label=""
            />
          </div>
        </div>

        {/* enf of a select */}
        {/* <p className="text-customBlack bg-lemonGreen px-4 rounded-lg " onClick={saveNow}>
          save now
        </p> */}
      </div>
      <div className="flex   mt-8  items-center justify-between w-[383px]">
        <p className="text-neutral-600">Switch to Double</p>

        <Toggler enabled={showdouble} setEnabled={setShowDouble} />
      </div>
    </div>
  );
};
