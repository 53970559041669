import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { sortByDateField, convertDateTime } from "../../utils";
import { useAppDispatch } from "../../app/hooks";
import { Award } from "../../assets/svgs/buddies";
import { getMyMatches } from "../../features/auth/AuthSlice";
import LoadingComponent from "../../components/Loading/Loader";
import ComboSelect from "../../components/Selects/ComboSelect";
const MyMatches = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userSlice = useAppSelector((state) => state.auth);
  const [completedMatch, setCompletedMatch] = useState<any>([]);
  const [optionList, setOptionList] = useState<any>([]);
  const [renderedList, setRenderedList] = useState<any>([]);
  useEffect(() => {
    if (userSlice.userData.success === false || undefined) {
      navigate("/login");
    }
    dispatch(getMyMatches());
  }, [userSlice.userData]);
  let allMatches = userSlice?.myMatches;
  const sortedArray = sortByDateField(allMatches, {
    field: "time",
  });

  useEffect(() => {
    const completedMatches = sortedArray;
    const groupedMatch = completedMatches.reduce((result: any, item: any) => {
      const tournamentId = item?._tournament?._id ?? "no_tournament_id";
      result[tournamentId] = result[tournamentId] || [];
      result[tournamentId].push(item);
      return result;
    }, {});

    const groupedData = Object.entries(groupedMatch).map(([id, group]) => ({
      id,
      group,
    }));

    setCompletedMatch(groupedData);
    setRenderedList(groupedData);
  }, []);

  const [selected, setSelected] = useState<{ id: string; name: string }>({
    id: "",
    name: "",
  });

  useEffect(() => {
    if (completedMatch?.length) {
      const list = completedMatch?.map((item: any) => {
        const data = { id: item?.id, name: item?.group[0]?._tournament?.name };
        return data;
      });
      setOptionList(list);
    }
  }, [completedMatch]);

  useEffect(() => {
    if (selected?.name) {
      const filteredList = completedMatch?.filter(
        (item: any) => item?.id === selected?.id
      );
      setRenderedList(filteredList);
    }
  }, [selected]);

  if (userSlice?.fetchLoading) {
    return (
      <div className="w-full md:max-w-[428px] md:mx-auto mt-4 px-4">
        <LoadingComponent />
      </div>
    );
  }
  return (
    <div className="w-full flex flex-col px-4 py-6 md:max-w-[428px] md:mx-auto ">
      <div className="flex justify-between mt-6 items-center">
        <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
        <p className="text-[#303030] font-bold text-[15px] ">My Matches</p>
        <div className="more-shadow opacity-0"></div>
      </div>
      <div className="w-full mb-4 mt-8 flex flex-col">
        <p className="text-xs text-neutral-400">Search for a tournament</p>
        <ComboSelect
          options={optionList}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      <div className="flex flex-col  space-y-3">
        {/* <p className="text-[#acacac] text-[12px] ">Scores</p> */}

        <div className="flex flex-col space-y-3">
          {!renderedList.length ? (
            <h1 className="text-center text-neutral-700 text-base mt-[30px] ">
              You dont have a scheduled match yet!
            </h1>
          ) : (
            <div>
              {renderedList.map((item: any) => {
                return (
                  <div className="mb-6">
                    <p className="text-customBlack text-sm font-semibold capitalize mb-2">
                      {item?.group[0]?._tournament?.name}
                    </p>
                    <div className=" border-border border rounded-[16px] p-4">
                      {item?.group?.map((item: any) => {
                        const {
                          winner,
                          setA,
                          setB,
                          setC,
                          challenged,
                          challenger,
                          time,
                          challengedB,
                          challengerB,
                        } = item;

                        let realdate = convertDateTime(time).date;
                        let realTime = convertDateTime(time).time;

                        const challengerImage = challenger?.image
                          ? challenger?.image || challenger?.thumnail
                          : "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";
                        const challengedImage = challenged?.image
                          ? challenged?.image || challenged?.thumnail
                          : "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";
                        return (
                          <div className="flex  flex-col w-full border-b-[#E8EAED] border-b pb-3 mb-3">
                            <div className="flex justify-between items-center">
                              <p className="text-neutral-500 text-[10px] ">
                                {realdate}
                              </p>
                              <p className="text-neutral-500 text-[10px] ">
                                {realTime}{" "}
                              </p>
                            </div>
                            {/* head session */}
                            <div className="flex items-center justify-between mt-[7px]">
                              <div className="flex space-x-4 items-center">
                                <img
                                  src={challengerImage}
                                  alt="sample"
                                  className="w-[24px] h-[24px] rounded-full"
                                />
                                <p className="flex text-[#2c2c2c] text-xs font-semibold capitalize items-center ">
                                  1.{" "}
                                  {challengerB?.firstname
                                    ? `${challenger?.firstname}/${challengerB?.firstname}`
                                    : challenger?.lastname
                                    ? challenger?.firstname +
                                      " " +
                                      challenger?.lastname
                                    : challenger?.firstname
                                    ? challenger?.firstname
                                    : "BYE"}
                                  <span className="ml-1">
                                    {winner === challenger?._id && <Award />}
                                  </span>
                                </p>
                              </div>
                              <div className="flex space-x-4">
                                <p className="text-neutral-500 text-[12px]">
                                  {!setA?.challenger && !setA?.challenged
                                    ? "-"
                                    : setA?.challenger
                                    ? setA?.challenger
                                    : 0}
                                </p>
                                <p className="text-neutral-500 text-[12px]">
                                  {!setB?.challenger && !setB?.challenged
                                    ? "-"
                                    : setB?.challenger
                                    ? setB?.challenger
                                    : 0}
                                </p>
                                <p className="text-neutral-500 text-[12px]">
                                  {parseInt(setC?.challenged) !== 0 &&
                                  !setC?.challenged &&
                                  parseInt(setC?.challenger) !== 0 &&
                                  !setC?.challenger
                                    ? setC?.challenger
                                    : ""}
                                </p>
                              </div>
                            </div>
                            {/* tail session */}
                            <div className="flex items-center justify-between mt-2">
                              <div className="flex space-x-4 items-center">
                                <img
                                  src={challengedImage}
                                  alt="sample"
                                  className="w-[24px] h-[24px] rounded-full"
                                />
                                <p className="flex text-[#2c2c2c] text-xs font-semibold capitalize items-center">
                                  2.{" "}
                                  {challengedB?.firstname
                                    ? `${challenged?.firstname}/${challengedB?.firstname}`
                                    : challenged?.lastname
                                    ? challenged?.firstname +
                                      " " +
                                      challenged?.lastname
                                    : challenged?.firstname
                                    ? challenged?.firstname
                                    : "BYE"}
                                  <span className="ml-1">
                                    {winner === challenged?._id && <Award />}
                                  </span>
                                </p>
                              </div>
                              <div className="flex space-x-4">
                                <p className="text-neutral-500 text-[12px]">
                                  {" "}
                                  {!setA?.challenger && !setA?.challenged
                                    ? "-"
                                    : setA?.challenged
                                    ? setA?.challenged
                                    : 0}
                                </p>
                                <p className="text-neutral-500 text-[12px]">
                                  {" "}
                                  {!setB?.challenger && !setB?.challenged
                                    ? "-"
                                    : setB?.challenged
                                    ? setB?.challenged
                                    : 0}
                                </p>
                                <p className="text-neutral-500 text-[12px]">
                                  {parseInt(setC?.challenged) !== 0 &&
                                  !setC?.challenged &&
                                  parseInt(setC?.challenger) !== 0 &&
                                  !setC?.challenger
                                    ? setC?.challenged
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyMatches;
