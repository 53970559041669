import DashboardLayout from "../../../Layouts/DashboardLayout";

import { useNavigate } from "react-router-dom";
import { NavigationArrow } from "../../../assets/svgs/admin";

import TeamCompetitionScoringBoard from "../../../components/Others/Knockout/TeamCompetitionScoringBoard";
const TeamCompetitionScoringPage = () => {
  const navigate = useNavigate();
  return (
    <DashboardLayout current={3}>
      <div className="w-full flex flex-col bg-[#f4f4f4] h-full p-[30px] 2xl:p-8 2xl:pr-[50px] ">
        <div className="flex w-fit" onClick={() => navigate(-1)}>
          <NavigationArrow />
        </div>
        <TeamCompetitionScoringBoard />
      </div>
    </DashboardLayout>
  );
};

export default TeamCompetitionScoringPage;
