import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getSingleLadderDetailUser,
  getAllLadderPlayerUser,
} from "../../features/auth/ladder-slice";
import { toast } from "react-toastify";
import { DarkSearchIcon } from "../../assets/svgs";
import { CautionIcon } from "../../assets/svgs/Icons";
import { Btn } from "../Button";
import ReUseModal from "../Modal/ReUseModal";
import LoadingComponent from "../Loading/Loader";

const PlayerListRank = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ladder = useAppSelector((state) => state.ladder);
  const auth = useAppSelector((state) => state.auth);
  const [players, setPlayers] = useState<any>([]);
  const [isPoint, setIsPoint] = useState(false);
  const [sortedPlayers, setSortedPlayers] = useState<any>([]);
  const [isHigher, setIsHigher] = useState(false);
  const [isLower, setIsLower] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [isSelf, setIsSelf] = useState(false);

  const id = useParams();

  useEffect(() => {
    dispatch(getSingleLadderDetailUser({ id: id.id }));
    dispatch(getAllLadderPlayerUser({ id: id.id }));
  }, []);

  useEffect(() => {
    if (ladder?.singleLadderDetail?.leaderboard?.length > 0) {
      setPlayers(ladder?.singleLadderDetail?.leaderboard);
    }
  }, [ladder?.singleLadderDetail]);

  useEffect(() => {
    const sortedItems = [...players].sort((a, b) => b.points - a.points);
    setSortedPlayers(sortedItems);
  }, [players]);

  const info = ladder?.singleLadderDetail;

  const nonGreyedPlayersPoint = sortedPlayers.filter(
    (item: any) => item?.locked !== true
  );
  const nonGreyedPlayersRank = players.filter(
    (item: any) => item?.locked !== true
  );
  const userId = auth?.userData?.user?._id;
  const admin = ladder?.ladderAdmin;
  // console.log(ladder.singleLadderDetail);
  const challengePower = ladder?.singleLadderDetail?.challenge_power;

  const currentUser = players.find((player: any) => player._id?._id === userId);

  const currentUserIndexPoint = nonGreyedPlayersPoint.indexOf(currentUser);

  const currentUserIndexRank = nonGreyedPlayersRank.indexOf(currentUser);
  const userPosition = players.indexOf(currentUser);

  console.log(currentUserIndexRank);
  const handleClickRank = (clickedPlayer: any) => {
    if (currentUser?.locked) {
      setIsLocked(true);
    } else {
      const clickedPlayerIndex = nonGreyedPlayersRank?.indexOf(clickedPlayer);

      if (currentUserIndexRank === clickedPlayerIndex) {
        setIsSelf(true);
      } else if (clickedPlayerIndex < currentUserIndexPoint) {
        const positionDifference = currentUserIndexRank - clickedPlayerIndex;
        if (positionDifference <= challengePower) {
          navigate(`/selected-profile/${clickedPlayer?._id?._id}`);
        } else {
          setIsHigher(true);
        }
      } else {
        setIsLower(true);
      }
    }
  };

  const [filteredPlayers, setFilteredPlayers] = useState([]);

  useEffect(() => {
    setFilteredPlayers(players);
  }, [players]);

  const handleSearch = (event: { target: { value: string } }) => {
    const searchValue = event.target.value.toLowerCase();

    const filtered = players?.filter(
      (player: any) =>
        player?._id?.firstname?.toLowerCase()?.includes(searchValue)
    );

    setFilteredPlayers(filtered);
  };
  if (ladder?.fetchLoading) {
    return (
      <div className="w-full mt-8 px-4 md:h-screen md:max-w-[428px] md:mx-auto">
        <LoadingComponent />
      </div>
    );
  }
  return (
    <div className="w-full flex flex-col">
      <div
        className={`w-full h-20 border-border border rounded-md flex items-center justify-between px-4 bg-[#FFEECC] ${
          currentUser?.locked ? "opacity-50" : "opacity-100"
        } `}
        onClick={() => {
          if (currentUser?.locked) {
            toast.error("Player Not Available");
          } else {
            handleClickRank(currentUser);
          }
        }}
      >
        <div className="flex items-center space-x-3">
          <div
            className={`w-[44px] h-[44px] rounded-full  text-base font-semibold flex items-center justify-center bg-[#FFA800] text-[#fff] `}
          >
            {userPosition + 1}
          </div>

          <div className="flex flex-col">
            <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
              {`${currentUser?._id?.firstname} ${
                currentUser?._id?.lastname ? currentUser?._id?.lastname : ""
              }`}
            </p>
            {/* <p className="text-[12px] text-[#acacac]">
                            {item?.locked ? "Not Available" : "Available"}
                          </p> */}
          </div>
        </div>
        <div className="  w-3/12 grid grid-cols-3  ">
          {/* start */}
          <div className="flex flex-col gap-2">
            <p className="text-[12px] text-[#acacac]">P</p>
            <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
              {currentUser?.matches}
            </p>
          </div>
          {/* end */}
          {/* start */}
          <div className="flex flex-col gap-2">
            <p className="text-[12px] text-[#acacac]">W</p>
            <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
              {currentUser?.win}
            </p>
          </div>
          {/* end */}
          {/* start */}
          <div className="flex flex-col gap-2">
            <p className="text-[12px] text-[#acacac]">L</p>
            <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
              {currentUser?.loss}
            </p>
          </div>
          {/* end */}
          {/* start */}
          {/* <div className="flex flex-col gap-2">
                          <p className="text-[12px] text-[#acacac]">
                           Pts
                          </p>
                          <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                            {item?.points}
  </p>
                        </div> */}
          {/* end */}
        </div>
      </div>
      <div className="mt-6">
        <div className="w-full bg-[#f4f4f4] h-[50px] rounded-[16px] px-2 flex items-center gap-2">
          <span className="min-w-[16px]">
            <DarkSearchIcon />
          </span>
          <input
            type="text"
            className="border-none outline-none focus:outline-none flex-grow placeholder:text-xs bg-transparent placeholder:text-[#c6c6c6]"
            placeholder="Search ladder"
            onChange={handleSearch}
          />
        </div>
      </div>
      <div className="mt-6 flex flex-col space-y-4">
        {filteredPlayers?.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className={`w-full h-20 border-border border rounded-md flex items-center justify-between px-4 ${
                item?._id?._id === userId ? "bg-[#FFEECC]" : "bg-white"
              } ${item?.locked ? "opacity-50" : "opacity-100"} `}
              onClick={() => {
                if (item?.locked) {
                  toast.error("Player Not Available");
                } else {
                  handleClickRank(item);
                }
              }}
            >
              <div className="flex items-center space-x-3">
                <div
                  className={`w-[44px] h-[44px] rounded-full  text-base font-semibold flex items-center justify-center ${
                    item?._id?._id === userId
                      ? "bg-[#FFA800] text-[#fff]"
                      : "bg-[#ffeecc] text-[#eb9b00]"
                  } `}
                >
                  {index + 1}
                </div>

                <div className="flex flex-col">
                  <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                    {`${item?._id?.firstname} ${
                      item?._id?.lastname ? item?._id?.lastname : ""
                    }`}
                  </p>
                  {/* <p className="text-[12px] text-[#acacac]">
                            {item?.locked ? "Not Available" : "Available"}
                          </p> */}
                </div>
              </div>
              <div className="  w-3/12 grid grid-cols-3  ">
                {/* start */}
                <div className="flex flex-col gap-2">
                  <p className="text-[12px] text-[#acacac]">P</p>
                  <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                    {item?.matches}
                  </p>
                </div>
                {/* end */}
                {/* start */}
                <div className="flex flex-col gap-2">
                  <p className="text-[12px] text-[#acacac]">W</p>
                  <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                    {item?.win}
                  </p>
                </div>
                {/* end */}
                {/* start */}
                <div className="flex flex-col gap-2">
                  <p className="text-[12px] text-[#acacac]">L</p>
                  <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                    {item?.loss}
                  </p>
                </div>
                {/* end */}
                {/* start */}
                {/* <div className="flex flex-col gap-2">
                          <p className="text-[12px] text-[#acacac]">
                           Pts
                          </p>
                          <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                            {item?.points}
  </p>
                        </div> */}
                {/* end */}
              </div>
            </div>
          );
        })}
      </div>
      <ReUseModal open={isHigher} setOpen={setIsHigher}>
        <div className="w-full flex flex-col">
          <div className="flex items-center justify-center mt-10">
            <CautionIcon />
          </div>
          <h1 className="text-center mt-2 font-bold text-[22px] leading-[24px] text-customBlack ">
            Can't Challenge
          </h1>
          <p className="text-[#868686] mt-1 text-sm text-center max-w-[340px] mx-auto">
            {` Sorry you can only challenge players that are ${
              challengePower || ""
            } steps ahead of you on the table `}
          </p>
          <div className="w-full flex items-center justify-center  gap-6 mt-16">
            <Btn name="Close" onClick={() => setIsHigher(false)} active />
          </div>
        </div>
      </ReUseModal>
      <ReUseModal open={isLocked} setOpen={setIsLocked}>
        <div className="w-full flex flex-col">
          <div className="flex items-center justify-center mt-10">
            <CautionIcon />
          </div>
          <h1 className="text-center mt-2 font-bold text-[22px] leading-[24px] text-customBlack ">
            Can't Challenge Player
          </h1>
          <p className="text-[#868686] mt-1 text-sm text-center max-w-[340px] mx-auto">
            Unfortunately, you are currently marked as unavailable for the
            competition. Please contact the admin for further assistance or more
            information
          </p>
          <div className="w-full flex items-center justify-center  gap-6 mt-16">
            <Btn name="Close" onClick={() => setIsLocked(false)} active />
          </div>
        </div>
      </ReUseModal>
      <ReUseModal open={isLower} setOpen={setIsLower}>
        <div className="w-full flex flex-col">
          <div className="flex items-center justify-center mt-10">
            <CautionIcon />
          </div>
          <h1 className="text-center mt-2 font-bold text-[22px] leading-[24px] text-customBlack ">
            Can't Challenge
          </h1>
          <p className="text-[#868686] mt-1 text-sm text-center max-w-[340px] mx-auto">
            Sorry you can't challenge players that are below you on the table
          </p>
          <div className="w-full flex items-center justify-center  gap-6 mt-16">
            <Btn name="Close" onClick={() => setIsLower(false)} active />
          </div>
        </div>
      </ReUseModal>
      <ReUseModal open={isSelf} setOpen={setIsSelf}>
        <div className="w-full flex flex-col">
          <div className="flex items-center justify-center mt-10">
            <CautionIcon />
          </div>
          <h1 className="text-center mt-2 font-bold text-[22px] leading-[24px] text-customBlack ">
            Can't Challenge Self
          </h1>
          <p className="text-[#868686] mt-1 text-sm text-center max-w-[340px] mx-auto">
            Sorry you cannot challenge yourself
          </p>
          <div className="w-full flex items-center justify-center  gap-6 mt-16">
            <Btn name="Close" onClick={() => setIsSelf(false)} active />
          </div>
        </div>
      </ReUseModal>
    </div>
  );
};

export default PlayerListRank;
