import { Navigate, useLocation } from "react-router-dom";
import { store } from "./app/store";

const ProtectedRoute = ({ children }: { children: any }) => {
  const token = store.getState().adminAuth.token;
  const { pathname } = useLocation();

  if (token === undefined || token === "") {
    return <Navigate to={`/admin/login/?redirect=${pathname}`} replace />;
  }
  return children;
};

// export const PublicRoute = ({ children }: { children: any }) => {
//   const token = store.getState().adminAuth.token;

//   if (token === undefined || token === "") {
//     return children;
//   }
//   return <Navigate to="/bets" replace />;
// };

export default ProtectedRoute;
