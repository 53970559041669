import receipt from "../../../assets/svgs/receipt.svg";

const LadderBoardTable = () => {
  return (
    <div className=" w-417 h-260 top-418 left-973 rounded-20 w-full flex flex-col bg-white rounded-[20px] h-[300px] ">
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className="w-93 h-17 top-22 left-16 font-inter font-semibold text-14 leading-17 tracking-tighter text-left text-gray-900 text-sm ">
          Ladder Board
        </h1>
        {/* <p className="text-[#cfcfcf] text-sm font-[500]">
          Starts May10 Ends May 20
        </p> */}
      </div>
      <div className="w-417 h-35 top-51 opacity-30 w-full h-[35px] flex pl-4 bg-[#F4F4F4] justify-between items-center">
        <div className="flex items-center space-x-[17px] w-7/12 bg-[#F4F4F4]">
          <p className="w-22 h-15 top-61 left-16 font-inter font-medium text-12 leading-15 tracking-tighter text-left text-neutral-600 text-xs">
            S/N
          </p>
          <p className="w-34 h-15 top-61 left-55 font-inter font-medium text-12 leading-15 tracking-tighter text-left text-neutral-600 text-xs">
            Name
          </p>
        </div>
        <div className=" w-5/12 h-[35px] items-center flex">
          <p className="w-34 h-15 top-61 left-55 font-inter font-medium text-12 leading-15 tracking-tighter text-left text-neutral-600 text-xs ">
            PTS
          </p>
        </div>
      </div>
      <div className=" mt-20 border-2.38 border-gray-600 w-38 h-38 flex items-center justify-center">
        <img
          src={receipt}
          className=" border-2.5 border-gray-600 w-38 h-38w-38 h-38"
          alt=""
        />
      </div>
      <h1 className="font-inter text-xs font-medium leading-15 tracking-wider w-106 h-15 text-center mt-5">
        No ladder board
      </h1>
      {/* <div className="flex flex-col space-y-2 rounded-b-[20px] bg-white pb-2">
        {data.map((item: any, index: number) => {
          return (
            <div
              className="w-full h-[35px] flex pl-4  justify-between items-center"
              key={index}
            >
              <div className="flex items-center space-x-[17px] w-7/12 ">
                <p className="text-neutral-600 font-[500] text-xs">
                  {index + 1}
                </p>
                <div className="flex  ">
                  <p className="text-neutral-600 font-[500] text-xs flex items-center space-x-2 ">
                    {item.winner && (
                      <span>
                        <WinnerCup />
                      </span>
                    )}
                    <img
                      src={item.image}
                      alt="image"
                      className="rounded-full mx-2"
                    />
                    {item.name}{" "}
                  </p>
                </div>
              </div>
              <div className=" w-5/12 h-[35px] items-center flex">
                <p className="text-neutral-600 text-xs font-[500]">
                  {item.pts}
                </p>
              </div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default LadderBoardTable;
