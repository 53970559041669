import DashboardLayout from "../../../Layouts/DashboardLayout";
import { useEffect, useState } from "react";
import { BackBtn } from "../../../assets/svgs/Icons";
import { quizData } from "../../rank/RankYourTennis";
import Radio from "../../../components/CustomRadio";
import { Btn } from "../../../components/Button";
import sentMark from "../../../assets/svgs/checkmark.svg";
import ReUseModal from "../../../components/Modal/ReUseModal";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { rankPlayer, resetAll } from "../../../features/auth/TournamentSlice";
const EvaluatePlayerAdmin = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [rank, setRank] = useState("");
  const navigate = useNavigate();
  const id = useParams();
  const userId = id?.id;
  const [players, setPlayers] = useState({});

  const evaluations = useAppSelector((state) => state.tournament);
  useEffect(() => {
    if (evaluations?.allEvaluationsAdmin?.length > 0) {
      const filteredUsers = evaluations.allEvaluationsAdmin?.filter(
        (item: any) => item?.player?._id === userId
      );
      setPlayers(filteredUsers[0]);
    } else setPlayers({});
  }, [evaluations?.allEvaluationsAdmin]);

  const handleRank = () => {
    const data = {
      input: rank,
      id: id.id,
    };
    dispatch(rankPlayer(data));
  };

  useEffect(() => {
    if (evaluations?.rankPlayerSuccess) {
      setOpen(true);
      dispatch(resetAll());
      setTimeout(() => {
        navigate("/admin/club");
      }, 3000);
    }
  }, [evaluations?.rankPlayerSuccess]);

  return (
    <DashboardLayout current={5}>
      <div className="w-full flex flex-col bg-[#f4f4f4] h-full p-[30px] 2xl:p-8 2xl:pr-[50px]">
        <BackBtn />

        <div className="w-full mt-6 bg-white pb-8 rounded-[20px] flex flex-col ">
          <div className=" px-4 h-[50px] flex items-center border-b border-b-neutral-300">
            <h1 className="text-sm font-semibold text-customBlack">
              Evaluate Player
            </h1>
          </div>
          <p className="mt-4 px-4 text-sm text-customBlack">
            Kindly go through the rank questions and asnwer and input their rank
            based on their selected answer
          </p>
          <div className="w-full grid grid-cols-2 px-4 mt-4">
            {/* left side */}
            <div className="w-full flex flex-col gap-y-5 ">
              {quizData.slice(0, 5)?.map((item: any, i: number) => {
                return <SingleQuestion item={item} key={i} players={players} />;
              })}
            </div>
            {/* right side */}
            <div className="w-full flex flex-col border-l border-l-neutral-300 gap-y-5 px-4">
              {quizData.slice(5, 10)?.map((item: any, i: number) => {
                return <SingleQuestion item={item} key={i} players={players} />;
              })}
            </div>
          </div>
          <div className="px-4 flex flex-col mt-6">
            <p className="text-[#363636] text-sm ">
              Assign point to a player based on evaluation
            </p>
            <p className="text-[#5E5E5E] text-sm mt-3">Point value</p>
            <div className="w-full mt-2">
              <input
                type="text"
                className="h-[50px] w-full max-w-[308px] rounded-[5px] border border-[#363636] px-3 outline-none focus:outline-none "
                value={rank}
                onChange={(e: any) => setRank(e.target.value)}
              />
            </div>
          </div>
          <div className="w-full flex items-center justify-center mt-10">
            <Btn
              active={rank.length > 0}
              className="max-w-[442px] mx-auto"
              name="Confirm"
              loading={evaluations?.loading}
              onClick={handleRank}
            />
          </div>
        </div>
      </div>
      <ReUseModal open={open} setOpen={setOpen}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-customBlack space-y-1 text-[24px] font-bold text-center">
          Rank Player
        </h1>
        <p className="text-center w-10/12 max-w-[331px] mx-auto text-neutral-500 text-sm leading-[24px] tracking-[0.14px]">
          You’ve successfully ranked this player
        </p>
        <Btn
          active={true}
          name="Close"
          onClick={() => {
            setOpen(false);
            navigate("/admin/club");
          }}
          className="mt-[49px] mb-3"
        />
      </ReUseModal>
    </DashboardLayout>
  );
};
// remember we wanna do a slice operation so we cam split quiz data to two  halves

const SingleQuestion = ({ item, players }: any) => {
  const { name } = item;
  return (
    <div className="w-full flex flex-col " key={item.id}>
      <div className="flex gap-x-2">
        <span className="text-[#363636] text-sm">{item.id}</span>
        <p className="text-[#363636] text-sm ">{item.question}</p>
      </div>
      <div className="w-full flex flex-col gap-y-2 mt-3">
        {item?.options?.map((opt: any, i: number) => {
          return (
            <div className="w-full flex items-center gap-x-2 ">
              <span>
                <Radio
                  active={opt === players[name]}
                  onClick={() => console.log("")}
                />
              </span>
              <p className="text-[#363636] text-sm">{opt}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EvaluatePlayerAdmin;
