import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SuperAdminLayout from "../../Layouts/SuperAdminLayout";
import { NavigationArrow } from "../../assets/svgs/admin";
import sentMark from "../../assets/svgs/sent-mark.svg";
import { Btn } from "../../components/Button";
import ReUseModal from "../../components/Modal/ReUseModal";
const AddNewAdmin = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const handleContinue = () => {
    setOpenModal(false);
  };
  return (
    <div>
      <SuperAdminLayout current={1}>
        <div className="w-full flex flex-col bg-[#f4f4f4] h-full p-[30px] 2xl:p-8 2xl:pr-[50px] ">
          <div className="flex w-fit" onClick={() => navigate(-1)}>
            <NavigationArrow />
          </div>
          <div className="bg-white rounded-[20px] flex flex-col h-full mt-6 ">
            <div className="h-[51px] ">
              <h1 className="pl-5 text-customBlack font-semibold text-sm mt-[22px] ">
                Create Tournament
              </h1>
            </div>
            {/* breaker line */}
            <div className="w-full h-[1px] bg-[#cfcfcf] " />
            {/* form session */}
            <div className="flex flex-col relative h-full">
              {/* left session */}
              <div className="w-full ">
                <p className="mt-3 text-neutral-600 pl-5">
                  Select the type of tournament you want to create
                </p>
                <div className="w-9/12 grid grid-cols-2 gap-8 pl-5 mt-[30px]">
                  {/* start of a form */}
                  <div className="flex flex-col">
                    <label className="text-neutral-500 text-sm mb-2 ">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="h-[50px] px-3 border border-border rounded-[5px] placeholder:text-sm placeholder:text-neutral-400 "
                      placeholder="First Name"
                    />
                  </div>

                  {/* end of a form */}
                  {/* start of a form */}
                  <div className="flex flex-col">
                    <label className="text-neutral-500 text-sm mb-2 ">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="h-[50px] px-3 border border-border rounded-[5px] placeholder:text-sm placeholder:text-neutral-400 "
                      placeholder="Last Name"
                    />
                  </div>

                  {/* end of a form */}
                  {/* start of a form */}
                  <div className="flex flex-col">
                    <label className="text-neutral-500 text-sm mb-2 ">
                      Email Address
                    </label>
                    <input
                      type="text"
                      className="h-[50px] px-3 border border-border rounded-[5px] placeholder:text-sm placeholder:text-neutral-400 "
                      placeholder="Email Address"
                    />
                  </div>

                  {/* end of a form */}
                  {/* start of a form */}
                  <div className="flex flex-col">
                    <label className="text-neutral-500 text-sm mb-2 ">
                      Name of the Club
                    </label>
                    <input
                      type="text"
                      className="h-[50px] px-3 border border-border rounded-[5px] placeholder:text-sm placeholder:text-neutral-400 "
                      placeholder="Name of the Club"
                    />
                  </div>

                  {/* end of a form */}
                  {/* start of a form */}
                  <div className="flex flex-col">
                    <label className="text-neutral-500 text-sm mb-2 ">
                      Number of Courts
                    </label>
                    <input
                      type="text"
                      className="h-[50px] px-3 border border-border rounded-[5px] placeholder:text-sm placeholder:text-neutral-400 "
                      placeholder=""
                    />
                  </div>

                  {/* end of a form */}
                  {/* start of a form */}
                  <div className="flex flex-col">
                    <label className="text-neutral-500 text-sm mb-2 ">
                      Country
                    </label>
                    <input
                      type="text"
                      className="h-[50px] px-3 border border-border rounded-[5px] placeholder:text-sm placeholder:text-neutral-400 "
                      placeholder="Last Name"
                    />
                  </div>

                  {/* end of a form */}
                </div>
                <div className=" flex justify-center items-center w-3/12 mx-auto mt-[175px]">
                  <Btn
                    onClick={() => setOpenModal(true)}
                    active={true}
                    name={"Add"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReUseModal open={openModal} setOpen={setOpenModal}>
          <img src={sentMark} alt="sent" className="mx-auto mt-6" />
          <h1 className="text-cutomBlack text-[24px] font-bold text-center">
            Successful
          </h1>
          <p className="text-center w-10/12 mx-auto text-neutral-500 ">
            You’ve successfully added a new admin
          </p>
          <Btn
            active={true}
            name="Create"
            onClick={handleContinue}
            className="mt-[49px] mb-3"
          />
        </ReUseModal>
      </SuperAdminLayout>
    </div>
  );
};

export default AddNewAdmin;
