/** this would wrap all the api endpoints and base urls */
export const baseUrl = process.env.REACT_APP_BASE_URL;

export const url = {
  login: "v1/login",
  googleLogin: "v1/login/google",
  register: "v1/register",
  verifyUserEmail: "v1/confirm/email",
  userOtpVerification: "v1/confirm/otp",
  googleRegister: "v1/register/google",
  bets: "v1/bet",
  payment: "v1/payment",
  transactions: "v1/transactions",
  invite: "v1/invite",
  clubs: "v1/clubs",
  quote: "v1/quote",
  profile: "v1/profile",
  joinTournament: "v1/tournament/invite/join",
  leaveTournament: "v1/tournament/invite/leave",
  getInvitations: "v1/invitations",
  allMatches: "v1/matches/all",
  myMatches: "v1/matches",
  adminLogin: "v1/admin/login",
  adminSuperLogin: "v1/super/login",
  adminRegister: "v1/admin/register",
  verifyAdmin: "v1/admin/resend/verify/account",
  forgotPassword: "v1/forgot-password",
  forgetOtp: "v1/verify/password",
  newPassword: "v1/reset-password",
  evaluation: "v1/evaluation",
  joinComp: "v2/competition",
  deleteMatch: "v1/admin/match",
  getCourt: "v1/courts/club",
  profileUpdate: "v1/profile/setup",
  getEvaluation: "v1/admin/evaluations",
  rankPlayer: "v1/admin/rank",

  adminForgotPassword: "v1/admin/forgot-password",
  adminResetPassword: "v1/admin/reset-password",
  createCompetition: "v2/competition",
  adminVerifyOtp: "v1/admin/verify/account",
  adminVerifyForgotOtp: "v1/admin/verify/password",
  adminResendOtp: "v1/admin/resend/verify/account",
  adminVerifyAccount: "v1/admin/resend/verify/account",
  getInvitationCode: "v1/admin/club/invitation",
  sendInvitationCode: "v1/admin/club/send/invitation",
  generateNewInvitationCode: "v1/admin/club/new/invitation",
  allUserAdmin: "v1/super",
  getAllAdmin: "v1/super/admin",
  getSingleAdmin: "v1/super/admin/",
  getAllClub: "v1/super/clubs",
  createClub: "v1/admin/club",
  deleteAdmin: "v1/super/admin",
  getClubProfile: "v1/admin/club",
  getAllPendingMemeber: "v1/admin/club/pending/join",
  getAllAcceptedMember: "v1/admin/club/accepted",
  getAllRejectedMember: "v1/admin/club/rejected",
  getAllClubMembers: "v1/admin/club/members",
  createCourt: "v1/admin/court",
  getAllCourt: "v1/admin/courts",
  bookCourt: "v1/admin/court/book",
  allBookings: "v1/admin/club/bookings",
  bookCourtUser: "v1/courts/book",
  allBookingsUser: "v1/courts/bookings",
  getAllCourtUser: "v1/courts/club",
  courtDetailUser: "v1/courts/detail",
  updateMemberState: "v1/admin/club/update/",
  editBooking: "v1/admin/court/book/",
  editCourtDetails: "v1/admin/court/",
  deleteCourt: "v1/admin/court/",
  allPendingMember: "v1/admin/club/pending/join/",
  createFirstCome: "v1/admin/tournament/knockout/fcfs",
  createPrefill: "v1/admin/tournament/knockout/prefill",
  getAllTournaments: "v1/admin/tournaments",
  getAllAcceptedPlayers: "v1/admin/tournament/",
  editTournament: "v1/admin/tournament",
  startTournament: "v1/admin/tournament/knockout/",
  startCompetition: "v1/admin/competition/",
  deleteTournament: "v1/admin/tournament/",
  matchInTournament: "v1/admin/tournament/knockout/",
  updateMatchScore: "v1/admin/match/",
  nextRound: "v1/admin/tournament/knockout/",
  allMatchesAdmin: "v1/admin/matches",
  prefillAddPlayers: "v1/admin/tournament/knockout/prefill/",
  ladder: "v1/ladder",
  searchPlayer: "v1/friendly/search?keyword=",
  viewPlayer: "v1/friendly/view/",
  sendIEmailInvitation: "v1/friendly/send/invitation",
  invitePlayer: "v1/friendly/invite",
  sentInvitation: "v1/friendly/invitation/sent",
  allInvitations: "v1/friendly/invitations",
  receivedInvitation: "v1/friendly/invitation/received",
  manageInvite: "v1/friendly/invitation/",
  scoreFriendlyMatch: "v1/friendly/match/",
  invitations: "v1/invitations",
  competition: "v2/competition",
  updateBooking: "v1/admin/court/booking",
  updateClub: "v1/admin/club/update",
  editProfileAdmin: "v1/admin/profile",
};
