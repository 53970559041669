import { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import useA from "../../assets/png/use1.jpg";
import useB from "../../assets/png/use2.jpg";
import useC from "../../assets/png/use3.jpg";
import useD from "../../assets/png/use4.jpg";
import { More } from "../../assets/svgs/court";
const testData = [
  {
    name: "Victory Court Limte..",
    price: "N18,000",
    img: useA,
    locstion: "Ikeja Lagos",
  },
  {
    name: "Victory Court Limte..",
    price: "N18,000",
    img: useB,
    locstion: "Ikeja Lagos",
  },
  {
    name: "Victory Court Limte..",
    price: "N18,000",
    img: useC,
    locstion: "Ikeja Lagos",
  },
  {
    name: "Victory Court Limte..",
    price: "N18,000",
    img: useD,
    locstion: "Ikeja Lagos",
  },
];
const testData2 = [
  {
    name: "London court",
    price: "$200",
    img: useA,
    locstion: "United Kingdom",
  },
  {
    name: "London court",
    price: "$200",
    img: useD,
    locstion: "United Kingdom",
  },
  {
    name: "London court",
    price: "$200",
    img: useB,
    locstion: "United Kingdom",
  },
  {
    name: "London court",
    price: "$200",
    img: useC,
    locstion: "United Kingdom",
  },
];
const MoreCourt = () => {
  const navigate = useNavigate();
  const [present, setPresent] = useState(1);

  return (
    <>
      {/* <div className=" flex-col lg:flex hidden mt-6 px-4 justify-center h-screen w-1/2 mx-auto">
        <h1 className="font-bold text-customBlack text-xl text-center mb-2 ">
          Oops, this page is only designed for mobile devices.
        </h1>
        <p className="text-neutral-700 text-base  ">
          Please switch to a smaller screen, such as a smartphone or tablet, to
          enjoy this feature. We appreciate your cooperation.
        </p>
      </div> */}
      <div className="w-full flex flex-col px-4 py-6 bg-[#f5f5f5] min-h-screen md:max-w-[428px] md:mx-auto md:h-screen">
        <div className="flex justify-between mt-6 items-center">
          <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
          <p className="text-[#303030] font-bold text-[15px] ">Find a Court</p>
          <div className="more-shadow opacity-0">
            <More />
          </div>
        </div>

        {/* START OF CHANGER*/}
        <div className="flex justify-between items-center space-x-4 mt-6 mb-8">
          <div
            onClick={() => setPresent(1)}
            className={`w-1/2 rounded-[16px] h-[45px] flex items-center justify-center ${
              present === 1
                ? "text-white text-[12px] bg-[#FFA800] font-bold"
                : "bg-[#FFEECC] text-[#FFA800] text-[12px]"
            }`}
          >
            Reserved Court
          </div>
          <div
            onClick={() => setPresent(2)}
            className={`w-1/2 rounded-[16px] h-[45px] flex items-center justify-center ${
              present === 2
                ? "text-white text-[12px]  bg-[#FFA800] font-bold"
                : "bg-[#FFEECC] text-[#FFA800] text-[12px]"
            }`}
          >
            Pending Reservation
          </div>
        </div>
        {/* END OF CHANGER */}

        {present === 1 && (
          <div className="grid grid-cols-2 gap-6">
            {testData.map((item, index) => {
              return (
                <div className="flex flex-col" key={index}>
                  <img
                    src={item.img}
                    alt="it"
                    className="w-full h-[121px] rounded-t-[20px]"
                  />
                  <div className="flex flex-col bg-white rounded-b-[20px] p-4 ">
                    <p className="truncate text-[#263238] text-[12px] ">
                      {item.name}
                    </p>
                    <p className="truncate text-[#263238] text-[12px] ">
                      {item.locstion}
                    </p>
                    <p className="truncate text-[#263238] text-[14px] font-bold ">
                      {item.price}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {present === 2 && (
          <div className="grid grid-cols-2 gap-6">
            {testData2.map((item, index) => {
              return (
                <div className="flex flex-col" key={index}>
                  <img
                    src={item.img}
                    alt="it"
                    className="w-full h-[121px] rounded-t-[20px]"
                  />
                  <div className="flex flex-col bg-white rounded-b-[20px] p-4 ">
                    <p className="truncate text-[#263238] text-[12px] ">
                      {item.name}
                    </p>
                    <p className="truncate text-[#263238] text-[12px] ">
                      {item.locstion}
                    </p>
                    <p className="truncate text-[#263238] text-[14px] font-bold ">
                      {item.price}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default MoreCourt;
