import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { convertDateTime, sortByDateField } from "../../utils";

import { useEffect, useState } from "react";
import { getInvitationsUser } from "../../features/auth/ladder-slice";
import LoadingComponent from "../../components/Loading/Loader";
const styles = {
  active:
    "bg-white text-sm text-customBlack font-[600] full flex items-center justify-center cursor-pointer h-[43px] active-t-btn border-[#0000000A] border-[1px]  ",
  inactive:
    "text-sm text-[#afafaf] font-semibold flex items-center justify-center w-full  h-[25px] cursor-pointer",
};

interface FormattedDateTime {
  date: string;
  time: string;
}

const AllChallengeInvitations = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userSlice = useAppSelector((state) => state.auth);
  const ladder = useAppSelector((state) => state.ladder);
  useEffect(() => {
    dispatch(getInvitationsUser());
  }, []);

  const [current, setCurrent] = useState("pending");
  const [pendingList, setPendingList] = useState([]);
  const [sentList, setSentList] = useState([]);
  const [acceptedList, setAcceptedList] = useState([]);
  const [allSentInvites, setAllSentInvites] = useState([]);
  const [allPendingInvites, setAllPendingInvites] = useState([]);
  const [allAcceptedInvites, setAllAcceptedInvites] = useState([]);

  let allMatches = userSlice?.myMatches;
  const sortedArray = sortByDateField(allMatches, {
    field: "time",
  });
  useEffect(() => {
    if (userSlice.userData.success === false || undefined) {
      navigate("/login");
    }
  }, [userSlice.userData]);
  useEffect(() => {
    if (ladder?.allInvitations?.length) {
      const pendingInvite = ladder?.allInvitations?.filter(
        (e: any) =>
          e.invitation === "pending" &&
          e.challenger?._id !== userSlice?.userData?.user?._id
      );
      setPendingList(pendingInvite);
      const sentInvites = ladder?.allInvitations?.filter(
        (e: any) => e.challenger?._id === userSlice?.userData?.user?._id
      );

      setSentList(sentInvites);
      const acceptedInvite = ladder?.allInvitations?.filter(
        (e: any) => e.invitation === "accepted"
      );
      setAcceptedList(acceptedInvite);
    }
  }, [ladder?.allInvitations]);

  const sortedPending = sortByDateField(pendingList, {
    field: "time",
  });
  const sortedAccepted = sortByDateField(acceptedList, {
    field: "time",
  });
  const sortedSent = sortByDateField(sentList, {
    field: "time",
  });

  useEffect(() => {
    const sentInvites: any = [];
    const pendingInvites: any = [];
    const acceptedInvites: any = [];

    sortedSent.forEach((match: any) => {
      const tournamentName = match._tournament.name;
      let tournament = sentInvites.find((t: any) => t.name === tournamentName);

      if (!tournament) {
        tournament = { name: tournamentName, matches: [] };
        sentInvites.push(tournament);
      }

      tournament.matches.push(match);
    });
    sortedPending.forEach((match: any) => {
      const tournamentName = match._tournament.name;
      let tournament = pendingInvites.find(
        (t: any) => t.name === tournamentName
      );

      if (!tournament) {
        tournament = { name: tournamentName, matches: [] };
        pendingInvites.push(tournament);
      }

      tournament.matches.push(match);
    });
    sortedAccepted.forEach((match: any) => {
      const tournamentName = match._tournament.name;
      let tournament = acceptedInvites.find(
        (t: any) => t.name === tournamentName
      );

      if (!tournament) {
        tournament = { name: tournamentName, matches: [] };
        acceptedInvites.push(tournament);
      }

      tournament.matches.push(match);
    });

    setAllSentInvites(sentInvites);
    setAllPendingInvites(pendingInvites);
    setAllAcceptedInvites(acceptedInvites);
  }, [ladder?.allInvitations]);

  if (ladder?.fetchLoading) {
    return (
      <div className="px-4 mt-8 mx-auto md:max-w-[428px] ">
        <LoadingComponent />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col px-4 py-6 md:max-w-[428px] md:mx-auto md:h-screen">
      <div className="flex justify-between  items-center">
        <IoIosArrowBack
          className="text-2xl"
          onClick={() => navigate("/clubs")}
        />
        <p className="text-[#303030] font-bold text-[15px] ">
          All Challenge Invitations
        </p>
        <div className="more-shadow opacity-0"></div>
      </div>
      <div className="flex flex-row w-full tounament-header-bg  h-[47px] items-center px-1 mt-[30px]  ">
        <div className="w-1/3" onClick={() => setCurrent("pending")}>
          <p
            className={current === "pending" ? styles.active : styles.inactive}
          >
            Pending
          </p>
        </div>
        <div className="w-1/3 " onClick={() => setCurrent("accepted")}>
          <p
            className={
              current === "accepted"
                ? styles.active
                : ` ${styles.inactive} border-r border-r-[#0000000A] border-l border-l-[#0000000A] `
            }
          >
            Accepted
          </p>
        </div>
        <div className="w-1/3" onClick={() => setCurrent("sent")}>
          <p className={current === "sent" ? styles.active : styles.inactive}>
            Sent
          </p>
        </div>
      </div>

      <div className="flex flex-col mt-8 space-y-3">
        {/* <p className="text-[#acacac] text-[12px] ">Scores</p> */}
        {/*  start of  pending list */}
        {current === "pending" && (
          <div className="flex flex-col space-y-3">
            {allPendingInvites?.length === 0 ? (
              <h1 className="text-center text-neutral-700 text-base mt-[30px] ">
                You dont have any pending challenge !
              </h1>
            ) : (
              <div className=" flex flex-col space-y-3">
                {allPendingInvites?.map((item: any, index: number) => {
                  return (
                    <div className="w-full flex flex-col" key={index}>
                      <p className="text-customBlack text-sm font-semibold capitalize mb-3">
                        {item?.name}
                      </p>
                      <div className="w-full border-border border rounded-[16px] p-4 pb-3 mb-3 ">
                        {item?.matches?.map((match: any, index: number) => {
                          const {
                            challenged,
                            challenger,

                            _id,
                          } = match;
                          const realDayTime = convertDateTime(match?.time);
                          return (
                            <div
                              className="flex  flex-col w-full border-b border-b-[#F4F4F4] cursor-pointer last:border-none py-3 "
                              key={index}
                              onClick={() =>
                                navigate(`/pending-ladder-invite/${_id}`)
                              }
                            >
                              <div className="flex justify-center">
                                <div className="flex justify-between items-center gap-4 w-full">
                                  <div className="flex-grow  w-6/12">
                                    <p className="text-sm font-bold text-customBlack capitalize text-right">
                                      {/* {challenger?.firstname !== null &&
                              challenger?.firstname
                                ? `${challenger?.firstname} ${challenger?.lastname}`
                                : ""} */}
                                      {challenger?.firstname &&
                                        challenger?.firstname}{" "}
                                      {challenger?.lastname &&
                                        challenger?.lastname}
                                    </p>
                                    <p className="text-xs  font-medium text-customBlack  text-right">
                                      Pts:{" "}
                                      {challenger?.rank
                                        ? challenger?.rank
                                        : challenger?.rank}
                                    </p>
                                  </div>
                                  <div className="w-[60px] min-w-[60px] max-w-[60px] items-center flex flex-col justify-center">
                                    <p className="text-base font-bold text-primaryYellow uppercase">
                                      {match?.time
                                        ? realDayTime?.otherTime
                                        : "Nil"}
                                    </p>
                                    <p className="text-xs font-bold text-primaryYellow uppercase">
                                      {match?.time
                                        ? realDayTime?.otherDate
                                        : "Nil"}
                                    </p>
                                  </div>
                                  <div className="flex-grow w-6/12">
                                    <p className="text-sm font-bold text-customBlack capitalize">
                                      {challenged?.firstname &&
                                        challenged?.firstname}{" "}
                                      {challenged?.lastname &&
                                        challenged?.lastname}
                                    </p>
                                    <p className="text-xs  font-medium text-customBlack">
                                      Pts:{" "}
                                      {challenged?.rank
                                        ? challenged?.rank
                                        : challenged?.rank}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        {/* start of a single */}

                        {/* end of a single */}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
        {/* end of pending list */}
        {/*  start of  accepted list */}
        {current === "accepted" && (
          <div className="flex flex-col space-y-3">
            {allAcceptedInvites?.length === 0 ? (
              <h1 className="text-center text-neutral-700 text-base mt-[30px] ">
                You dont have any accepted challenge !
              </h1>
            ) : (
              <div className=" flex flex-col space-y-3">
                {allAcceptedInvites?.map((item: any, index: number) => {
                  return (
                    <div className="w-full flex flex-col" key={index}>
                      <p className="text-customBlack text-sm font-semibold capitalize mb-3">
                        {item?.name}
                      </p>
                      <div className="w-full border-border border rounded-[16px] p-4 pb-3 mb-3 ">
                        {item?.matches?.map((match: any, index: number) => {
                          const {
                            challenged,
                            challenger,

                            _id,
                          } = match;
                          const realDayTime = convertDateTime(match?.time);
                          return (
                            <div
                              className="flex  flex-col w-full border-b border-b-[#F4F4F4] last:border-none py-3 "
                              key={index}
                              onClick={() =>
                                navigate(`/accepted-ladder-invite/${_id}`)
                              }
                            >
                              <div className="flex justify-center">
                                <div className="flex justify-between items-center gap-4 w-full">
                                  <div className="flex-grow  w-6/12">
                                    <p className="text-sm font-bold text-customBlack capitalize text-right">
                                      {/* {challenger?.firstname !== null &&
                              challenger?.firstname
                                ? `${challenger?.firstname} ${challenger?.lastname}`
                                : ""} */}
                                      {challenger?.firstname &&
                                        challenger?.firstname}{" "}
                                      {challenger?.lastname &&
                                        challenger?.lastname}
                                    </p>
                                    <p className="text-xs  font-medium text-customBlack  text-right">
                                      Pts:{" "}
                                      {challenger?.rank
                                        ? challenger?.rank
                                        : challenger?.rank}
                                    </p>
                                  </div>
                                  <div className="w-[60px] min-w-[60px] max-w-[60px] items-center flex flex-col justify-center">
                                    <p className="text-base font-bold text-primaryYellow uppercase">
                                      {match?.time
                                        ? realDayTime?.otherTime
                                        : "Nil"}
                                    </p>
                                    <p className="text-xs font-bold text-primaryYellow uppercase">
                                      {match?.time
                                        ? realDayTime?.otherDate
                                        : "Nil"}
                                    </p>
                                  </div>
                                  <div className="flex-grow w-6/12">
                                    <p className="text-sm font-bold text-customBlack capitalize">
                                      {challenged?.firstname &&
                                        challenged?.firstname}{" "}
                                      {challenged?.lastname &&
                                        challenged?.lastname}
                                    </p>
                                    <p className="text-xs  font-medium text-customBlack">
                                      Pts:{" "}
                                      {challenged?.rank
                                        ? challenged?.rank
                                        : challenged?.rank}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        {/* start of a single */}

                        {/* end of a single */}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
        {/* end of accepted list */}
        {/*  start of  accepted list */}
        {current === "sent" && (
          <div className="flex flex-col space-y-3">
            {allSentInvites?.length === 0 ? (
              <h1 className="text-center text-neutral-700 text-base mt-[30px] ">
                You dont have any sent challenge !
              </h1>
            ) : (
              <div className=" flex flex-col space-y-3">
                {allSentInvites?.map((item: any, index: number) => {
                  return (
                    <div className="w-full flex flex-col" key={index}>
                      <p className="text-customBlack text-sm font-semibold capitalize mb-3">
                        {item?.name}
                      </p>
                      <div className="w-full border-border border rounded-[16px] p-4 pb-3 mb-3 ">
                        {item?.matches?.map((match: any, index: number) => {
                          const {
                            challenged,
                            challenger,

                            _id,
                          } = match;
                          const realDayTime = convertDateTime(match?.time);
                          return (
                            <div
                              className="flex  flex-col w-full border-b border-b-[#F4F4F4] last:border-none py-3 "
                              key={index}
                              onClick={() =>
                                navigate(`/sent-ladder-invite/${_id}`)
                              }
                            >
                              <div className="flex justify-center">
                                <div className="flex justify-between items-center gap-4 w-full">
                                  <div className="flex-grow  w-6/12">
                                    <p className="text-sm font-bold text-customBlack capitalize text-right">
                                      {/* {challenger?.firstname !== null &&
                              challenger?.firstname
                                ? `${challenger?.firstname} ${challenger?.lastname}`
                                : ""} */}
                                      {challenger?.firstname &&
                                        challenger?.firstname}{" "}
                                      {challenger?.lastname &&
                                        challenger?.lastname}
                                    </p>
                                    <p className="text-xs  font-medium text-customBlack  text-right">
                                      Pts:{" "}
                                      {challenger?.rank
                                        ? challenger?.rank
                                        : challenger?.rank}
                                    </p>
                                  </div>
                                  <div className="w-[60px] min-w-[60px] max-w-[60px] items-center flex flex-col justify-center">
                                    <p className="text-base font-bold text-primaryYellow uppercase">
                                      {match?.time
                                        ? realDayTime?.otherTime
                                        : "Nil"}
                                    </p>
                                    <p className="text-xs font-bold text-primaryYellow uppercase">
                                      {match?.time
                                        ? realDayTime?.otherDate
                                        : "Nil"}
                                    </p>
                                  </div>
                                  <div className="flex-grow w-6/12">
                                    <p className="text-sm font-bold text-customBlack capitalize">
                                      {challenged?.firstname &&
                                        challenged?.firstname}{" "}
                                      {challenged?.lastname &&
                                        challenged?.lastname}
                                    </p>
                                    <p className="text-xs  font-medium text-customBlack">
                                      Pts:{" "}
                                      {challenged?.rank
                                        ? challenged?.rank
                                        : challenged?.rank}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        {/* start of a single */}

                        {/* end of a single */}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
        {/* end of accepted list */}
      </div>
    </div>
  );
};

export default AllChallengeInvitations;
