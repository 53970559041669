import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdminAuthLayout from "../../../Layouts/AdminAuthLayout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import logo from "../../../assets/svgs/love-logo.svg";
import { Btn } from "../../../components/Button";
import { AdminInput } from "../../../components/Input";
import { AuthSubTitle, AuthTitle } from "../../../components/Text";
import UploadPhoto from "../../../components/upload/UploadImage";
import { createClub, resetClubSuccess } from "../../../features/auth/AdminAuth";
const CreateClubs = () => {
  const navigate = useNavigate();
  const adminAuth = useAppSelector((state) => state.adminAuth);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(resetClubSuccess());
  }, []);

  const [name, setName] = useState("");
  const [bio, setBio] = useState("");
  const [address, setAddress] = useState("");
  const [states, setStates] = useState("");
  const [country, setCountry] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const [active, setActive] = useState(false);

  useEffect(() => {
    if (name && bio && states && country && address) setActive(true);
    else setActive(false);
  }, [name, bio, states, address, country]);

  const handleSubmit = () => {
    const data = {
      name: name,
      bio: bio,
      state: states,
      country: country,
      address: address,
      image: imageUrl
        ? imageUrl
        : "https://cdn-icons-png.flaticon.com/512/1077/1077114.png",
      thumbnail: imageUrl
        ? imageUrl
        : "https://cdn-icons-png.flaticon.com/512/1077/1077114.png",
    };
    dispatch(createClub(data));
  };

  useEffect(() => {
    if (adminAuth.createClubSuccess) {
      toast.success("Club Created Successfully");

      navigate("/admin/login");
    }
  }, [adminAuth.createClubSuccess]);

  return (
    <div className="w-full ">
      <AdminAuthLayout>
        <div className="w-full flex min-h-screen flow-hide justify-center relative ">
          {/* left side  */}
          <div className="w-1/2 bg-white flex flex-col flow-hide">
            <div className="mx-auto mt-8">
              <img src={logo} alt="logo" className="h-auto" />
            </div>
            <div className="flex flex-col justify-center items-center mt-8">
              <AuthTitle text="Club Details" className="text-start" />
              <AuthSubTitle
                text="Fill in the details below to create an account"
                className="mt-[10px] text-start"
              />
              <div className="flex flex-col lg:w-8/12 mx-auto xl:w-7/12 2xl:w-7/12 mt-10 space-y-4">
                <AdminInput
                  placeholder="Enter Club Name"
                  onChange={(e: any) => setName(e.target.value)}
                  label="Name of Your Club"
                  required
                />
                <div className="flex space-y-2 flex-col">
                  <label className="text-neutral-400 text-sm m ">
                    Bio of the Club
                  </label>
                  <textarea
                    name=""
                    id=""
                    onChange={(e: any) => setBio(e.target.value)}
                    className="h-[89px] border-border border rounded-[5px] placeholder:text-[#afafaf] p-3 w-full "
                    placeholder="Write something.."
                  ></textarea>
                </div>
                <AdminInput
                  placeholder="Address"
                  onChange={(e: any) => setAddress(e.target.value)}
                  label="Address"
                  required
                />
                <div className="flex space-x-4 items-center">
                  <AdminInput
                    placeholder="Enter State"
                    onChange={(e: any) => setStates(e.target.value)}
                    label="State"
                    required
                  />
                  <AdminInput
                    placeholder="Country"
                    onChange={(e: any) => setCountry(e.target.value)}
                    label="Country"
                    required
                  />
                </div>
                <div className="flex flex-col mt-4">
                  <label className="text-neutral-400 text-sm mb-1 ">
                    Upload club Thumbnail
                  </label>
                  <div className="div">
                    <UploadPhoto getFileUrl={setImageUrl} />
                  </div>
                  {/* <NormalInput
                    placeholder="Enter your password"
                    classes="mb-8 bg-white border border-border"
                    required
                    type="file"
                    onChange={(e: any) => setImage(e.target.files)}
                  /> */}
                </div>

                {/* button session */}
                <div className="mt-20 relative">
                  <Btn
                    name="Continue"
                    className="w-full mt-[220px] mb-20"
                    onClick={handleSubmit}
                    active={active}
                    loading={adminAuth.loading}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* end ofleft side  */}
          {/* right side  */}
          {/* <div className={`w-1/2 ${stages[current]} relative`}>
            <div className="absolute bottom-28 w-full flex justify-center left-0 right-0 px-16">
              <GlassProgress onClick={handleNext} current={current} />
            </div>
          </div> */}
          {/* end of the right hand side */}
        </div>
      </AdminAuthLayout>
    </div>
  );
};

export default CreateClubs;
