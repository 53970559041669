type DropdownProps = {
  setNumberPerPage: any;
  numberPerPage: number;
};
const DropDownMenu = ({ setNumberPerPage, numberPerPage }: DropdownProps) => {
  const handleItemsPerPageChange = (e: any) => {
    // setItemsPerPage(Number(e.target.value));
    setNumberPerPage(e.target.value);
  };

  const options = [10, 20, 41];

  return (
    <div>
      <label htmlFor="itemsPerPage" className="p-4">
        Items per Page:
      </label>
      <select
        id="itemsPerPage"
        value={numberPerPage}
        onChange={handleItemsPerPageChange}
        className="px-4 py-2 border rounded-md focus:outline-none focus:border-lemonGreen-500 cursor-pointer"
      >
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};
export default DropDownMenu;
