import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SuperAdminLayout from "../../Layouts/SuperAdminLayout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import addCircle from "../../assets/svgs/add-circle.svg";
import DashboardOverview from "../../components/Dashboard/DashboardOverview";
import SuperAdminTable from "../../components/Tables/AdminTables/SuperAdminTable";
import {
  getAllAdmin,
  getAllClub,
  getAllUsers,
} from "../../features/auth/SuperAdminSlice";
const SuperAdminHome = () => {
  const naviagte = useNavigate();
  const dispatch = useAppDispatch();
  const adminState = useAppSelector((state) => state.superAdminAuth);

  useEffect(() => {
    dispatch(getAllAdmin());
    dispatch(getAllUsers());
    dispatch(getAllClub());
  }, []);

  const { clubs, admin, users, courts } = adminState?.allUserAdmin;
  return (
    <SuperAdminLayout current={1}>
      <div className="w-full flex flex-col bg-[#f4f4f4] p-[30px] 2xl:p-8 ">
        <div className="w-full flex justify-between items-center">
          <h1 className="text-lg font-[600] text-customBlack">Dashboard</h1>
          <div
            className="flex items-center space-x-2 cursor-pointer bg-white p-[10px] rounded-[5px]"
            onClick={() => naviagte("/super/add")}
          >
            <img src={addCircle} alt="sd" />

            <p className="text-sm text-customBlack font-[500]">Add New Admin</p>
          </div>
        </div>
        {/* GENERAL SPLITER */}
        <div className="flex space-x-10 mt-6">
          {/* START OF LEFT @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@22 */}
          <div className="w-full flex flex-col">
            <div className="w-full 2xl:w-11/12 mb-8">
              <DashboardOverview
                courtNo={courts}
                usertNo={users}
                clubNo={clubs}
                adminNo={admin}
              />
            </div>
            <SuperAdminTable className={""} data={adminState?.allAdmin} />
          </div>
          {/* END OF LEFT  ########################################################33*/}

          {/* END OF GENERAL SPLITER */}
        </div>

        {/* end of first session */}
        {/* <div className="mt-6 w-full">
          <HistoryTable />
        </div> */}
      </div>
    </SuperAdminLayout>
  );
};

export default SuperAdminHome;
