import receipt from "../../../assets/svgs/receipt.svg";

const PlayerRankingTables = () => {
  return (
    <div className="w-full flex flex-col bg-white rounded-[20px] h-[350px] ">
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className="font-inter font-semibold text-14 leading-17 tracking-tighter text-left w-110 h-17 top-22 left-16 text-sm text-customBlack">
          Player Rankings
        </h1>
        {/* <p className="text-primaryYellow text-sm font-[500]">View All</p> */}
      </div>
      <div className=" w-368 h-35 top-51 opacity-30 bg-[#F4F4F4] w-full h-[35px] flex pl-4 justify-between items-center">
        <div className="flex items-center space-x-[17px] w-7/12 ">
          <p className="w-22 h-15 top-61 left-16 font-inter font-medium text-12 leading-15 tracking-tighter text-left text-[#5E5E5E] text-xs">
            S/N
          </p>
          <p className="w-34 h-15 top-61 left-55 font-inter font-medium text-12 leading-15 tracking-tighter text-left text-[#5E5E5E] text-xs">
            Name
          </p>
        </div>
        <div className="grid grid-cols-5 w-5/12 h-[35px] items-center">
          <p className="w-34 h-15 top-61 left-55 font-inter font-medium text-12 leading-15 tracking-tighter  text-[#5E5E5E] text-center ext-neutral-600 text-xs ">
            P
          </p>
          <p className="w-12 h-15 top-61 left-279 font-inter font-medium text-12 leading-15 tracking-tighter text-[#5E5E5E] text-lefttext-neutral-600 text-xs ">
            W
          </p>
          <p className="w-7 h-15 top-61 left-309 font-inter font-medium text-12 leading-15 tracking-tighter text-lefttext-neutral-600 text-xs text-[#5E5E5E]">
            L
          </p>
          <p className="w-25 h-15 top-61 left-300 font-inter font-medium text-12 leading-15 tracking-tighter text-lefttext-neutral-600 text-xs text-[#5E5E5E] ">
            ENS
          </p>
        </div>
        <div>
          <p className=" w-10 h-15 font-inter font-medium text-12 leading-15 tracking-tighter text-left h-15 top-61 font-inter text-12 leading-15 text-[#5E5E5E] text-xs ">
            PTS
          </p>
        </div>
      </div>
      <div className=" mt-20 border-2.38 border-gray-600 w-38 h-38 flex items-center justify-center">
        <img
          src={receipt}
          className=" border-2.5 border-gray-600 w-38 h-38w-38 h-38"
          alt=""
        />
      </div>
      <h1 className="font-inter text-xs font-medium leading-15 tracking-wider w-106 h-15 text-center mt-8">
        No reserved court
      </h1>

      {/* <div className="flex flex-col rounded-b-[20px] bg-white">
        {
          data.map((item: any, index: number) => {
            return (
              <div
                className="w-full h-[35px] flex pl-4  justify-between items-center"
                key={index}
              >
                <div className="flex items-center space-x-[17px] w-7/12 ">
                  <p className="text-neutral-600 font-[500] text-xs">
                    {index + 1}
                  </p>
                  <p className="text-neutral-600 font-[500] text-xs flex items-center">
                    {item.winner && (
                      <span>
                        <WinnerCup />
                      </span>
                    )}
                    {item.name}{" "}
                  </p>
                </div>
                <div className="grid grid-cols-5 w-5/12 h-[35px] items-center">
                  <p className="text-neutral-600 text-xs font-[500]">
                    {item.p}
                  </p>
                  <p className="text-neutral-600 text-xs font-[500]">
                    {item.w}
                  </p>
                  <p className="text-neutral-600 text-xs font-[500]">
                    {item.l}
                  </p>
                  <p className="text-neutral-600 text-xs font-[500]">
                    {item.bns}
                  </p>
                  <p className="text-neutral-600 text-xs font-[500] w-full bg-[#FAE7CC] text-center h-full flex items-center justify-center">
                    {item.pts}
                  </p>
                </div>
              </div>
            );})
        }
      </div> */}
    </div>
  );
};

export default PlayerRankingTables;
