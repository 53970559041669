import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import receipt from "../../assets/svgs/receipt.svg";
import { capitalizeWords, convertDateFormat } from "../../utils";
import { useNavigate } from "react-router-dom";
import { getAllEvaluations } from "../../features/auth/TournamentSlice";
const RankMemberTable = () => {
  const evaluations = useAppSelector((state) => state.tournament);
  const [players, setPlayers] = useState([]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllEvaluations());
  }, []);

  useEffect(() => {
    if (evaluations?.allEvaluationsAdmin?.length > 0) {
      const filteredUsers = evaluations.allEvaluationsAdmin?.filter(
        (item: any) => item?.player?.rank === 0
      );
      setPlayers(filteredUsers);
    } else setPlayers([]);
  }, [evaluations?.allEvaluationsAdmin]);

  return (
    <div className="bg-white rounded-[20px] flex flex-col h-full mt-6 flow-hide relative pt-5 ">
      <h1 className="pl-5">All Club Members</h1>
      <table className="w-full table-auto">
        <thead>
          <tr className="w-full bg-[#f4f4f4] bg-opacity-30 flex item-center h-[35px] pl-5">
            <th className="w-3/12 text-sm text-neutral-600 flex items-center ">
              Name of player
            </th>
            <th className="w-3/12 text-sm text-neutral-600 flex items-center">
              Email Address
            </th>
            <th className="w-3/12 text-sm text-neutral-600 flex items-center">
              Rank
            </th>
            <th className="w-3/12 text-sm text-neutral-600 flex items-center">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="w-full flex flex-col space-y-4 mt-2">
          {!players?.length && (
            <tr>
              <td className="w-full flex flex-col items-center justify-center">
                <div className="mt-10 border-2.38 border-gray-600  flex flex-col items-center">
                  <img
                    src={receipt}
                    className="border-2.5 border-gray-600 w-38 h-38"
                    alt="receipt"
                  />
                  <h1 className="font-inter text-sm font-medium leading-15 tracking-wider w-106 h-15 text-center mt-5">
                    No Members Yet
                  </h1>
                </div>
              </td>
            </tr>
          )}
          {players?.map((item: any, index: number) => {
            return <SingleMember item={item} key={index} index={index} />;
          })}
        </tbody>
      </table>
      <div className="w-full h-[36px] items-center flex justify-between pl-4 pr-8 mb-0 mt-3">
        {/* pagination */}
        {/* <div className="flex items-center">
          <p className="text-sm">Page</p>
          <p className="px-1 rounded-sm border border-border text-customBlack font-bold cursor-pointer text-sm mx-2 ">
            1
          </p>
          <p className="text-sm text-customBlack">of 2</p>
          <div className="space-x-2 items-center ml-8 flex">
            <span className="text-base px-1 bg-[#f4f4f4] text-[#cfcfcf] opacity-70 rounded-sm border border-border">
              <IoIosArrowBack />
            </span>
            <span className="text-base px-1 bg-[#f4f4f4] text-customBlack opacity-70 rounded-sm border border-border">
              <IoIosArrowForward />
            </span>
          </div>
        </div> */}
        {/* enteries */}
        {/* <div className="flex">
          <p>No of entries </p>
          <div>
            <p className="px-1 rounded-sm border border-border text-customBlack font-bold cursor-pointer text-sm mx-2 ">
              10 per page
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default RankMemberTable;

const SingleMember = ({ item, index }: any) => {
  const [openModal, setOpenModal] = useState(false);
  const admin = useAppSelector((state) => state.adminAuth);
  const clubName = admin.clubDeatails?.name ? admin.clubDeatails?.name : "";
  const { player } = item;
  const navigate = useNavigate();

  const {
    _id,
    email,
    firstname,
    request,
    lastname,
    image,
    rank,

    account_verify,
    dateOfBirth,
  } = player;

  let passImage = image
    ? image
    : "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";

  return (
    <>
      <tr key={index} className="w-full flex items-center pl-5">
        <td
          className="w-3/12 flex items-center space-x-2 cursor-pointer"
          onClick={() => setOpenModal(true)}
        >
          <img
            src={
              image
                ? image
                : "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            }
            alt="sample"
            className="w-[28px] h-[28px] rounded-full"
          />
          <p className="text-sm text-customBlack font-[500]">
            {capitalizeWords(
              `${firstname} ${lastname !== null ? lastname : " "}`
            )}
          </p>
        </td>
        <td className="w-3/12 text-sm text-customBlack font-[500]">{email}</td>
        <td className="w-3/12 text-sm text-customBlack font-[500]">{rank}</td>
        <td className="w-3/12 text-sm text-[#FFA800] font-[500]">
          <p
            className="underline cursor-pointer"
            onClick={() => navigate(`/admin/evaluate-player/${_id}`)}
          >
            Evaluate Player
          </p>
        </td>
      </tr>
    </>
  );
};
