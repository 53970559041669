import { ChangeEvent, useState } from "react";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { TbLock } from "react-icons/tb";
import { Padlock } from "../../assets/svgs/Icons";
import calender from "../../assets/svgs/calendar.svg";
import { EmailLogo, ProfileIcon } from "../../assets/svgs";

interface inputProps {
  name?: string;
  placeholder?: string;
  classes?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  label?: string;
  required: boolean;
}

export function LoginInput({ placeholder, onChange, required }: inputProps) {
  return (
    <div className="mt-1 relative rounded-md shadow-sm bg-[#f4f4f4] h-[50px] flex items-center">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none pr-4">
        <BsFillEnvelopeFill
          className="h-5 w-5 text-[#C6C6C6]"
          aria-hidden="true"
        />
      </div>
      <input
        type="email"
        name="email"
        onChange={onChange}
        id="email"
        className="focus:ring-[#C6C6C6] focus:border-none outline-none  block w-full pl-10 sm:text-sm border-none bg-inherit rounded-md"
        placeholder={placeholder}
        required={required}
      />
    </div>
  );
}

export const NormalInput = ({
  name,
  placeholder,
  classes,
  onChange,
  type,
  required,
}: inputProps) => {
  return (
    <input
      className={`${classes} w-full  placeholder:text-[#7A7978] rounded-[12px] py-[18px] px-[24px] h-[50px]  bg-[#F4F4F4] text-[12px] ${
        type ? "opacity-80" : ""
      } `}
      type={type ? type : "text"}
      name={name}
      autoComplete="off"
      onChange={onChange}
      placeholder={placeholder}
      required={required}
    />
  );
};
export const EmailInput = ({
  placeholder,
  classes,
  required,
  onChange,
}: inputProps) => {
  return (
    <div
      className={`flex items-center space-x-3 rounded-[8px] bg-neutral-200 h-[50px] py-[13px] px-[24px] ${classes}`}
    >
      <EmailLogo />
      <input
        type="text"
        className="text-sm bg-inherit outline-none border-none focus:border-none focus:outline-none placeholder:text-[#afafaf] placeholder:text-sm flex-grow "
        placeholder={placeholder}
        onChange={onChange}
        required={required}
      />
    </div>
  );
};
export const NameInput = ({
  placeholder,
  classes,
  onChange,
  required,
}: inputProps) => {
  return (
    <div
      className={`flex items-center space-x-3 rounded-[8px] bg-neutral-200 h-[50px] py-[13px] px-[24px] ${classes}`}
    >
      <ProfileIcon />
      <input
        type="text"
        className="text-sm bg-inherit outline-none border-none focus:border-none focus:outline-none placeholder:text-[#afafaf] placeholder:text-sm flex-grow "
        placeholder={placeholder}
        onChange={onChange}
        required={required}
      />
    </div>
  );
};
export const CalenderInput = ({
  placeholder,
  classes,
  onChange,
  required,
}: inputProps) => {
  return (
    <div
      className={`flex items-center space-x-3 rounded-[8px] bg-neutral-200 h-[50px] py-[13px] px-[24px] ${classes}`}
    >
      <img src={calender} alt="calender" className="w-fit h-auto" />
      <input
        type="date"
        className="text-sm bg-inherit outline-none border-none focus:border-none focus:outline-none placeholder:text-[#afafaf] placeholder:text-sm flex-grow "
        placeholder={placeholder}
        onChange={onChange}
        required={required}
      />
    </div>
  );
};
export const AdminCalenderInput = ({
  placeholder,
  classes,
  onChange,
  required,
}: inputProps) => {
  return (
    <div
      className={`flex items-center  rounded-[8px] bg-white border border-border h-[50px] py-[13px] px-[24px] relative ${classes}`}
    >
      <input
        type="date"
        className="text-sm bg-white outline-none border-none focus:border-none focus:outline-none placeholder:text-[#afafaf] placeholder:text-sm flex-grow placeholder:uppercase uppercase text-[#afafaf] "
        placeholder={placeholder}
        onChange={onChange}
        required={required}
      />
    </div>
  );
};
export const NewPasswordInput = ({
  placeholder,
  required,
  classes,
  onChange,
}: inputProps) => {
  const [show, setShow] = useState(false);
  return (
    <div
      className={`flex items-center space-x-3 rounded-[8px] bg-neutral-200 h-[50px] py-[13px] px-[24px] ${classes}`}
    >
      <Padlock />
      <input
        type={show ? "text" : "password"}
        className="text-sm bg-inherit outline-none border-none focus:border-none focus:outline-none placeholder:text-[#afafaf] placeholder:text-sm flex-grow "
        placeholder={placeholder}
        onChange={onChange}
        required={required}
      />
      {show ? (
        <AiFillEyeInvisible
          onClick={() => setShow(false)}
          className="text-[#AFAFAF] cursor-pointer text-2xl"
        />
      ) : (
        <AiOutlineEye
          onClick={() => setShow(true)}
          className="text-[#AFAFAF] cursor-pointer text-2xl"
        />
      )}
    </div>
  );
};
export const AdminPasswordInput = ({
  placeholder,
  required,
  classes,
  onChange,
}: inputProps) => {
  const [show, setShow] = useState(false);
  return (
    <div
      className={`flex items-center space-x-3 rounded-[5px] bg-white h-[50px] py-[13px] px-[24px] border border-border ${classes}`}
    >
      <input
        type={show ? "text" : "password"}
        className="text-sm bg-inherit outline-none border-none focus:border-none focus:outline-none placeholder:text-[#afafaf] placeholder:text-sm flex-grow "
        placeholder={placeholder}
        onChange={onChange}
        required={required}
      />
      {show ? (
        <AiOutlineEyeInvisible
          onClick={() => setShow(false)}
          className="text-[#AFAFAF] cursor-pointer text-2xl"
        />
      ) : (
        <AiOutlineEye
          onClick={() => setShow(true)}
          className="text-[#AFAFAF] cursor-pointer text-2xl"
        />
      )}
    </div>
  );
};
export const NormalTextArea = ({
  name,
  placeholder,
  required,
  classes,
}: inputProps) => {
  return (
    <textarea
      className={`${classes} w-full border-none placeholder:text-[#7A7978] rounded-[12px] py-[18px] px-[24px] h-[120px]  bg-[#F4F4F4] text-[12px] `}
      name={name}
      required={required}
      autoComplete="off"
      placeholder={placeholder}
    />
  );
};

export const PasswordInput = ({
  name,
  placeholder,
  classes,
  onChange,
  required,
}: inputProps) => {
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(false);
  return (
    <div
      className={`flex ${classes} items-center border ${
        active ? "border-customBlack border-[2px]" : "border-border"
      }  rounded-[10px] py-[18px] px-[16px] h-[50px] w-full bg-[#f4f4f4]
`}
      onMouseEnter={() => setActive(!active)}
    >
      <input
        className={` w-11/12  placeholder:text-placeholder  outline-none bg-inherit text-[12px] focus:bg-[#f4f4f4] `}
        type={show ? "text" : "password"}
        name={name}
        onChange={onChange}
        required={required}
        autoComplete="off"
        placeholder={placeholder}
      />
      {show ? (
        <AiFillEye
          className="text-[#C6C6C6] text-lg cursor-pointer"
          onClick={() => setShow(false)}
        />
      ) : (
        <AiFillEyeInvisible
          className="text-[#C6C6C6] text-lg cursor-pointer"
          onClick={() => setShow(true)}
        />
      )}
    </div>
  );
};

export const AdminInput = ({
  placeholder,
  required,
  classes,
  onChange,
  label,
}: inputProps) => {
  return (
    <div className={`flex w-full flex-col ${classes}`}>
      <label className="text-neutral-400 text-sm mb-1 ">{label}</label>
      <div className="border border-border rounded-[5px] w-full h-[50px] bg-white">
        <input
          type="email"
          name="email"
          onChange={onChange}
          required={required}
          id="email"
          className="focus:ring-[#C6C6C6] focus:border-none outline-none  block w-full pl-4 sm:text-sm border-none bg-inherit rounded-md h-[48px] placeholder:text-neutral-400 "
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export function LoginPassword({
  name,
  placeholder,
  required,
  classes,
  onChange,
}: inputProps) {
  const [show, setShow] = useState(false);
  return (
    <div
      className={`${classes} relative rounded-md shadow-sm bg-[#f4f4f4] h-[50px] flex items-center space-x-4`}
    >
      <div className="absolute inset-y-0 left-0 px-3 flex items-center pointer-events-none">
        <span className="text-[#c6c6c6] mr-4 ">
          <TbLock className="text-lg" />
        </span>
      </div>
      <input
        type={show ? "text" : "password"}
        name={name}
        className="focus:ring-none outline-none focus:border-none  block w-full pl-7 pr-12 sm:text-sm border-none  bg-inherit"
        placeholder={placeholder}
        onChange={onChange}
        aria-describedby="password"
        required={required}
      />
      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <span className="text-[#C6C6C6]  cursor-pointer">
          {show ? (
            <AiFillEye
              className="text-[#C6C6C6] text-lg cursor-pointer"
              onClick={() => setShow(false)}
            />
          ) : (
            <AiFillEyeInvisible
              className="text-[#C6C6C6] text-lg cursor-pointer"
              onClick={() => setShow(true)}
            />
          )}
        </span>
      </div>
    </div>
  );
}
