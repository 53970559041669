// import  { useState } from "react";
import { useState } from "react";

import { IoIosArrowDown } from "react-icons/io";
import barcode from "../../assets/svgs/barcode.svg";
import barcode2 from "../../assets/svgs/barcode2.svg";
import directboxsend from "../../assets/svgs/directboxsend.svg";
import directboxsend2 from "../../assets/svgs/directboxsend2.svg";
import tennisBall from "../../assets/svgs/tennisBall.svg";
import tennisBall2 from "../../assets/svgs/tennisBall2.svg";
import useHover from "../../components/IconComponent/useHover";

import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
// import import { TbBallTennis from 'react-icons/tb';

const ProfileDropdown = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { currentUserData } = useAppSelector((state) => state.utility);
  const { firstname, lastname, thumbnail } = currentUserData.user;

  const {
    isHovered: isHovered1,
    onMouseEnter: handleMouseEnter1,
    onMouseLeave: handleMouseLeave1,
  } = useHover();
  const {
    isHovered: isHovered2,
    onMouseEnter: handleMouseEnter2,
    onMouseLeave: handleMouseLeave2,
  } = useHover();
  const {
    isHovered: isHovered3,
    onMouseEnter: handleMouseEnter3,
    onMouseLeave: handleMouseLeave3,
  } = useHover();

  return (
    <div className="w-full  flex flex-col relative pr-4 items-end">
      <div
        className="flex items-center space-x-2 cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <span className="inline-block relative cursor-pointer">
          <img
            className="h-8 w-8 rounded-full"
            src={
              thumbnail
                ? thumbnail
                : "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            }
            alt="thumbnail"
          />
        </span>
        <p className="text-sm text-neutral-700 font-[500] capitalize">
          {lastname ? `${firstname + " " + lastname}` : `${firstname}`}
        </p>
        <IoIosArrowDown className="text-neutral-700n text-lg" />
      </div>
      {open && (
        <div
          className="absolute top-14  flex flex-col right-0 bg-white  min-h-[152px]  shadow-xl w-[245px] z-max rounded-[10px] "
          onMouseLeave={() => setOpen(false)}
        >
          <div className="h-[1px]  w-full bg-border" />
          <div className="w-full  flex flex-col ">
            {/* single items here */}
            <div
              className="flex items-center hover:bg-[#EBF6D4] py-3 px-5 gap-[10px] text-sm text-[#868686] rounded-l-[5px] hover:text-[#82B020] cursor-pointer h h-[50px] hover:rounded-t-[10px] "
              onClick={() => navigate("/admin/send-invitation/s")}
              onMouseEnter={handleMouseEnter1}
              onMouseLeave={handleMouseLeave1}
            >
              {isHovered1 ? (
                <img alt="" src={barcode2} />
              ) : (
                <img alt="" src={barcode} className="text-lg" />
              )}
              <p>Generate Invitation Code</p>
            </div>
            {/* end of a single item */}
            {/* single items here */}
            <div
              className="flex items-center hover:bg-[#EBF6D4] py-3 px-5 gap-[10px] text-sm text-[#868686] rounded-l-[5px] hover:text-[#82B020] cursor-pointer h h-[50px]  "
              onClick={() => navigate("/admin/send-invitation/s")}
              onMouseEnter={handleMouseEnter2}
              onMouseLeave={handleMouseLeave2}
            >
              {isHovered2 ? (
                <img alt="" src={directboxsend} />
              ) : (
                <img alt="" src={directboxsend2} className="text-lg" />
              )}
              <p>Send Invitation </p>
            </div>
            {/* end of a single item */}
            {/* single items here */}
            <div
              className="flex items-center hover:bg-[#EBF6D4] py-3 px-5 gap-[10px] text-sm text-[#868686] rounded-l-[5px] hover:text-[#82B020] cursor-pointer h h-[50px] hover:rounded-b-[10px] "
              onClick={() => navigate("/admin/create-tournament")}
              onMouseEnter={handleMouseEnter3}
              onMouseLeave={handleMouseLeave3}
            >
              {isHovered3 ? (
                <img alt="" src={tennisBall} />
              ) : (
                <img alt="" src={tennisBall2} className="text-lg" />
              )}
              <p>Create Tournament </p>
            </div>
            {/* end of a single item */}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileDropdown;
