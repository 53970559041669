import React, { ChangeEvent, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { IoIosArrowBack } from "react-icons/io";
import { More } from "../../assets/svgs/court";
import { useNavigate, useParams } from "react-router-dom";
import { getAllCourtUser } from "../../features/auth/ladder-slice";
import { Btn } from "../../components/Button";
import { updateMatchScore } from "../../features/auth/TournamentSlice";
import ReUseModal from "../../components/Modal/ReUseModal";
import PrimarySelect from "../../components/Selects/PrimarySelect";
import addNewSet from "../../assets/svgs/add-new-set.svg";
import {
  restoreDefault,
  scoreFriendlyMatch,
} from "../../features/auth/playerslice";
import sentMark from "../../assets/svgs/sent-mark.svg";
interface FormattedDateTime {
  date: string;
  time: string;
}

interface IPayloadData {
  id: string | undefined;
  data: {
    winner: string | undefined;
    [key: string]:
      | { challenger: string; challenged: string }
      | string
      | undefined;
  };
}
const ViewSingleSentMatch = () => {
  const user = useAppSelector((state) => state.auth);
  const ladder = useAppSelector((state) => state.ladder);
  const player = useAppSelector((state) => state.player);
  const [details, setDetails] = useState<any>({});
  const [openSuccess, setOpenSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const [court, setCourt] = useState("");

  const challenger = user?.userData?.user;

  const id = useParams();
  const item = player?.allSentInvitation?.filter(
    (item: any) => item?._id === id.id
  );
  useEffect(() => {
    dispatch(getAllCourtUser());
    setDetails(item[0]);
  }, []);

  useEffect(() => {
    if (ladder?.allCourts?.length !== 0) {
      const matchCourt = ladder?.allCourts?.filter(
        (item: any) => item?._id === details?.court
      );
      setCourt(matchCourt[0]?.name || "");
    }
  }, [ladder?.allCourts]);

  const navigate = useNavigate();

  function formatDateTime(inputDate: string): FormattedDateTime {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
    };
    const date: Date = new Date(inputDate);

    const dayMonthFormat: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
    };
    const formattedDate: string = date
      .toLocaleDateString("en-US", dayMonthFormat)
      .toUpperCase();

    const formattedTime: string = `${date.getHours()}:${
      date.getMinutes() < 10 ? "0" : ""
    }${date.getMinutes()}`;

    return { date: formattedDate, time: formattedTime };
  }
  const formattedDateTime: FormattedDateTime = formatDateTime(details?.time);
  const detailData = item[0] || {};
  const {
    challenged,
    winner,
    setA,
    setB,
    setC,
    setD,
    setE,
    challenger_score,
  }: any = detailData;

  const tournament = useAppSelector((state) => state.tournament);

  const [openEdit, setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);

  const [selected, setSelected] = useState<any>({});
  const [matchScoreSet, setMatchScoreSet] = useState<any>(
    parseInt(setC?.challenger) > 0 ? 3 : 2
  );
  const [errorMaxSet, setErrorMaxSet] = useState("");

  const listofMatchSets = Array.from(
    { length: matchScoreSet },
    (_, i) => i + 1
  );

  const selectData = [
    {
      name: challenger?.firstname,
      id: challenger?._id,
    },
    {
      name: challenged?.firstname,
      id: challenged?._id,
    },
  ];

  const [oneChallenged, setOneChallenged] = useState(
    !setA?.challenged ? "0" : `${setA?.challenged}`
  );
  const [oneChallenger, setOneChallenger] = useState(
    !setA?.challenger ? "0" : `${setA?.challenger}`
  );

  const [twoChallenged, setTwoChallenged] = useState(
    !setB?.challenged ? "0" : `${setB?.challenged}`
  );
  const [twoChallenger, setTwoChallenger] = useState(
    !setB?.challenger ? "0" : `${setB?.challenger}`
  );

  const [threeChallenged, setThreeChallenged] = useState(
    !setC?.challenged ? "0" : `${setC?.challenged}`
  );
  const [threeChallenger, setThreeChallenger] = useState(
    !setC?.challenger ? "0" : `${setC?.challenger}`
  );

  const [fourthChallenged, setFourthChallenged] = useState(
    !setD?.challenged ? "0" : `${setD?.challenged}`
  );
  const [fourthChallenger, setFourthChallenger] = useState(
    !setD?.challenger ? "0" : `${setD?.challenger}`
  );

  const [fifthChallenged, setFifthChallenged] = useState(
    !setE?.challenged ? "0" : `${setE?.challenged}`
  );
  const [fifthChallenger, setFifthChallenger] = useState(
    !setE?.challenger ? "0" : `${setE?.challenger}`
  );

  useEffect(() => {
    if (openEdit) {
      setOneChallenged(!setA?.challenged ? "0" : `${setA?.challenged}`);
      setOneChallenger(!setA?.challenger ? "0" : `${setA?.challenger}`);

      setTwoChallenged(!setB?.challenged ? "0" : `${setB?.challenged}`);
      setTwoChallenger(!setB?.challenger ? "0" : `${setB?.challenger}`);

      setThreeChallenged(!setC?.challenged ? "0" : `${setC?.challenged}`);
      setThreeChallenger(!setC?.challenger ? "0" : `${setC?.challenger}`);

      setFourthChallenged(!setD?.challenged ? "0" : `${setD?.challenged}`);
      setFourthChallenger(!setD?.challenger ? "0" : `${setD?.challenger}`);

      setFifthChallenged(!setE?.challenged ? "0" : `${setE?.challenged}`);
      setFifthChallenger(!setE?.challenger ? "0" : `${setE?.challenger}`);

      setMatchScoreSet(parseInt(setC?.challenger) > 0 ? 3 : 2);
    }
  }, [openEdit]);

  useEffect(() => {
    if (tournament.updateMatchScoreSuccess) {
      // toast.success("updateSUCCES")
      setOpenEdit(false);
    }
  }, [tournament.updateMatchScoreSuccess]);

  const UpdateScore = () => {
    const payLoaddata: IPayloadData = {
      id: id.id,
      data: {
        winner: selected?.id,
        setA: {
          challenger: oneChallenger,
          challenged: oneChallenged,
        },
        setB: {
          challenger: twoChallenger,
          challenged: twoChallenged,
        },
      },
    };
    if (threeChallenged !== "0" || threeChallenger !== "0") {
      payLoaddata.data.setC = {
        challenger: threeChallenger,
        challenged: threeChallenged,
      };
    }
    if (fourthChallenged !== "0" || fourthChallenger !== "0") {
      payLoaddata.data.setD = {
        challenger: fourthChallenger,
        challenged: fourthChallenged,
      };
    }
    if (fifthChallenged !== "0" || fifthChallenger !== "0") {
      payLoaddata.data.setE = {
        challenger: fifthChallenger,
        challenged: fifthChallenged,
      };
    }

    dispatch(scoreFriendlyMatch(payLoaddata));
  };

  useEffect(() => {
    if (player?.scoreMatchSuccess) {
      setOpenEdit(false);
      setOpenSuccess(true);
      dispatch(restoreDefault());
    }
  }, [player?.scoreMatchSuccess]);

  const addMatchSet = () => {
    if (matchScoreSet >= 5) {
      setErrorMaxSet("match set limit reached");
      setTimeout(function () {
        setErrorMaxSet("");
      }, 3000);
      return;
    }
    setMatchScoreSet(matchScoreSet + 1);
  };

  const handleChallengedOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    item: number
  ) => {
    const inputValue = e.target.value;
    if (inputValue.length > 1) {
      if (item === 1) {
        setOneChallenged(inputValue.toString().substring(0, 1));
      } else if (item === 2) {
        setTwoChallenged(inputValue.toString().substring(0, 1));
      } else if (item === 3) {
        setThreeChallenged(inputValue.toString().substring(0, 1));
      } else if (item === 4) {
        setFourthChallenged(inputValue.toString().substring(0, 1));
      } else if (item === 5) {
        setFifthChallenged(inputValue.toString().substring(0, 1));
      }
      return;
    }

    if (item === 1) {
      setOneChallenged(inputValue);
    }
    if (item === 2) {
      setTwoChallenged(inputValue);
    }
    if (item === 3) {
      setThreeChallenged(inputValue);
    }
    if (item === 4) {
      setFourthChallenged(inputValue);
    }
    if (item === 5) {
      setFifthChallenged(inputValue);
    }
  };
  const handleChallengerOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    item: number
  ) => {
    const inputValue = e.target.value;
    if (inputValue.length > 1) {
      if (item === 1) {
        setOneChallenger(inputValue.toString().substring(0, 1));
      } else if (item === 2) {
        setTwoChallenger(inputValue.toString().substring(0, 1));
      } else if (item === 3) {
        setThreeChallenger(inputValue.toString().substring(0, 1));
      } else if (item === 4) {
        setFourthChallenger(inputValue.toString().substring(0, 1));
      } else if (item === 5) {
        setFifthChallenger(inputValue.toString().substring(0, 1));
      }

      return;
    }
    if (item === 1) {
      setOneChallenger(inputValue);
    }
    if (item === 2) {
      setTwoChallenger(inputValue);
    }
    if (item === 3) {
      setThreeChallenger(inputValue);
    }
    if (item === 4) {
      setFourthChallenger(inputValue);
    }
    if (item === 5) {
      setFifthChallenger(inputValue);
    }
  };

  let scores = `${
    challenger_score?.setA?.challenger
      ? challenger_score?.setA?.challenger
      : "0"
  } - ${
    challenger_score?.setA?.challenged
      ? challenger_score?.setA?.challenged
      : "0"
  }, ${" "} ${
    challenger_score?.setB?.challenger
      ? challenger_score?.setB?.challenger
      : "0"
  } - ${
    challenger_score?.setB?.challenged
      ? challenger_score?.setB?.challenged
      : "0"
  }, ${" "} ${
    challenger_score?.setC?.challenger
      ? challenger_score?.setC?.challenger
      : "0"
  } - ${
    challenger_score?.setC?.challenged
      ? challenger_score?.setC?.challenged
      : "0"
  }`;

  return (
    <div className="w-full flex flex-col px-4 pb-10 relative h-screen">
      <div className="flex justify-between mt-6 items-center mb-8">
        <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
        <p className="text-[#303030] font-bold text-[15px] ">Match Invite</p>
        <div className="more-shadow opacity-0">
          <More />
        </div>
      </div>
      <div className="w-full bg-[#FFA800] h-[250px] rounded-[24px] p-4 flex space-x-6 justify-between">
        <div className="flex flex-col">
          <img
            src={user?.userData?.user?.image}
            alt="profile avatar"
            className="w-[120px] h-[120px] object-cover rounded-[20px]"
          />
          <p className="text-white text-base font-bold mb-2 mt-1 text-center capitalize">
            {user?.userData?.user?.firstname}
            <br />
            {user?.userData?.user?.lastname}
          </p>
          <div className="h-[29px] bg-white flex items-center justify-center px-4 rounded-3xl text-ash text-xs  ">
            {user?.userData?.user?.rank}
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-base font-bold text-white mt-8">VS</p>
        </div>
        <div className="flex flex-col">
          <img
            src={details?.challenged?.image}
            alt="profile avatar"
            className="w-[120px] h-[120px] object-cover rounded-[20px]"
          />
          <p className="text-white text-base font-bold mb-2 mt-1 text-center capitalize">
            {details?.challenged?.firstname}
            <br />
            {details?.challenged?.lastname}
          </p>
          <div className="h-[29px] bg-white flex items-center justify-center px-4 rounded-3xl text-ash text-xs  ">
            {details?.challenged?.rank}
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-8 space-y-4">
        {/* start of partl */}
        <div className="flex flex-col">
          <p className="text-sm text-customBlack font-bold mb-4">
            Match Details
          </p>
        </div>

        {/* end of a pack */}

        {/* start of partl */}
        {/* <div className="flex justify-between items-center mb-3">
          <p className="text-sm text-[#5e5e5e] font-[400] ">Match Date </p>
          <p className="text-customBlack text-sm font-bold">
            {" "}
            {formattedDateTime?.date}
          </p>
        </div> */}
        <div className="w-full min-h-[50px] flex items-center border border-[#CFCFCF] px-6 justify-between rounded-[10px] mb-3">
          <p className=" text-xs text-[#5E5E5E]">Date</p>
          <p className="text-sm font-semibold text-customBlack">
            {formattedDateTime?.date},2024
          </p>
          <p></p>
        </div>
        <div className="w-full min-h-[50px] flex items-center border border-[#CFCFCF] px-6 justify-between rounded-[10px] mb-3">
          <p className=" text-xs text-[#5E5E5E]">Time</p>
          <p className="text-sm font-semibold text-customBlack">
            {formattedDateTime.time}pm
          </p>
          <p></p>
        </div>
        <div className="w-full min-h-[50px] flex items-center border border-[#CFCFCF] px-6 justify-between rounded-[10px] mb-3">
          <p className=" text-xs text-[#5E5E5E]">Court</p>
          <p className="text-sm font-semibold text-customBlack capitalize">
            {court}
          </p>
          <p></p>
        </div>
        {/* end of a pack */}
      </div>
      <div className="mt-8 flex flex-col mb-8">
        <p className="text-sm text-customBlack font-bold mb-4">Match Score</p>

        <div className="w-full border border-[#CFCFCF] rounded-[16px] min-h-[162px] px-4 flex flex-col justify-center  ">
          <div className="w-full flex justify-between gap-2">
            <div className="w-5/12 pl-4">
              <p className="text-[10px] text-[#868686] font-semibold">Player</p>
            </div>
            <div className="w-2/12 flex justify-center">
              <p className="text-[10px] text-[#868686] font-semibold">W/L</p>
            </div>
            <div className="w-5/12">
              <p className="text-start text-[10px] text-[#868686] pl-4 font-semibold">
                Result
              </p>
            </div>
          </div>

          {/* start */}
          <div className="w-full flex gap-2 items-center justify-between mt-2 ">
            <div className="w-5/12 h-[40px] rounded-[12px] bg-[#F4F4F4] flex items-center px-4 truncate ">
              <p className="font-medium text-customBlack text-xs capitalize">
                {user?.userData?.user?.firstname}
              </p>
            </div>
            <div className="w-2/12 h-[40px] rounded-[12px] bg-[#F4F4F4] flex items-center px-4 truncate justify-center ">
              <p className="font-medium text-customBlack text-xs">
                {" "}
                <p className="font-medium text-customBlack text-xs">
                  {challenger?._id === challenger_score?.winner ? "W" : "L"}
                </p>
              </p>
            </div>
            <div className="w-5/12 h-[40px] rounded-[12px] bg-[#F4F4F4] flex items-center px-4 truncate ">
              <p className="font-medium text-customBlack text-xs">{scores}</p>
            </div>
          </div>
          {/* end */}

          {/* start */}
          <div className="w-full flex gap-2 items-center justify-between mt-2 ">
            <div className="w-5/12 h-[40px] rounded-[12px] bg-[#F4F4F4] flex items-center px-4 truncate ">
              <p className="font-medium text-customBlack text-xs capitalize">
                {details?.challenged?.firstname}
              </p>
            </div>
            <div className="w-2/12 h-[40px] rounded-[12px] bg-[#F4F4F4] flex items-center px-4 truncate justify-center ">
              <p className="font-medium text-customBlack text-xs">
                {challenger?._id !== challenger_score?.winner ? "W" : "L"}
              </p>
            </div>
            <div className="w-5/12 h-[40px] rounded-[12px] bg-[#F4F4F4] flex items-center px-4 truncate ">
              <p className="font-medium text-customBlack text-xs">{scores}</p>
            </div>
          </div>
          {/* end */}
        </div>
      </div>
      <div className=" w-full px-4 flex items-center justify-center mt-8 pb-8 ">
        <Btn onClick={() => setOpenEdit(true)} active name={"Update score"} />
      </div>
      <ReUseModal open={openEdit} setOpen={setOpenEdit}>
        <span className="w-full">
          <h1>Update Match Score</h1>
          {/* start of set */}
          <span className="border-t-2 border-neutral300-500 mt-7"></span>
          {listofMatchSets.map((item, index) => {
            return (
              <span className="flex flex-col" key={index}>
                <label
                  htmlFor=""
                  className="text-customBlack font-semibold mb-2 mt-6 text-base"
                >
                  SET {item}:
                </label>
                <span className="w-full flex flex-col space-y-4">
                  <span className="w-3/4 flex justify-between mb-2 mt-3">
                    <p className="text-base text-neutral-700 font-medium  capitalize">
                      {challenger?.lastname
                        ? challenger?.firstname + " " + challenger?.lastname
                        : challenger?.firstname}
                    </p>
                    <input
                      type="number"
                      className="w-[40px] py-1 px-2 text-sm rounded-md small-input"
                      min={1}
                      max={10}
                      value={
                        item === 1
                          ? oneChallenger
                          : item === 2
                          ? twoChallenger
                          : item === 3
                          ? threeChallenger
                          : item === 4
                          ? fourthChallenger
                          : item === 5
                          ? fifthChallenger
                          : item
                      }
                      onChange={(e) => handleChallengerOnChange(e, item)}
                    ></input>
                  </span>
                  <span className="w-3/4 flex justify-between mb-2">
                    <p className=" text-neutral-700 font-medium text-sm capitalize">
                      {challenged?.lastname
                        ? challenged?.firstname + " " + challenged?.lastname
                        : challenged?.firstname}
                    </p>
                    <input
                      type="number"
                      className="w-[40px] py-1 px-2 text-sm rounded-md small-input"
                      min={1}
                      max={10}
                      value={
                        item === 1
                          ? oneChallenged
                          : item === 2
                          ? twoChallenged
                          : item === 3
                          ? threeChallenged
                          : item === 4
                          ? fourthChallenged
                          : item === 5
                          ? fifthChallenged
                          : item
                      }
                      onChange={(e) => handleChallengedOnChange(e, item)}
                    ></input>
                  </span>
                </span>
              </span>
            );
          })}
          <span className="w-full my-5 cursor-pointer" onClick={addMatchSet}>
            <img alt="" src={addNewSet} className="object-contain h-[30px]" />
          </span>
          <p className="text-xs text-red-600">{errorMaxSet}</p>
          <span className="w-10/12 mt-6 mb-10 flex flex-col">
            <PrimarySelect
              label={"Select the  Winner"}
              selected={selected}
              setSelected={setSelected}
              data={selectData}
            />
          </span>
          <span className="w-10/12 mt-10 mx-auto">
            <Btn
              name="update"
              active={true}
              onClick={UpdateScore}
              loading={player?.loading}
            />
          </span>
        </span>
      </ReUseModal>
      <ReUseModal open={openSuccess} setOpen={setOpenSuccess}>
        <div className="w-full flex flex-col">
          <img src={sentMark} alt="sent" className="mx-auto mt-6" />
          <h1 className="text-customBlack text-[18px] font-bold text-center ">
            Match Scored
          </h1>
          <p className="text-center w-10/12 mx-auto text-neutral-500 text-sm ">
            You’ve successfully scored this match
          </p>
          <Btn
            active={true}
            name="Close"
            onClick={() => {
              setOpenSuccess(false);
              dispatch(restoreDefault());
              navigate(`/matches`);
            }}
            className="mt-[25px] mb-3"
          />
        </div>
      </ReUseModal>
    </div>
  );
};

export default ViewSingleSentMatch;
