import React from "react";
interface userTeam {
  index: number;
  isActive: boolean;
  name: string;
  points: number | string;
}
const UserTeamCard = ({ name, index, isActive, points }: userTeam) => {
  return (
    <div
      key={index}
      className={`w-full competition-card-shadow h-[59px]  rounded-[12px] flex items-center justify-between px-4 ${
        isActive ? "bg-[#F4FCED]" : "bg-white"
      }  `}
    >
      <div className="flex items-center gap-2">
        <div
          className={`w-[35px] h-[35px] rounded-full  text-base font-medium flex items-center justify-center bg-[#7BC13314] bg-opacity-10 text-[#7BC133]  leading-[24px]  `}
        >
          {index + 1}
        </div>

        <div className="flex flex-col">
          <p className="text-[14px] leading-[20px] font-medium text-customBlack p-0 m-0  capitalize">
            {name}
          </p>
        </div>
      </div>
      <div className="  w-4/12 flex justify-end  ">
        {/* start */}
        <span
          className={`h-[34px]  flex items-center justify-center border border-[#E4E4E7] rounded-[5px] px-4 ${
            isActive ? "bg-[#EAF9DA]" : "bg-[#FAFAFA]"
          }`}
        >
          <p className="text-[14px] leading-[20px] text-customBlack  font-normal  ">
            {points}pts
          </p>
        </span>

        {/* end */}
      </div>
    </div>
  );
};

export default UserTeamCard;
