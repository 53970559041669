import receipt from "../../../assets/svgs/receipt.svg";
interface tableProps {
  className: string;
}
const data = [
  {
    date: "11-May-2023",
    challenger: "Aniedi Udo-Obong",
    challenged: "Daniel Ayeni",
    dateOfMatch: "23-May-2023",
  },
  {
    date: "11-May-2023",
    challenger: "Aniedi Udo-Obong",
    challenged: "Daniel Ayeni",
    dateOfMatch: "23-May-2023",
  },
  {
    date: "11-May-2023",
    challenger: "Aniedi Udo-Obong",
    challenged: "Daniel Ayeni",
    dateOfMatch: "23-May-2023",
  },
  {
    date: "11-May-2023",
    challenger: "Aniedi Udo-Obong",
    challenged: "Daniel Ayeni",
    dateOfMatch: "23-May-2023",
  },
  {
    date: "11-May-2023",
    challenger: "Aniedi Udo-Obong",
    challenged: "Daniel Ayeni",
    dateOfMatch: "23-May-2023",
  },
  {
    date: "11-May-2023",
    challenger: "Aniedi Udo-Obong",
    challenged: "Daniel Ayeni",
    dateOfMatch: "23-May-2023",
  },
  {
    date: "11-May-2023",
    challenger: "Aniedi Udo-Obong",
    challenged: "Daniel Ayeni",
    dateOfMatch: "23-May-2023",
  },
  {
    date: "11-May-2023",
    challenger: "Aniedi Udo-Obong",
    challenged: "Daniel Ayeni",
    dateOfMatch: "23-May-2023",
  },
];

const TournamentBoardTable = ({ className }: tableProps) => {
  return (
    <div
      className={`w-691 h-334 top-265 left-262 rounded-20 w-full  h-full flex flex-col bg-[#FFFFFF]  rounded-[20px] ${className} `}
    >
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className="font-inter text-base font-semibold leading-17 tracking-wider text-left text-customBlack">
          Ladder Tournament Board
        </h1>
        {/* <p className="text-primaryYellow text-sm font-[500]">View All</p> */}
      </div>
      {/* Table section starts from here  #################################################3*/}
      <table className="min-w-full w-full h-full  ">
        <thead className="w-691 h-35 top-55 opacity-30 bg-[#F4F4F4] ">
          <tr className="bg-[##F4F4F4] mr-8 grid grid-cols-12  h-[35px] items-center">
            <th className=" text-neutral-600 font-inter text-xs font-medium leading-15 tracking-wider text-center ">
              Date
            </th>
            <th className=" text-neutral-600 font-inter font-medium leading-15 tracking-wider text-center col-start-3 col-end-6 text-xs ">
              Challenger
            </th>
            <th className="font-inter font-medium leading-15 tracking-wider col-start-6 col-end-7 text-xs text-neutral-600 text-center ">
              VS
            </th>
            <th className=" font-inter text-xs font-medium leading-15 tracking-wider col-start-7 col-end-9 text-neutral-600 text-center ">
              Challenged
            </th>
            <th className="font-inter text-xs font-medium tracking-wider col-start-10 col-end-12  text-neutral-600 text-center ">
              Date of Match
            </th>
            <th className=" font-inter ml-3 text-xs font-medium leading-15 tracking-wider col-start-12 col-end-12 text-neutral-600 text-left ">
              Results
            </th>
          </tr>
        </thead>
        <tbody className="w-full">
          <tr className="w-full ">
            <td className="w-full flex h-full flex-col justify-center items-center">
              <div className="  border-2.38 border-gray-600 w-38 h-38 flex items-center justify-center">
                <img src={receipt} className="w-38 h-38" alt="" />
              </div>
              <span className="font-inter text-xs font-medium leading-15 tracking-wider text-center mt-8">
                {data.length === 0 && "No Data Available"}
                No ladder tournament
              </span>
            </td>
          </tr>
        </tbody>
        {/* <tbody className="w-full  rounded-b-[20px] flex flex-col h-full ">
          {data.slice(0,5).map((item: any, index: number) => {
            return (
              <tr className="grid grid-cols-12  py-3  items-center" key={index}>
                <td className=" col-start-1 col-end-3 text-sm text-neutral-600 text-center ">
                  {item.date}
                </td>
                <td className=" col-start-3 col-end-6 text-sm text-neutral-600 text-center ">
                  {item.challenger}
                </td>
                <td className=" col-start-6 col-end-7 text-sm text-neutral-600 text-center ">
                  VS
                </td>
                <td className=" col-start-7 col-end-9 text-sm text-neutral-600 text-center ">
                  {item.challenged}
                </td>
                <td className=" col-start-9 col-end-11 text-sm text-neutral-600 text-center ">
                  {item.dateOfMatch}
                </td>
                <td className=" col-start-11 col-end-13 text-sm text-neutral-600 text-center ">

                </td>
              </tr>
            );
          })}
        </tbody> */}
      </table>

      {/* Table section Ends from here $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$4 */}
    </div>
  );
};

export default TournamentBoardTable;
