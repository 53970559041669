import React from "react";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { GoogleLogo } from "../../assets/svgs";
interface buttonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  active: boolean;
  name: string;
  className?: string;
  loading?: boolean;
}
export const Btn = ({
  active,
  onClick,
  name,
  className,
  loading,
}: buttonProps) => {
  return (
    <>
      {loading ? (
        <button
          className={`${className} ${
            active
              ? "bg-lemonGreen btn-drop"
              : "bg-lemonGreen opacity-100 btn-drop"
          } text-white w-full font-semibold h-[52px] flex justify-center items-center font-Inter rounded-[16px] cursor-progress hover:opacity-80`}
          disabled
        >
          <svg
            className="animate-spin h-5 w-5 mr-3 bg-white ..."
            viewBox="0 0 24 24"
          ></svg>
          Loading...
        </button>
      ) : (
        <button
          className={`${className} ${
            active ? "bg-lemonGreen" : "bg-lemonGreen opacity-40"
          } text-white w-full h-[52px] font-semibold flex justify-center items-center font-Inter rounded-[16px] cursor-pointer hover:opacity-70`}
          disabled={!active}
          onClick={onClick}
        >
          {name}
        </button>
      )}
    </>
  );
};
export const RedBtn = ({
  active,
  onClick,
  name,
  className,
  loading,
}: buttonProps) => {
  return (
    <>
      {loading ? (
        <button
          className={`${className} ${
            active
              ? "bg-[#B00020] btn-drop"
              : "bg-[#B00020] opacity-100 btn-drop"
          } text-white w-full font-semibold h-[52px] flex justify-center items-center font-Inter rounded-[16px] cursor-progress hover:opacity-80`}
          disabled
        >
          <svg
            className="animate-spin h-5 w-5 mr-3 bg-white ..."
            viewBox="0 0 24 24"
          ></svg>
          Loading...
        </button>
      ) : (
        <button
          className={`${className} ${
            active ? "bg-[#B00020]" : "bg-[#B00020] opacity-40"
          } text-white w-full h-[52px] font-semibold flex justify-center items-center font-Inter rounded-[16px] cursor-pointer hover:opacity-70`}
          disabled={!active}
          onClick={onClick}
        >
          {name}
        </button>
      )}
    </>
  );
};
export const RedOutlinedBtn = ({
  active,
  onClick,
  name,
  className,
  loading,
}: buttonProps) => {
  return (
    <>
      {loading ? (
        <button
          className={`${className} border border-[#B00020] text-[#B00020] w-full h-[60px] flex justify-center items-center font-Inter rounded-[20px] cursor-pointer font-bold hover:bg-[#B00020] hover:text-white`}
          disabled
        >
          <svg
            className="animate-spin h-5 w-5 mr-3 ..."
            viewBox="0 0 24 24"
          ></svg>
          Loading...
        </button>
      ) : (
        <button
          className={`${className} border border-[#B00020] text-[#B00020] w-full h-[60px] flex justify-center items-center font-bold font-Inter rounded-[20px] cursor-pointer hover:bg-[#B00020] hover:text-white`}
          disabled={!active}
          onClick={onClick}
        >
          {name}
        </button>
      )}
    </>
  );
};
export const OutlinedBtn = ({
  active,
  onClick,
  name,
  className,
  loading,
}: buttonProps) => {
  return (
    <>
      {loading ? (
        <button
          className={`${className} border border-lemonGreen text-lemonGreen w-full h-[52px] flex justify-center items-center font-Inter rounded-[20px] cursor-pointer hover:bg-lemonGreen hover:text-white`}
          disabled
        >
          <svg
            className="animate-spin h-5 w-5 mr-3 ..."
            viewBox="0 0 24 24"
          ></svg>
          Loading...
        </button>
      ) : (
        <button
          className={`${className} border border-lemonGreen text-lemonGreen w-full h-[60px] flex justify-center items-center font-Inter rounded-[20px] cursor-pointer hover:bg-lemonGreen hover:text-white`}
          disabled={!active}
          onClick={onClick}
        >
          {name}
        </button>
      )}
    </>
  );
};

export const CustomLoginBtn = ({
  active,
  onClick,
  name,
  className,
  loading,
}: buttonProps) => {
  return (
    <>
      {loading ? (
        <button
          className={`${className} ${
            active ? "bg-white" : "bg-white opacity-100"
          } text-white w-full h-[60px] flex justify-center items-center font-Inter rounded-[16px] cursor-progress hover:opacity-80`}
          disabled
        >
          <svg
            className="animate-spin h-5 w-5 mr-3 bg-neutral-600 ..."
            viewBox="0 0 24 24"
          ></svg>
          Loading...
        </button>
      ) : (
        <button
          className={`${className} ${
            active ? "bg-white" : "bg-white opacity-40"
          } text-neutral-700 w-full h-[60px] flex justify-center items-center font-[600] font-Inter rounded-[16px] cursor-pointer border border-neutral-700 gap-4`}
          disabled={!active}
          onClick={onClick}
        >
          <BsFillEnvelopeFill className="text-xl" />
          &nbsp; {name}
        </button>
      )}
    </>
  );
};

export const GoogleAuthBtn = ({
  active,
  onClick,
  name,
  className,
  loading,
}: buttonProps) => {
  return (
    <>
      {loading ? (
        <button
          className={`${className} border border-gray-900 text-gray-900 w-full h-[60px] flex justify-center items-center font-Inter rounded-[20px] cursor-pointer hover:bg-gray-900 hover:text-white`}
          disabled
        >
          <svg
            className="animate-spin h-5 w-5 mr-3 ..."
            viewBox="0 0 24 24"
          ></svg>
          Loading...
        </button>
      ) : (
        <button
          className={`${className} border border-gray-900 text-gray-900 w-full h-[60px] flex justify-center items-center font-Inter rounded-[20px] cursor-pointer hover:bg-gray-600 hover:text-white gap-4 font-[600]`}
          disabled={!active}
          onClick={onClick}
        >
          <GoogleLogo /> {name}
        </button>
      )}
    </>
  );
};
