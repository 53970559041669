import receipt from "../../../../assets/svgs/receipt.svg";

const AssignedCourtTable = ({ className }: any) => {
  return (
    <div
      className={`w-full  h-[50vh]  flex flex-col bg-white  rounded-[20px] ${className} `}
    >
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className="text-sm font-semibold text-customBlack">
          Assigned Courts
        </h1>
      </div>
      {/* Table section starts from here  #################################################3*/}
      <table className="min-w-full w-full h-full  ">
        <thead>
          <tr className="w-full bg-[#f4f4f4] bg-opacity-30 flex item-center h-[35px] pl-5">
            <th className="w-2/12 text-xs text-neutral-600 flex items-center ">
              Court No
            </th>
            <th className="w-2/12 text-xs text-neutral-600 flex items-center">
              Booked By
            </th>
            <th className="w-2/12 text-xs text-neutral-600 flex items-center">
              Location
            </th>
            <th className="w-2/12 text-xs text-neutral-600 flex items-center">
              Booked Date
            </th>
            <th className="w-1/12 text-xs text-neutral-600 flex items-center">
              Booked Time
            </th>
            <th className="w-1/12 text-xs text-neutral-600 flex items-center">
              Timeslot
            </th>
            <th className="w-2/12 text-xs text-neutral-600 flex items-center">
              QR Code
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="w-full flex flex-col items-center justify-center">
              <div className="mt-10 border-2.38 border-gray-600 w-38 h-38 flex flex-col items-center">
                <img
                  src={receipt}
                  className="border-2.5 border-gray-600 w-38 h-38"
                  alt=""
                />
                <h1 className="font-inter text-xs font-medium leading-15 tracking-wider w-106 h-15 text-center mt-5">
                  No Assigned Court Found
                </h1>
              </div>
            </td>
          </tr>
        </tbody>

        {/* <tbody className="w-full  rounded-b-[20px] flex flex-col h-full ">
          {data.slice(0,5).map((item: any, index: number) => {
            return (
              <tr className="grid grid-cols-12  py-3  items-center" key={index}>
                <td className=" col-start-1 col-end-3 text-sm text-neutral-600 text-center ">
                  {item.date}
                </td>
                <td className=" col-start-3 col-end-6 text-sm text-neutral-600 text-center ">
                  {item.challenger}
                </td>
                <td className=" col-start-6 col-end-7 text-sm text-neutral-600 text-center ">
                  VS
                </td>
                <td className=" col-start-7 col-end-9 text-sm text-neutral-600 text-center ">
                  {item.challenged}
                </td>
                <td className=" col-start-9 col-end-11 text-sm text-neutral-600 text-center ">
                  {item.dateOfMatch}
                </td>
                <td className=" col-start-11 col-end-13 text-sm text-neutral-600 text-center ">

                </td>
              </tr>
            );
          })}
        </tbody> */}
      </table>
      {/* Table section Ends from here $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$4 */}
    </div>
  );
};

export default AssignedCourtTable;
