import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import optionIcon from "../../assets/svgs/more.svg";
import receipt from "../../assets/svgs/receipt.svg";
import {
  getAllClubMembers,
  getAllPendingMember,
  resetMemberState,
  updateMemberState,
} from "../../features/auth/AdminAuth";
import { capitalizeWords, convertDateFormat } from "../../utils";
import ReUseModal from "../Modal/ReUseModal";
import { ModalTitle } from "../Text";
const PendingMembers = () => {
  const adminAuth = useAppSelector((state) => state.adminAuth);
  const dispatch = useAppDispatch();
  let membersArray = adminAuth.allPendingMembers;
  useEffect(() => {
    if (adminAuth.updateMemberSuccess) {
      toast.success("Update Successful");

      setTimeout(() => {
        dispatch(resetMemberState());
        dispatch(getAllClubMembers());
        dispatch(getAllPendingMember());
      }, 500);
    }
  }, [adminAuth.updateMemberSuccess]);

  // const pendingArray = filterByState(membersArray, memberState);
  const pendingArray = membersArray;

  return (
    <div className="bg-white rounded-[20px] flex flex-col h-full mt-6 flow-hide relative pt-5">
      <h1 className="pl-5">All Pending Members</h1>
      <table className="w-full table-auto">
        <thead>
          <tr className="w-full bg-[#f4f4f4] bg-opacity-30 flex item-center h-[35px] pl-5">
            <th className="w-3/12 text-sm text-neutral-600 flex items-center ">
              Name of player
            </th>
            <th className="w-3/12 text-sm text-neutral-600 flex items-center">
              Email Address
            </th>
            <th className="w-1/12 text-sm text-neutral-600 flex items-center">
              Points
            </th>
            <th className="w-2/12 text-sm text-neutral-600 flex items-center">
              Date Joined
            </th>
            <th className="w-2/12 text-sm text-neutral-600 flex items-center">
              Status
            </th>
            <th className="w-1/12 text-sm text-neutral-600 flex items-center">
              Action
            </th>
          </tr>
        </thead>

        {!pendingArray?.length ? (
          <tbody>
            <tr>
              <td className="mt-10 border-2.38 border-gray-600 w-38 h-38 flex flex-col items-center">
                <img
                  src={receipt}
                  className="border-2.5 border-gray-600 w-38 h-38"
                  alt="receipt icon"
                />
                <span className="font-inter text-sm font-medium leading-15 tracking-wider w-106 h-15 text-center mt-5">
                  No Pending Members
                </span>
              </td>
            </tr>
          </tbody>
        ) : (
          // <div className="w-full h-[40vh] flex justify-center pt-10">
          //   <h1>No Pending Request</h1>
          // </div>
          <span>
            <tbody className="w-full flex flex-col space-y-4 mt-2">
              {pendingArray?.map((item: any, index: number) => {
                return <SingleMember item={item} index={index} />;
              })}
            </tbody>
            <div className="w-full h-[36px] items-center flex justify-between pl-4 pr-8 mb-0 mt-3">
              {/* pagination */}
              {pendingArray?.length > 10 && (
                <div className="flex items-center">
                  <p className="text-sm">Page</p>
                  <p className="px-1 rounded-sm border border-border text-customBlack font-bold cursor-pointer text-sm mx-2 ">
                    1
                  </p>
                  <p className="text-sm text-customBlack">of 2</p>
                  <div className="space-x-2 items-center ml-8 flex">
                    <span className="text-base px-1 bg-[#f4f4f4] text-[#cfcfcf] opacity-70 rounded-sm border border-border">
                      <IoIosArrowBack />
                    </span>
                    <span className="text-base px-1 bg-[#f4f4f4] text-customBlack opacity-70 rounded-sm border border-border">
                      <IoIosArrowForward />
                    </span>
                  </div>
                </div>
              )}

              {/* enteries */}
              {!pendingArray && (
                <div className="flex">
                  <div className="mt-10 border-2.38 border-gray-600 w-38 h-38 flex flex-col items-center">
                    <img
                      src={receipt}
                      className="border-2.5 border-gray-600 w-38 h-38"
                      alt="empty reciept icon"
                    />
                    <h1 className="font-inter text-sm font-medium leading-15 tracking-wider w-106 h-15 text-center mt-5">
                      No Data Yet
                    </h1>
                  </div>
                  <div>
                    <p className="px-1 rounded-sm border border-border text-customBlack font-bold cursor-pointer text-sm mx-2 ">
                      10 per page
                    </p>
                  </div>
                </div>
              )}
            </div>
          </span>
        )}
      </table>
    </div>
  );
};

export default PendingMembers;

const SingleMember = ({ item, index }: any) => {
  let sampleData = {
    firstname: "",
    lastname: "",
    email: "",
    image: "",
    thumbnail: "",
  };

  const { email, firstname, updatedAt, lastname, image, thumbnail } =
    item?.firstname !== undefined ? item : sampleData;
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const adminAuth = useAppSelector((state) => state.adminAuth);
  const AcceptMember = () => {
    const data = {
      id: item?._id,
      state: "accepted",
    };
    dispatch(updateMemberState(data));
  };
  const RejectMember = () => {
    const data = {
      id: item?._id,
      state: "rejected",
    };
    dispatch(updateMemberState(data));
  };

  useEffect(() => {
    if (adminAuth.updateMemberSuccess) {
      setOpenModal(false);
    }
  }, [adminAuth.updateMemberSuccess]);

  return (
    <>
      <tr key={index} className="w-full flex items-center pl-5">
        <td className="w-3/12 flex items-center space-x-2">
          <img
            src={` ${
              image
                ? image || thumbnail
                : "https://img.freepik.com/premium-vector/avatar-profile-icon_188544-4755.jpg"
            }`}
            alt="sample"
            className="w-[28px] h-[28px] rounded-full "
          />
          <p className="text-sm text-customBlack font-[500]">
            {capitalizeWords(
              `${firstname ? firstname : ""} ${lastname ? lastname : ""}`
            )}
          </p>
        </td>
        <td className="w-3/12 text-sm text-customBlack font-[500] ">
          {email ? email : "no email"}
        </td>
        <td className="w-1/12 text-sm text-customBlack font-[500] flex ml-5 justify-center">
          21,092,22
        </td>
        <td className="w-2/12 text-sm text-customBlack font-[500] flex justify-center">
          {convertDateFormat(updatedAt)}
        </td>
        <td className="w-2/12 text-sm text-customBlack font-[500] flex justify-center">
          <p className="text-[#E48900] px-2 py-[6px] rounded-[8px] bg-[#FAE7CC] w-fit">
            {item.state}
          </p>
        </td>
        <td className="w-2/12 text-sm text-customBlack font-[500] flex justify-center">
          <img
            src={optionIcon}
            alt="options"
            className=""
            onClick={() => setOpenModal(true)}
          />
        </td>
      </tr>
      <ReUseModal open={openModal} setOpen={setOpenModal}>
        {/* // added bg-[#fae7cc] opacity-50 to the div below */}

        <div className="w-full flex  flex-col space-y-6">
          <ModalTitle text="Accept Member" className="text-center space-x-2 " />
          <p className="text-center mt-1  text-base font-[400] leading-[20px] text-[#5E5E5E]">
            Click accept in the option below to accept
            <span className="font-bold ml-1 ">{firstname + lastname} </span>
            to your club
          </p>
          <div className="w-11/12 mx-auto grid grid-cols-2 gap-x-4 items-center mt-16">
            <button
              className="flex w-full rounded-[16px] border border-[#B00020] items-center justify-center cursor-pointer text-sm text-[#B00020] h-[52px] font-bold "
              onClick={RejectMember}
            >
              Reject
            </button>
            <button
              className=" bg-lemonGreen flex w-full rounded-[16px] items-center justify-center text-sm text-white cursor-pointer font-bold  h-[52px] "
              onClick={AcceptMember}
            >
              Accept
            </button>
          </div>
        </div>
      </ReUseModal>
    </>
  );
};
