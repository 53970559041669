import { useState, useEffect } from "react";
import DashboardLayout from "../../../Layouts/DashboardLayout";
import { NavigationArrow } from "../../../assets/svgs/admin";
import { useNavigate } from "react-router-dom";
import PrimarySelect from "../../../components/Selects/PrimarySelect";
import { Btn } from "../../../components/Button";
import ReUseModal from "../../../components/Modal/ReUseModal";
import sentMark from "../../../assets/svgs/checkmark.svg";
import { AdminInput } from "../../../components/Input";
import { stateArray } from "../../../utils/nigeriaStates";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { createCourt } from "../../../features/auth/TournamentSlice";
import UploadImageAdmin from "../../../components/upload/Upload-image-admin";
import { toast } from "react-toastify";

const BookType = [
  {
    name: "Public",
    id: 1,
  },
  {
    name: "Private",
    id: 1,
  },
];
const Countries = [
  {
    name: "Nigeria",
    id: 1,
  },
];

const CreateCourt = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tournament = useAppSelector((state) => state.tournament);
  const [selected, setSelected] = useState<any>({});
  const [selectedA, setSelectedA] = useState<any>({});
  const [selectedB, setSelectedB] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [courtName, setCourtName] = useState("");
  const [address, setAddress] = useState("");
  const [price, setPrice] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [previewing, setPreviewing] = useState<any>("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (selectedA.name === "Private") {
      setIsPrivate(true);
    } else {
      setIsPrivate(false);
    }
  }, [selectedA]);

  const handleSubmit = () => {
    if (address && courtName && selected.name && selectedB.name) {
      const data = {
        type: selectedA?.type?.name ? selectedA?.name?.toLowerCase() : "public",

        name: courtName,
        address: address ? address : `Nigeria + ${selectedB?.name}`,
        country: "Nigeria",
        state: selectedB.name,
        price: price ? price : 0,
        image: imageUrl,
        thumbnail: imageUrl,
        privateMode: isPrivate,
        description: description,
      };
      dispatch(createCourt(data));
    } else {
      toast.error("all fields are required");
    }
  };

  useEffect(() => {
    if (tournament.createCourtSuccess) {
      setOpen(true);
      navigate("/admin/courts");
    } else {
      setOpen(false);
    }
  }, [tournament.createCourtSuccess]);

  return (
    <DashboardLayout current={3}>
      <div className="w-full pl-[30px] pr-[50px] flex flex-col ">
        <div
          className="flex w-fit cursor-pointer mt-10"
          onClick={() => navigate(-1)}
        >
          <NavigationArrow />
        </div>

        <div className="w-full flex flex-col bg-white mt-[26px] min-h-[95vh] rounded-[5px] relative ">
          <div className="h-[51px] ">
            <h1 className="pl-5 text-customBlack font-semibold text-sm mt-[22px] ">
              Onboard a Court
            </h1>
          </div>
          {/* breaker line */}
          <div className="w-full h-[1px] bg-[#cfcfcf] " />
          <p className="mt-3 text-neutral-600 pl-5 ">
            Fill in the details below to create a court
          </p>
          {/* start of actions */}
          <div className="2xl:w-10/12 w-11/12 flex gap-8 mt-[31px] gap-x-[60px] pl-5 gap-y-[30px]">
            {/* left side */}
            <div className="w-1/2 flex flex-col gap-8">
              <div className="w-full">
                <AdminInput
                  label="Name of Court"
                  placeholder="Name of Court"
                  required={true}
                  onChange={(e: any) => setCourtName(e.target.value)}
                />
              </div>
              <div className="w-full">
                <PrimarySelect
                  label="Type of Court"
                  selected={selectedA}
                  setSelected={setSelectedA}
                  name="Type of Court"
                  data={BookType}
                />
              </div>
              <div className="w-full">
                <PrimarySelect
                  label="Select Country"
                  selected={selected}
                  setSelected={setSelected}
                  name="Select Country"
                  data={Countries}
                />
              </div>
            </div>
            {/* end of left side */}
            {/* start of the right side */}
            <div className="w-1/2 flex flex-col">
              <div className="w-full flex flex-col">
                <label className="text-neutral-400 text-sm mb-2 ">
                  Upload Court Image
                </label>
                <UploadImageAdmin
                  getFileUrl={setImageUrl}
                  setPreviewing={setPreviewing}
                />
              </div>
              <div className="w-full mt-8">
                <PrimarySelect
                  label="Select State"
                  selected={selectedB}
                  setSelected={setSelectedB}
                  name="Select State"
                  data={stateArray}
                />
              </div>
            </div>
          </div>
          {/* edn of right side */}
          <div className=" mt-8 2xl:w-10/12 grid grid-cols-2 w-11/12 flex gap-8 pl-5 gap-y-[30px] ">
            <AdminInput
              label="Address of Court"
              placeholder="Address of Court"
              required={true}
              onChange={(e: any) => setAddress(e.target.value)}
            />
            <AdminInput
              label="Court Description"
              placeholder="Enter the court description here"
              required={true}
              onChange={(e: any) => setDescription(e.target.value)}
            />
          </div>

          <div
            className={` ${
              previewing
                ? "flex w-4/12 mx-auto mt-12 2xl:mt-20 pb-12"
                : "flex w-4/12 mx-auto bottom-[10px] absolute left-0 right-0  ml-65 pb-20 mt-[45px]"
            }   `}
          >
            <Btn
              // className="absolute bottom-4 left-1/2 transform -translate-x-1/2 py-2 px-4 bg-blue-500 text-white rounded cursor-pointer"
              active={true}
              onClick={handleSubmit}
              name="Create"
              loading={tournament.loading}
            />
          </div>
        </div>
      </div>
      <ReUseModal open={open} setOpen={setOpen}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-cutomBlack text-[24px] font-bold text-center">
          Court Created
        </h1>
        <p className="text-center w-10/12 mx-auto text-neutral-500 ">
          You’ve successfully created a court
        </p>
        <Btn
          active={true}
          name="Continue"
          onClick={() => {
            setOpen(false);
          }}
          className="mt-[25px] mb-3"
        />
      </ReUseModal>
    </DashboardLayout>
  );
};

export default CreateCourt;
