import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSimplifiedError } from "../../utils";
import { APIService } from "../../utils/APIServices";
import { url } from "../../utils/endpoints";
export interface SuperAdminState {
  loading: boolean;
  token: string;
  data: any;
  allClubs?: any;
  clubsLoading: boolean;
  error: any;
  superAdminData: any;
  loginSuccess: boolean;
  allAdmin: any;
  allUserAdmin: any;
  deleteAdminSuccess: boolean;
}

const initialState: SuperAdminState = {
  loading: false,
  token: "",
  data: [],
  allClubs: [],
  clubsLoading: false,
  error: {},
  superAdminData: [],
  loginSuccess: false,
  allAdmin: [],
  allUserAdmin: [],
  deleteAdminSuccess: false,
};

export const superSlice = createSlice({
  name: "superAdminAuth",
  initialState,
  reducers: {
    restoreDefault: (state) => {
      state.loading = false;
      state.loginSuccess = false;
      state.clubsLoading = false;
      state.token = "";
      state.superAdminData = [];
    },
    resetAll: (state) => {
      state.loading = false;
      state.loginSuccess = false;
      state.clubsLoading = false;
      state.superAdminData = [];
      state.token = "";
    },
    resetDelete: (state) => {
      state.deleteAdminSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUser.pending, (state) => {
        state.clubsLoading = true;
      })
      .addCase(fetchAllUser.fulfilled, (state, { payload }) => {
        state.clubsLoading = false;
        state.allClubs = payload;
      })
      .addCase(fetchAllUser.rejected, (state, { payload }) => {
        state.clubsLoading = false;
        state.error = payload;
      })
      .addCase(loginSuperAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginSuperAdmin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.superAdminData = payload;
        state.token = payload.token;
        state.loginSuccess = true;
      })
      .addCase(loginSuperAdmin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.loginSuccess = false;
      })
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allUserAdmin = payload;
      })
      .addCase(getAllUsers.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getAllAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAdmin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allAdmin = payload.data;
      })
      .addCase(getAllAdmin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getAllClub.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllClub.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allClubs = payload.data;
      })
      .addCase(getAllClub.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(deleteAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAdmin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.deleteAdminSuccess = true;
      })
      .addCase(deleteAdmin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.deleteAdminSuccess = false;
      });
  },
});

export const fetchAllUser = createAsyncThunk(
  "allClubs",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.get(`${url.clubs}`);
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const loginSuperAdmin = createAsyncThunk(
  "loginSuperAdmin",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.adminSuperLogin}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const deleteAdmin = createAsyncThunk(
  "deleteAdmin",
  async (payload: any, { rejectWithValue, getState }) => {
    console.error("HELLO ", payload);
    // try {
    //   const { data } = await APIService.delete(`${url.deleteAdmin}/${payload}`, {
    //     headers: {
    //       Authorization: `Bearer ${superAdminAuth?.token}`,
    //     },
    //   } );
    //   return data;
    // } catch (error: any) {
    //   return rejectWithValue(
    //     getSimplifiedError(error.response ? error : error)
    //   );
    // }
  }
);
export const getAllUsers = createAsyncThunk(
  "getAllUsers",
  async (_, { rejectWithValue, getState }) => {
    const { superAdminAuth }: any = getState();
    try {
      const { data } = await APIService.get(`${url.allUserAdmin}`, {
        headers: {
          Authorization: `Bearer ${superAdminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getAllAdmin = createAsyncThunk(
  "getAllAdmin",
  async (_, { rejectWithValue, getState }) => {
    const { superAdminAuth }: any = getState();
    try {
      const { data } = await APIService.get(`${url.getAllAdmin}`, {
        headers: {
          Authorization: `Bearer ${superAdminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getAllClub = createAsyncThunk(
  "getAllClub",
  async (_, { rejectWithValue, getState }) => {
    const { superAdminAuth }: any = getState();
    try {
      const { data } = await APIService.get(`${url.getAllClub}`, {
        headers: {
          Authorization: `Bearer ${superAdminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const authSelector = (state: any) => state.auth;

export const { restoreDefault, resetAll, resetDelete } = superSlice.actions;
export default superSlice.reducer;
