import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { getSingleCompetitionDetailAdmin } from "../../../features/auth/TournamentSlice";
import SingleMatchFixture from "./single-match-fixture";
import {
  Match,
  MatchState,
} from "../../../pages/admin/Competition/ScheduleCompetitionMatchPage";
import PrimarySelect from "../../Selects/PrimarySelect";

interface SingleSetScheduleProps {
  matchSet: MatchState;
  setMatchSets: React.Dispatch<React.SetStateAction<MatchState[]>>;
}
const SingleSetSchedule: React.FC<SingleSetScheduleProps> = ({
  matchSet,
  setMatchSets,
}) => {
  const tournament = useAppSelector((state) => state.tournament);
  const dispatch = useAppDispatch();
  const [allMatching, setAllMatching] = useState<Match[]>(matchSet.matchList);
  const [selectedA, setSelectedA] = useState<any>({});
  const [selectedB, setSelectedB] = useState<any>({});
  const [playersA, setPlayersA] = useState<any>([]);
  const [playersB, setPlayersB] = useState<any>([]);
  const [selectedCat, setSelectedCat] = useState<any>({ name: "", _id: "" });
  const id = useParams();
  useEffect(() => {
    dispatch(getSingleCompetitionDetailAdmin({ id: id.id }));
  }, []);
  const details = tournament?.competitionDetailAdmin;

  const groupedData = details?.teams.map((team: any) => {
    const teamPlayers = details.players.filter(
      (player: any) =>
        player.team === team._id && player?.group === selectedCat?._id
    );
    return {
      name: team.name,
      id: team._id,
      players: teamPlayers,
    };
  });

  const playersArray = tournament.allAcceptedPlayers;

  useEffect(() => {
    const listA: any = groupedData?.filter(
      (team: any) => team.id === selectedA.id
    );

    const listB: any = groupedData?.filter(
      (team: any) => team.id === selectedB.id
    );
    const finalListA = listA?.[0]?.players?.map((item: any) => item?._id);
    const finalListB = listB?.[0]?.players?.map((item: any) => item?._id);
    setPlayersA(finalListA);

    setPlayersB(finalListB);
  }, [selectedA, selectedB]);
  // Update the parent component's state when allMatching changes
  useEffect(() => {
    setMatchSets((prevSets) =>
      prevSets.map((set) =>
        set.id === matchSet.id ? { ...set, matchList: allMatching } : set
      )
    );
  }, [allMatching, matchSet.id, setMatchSets]);

  // Function to add a new match
  const addNewMatch = () => {
    const newMatch: Match = {
      id: allMatching.length,
      challenger: "",
      challenged: "",
      court: "",
      time: "",
      isDouble: false,
      challengerB: "",
      challengedB: "",
    };

    setAllMatching((prevMatches) => [...prevMatches, newMatch]);
  };

  // if (tournament?.fetchLoading) {
  //   return (
  //     <div className="w-full p-5">
  //       <LoadingComponent />
  //     </div>
  //   );
  // }
  return (
    <div className=" flex flex-col h-full  flow-hide border-dashed  border-border border-b-[2px] last:border-none pb-6  ">
      {/* breaker line */}
      <div className="flex items-center gap-4 mb-4 ">
        <p className="text-sm text-customBlack "> Category:</p>
        <div className="w-fit  ">
          <PrimarySelect
            noBorder
            height="h-[30px]  min-w-max"
            selected={selectedCat}
            setSelected={setSelectedCat}
            data={tournament?.competitionDetailAdmin?.groups}
            label={""}
            name="select Category"
          />
        </div>
      </div>
      <div className="w-full flex flex-col  ">
        <div className="w-full grid grid-cols-2 xl:max-w-[700px]  ">
          <div className="w-fit ">
            <PrimarySelect
              noBorder
              height="h-[30px] -ml-3 min-w-max"
              selected={selectedA}
              setSelected={setSelectedA}
              data={groupedData}
              label={""}
              name="select team"
            />
          </div>
          <div className="w-fit ">
            <PrimarySelect
              noBorder
              height="h-[30px]"
              selected={selectedB}
              setSelected={setSelectedB}
              data={groupedData}
              label={""}
              name="Select team"
            />
          </div>
        </div>
        {/* form area */}
        <div className="w-full flex flex-col max-w-[700px] ">
          {allMatching?.map((item: any, index: number) => {
            return (
              <SingleMatchFixture
                {...item}
                playersList={playersArray}
                key={index}
                allMatching={allMatching}
                setAllMatching={setAllMatching}
                index={index}
                playersA={playersA}
                playersB={playersB}
              />
            );
          })}
        </div>
        <p
          className=" mt-3 text-secondary700 cursor-pointer text-sm font-semibold "
          onClick={addNewMatch}
        >
          Add new match
        </p>
      </div>
    </div>
  );
};

export default SingleSetSchedule;
