import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NavigationArrow } from "../../../assets/svgs/admin";
import { playersNumber, expiryTimes } from "../../../utils/sampData";
import { Btn } from "../../Button";
import Radio from "../../CustomRadio";
import ColouredSelect from "../../Selects/ColouredSelect";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  editLadder,
  getSingleLadderDetail,
  resetAllLadderState,
} from "../../../features/auth/ladder-slice";
import { convertDateInput, convertDateToFormat } from "../../../utils";
import ReUseModal from "../../Modal/ReUseModal";
import sentMark from "../../../assets/svgs/checkmark.svg";
import { AdminDateInput } from "../../Input/DateInput";
import LoadingComponent from "../../Loading/Loader";
const EditSingleLadderDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const ladder = useAppSelector((state) => state.ladder);
  const id = useParams();
  useEffect(() => {
    dispatch(getSingleLadderDetail({ id: id.id }));
  }, []);
  const item = ladder?.singleLadder;

  const {
    players,
    action,
    matches,
    name,
    _type,
    start,
    stop,
    status,
    gender,
    _id,
    points,

    challenge_power,
    challenge_timeout,
  } = item;

  const [genderType, setGenderType] = useState("all");
  const [expiryTime, setExpiryTime] = useState<any>(null);
  const [playerNo, setPlayerNo] = useState<any>(null);
  const [pointsNo, setPointsNo] = useState<any>(null);
  const [showRankModal, setShowRankModal] = useState(false);
  const [showPointModal, setShowPointModal] = useState(false);
  const [ladderStartDate, setladderStartDate] = useState("");
  const [ladderEndDate, setladderEndDate] = useState("");
  const [competitionName, setCompetitionName] = useState("");
  const [deadline, setDeadline] = useState("");
  const handleUpdateLadder = () => {
    const data = {
      name: competitionName || item?.name,

      start: convertDateToFormat(ladderStartDate || item?.start),
      stop: convertDateToFormat(ladderEndDate || item?.stop),
      deadline: convertDateToFormat(deadline || item?.deadline),
      challenge_timeout: expiryTime?.time
        ? expiryTime?.time
        : item?.challenge_timeout,
      challenge_power: playerNo?.value
        ? playerNo?.value
        : item?.challenge_power,

      points: pointsNo?.value ? pointsNo?.value : item?.points,
      mode: "ladder",
      gender: genderType || item?.gender || "all",
    };

    dispatch(editLadder({ data: data, id: id.id }));
  };

  useEffect(() => {
    if (ladder?.editLadderSuccess) {
      if (_type?.id === "rank") {
        setShowRankModal(true);
        setTimeout(() => {
          dispatch(resetAllLadderState());
          navigate(`/admin/tournament`);
        }, 3000);
      } else {
        setShowPointModal(true);
        setTimeout(() => {
          dispatch(resetAllLadderState());
          navigate(`/admin/tournament`);
        }, 3000);
      }
    }
  }, [ladder?.editLadderSuccess]);
  const handleContinue = () => {
    setShowRankModal(false);
    setShowPointModal(false);
    dispatch(resetAllLadderState());
    navigate("/admin/tournament");
  };

  const [showTable, setShowTable] = useState(false);
  useEffect(() => {
    if (ladder?.singleLadder?.name) {
      setShowTable(true);
    } else setShowTable(false);
  }, [ladder?.singleLadder]);

  if (ladder?.loading) {
    return (
      <div className="mt-8 px-8">
        <LoadingComponent />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col bg-[#f4f4f4] h-full min-h-max  p-[30px] 2xl:p-8 2xl:pr-[50px]">
      <div className="flex w-fit cursor-pointer" onClick={() => navigate(-1)}>
        <NavigationArrow />
      </div>
      <div className="bg-white rounded-[20px] flex flex-col min-h-screen  mt-6 relative">
        <div className="h-[51px]">
          <h1 className="pl-5 text-customBlack font-semibold text-sm mt-[22px] ">
            Edit Tournament
          </h1>
        </div>
        {/* breaker line */}
        <div className="w-full h-[1px] bg-[#cfcfcf] " />
        <div className="w-full flex flex-col">
          <p className="mt-3 text-neutral-700 pl-5 text-sm">
            Edit Tournament details
          </p>
          <div className="w-10/12 mt-[36px] flex flex-col pl-5">
            <div className="flex flex-col w-full">
              {/* start of function session */}
              <div className="flex items-center gap-[19px] ">
                <p className="text-neutral-700 text-sm">Name of Tournament:</p>
                <input
                  type="text"
                  className="outline-none h-[30px] rounded-[5px] border border-[#afafaf]  w-[230px] px-3 focus:outline-none"
                  value={competitionName || item.name}
                  onChange={(e: any) => {
                    if (e.target.value.length <= 30) {
                      setCompetitionName(e.target.value);
                    }
                    setCompetitionName(e.target.value.substring(0, 30));
                  }}
                />
              </div>
              <div className="flex space-x-4 items-center w-full mt-6">
                <div className="flex space-x-3    items-center">
                  <p className="text-neutral-700 text-sm min-w-max">
                    Tournament Start Date:
                  </p>

                  <AdminDateInput
                    date={ladderStartDate || convertDateInput(item?.start)}
                    setDate={setladderStartDate}
                    className="h-[30px] w-[230px] min-w-[230px] max-w-[230px]"
                  />
                </div>
                <p className="text-customBlack text-xs">to</p>
                <div className="flex space-x-3    items-center">
                  <p className="text-neutral-700 text-sm">
                    Tournament End Date:
                  </p>

                  <AdminDateInput
                    date={ladderEndDate || convertDateInput(item?.stop)}
                    setDate={setladderEndDate}
                    className="h-[30px] w-[230px] min-w-[230px] max-w-[230px]"
                  />
                </div>
              </div>
              <div className="flex gap-[22px]    items-center mt-6">
                <p className="text-neutral-700 text-sm">
                  Registration Deadline:
                </p>

                <AdminDateInput
                  date={deadline || convertDateInput(item?.deadline)}
                  setDate={setDeadline}
                  className="h-[30px] w-[230px] min-w-[230px] max-w-[230px]"
                />
              </div>
              <div className="flex items-center gap-[68px] mt-6">
                <p className="text-neutral-700 text-sm">Gender Based:</p>
                <div className="flex gap-6">
                  <span className="flex items-center">
                    <Radio
                      active={genderType === "all"}
                      onClick={() => setGenderType("all")}
                    />
                    <p className="text-sm text-customBlack ml-1">
                      All Club Members
                    </p>
                  </span>
                  <span className="flex items-center">
                    <Radio
                      active={genderType === "male"}
                      onClick={() => setGenderType("male")}
                    />
                    <p className="text-sm text-customBlack ml-1">Male</p>
                  </span>
                  <span className="flex items-center">
                    <Radio
                      active={genderType === "female"}
                      onClick={() => setGenderType("female")}
                    />
                    <p className="text-sm text-customBlack ml-1">Female</p>
                  </span>
                </div>
              </div>
              {_type === "rank" && (
                <div className="mt-6 flex items-center gap-4 ">
                  <p className="text-sm text-customBlack ml-1">
                    Number of players that can be challenged:
                  </p>
                  <div className="w-16">
                    <ColouredSelect
                      data={playersNumber}
                      name="0"
                      selected={
                        playerNo
                          ? playerNo
                          : {
                              value: item?.challenge_power,
                            }
                      }
                      setSelected={setPlayerNo}
                    />
                  </div>
                </div>
              )}
              {_type !== "rank" && (
                <div className="mt-6 flex items-center gap-4 ">
                  <p className="text-sm text-customBlack ml-1">
                    Number of points earned for winning a challenge:
                  </p>
                  <div className="w-16">
                    <ColouredSelect
                      data={playersNumber}
                      // name="0"
                      selected={
                        pointsNo
                          ? pointsNo
                          : {
                              value: item?.points,
                            }
                      }
                      setSelected={setPointsNo}
                    />
                  </div>
                </div>
              )}

              <div className="mt-6 flex items-center gap-4  ">
                <p className="text-sm text-customBlack ml-1">
                  Expiration period for a challenge invitation::
                </p>
                <div className="w-28">
                  <ColouredSelect
                    data={expiryTimes}
                    name="1 day"
                    selected={
                      expiryTime
                        ? expiryTime
                        : {
                            value: `${
                              challenge_timeout === 24
                                ? "1 day"
                                : challenge_timeout + " hours"
                            } `,
                            time: challenge_timeout,
                          }
                    }
                    setSelected={setExpiryTime}
                  />
                </div>
              </div>

              <div className="w-4/12 mx-auto mt-20 mb-8 absolute bottom-6 left-0 right-0">
                <Btn
                  name="Update"
                  active
                  onClick={handleUpdateLadder}
                  loading={ladder?.loading}
                  className=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReUseModal open={showRankModal} setOpen={setShowRankModal}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-customBlack space-y-1 text-[24px] font-bold text-center">
          Update Successful
        </h1>
        <p className="text-center w-10/12 max-w-[331px] mx-auto text-neutral-500 text-sm">
          The tournament details has been updated. All the players will be
          notified
        </p>
        <Btn
          active={true}
          name="Continue"
          onClick={handleContinue}
          className="mt-[49px] mb-3"
        />
      </ReUseModal>
      <ReUseModal open={showPointModal} setOpen={setShowPointModal}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-customBlack space-y-1 text-[24px] font-bold text-center">
          Update Successfull
        </h1>
        <p className="text-center w-10/12 max-w-[331px] mx-auto text-neutral-500 text-sm">
          The tournament details has been updated. All the players will be
          notified
        </p>
        <Btn
          active={true}
          name="Continue"
          onClick={handleContinue}
          className="mt-[49px] mb-3"
        />
      </ReUseModal>
    </div>
  );
};

export default EditSingleLadderDetails;
