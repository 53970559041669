import { useNavigate } from "react-router-dom";
import GeneralLayout from "../../Layouts/GeneralLayout";
import matcho from "../../assets/png/match-hero.png";
import {
  MatchScoreIcon,
  MatchInviteIcon,
  UpcomingIcon,
  InviteIcon,
} from "../../assets/svgs/club";
import LinkCard from "../../components/cards/link-card";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import { getAllMatches, getMyMatches } from "../../features/auth/AuthSlice";
import { convertDateTime, sortByDateField } from "../../utils";
import LoadingComponent from "../../components/Loading/Loader";
interface FormattedDateTime {
  date: string;
  time: string;
}
const Matches = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  useEffect(() => {
    if (user.userData?.user?.account_verify === false) {
      navigate("/email-verification");
    } else if (user?.token) {
      dispatch(getAllMatches());
      dispatch(getMyMatches());
    } else {
      navigate("/users");
    }
  }, [user.userData]);
  const [ongoingMatch, setOngoingMatch] = useState<any>([]);

  let allMatches = user?.allMatches;
  const filterNullAndUndefinedWinners = (arr: any) => {
    return arr.filter(
      (obj: { winner: null | undefined }) =>
        obj.winner === null || obj.winner === undefined
    );
  };
  const filterWinners = (arr: any) => {
    return arr.filter(
      (obj: any) => obj.winner !== null || obj.winner !== undefined
    );
  };
  const sortedArray = sortByDateField(allMatches, {
    field: "time",
  });
  useEffect(() => {
    const onGoingMatch = filterNullAndUndefinedWinners(sortedArray);
    const groupedMatch = onGoingMatch.reduce((result: any, item: any) => {
      const tournamentId = item?._tournament?._id ?? "no_tournament_id";

      result[tournamentId] = result[tournamentId] || [];

      result[tournamentId].push(item);

      return result;
    }, {});

    const groupedData = Object.entries(groupedMatch).map(([id, group]) => ({
      id,
      group,
    }));
    setOngoingMatch(groupedData);
  }, []);

  const upcoming =
    ongoingMatch.length > 0 ? ongoingMatch[0]?.group?.slice(0, 3) : [];
  function formatDateTime(inputDate: string): FormattedDateTime {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
    };
    const date: Date = new Date(inputDate);

    const dayMonthFormat: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
    };
    const formattedDate: string = date
      .toLocaleDateString("en-US", dayMonthFormat)
      .toUpperCase();

    const formattedTime: string = `${date.getHours()}:${
      date.getMinutes() < 10 ? "0" : ""
    }${date.getMinutes()}`;

    return { date: formattedDate, time: formattedTime };
  }
  const filterItemsByTime = () => {
    // Get current date and time
    const currentDate = new Date();

    // Filter out items whose deadline has not passed
    const filteredItems = upcoming.filter(
      (item: { time: string | number | Date }) => {
        // Convert item deadline string to Date object
        const matchDate = new Date(item.time);
        // Check if current date is before the item's deadline
        return currentDate <= matchDate;
      }
    );

    setUpcomingMatches(filteredItems);
  };

  useEffect(() => {
    if (upcoming?.length > 0) {
      filterItemsByTime();
    }
  }, [user?.allMatches]);

  return (
    <GeneralLayout active={2} name="Matches">
      <div className="w-full flex flex-col px-4 pb-40 md:w-[450px]">
        <div className=" flex items-center w-full justify-center ">
          <img src={matcho} alt="" />
        </div>
        <p className="text-[#2C2C2C] text-sm font-semibold mt-8">
          Quick Action
        </p>
        <div className="w-full gap-4 grid grid-cols-2 mt-4">
          <LinkCard
            name="Invite friend for a match"
            onClick={() => navigate("/user/allplayers")}
            icon={<InviteIcon />}
          />
          <LinkCard
            name="Upcoming Matches"
            onClick={() => navigate("/upcoming-matches")}
            icon={<UpcomingIcon />}
          />
          <LinkCard
            name="Match invites"
            onClick={() => navigate("/match-invite")}
            icon={<MatchInviteIcon />}
          />
          <LinkCard
            name="Match Score"
            onClick={() => navigate("/week-score")}
            icon={<MatchScoreIcon />}
          />
        </div>

        {/* <div className="mt-12"> */}
        <p className="text-md font-semibold text-[#2c2c2c] mb-4 mt-8">
          This Week’s Upcoming Matches
        </p>
        {user?.fetchLoading ? (
          <div className="mt-6 px-4 ">
            <LoadingComponent />
          </div>
        ) : (
          <div className="mb-8">
            {upcomingMatches?.length === 0 ? (
              <div className="border border-neutral-200 p-4 flex flex-col">
                <p className="text-sm font-semibold text-customBlack">
                  There are no upcoming matches
                </p>
              </div>
            ) : (
              upcomingMatches?.map((item: any, index: number) => {
                const realDayTime = convertDateTime(item?.time);

                const {
                  challenged,
                  challenger,

                  challengedB,
                  challengerB,
                } = item;

                return (
                  <div
                    key={index}
                    className="flex justify-between items-center border border-neutral-200 mb-4 p-4 rounded-[16px] gap-4 cursor-pointer w-full"
                  >
                    <div className="flex-grow  w-6/12">
                      <p className="text-sm font-semibold text-customBlack capitalize">
                        {challengerB?.firstname
                          ? `${challenger?.firstname}/${challengerB?.firstname}`
                          : challenger?.lastname
                          ? challenger?.firstname + " " + challenger?.lastname
                          : challenger?.firstname
                          ? challenger?.firstname
                          : "BYE"}
                      </p>
                      {/* <p className="font-medium text-[10px] text-neutral-400">
                            {item.playerRank}
                          </p> */}
                    </div>
                    <div className="w-[60px] max-w-[60px] min-w-[60px]">
                      <p className="text-xs font-bold text-primaryYellow">
                        {realDayTime.otherTime}
                      </p>
                      <p className="font-bold text-[10px] text-primaryYellow ">
                        {realDayTime?.otherDate}
                      </p>
                    </div>
                    <div className="flex-grow  w-6/12">
                      <p className="text-sm font-semibold text-customBlack capitalize">
                        {challengedB?.firstname
                          ? `${challenged?.firstname}/${challengedB?.firstname}`
                          : challenged?.lastname
                          ? challenged?.firstname + " " + challenged?.lastname
                          : challenged?.firstname
                          ? challenged?.firstname
                          : "BYE"}
                      </p>
                      {/* <p className="font-medium text-[10px] text-neutral-400">
                            {item.opponentRank}
                          </p> */}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        )}
        <div className="h-20 mt-6 w-full"></div>
      </div>
    </GeneralLayout>
  );
};

export default Matches;
