import { useEffect } from "react";

const RedirectedSuper = () => {
  useEffect(() => {
    window.location.replace("/super/login");
  }, []);
  return <div></div>;
};

export default RedirectedSuper;
