import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowUp } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import pImg from "../../assets/png/profile-img.png";
import { More } from "../../assets/svgs/court";
import { Btn, OutlinedBtn } from "../../components/Button";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CautionIcon } from "../../assets/svgs/tournament";
import ReUseModal from "../../components/Modal/ReUseModal";
import {
  getAllLadderPlayerUser,
  getSingleLadderDetailUser,
} from "../../features/auth/ladder-slice";
import { formatDate } from "../../utils";
import LoadingComponent from "../../components/Loading/Loader";
import { toast } from "react-toastify";

const SelectedLadderChallenge = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ladder = useAppSelector((state) => state.ladder);
  const auth = useAppSelector((state) => state.auth);
  const [players, setPlayers] = useState<any>([]);
  const [isPoint, setIsPoint] = useState(false);
  const [sortedPlayers, setSortedPlayers] = useState<any>([]);
  const [isHigher, setIsHigher] = useState(false);
  const [isLower, setIsLower] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [isSelf, setIsSelf] = useState(false);

  const id = useParams();

  useEffect(() => {
    dispatch(getSingleLadderDetailUser({ id: id.id }));
    dispatch(getAllLadderPlayerUser({ id: id.id }));
  }, []);

  useEffect(() => {
    if (ladder?.singleLadderDetail?.leaderboard?.length > 0) {
      setPlayers(ladder?.singleLadderDetail?.leaderboard);
    }
  }, [ladder?.singleLadderDetail]);
  useEffect(() => {
    if (ladder?.singleLadderDetail?._type === "point") {
      setIsPoint(true);
    } else setIsPoint(false);
  }, [ladder?.singleLadderDetail]);

  useEffect(() => {
    const sortedItems = [...players].sort((a, b) => b.points - a.points);
    setSortedPlayers(sortedItems);
  }, [players]);

  const info = ladder?.singleLadderDetail;

  const nonGreyedPlayersPoint = sortedPlayers.filter(
    (item: any) => item?.locked !== true
  );
  const nonGreyedPlayersRank = players.filter(
    (item: any) => item?.locked !== true
  );
  const userId = auth?.userData?.user?._id;
  const admin = ladder?.ladderAdmin;
  // console.log(ladder.singleLadderDetail);
  const challengePower = ladder?.singleLadderDetail?.challenge_power;

  const currentUser = players.find((player: any) => player._id?._id === userId);

  const currentUserIndexPoint = nonGreyedPlayersPoint.indexOf(currentUser);

  const handleClickPoint = (clickedPlayer: any) => {
    if (currentUser?.locked) {
      setIsLocked(true);
    } else {
      const clickedPlayerIndex = nonGreyedPlayersPoint.indexOf(clickedPlayer);

      if (currentUserIndexPoint === clickedPlayerIndex) {
        setIsSelf(true);
      } else if (clickedPlayerIndex < currentUserIndexPoint) {
        const positionDifference = currentUserIndexPoint - clickedPlayerIndex;
        if (positionDifference <= challengePower) {
          navigate(`/selected-profile/${clickedPlayer?._id?._id}`);
        } else {
          setIsHigher(true);
        }
      } else {
        setIsLower(true);
      }
    }
  };
  const currentUserIndexRank = nonGreyedPlayersRank.indexOf(currentUser);

  const handleClickRank = (clickedPlayer: any) => {
    if (currentUser?.locked) {
      setIsLocked(true);
    } else {
      const clickedPlayerIndex = nonGreyedPlayersRank?.indexOf(clickedPlayer);

      if (currentUserIndexRank === clickedPlayerIndex) {
        setIsSelf(true);
      } else if (clickedPlayerIndex < currentUserIndexPoint) {
        const positionDifference = currentUserIndexRank - clickedPlayerIndex;
        if (positionDifference <= challengePower) {
          navigate(`/selected-profile/${clickedPlayer?._id?._id}`);
        } else {
          setIsHigher(true);
        }
      } else {
        setIsLower(true);
      }
    }
  };

  const handleClick = () => {
    const formattedNumber = admin?.phone.replace(/\D/g, ""); // Remove non-digit characters
    const whatsappUrl = `https://wa.me/${formattedNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="w-full md:h-screen md:max-w-[428px] md:mx-auto flex flex-col px-4 py-6 relative min-h-screen">
      <div className="flex justify-between mt-6 mb-[30px] items-center">
        <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
        <p className="text-customBlack font-bold text-base truncate capitalize ">
          {ladder?.fetchLoading ? "" : info?.name}
        </p>
        <div className="more-shadow opacity-0">
          <More />
        </div>
      </div>
      {/* END OF THE ARROW SIDE */}

      {ladder?.fetchLoading ? (
        <div className="mt-8 px-4">
          <LoadingComponent />
        </div>
      ) : (
        <div className="w-full flex flex-col">
          <div className="profile-hero-bg flex flex-col justify-center px-6 min-w-full py-8 rounded-[24px] ">
            <div className="flex space-x-4">
              <img
                src={admin?.image}
                alt="profile avatar"
                className="w-[100px] h-[100px] rounded-[20px]"
              />
              <div className="flex flex-col justify-center">
                <p className="text-white text-base font-bold mb-1 capitalize">
                  {admin?.firstname + " " + admin?.lastname}
                </p>
                <p className="text-xs text-white mb-2">Ladder Administrator</p>
                <p className="text-xs text-white mb-2 capitalize">
                  {" "}
                  {ladder?.singleLadderDetail?._type} Ladder{" "}
                </p>
                <p
                  className="text-sm text-white mb-2 cursor-pointer "
                  onClick={handleClick}
                >
                  Chat Admin
                </p>
              </div>
            </div>
          </div>
          {/* END OF THE PROFILE HEADER */}
          <div className="flex flex-col w-full mt-12 ">
            <div className="flex justify-between items-center">
              <p className="text-[14px] text-[#2c2c2c] font-[600]">
                Ladder Board
              </p>
              <p
                className="text-[12px] text-[#ACACAC] font-[600] cursor-pointer"
                onClick={() => navigate(`/players-list/${id.id}`)}
              >
                view more
              </p>
            </div>
            {!isPoint && (
              <div className="mt-6 flex flex-col space-y-4">
                {players?.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className={`w-full h-20 border-border border rounded-md flex items-center justify-between px-4 ${
                        item?._id?._id === userId ? "bg-[#FFEECC]" : "bg-white"
                      } ${item?.locked ? "opacity-50" : "opacity-100"} `}
                      onClick={() => {
                        if (item?.locked) {
                          toast.error("Player Not Available");
                        } else {
                          handleClickRank(item);
                        }
                      }}
                    >
                      <div className="flex items-center space-x-3">
                        <div
                          className={`w-[44px] h-[44px] rounded-full  text-base font-semibold flex items-center justify-center ${
                            item?._id?._id === userId
                              ? "bg-[#FFA800] text-[#fff]"
                              : "bg-[#ffeecc] text-[#eb9b00]"
                          } `}
                        >
                          {index + 1}
                        </div>

                        <div className="flex flex-col">
                          <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                            {`${item?._id?.firstname} ${
                              item?._id?.lastname ? item?._id?.lastname : ""
                            }`}
                          </p>
                          {/* <p className="text-[12px] text-[#acacac]">
                            {item?.locked ? "Not Available" : "Available"}
                          </p> */}
                        </div>
                      </div>
                      <div className="  w-3/12 grid grid-cols-3  ">
                        {/* start */}
                        <div className="flex flex-col gap-2">
                          <p className="text-[12px] text-[#acacac]">P</p>
                          <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                            {item?.matches}
                          </p>
                        </div>
                        {/* end */}
                        {/* start */}
                        <div className="flex flex-col gap-2">
                          <p className="text-[12px] text-[#acacac]">W</p>
                          <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                            {item?.win}
                          </p>
                        </div>
                        {/* end */}
                        {/* start */}
                        <div className="flex flex-col gap-2">
                          <p className="text-[12px] text-[#acacac]">L</p>
                          <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                            {item?.loss}
                          </p>
                        </div>
                        {/* end */}
                        {/* start */}
                        {/* <div className="flex flex-col gap-2">
                          <p className="text-[12px] text-[#acacac]">
                           Pts
                          </p>
                          <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                            {item?.points}
  </p>
                        </div> */}
                        {/* end */}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {isPoint && (
              <div className="mt-6 flex flex-col space-y-4">
                {sortedPlayers?.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className={`w-full h-20 border-border border rounded-md flex items-center justify-between px-4 ${
                        item?._id?._id === userId ? "bg-[#FFEECC]" : "bg-white"
                      } ${item?.locked ? "opacity-50" : "opacity-100"} `}
                      onClick={() => {
                        if (item?.locked) {
                          toast.error("Player Not Available");
                        } else {
                          handleClickPoint(item);
                        }
                      }}
                    >
                      <div className="flex items-center space-x-3">
                        <div
                          className={`w-[44px] h-[44px] rounded-full  text-base font-semibold flex items-center justify-center ${
                            item?._id?._id === userId
                              ? "bg-[#FFA800] text-[#fff]"
                              : "bg-[#ffeecc] text-[#eb9b00]"
                          } `}
                        >
                          {index + 1}
                        </div>

                        <div className="flex flex-col">
                          <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                            {`${item?._id?.firstname} ${
                              item?._id?.lastname ? item?._id?.lastname : ""
                            }`}
                          </p>

                          {/* <p className="text-[12px] text-[#acacac]">
                            {item?.locked ? "Not Available" : "Available"}
                          </p> */}
                        </div>
                      </div>
                      <div className="  w-4/12 grid grid-cols-4  ">
                        {/* start */}
                        <div className="flex flex-col gap-2">
                          <p className="text-[12px] text-[#acacac]">P</p>
                          <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                            {item?.matches}
                          </p>
                        </div>
                        {/* end */}
                        {/* start */}
                        <div className="flex flex-col gap-2">
                          <p className="text-[12px] text-[#acacac]">W</p>
                          <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                            {item?.win}
                          </p>
                        </div>
                        {/* end */}
                        {/* start */}
                        <div className="flex flex-col gap-2">
                          <p className="text-[12px] text-[#acacac]">L</p>
                          <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                            {item?.loss}
                          </p>
                        </div>
                        {/* end */}
                        {/* start */}
                        <div className="flex flex-col gap-2">
                          <p className="text-[12px] text-[#acacac]">Pts</p>
                          <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                            {item?.points}
                          </p>
                        </div>
                        {/* end */}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className={`flex flex-col mt-8 pb-6`}>
            <p className="text-center text-[12px] text-[#acacac] mt-11 mb-4">
              Starts {formatDate(info?.start)} . Ends {formatDate(info?.stop)}
            </p>
            <OutlinedBtn
              name="View Ladder Rules"
              active={true}
              onClick={() => navigate(`/view-rules/ladder/${id.id}`)}
            />
          </div>
        </div>
      )}
      <ReUseModal open={isHigher} setOpen={setIsHigher}>
        <div className="w-full flex flex-col">
          <div className="flex items-center justify-center mt-10">
            <CautionIcon />
          </div>
          <h1 className="text-center mt-2 font-bold text-[22px] leading-[24px] text-customBlack ">
            Can't Challenge
          </h1>
          <p className="text-[#868686] mt-1 text-sm text-center max-w-[340px] mx-auto">
            {` Sorry you can only challenge players that are ${
              challengePower || ""
            } steps ahead of you on the table `}
          </p>
          <div className="w-full flex items-center justify-center  gap-6 mt-16">
            <Btn name="Close" onClick={() => setIsHigher(false)} active />
          </div>
        </div>
      </ReUseModal>
      <ReUseModal open={isLocked} setOpen={setIsLocked}>
        <div className="w-full flex flex-col">
          <div className="flex items-center justify-center mt-10">
            <CautionIcon />
          </div>
          <h1 className="text-center mt-2 font-bold text-[22px] leading-[24px] text-customBlack ">
            Can't Challenge Player
          </h1>
          <p className="text-[#868686] mt-1 text-sm text-center max-w-[340px] mx-auto">
            Unfortunately, you are currently marked as unavailable for the
            competition. Please contact the admin for further assistance or more
            information
          </p>
          <div className="w-full flex items-center justify-center  gap-6 mt-16">
            <Btn name="Close" onClick={() => setIsLocked(false)} active />
          </div>
        </div>
      </ReUseModal>
      <ReUseModal open={isLower} setOpen={setIsLower}>
        <div className="w-full flex flex-col">
          <div className="flex items-center justify-center mt-10">
            <CautionIcon />
          </div>
          <h1 className="text-center mt-2 font-bold text-[22px] leading-[24px] text-customBlack ">
            Can't Challenge
          </h1>
          <p className="text-[#868686] mt-1 text-sm text-center max-w-[340px] mx-auto">
            Sorry you can't challenge players that are below you on the table
          </p>
          <div className="w-full flex items-center justify-center  gap-6 mt-16">
            <Btn name="Close" onClick={() => setIsLower(false)} active />
          </div>
        </div>
      </ReUseModal>
      <ReUseModal open={isSelf} setOpen={setIsSelf}>
        <div className="w-full flex flex-col">
          <div className="flex items-center justify-center mt-10">
            <CautionIcon />
          </div>
          <h1 className="text-center mt-2 font-bold text-[22px] leading-[24px] text-customBlack ">
            Can't Challenge Self
          </h1>
          <p className="text-[#868686] mt-1 text-sm text-center max-w-[340px] mx-auto">
            Sorry you cannot challenge yourself
          </p>
          <div className="w-full flex items-center justify-center  gap-6 mt-16">
            <Btn name="Close" onClick={() => setIsSelf(false)} active />
          </div>
        </div>
      </ReUseModal>
    </div>
  );
};

export default SelectedLadderChallenge;
