import DashboardLayout from "../../../Layouts/DashboardLayout";
// import ScoreBoard from "../../../components/Others/Knockout/ScoreboardFCFS";
import { useNavigate } from "react-router-dom";
import { NavigationArrow } from "../../../assets/svgs/admin";

import TeamMembersTable from "../../../components/Tables/AdminTables/TournamentTables/TeamMembersTable";
const TeamMembersPage = () => {
  const navigate = useNavigate();
  return (
    <DashboardLayout current={3}>
      <div className="w-full flex flex-col bg-[#f4f4f4] h-full p-[30px] 2xl:p-8 2xl:pr-[50px] ">
        <div className="flex w-fit" onClick={() => navigate(-1)}>
          <NavigationArrow />
        </div>
        <TeamMembersTable />
      </div>
    </DashboardLayout>
  );
};

export default TeamMembersPage;
