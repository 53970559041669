import DashboardLayout from "../../../Layouts/DashboardLayout";
import { NavigationArrow } from "../../../assets/svgs/admin";
import AllLadderPlayersTables from "../../../components/Tables/AdminTables/TournamentTables/AllLadderPlayersTable";
import { useNavigate } from "react-router-dom";
import AllPlayersTablePointLadder from "../../../components/Tables/AdminTables/TournamentTables/AllPlayersTablePointLadder";
const ViewAllPlayersPointLadder = () => {
  const navigate = useNavigate();
  return (
    <DashboardLayout current={3}>
      <div className="w-full bg-[#f4f4f4] flex flex-col p-[30px] 2xl:p-8">
        <div className="flex w-full justify-between items-center">
          <div
            className="flex w-fit cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <NavigationArrow />
          </div>
        </div>
        <AllPlayersTablePointLadder className="mt-6" />
      </div>
    </DashboardLayout>
  );
};

export default ViewAllPlayersPointLadder;
