import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSimplifiedError } from "../../utils";
import { APIService, nAPIService } from "../../utils/APIServices";
import { url } from "../../utils/endpoints";
export interface AuthState {
  invitationCode: string;
  invitationCodeSuccess: boolean;
  loading: boolean;
  fetchLoading: boolean;
  token: string;
  data: any;
  allClubs?: any;
  clubsLoading: boolean;
  error: any;
  userData: any;
  registerSuccess: boolean;
  loginSuccess: boolean;
  verified: boolean;
  userEmail: string;
  resendOtpSuccess: boolean;
  otpSent: boolean;
  forgetToken: string;
  forgetOtpSent: boolean;
  verificationEmail: string;
  forgetPasswordEmail: string;
  resetPasswordSuccessful: boolean;
  playerNumber: number;
  createClubSuccess: boolean;
  allClubMembers: any;
  allPendingMembers: any;
  allAcceptedMembers: any;
  allRejectedMembers: any;
  clubDeatails: any;
  updateMemberSuccess: boolean;
  updateClubSuccess: boolean;
  editProfileSuccess: boolean;
}

const initialState: AuthState = {
  invitationCode: "",
  invitationCodeSuccess: false,
  playerNumber: 8,
  loading: false,
  token: "",
  data: [],
  allClubs: [],
  clubsLoading: false,
  error: {},
  userData: [],
  registerSuccess: false,
  loginSuccess: false,
  verified: false,
  userEmail: "",
  verificationEmail: "",
  resendOtpSuccess: false,
  otpSent: false,
  forgetToken: "",
  forgetOtpSent: false,
  forgetPasswordEmail: "",
  resetPasswordSuccessful: false,
  createClubSuccess: false,
  allClubMembers: [],
  allPendingMembers: [],
  allAcceptedMembers: [],
  allRejectedMembers: [],
  clubDeatails: {},
  updateMemberSuccess: false,
  updateClubSuccess: false,
  fetchLoading: false,
  editProfileSuccess: false,
};

export const adminAuthSlice = createSlice({
  name: "adminAuth",
  initialState,
  reducers: {
    restoreDefault: (state) => {
      state.loading = false;
      state.registerSuccess = false;
      state.createClubSuccess = false;
      state.loginSuccess = false;
      state.clubsLoading = false;
      state.userData = [];
      state.verified = false;
      state.token = "";
    },
    resetInvitation: (state) => {
      state.invitationCodeSuccess = false;
      state.loading = false;
    },
    resetAll: (state) => {
      state.loading = false;
      state.registerSuccess = false;
      state.loginSuccess = false;
      state.clubsLoading = false;
      state.userData = [];
      state.token = "";
    },
    resetMemberState: (state) => {
      state.updateMemberSuccess = false;
    },
    resetClubUpdate: (state) => {
      state.updateClubSuccess = false;
    },
    resetProfileUpdate: (state) => {
      state.editProfileSuccess = false;
    },
    resetClubSuccess: (state) => {
      state.createClubSuccess = false;
      state.userData = [];
    },

    setVerifyEmail: (state, action: PayloadAction<string>) => {
      state.verificationEmail = action.payload;
    },
    setPlayerNumber: (state, action: PayloadAction<any>) => {
      state.playerNumber = action.payload;
    },
    setForgetEmail: (state, action: PayloadAction<string>) => {
      state.forgetPasswordEmail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClubs.pending, (state) => {
        state.clubsLoading = true;
      })
      .addCase(fetchClubs.fulfilled, (state, { payload }) => {
        state.clubsLoading = false;
        state.allClubs = payload;
      })
      .addCase(fetchClubs.rejected, (state, { payload }) => {
        state.clubsLoading = false;
        state.error = payload;
      })
      .addCase(adminRegister.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminRegister.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userData = payload;
        state.token = payload.token;
        state.registerSuccess = true;
        state.verificationEmail = payload.user?.email;
      })
      .addCase(adminRegister.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.registerSuccess = false;
      })
      .addCase(loginAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginAdmin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userData = payload;
        state.token = payload.token;
        state.loginSuccess = true;
      })
      .addCase(loginAdmin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.loginSuccess = false;
      })
      .addCase(verifyAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyAdmin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.otpSent = true;
      })
      .addCase(verifyAdmin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.otpSent = false;
      })
      .addCase(resendOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(resendOtp.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.resendOtpSuccess = true;
      })
      .addCase(resendOtp.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.resendOtpSuccess = false;
      })
      .addCase(verifyAdminOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyAdminOtp.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.verified = true;
      })
      .addCase(verifyAdminOtp.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.verified = false;
      })
      .addCase(adminForgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminForgotPassword.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.forgetOtpSent = true;
      })
      .addCase(adminForgotPassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.forgetOtpSent = false;
      })
      .addCase(adminForgotPasswordOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminForgotPasswordOtp.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.forgetToken = payload.token;
      })
      .addCase(adminForgotPasswordOtp.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.verified = false;
      })
      .addCase(adminResetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminResetPassword.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.resetPasswordSuccessful = true;
      })
      .addCase(adminResetPassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.resetPasswordSuccessful = false;
      })
      .addCase(getInvitationCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvitationCode.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.invitationCode = payload.data;
      })
      .addCase(getInvitationCode.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(generateNewInvitationCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(generateNewInvitationCode.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.invitationCode = payload.data;
      })
      .addCase(generateNewInvitationCode.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.resetPasswordSuccessful = false;
      })
      .addCase(sendInvitationCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendInvitationCode.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.invitationCodeSuccess = true;
      })
      .addCase(sendInvitationCode.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.invitationCodeSuccess = false;
      })
      .addCase(createClub.pending, (state) => {
        state.loading = true;
      })
      .addCase(createClub.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.createClubSuccess = true;
      })
      .addCase(createClub.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createClubSuccess = false;
      })
      .addCase(getAllClubMembers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllClubMembers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allClubMembers = payload.data;
      })
      .addCase(getAllClubMembers.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getClubDetails.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(getClubDetails.fulfilled, (state, { payload }) => {
        state.clubDeatails = payload.data;
        state.fetchLoading = false;
      })
      .addCase(getClubDetails.rejected, (state, { payload }) => {
        state.fetchLoading = false;
        state.error = payload;
      })
      .addCase(getAllPendingMember.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPendingMember.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allPendingMembers = payload.data;
      })
      .addCase(getAllPendingMember.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(updateMemberState.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMemberState.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.updateMemberSuccess = true;
      })
      .addCase(updateMemberState.rejected, (state, { payload }) => {
        state.loading = false;
        state.updateMemberSuccess = false;
      })
      .addCase(updateClubDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateClubDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.updateClubSuccess = true;
      })
      .addCase(updateClubDetails.rejected, (state, { payload }) => {
        state.loading = false;
        state.updateClubSuccess = false;
      })
      .addCase(editAdminProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(editAdminProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.editProfileSuccess = true;
      })
      .addCase(editAdminProfile.rejected, (state, { payload }) => {
        state.loading = false;
      });
  },
});

export const fetchClubs = createAsyncThunk(
  "allClubs",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await APIService.get(`${url.clubs}`);
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const adminRegister = createAsyncThunk(
  "adminRegister",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.adminRegister}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const loginAdmin = createAsyncThunk(
  "loginAdmin",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.adminLogin}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const verifyAdmin = createAsyncThunk(
  "verifyAdmin",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.verifyAdmin}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const verifyAdminOtp = createAsyncThunk(
  "verifyAdminOtp",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.adminVerifyOtp}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const resendOtp = createAsyncThunk(
  "resendOtp",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.adminResendOtp}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const adminForgotPassword = createAsyncThunk(
  "adminForgotPassword",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(
        `${url.adminForgotPassword}`,
        payload
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const adminForgotPasswordOtp = createAsyncThunk(
  "adminForgotPasswordOtp",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(
        `${url.adminVerifyForgotOtp}`,
        payload
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const updateClubDetails = createAsyncThunk(
  "updateClubDetails",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();
    try {
      const { data } = await APIService.put(`${url.updateClub}`, payload, {
        headers: {
          Authorization: `Bearer ${adminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const editAdminProfile = createAsyncThunk(
  "editAdminProfile",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();
    try {
      const { data } = await APIService.put(
        `${url.editProfileAdmin}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const adminResetPassword = createAsyncThunk(
  "adminResetasswordOtp",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();
    try {
      const { data } = await APIService.put(
        `${url.adminResetPassword}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.forgetToken}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const getInvitationCode = createAsyncThunk(
  "getInvitationCode",
  async (_, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();
    try {
      const { data } = await APIService.get(
        `${url.getInvitationCode}`,

        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const generateNewInvitationCode = createAsyncThunk(
  "generateNewInvitationCode",
  async (_, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();
    try {
      const { data } = await APIService.post(
        `${url.generateNewInvitationCode}`,

        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const sendInvitationCode = createAsyncThunk(
  "sendInvitationCode",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.sendInvitationCode}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const createClub = createAsyncThunk(
  "createClub",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(`${url.createClub}`, payload, {
        headers: {
          Authorization: `Bearer ${adminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getClubDetails = createAsyncThunk(
  "getClubDetails",
  async (_, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.getClubProfile}`, {
        headers: {
          Authorization: `Bearer ${adminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getAllClubMembers = createAsyncThunk(
  "getAllClubMembers",
  async (_, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.getAllClubMembers}`, {
        headers: {
          Authorization: `Bearer ${adminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getAllPendingMember = createAsyncThunk(
  "getAllPendingMember",
  async (_, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.allPendingMember}`, {
        headers: {
          Authorization: `Bearer ${adminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const updateMemberState = createAsyncThunk(
  "updateMemberState",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      let req = {
        method: "put",
        address: `${url.updateMemberState}${payload.id}/${payload.state}`,
        header: `Authorization: Bearer ${adminAuth?.token}`,
      };
      const { data } = await nAPIService(req.method, req.address, req.header);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const adminAuthSelector = (state: any) => state.adminAuth;

export const {
  restoreDefault,
  resetAll,
  setVerifyEmail,
  setForgetEmail,
  resetInvitation,
  setPlayerNumber,
  resetMemberState,
  resetClubSuccess,
  resetClubUpdate,
  resetProfileUpdate,
} = adminAuthSlice.actions;
export default adminAuthSlice.reducer;
