import SuperAdminLayout from "../../Layouts/SuperAdminLayout";
import ManageTable from "../../components/Tables/AdminTables/ManageAdminTable";

const ManageAdmin = () => {
  return (
    <SuperAdminLayout current={2}>
      <div className="w-full flex flex-col bg-[#f4f4f4] p-[30px] 2xl:p-8 ">
        <h1 className="text-lg font-[600] text-customBlack">Manage Admin</h1>
        <div className="w-full flex-col flex">
          <ManageTable className="mt-8" />
        </div>
      </div>
    </SuperAdminLayout>
  );
};

export default ManageAdmin;
