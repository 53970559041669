/* This example requires Tailwind CSS v2.0+ */
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import { IoIosArrowDown } from "react-icons/io";
import defaultImage from "../../assets/png/default-png.png";
interface selectProps {
  selected: any;
  setSelected: any;
  options: any;
  name?: string;
  height?: string;
  noBorder?: boolean;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function TeamSelect({
  selected,
  setSelected,
  options,
  name,
  height,
  noBorder,
}: selectProps) {
  // const [selected, setSelected] = useState(people[3])

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <div className="flex flex-col w-full">
          <div className="mt-1 relative w-full">
            <Listbox.Button
              className={`${
                height ? height : "h-[49px] bg-white"
              } relative w-full ${
                noBorder
                  ? "border-none outline-none"
                  : "border  border-border focus:ring-1 focus:ring-[#7BC133] focus:border-[#7BC133] shadow-sm"
              }   rounded-md  pl-3 pr-10  text-left cursor-default focus:outline-none  sm:text-sm`}
            >
              <span
                className={` ${
                  noBorder ? "text-neutral-700 font-semibold" : ""
                } block truncate capitalize`}
              >
                {selected?.firstname ?? ""} {selected?.lastname ?? ""}
              </span>
              <p className="text-neutral-400 text-xs capitalize leading-[16px]">
                {selected?.groupName}
              </p>
              <span className="absolute inset-y-0 my-auto right-0 flex items-center pr-2 pointer-events-none">
                <IoIosArrowDown
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-[12px]  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm flex flex-col">
                <div className="w-full pt-3 pb-2 pl-4 border-b-[0.6px] border-b-border">
                  <p className="text-xs text-neutral-600 font-medium">
                    NAMES OF PLAYERS
                  </p>
                </div>
                {options?.map((person: any, index: number) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "text-[#0e0e0e] bg-[#EBF6D4] "
                          : "text-gray-900",
                        "cursor-default select-none relative py-2 pl-4 pr-4 "
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <div className="flex items-center gap-2">
                        <img
                          src={person?.image}
                          alt=""
                          onError={(e: any) => {
                            e.target.onerror = null; // Prevent looping
                            e.target.src = defaultImage;
                          }}
                          className="w-[34px] h-[34px] rounded-full "
                        />
                        <div className="flex flex-col">
                          <span
                            className={classNames(
                              selected
                                ? "font-semibold text-sm text-customBlack"
                                : "font-normal capitalize text-sm text-customBlack",
                              "block truncate leading-[20px] "
                            )}
                          >
                            {person?.firstname} {person?.lastname ?? ""}
                          </span>
                          <p className="text-xs leading-[16px] text-neutral-400 capitalize">
                            {person?.groupName}
                          </p>
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}
