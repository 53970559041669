import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { getSingleCompetitionDetailAdmin } from "../../../../features/auth/TournamentSlice";
import LoadingComponent from "../../../Loading/Loader";

const TeamPointOverviewTable = () => {
  const tournament = useAppSelector((state) => state.tournament);
  const dispatch = useAppDispatch();
  const id = useParams();

  useEffect(() => {
    dispatch(getSingleCompetitionDetailAdmin({ id: id.id }));
  }, []);

  const details = tournament?.competitionDetailAdmin;

  if (tournament?.fetchLoading) {
    return (
      <div className="w-full p-5">
        <LoadingComponent />
      </div>
    );
  }
  return (
    <div className="bg-white rounded-[20px] flex flex-col  h-screen  mt-6 flow-hide  ">
      <div className="flex-grow">
        <div className="">
          <div className="pl-5 pt-[22px] pb-3 flex justify-between">
            <p className="font-semibold text-customBlack text-sm sm:text-md capitalize">
              {" "}
              {details?.name} Single Day Competition
            </p>
          </div>
        </div>
        {/* breaker line */}
        <div className="w-full h-[1px] bg-[#cfcfcf] " />

        <table className="w-full flex flex-col border border-border bg-white  ">
          <thead className="w-full bg-[#f4f4f4] bg-opacity-40 ">
            <tr className="flex items-center h-[35px] pl-5">
              <th className="w-auto flex flex-grow theader text-neutral-700 text-xs font-[500] tracking-[0.12px] gap-6 items-center">
                <span>SN</span>
                <span>Names</span>
              </th>
              <th className="w-1/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left ">
                Points
              </th>
              <th className="w-1/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left">
                Matches
              </th>
              <th className="w-1/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left">
                Wins
              </th>
              <th className="w-1/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left">
                Losses
              </th>
              <th className="w-1/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left">
                Win(%)
              </th>
            </tr>
          </thead>
          <tbody>
            {details?.teams?.map((item: any, index: any) => {
              return (
                <tr className="h-[49px] items-center flex  border-b border-b-border ">
                  <td className="w-auto flex flex-grow gap-6  items-center pl-5 ">
                    <p className="text-customBlack text-sm font-medium">
                      {index + 1}
                    </p>
                    <p className="text-customBlack text-sm font-medium">
                      {item?.name}
                    </p>
                  </td>
                  <td className="w-1/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left pl-2">
                    <p className="text-customBlack text-sm font-medium">
                      {Number(item?.points * 3)}
                    </p>
                  </td>
                  <td className="w-1/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left pl-2">
                    <p className="text-customBlack text-sm font-medium">
                      {item?.matches?.length}
                    </p>
                  </td>
                  <td className="w-1/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left pl-2">
                    <p className="text-customBlack text-sm font-medium">
                      {item?.win}
                    </p>
                  </td>
                  <td className="w-1/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left pl-2">
                    <p className="text-customBlack text-sm font-medium">
                      {Number(item?.matches?.length - item?.win)}
                    </p>
                  </td>
                  <td className="w-1/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left pl-2">
                    <p className="text-customBlack text-sm font-medium">
                      {(Number(item?.win) / Number(item?.matches?.length)) *
                        100}
                      %
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamPointOverviewTable;
