import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import GeneralLayout from "../../Layouts/GeneralLayout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ArrowRight, RankCircle } from "../../assets/svgs/Icons";
import {
  AllInvitationIcon,
  MatchScoreIcon,
  MyMatchIcon,
} from "../../assets/svgs/card-icons";
import ProfileCircle from "../../assets/svgs/profilecircle.svg";
import LinkCard from "../../components/cards/link-card";
import {
  fetchQuotes,
  getAllMatches,
  getClubDetail,
  getEvaluationStatus,
  setIsEvaluated,
  updateUserData,
} from "../../features/auth/AuthSlice";
import { convertDateTime, sortByDateField } from "../../utils";

import LoadingComponent from "../../components/Loading/Loader";
import ReUseModal from "../../components/Modal/ReUseModal";

const MainHome = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const users = useAppSelector((state) => state.auth);

  const [profileComplete, setProfileComplete] = useState(false);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  const [ongoingMatch, setOngoingMatch] = useState<any>([]);

  // Handle redirection if redirectUrl is available
  useEffect(() => {
    if (users?.redirectUrl) {
      navigate(users.redirectUrl);
    }
  }, [users?.redirectUrl, navigate]);

  // Fetch user-related data
  useEffect(() => {
    if (users?.profileData?._id) {
      const data = {
        user: users?.profileData,
        success: true,
      };
      dispatch(updateUserData(data));
    }
    if (users.userData?.user?.account_verify === false) {
      navigate("/email-verification");
    } else if (users?.token) {
      dispatch(getClubDetail());

      dispatch(getAllMatches());

      dispatch(fetchQuotes());
    } else {
      navigate("/");
    }
  }, [users?.token, users.userData?.user?.account_verify, navigate, dispatch]);

  // Handle evaluation status
  useEffect(() => {
    if (users?.userData?.user?.rank === 0) {
      dispatch(getEvaluationStatus());
    } else {
      dispatch(setIsEvaluated());
    }
  }, [users?.userData?.user?.rank, dispatch]);
  const filterNullAndUndefinedWinners = (arr: any) => {
    return arr.filter(
      (obj: { winner: null | undefined }) =>
        obj.winner === null || obj.winner === undefined
    );
  };
  // Set ongoing matches
  useEffect(() => {
    const allMatches = users?.allMatches;
    const sortedArray = sortByDateField(allMatches, { field: "time" });
    const onGoingMatch = filterNullAndUndefinedWinners(sortedArray);
    const groupedMatch = onGoingMatch.reduce((result: any, item: any) => {
      const tournamentId = item?._tournament?._id ?? "no_tournament_id";
      result[tournamentId] = result[tournamentId] || [];
      result[tournamentId].push(item);
      return result;
    }, {});

    const groupedData = Object.entries(groupedMatch).map(([id, group]) => ({
      id,
      group,
    }));
    setOngoingMatch(groupedData);
  }, [users?.allMatches]);

  // Memoize profile completeness
  const isProfileComplete = useMemo(() => {
    const data = users?.userData?.user;
    return data?.dateOfBirth && data?.phone && data?.gender && data?.address;
  }, [users?.userData?.user]);

  useEffect(() => {
    setProfileComplete(isProfileComplete);
  }, [isProfileComplete]);

  // Filter upcoming matches
  useEffect(() => {
    if (ongoingMatch.length > 0) {
      const upcoming = ongoingMatch[0]?.group?.slice(0, 3) || [];
      const currentDate = new Date();
      const filteredItems = upcoming.filter(
        (item: { time: string | number | Date }) => {
          const matchDate = new Date(item.time);
          return currentDate <= matchDate;
        }
      );
      setUpcomingMatches(filteredItems);
    }
  }, [ongoingMatch]);

  // Update user data
  useEffect(() => {
    if (users?.profileData?._id) {
      const data = {
        user: users.profileData,
        success: true,
      };
      dispatch(updateUserData(data));
    }
  }, [users?.profileData?._id, dispatch]);
  const qoutes = users?.allQoutes;

  return (
    <GeneralLayout name="Hello" greetings="Welcome back!" active={1}>
      <div className="w-full flex flex-col px-4 pb-40 md:w-[450px]">
        <div className="home-hero-bg rounded-[24px] flex flex-col justify-center px-8 min-w-full py-8 ">
          <p className="text-white text-base font-bold mb-1 capitalize">
            {users?.profileData?._club?.name}
          </p>
          {/* <SwitchItem /> */}
        </div>
        <p className="text-[#2C2C2C] text-sm font-semibold mt-8">
          Quick Action
        </p>
        <div className="w-full gap-4 grid grid-cols-2 mt-4">
          <LinkCard
            name="All Invitations"
            onClick={() => navigate("/all-invitations")}
            icon={<AllInvitationIcon />}
          />
          <LinkCard
            name="My Matches"
            onClick={() => navigate("/my-matches")}
            icon={<MyMatchIcon />}
          />
          <LinkCard
            name="Match Score"
            onClick={() => navigate("/week-score")}
            icon={<MatchScoreIcon />}
          />
        </div>

        <div className="mt-12">
          {/* <p className="text-md font-semibold text-[#2c2c2c] mb-4">
            Complete your Profile
          </p> */}
          <div className="flex flex-col w-full gap-y-4">
            {!profileComplete && (
              <div
                className="border h-[68px] flex items-center px-4 border-[#f4f4f4] cursor-pointer  space-x-4 rounded-[16px] w-full justify-between"
                onClick={() => navigate("/complete-profile")}
              >
                <div className="flex space-x-3 items-center">
                  <span>
                    <img src={ProfileCircle} alt="" />
                  </span>
                  <p className="text-sm text-customBlack font-medium">
                    Complete your profile
                  </p>
                </div>
                <span>
                  <ArrowRight />
                </span>
              </div>
            )}

            {!users?.isEvaluated && (
              <div
                className="border h-[68px] flex items-center px-4 border-[#f4f4f4] cursor-pointer  space-x-4 rounded-[16px] w-full justify-between"
                onClick={() => navigate("/rank")}
              >
                <div className="flex space-x-3 items-center">
                  <span>
                    <RankCircle />
                  </span>
                  <p className="text-sm text-customBlack font-medium">
                    Rank your Tennis
                  </p>
                </div>
                <span>
                  <ArrowRight />
                </span>
              </div>
            )}

            <h2 className="mb-4 mt-[30px] font-semibold text-neutral-700 text-sm">
              Upcoming Matches
            </h2>

            {users?.fetchLoading ? (
              <div className="mt-6 px-4 ">
                <LoadingComponent />
              </div>
            ) : (
              <div className="mb-8">
                {upcomingMatches?.length === 0 ? (
                  <div className="border border-neutral-200 p-4 flex flex-col">
                    <p className="text-sm font-semibold text-customBlack">
                      There are no upcoming matches
                    </p>
                  </div>
                ) : (
                  upcomingMatches?.map((item: any, index: number) => {
                    const realDayTime = convertDateTime(item?.time);

                    const {
                      challenged,
                      challenger,

                      challengedB,
                      challengerB,
                    } = item;

                    return (
                      <div
                        key={index}
                        className="flex between items-center border border-neutral-200 mb-4 p-4 rounded-xl gap-4"
                      >
                        <div className="flex-grow  w-6/12 ">
                          <p className="text-sm font-semibold text-customBlack capitalize">
                            {challengerB?.firstname
                              ? `${challenger?.firstname}/${challengerB?.firstname}`
                              : challenger?.lastname
                              ? challenger?.firstname +
                                " " +
                                challenger?.lastname
                              : challenger?.firstname
                              ? challenger?.firstname
                              : "BYE"}
                          </p>
                          {/* <p className="font-medium text-[10px] text-neutral-400">
                            {item.playerRank}
                          </p> */}
                        </div>
                        <div className="w-[60px] max-w-[60px] min-w-[60px]">
                          <p className="text-xs font-bold text-primaryYellow">
                            {realDayTime.otherTime}
                          </p>
                          <p className="font-bold text-[10px] text-primaryYellow ">
                            {realDayTime?.otherDate}
                          </p>
                        </div>
                        <div className="flex-grow  w-6/12">
                          <p className="text-sm font-semibold text-customBlack capitalize">
                            {challengedB?.firstname
                              ? `${challenged?.firstname}/${challengedB?.firstname}`
                              : challenged?.lastname
                              ? challenged?.firstname +
                                " " +
                                challenged?.lastname
                              : challenged?.firstname
                              ? challenged?.firstname
                              : "BYE"}{" "}
                          </p>
                          {/* <p className="font-medium text-[10px] text-neutral-400">
                            {item.opponentRank}
                          </p> */}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            )}

            <h2 className="mb-4 font-semibold text-customBlack text-sm mt-[30px]">
              Tennis Quote
            </h2>
            <div className="border-2 rounded-xl border-primaryYellow p-5 mb-20 md:w-[400px] md:mb-2">
              {qoutes?.length > 0 ? (
                qoutes?.map((item: any, index: number) => (
                  <div key={index}>
                    <p className="text-xs font-regular leading-6 text-neutral-600">
                      {item.message}
                    </p>
                    <p className="text-sm font-medium text-neutral-600 capitalize">
                      - {item.author}{" "}
                    </p>
                  </div>
                ))
              ) : (
                <p className="text-xs font-regular leading-6 text-neutral-600">
                  No quotes available
                </p>
              )}
            </div>
          </div>
          {/* <p className="text-md font-semibold text-[#2c2c2c] mb-4 mt-8">
            Tennis Quote
          </p> */}
          {/* <div className="w-full  border border-ash p-6 flex flex-col">
            <p>
              {qoutes}
            </p>
            <p>- {writer}</p>
          </div> */}
          <div className="h-28 w-full"></div>
        </div>
      </div>
    </GeneralLayout>
  );
};

export default MainHome;
