import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getMatchInTournament,
  gotoNextRound,
  resetAll,
  resetScoreSuccess,
} from "../../../features/auth/TournamentSlice";
import { Btn } from "../../Button";
import ReUseModal from "../../Modal/ReUseModal";

const ScoreBoard = () => {
  const dispatch = useAppDispatch();
  const tournament = useAppSelector((state) => state.tournament);
  const id = useParams();
  const [, setOpenSuccesModal] = useState(false);
  let tid = id?.id;
  const matches = tournament?.matchInTournament?.match;

  const tournamentStatus = tournament?.matchInTournament?.tournament?.status;

  const navigate = useNavigate();
  const [currentRound, setCurrentRound] = useState(1);
  const [currentMatch, setCurrentMatch] = useState([]);

  const [nextRound, setNextRound] = useState(2);

  let maxRound = 0;
  matches?.forEach((item: any) => {
    if (item.round > maxRound) {
      maxRound = item.round;
    }
  });
  const listOfRounds = Array.from({ length: maxRound }, (_, i) => i + 1);

  useEffect(() => {
    const getMatchesRound = matches?.filter((item: any) => {
      return item.round === currentRound;
    });

    setCurrentMatch(getMatchesRound);
  }, [currentRound, matches]);

  useEffect(() => {
    dispatch(getMatchInTournament({ id: id.id }));
    dispatch(resetAll());
    dispatch(resetScoreSuccess());
  }, []);

  useEffect(() => {
    if (tournament.updateMatchScoreSuccess) {
      setOpenSuccesModal(true);

      dispatch(resetAll());
    }
  }, [tournament.updateMatchScoreSuccess]);

  useEffect(() => {
    if (tournament.nextRoundSuccess) {
      toast.success("You have moved to the next round successfully");
      setTimeout(() => {
        dispatch(resetAll());
        navigate("/admin/tournament");
      }, 1000);
    }
  }, [tournament.nextRoundSuccess]);
  const handleGotoNextRound = () => {
    dispatch(gotoNextRound({ id: tid }));
  };

  if (tournament.matchInTournamentLoading || !matches) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <h1 className="text-customBlack text-xl text-center">Loading....</h1>
      </div>
    );
  }

  const moveNextRound = () => {
    if (currentRound >= maxRound) {
      setCurrentRound(1);
      setNextRound(2);
      return;
    }
    setCurrentRound(currentRound + 1);
    setNextRound(nextRound + 1);
    if (nextRound >= maxRound + 1) {
      setNextRound(1);
    }
  };
  const movePrevRound = () => {
    if (currentRound === 1) {
      setCurrentRound(maxRound);
      setNextRound(1);
      return;
    }
    setCurrentRound(currentRound - 1);
    if (nextRound === 1) {
      setNextRound(maxRound);
      return;
    }
    setNextRound(nextRound - 1);
  };
  return (
    <div className="bg-white rounded-[20px] h-screen flex flex-col  mt-6 ">
      <div className="flex-grow ">
        <div className="mt-[22px]">
          <div className="pl-5 flex justify-between">
            <p className="font-medium text-sm sm:text-md">
              Round {currentRound}
            </p>
            <div className="flex justify-between items-center space-x-6 mr-10">
              <span
                className="text-neutral text-sm sm:text-lg cursor-grab"
                onClick={movePrevRound}
              >
                <IoIosArrowBack />
              </span>
              {listOfRounds.map((round) => {
                return (
                  <span
                    key={round}
                    className={
                      currentRound === round
                        ? "bg-lemonGreen w-[20px]  h-[20px]  flex items-center justify-center rounded-[3px] text-sm text-white cursor-grab"
                        : nextRound === round
                        ? "text-customBlack text-sm sm:text-lg font-medium cursor-grab"
                        : "text-deepAsh text-sm sm:text-lg font-normal cursor-grab"
                    }
                    onClick={() => {
                      setCurrentRound(round);
                      setNextRound(round + 1);
                    }}
                  >
                    {round}
                  </span>
                );
              })}
              <span
                className="text-neutral cursor-grab text-sm sm:text-lg  font-normal"
                onClick={moveNextRound}
              >
                <IoIosArrowForward />
              </span>
            </div>
          </div>
        </div>
        {/* breaker line */}
        <div className="w-full h-[1px] bg-[#cfcfcf] " />
        <div className="bg-white w-full flex flex-row justify-between flex-start">
          <div className="grid grid-cols-2 pl-5 w-4/6  gap-6 mt-5 overflow-y-auto">
            {currentMatch?.map((item: any, index: any) => {
              return <SingleForm index={index} item={item} />;
            })}
          </div>
          <div className="space-y-4 mt-5 mr-10">
            <div className="flex items-center">
              <span className="rounded-full bg-yellow w-2.5 h-2.5"></span>
              <p className="ml-2 font-medium text-sm text-darkblack">
                Match Ongoing
              </p>
            </div>
            <div className="flex items-center">
              <span className="rounded-full bg-neutral-400 w-2.5 h-2.5"></span>
              <p className="ml-2 font-medium text-sm text-darkblack">
                Loser of the Match
              </p>
            </div>
            <div className="flex items-center">
              <span className="rounded-full bg-secondary700 w-2.5 h-2.5"></span>
              <p className="ml-2 font-medium text-sm text-darkblack">
                Winner of the Match
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white pt-8 mb-3 flex items-center justify-center">
        <div className="flex items-center justify-center w-2/4">
          {tournamentStatus === "finished" ? (
            <p className="text-lg text-center text-neutral-700 font-bold">
              The tournament has ended
            </p>
          ) : (
            <Btn
              name="Go to Next Round"
              active={true}
              onClick={handleGotoNextRound}
              loading={tournament.loading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ScoreBoard;

const SingleForm = ({ item, index }: any) => {
  const tournament = useAppSelector((state) => state.tournament);
  const { challenged, challenger, winner, setA, setB, setC } = item;
  const [openView, setOpenView] = useState(false);

  useEffect(() => {
    if (tournament.updateMatchScoreSuccess) {
      // toast.success("updateSUCCES")
      // setOpenEdit(false);
    }
  }, [tournament.updateMatchScoreSuccess]);

  return (
    <>
      {/* */}
      <div className="flex space-x-5">
        <div key={index} className="flex flex-col rounded-[5px] ">
          <div className="flex flex-row rounded-t-[5px] border-t-2 border-r-2 border-l-2 border-[#CFCFCF]-500">
            <span
              className={
                winner?._id
                  ? challenger?._id === winner?._id
                    ? "rounded-tl-[5px] bg-secondary700  p-2 w-[30px] flex items-center justify-center text-lg text-white"
                    : "rounded-tl-[5px] bg-deepAsh p-2 w-[30px] flex items-center justify-center text-lg text-white"
                  : "rounded-tl-[5px] bg-warning  p-2 w-[30px] flex items-center justify-center text-lg text-white"
              }
            >
              <span className="ml-auto">1</span>
            </span>
            <span className=" bg-white w-[160px] p-2 text-sm text-darkblack font-medium flex items-center capitalize  border-r-gray-600">
              {challenger?.lastname
                ? challenger?.firstname + " " + challenger?.lastname
                : challenger?.firstname
                ? challenger?.firstname
                : "BYE"}
            </span>
            <div className="rounded-tr-[5px] w-[50px] bg-white flex items-center justify-center border-l-2  border-l-[#CFCFCF]-900">
              <span className="text-sm text-customBlack font-medium">
                {(parseInt(setC?.challenged) === 0 || !setC?.challenged) &&
                (parseInt(setC?.challenger) === 0 || !setC?.challenger) &&
                setA
                  ? `${setA?.challenger && setA?.challenger}, ${
                      setB?.challenger && setB?.challenger
                    }`
                  : setA || setB || setC
                  ? `${setA.challenger && setA.challenger}, ${
                      setB.challenger && setB.challenger
                    }, ${setC.challenger || 0}`
                  : "-, -"}
              </span>
            </div>
          </div>
          <div className="rounded-br-[5px]  flex flex-row border-2 border-[#CFCFCF]-500">
            <span
              className={
                winner?._id
                  ? challenged?._id === winner?._id
                    ? "rounded-bl-[5px] bg-secondary700  p-2 w-[30px] flex items-center justify-center text-lg text-white"
                    : "rounded-bl-[5px] bg-deepAsh p-2 w-[30px] flex items-center justify-center text-lg text-white"
                  : "rounded-bl-[5px] bg-warning  p-2 w-[30px] flex items-center justify-center text-lg text-white"
              }
            >
              <span className="ml-auto">2</span>
            </span>
            <span className="rounded-br-[5px] bg-white w-[160px] p-2 text-sm text-darkblack font-medium flex items-center capitalize">
              {" "}
              {challenged?.lastname
                ? challenged?.firstname + " " + challenged?.lastname
                : challenged?.firstname
                ? challenged?.firstname
                : "BYE"}
            </span>
            <div className="rounded-tr-[5px] w-[50px] bg-white flex items-center justify-center border-l-2  border-l-[#CFCFCF]-900">
              <span className="text-sm text-customBlack font-medium">
                {(parseInt(setC?.challenged) === 0 || !setC?.challenged) &&
                (parseInt(setC?.challenger) === 0 || !setC?.challenger) &&
                setA
                  ? `${setA.challenged && setA.challenged}, ${
                      setB.challenged && setB.challenged
                    }`
                  : setA || setB || setC
                  ? `${setA.challenged && setA.challenged}, ${
                      setB.challenged && setB.challenged
                    }, ${setC.challenged || 0}`
                  : "-, -"}
              </span>
            </div>
          </div>
        </div>
      </div>

      <ReUseModal open={openView} setOpen={setOpenView}>
        <div className="w-full flex flex-col">
          <h1>View Match Score</h1>
          {/* start of set */}
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="text-customBlack font-semibold text-lg mb-2 mt-6"
            >
              SET 1:
            </label>
            <div className="w-full flex flex-col">
              <div className="w-8/12 flex justify-between mb-2">
                <p className="text-base text-neutral-700 capitalize">
                  {challenger?.firstname}
                </p>
                <p className="text-base text-neutral-700 capitalize">
                  {challenged?.firstname}
                </p>
              </div>
              <div className="flex justify-between w-8/12">
                <p className="text-customBlack text-lg font-bold">
                  {setA?.challenger}
                </p>
                <p className="text-customBlack text-lg font-bold">
                  {setA?.challenged}
                </p>
              </div>
            </div>
          </div>
          {/* enf pf set */}
          {/* start of set */}
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="text-customBlack font-semibold text-lg mb-2 mt-6"
            >
              SET 2:
            </label>
            <div className="w-full flex flex-col">
              <div className="w-8/12 flex justify-between mb-2">
                <p className="text-base text-neutral-700 capitalize">
                  {challenger?.firstname}
                </p>
                <p className="text-base text-neutral-700 capitalize">
                  {challenged?.firstname}
                </p>
              </div>
              <div className="flex justify-between w-8/12">
                <p className="text-customBlack text-lg font-bold">
                  {setB?.challenger}
                </p>
                <p className="text-customBlack text-lg font-bold">
                  {setB?.challenged}
                </p>
              </div>
            </div>
          </div>
          {/* enf pf set */}
          {/* start of set */}
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="text-customBlack font-semibold text-lg mb-2 mt-6"
            >
              SET 3:
            </label>
            <div className="w-full flex flex-col">
              <div className="w-8/12 flex justify-between mb-2">
                <p className="text-base text-neutral-700 capitalize">
                  {challenger?.firstname}
                </p>
                <p className="text-base text-neutral-700 capitalize">
                  {challenged?.firstname}
                </p>
              </div>
              <div className="flex justify-between w-8/12">
                <p className="text-customBlack text-lg font-bold">
                  {setC?.challenger}
                </p>
                <p className="text-customBlack text-lg font-bold">
                  {setC?.challenged}
                </p>
              </div>
            </div>
          </div>
          {/* enf pf set */}

          <div className="w-10/12 mt-10 mx-auto">
            <Btn name="Back" active={true} onClick={() => setOpenView(false)} />
          </div>
        </div>
      </ReUseModal>
    </>
  );
};
