import GeneralLayout from "../../Layouts/GeneralLayout";
const Buddies = () => {
  return (
    <GeneralLayout active={4} name="Buddies">
      <div className="w-full flex flex-col px-4 pb-40  md:w-[450px]">
        {/* <div className="flex items-center h-[50px] justify-between space-x-4  overflow-x-hidden w-full">
          <div className="flex h-[50px] bg-[#f4f4f4] w-10/12 items-center px-2 rounded-[16px]  ">
            <div className="w-2/12">
              <MdSearch className="text-2xl" />
            </div>
            <input
              type="text"
              className=" bg-inherit outline-none border-none focus:border-none focus:outline-none w-10/12"
            />
          </div>
          <div className="w-2/12">
            <ChatSend />
          </div>
        </div> */}
        <div className="mt-12">
          <p className="text-md font-semibold text-center text-[#2c2c2c] mb-4">
            The buddy chat feature is Coming Soon!
          </p>
        </div>
        {/* <div className="mt-4 w-full grid grid-cols-4 gap-3">
          <div className="flex flex-col">
            <img
              src={picko}
              alt="oick"
              className="rounded-[24px] w-[78px] h-[84px]"
            />
            <p className="text-[10px] text-center mt-1">Tomiwa Philips</p>
          </div>
          <div className="flex flex-col">
            <img
              src="https://res.cloudinary.com/www-daniekeys-com/image/upload/v1658594783/samp_ogv94k.jpg"
              alt="oick"
              className="rounded-[24px] w-[78px] h-[84px]"
            />
            <p className="text-[10px] text-center mt-1">Tomiwa Philips</p>
          </div>
          <div className="flex flex-col">
            <img
              src={picko}
              alt="oick"
              className="rounded-[24px] w-[78px] h-[84px]"
            />
            <p className="text-[10px] text-center mt-1">Tomiwa Philips</p>
          </div>
          <div className="flex flex-col">
            <img
              src="https://res.cloudinary.com/www-daniekeys-com/image/upload/v1614139131/DANIKEYS_LOGO_bf662i.jpg"
              alt="oick"
              className="rounded-[24px] w-[78px] h-[84px]"
            />
            <p className="text-[10px] text-center mt-1">Tomiwa Philips</p>
          </div>
        </div>
        <div className="mt-12">
          <p className="text-md font-semibold text-[#2c2c2c] mb-4">
            Recent Messages
          </p>
                  <div className="flex flex-col space-y-2">

            <div className="w-full h-20 border-border border rounded-md flex items-center justify-between px-4 ">
              <div className="flex items-center space-x-3">
                <img
                  alt="avatar"
                  src="https://res.cloudinary.com/www-daniekeys-com/image/upload/v1614139131/DANIKEYS_LOGO_bf662i.jpg"
                  className="rounded-full w-[40px] h-auto"
                />
                <div className="flex flex-col">
                  <p className="text-[14px] text-black p-0 m-0 font-bold">
                    Tomiwa Philips
                  </p>
                  <p className="text-[12px] text-black">Where are you?</p>
                </div>
              </div>
              <div className="flex items-center">
                <p className="text-[12px] text-black">12:34am</p>
              </div>
                      </div>

            <div className="w-full h-20 border-border border rounded-md flex items-center justify-between px-4 ">
              <div className="flex items-center space-x-3">
                <img
                  alt="avatar"
                  src="https://res.cloudinary.com/www-daniekeys-com/image/upload/v1614139131/DANIKEYS_LOGO_bf662i.jpg"
                  className="rounded-full w-[40px] h-auto"
                />
                <div className="flex flex-col">
                  <p className="text-[14px] text-black p-0 m-0 font-bold">
                    Tomiwa Philips
                  </p>
                  <p className="text-[12px] text-black">Where are you?</p>
                </div>
              </div>
              <div className="flex items-center">
                <p className="text-[12px] text-black">12:34am</p>
              </div>
                      </div>

            <div className="w-full h-20 border-border border rounded-md flex items-center justify-between px-4 ">
              <div className="flex items-center space-x-3">
                <img
                  alt="avatar"
                  src="https://res.cloudinary.com/www-daniekeys-com/image/upload/v1614139131/DANIKEYS_LOGO_bf662i.jpg"
                  className="rounded-full w-[40px] h-auto"
                />
                <div className="flex flex-col">
                  <p className="text-[14px] text-black p-0 m-0 font-bold">
                    Tomiwa Philips
                  </p>
                  <p className="text-[12px] text-black">Where are you?</p>
                </div>
              </div>
              <div className="flex items-center">
                <p className="text-[12px] text-black">12:34am</p>
              </div>
                      </div>

            <div className="w-full h-20 border-border border rounded-md flex items-center justify-between px-4 ">
              <div className="flex items-center space-x-3">
                <img
                  alt="avatar"
                  src="https://res.cloudinary.com/www-daniekeys-com/image/upload/v1614139131/DANIKEYS_LOGO_bf662i.jpg"
                  className="rounded-full w-[40px] h-auto"
                />
                <div className="flex flex-col">
                  <p className="text-[14px] text-black p-0 m-0 font-bold">
                    Tomiwa Philips
                  </p>
                  <p className="text-[12px] text-black">Where are you?</p>
                </div>
              </div>
              <div className="flex items-center">
                <p className="text-[12px] text-black">12:34am</p>
              </div>
                      </div>

            <div className="w-full h-20 border-border border rounded-md flex items-center justify-between px-4 ">
              <div className="flex items-center space-x-3">
                <img
                  alt="avatar"
                  src="https://res.cloudinary.com/www-daniekeys-com/image/upload/v1614139131/DANIKEYS_LOGO_bf662i.jpg"
                  className="rounded-full w-[40px] h-auto"
                />
                <div className="flex flex-col">
                  <p className="text-[14px] text-black p-0 m-0 font-bold">
                    Tomiwa Philips
                  </p>
                  <p className="text-[12px] text-black">Where are you?</p>
                </div>
              </div>
              <div className="flex items-center">
                <p className="text-[12px] text-black">12:34am</p>
              </div>
                      </div>

            <div className="w-full h-20 border-border border rounded-md flex items-center justify-between px-4 ">
              <div className="flex items-center space-x-3">
                <img
                  alt="avatar"
                  src="https://res.cloudinary.com/www-daniekeys-com/image/upload/v1614139131/DANIKEYS_LOGO_bf662i.jpg"
                  className="rounded-full w-[40px] h-auto"
                />
                <div className="flex flex-col">
                  <p className="text-[14px] text-black p-0 m-0 font-bold">
                    Tomiwa Philips
                  </p>
                  <p className="text-[12px] text-black">Where are you?</p>
                </div>
              </div>
              <div className="flex items-center">
                <p className="text-[12px] text-black">12:34am</p>
              </div>
                      </div>

          </div>
        <div className="h-20 mt-6 w-full"></div>
        </div> */}
      </div>
    </GeneralLayout>
  );
};

export default Buddies;
