import { useEffect, useState } from "react";
import receipt from "../../../../assets/svgs/receipt.svg";
import {
  editUserPoint,
  getLadderMembers,
  getSingleLadderDetail,
  resetAllLadderState,
} from "../../../../features/auth/ladder-slice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import sentMark from "../../../../assets/svgs/checkmark.svg";
import { removePlayer } from "../../../../features/auth/ladder-slice";
import ReUseModal from "../../../Modal/ReUseModal";
import { DeleteIcon } from "../../../../assets/svgs/tournament";
import { Btn } from "../../../Button";
import { useParams } from "react-router-dom";

import { SingleUserIcon, TrashIcon } from "../../../../assets/svgs/Icons";
import Options from "../../../Selects/Options";
import LoadingComponent from "../../../Loading/Loader";
const AllPlayersTablePointLadder = ({ className }: any) => {
  const dispatch = useAppDispatch();
  const ladder = useAppSelector((state) => state?.ladder);
  const [details, setDetails] = useState<any>([]);
  const [ladderName, setLadderName] = useState("");

  const id = useParams();
  useEffect(() => {
    dispatch(getSingleLadderDetail({ id: id.id }));
  }, []);
  useEffect(() => {
    if (ladder?.singleLadder?.leaderboard?.length > 0) {
      setDetails(ladder.singleLadder?.leaderboard);
      setLadderName(ladder?.singleLadder?.name);
    }
  }, [ladder?.singleLadder]);
  useEffect(() => {
    if (ladder?.playerRemoveSuccess) {
      dispatch(getSingleLadderDetail({ id: id.id }));
    }
  }, [ladder?.playerRemoveSuccess]);
  useEffect(() => {
    if (ladder?.editPointSuccess) {
      dispatch(getSingleLadderDetail({ id: id.id }));
    }
  }, [ladder?.editPointSuccess]);

  if (ladder?.loading) {
    return (
      <div className="mt-8 px-8">
        <LoadingComponent />
      </div>
    );
  }

  return (
    <div
      className={`w-full  h-full min-h-[40vh] flex flex-col bg-white  rounded-[20px] ${className} `}
    >
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className="text-sm font-semibold text-customBlack capitalize">
          Players - {ladderName}
        </h1>
      </div>
      <table className="min-w-full w-full h-full bg-[#fff] rounded-b-[20px]   ">
        <thead className=" bg-[#f4f4f4] bg-opacity-40 mb-2">
          <tr className="flex items-center h-[35px] pl-5">
            <th className="w-3/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-start ">
              {" "}
              Name
            </th>

            <th className="w-1/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-start ">
              {" "}
              Points
            </th>
            <th className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-start ">
              {" "}
              Email Address
            </th>
            <th className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-start ">
              {" "}
              Date of Birth
            </th>
            <th className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-center ">
              {" "}
              Date Joined
            </th>
            <th className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-center ">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="w-full flex flex-col space-y-6 mb-4 mt-2">
          {details?.map((item: any, index: number) => {
            return (
              <SingleRow
                item={item}
                index={index}
                key={index}
                total={details?.length}
              />
            );
          })}
          {details?.length === 0 && (
            <div className="mt-10 border-2.38 border-gray-600 w-38 h-38 flex flex-col items-center">
              <img src={receipt} className=" w-38 h-38" alt="" />
              <h1 className="font-inter text-xs font-medium  tracking-[0.12px]  text-center mt-2 text-neutral-600">
                No player has joined the tournament
              </h1>
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AllPlayersTablePointLadder;

const SingleRow = ({ item, index, total }: any) => {
  const { lastname, firstname, image, rank, _id } = item?._id;
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteSuccess, setOpenDeleteSuccess] = useState(false);
  const [openPoint, setOpenPoint] = useState(false);
  const [openPointSuccess, setOpenPointSuccess] = useState(false);
  const currentPoint = item?.points;
  const [point, setPoint] = useState<any>(currentPoint || 0);

  const dispatch = useAppDispatch();
  const ladder = useAppSelector((state) => state?.ladder);
  const id = useParams();
  useEffect(() => {
    if (ladder?.playerRemoveSuccess) {
      setOpenDelete(false);
      setOpenDeleteSuccess(true);
      dispatch(resetAllLadderState());
      setTimeout(() => {
        setOpenDeleteSuccess(false);
      }, 2000);
    }
  }, [ladder?.playerRemoveSuccess]);
  const remove = () => {
    const datasent = {
      id: id.id,
      data: {
        id: _id,
        alert: true,
      },
    };
    dispatch(removePlayer(datasent));
  };
  const isLastfour = total > 4 ? index >= total - 4 : false;

  const handleEditPoint = () => {
    const sentData = {
      id: id.id,
      data: {
        id: _id,
        points: Number(point),
      },
    };
    dispatch(editUserPoint(sentData));
  };
  useEffect(() => {
    if (ladder?.editPointSuccess) {
      setOpenPoint(false);
      setOpenPointSuccess(true);
      dispatch(resetAllLadderState());
      setTimeout(() => {
        setOpenPointSuccess(false);
      }, 2000);
    }
  }, [ladder?.editPointSuccess]);
  return (
    <>
      <tr className="w-full flex items-center pl-5 " key={index}>
        <td className="text-start text-sm text-customBlack capitalize w-3/12 flex space-x-2 items-center">
          <img src={image} alt="samp" className="w-6 h-auto rounded-full" />
          <span className="capitalize">{`${firstname} ${
            lastname ? lastname : ""
          }`}</span>
        </td>

        <td className="text-start text-sm text-customBlack capitalize w-1/12 ">
          {item?.points} pts
        </td>
        <td className="text-start text-sm text-customBlack capitalize w-2/12">
          {`lion${index}@email.com`}
        </td>
        <td className="text-start text-sm text-customBlack capitalize w-2/12">
          01-May-1989
        </td>
        <td className=" text-sm text-customBlack capitalize w-2/12 text-center">
          26-Jan-2024
        </td>
        <td className="text-center text-sm text-customBlack capitalize w-2/12 cursor-pointer flex justify-center">
          <span>
            <Options isLast={isLastfour}>
              <div className="w-full flex flex-col ">
                <div
                  className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                  onClick={() => setOpenPoint(true)}
                >
                  <span>
                    <SingleUserIcon />
                  </span>
                  <p className="text-customBlack text-sm">Assign Points</p>
                </div>
                <div
                  className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                  onClick={() => setOpenDelete(true)}
                >
                  <span>
                    <TrashIcon />
                  </span>
                  <p className="text-[#B00020] text-sm">Remove Player</p>
                </div>
              </div>
            </Options>
          </span>
        </td>
      </tr>
      <ReUseModal open={openDelete} setOpen={setOpenDelete}>
        <div className="w-full flex flex-col">
          <div className="flex items-center justify-center mt-10">
            <DeleteIcon />
          </div>
          <h1 className="text-center mt-2 font-bold text-[22px] leading-[24px] text-customBlack ">
            Remove Player
          </h1>
          <p className="text-[#868686] mt-1 text-sm text-center max-w-[340px] mx-auto">
            Are you sure you want to remove this player from the ladder?
          </p>
          <div className="w-full flex items-center justify-center  gap-6 mt-16">
            <button
              className="w-full max-w-[174px] rounded-[16px] border border-[#9cd326] h-[52px] flex items-center cursor-pointer justify-center text-sm font-semibold text-[#9CD326] "
              onClick={() => setOpenDelete(false)}
              disabled={ladder?.loading}
            >
              No Cancel
            </button>
            <button
              className="w-full max-w-[174px] rounded-[16px] bg-[#B00020] h-[52px] flex items-center cursor-pointer justify-center text-sm font-semibold text-white border-none outline-none"
              onClick={remove}
              disabled={ladder?.loading}
            >
              {ladder?.loading ? "Loading..." : "Yes Remove"}
            </button>
          </div>
        </div>
      </ReUseModal>
      <ReUseModal open={openDeleteSuccess} setOpen={setOpenDeleteSuccess}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-customBlack space-y-1 text-[24px] font-bold text-center">
          Player Removed
        </h1>
        <p className="text-center w-10/12 max-w-[331px] mx-auto text-neutral-500 text-sm">
          You’ve successfully removed the player from this tournament
        </p>
        <Btn
          active={true}
          name="Close"
          onClick={() => setOpenDeleteSuccess(false)}
          className="mt-[49px] mb-3"
        />
      </ReUseModal>
      <ReUseModal open={openPointSuccess} setOpen={setOpenPointSuccess}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-customBlack space-y-1 text-[24px] font-bold text-center">
          Points Assigned
        </h1>
        <p className="text-center w-10/12 max-w-[331px] mx-auto text-neutral-500 text-sm">
          You’ve successfully assigned points to the player
        </p>
        <Btn
          active={true}
          name="Close"
          onClick={() => setOpenPointSuccess(false)}
          className="mt-[49px] mb-3"
        />
      </ReUseModal>
      <ReUseModal open={openPoint} setOpen={setOpenPoint}>
        <div className="w-full flex flex-col">
          <h1 className="text-customBlack space-y-1 text-base font-bold ">
            Assign Points
          </h1>
          <hr className="bg-[#cfcfcf] w-full mt-3" />
          <div className="w-full flex mt-5 items-center justify-between mb-12">
            <p className="text-sm font-medium text-customBlack">Points</p>

            <span>
              <input
                type="text"
                placeholder="-"
                value={point}
                onChange={(e: any) => setPoint(e.target.value)}
                className="w-[38px] rounded-[10px] h-[38px] border-[0.5px] border-[#e5e5e5] outline-none focus:outline-none  placeholder:text-[#e5e5e5] flex items-center justify-center placeholder:text-[30px] placeholder:leading-[0] placeholder:text-center placeholder:font-bold text-customBlack text-sm text-center "
              />
            </span>
          </div>
          <div className="w-10/12 mx-auto">
            <Btn
              active={true}
              name="Assign"
              onClick={handleEditPoint}
              loading={ladder?.loading}
              className="mt-[49px] mb-3 "
            />
          </div>
        </div>
      </ReUseModal>
    </>
  );
};
