import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import receipt from "../../../../assets/svgs/receipt.svg";
import {
  deleteCompetition,
  getAllAcceptedPlayer,
  getAllCompetitionsAdmin,
  getAllTournaments,
  getMatchInTournament,
  resetAll,
} from "../../../../features/auth/TournamentSlice";
import { formatDate } from "../../../../utils";
import sentMark from "../../../../assets/svgs/sent-mark.svg";
import {
  ShareFacebook,
  ShareInstagram,
  ShareLinkedIn,
  ShareTwitter,
  SingleUserIcon,
  TrashIcon,
} from "../../../../assets/svgs/Icons";
import Options from "../../../Selects/Options";
import {
  CompetitionScheduleIcon,
  MultiUserIcon,
  SendIcon,
  TennisBallIcon,
} from "../../../../assets/svgs/club";
import { Btn } from "../../../Button";
import ReUseModal from "../../../Modal/ReUseModal";
import { toast } from "react-toastify";
import { DeleteIcon } from "../../../../assets/svgs/tournament";
import LoadingComponent from "../../../Loading/Loader";

const AllCompetitionTables = ({ className }: any) => {
  const dispatch = useAppDispatch();
  const tournament = useAppSelector((state) => state.tournament);
  const [openDeleteSuccess, setOpenDeletedSuccess] = useState<boolean>(false);
  useEffect(() => {
    // dispatch(getAllTournaments());
    dispatch(getAllCompetitionsAdmin());
    dispatch(resetAll());
  }, []);

  useEffect(() => {
    if (tournament?.deleteCompetitionSuccess) {
      setOpenDeletedSuccess(true);
      dispatch(resetAll());
      dispatch(getAllCompetitionsAdmin());
    }
  }, [tournament?.deleteCompetitionSuccess]);

  return (
    <div
      className={`w-full  h-full min-h-[40vh] flex flex-col bg-white  rounded-[20px] ${className} `}
    >
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className="text-sm font-semibold text-customBlack">
          Competition Tournament
        </h1>
      </div>
      {tournament?.fetchLoading ? (
        <div className="w-full p-5">
          <LoadingComponent />
        </div>
      ) : (
        <table className="min-w-full w-full h-full bg-[#fff] table-auto rounded-b-[20px]   ">
          <thead className=" bg-[#f4f4f4] bg-opacity-40 mb-2  whitespace-no-wrap ">
            <tr className="whitespace-no-wrap flex items-center h-[35px] pl-5">
              <th className="w-2/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-start ">
                {" "}
                Name of Tournament
              </th>
              <th className="w-[100px]  theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-start ">
                {" "}
                Type
              </th>
              <th className="w-2/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-center ">
                {" "}
                Competition Date
              </th>
              <th className="w-[100px] theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-center ">
                {" "}
                No of Players
              </th>
              <th className="w-2/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-center whitespace-no-wrap ">
                {" "}
                No of Teams
              </th>
              <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-start whitespace-no-wrap">
                {" "}
                Status
              </th>
              <th className="pl-5 w-2/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-start flex whitespace-no-wrap 2xl:ml-10  ">
                {" "}
                Actions
              </th>
              <th className="flex-grow"></th>
            </tr>
          </thead>
          <tbody className="w-full flex flex-col space-y-6 mb-4 mt-2 min-h-[40vh]">
            {tournament?.allCompetitionsAdmin?.length ? (
              tournament?.allCompetitionsAdmin?.map(
                (item: any, index: number) => {
                  return <SingleRow item={item} key={index} />;
                }
              )
            ) : (
              <div className="mt-10 border-2.38 border-gray-600 w-38 h-38 flex flex-col items-center">
                <img
                  src={receipt}
                  className="border-2.5 border-gray-600 w-38 h-38"
                  alt=""
                />
                <h1 className="font-inter text-sm font-medium leading-15 tracking-wider w-106 h-15 text-center mt-5">
                  No Ongoing Competitions
                </h1>
              </div>
            )}
          </tbody>
        </table>
      )}

      <ReUseModal open={openDeleteSuccess} setOpen={setOpenDeletedSuccess}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-cutomBlack text-[24px] font-bold text-center">
          Competition Deleted
        </h1>
        <p className="text-center w-10/12 mx-auto text-neutral-500 ">
          Competition has been deleted successfully
        </p>
        <Btn
          active={true}
          name="Close"
          onClick={() => {
            setOpenDeletedSuccess(false);
            dispatch(getAllCompetitionsAdmin());
          }}
          className="mt-[49px] mb-3"
        />
      </ReUseModal>
    </div>
  );
};

export default AllCompetitionTables;

const SingleRow = ({ item, index }: any) => {
  const {
    limit,
    name,
    mode,
    start,
    status,
    createdAt,
    _id,
    round,
    players,

    team,
    teams,
  } = item;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tournament = useAppSelector((state) => state.tournament);
  const [openShare, setOpenShare] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const copyClipboard = () => {
    const word = `${process.env.REACT_APP_LIVE_URL}/join?id=${_id}`;
    navigator.clipboard.writeText(word);
    toast.success("Copied to Clipboard");
  };
  const handleDelete = () => {
    dispatch(deleteCompetition({ id: _id }));
  };
  useEffect(() => {
    if (tournament?.deleteCompetitionSuccess) {
      setOpenDelete(false);
    }
  }, [tournament?.deleteCompetitionSuccess]);
  return (
    <tr className="w-full flex items-center pl-5 " key={index}>
      <td className="text-left text-sm text-neutral-800 w-2/12 capitalize">
        {name ? name : "-"}
      </td>
      <td className="text-start text-sm text-neutral-800 w-[100px] capitalize">
        {item?.team === true ? "Team " : "Single "}
      </td>
      <td className="text-center text-sm text-neutral-800 w-2/12">
        {formatDate(start || createdAt)}
      </td>
      <td className=" text-center text-sm  text-neutral-800 w-[100px]">
        {players?.length ? players.length : "-"}
      </td>
      <td className="text-center text-sm text-neutral-800 w-2/12">
        {teams?.length ? teams.length : "-"}
      </td>
      <td className="text-start text-sm text-neutral-800 w-[100px]">
        {status === "pending" ? (
          <p className="bg-[#F7F7F7] text-[#E48900] text-sm p-[6px] rounded-[8px] flex items-center justify-center ">
            {status === "ongoing" || status === "schedule"
              ? "In Progress"
              : status === "pending"
              ? "Not Started"
              : status === "finished"
              ? "Ended"
              : status}
          </p>
        ) : (
          <p className="bg-[#D1E5D6] text-[#197C34] text-sm px-[4px] py-[6px] rounded-[8px] flex items-center justify-center  ">
            {status === "ongoing" || status === "schedule"
              ? "In Progress"
              : status === "pending"
              ? "Not Started"
              : status === "finished"
              ? "Ended"
              : status}
          </p>
        )}
      </td>
      <td className="  text-sm text-neutral-800 w-2/12 flex justify-center   ">
        <div>
          {status === "pending" && team && players?.length > 1 && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-start"
              onClick={() => {
                navigate(`/admin/competition/assign-player/${_id}`);
              }}
            >
              Start Assigning
            </p>
          )}
          {status === "ongoing" && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-start"
              onClick={() => {
                if (team) {
                  navigate(`/admin/scoring/team-competition/${_id}`);
                } else {
                  dispatch(getMatchInTournament({ id: _id }));
                  navigate(`/admin/scoring/competition/${_id}`);
                }
              }}
            >
              View Scores
            </p>
          )}
          {status === "pending" && team === false && players?.length > 1 && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-start"
              onClick={() => {
                dispatch(getMatchInTournament({ id: _id }));
                navigate(`/admin/competition/${_id}`);
              }}
            >
              Start Scheduling
            </p>
          )}
          {status === "finished" && team === false && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-start"
              onClick={() => {
                dispatch(getMatchInTournament({ id: _id }));
                navigate(`/admin/score-tournament/knockout/${_id}`);
              }}
            >
              View Scheduling
            </p>
          )}
          {status === "pending" && team === false && players?.length === 0 && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-right"
              onClick={() => {
                navigate(`/admin/select-players/competition/${_id}`);
              }}
            >
              Select Players
            </p>
          )}
          {status === "pending" && players?.length !== 0 && team === false && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer "
              onClick={() => {
                dispatch(getAllAcceptedPlayer({ id: _id }));
                navigate(`/admin/competition/${_id}`);
              }}
            >
              Start Scheduling
            </p>
          )}
        </div>
        <div>
          {status === "pending" && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-start"
              onClick={() => {
                if (team) {
                  navigate(`/admin/scoring/team-competition/${_id}`);
                } else {
                  dispatch(getMatchInTournament({ id: _id }));
                  navigate(`/admin/scoring/competition/${_id}`);
                }
              }}
            >
              {round > 1 ? "View Scores" : ""}
            </p>
          )}
          {status === "ongoing" && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-start"
              onClick={() => {
                if (team) {
                  navigate(`/admin/scoring/team-competition/${_id}`);
                } else {
                  dispatch(getMatchInTournament({ id: _id }));
                  navigate(`/admin/scoring/competition/${_id}`);
                }
              }}
            >
              {round > 1 ? "View Scores" : ""}
            </p>
          )}

          {status === "finished" && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-start"
              onClick={() => {
                if (team) {
                  navigate(`/admin/scoring/team-competition/${_id}`);
                } else {
                  dispatch(getMatchInTournament({ id: _id }));
                  navigate(`/admin/scoring/competition/${_id}`);
                }
              }}
            >
              View Scores
            </p>
          )}

          {/* {status === "pending" && players?.length !== 0 && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer "
              onClick={() => {
                dispatch(getAllAcceptedPlayer({ id: _id }));
                navigate(`/admin/scoring/knockout/${_id}`);
              }}
            >
              {round > 1 ? "View Scores" : ""}
            </p>
          )} */}
        </div>

        {/* <div>
          {status === "ongoing" && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-start"
              onClick={() => {
                navigate(`/admin/competition/add/${_id}`);
              }}
            >
              Add Match
            </p>
          )}
        </div> */}
      </td>
      <td className="flex grow justify-end pr-10">
        <span>
          <Options isLast={false}>
            <div className="w-full flex flex-col ">
              {/* {team && (
                <div
                  className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                  onClick={() => {
                    navigate(
                      `/admin/competition/assign-player-category/${_id}`
                    );
                  }}
                >
                  <span>
                    <SingleUserIcon />
                  </span>
                  <p className="text-customBlack text-sm">
                    Add Players to Category
                  </p>
                </div>
              )} */}
              {team && (
                <div
                  className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                  onClick={() => {
                    navigate(`/admin/competition/assign-player/${_id}`);
                  }}
                >
                  <span>
                    <SingleUserIcon />
                  </span>
                  <p className="text-customBlack text-sm">Assign Players</p>
                </div>
              )}

              <div
                className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                onClick={() => {
                  if (team) {
                    navigate(`/admin/competition/schedule-match/${_id}`);
                  } else {
                    navigate(`/admin/competition/${_id}`);
                  }
                }}
              >
                <span>
                  <CompetitionScheduleIcon />
                </span>
                <p className="text-customBlack text-sm">Schedule Matches</p>
              </div>
              {team && (
                <div
                  className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                  onClick={() => {
                    navigate(`/admin/team-members/${_id}`);
                  }}
                >
                  <span>
                    <MultiUserIcon />
                  </span>
                  <p className="text-customBlack text-sm">View Team</p>
                </div>
              )}
              {team && (
                <div
                  className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                  onClick={() => {
                    dispatch(getMatchInTournament({ id: _id }));
                    navigate(`/admin/team-competition/overview/${_id}`);
                  }}
                >
                  <span>
                    <TennisBallIcon />
                  </span>
                  <p className="text-customBlack text-sm">
                    View Competition Board
                  </p>
                </div>
              )}

              <div
                className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                onClick={() => setOpenShare(true)}
              >
                <span>
                  <SendIcon />
                </span>
                <p className="text-customBlack text-sm">Share Link</p>
              </div>

              <div
                className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                // onClick={() => setOpenDelete(true)}
                onClick={() => {
                  setOpenDelete(true);
                }}
              >
                <span>
                  <TrashIcon />
                </span>
                <p className="text-[#B00020] text-sm">Delete Competition</p>
              </div>
            </div>
          </Options>
        </span>
      </td>
      <ReUseModal open={openShare} setOpen={setOpenShare}>
        <div className="flex flex-col">
          <h1 className="text-customBlack text-[22px] font-bold">
            Copy and Share Competition Link
          </h1>
          <div className="flex mt-8 mb-6 border-border border h-[52px] rounded-[16px] items-center justify-between pl-4 pr-2 space-x-2">
            <p className="text-customBlack text-sm font-medium  truncate">
              {`${process.env.REACT_APP_LIVE_URL}/join?id=${_id}`}
            </p>
            <button
              className="h-[48px] rounded-[12px] bg-[#9CD326] justify-center text-white flex text-[12px] items-center min-w-[60px] border-none outline-none"
              onClick={copyClipboard}
            >
              copy
            </button>
          </div>
          <div className="w-full space-x-6 flex items-center ">
            <span>
              <ShareFacebook />
            </span>
            <span>
              <ShareTwitter />
            </span>
            <span>
              <ShareLinkedIn />
            </span>
            <span>
              <ShareInstagram />
            </span>
          </div>
        </div>
        <div className="flex justify-center mt-[50px] mb-3 space-x-8">
          <span className="w-6/12">
            <Btn
              active={true}
              name="Close"
              onClick={() => setOpenShare(false)}
              className=""
            />
          </span>
        </div>
      </ReUseModal>
      <ReUseModal open={openDelete} setOpen={setOpenDelete}>
        <div className="w-full flex flex-col">
          <div className="flex items-center justify-center mt-10">
            <DeleteIcon />
          </div>
          <h1 className="text-center mt-2 font-bold text-[22px] leading-[24px] text-customBlack ">
            Delete Competition
          </h1>
          <p className="text-[#868686] mt-1 text-sm leading-[24px] tracking-[0.14px] max-w-[340px] mx-auto">
            Are you sure you want to delete this competition?
          </p>
          <div className="w-full flex items-center justify-center  gap-6 mt-16">
            <button
              className="w-full max-w-[174px] rounded-[16px] border border-[#9cd326] h-[52px] flex items-center cursor-pointer justify-center text-sm leading-[24px] tracking-[0.14px] font-semibold text-[#9CD326] "
              onClick={() => setOpenDelete(false)}
              disabled={tournament?.loading}
            >
              No Cancel
            </button>
            <button
              className="w-full max-w-[174px] rounded-[16px] bg-[#B00020] h-[52px] flex items-center cursor-pointer justify-center text-sm leading-[24px] tracking-[0.14px] font-semibold text-white border-none outline-none"
              onClick={handleDelete}
              disabled={tournament?.loading}
            >
              {tournament?.loading ? "loading..." : "Yes Delete"}
            </button>
          </div>
        </div>
      </ReUseModal>
    </tr>
  );
};
