import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate, useParams } from "react-router-dom";

import { LocationIcon, PendingIcon } from "../../assets/svgs/court";

import { BackArrow } from "../../assets/svgs/court";
import { UserTimeInput, UserDateInput } from "../../components/Input/DateInput";
import { Btn } from "../../components/Button";
import ReUseModal from "../../components/Modal/ReUseModal";
import LoadingComponent from "../../components/Loading/Loader";
import {
  bookCourtUser,
  getSingleCourtDetailUser,
  resetAll,
} from "../../features/auth/TournamentSlice";
import { convertDateToNumeric } from "../../utils";

const BookSingleCourt = () => {
  const dispatch = useAppDispatch();
  const tournament = useAppSelector((state) => state.tournament);
  const navigate = useNavigate();
  const [date, setDate] = useState<string>("");
  const [time, setTime] = useState<string>("");
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (date && time) {
      setActive(true);
    } else setActive(false);
  }, [date, time]);
  const id = useParams();
  useEffect(() => {
    dispatch(getSingleCourtDetailUser({ id: id.id }));
  }, []);

  const handleBookCourt = () => {
    const data = {
      type: "individual",
      date: convertDateToNumeric(date),
      time: time,
      court: id.id,
      match: null,
    };
    dispatch(bookCourtUser(data));
  };
  useEffect(() => {
    if (tournament?.bookCourtSuccessUser) {
      setOpen(true);
      dispatch(resetAll());
      setTimeout(() => {
        navigate("/find-court");
      }, 3000);
    }
  }, [tournament?.bookCourtSuccessUser]);
  if (tournament?.fetchLoading) {
    return (
      <div className="w-full md:max-w-[428px] mt-8 px-4 md:mx-auto ">
        <LoadingComponent />
      </div>
    );
  }

  return (
    <>
      <div className="w-full flex flex-col   md:max-w-[428px] md:mx-auto md:h-screen h-screen relative">
        <div
          className="imag-shad-big relative rounded-[30px] flex flex-col "
          style={{
            width: "100%",
            height: "300px",
            minWidth: "100%",
            minHeight: "300px",

            marginBottom: "16px",
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${tournament?.singleCourtDetailUser?.image})`,
            backgroundSize: "cover",
          }}
        >
          <span
            className="ml-8 mt-16 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <BackArrow />
          </span>
        </div>
        <div className="px-6 pb-6 mt-5 flex flex-col w-full">
          <h1 className="text-2xl font-bold text-[#333333] capitalize">
            {tournament?.singleCourtDetailUser?.name}
          </h1>
          <div className="flex gap-1 items-center mt-2">
            <span>
              <LocationIcon />
            </span>
            <p className="text-neutral-600 text-[12px] ">
              {tournament?.singleCourtDetailUser?.address}
            </p>
          </div>
          <p className="text-[#303030] text-[12px] mt-5 leading-[24px] ">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem eos
            architecto quod necessitatibus qui quo. Cupiditate ullam placeat
            esse quod.
          </p>
          <h2 className="text-sm font-bold text-customBlack mt-6">
            Booking Details
          </h2>
          <div className="flex flex-col gap-1 mt-4">
            <p className="text-xs font-semibold text-customBlack">Match Date</p>
            <UserDateInput date={date} setDate={setDate} />
          </div>
          <div className="flex flex-col gap-1 mt-4">
            <p className="text-xs font-semibold text-customBlack">Match Time</p>
            <UserTimeInput time={time} setTime={setTime} />
          </div>
        </div>
        <div className="px-6  absolute bottom-6 left-0 right-0 ">
          <Btn
            name="Book Court"
            onClick={handleBookCourt}
            active={active}
            loading={tournament?.loading}
          />
        </div>
        {/* end of the Destinations */}
      </div>
      <ReUseModal open={open} setOpen={setOpen}>
        <span className="w-full flex justify-center mb-4">
          <PendingIcon />
        </span>
        <h1 className="text-cutomBlack text-lg font-bold text-center ">
          Court Booking Pending
        </h1>
        <p className="text-center w-10/12 mx-auto text-neutral-500  max-w-[331px] text-sm ">
          You’ll be notified once your court booking is approved
        </p>
        <Btn
          active={true}
          name="Close"
          onClick={() => {
            setOpen(false);
            navigate(`/find-court`);
          }}
          className="mt-[25px] mb-3 max-w-[170px] mx-auto"
        />
      </ReUseModal>
    </>
  );
};

export default BookSingleCourt;
