export const playersDummy = [
  {
    firstname: "Daniel",
    lastname: "Johnson",
    rank: "21,099,201",
    level: "5",
  },
  {
    firstname: "Daniel",
    lastname: "Johnson",
    rank: "21,099,201",
    level: "5",
  },
  {
    firstname: "Daniel",
    lastname: "Johnson",
    rank: "21,099,201",
    level: "5",
  },
  {
    firstname: "Daniel",
    lastname: "Johnson",
    rank: "21,099,201",
    level: "5",
  },
  {
    firstname: "Daniel",
    lastname: "Johnson",
    rank: "21,099,201",
    level: "5",
  },
  {
    firstname: "Daniel",
    lastname: "Johnson",
    rank: "21,099,201",
    level: "5",
  },
  {
    firstname: "Daniel",
    lastname: "Johnson",
    rank: "21,099,201",
    level: "5",
  },
  {
    firstname: "Daniel",
    lastname: "Johnson",
    rank: "21,099,201",
    level: "5",
  },
  {
    firstname: "Daniel",
    lastname: "Johnson",
    rank: "21,099,201",
    level: "5",
  },
  {
    firstname: "Daniel",
    lastname: "Johnson",
    rank: "21,099,201",
    level: "5",
  },
  {
    firstname: "Daniel",
    lastname: "Johnson",
    rank: "21,099,201",
    level: "5",
  },
  {
    firstname: "Daniel",
    lastname: "Johnson",
    rank: "21,099,201",
    level: "5",
  },
  {
    firstname: "Daniel",
    lastname: "Johnson",
    rank: "21,099,201",
    level: "5",
  },
  {
    firstname: "Daniel",
    lastname: "Johnson",
    rank: "21,099,201",
    level: "5",
  },
  {
    firstname: "Daniel",
    lastname: "Johnson",
    rank: "21,099,201",
    level: "5",
  },
  {
    firstname: "Daniel",
    lastname: "Johnson",
    rank: "21,099,201",
    level: "5",
  },
];
export const dummyCourts = [
  {
    _id: "656f0dde69b3a26b0eebba82",
    _club: "656f0b6869b3a26b0eebb8d1",
    _type: "public",
    name: "mr val",
    address: "loader street",
    country: "Nigeria",
    state: "Abia",
    price: 0,
    image:
      "https://res.cloudinary.com/www-daniekeys-com/image/upload/v1686232363/Group_34506_y1mmxg.png",
    thumbnail: null,
    privateMode: false,
    createdAt: "2023-12-05T11:47:42.249Z",
    updatedAt: "2023-12-05T11:47:42.249Z",
    __v: 0,
  },
  {
    _id: "656f0df369b3a26b0eebba8f",
    _club: "656f0b6869b3a26b0eebb8d1",
    _type: "public",
    name: "oyinda",
    address: "cyril Second house",
    country: "Nigeria",
    state: "Abia",
    price: 0,
    image:
      "https://res.cloudinary.com/www-daniekeys-com/image/upload/v1686232363/Group_34506_y1mmxg.png",
    thumbnail: null,
    privateMode: false,
    createdAt: "2023-12-05T11:48:03.896Z",
    updatedAt: "2023-12-05T11:48:03.896Z",
    __v: 0,
  },
];

export const invitationsMockData = [
  {
    challenger: {
      firstname: "Dan",
      lastname: "Korre",
      rank: 0,
      _id: "26126616212816",
    },
    challenged: {
      firstname: "James",
      lastname: "Caleb",
      rank: 0,
      _id: "26126616212816",
    },

    time: "19:00",
    date: "12 jun",
    status: "pending",
  },
  {
    challenger: {
      firstname: "jacob",
      lastname: "Kima",
      rank: 0,
      _id: "26126616212816",
    },
    challenged: {
      firstname: "esther",
      lastname: "Caleb",
      rank: 0,
      _id: "26126616212816",
    },

    time: "19:00",
    date: "12 jun",
    status: "pending",
  },
  {
    challenger: {
      firstname: "Tomer",
      lastname: "Keesse",
      rank: 0,
      _id: "26126616212816",
    },
    challenged: {
      firstname: "Valo",
      lastname: "Pocsss",
      rank: 0,
      _id: "26126616212816",
    },

    time: "19:00",
    date: "12 jun",
    status: "sent",
  },
  {
    challenger: {
      firstname: "Tom",
      lastname: "Keen",
      rank: 0,
      _id: "26126616212816",
    },
    challenged: {
      firstname: "Val",
      lastname: "Pocker",
      rank: 0,
      _id: "26126616212816",
    },

    time: "19:00",
    date: "12 jun",
    status: "sent",
  },
  {
    challenger: {
      firstname: "Tom",
      lastname: "One",
      rank: 0,
      _id: "26126616212816",
    },
    challenged: {
      firstname: "Zam",
      lastname: "One",
      rank: 0,
      _id: "26126616212816",
    },

    time: "19:00",
    date: "12 jun",
    status: "accepted",
  },
  {
    challenger: {
      firstname: "James",
      lastname: "Bush",
      rank: 0,
      _id: "26126616212816",
    },
    challenged: {
      firstname: "Lee",
      lastname: "whne",
      rank: 0,
      _id: "26126616212816",
    },

    time: "19:00",
    date: "12 jun",
    status: "accepted",
  },
];
export const playersNumber = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
];
export const categoriesNumber = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
  { value: 11 },
  { value: 12 },
];

export const expiryTimes = [
  {
    value: "1 day",
    time: 24,
  },
  { value: "3 hours", time: 3 },
  { value: "6 hours", time: 6 },
  { value: "9 hours", time: 9 },
  { value: "12 hours", time: 12 },
  { value: "15 hours", time: 15 },
  { value: "18 hours", time: 18 },
  { value: "2 days", time: 48 },
  { value: "3 days", time: 72 },
  { value: "4 days", time: 96 },
  { value: "5 days", time: 120 },
  { value: "6 days", time: 144 },
  { value: "7 days", time: 168 },
  { value: "8 days", time: 192 },
  { value: "9 days", time: 216 },
  { value: "10 days", time: 240 },
  { value: "11 days", time: 264 },
  { value: "12 days", time: 288 },
];
export const ladderSamp = [
  {
    name: "Lagos ladder tournament",
    type: "rank",
    start: "01-Jan-2024",
    end: "31-Dec-2024",
    players: "0",
    matches: "0",
    status: "pending",
    action: false,
    id: 0,
  },
  {
    name: "Lagos ladder tournament",
    type: "rank",
    start: "01-Jan-2024",
    end: "31-Dec-2024",
    players: "0",
    matches: "0",
    status: "pending",
    action: true,
    id: 1,
  },
  {
    name: "Lagos ladder tournament",
    type: "rank",
    start: "01-Jan-2024",
    end: "31-Dec-2024",
    players: "0",
    matches: "0",
    status: "pending",
    action: false,
    id: 2,
  },
];
export const dummyPlayerList = [
  {
    name: "David Alaba",
    rank: "5.0",
    email: "dummy@email.com",
    dob: "01-May-1989",
    doj: "01-May-2011",
  },
  {
    name: "David Alaba",
    rank: "5.0",
    email: "dummy@email.com",
    dob: "01-May-1989",
    doj: "01-May-2011",
  },
  {
    name: "David Alaba",
    rank: "5.0",
    email: "dummy@email.com",
    dob: "01-May-1989",
    doj: "01-May-2011",
  },
  {
    name: "David Alaba",
    rank: "5.0",
    email: "dummy@email.com",
    dob: "01-May-1989",
    doj: "01-May-2011",
  },
  {
    name: "David Alaba",
    rank: "5.0",
    email: "dummy@email.com",
    dob: "01-May-1989",
    doj: "01-May-2011",
  },
  {
    name: "David Alaba",
    rank: "5.0",
    email: "dummy@email.com",
    dob: "01-May-1989",
    doj: "01-May-2011",
  },
  {
    name: "David Alaba",
    rank: "5.0",
    email: "dummy@email.com",
    dob: "01-May-1989",
    doj: "01-May-2011",
  },
  {
    name: "David Alaba",
    rank: "5.0",
    email: "dummy@email.com",
    dob: "01-May-1989",
    doj: "01-May-2011",
  },
  {
    name: "David Alaba",
    rank: "5.0",
    email: "dummy@email.com",
    dob: "01-May-1989",
    doj: "01-May-2011",
  },
  {
    name: "David Alaba",
    rank: "5.0",
    email: "dummy@email.com",
    dob: "01-May-1989",
    doj: "01-May-2011",
  },
  {
    name: "David Alaba",
    rank: "5.0",
    email: "dummy@email.com",
    dob: "01-May-1989",
    doj: "01-May-2011",
  },
  {
    name: "David Alaba",
    rank: "5.0",
    email: "dummy@email.com",
    dob: "01-May-1989",
    doj: "01-May-2011",
  },
];
export const AllPlayersRawData = [
  {
    _id: "1000",
    name: "Aniedi Udo",
    image:
      "https://res.cloudinary.com/www-daniekeys-com/image/upload/v1687906019/CoinMarketCap_g0lm9e.png",
  },
  {
    _id: "1001",
    name: "Ayeni Daniel",
    image:
      "https://res.cloudinary.com/www-daniekeys-com/image/upload/v1687870851/Telegram-Messenger-New_pivoaj.png",
  },
  {
    _id: "1002",
    name: "Cyril star",
    image:
      "https://res.cloudinary.com/www-daniekeys-com/image/upload/v1687870597/LinkedIn_logo_initials_ljiwuq.png",
  },
  {
    _id: "1003",
    name: "olamide star",
    image:
      "https://res.cloudinary.com/www-daniekeys-com/image/upload/v1653339134/unnamed_b3yoke.jpg",
  },
  {
    _id: "1004",
    name: "davido star",
    image:
      "https://res.cloudinary.com/www-daniekeys-com/image/upload/v1687348397/TBT_1-07_2_cimjws.png",
  },
  {
    _id: "1005",
    name: "Dbanj star",
    image:
      "https://res.cloudinary.com/www-daniekeys-com/image/upload/v1687348397/TBT_1-07_2_cimjws.png",
  },
  {
    _id: "1006",
    name: "Kcee star",
    image:
      "https://res.cloudinary.com/www-daniekeys-com/image/upload/v1687348397/TBT_1-07_2_cimjws.png",
  },
  {
    _id: "1007",
    name: "psquare stssar",
    image:
      "https://res.cloudinary.com/www-daniekeys-com/image/upload/v1687348397/TBT_1-07_2_cimjws.png",
  },
  {
    _id: "1008",
    name: "Rema boy star",
    image:
      "https://res.cloudinary.com/www-daniekeys-com/image/upload/v1687348397/TBT_1-07_2_cimjws.png",
  },
];
