import { useState, useEffect, ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import DashboardLayout from "../../Layouts/DashboardLayout";
import { AdminInput } from "../../components/Input";
import { resetProfileUpdate, editAdminProfile } from "./AdminAuth";
import { toast } from "react-toastify";
import { Btn } from "../../components/Button";
const AdminProfile = () => {
  const dispatch = useAppDispatch();
  const admin = useAppSelector((state) => state.adminAuth);

  const [phone, setPhone] = useState("");
  const [image, setImage] = useState("");
  const handleUpdate = () => {
    const data = {
      phone: phone,
      image: image
        ? image
        : "https://res.cloudinary.com/www-daniekeys-com/image/upload/v1717640876/admin-photo_xzcfyd.png",
    };

    dispatch(editAdminProfile(data));
  };
  useEffect(() => {
    if (admin?.editProfileSuccess) {
      toast.success("profile updated successfully");
      dispatch(resetProfileUpdate());
    }
  }, [admin?.editProfileSuccess]);

  return (
    <DashboardLayout current={6}>
      <div className="w-full pl-8 flex flex-col gap-8 max-w-[330px] pt-8">
        <p className="text-base text-customBlack">Add Phone number for admin</p>
        <AdminInput
          onChange={(e) => setPhone(e.target.value)}
          required={false}
        />
        <Btn
          active
          name="Update"
          loading={admin?.loading}
          onClick={handleUpdate}
        />
      </div>
    </DashboardLayout>
  );
};

export default AdminProfile;
