import { useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";

import { useNavigate } from "react-router-dom";
import { More } from "../../../../assets/svgs/court";
import notificationIcon from "../../../../assets/svgs/notification-bell.svg";
import {
  getInvitations,
  resetInvitation,
} from "../../../../features/auth/AuthSlice";
import LoadingComponent from "../../../../components/Loading/Loader";
const AllInvitations = () => {
  const navigate = useNavigate();
  const userSlice = useAppSelector((state) => state.auth);
  let invites = userSlice?.allInvitations;
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (userSlice.userData.success === false || undefined) {
      navigate("/login");
    }
    dispatch(resetInvitation());
    dispatch(getInvitations());
  }, [userSlice.userData]);

  if (userSlice?.fetchLoading) {
    return (
      <div className="w-full md:max-w-[428px] md:mx-auto mt-4 px-4">
        <LoadingComponent />
      </div>
    );
  }

  return (
    <>
      <div className="w-full flex flex-col px-4 py-6 md:max-w-[428px] md:mx-auto  ">
        <div className="flex justify-between mt-6 items-center">
          <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
          <p className="text-[#303030] font-bold text-[15px] ">
            All Invitations
          </p>
          <div className="more-shadow opacity-0">
            <More />
          </div>
        </div>
        {/* END OF THE ARROW SIDE */}
        <div className="flex flex-col mt-[30px] space-y-4">
          {!invites.length ? (
            <h1 className="text-center text-neutral-700 text-base mt-[30px] ">
              No Invitations Yet
            </h1>
          ) : (
            invites?.map((item: any, index: number) => {
              const { _tournament, to } = item;
              const handleClick = () => {
                navigate(
                  `/view-invitation?tid=${_tournament?._id}&uid=${to}&limit=${_tournament?.limit}`
                );
              };
              return (
                <div
                  className="w-full p-4 bg-white flex items-start border border-border rounded-[10px] space-x-4"
                  key={index}
                  onClick={handleClick}
                >
                  <div>
                    <img
                      src={notificationIcon}
                      alt="notifcation"
                      className="w-10 h-10"
                    />
                  </div>
                  <div className="flex flex-grow flex-col ">
                    <h1 className="text-customBlack font-bold text-sm ">
                      Knockout Tournament
                    </h1>
                    <p className="text-xs text-customBlack mt-[6px]">
                      You’ve been invited to a knockout to...{" "}
                      <span className="font-bold text-customBlack text-xs">
                        see more
                      </span>{" "}
                    </p>
                    <div className="flex justify-between mt-3">
                      <p className=" text-neutral-500 text-xs ">10:00 am</p>
                      <p className="text-xs text-lemonGreen ">View</p>
                    </div>
                  </div>
                </div>
              );
            })
          )}
          {/* start of a single */}

          {/* end of a single */}
        </div>
      </div>
    </>
  );
};

export default AllInvitations;
