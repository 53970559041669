import { useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import optionIcon from "../../../../assets/svgs/more.svg";
import receipt from "../../../../assets/svgs/receipt.svg";
import { convertDateTime, sortByDateField } from "../../../../utils";
import ReUseModal from "../../../Modal/ReUseModal";
import { AdminDateInput, AdminTimeInput } from "../../../Input/DateInput";
const styles = {
  activeLink:
    "text-[#82B020] border-b-[#82B020] border-b-[3px] text-base pb-[10px] cursor-pointer",
  inActiveLink: "text-neutral-400 text-base pb-[10px] cursor-pointer",
};

const KnockoutMatchesTables = ({ className }: any) => {
  const tournament = useAppSelector((state) => state.tournament);
  let allMatches = tournament.allMatches;
  let knockoutMatches = allMatches.filter(
    (item: any) => item?._tournament?.mode === "knockout"
  );

  // let sortedArray = sortByUpdatedAt(allMatches);
  const sortedArray = sortByDateField(knockoutMatches, {
    field: "time",
  });

  return (
    <div
      className={`w-full  h-full min-h-[40vh] max-h-screen flow-hide flex flex-col bg-white  rounded-[20px] ${className} `}
    >
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className="text-sm font-semibold text-customBlack">
          Knockout Matches
        </h1>
      </div>
      <table className="min-w-full w-full h-full bg-[#fff] rounded-b-[20px]   ">
        <thead className=" bg-[#f4f4f4] bg-opacity-40 mb-2   ">
          <tr className="flex items-center h-[35px] pl-5">
            <th className="w-3/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-start ">
              {" "}
              Date & Time
            </th>
            <th className="w-2/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA ">
              {" "}
              Player Name 1
            </th>
            <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA ">
              {" "}
              VS
            </th>
            <th className="w-2/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA ">
              {" "}
              Player Name 2
            </th>
            <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA ">
              {" "}
              Court
            </th>
            <th className="w-2/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA ">
              {" "}
              Results
            </th>
            <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA flex justify-center ">
              {" "}
              Action
            </th>
          </tr>
        </thead>
        <tbody className="w-full flex flex-col space-y-6 mb-4 mt-2">
          {!allMatches.length ? (
            <div className="mt-10 border-2.38 border-gray-600 w-38 h-38 flex flex-col items-center">
              <img
                src={receipt}
                className="border-2.5 border-gray-600 w-38 h-38"
                alt=""
              />
              <h1 className="font-inter text-sm font-medium leading-15 tracking-wider w-106 h-15 text-center mt-5">
                No Ongoing Knockout Matches
              </h1>
            </div>
          ) : (
            sortedArray.map((item: any, index: number) => {
              return <SingleRow item={item} index={index} />;
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default KnockoutMatchesTables;

const SingleRow = ({ item, index }: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [showTime, setShowTime] = useState(true);
  const [date, setDate] = useState("");

  const { setA, setB, setC, challenged, challenger, court, time } = item;
  let daytime = convertDateTime(time);
  let challengerName = ` ${
    challenger?.firstname ? challenger?.firstname : "BYE"
  } ${challenger?.lastname ? challenger?.lastname : ""} `;
  let challengedName = `${
    challenged?.firstname ? challenged?.firstname : "BYE"
  } ${challenged?.lastname ? challenged?.lastname : ""} `;
  let scores = `${setA?.challenger ? setA?.challenger : "0"} - ${
    setA?.challenged ? setA?.challenged : "0"
  }, ${" "} ${setB?.challenger ? setB?.challenger : "0"} - ${
    setB?.challenged ? setB?.challenged : "0"
  }, ${" "} ${setC?.challenger ? setC?.challenger : "0"} - ${
    setC?.challenged ? setC?.challenged : "0"
  }`;

  return (
    <>
      <tr className="w-full flex items-center pl-5 " key={index}>
        <td className="text-start text-sm text-neutral-800 w-3/12 flex gap-x-1 ">
          <p className="text-sm text-neutral-800">{daytime.date}</p>
          <p className="text-sm text-neutral-800 ">{daytime.time} </p>
        </td>
        <td className="text-center text-sm text-neutral-800 w-2/12 capitalize">
          {challengerName}
        </td>
        <td className="text-center text-sm text-neutral-800 w-1/12">VS</td>
        <td className="text-center text-sm text-neutral-800 w-2/12 capitalize">
          {challengedName}
        </td>
        <td className="text-center text-sm text-neutral-800 w-1/12 capitalize">
          {court?.name ? court.name : ""}
        </td>
        <td className="text-center text-sm text-neutral-800 w-2/12">
          {scores}
        </td>
        <td className="text-playerA text-sm text-neutral-800 w-1/12 flex justify-center">
          <img
            src={optionIcon}
            alt="options"
            className="cursor-pointer"
            onClick={() => setOpenModal(true)}
          />
        </td>
      </tr>
      <ReUseModal open={openModal} setOpen={setOpenModal}>
        <div className="flex flex-col">
          <div className="flex  space-x-[50px] items-center border-b border-b-[#cfcfcf] mt-10 ">
            <p
              className={showTime ? styles.activeLink : styles.inActiveLink}
              onClick={() => setShowTime(true)}
            >
              Time
            </p>
            <p
              className={
                showTime === false ? styles.activeLink : styles.inActiveLink
              }
              onClick={() => setShowTime(false)}
            >
              Date & Time
            </p>
          </div>
          {showTime ? (
            <div className="flex flex-col">
              <div className="flex flex-col mt-[23px]">
                <label htmlFor="" className="text-sm text-neutral-600">
                  Change Match Time
                </label>

                <AdminTimeInput
                  time={date}
                  setTime={setDate}
                  height="h-[50px]"
                />
              </div>
              <div className="grid grid-cols-2 gap-x-3 mt-[46px]">
                <button
                  className="w-full border border-lemonGreen h-[52px] flex items-center justify-center text-lemonGreen text-sm rounded-[16px] font-semibold cursor-pointer"
                  onClick={() => setOpenModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="w-full border bg-lemonGreen h-[52px] flex items-center justify-center text-white text-sm font-semibold rounded-[16px] cursor-pointer "
                  // onClick={updateDetails}
                >
                  Change
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="flex flex-col mt-[23px]">
                <label htmlFor="" className="text-sm text-neutral-600">
                  Change Match Date
                </label>

                <AdminDateInput
                  date={date}
                  setDate={setDate}
                  className="h-[30px] w-[230px] min-w-[230px] max-w-[230px]"
                />
              </div>
              <div className="flex flex-col mt-[23px]">
                <label htmlFor="" className="text-sm text-neutral-600">
                  Change Match Time
                </label>
                <AdminTimeInput
                  time={date}
                  setTime={setDate}
                  height="h-[50px]"
                />
              </div>
              <div className="grid grid-cols-2 gap-x-3 mt-[46px]">
                <button
                  className="w-full border border-lemonGreen h-[52px] flex items-center justify-center text-lemonGreen text-sm rounded-[16px] font-semibold cursor-pointer"
                  onClick={() => setOpenModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="w-full border bg-lemonGreen h-[52px] flex items-center justify-center text-white text-sm font-semibold rounded-[16px] cursor-pointer "
                  // onClick={updateDetails}
                >
                  Change
                </button>
              </div>
            </div>
          )}
        </div>
      </ReUseModal>
    </>
  );
};
