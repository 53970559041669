interface svgProps {
  width?: string;
  height?: string;
}

export const ChatSend = ({ width, height }: svgProps) => {
  return (
    <svg
      width={width ? width : "50"}
      height={height ? height : "50"}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="16" fill="#FFA800" />
      <path
        d="M17 33V20C17 19.2044 17.3161 18.4413 17.8787 17.8787C18.4413 17.3161 19.2044 17 20 17H30C30.7956 17 31.5587 17.3161 32.1213 17.8787C32.6839 18.4413 33 19.2044 33 20V26C33 26.7956 32.6839 27.5587 32.1213 28.1213C31.5587 28.6839 30.7956 29 30 29H21L17 33Z"
        stroke="white"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Award = () => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_567_2311)">
        <path
          d="M10.5017 13.6279L13.4767 18.7808L14.875 15.9527L18.0233 16.1558L15.0483 11.0029"
          fill="#FFEECC"
        />
        <path
          d="M10.5017 13.6279L13.4767 18.7808L14.875 15.9527L18.0233 16.1558L15.0483 11.0029"
          stroke="#FFA800"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.95166 11.0029L2.97666 16.1558L6.12498 15.9527L7.52329 18.7808L10.4983 13.6279"
          fill="#FFEECC"
        />
        <path
          d="M5.95166 11.0029L2.97666 16.1558L6.12498 15.9527L7.52329 18.7808L10.4983 13.6279"
          stroke="#FFA800"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 13.625C13.3995 13.625 15.75 11.2745 15.75 8.375C15.75 5.47551 13.3995 3.125 10.5 3.125C7.60051 3.125 5.25 5.47551 5.25 8.375C5.25 11.2745 7.60051 13.625 10.5 13.625Z"
          fill="#FFA800"
          stroke="#FFA800"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_567_2311">
          <rect
            width="21"
            height="21"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
