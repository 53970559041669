import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import trash from "../../../../assets/svgs/new-trash.svg";
import receipt from "../../../../assets/svgs/receipt.svg";
import {
  deleteCourt,
  getAllCourt,
  resetDeleteCourtSuccess,
  resetEditCourtSuccess,
  updateCourtDetails,
} from "../../../../features/auth/TournamentSlice";
import { capitalizeWords } from "../../../../utils";
import ReUseModal from "../../../Modal/ReUseModal";
import { ModalSub } from "../../../Text";

import { EditPencil, TrashIcon } from "../../../../assets/svgs/Icons";
import Options from "../../../Selects/Options";
const AllCourtTable = ({ className, setAllCourtNo }: any) => {
  const dispatch = useAppDispatch();
  const tournament = useAppSelector((state) => state.tournament);

  useEffect(() => {
    dispatch(getAllCourt());
    setAllCourtNo(tournament?.allCourts?.length);
  }, []);

  let allCourt = tournament?.allCourts;

  useEffect(() => {
    if (tournament.editCourtSuccess) {
      toast.success("Update Successful");

      setTimeout(() => {
        dispatch(resetEditCourtSuccess());
        dispatch(getAllCourt());
      }, 500);
    }
  }, [tournament.editCourtSuccess]);

  useEffect(() => {
    if (tournament.deleteCourtSuccess) {
      toast.success("Court Deleted Successful");

      setTimeout(() => {
        dispatch(resetDeleteCourtSuccess());
        dispatch(getAllCourt());
      }, 500);
    }
  }, [tournament.deleteCourtSuccess]);

  return (
    <div
      className={`w-full  min-h-[50vh]  flex flex-col bg-white  rounded-[20px] ${className} `}
    >
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className="text-sm font-semibold text-customBlack">All Courts</h1>
      </div>
      {/* Table section starts from here  #################################################3*/}
      <table className="min-w-full w-full h-full  ">
        <thead>
          <tr className="w-full bg-[#f4f4f4] flex items-center item-center h-[35px] pl-5 ">
            <th className=" text-sm text-neutral-600 font-medium flex items-center w-3/12">
              Court Name
            </th>
            <th className=" text-sm text-neutral-600 font-medium flex items-center w-1/12">
              Type of Court
            </th>
            <th className=" text-sm text-neutral-600 font-medium flex items-center w-3/12">
              Address
            </th>
            <th className=" text-sm text-neutral-600 font-medium flex items-center w-1/12">
              Country
            </th>
            <th className=" text-sm text-neutral-600 font-medium flex items-center w-1/12">
              State
            </th>
            <th className=" text-sm text-neutral-600 font-medium flex items-center justify-start w-1/12">
              Status
            </th>
            <th className=" text-sm text-neutral-600 font-medium flex items-center justify-center w-1/12">
              Actions
            </th>
          </tr>
        </thead>

        <tbody className="w-full  rounded-b-[20px] flex flex-col h-full ">
          {allCourt?.length === 0 && (
            <tr className="w-full ">
              <td className="w-full flex flex-col items-center justify-center h-full">
                <div className="  border-2.38 border-gray-600 font-normal  flex items-center justify-center">
                  <img
                    src={receipt}
                    className=" border-2.5 border-gray-600 font-normal "
                    alt=""
                  />
                </div>
                <span className="font-inter text-sm font-medium leading-15 tracking-wider w-106 h-15 text-center mt-8">
                  No available courts found
                </span>
              </td>
            </tr>
          )}
          {allCourt?.map((item: any, index: number) => {
            return (
              <SingleCourt item={item} key={index} total={allCourt?.length} />
            );
          })}
        </tbody>
      </table>
      {/* Table section Ends from here $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$4 */}
    </div>
  );
};

export default AllCourtTable;

const SingleCourt = ({ item, index, total }: any) => {
  const dispatch = useAppDispatch();
  const tournament = useAppSelector((state) => state.tournament);

  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [name, setName] = useState("");
  const updateDetails = () => {
    const data = {
      id: item?._id,
      name: name,
      type: "public",
    };
    dispatch(updateCourtDetails(data));
  };

  const DeleteCourt = () => {
    const data = {
      id: item?._id,
    };
    dispatch(deleteCourt(data));
  };

  useEffect(() => {
    if (tournament.editCourtSuccess) {
      setOpenEdit(false);
    }
  }, [tournament.editCourtSuccess]);

  useEffect(() => {
    if (tournament.deleteCourtSuccess) {
      setOpenDelete(false);
    }
  }, [tournament.deleteCourtSuccess]);
  const isLastfour = total > 4 ? index >= total - 4 : false;

  return (
    <>
      <tr className=" pl-5 flex items-center w-full  py-3  " key={index}>
        <td className=" w-3/12 flex gap-1 items-center text-sm text-customBlack font-medium text-start ">
          <span>
            <img
              src={item?.image}
              alt="court-image"
              className="w-7 h-7 rounded-full object-cover"
            />
          </span>
          <span>{capitalizeWords(item.name)}</span>
        </td>
        <td className=" w-1/12  text-sm text-customBlack font-medium text-start ">
          {capitalizeWords(item._type)}
        </td>
        <td className=" w-3/12  text-sm text-customBlack font-medium text-start ">
          {item.address}
        </td>
        <td className=" w-1/12  text-sm text-customBlack font-medium text-start ">
          {capitalizeWords(item.country)}
        </td>
        <td className=" w-1/12   text-sm text-customBlack font-medium text-start ">
          {capitalizeWords(item.state)}
        </td>
        <td className=" w-1/12   text-sm text-customBlack font-medium text-start ">
          <p className="bg-[#D1E5D6] text-[#197C34] text-xs p-[6px] rounded-[8px] flex items-center justify-center w-fit ">
            Available
          </p>
        </td>
        <td className=" flex justify-center w-1/12 ">
          <span>
            <Options isLast={isLastfour}>
              <div className="w-full flex flex-col ">
                <div
                  className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                  onClick={() => setOpenEdit(true)}
                >
                  <span>
                    <EditPencil />
                  </span>
                  <p className="text-customBlack text-sm">Edit court details</p>
                </div>

                <div
                  className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                  onClick={() => setOpenDelete(true)}
                >
                  <span>
                    <TrashIcon />
                  </span>
                  <p className="text-[#B00020] text-sm">Remove court</p>
                </div>
              </div>
            </Options>
          </span>
        </td>
      </tr>
      <ReUseModal open={openEdit} setOpen={setOpenEdit}>
        <div className="flex flex-col">
          <h1 className="text-base text-customBlack font-[500] ">
            Change Court Detail
          </h1>

          <div className="h-[2px] bg-[#CFCFCF] w-full mt-2 " />
          <div className="flex flex-col mt-[23px]">
            <label htmlFor="" className="text-sm text-neutral-600" font-normal>
              Change Court Name
            </label>
            <input
              type="text"
              className="h-[50px] border border-neutral-600 font-normal mt-2 cursor-pointer rounded-[5px] text-neutral-700 placeholder:text-placeholder px-3"
              value={name || item?.name}
              onChange={(e: any) => setName(e.target.value)}
            />
          </div>
          <div className="grid grid-cols-2 gap-x-3 mt-[46px]">
            <button
              className="w-full border border-lemonGreen h-[52px] flex items-center justify-center text-lemonGreen text-sm rounded-[16px] font-semibold cursor-pointer"
              onClick={() => setOpenEdit(false)}
            >
              Cancel
            </button>
            <button
              className="w-full border bg-lemonGreen h-[52px] flex items-center justify-center text-white text-sm font-semibold rounded-[16px] cursor-pointer "
              onClick={updateDetails}
            >
              Change
            </button>
          </div>
        </div>
      </ReUseModal>
      <ReUseModal open={openDelete} setOpen={setOpenDelete}>
        <div className="w-full flex flex-col justify-center items-center py-8">
          <img src={trash} alt="trash icon" className="mx-auto" />
          <p className="text-customBlack font-bold text-lg text-center">
            Delete Court
          </p>
          <ModalSub
            className="text-center mt-2"
            text="Are you sure you want to delete this court?"
          />
          <div className="w-11/12 mx-auto grid grid-cols-2 gap-x-4 items-center mt-16">
            <div
              className="flex w-full rounded-[16px] border border-lemonGreen items-center justify-center cursor-pointer text-sm text-lemonGreen h-[52px] "
              onClick={() => setOpenDelete(false)}
            >
              No
            </div>
            <div
              className=" bg-[#B00020] flex w-full rounded-[16px]  items-center justify-center text-sm text-white cursor-pointer  h-[52px] "
              // onClick={handleDelete(item._id)}
              // onClick={() => handleDelete(item._id)}
              onClick={DeleteCourt}
            >
              {tournament?.loading ? "Loading..." : "Yes"}
            </div>
          </div>
        </div>
      </ReUseModal>
    </>
  );
};
