import { useState, useEffect, MouseEvent } from "react";
import ColouredSelect from "../../../components/Selects/ColouredSelect";
import { categoriesNumber } from "../../../utils/sampData";
import { NavigationArrow } from "../../../assets/svgs/admin";
import DashboardLayout from "../../../Layouts/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import { Btn } from "../../../components/Button";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  addCategories,
  resetAll,
  setCategories,
} from "../../../features/auth/TournamentSlice";
import ReUseModal from "../../../components/Modal/ReUseModal";
import sentMark from "../../../assets/svgs/checkmark.svg";

const AddCategoriesPage = () => {
  const dispatch = useAppDispatch();
  const tournamentAuth = useAppSelector((state) => state.tournament);
  const id = useParams();
  let tid = id?.id;
  const [catNo, setCatNo] = useState<{
    value: number;
  }>({ value: 2 });
  const navigate = useNavigate();
  const [openCat, setOpenCat] = useState<boolean>(false);

  const [catNames, setCatNames] = useState<string[]>(Array(2).fill(""));
  const handleTeamNameChange = (index: number, name: string) => {
    const newCatNames = [...catNames];
    newCatNames[index] = name;
    setCatNames(newCatNames);
  };
  const handleAddCategories = () => {
    const data = {
      id: tournamentAuth?.competitionId,
      data: { names: catNames },
    };
    dispatch(addCategories(data));
  };
  useEffect(() => {
    if (tournamentAuth?.addCategoriesSuccess) {
      setOpenCat(true);
      dispatch(resetAll());
    }
  }, [tournamentAuth?.addCategoriesSuccess]);

  return (
    <DashboardLayout current={3}>
      <div className="w-full flex flex-col bg-[#f4f4f4] h-full min-h-max  p-[30px] 2xl:p-8 2xl:pr-[50px]">
        <div className="flex w-fit" onClick={() => navigate(-1)}>
          <NavigationArrow />
        </div>
        <div
          className={`bg-white rounded-[20px] flex flex-col   mt-6 relative min-h-screen`}
        >
          <div className="">
            <div className="flex flex-col">
              <div className="h-[51px] ">
                <h1 className="text-customBlack font-semibold text-sm mt-[22px] pl-5 ">
                  Assign Players to Categories
                </h1>
              </div>
              {/* breaker line */}
              <div className="w-full h-[1px] bg-[#cfcfcf] " />
              <p className="mt-3 text-neutral-700 font-normal pl-5">
                Select the number of categories for this competition and assign
                players
              </p>
              {/* start of function session */}
              <div className="mt-6 flex items-center gap-4 min-w-[383px] max-w-[385px] justify-between pl-5  ">
                <p className="text-sm text-customBlack ">
                  Number of Categories:
                </p>
                <div className="w-16">
                  <ColouredSelect
                    data={categoriesNumber}
                    name="2"
                    selected={catNo}
                    setSelected={setCatNo}
                  />
                </div>
              </div>
              <p className="text-customBlack text-sm font-medium mt-[30px] mb-4 pl-5">
                Name of Categories
              </p>
              <div className="flex flex-col pb-8">
                {Array.from({ length: catNo.value }, (_, index) => (
                  <div
                    className="flex  items-center mb-4 max-w-[393px] justify-between pl-5"
                    key={index}
                  >
                    <p className="text-neutral-700">Category {index + 1}:</p>
                    <input
                      type="text"
                      className="outline-none h-[30px] rounded-[5px] border border-neutral-400 w-[230px] px-2 ml-6"
                      value={catNames[index]}
                      onChange={(e) =>
                        handleTeamNameChange(index, e.target.value)
                      }
                    />
                  </div>
                ))}
              </div>
              <div
                className={` flex items-center justify-center max-w-[413px] w-5/12 mx-auto mt-8 ${
                  catNo?.value > 8 ? "flex" : "absolute bottom-5 left-0 right-0"
                } `}
              >
                <Btn
                  name=" Continue"
                  active={true}
                  className=""
                  onClick={handleAddCategories}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReUseModal open={openCat} setOpen={setOpenCat}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-customBlack space-y-1 text-[24px] font-bold text-center">
          Categories Added
        </h1>
        <p className="text-center w-10/12 mx-auto text-neutral-500 text-sm">
          You have successfully added the categories for the team competition.
        </p>
        <Btn
          active={true}
          name="Continue"
          onClick={() => navigate("/admin/tournament")}
          className="mt-[49px] mb-3"
        />
      </ReUseModal>
    </DashboardLayout>
  );
};

export default AddCategoriesPage;
