import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { LocationIcon, PendingIcon } from "../../assets/svgs/court";

import useD from "../../assets/png/use4.jpg";
import { BackArrow } from "../../assets/svgs/court";

import { RedBtn, Btn, OutlinedBtn } from "../../components/Button";
import ReUseModal from "../../components/Modal/ReUseModal";
import { CautionIcon } from "../../assets/svgs/Icons";
import sentMark from "../../assets/svgs/sent-mark.svg";
const SingleCourtReservation = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState<boolean>(false);
  const [openConsentModal, setOpenConsentModal] = useState<boolean>(false);

  return (
    <>
      <div className="w-full flex flex-col   md:max-w-[428px] md:mx-auto md:h-screen h-screen relative">
        <div
          className="imag-shad-big relative rounded-[30px] flex flex-col "
          style={{
            width: "100%",
            height: "300px",
            minWidth: "100%",
            minHeight: "300px",

            marginBottom: "16px",
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${useD})`,
            backgroundSize: "cover",
          }}
        >
          <span
            className="ml-8 mt-16 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <BackArrow />
          </span>
        </div>
        <div className="px-6 pb-6 mt-5 flex flex-col w-full">
          <div className="w-full flex items-center justify-between">
            <div className="flex flex-col w-7/12">
              <h1 className="text-2xl font-bold text-[#333333] capitalize">
                Lagos metropolitan tennis club
              </h1>
              <div className="flex gap-1 items-center mt-2">
                <span>
                  <LocationIcon />
                </span>
                <p className="text-neutral-600 text-[12px] ">
                  12, Adesanya Street,Ikeja, Lagos
                </p>
              </div>
            </div>
            <div>
              <p className="text-primaryYellow bg-[#FFEECC] p-2 rounded-[15px] h-[31px] flex items-center text-sm">
                Pending
              </p>
            </div>
          </div>

          <p className="text-[#303030] text-[12px] mt-5 leading-[24px] ">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem eos
            architecto quod necessitatibus qui quo. Cupiditate ullam placeat
            esse quod.
          </p>
          <h2 className="text-sm font-bold text-customBlack mt-6">
            Booking Details
          </h2>

          <div className="w-full min-h-[50px] mt-5 flex items-center border border-[#CFCFCF] px-6 justify-between rounded-[10px] mb-3">
            <p className=" text-xs text-[#5E5E5E]">Date</p>
            <p className="text-sm font-semibold text-customBlack">
              Jan 20, 2024
            </p>
            <p></p>
          </div>
          <div className="w-full min-h-[50px] flex items-center border border-[#CFCFCF] px-6 justify-between rounded-[10px] mb-3">
            <p className=" text-xs text-[#5E5E5E]">Time</p>
            <p className="text-sm font-semibold text-customBlack">6:00pm</p>
            <p></p>
          </div>
        </div>
        <div className="px-6  absolute bottom-6 left-0 right-0 ">
          <RedBtn
            active
            name="Cancel Reservation"
            onClick={() => setOpenConsentModal(true)}
          />
        </div>
        {/* end of the Destinations */}
      </div>
      <ReUseModal open={open} setOpen={setOpen}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-cutomBlack text-lg font-bold text-center ">
          Court Booking Cancelled
        </h1>
        <p className="text-center w-10/12 mx-auto text-neutral-500  max-w-[331px] text-sm ">
          You’ve successfully cancelled your reservation
        </p>
        <Btn
          active={true}
          name="Close"
          onClick={() => {
            setOpen(false);
            navigate(`/find-court`);
          }}
          className="mt-[25px] mb-3 max-w-[170px] mx-auto"
        />
      </ReUseModal>
      <ReUseModal open={openConsentModal} setOpen={setOpenConsentModal}>
        <div className="w-full flex flex-col">
          <span className="mx-auto mt-6">
            <CautionIcon />
          </span>

          <h1 className="text-customBlack text-[18px] font-bold text-center mt-6">
            Cancel Booking
          </h1>
          <p className="text-center w-10/12 mx-auto text-neutral-500 text-sm ">
            Are you sure you want to cancel your court reservation?
          </p>
          <div className="flex justify-between items-center mt-4 gap-5">
            <span className="w-1/2">
              <OutlinedBtn
                active
                onClick={() => setOpenConsentModal(false)}
                name="No Cancel"
              />
            </span>
            <span className="w-1/2">
              <button
                className="h-[56px] w-full rounded-[16px] bg-[#B00020]  text-white font-semibold text-base  flex items-center justify-center cursor-pointer"
                onClick={() => {
                  setOpenConsentModal(false);
                  setOpen(true);
                }}
              >
                Yes,Cancel
              </button>
            </span>
          </div>
        </div>
      </ReUseModal>
    </>
  );
};

export default SingleCourtReservation;
