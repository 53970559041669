import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
const FavourtiteMatches = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <div className=" flex-col lg:flex hidden mt-6 px-4 justify-center h-screen w-1/2 mx-auto">
        <h1 className="font-bold text-customBlack text-xl text-center mb-2 ">
          Oops, this page is only designed for mobile devices.
        </h1>
        <p className="text-neutral-700 text-base  ">
          Please switch to a smaller screen, such as a smartphone or tablet, to
          enjoy this feature. We appreciate your cooperation.
        </p>
      </div> */}
      <div className="w-full flex flex-col px-4 py-6 md:max-w-[428px] md:mx-auto md:h-screen">
        <div className="flex justify-between mt-4 items-center">
          <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
          <p className="text-[#303030] font-bold text-[15px] ">
            Favourite Matches
          </p>
          <div className="more-shadow opacity-0"></div>
        </div>
        <div className="flex flex-col w-full space-y-2">
          {/* start of a sessiom */}
          <div className="w-full h-20 border-border border rounded-md flex items-center justify-center px-4 space-x-4 ">
            <div className="flex flex-col">
              <p className="text-[14px] text-[#414141] font-semibold">
                Brenda Miles
              </p>
              <p className="text-[10px] text-[#acacac]">RK: 45,093,334</p>
            </div>
            <div className="flex flex-col">
              <p className="text-[12px] font-bold text-[#ff9900]">19:00</p>
              <p className="text-[12px] font-bold text-[#ff9900]">12 JUN</p>
            </div>
            <div className="flex flex-col">
              <p className="text-[14px] text-[#414141] font-semibold">
                Brenda Miles
              </p>
              <p className="text-[10px] text-[#acacac]">RK: 45,093,334</p>
            </div>
          </div>
          {/* end of a session */}
          {/* start of a sessiom */}
          <div className="w-full h-20 border-border border rounded-md flex items-center justify-center px-4 space-x-4 ">
            <div className="flex flex-col">
              <p className="text-[14px] text-[#414141] font-semibold">
                Brenda Miles
              </p>
              <p className="text-[10px] text-[#acacac]">RK: 45,093,334</p>
            </div>
            <div className="flex flex-col">
              <p className="text-[12px] font-bold text-[#ff9900]">19:00</p>
              <p className="text-[12px] font-bold text-[#ff9900]">12 JUN</p>
            </div>
            <div className="flex flex-col">
              <p className="text-[14px] text-[#414141] font-semibold">
                Brenda Miles
              </p>
              <p className="text-[10px] text-[#acacac]">RK: 45,093,334</p>
            </div>
          </div>
          {/* end of a session */}
          {/* start of a sessiom */}
          <div className="w-full h-20 border-border border rounded-md flex items-center justify-center px-4 space-x-4 ">
            <div className="flex flex-col">
              <p className="text-[14px] text-[#414141] font-semibold">
                Brenda Miles
              </p>
              <p className="text-[10px] text-[#acacac]">RK: 45,093,334</p>
            </div>
            <div className="flex flex-col">
              <p className="text-[12px] font-bold text-[#ff9900]">19:00</p>
              <p className="text-[12px] font-bold text-[#ff9900]">12 JUN</p>
            </div>
            <div className="flex flex-col">
              <p className="text-[14px] text-[#414141] font-semibold">
                Brenda Miles
              </p>
              <p className="text-[10px] text-[#acacac]">RK: 45,093,334</p>
            </div>
          </div>
          {/* end of a session */}
        </div>
      </div>
    </>
  );
};

export default FavourtiteMatches;
