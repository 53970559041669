import { useEffect, useState } from "react";
import { AllPlayersRawData } from "../../../utils/sampData";
import AutoSchedule from "./AutoSchedule";
import Schedule from "./Schedule";
import ViewAllPlayer from "./ViewAllPlayer";

const FirstCome = () => {
  const [selectedPlayer] = useState<any>();

  const [nextPage, setNextPage] = useState(true);
  const [filterArray, setFilterArray] = useState(AllPlayersRawData);
  const [arrays] = useState<any>([]);

  useEffect(() => {
    let newPlayerList = filterArray.filter(
      (p) => p?._id !== selectedPlayer?._id
    );
    setFilterArray(newPlayerList);
  }, [selectedPlayer]);

  return (
    <>
      {nextPage ? (
        <ViewAllPlayer arrays={arrays} setNextPage={setNextPage} />
      ) : (
        <div className="flex flex-col">
          <ScheduleType />
        </div>
      )}
    </>
  );
};

export default FirstCome;

const ScheduleType = () => {
  const [autoSchedule, setAutoSchedule] = useState(false);
  return (
    <>
      {autoSchedule ? (
        <AutoSchedule
          autoSchedule={autoSchedule}
          setAutoSchedule={setAutoSchedule}
        />
      ) : (
        <Schedule
          autoSchedule={autoSchedule}
          setAutoSchedule={setAutoSchedule}
        />
      )}
    </>
  );
};
