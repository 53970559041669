import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import docSvg from "../../assets/svgs/document-download.svg";
import { Btn } from "../Button";
import { toast } from "react-toastify";
import {
  getClubDetails,
  resetClubUpdate,
  updateClubDetails,
} from "../../features/auth/AdminAuth";
import LoadingComponent from "../Loading/Loader";
import UploadImageAdmin from "../upload/Upload-image-admin";

const ClubOverview = () => {
  const adminAuth = useAppSelector((state) => state.adminAuth);
  const dispatch = useAppDispatch();
  let details = adminAuth.clubDeatails;
  const [name, setName] = useState(details?.name);
  const [address, setAddress] = useState(details?.address);
  const [city, setCity] = useState(details?.state);
  const [country, setCountry] = useState(details?.country);
  const [bio, setBio] = useState(details?.bio);
  const [image, setImage] = useState("");
  const [rules, setRules] = useState(details?.rules);
  const [phone, setPhone] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [previewing, setPreviewing] = useState<any>("");
  const handleUpdate = () => {
    const data = {
      name: name,
      image: imageUrl,
      thumbnail: image,
      address: address,
      state: city,
      country: country,
      bio: bio,
      phone: phone,
    };
    dispatch(updateClubDetails(data));
  };

  useEffect(() => {
    if (adminAuth?.updateClubSuccess) {
      toast.success("Club Details Updated Successfully");
      dispatch(resetClubUpdate());
      dispatch(getClubDetails());
    }
  }, [adminAuth?.updateClubSuccess]);

  if (adminAuth?.fetchLoading) {
    return (
      <div className="mt-8 px-4">
        <LoadingComponent />
      </div>
    );
  }

  return (
    <div className="w-1128 h-777 top-218 left-262 rounded-20 w-1128 top-218 left-262 rounded-20 bg-white rounded-[20px] flex flex-col h-full mt-6 flow-hide relative pl-5 ">
      <h1 className="mt-4 text-sm text-neutral-400">CLUB INFORMATION</h1>
      <div className="w-9/12 grid grid-cols-2  gap-x-[60px] gap-y-[30px] mt-7">
        {/* start of a form */}
        <div className="flex items-left w-auto h-auto top-67 left-20 flex-col ">
          <p className=" w-125 text-gray-600 text-left font-inter text-base font-normal leading-24 mb-2">
            Name of Your Club
          </p>
          <input
            type="text"
            className=" font-inter text-base font-normal leading-24 tracking-normal text-left w-115 top-13 left-12 text-gray-900 w-442 h-50 p-y-13 p-r-315 p-x-12 border-1 border-solid border-gray-600 rounded-5 rounded-[5px] border py-2 px-2 placeholder:text-placeholder capitalize"
            value={name}
            onChange={(e: any) => setName(e.target.value)}
          />
        </div>
        {/* end of a form */}
        {/* start of a form */}
        <div className="flex items-left w-auto h-auto top-177 left-20 flex-col ">
          <p className=" font-inter text-base font-normal leading-24 tracking-normal text-left w-55 text-gray-600 mb-2">
            Address
          </p>
          <input
            type="text"
            className=" font-inter text-base font-normal leading-24 tracking-normal text-left w-55 top-13 left-12 text-gray-900 w-442 h-50 p-y-13 p-r-375 p-x-12 border-1 border-solid border-gray-600 rounded-5 rounded-[5px] border py-2 px-2 placeholder:text-placeholder capitalize"
            value={address}
            onChange={(e: any) => setAddress(e.target.value)}
          />
        </div>
        {/* end of a form */}

        {/* start of a form */}
        <div className="flex items-left w-auto h-auto top-177 left-20 flex-col ">
          <p className="font-inter text-base font-normal leading-24 tracking-normal text-left w-55 text-gray-600 mb-2 ">
            State
          </p>
          <input
            type="text"
            className=" font-inter text-base font-normal leading-24 tracking-normal text-left w-55 top-13 left-12 text-gray-900 w-442 h-50 p-y-13 p-r-375 p-x-12 border-1 border-solid border-gray-600 rounded-5 rounded-[5px] border py-2 px-2 placeholder:text-placeholder capitalize "
            value={city}
            onChange={(e: any) => setCity(e.target.value)}
          />
        </div>
        {/* end of a form */}
        {/* start of a form */}
        <div className="flex items-left w-auto h-auto top-177 left-20 flex-col">
          <p className=" font-inter text-base font-normal leading-24 tracking-normal text-left w-55 text-gray-600 mb-2  ">
            Country
          </p>
          <input
            type="text"
            className=" font-inter text-base font-normal leading-24 tracking-normal text-left w-55 text-gray-900 w-442 h-50 p-y-13 p-r-375 p-x-12 border-1 border-solid border-gray-600 rounded-5 rounded-[5px] border py-2 px-2 placeholder:text-placeholder capitalize "
            value={country}
            onChange={(e: any) => setCountry(e.target.value)}
          />
        </div>
        {/* end of a form */}
        {/* start of a form */}
        <div className=" w-442 border-solid border-gray-600 rounded-5 text-gray-600">
          <p className=" font-inter text-base font-normal leading-24 tracking-normal text-left w-full top-13 left-12 text-neutral-600 mb-2">
            Bio of the Club
          </p>
          <textarea
            className=" py-2 px-2 w-full text- h-120 pb-10 pr-20 text-left resize-none border border-1 rounded-5 rounded-[5px] border-solid border-gray-600 rounded-5 text-gray-600 placeholder:text-placeholder capitalize "
            value={bio}
            placeholder="Write something.."
            onChange={(e: any) => setBio(e.target.value)}
          />

          <div className=" mt-7 flex-none w-442 border-solid border-gray-600 rounded-5 text-gray-600 ">
            <p className="font-inter text-base font-normal leading-24 tracking-normal w-98 text-gray-600 mb-2">
              Rules and Regulation
            </p>
            <textarea
              className=" py-2 px-2 font-inter text-base font-normal leading-24 tracking-normal text-left w-full h-24 top-13 left-12 border border-1 rounded-5 rounded-[5px] rounded-5 w-442 h-120 pb-10 pr-20 resize-none border-1 rounded-5 border-solid border-gray-600 text-gray-600"
              value={rules}
              placeholder="Write something.."
              onChange={(e: any) => setRules(e.target.value)}
            />
          </div>
        </div>
        {/* end of a form */}
        {/* start of a form */}
        <div className="flex  w-auto h-auto  flex-col">
          <div className="flex items-left w-auto h-auto top-67 left-20 flex-col mb-8 ">
            <p className=" w-125 text-gray-600 text-left font-inter text-base font-normal leading-24 mb-2">
              Club Admin Phone (whatsapp)
            </p>
            <input
              type="tel"
              className=" font-inter text-base font-normal leading-24 tracking-normal text-left w-115 top-13 left-12 text-gray-900 w-442 h-50 p-y-13 p-r-315 p-x-12 border-1 border-solid border-gray-600 rounded-5 rounded-[5px] border py-2 px-2 placeholder:text-placeholder capitalize"
              value={phone}
              onChange={(e: any) => setPhone(e.target.value)}
            />
          </div>
          <div className="w-full flex flex-col">
            <label className="text-grey-600 text-sm mb-2 ">
              Upload Club Image
            </label>
            <UploadImageAdmin
              getFileUrl={setImageUrl}
              setPreviewing={setPreviewing}
            />
          </div>
        </div>
        {/* end of a form */}
      </div>
      <div className="w-4/12 mx-auto mt-[150px] mb-12">
        <Btn
          active={true}
          name="Update"
          onClick={handleUpdate}
          loading={adminAuth?.loading}
        />
      </div>
    </div>
  );
};

export default ClubOverview;
