import { useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { More } from "../../assets/svgs/court";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import LoadingComponent from "../../components/Loading/Loader";
import { dummyTeamValues } from "../../utils/laddersampdata";
import { getSingleCompetitionDetailUser } from "../../features/auth/TournamentSlice";
import dummyPic from "../../assets/png/profile-img.png";
import UserTeamCard from "../../components/cards/user-team-card";
import { SectionTitle } from "../../components/Text/new-text";
const SelectedCompetition = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ladder = useAppSelector((state) => state.ladder);
  const tournament = useAppSelector((state) => state.tournament);
  const auth = useAppSelector((state) => state.auth);

  const id = useParams();

  useEffect(() => {
    dispatch(getSingleCompetitionDetailUser({ id: id.id }));
  }, []);

  const details = tournament?.competitionDetailUser;

  const userId = auth?.userData?.user?._id;
  // Find the player object where the user ID matches
  const player = details?.players.find(
    (player: any) => player._id._id === userId
  );

  const admin = details?._creator;
  // Assign the team ID to UserTeamId
  const UserTeamId = player ? player.team : null;

  const handleClick = () => {
    const formattedNumber = admin?.phone.replace(/\D/g, ""); // Remove non-digit characters
    const whatsappUrl = `https://wa.me/${formattedNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="w-full md:h-screen md:max-w-[428px] md:mx-auto flex flex-col px-4 py-6 relative min-h-screen">
      <div className="flex justify-between mt-6 mb-[30px] items-center">
        <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
        {/* <p className="text-customBlack font-bold text-base truncate capitalize ">
          Single Day Competition
        </p> */}
        <SectionTitle text={details?.name} />
        <div className="more-shadow opacity-0">
          <More />
        </div>
      </div>
      {/* END OF THE ARROW SIDE */}

      {tournament?.fetchLoading ? (
        <div className="mt-8 px-4">
          <LoadingComponent />
        </div>
      ) : (
        <div className="w-full flex flex-col">
          <div className="new-home-banner flex flex-col justify-center px-4 min-w-full py-8 rounded-[24px] ">
            <div className="flex space-x-4">
              <img
                src={dummyPic}
                alt="profile avatar"
                className="w-[100px] h-[100px] rounded-full object-cover"
              />
              <div className="flex flex-col justify-center">
                <p className="text-white text-lg  font-semibold mb-1 capitalize">
                  {admin?.firstname + " " + admin?.lastname}
                </p>
                <p className="text-sm text-white mb-2">
                  Competition Administrator
                </p>
                <div className="flex gap-2 items-center">
                  <p className="text-xs text-[#E4F7D1] p-[10px] rounded-[100px] bg-[#92D250] min-w-max">
                    Team Competition
                  </p>
                  <p
                    className=" bg-[#92D250] rounded-[100px] p-[10px] text-xs text-[#E4F7D1]  cursor-pointer min-w-max "
                    onClick={handleClick}
                  >
                    Chat Admin
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* END OF THE PROFILE HEADER */}
          <div className="flex flex-col w-full mt-12 ">
            <div className="flex justify-between items-center">
              <SectionTitle text={"Competition Board"} />
            </div>

            <div className="mt-6 flex flex-col space-y-4">
              {details?.teams?.map((item: any, index: number) => {
                return (
                  <UserTeamCard
                    index={index}
                    key={index}
                    isActive={UserTeamId === item?._id}
                    name={item?.name}
                    points={Number(item?.points * 3)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectedCompetition;
