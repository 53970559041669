import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../../Layouts/DashboardLayout";
import samp from "../../../assets/png/samp-p-one.png";
import { NavigationArrow } from "../../../assets/svgs/admin";
import { Btn } from "../../../components/Button";
const ViewPlayersFirstCome = () => {
  const navigate = useNavigate();
  const [arrays, setArray] = useState<any>([]);
  useEffect(() => {
    let actual = 14;
    let arrays = [];
    for (let i = 0; i < actual; i++) {
      arrays.push(i);
    }
    setArray(arrays);
  }, []);

  return (
    <DashboardLayout current={2}>
      <div className="w-full flex flex-col bg-[#f4f4f4] h-full p-[30px] 2xl:p-8 2xl:pr-[50px] ">
        <div className="flex w-fit" onClick={() => navigate(-1)}>
          <NavigationArrow />
        </div>
        <div className="bg-white rounded-[20px] flex flex-col h-full mt-6 flow-hide relative ">
          <div className="h-[51px] ">
            <h1 className="pl-5 text-customBlack font-semibold text-sm mt-[22px] ">
              All Accepted Players
            </h1>
          </div>
          <div className="w-full h-[35px] flex items-center space-x-[43px] border border-border bg-[#f4f4f4] bg-opacity-30 pl-5">
            <p>S/N</p>
            <p className="text-xs text-neutral-500">
              Names of Accepted Players
            </p>
          </div>
          <div className="w-full flex flex-col">
            {arrays?.map((item: any, index: number) => {
              return (
                <div
                  className="w-full h-[35px] flex items-center space-x-[43px] pl-5 mt-6"
                  key={index}
                >
                  <p className="text-customBlack">{index + 1}</p>
                  <div className="flex items-center space-x-2">
                    <img src={samp} alt="" />
                    <p className="text-md font-[500] ">Ayeni Daniel </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="fixed z-100 bottom-12 left-0 right-0 flex justify-center">
            <div className="w-3/12 flex justify-center">
              <Btn
                name="Proceed"
                active={true}
                onClick={() => console.info("hello")}
                className="mx-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ViewPlayersFirstCome;
