import { useEffect, useState } from "react";
import { NavigationArrow } from "../../../assets/svgs/admin";
import { DeleteIcon } from "../../../assets/svgs/tournament";
import { Btn, OutlinedBtn } from "../../Button";
import { useNavigate, useParams } from "react-router-dom";
import ReUseModal from "../../Modal/ReUseModal";
import sentMark from "../../../assets/svgs/checkmark.svg";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getSingleLadderDetail,
  deleteLadder,
  resetAllLadderState,
  startLadder,
} from "../../../features/auth/ladder-slice";
import { formatDateBasic } from "../../../utils";
import { toast } from "react-toastify";
import LoadingComponent from "../../Loading/Loader";
const ViewLadderDetails = ({ isEdit }: any) => {
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteSuccess, setOpenDeleteSuccess] = useState(false);
  const id = useParams();
  const dispatch = useAppDispatch();
  const ladder = useAppSelector((state) => state.ladder);

  const item = ladder?.singleLadder;

  const {
    players,

    name,
    _type,
    start,
    stop,
    status,
    points,
    deadline,
    challenge_power,
    challenge_timeout,
    loss,
    walkover,
    pass,
  } = item;
  useEffect(() => {
    dispatch(resetAllLadderState());
  }, []);

  useEffect(() => {
    dispatch(getSingleLadderDetail({ id: id.id }));
  }, []);

  const handleDelete = () => {
    dispatch(deleteLadder({ id: id.id }));
  };

  useEffect(() => {
    if (ladder?.ladderDeleteSuccess) {
      setOpenDelete(false);
      setOpenDeleteSuccess(true);
      setTimeout(() => {
        dispatch(resetAllLadderState());

        navigate("/admin/tournament");
      }, 3000);
    }
  }, [ladder?.ladderDeleteSuccess]);
  const handleManualStart = () => {
    dispatch(startLadder({ id: id.id }));
  };

  useEffect(() => {
    if (ladder?.startLadderSuccess) {
      toast.success("Ladder has successfully been started manually");
      setTimeout(() => {
        dispatch(resetAllLadderState());
        navigate("/admin/tournament");
      }, 2000);
    }
  }, [ladder?.startLadderSuccess]);

  return (
    <div className="w-full flex flex-col bg-[#f4f4f4] h-full p-[30px] 2xl:p-8 2xl:pr-[50px] ">
      <div className="flex items-center justify-between">
        <div className="flex w-fit cursor-pointer" onClick={() => navigate(-1)}>
          <NavigationArrow />
        </div>
        <div className="flex justify-between items-center gap-8">
          {/* <div className="w-[300px]"></div> */}
          <Btn
            name="Manual Start"
            active
            onClick={handleManualStart}
            loading={ladder?.loading}
            className="min-w-[200px]"
          />
          <button
            className="min-w-[174px]  w-full rounded-[16px] h-[52px] flex items-center justify-center text-white text-sm leading-[24px] tracking-[0.14px] font-semibold bg-[#B00020] cursor-pointer "
            onClick={() => setOpenDelete(true)}
          >
            Delete Tournament
          </button>
        </div>
      </div>
      {/* content */}
      {ladder?.fetchLoading ? (
        <div className="px-8 mt-8 ">
          <LoadingComponent />
        </div>
      ) : (
        <div className="w-full rounded-[20px] flex flex-col bg-white mt-[21px]   ">
          <div className="h-[51px] flex items-center">
            <h1 className="pl-5 text-customBlack font-semibold text-sm leading-[24px] tracking-[0.14px] capitalize  ">
              {name}
            </h1>
          </div>
          {/* breaker line */}
          <div className="w-full h-[1px] bg-[#cfcfcf] " />
          <div className="flex flex-col w-full mt-3 pl-5">
            <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600 ">
              The ladder started on
              <span className="text-customBlack font-bold">
                {" "}
                {formatDateBasic(start)}
              </span>{" "}
              and ends on{" "}
              <span className="text-customBlack font-bold">
                {formatDateBasic(stop)}
              </span>{" "}
              Registration deadline:
              <span className="text-customBlack font-bold">
                {" "}
                {formatDateBasic(deadline)}
              </span>
            </p>
            <div className="w-6/12 2xl:w-5/12 flex flex-col mt-6">
              <h1 className="text-customBlack text-sm leading-[24px] tracking-[0.14px] font-bold">
                Details
              </h1>
              {/* start of item */}
              <div className="flex justify-between items-center mt-3">
                <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                  Type of Ladder
                </p>
                <p className="text-sm leading-[24px] tracking-[0.14px] text-customBlack font-bold capitalize">
                  {_type} Ladder
                </p>
              </div>
              {/* end of an item */}
              {/* start of item */}
              <div className="flex justify-between items-center mt-3">
                <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                  Status
                </p>
                <p className="bg-[#D1E5D6] text-[#197C34] text-xs p-[6px] rounded-[8px] flex items-center justify-center w-fit capitalize ">
                  {status}
                </p>
              </div>
              {/* end of an item */}
              {/* start of item */}
              <div className="flex justify-between items-center mt-3">
                <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                  Total No of Players
                </p>
                <p className="text-sm leading-[24px] tracking-[0.14px] text-customBlack font-bold">
                  {players?.length}
                </p>
              </div>
              {/* end of an item */}
              {/* start of item */}
              <div className="flex justify-between items-center mt-3">
                <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                  {" "}
                  No of Male Players
                </p>
                <p className="text-sm leading-[24px] tracking-[0.14px] text-customBlack font-bold">
                  0
                </p>
              </div>
              {/* end of an item */}
              {/* start of item */}
              <div className="flex justify-between items-center mt-3">
                <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                  {" "}
                  No of Female Players
                </p>
                <p className="text-sm leading-[24px] tracking-[0.14px] text-customBlack font-bold">
                  0
                </p>
              </div>
              {/* end of an item */}
              {/* start of item */}
              <div className="flex justify-between items-center mt-3">
                <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                  {" "}
                  No of Matches
                </p>
                <p className="text-sm leading-[24px] tracking-[0.14px] text-customBlack font-bold">
                  {ladder?.singleLadderMatches?.length}
                </p>
              </div>
              {/* end of an item */}
              {/* start of item */}
              <div className="flex justify-between items-center mt-3">
                <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                  {" "}
                  No of Players that can be challenged
                </p>
                <p className="text-sm leading-[24px] tracking-[0.14px] text-customBlack font-bold">
                  {challenge_power}
                </p>
              </div>
              {/* end of an item */}
              {/* start of item */}
              <div className="flex justify-between items-center mt-3">
                <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                  {" "}
                  No of points earned for winning a challenge
                </p>
                <p className="text-sm leading-[24px] tracking-[0.14px] text-customBlack font-bold">
                  {points}
                </p>
              </div>
              {/* end of an item */}
              {/* start of item */}
              <div className="flex justify-between items-center mt-3">
                <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                  {" "}
                  No of points earned for losing a challenge
                </p>
                <p className="text-sm leading-[24px] tracking-[0.14px] text-customBlack font-bold">
                  {loss}
                </p>
              </div>
              {/* end of an item */}
              {/* start of item */}
              <div className="flex justify-between items-center mt-3">
                <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                  {" "}
                  No of points earned for winning a match by pass
                </p>
                <p className="text-sm leading-[24px] tracking-[0.14px] text-customBlack font-bold">
                  {pass}
                </p>
              </div>
              {/* end of an item */}
              {/* start of item */}
              <div className="flex justify-between items-center mt-3">
                <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                  {" "}
                  No of points earned for winning a match by walkover
                </p>
                <p className="text-sm leading-[24px] tracking-[0.14px] text-customBlack font-bold">
                  {walkover}
                </p>
              </div>
              {/* end of an item */}
              {/* start of item */}
              <div className="flex justify-between items-center mt-3">
                <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                  {" "}
                  Expiration Period for a Challenge Invitation
                </p>
                <p className="text-sm leading-[24px] tracking-[0.14px] text-customBlack font-bold capitalize">
                  {challenge_timeout} Hours
                </p>
              </div>
              {/* end of an item */}
            </div>
            {/* RULES SESSION BEGINS */}
            <div className="2xl:w-10/12 w-11/12 mt-[30px] flex flex-col ">
              <h1 className="text-customBlack text-sm leading-[24px] tracking-[0.14px] font-bold">
                Ladder Rules
              </h1>
              <ul className="list-disc pl-5 ">
                <li className="mt-3">
                  <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                    All financial member of the club are eligible to
                    participate.
                  </p>
                </li>
                <li className="mt-3">
                  <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                    Players cannot challenge the same players challenged in the
                    previous week. This can only happen if any other person does
                    not challenge the player and will also attract a fee of
                    <span className="text-customBlack font-bold ml-1">
                      N10,000.
                    </span>
                  </p>
                </li>
                <li className="mt-3">
                  <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                    Failure to honour challenges four consecutive times can
                    result in removing a person’s name from the ladder board.
                  </p>
                </li>
                <li className="mt-3">
                  <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                    The tennis captain or the assistant captain will assign the
                    court and umpire for each game
                  </p>
                </li>
                <li className="mt-3">
                  <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                    Players have a right to direct complaints to the tennis
                    captain of the tournament director if they have any.
                  </p>
                </li>
                <li className="mt-3">
                  <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                    There will be strict disciplinary actions carried out if
                    players flout the rules of the game fo tennis, ranging from
                    using inappropriate language on an opponent, ball
                    boys/umpire to behave in a non-sportsman-like attitude. The
                    ladder committee headed by the tennis captain will review
                    actions and, if found guilty, will attract a fine of
                    <span className="text-customBlack font-bold ml-1">
                      N25,000.
                    </span>
                  </p>
                </li>
              </ul>
            </div>
            <div className="w-full mt-11 flex items-center pb-5 ">
              <span className="max-w-[300px] w-full mx-auto">
                <OutlinedBtn
                  name="Edit Match Details"
                  active
                  onClick={() => navigate(`/admin/edit-ladder/${id.id}`)}
                />
              </span>
            </div>
          </div>
        </div>
      )}

      <ReUseModal open={openDelete} setOpen={setOpenDelete}>
        <div className="w-full flex flex-col">
          <div className="flex items-center justify-center mt-10">
            <DeleteIcon />
          </div>
          <h1 className="text-center mt-2 font-bold text-[22px] leading-[24px] text-customBlack ">
            Delete Tournament
          </h1>
          <p className="text-[#868686] mt-1 text-sm leading-[24px] tracking-[0.14px] max-w-[340px] mx-auto">
            Are you sure you want to delete this tournament?
          </p>
          <div className="w-full flex items-center justify-center  gap-6 mt-16">
            <button
              className="w-full max-w-[174px] rounded-[16px] border border-[#9cd326] h-[52px] flex items-center cursor-pointer justify-center text-sm leading-[24px] tracking-[0.14px] font-semibold text-[#9CD326] "
              onClick={() => setOpenDelete(false)}
              disabled={ladder?.loading}
            >
              No Cancel
            </button>
            <button
              className="w-full max-w-[174px] rounded-[16px] bg-[#B00020] h-[52px] flex items-center cursor-pointer justify-center text-sm leading-[24px] tracking-[0.14px] font-semibold text-white border-none outline-none"
              onClick={handleDelete}
              disabled={ladder?.loading}
            >
              {ladder?.loading ? "loading..." : "Yes Delete"}
            </button>
          </div>
        </div>
      </ReUseModal>
      <ReUseModal open={openDeleteSuccess} setOpen={setOpenDeleteSuccess}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-customBlack space-y-1 text-[24px] font-bold text-center">
          Tournament Deleted
        </h1>
        <p className="text-center w-10/12 max-w-[331px] mx-auto text-neutral-500 text-sm leading-[24px] tracking-[0.14px]">
          You’ve successfully deleted this ladder tournament
        </p>
        <Btn
          active={true}
          name="Close"
          onClick={() => {
            dispatch(resetAllLadderState());
            setOpenDeleteSuccess(false);
            navigate("/admin/tournament");
          }}
          className="mt-[49px] mb-3"
        />
      </ReUseModal>
    </div>
  );
};

export default ViewLadderDetails;
