import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import underlay from "../../../assets/png/underlay.png";
import { LoginSvg } from "../../../assets/svgs";
import { Btn } from "../../../components/Button";
import { LoginInput, PasswordInput } from "../../../components/Input";
import { BigText, Ptext } from "../../../components/Text";
import { loginUser, restoreDefault } from "../../../features/auth/AuthSlice";
import { setCurrentUser } from "../../../features/auth/UtilitySlice";
const MainLogin = () => {
  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [active, setActive] = useState(false);

  useEffect(() => {
    dispatch(restoreDefault());
  }, []);

  useEffect(() => {
    if (email && password) setActive(true);
    else setActive(false);
  }, [email, password]);

  const handleSubmit = () => {
    const data = {
      email: email,
      password: password,
    };
    dispatch(loginUser(data));
  };

  useEffect(() => {
    if (auth.userData?.success) {
      dispatch(setCurrentUser(auth.userData));
      if (auth.userData)
        // toast.success("Login Successful");
        navigate("/home");
    } else navigate("/login");
  }, [auth.registerSuccess]);

  return (
    <>
      {/* <div className=" flex-col lg:flex hidden mt-6 px-4 justify-center h-screen w-1/2 mx-auto">
        <h1 className="font-bold text-customBlack text-xl text-center mb-2 ">
          Oops, this page is only designed for mobile devices.
        </h1>
        <p className="text-neutral-700 text-base  ">
          Please switch to a smaller screen, such as a smartphone or tablet, to
          enjoy this feature. We appreciate your cooperation.
        </p>
      </div> */}
      <div className="w-full h-screen relative bg-white overflow-y-hidden md:max-w-[428px] md:mx-auto md:h-screen">
        <div className="mt-[40px] flex flex-col px-8 " style={{ zIndex: 10 }}>
          <div className="flex justify-center w-full">
            <LoginSvg />
          </div>
          <BigText text="Welcome Back" className="text-center mt-16" />
          <Ptext
            text="Kindly enter your details to Login."
            className="text-center"
          />
          <div className="flex w-full mt-12 flex-col space-y-6 ">
            <LoginInput
              placeholder="Enter your Email"
              onChange={(e: any) => setEmail(e.target.value)}
              required
            />
            <PasswordInput
              placeholder="password"
              name="password"
              required
              onChange={(e: any) => setPassword(e.target.value)}
            />
            <div className="flex justify-end">
              <Link to="/forgot-password" className="text-[#ababab] mb-16">
                forgot password
              </Link>
            </div>
          </div>
          <Btn
            name="Login"
            active={active}
            loading={auth.loading}
            onClick={handleSubmit}
            className="z-5"
          />
          {/* <div className="mt-10">
          <GoogleLogin
          onSuccess={responseMessage}
          onError={() =>  errorMessage}
          />
        </div> */}

          <div className="flex items-center justify-center mt-4 z-5">
            <Ptext text="Dont have an account? " />
            <Link to="/users" className="text-[14px] text-primaryYellow ml-1">
              Sign Up Here
            </Link>
          </div>
        </div>

        <div className="absolute -bottom-12 w-full  " style={{ zIndex: 0 }}>
          <img
            src={underlay}
            alt="underlay"
            className="w-full h-auto mx-auto opacity-20 "
          />
        </div>
      </div>
    </>
  );
};

export default MainLogin;
