import React, { SetStateAction, useState } from "react";
import PrimarySelect from "../../Selects/PrimarySelect";
import { Player } from "../../../pages/admin/Competition/AssignPlayerPage";
import SearchSelect from "../../Selects/SearchSelect";
import TeamSelect from "../../Selects/TeamSelect";
interface SingleTeamPlayerProps {
  item: { name: string; _id: string };
  index: number;
  playersList: Player[];

  handlePlayerSelect: (teamId: string, player: Player) => void;
}
const SingleTeamPlayers: React.FC<SingleTeamPlayerProps> = ({
  item,
  index,
  playersList,
  handlePlayerSelect,
}) => {
  const [selectedPlayers, setSelectedPlayers] = useState<(Player | null)[]>([
    null,
    null,
  ]);

  const handleAddNewPlayerSelect = (): void => {
    setSelectedPlayers([...selectedPlayers, null]);
  };

  const handlePlayerChange = (newPlayer: Player, selectIndex: number): void => {
    const newSelectedPlayers = [...selectedPlayers];
    newSelectedPlayers[selectIndex] = newPlayer;
    setSelectedPlayers(newSelectedPlayers);
    handlePlayerSelect(item._id, newPlayer);
  };
  return (
    <div className="w-full flex flex-col  " key={item?._id}>
      <h1 className="text-neutral-700 font-semibold text-sm mb-4 ">
        <span>{index + 1}</span>:<span className=""> {item.name}</span>
      </h1>
      <div className="w-full flex max-w-[732px] flex-wrap justify-between gap-y-4">
        {selectedPlayers?.map((player, selectIndex) => (
          <div className="flex items-center gap-4">
            <p className="text-neutral-700 min-w-max text-sm">
              Player {selectIndex + 1} :{" "}
            </p>
            <div className="w-[230px]">
              <TeamSelect
                key={selectIndex}
                options={playersList}
                selected={player}
                setSelected={(newPlayer: Player) =>
                  handlePlayerChange(newPlayer as Player, selectIndex)
                }
              />
            </div>
          </div>
        ))}
      </div>
      <div className="flex">
        <button
          onClick={handleAddNewPlayerSelect}
          className="text-[#82B020] font-medium mt-6 text-sm"
        >
          Add New Player
        </button>
      </div>
    </div>
  );
};

export default SingleTeamPlayers;
