import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { restoreDefault } from "../../features/auth/AdminAuth";
const RedirectedHome = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(restoreDefault());
    window.location.replace("/admin/login");
  }, []);

  return <div></div>;
};

export default RedirectedHome;
