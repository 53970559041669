import { useSearchParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { useEffect, useState } from "react";
import {
  saveRedirectUrl,
  resetRedirect,
  resetJoinStatus,
  resetError,
} from "../../../features/auth/AuthSlice";
import { joinCompetition } from "../../../features/auth/AuthSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IoIosArrowBack } from "react-icons/io";
import { More } from "../../../assets/svgs/court";
import {
  Btn,
  OutlinedBtn,
  RedBtn,
  RedOutlinedBtn,
} from "../../../components/Button";
import Radio from "../../../components/CustomRadio";
import { CautionIcon } from "../../../assets/svgs/Icons";
import ReUseModal from "../../../components/Modal/ReUseModal";
import { getSingleCompetitionDetailUser } from "../../../features/auth/TournamentSlice";
import LoadingComponent from "../../../components/Loading/Loader";
import { formatDate } from "../../../utils";
const teamList = [
  { name: "Mercury Team", id: "1" },
  { name: "Venus Team", id: "2" },
  { name: "Earth Team", id: "3" },
  { name: "Mars Team", id: "4" },
  { name: "Jupiter Team", id: "5" },
  { name: "Saturn Team", id: "6" },
];

const JoinCompetition = () => {
  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.auth);
  const tournament = useAppSelector((state) => state.tournament);
  const dispatch = useAppDispatch();
  const [success, setSuccess] = useState(false);

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const teamBased = searchParams.get("team-based");
  const [openReject, setOpenReject] = useState(false);

  const handleJoin = () => {
    dispatch(joinCompetition({ id: id }));
  };

  useEffect(() => {
    if (auth?.joinError) {
      toast.warning(`You are already a player of this competition`);
    }
  }, [auth?.joinError]);

  useEffect(() => {
    if (auth?.token) {
      dispatch(getSingleCompetitionDetailUser({ id: id }));
    } else {
      dispatch(saveRedirectUrl(`/join?id=${id}`));
      toast.error("Kindly login or Register");
      setTimeout(() => {
        navigate("/users");
      }, 2000);
    }
  }, []);

  useEffect(() => {
    if (auth?.joinStatus && auth?.token) {
      setSuccess(true);
      dispatch(resetRedirect());
      toast.success("You have Joined the Competition Successfully");
      dispatch(resetJoinStatus());
      dispatch(resetError());
      setTimeout(() => {
        navigate("/competition");
      }, 2000);
    }
  }, [auth?.joinStatus]);
  const handleReject = () => {
    // navigate("/home");
    setOpenReject(true);
  };

  if (tournament?.fetchLoading) {
    return (
      <div className="w-full md:max-w-[428px] h-screen px-4 pt-5 mx-auto">
        <LoadingComponent />
      </div>
    );
  }
  return (
    <>
      <div className="w-full flex flex-col px-4 py-6 h-screen relative md:max-w-[428px] md:mx-auto md:h-screen">
        <div className="flex justify-between mt-6 items-center">
          <IoIosArrowBack
            className="text-2xl"
            onClick={() => navigate("/home")}
          />
          <p className="text-[#303030] font-bold text-[15px] ">
            Single Day Tournament Invitation
          </p>
          <div className="more-shadow opacity-0">
            <More />
          </div>
        </div>
        <div className="mt-8 flex flex-col ">
          <p className="text-sm text-neutral-600 leading-[24px]">
            You’ve been invited to{" "}
            <span className="text-customBlack font-bold capitalize">
              {tournament?.competitionDetailUser?.name} Single Day Competition
            </span>{" "}
            that will take place on{" "}
            <span className="text-customBlack font-bold">
              {" "}
              {formatDate(tournament?.competitionDetailUser?.start)}
            </span>
          </p>

          <p className="text-neutral-600 text-sm leading-[24px]">
            You will be assigned to a team shortly as soon as you have finished
            joining the competition.
          </p>
        </div>

        {/* end of team list */}
        {/* start of note session */}
        <div className="w-full bg-[#EBF6D480] rounded-[10px] bg-opacity-50 py-4 px-[10px] flex flex-col gap-[10px] mt-6">
          <p className="text-customBlack text-sm font-semibold ">Note:</p>

          <div className="flex gap-[10px] items-center ">
            <span className="w-[4px] h-[4px] bg-neutral-600 rounded-full"></span>
            <p className="text-neutral-600 text-sm leading-[24px]">
              You can only accept or reject this invite once
            </p>
          </div>
        </div>

        {/* end of note session */}
        {/* button */}
        <div className="flex flex-col absolute bottom-20 left-0 space-y-5 w-full px-4">
          <Btn name="Accept" onClick={handleJoin} active />
          <RedOutlinedBtn name="Reject" onClick={handleReject} active />
        </div>
      </div>

      <ReUseModal open={openReject} setOpen={setOpenReject}>
        <div className="w-full flex flex-col">
          <div className="flex items-center justify-center mt-10">
            <CautionIcon />
          </div>
          <h1 className="text-center mt-2 font-bold text-[22px] leading-[24px] text-customBlack ">
            Reject Competition
          </h1>
          <p className="text-[#868686] mt-1 text-sm text-center max-w-[340px] mx-auto">
            If you reject this competition you will not be able to participate
          </p>
          <div className="w-full flex items-center justify-center  gap-6 mt-16">
            <OutlinedBtn
              active
              onClick={() => setOpenReject(false)}
              name="No Cancel"
            />
            <RedBtn
              name="Yes Reject"
              onClick={() => setOpenReject(false)}
              active
            />
          </div>
        </div>
      </ReUseModal>
    </>
  );
};

export default JoinCompetition;
