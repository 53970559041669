import { useEffect, useState } from "react";
import DashboardLayout from "../../../Layouts/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import { NavigationArrow } from "../../../assets/svgs/admin";
import { AddCircleGreen } from "../../../assets/svgs";
import SingleSetSchedule from "../../../components/Others/competition/single-set-schedule";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  addCompetitionMatch,
  resetAll,
  startTeamCompetition,
} from "../../../features/auth/TournamentSlice";
import { Btn } from "../../../components/Button";
import ReUseModal from "../../../components/Modal/ReUseModal";
import sentMark from "../../../assets/svgs/checkmark.svg";
export interface Match {
  id: number;
  challenger: string;
  challenged: string;
  court: string;
  time: string;
  isDouble: boolean;
  challengerB?: string;
  challengedB?: string;
}

export interface MatchState {
  id: number;
  matchList: Match[];
}
const ScheduleCompetitionMatchPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tournament = useAppSelector((state) => state.tournament);
  const id = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [matchSets, setMatchSets] = useState<MatchState[]>([
    {
      id: 0,
      matchList: [
        {
          id: 0,
          challenger: "",
          challenged: "",
          court: "",
          time: "",
          isDouble: false,
        },
      ],
    },
  ]);
  const combinedMatches = matchSets.flatMap((grand) => grand.matchList);

  // Function to add a new set of MatchState

  const addNewSet = () => {
    const newId = matchSets.length;
    setMatchSets((prevSets) => [
      ...prevSets,
      {
        id: newId,
        matchList: [
          {
            id: 0,
            challenger: "",
            challenged: "",
            court: "",
            time: "",
            isDouble: false,
          },
        ],
      },
    ]);
  };

  const handleSchedule = () => {
    const data = {
      match: combinedMatches,
    };
    const sentData = {
      id: id.id,
      data: data,
    };
    dispatch(addCompetitionMatch(sentData));
  };
  useEffect(() => {
    if (
      tournament?.addCompetitionMatchSuccess &&
      tournament?.startCompetionSuccess === false &&
      tournament?.competitionDetailAdmin?.status === "pending"
    ) {
      dispatch(startTeamCompetition({ id: id.id }));
    }
    if (
      tournament?.addCompetitionMatchSuccess &&
      tournament?.startCompetionSuccess
    ) {
      setOpen(true);
      dispatch(resetAll());
      setTimeout(() => {
        setOpen(false);
        navigate("/admin/tournament");
      }, 3000);
    }
    if (
      tournament?.addCompetitionMatchSuccess &&
      tournament?.competitionDetailAdmin?.status === "ongoing"
    ) {
      setOpen(true);
      dispatch(resetAll());
      setTimeout(() => {
        setOpen(false);
        navigate("/admin/tournament");
      }, 3000);
    }
  }, [
    tournament?.addCompetitionMatchSuccess,
    tournament?.startCompetionSuccess,
  ]);

  return (
    <DashboardLayout current={3}>
      <div className="w-full flex flex-col bg-[#f4f4f4] h-full p-[30px] 2xl:p-8 2xl:pr-[50px] ">
        <div className="flex w-fit" onClick={() => navigate(-1)}>
          <NavigationArrow />
        </div>
        {/* CONTENT SESSION */}

        <div className="bg-white rounded-[20px] flex flex-col  h-screen  mt-6 flow-hide  ">
          <div className="flex-grow">
            <div className="">
              <div className="pl-5 pb-3 flex justify-between pt-[22px]">
                <p className=" text-sm text-customBlack font-semibold">
                  Schedule Matches
                </p>
              </div>
            </div>
            {/* breaker line */}
            <div className="w-full h-[1px] bg-[#cfcfcf] " />
            <div className="w-full">
              <div className=" px-5 pt-3 w-11/12">
                <div className="w-full flex items-center justify-between">
                  <p className="text-sm text-neutral-600">
                    Select the team and players to play against each other
                  </p>
                  <div
                    className="flex items-center gap-[10px] cursor-pointer"
                    onClick={addNewSet}
                  >
                    <span>
                      <AddCircleGreen />
                    </span>
                    <p className="text-secondary700 font-bold text-sm">
                      Add new set of team
                    </p>
                  </div>
                </div>
                <div className="w-full flex flex-col gap-[24px] mt-6">
                  {matchSets?.map((set, index) => (
                    <SingleSetSchedule
                      key={index}
                      matchSet={set}
                      setMatchSets={setMatchSets}
                    />
                  ))}
                </div>
              </div>
              <div className="w-full flex justify-center mt-8 mx-auto max-w-[300px] pb-10">
                <Btn
                  onClick={handleSchedule}
                  active={combinedMatches?.length > 0}
                  name={"Schedule Matches"}
                  loading={tournament?.loading}
                />
              </div>
            </div>
          </div>
        </div>
        {/* END OF CONTENT SESSION */}
        <ReUseModal open={open} setOpen={setOpen}>
          <img src={sentMark} alt="sent" className="mx-auto mt-6" />
          <h1 className="text-customBlack space-y-1 text-[24px] font-bold text-center">
            Matched Scheduled
          </h1>
          <p className="text-center w-10/12 max-w-[331px] mx-auto text-neutral-500 text-sm">
            You’ve successfully scheduled different matches
          </p>
          <Btn
            active={true}
            name="Continue"
            onClick={() => {
              dispatch(resetAll());
              setOpen(true);
              navigate("/admin/tournament");
            }}
            className="mt-[49px] mb-3"
          />
        </ReUseModal>
      </div>
    </DashboardLayout>
  );
};

export default ScheduleCompetitionMatchPage;
