import DashboardLayout from "../../../Layouts/DashboardLayout";
import ViewLadderDetails from "../../../components/Others/Ladder/ViewLadderDetails";

const ViewLadderDetailsPage = () => {
  return (
    <DashboardLayout current={2}>
      <ViewLadderDetails />
    </DashboardLayout>
  );
};

export default ViewLadderDetailsPage;
