const states = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];
const outputArray = [];

for (let i = 0; i < states.length; i++) {
  const obj = {
    name: states[i],
    id: i + 1,
  };
  outputArray.push(obj);
}

export const stateArray = outputArray;

export const isPowOf2 = (n) => {
  if (n === 0) return false;

  return (
    parseInt(Math.ceil(Math.log(n) / Math.log(2))) ===
    parseInt(Math.floor(Math.log(n) / Math.log(2)))
  ); // O(log n )
};

export function formatDateAndTimeSeprate(inputDateTime) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Parse the input date string to a Date object
  const dateObj = new Date(inputDateTime);

  // Get the day of the week (0-6)
  const dayOfWeek = days[dateObj.getUTCDay()];

  // Get the day of the month (1-31)
  const dayOfMonth = dateObj.getUTCDate();

  // Get the month (0-11)
  const month = months[dateObj.getUTCMonth()];

  // Get the year (4 digits)
  const year = dateObj.getUTCFullYear();

  // Get the hours (0-23) and minutes (0-59)
  const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();

  // Convert the hours to 12-hour format
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;

  // Format the date and time string
  const formattedDate = `${dayOfWeek}. ${dayOfMonth} ${month} ${year}`;
  const formattedTime = `${formattedHours}:${minutes
    .toString()
    .padStart(2, "0")} ${ampm}`;
  const realDate = {
    date: formattedDate,
    time: formattedTime,
  };

  return realDate;
}
