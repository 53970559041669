import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { getAllActiveplayer } from "../../../features/auth/TournamentSlice";
import { Btn } from "../../Button";

const ViewAllPlayer = ({ arrays, setNextPage }: any) => {
  const tournament = useAppSelector((state) => state.tournament);
  const dispatch = useAppDispatch();
  const numbers = useParams();
  let filterId = numbers.number;

  useEffect(() => {
    dispatch(getAllActiveplayer({ id: filterId }));
  }, []);
  const [searchParams] = useSearchParams();
  const currentRound = searchParams.get("round");
  let realRound = Number(currentRound);
  let playersArray = realRound
    ? tournament.allActivePlayers
    : tournament.allAcceptedPlayers;

  return (
    <div className="bg-white rounded-[20px] flex flex-col  mt-6 flow-hide relative ">
      <div className="h-[51px] ">
        <h1 className="pl-5 text-customBlack font-semibold text-sm mt-[22px] ">
          All Available Players
        </h1>
      </div>
      <div className="w-full h-[35px] flex items-center space-x-[43px] border border-border bg-[#f4f4f4] bg-opacity-30 pl-5">
        <p className="text-xs text-neutral-700">S/N</p>
        <p className="text-xs text-neutral-700">Names of Available Players</p>
      </div>
      <div className="w-full flex flex-col space-y-4">
        {playersArray?.map((item: any, index: number) => {
          return (
            <div
              className="w-full h-[35px] flex items-center space-x-[43px] pl-5 mt-6"
              key={index}
            >
              <p className="text-customBlack capitalize">{index + 1}</p>
              <div className="flex items-center space-x-2">
                <img
                  src={
                    item?.image
                      ? item?.image
                      : "https://img.freepik.com/premium-vector/avatar-profile-icon_188544-4755.jpg"
                  }
                  alt=""
                  className="w-[35x] h-[35px] rounded-full"
                />
                <p className="text-md font-[500] capitalize">
                  {item?.lastname
                    ? item.firstname + " " + item.lastname
                    : item.firstname}{" "}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-10 flex justify-center pb-10">
        <div className="w-3/12 flex justify-center">
          <Btn
            name="Proceed"
            active={true}
            onClick={() => setNextPage(false)}
            className="mx-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default ViewAllPlayer;
