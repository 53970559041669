import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../Layouts/DashboardLayout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ClubAdminOverview } from "../../components/Dashboard/DashboardOverview";
import InvitationRequestTable from "../../components/Tables/AdminTables/InvitationRequestTable";
import LadderBoardTable from "../../components/Tables/AdminTables/LadderBoardTable";
import PlayerRankingTables from "../../components/Tables/AdminTables/PlayerRankingTables";
import ReservedCourtTable from "../../components/Tables/AdminTables/ReservedCourtTable";
import TournamentBoardTable from "../../components/Tables/AdminTables/TournamentBoardTable";
import {
  getAllClubMembers,
  getAllPendingMember,
  getClubDetails,
} from "../../features/auth/AdminAuth";
import {
  getAllCourt,
  getAllTournaments,
} from "../../features/auth/TournamentSlice";
import { filterByState } from "../../utils";

const AdminHome = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const adminAuth = useAppSelector((state) => state.adminAuth);
  const tournament = useAppSelector((state) => state.tournament);

  useEffect(() => {
    if (adminAuth.userData?.user?._club === null || undefined) {
      navigate("/admin/createclub");
    }
  }, []);
  useEffect(() => {
    if (adminAuth.userData?.length !== 0) {
      dispatch(getAllCourt());

      dispatch(getAllTournaments());
      if (
        (adminAuth?.userData?.length !== 0 &&
          adminAuth.userData?.user?._club !== null) ||
        undefined
      ) {
        dispatch(getAllClubMembers());
        dispatch(getAllPendingMember());
        dispatch(getClubDetails());
      }
    } else {
      navigate("/admin/login");
    }
  }, [adminAuth.userData]);

  let membersArray = adminAuth?.allClubMembers;

  let memberState = "pending";

  const pendingArray = filterByState(membersArray, memberState);

  let allCourts = tournament?.allCourts;

  return (
    <DashboardLayout current={1}>
      <div className="w-full flex flex-col bg-[#f4f4f4] p-[30px] 2xl:p-8 ">
        <div className="w-full flex">
          <h1 className="text-lg font-[600] text-customBlack">Dashboard</h1>
          {/* <QuickActions /> */}
        </div>
        {/* GENERAL SPLITER */}
        <div className="flex space-x-10 mt-6">
          {/* START OF LEFT @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@22 */}
          <div className="w-8/12 flex flex-col">
            <ClubAdminOverview
              usertNo={membersArray?.length}
              courtNo={allCourts?.length}
            />
            <TournamentBoardTable className="mt-5" />
            <ReservedCourtTable className="mt-5" />
          </div>
          {/* END OF LEFT  ########################################################33*/}
          {/* START OF RIGHT @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@22 */}
          <div className="w-4/12 flex flex-col space-y-6">
            <PlayerRankingTables />
            <LadderBoardTable />
            <InvitationRequestTable pendingArray={pendingArray} />
          </div>
          {/* END OF RIGHT  ########################################################33*/}

          {/* END OF GENERAL SPLITER */}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AdminHome;
