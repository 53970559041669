import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdminAuthLayout from "../../../Layouts/AdminAuthLayout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import logo from "../../../assets/svgs/love-logo.svg";
import { Btn } from "../../../components/Button";
import {
  AdminCalenderInput,
  AdminInput,
  AdminPasswordInput,
} from "../../../components/Input";
import { AuthSubTitle, AuthTitle } from "../../../components/Text";
import {
  adminRegister,
  restoreDefault,
} from "../../../features/auth/AdminAuth";
import { AdminDateInput } from "../../../components/Input/DateInput";

const AdminRegister = () => {
  const adminAuth = useAppSelector((state) => state.adminAuth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [, setSuccessful] = useState(false);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [date, setDate] = useState("");

  const [active, setActive] = useState(false);
  const [value] = useState<any>();
  useEffect(() => {
    dispatch(restoreDefault());
  }, []);
  useEffect(() => {
    if (
      email &&
      password &&
      date &&
      firstName &&
      lastName &&
      date &&
      confirmPassword
    ) {
      setActive(true);
    } else setActive(false);
  }, [email, password, date, firstName, lastName, date, confirmPassword]);
  function convertDate(dateString: string | number | Date) {
    let date = new Date(dateString);
    return date.getTime();
  }
  const handleSubmit = () => {
    const data = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      password: password,
      image: "https://cdn-icons-png.flaticon.com/512/1077/1077114.png",
      thumbnail: "https://cdn-icons-png.flaticon.com/512/1077/1077114.png",
      birth: convertDate(date),
      club: value ? value?.id : "6437585e77a608e3be83c774",
    };
    if (password === confirmPassword) {
      dispatch(adminRegister(data));
    } else toast.error("Password does not match");
  };

  useEffect(() => {
    if (adminAuth.userData?.success) {
      toast.success("Registration Successful");
      setSuccessful(true);
      setTimeout(() => {
        navigate("/admin/email-verification-otp/s");
      }, 500);
    }
  }, [adminAuth.registerSuccess]);

  return (
    <>
      <div className="w-full ">
        <AdminAuthLayout>
          <div className="w-full flex h-full justify-center ">
            {/* left side  */}
            <div className="w-1/2 bg-white flex flex-col h-screen flow-hide pb-20">
              <div className="mx-auto mt-8">
                <img src={logo} alt="logo" className="h-auto" />
              </div>
              <div className="flex flex-col justify-center items-center mt-8">
                <AuthTitle text="Create Account" className="text-center" />
                <AuthSubTitle
                  text="Fill in the details below to create an account"
                  className="mt-[10px]"
                />
                <div className="flex flex-col space-y-4 lg:w-8/12 mx-auto xl:w-7/12 2xl:w-7/12 mt-10">
                  <div className="flex gap-8">
                    <AdminInput
                      placeholder="E.g Daniel"
                      required={true}
                      onChange={(e: any) => setFirstName(e.target.value)}
                      label="First Name"
                    />
                    <AdminInput
                      placeholder="E.g Samuel"
                      onChange={(e: any) => setLastName(e.target.value)}
                      label="Last Name"
                      required={true}
                    />
                  </div>
                  <AdminInput
                    placeholder="example@gmail.com"
                    onChange={(e: any) => setEmail(e.target.value)}
                    label="Email Address"
                    required={true}
                  />
                  <div className="flex flex-col mt-4">
                    <label className="text-neutral-400 text-sm  ">
                      Date of Birth
                    </label>
                    {/* <AdminCalenderInput
                      placeholder="Enter your password"
                      classes="mt-[6px]"
                      required={true}
                      onChange={(e: any) => setDate(e.target.value)}
                    /> */}
                    <AdminDateInput
                      className="mt-[6px] border border-border"
                      height="h-[50px]"
                      date={date}
                      setDate={setDate}
                    />
                  </div>
                  <div className="flex flex-col mt-4">
                    <label className="text-neutral-400 text-sm mb-1 ">
                      Password
                    </label>
                    <AdminPasswordInput
                      required={true}
                      placeholder="Enter your password"
                      classes=""
                      onChange={(e: any) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col mt-4">
                    <label className="text-neutral-400 text-sm mb-1 ">
                      Confirm Password
                    </label>
                    <AdminPasswordInput
                      placeholder="Confirm your password"
                      required={true}
                      classes=""
                      onChange={(e: any) => setConfirmPassword(e.target.value)}
                    />
                  </div>

                  {/* end of remeber me */}
                  {/* button session */}
                  {/* <div className="h-[0.5px] w-full bg-[#cfcfcf] mt-6 mb-4" /> */}
                  {/* end of button session */}
                </div>
                <div className=" lg:w-8/12 mx-auto xl:w-7/12 2xl:w-7/12">
                  <Btn
                    name="Create"
                    className="w-full mt-12"
                    onClick={handleSubmit}
                    active={active}
                    loading={adminAuth.loading}
                  />
                </div>
              </div>
            </div>
            {/* end ofleft side  */}
            {/* right side  */}
            {/* <div className={`w-1/2 ${stages[current]} relative`}>
              <div className="absolute bottom-28 w-full flex justify-center left-0 right-0 px-16">
                <GlassProgress onClick={handleNext} current={current} />
              </div>
            </div> */}
            {/* end of the right hand side */}
          </div>
        </AdminAuthLayout>
      </div>
    </>
  );
};
export default AdminRegister;
