import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { Logo } from "../../assets/svgs";
import chart from "../../assets/svgs/chart.svg";
import chart2 from "../../assets/svgs/chart2.svg";
import logOut from "../../assets/svgs/logout.svg";
import personalcard from "../../assets/svgs/personalcard.svg";
import personalcard2 from "../../assets/svgs/personalcard2.svg";
import setting from "../../assets/svgs/setting.svg";
import setting2 from "../../assets/svgs/setting2.svg";
import shop from "../../assets/svgs/shop.svg";
import shop2 from "../../assets/svgs/shop2.svg";
import tennisBall from "../../assets/svgs/tennisBall.svg";
import tennisBall2 from "../../assets/svgs/tennisBall2.svg";
import user from "../../assets/svgs/user.svg";
import user2 from "../../assets/svgs/user2.svg";
import useHover from "../../components/IconComponent/useHover";
import { restoreDefault } from "../../features/auth/AdminAuth";

const styles = {
  active:
    "bg-[#EBF6D4] h-[42px] py-3 px-5 gap-[10px] border-r-[4px] rounded-l-[5px] border-r-[#9CD326] flex items-center text-[#82B020] text-sm  cursor-pointer ",
  inactive:
    "flex items-center hover:bg-[#EBF6D4] py-3 px-5 gap-[10px] text-sm text-[#868686] rounded-l-[5px] hover:text-[#82B020] cursor-pointer hover:border-r-[#9CD326] hover:border-r-[4px] ",
};
interface sidebarProps {
  current: number;
}
const Sidebar = ({ current }: sidebarProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(restoreDefault());
    window.location.replace("/admin/login");
  };

  const handleSettings = () => {
    navigate("/admin/settings");
  };

  const {
    isHovered: isHovered1,
    onMouseEnter: handleMouseEnter1,
    onMouseLeave: handleMouseLeave1,
  } = useHover();
  const {
    isHovered: isHovered2,
    onMouseEnter: handleMouseEnter2,
    onMouseLeave: handleMouseLeave2,
  } = useHover();
  const {
    isHovered: isHovered3,
    onMouseEnter: handleMouseEnter3,
    onMouseLeave: handleMouseLeave3,
  } = useHover();
  const {
    isHovered: isHovered4,
    onMouseEnter: handleMouseEnter4,
    onMouseLeave: handleMouseLeave4,
  } = useHover();
  const {
    isHovered: isHovered5,
    onMouseEnter: handleMouseEnter5,
    onMouseLeave: handleMouseLeave5,
  } = useHover();
  const {
    isHovered: isHovered6,
    onMouseEnter: handleMouseEnter6,
    onMouseLeave: handleMouseLeave6,
  } = useHover();
  const {
    isHovered: isHovered7,
    onMouseEnter: handleMouseEnter7,
    onMouseLeave: handleMouseLeave7,
  } = useHover();
  const {
    isHovered: isHovered8,
    onMouseEnter: handleMouseEnter8,
    onMouseLeave: handleMouseLeave8,
  } = useHover();

  const [toggle1, setToggle1] = useState(true);
  const [toggle2, setToggle2] = useState(true);
  const [toggle3, setToggle3] = useState(true);
  const [toggle4, setToggle4] = useState(true);
  const [toggle5, setToggle5] = useState(true);
  const [toggle6, setToggle6] = useState(true);
  const [toggle7, setToggle7] = useState(true);
  const [toggle8, setToggle8] = useState(true);

  const handleToggle1 = () => {
    setToggle1(!toggle1);
  };

  const handleToggle2 = () => {
    setToggle2(!toggle2);
  };

  const handleToggle3 = () => {
    setToggle3(!toggle3);
  };

  const handleToggle4 = () => {
    setToggle4(!toggle4);
  };

  const handleToggle5 = () => {
    setToggle5(!toggle5);
  };

  const handleToggle6 = () => {
    setToggle6(!toggle6);
  };

  const handleToggle7 = () => {
    setToggle7(!toggle7);
  };

  const handleToggle8 = () => {
    setToggle8(!toggle8);
  };

  return (
    <div className="w-full fixed top-0 left-0 bottom-0 h-screen flex flex-col max-w-[250px]  border-r bg-white flow-hide  ">
      <div className="w-full flex justify-center mt-12">
        <Logo width="100" height="73" />
      </div>
      <div className="flex flex-col pl-4">
        <p className="text-[#082431] text-xs opacity-50 my-6 pl-6">MENU</p>
        <div className="flex flex-col space-y-1">
          {/* start of a single item */}
          <div
            className={current === 1 ? styles.active : styles.inactive}
            onMouseEnter={handleMouseEnter1}
            onMouseLeave={handleMouseLeave1}
            onClick={() => {
              navigate("/admin/home");
              handleToggle1();
            }}
          >
            {toggle1 && current !== 1 ? (
              isHovered1 ? (
                <img alt="" src={chart2} />
              ) : (
                <img alt="" src={chart} className="text-lg" />
              )
            ) : (
              <img alt="" src={chart2} className="text-lg" />
            )}
            <p>Dashboard</p>
          </div>
          {/* end of a single link item */}
          <div
            className={current === 2 ? styles.active : styles.inactive}
            onMouseEnter={handleMouseEnter2}
            onMouseLeave={handleMouseLeave2}
            onClick={() => {
              navigate("/admin/matches");
              handleToggle2();
            }}
          >
            {toggle2 && current !== 2 ? (
              isHovered2 ? (
                <img alt="" src={tennisBall} />
              ) : (
                <img alt="" src={tennisBall2} className="text-lg" />
              )
            ) : (
              <img alt="" src={tennisBall} className="text-lg" />
            )}
            <p>Matches</p>
          </div>

          {/* Single link item */}
          <div
            className={current === 3 ? styles.active : styles.inactive}
            onMouseEnter={handleMouseEnter3}
            onMouseLeave={handleMouseLeave3}
            onClick={() => {
              navigate("/admin/tournament");
              handleToggle3();
            }}
          >
            {toggle3 && current !== 3 ? (
              isHovered3 ? (
                <img alt="" src={tennisBall} />
              ) : (
                <img alt="" src={tennisBall2} className="text-lg" />
              )
            ) : (
              <img alt="" src={tennisBall} className="text-lg" />
            )}
            <p>Tournament</p>
          </div>
          {/* End of single link item */}

          {/* Single link item */}
          <div
            className={current === 4 ? styles.active : styles.inactive}
            onMouseEnter={handleMouseEnter4}
            onMouseLeave={handleMouseLeave4}
            onClick={() => {
              navigate("/admin/courts");
              handleToggle4();
            }}
          >
            {toggle4 && current !== 4 ? (
              isHovered4 ? (
                <img alt="" src={shop2} />
              ) : (
                <img alt="" src={shop} className="text-lg" />
              )
            ) : (
              <img alt="" src={shop2} className="text-lg" />
            )}
            <p>Court</p>
          </div>
          {/* End of single link item */}

          {/* Single link item */}
          <div
            className={current === 5 ? styles.active : styles.inactive}
            onMouseEnter={handleMouseEnter5}
            onMouseLeave={handleMouseLeave5}
            onClick={() => {
              navigate("/admin/club");
              handleToggle5();
            }}
          >
            {toggle5 && current !== 5 ? (
              isHovered5 ? (
                <img alt="" src={personalcard} />
              ) : (
                <img alt="" src={personalcard2} className="text-lg" />
              )
            ) : (
              <img alt="" src={personalcard} className="text-lg" />
            )}
            <p>Club</p>
          </div>
          {/* End of single link item */}
          {/* END OF THE UPPER SESSION OF THE SIDE BAR ############################################################################# */}
          <div className="flex flex-col pl-4 mt-12">
            <p className="text-[#082431] text-xs opacity-50 my-6 pl-6">
              OTHERS
            </p>
            <div className="flex flex-col space-y-1">
              {/* start of a single item */}
              <div
                className={current === 6 ? styles.active : styles.inactive}
                onMouseEnter={handleMouseEnter6}
                onMouseLeave={handleMouseLeave6}
                onClick={() => {
                  navigate("/admin/settings");
                  handleSettings();
                  handleToggle6();
                }}
              >
                {toggle6 && current !== 6 ? (
                  isHovered6 ? (
                    <img alt="" src={setting} />
                  ) : (
                    <img alt="" src={setting2} className="text-lg" />
                  )
                ) : (
                  <img alt="" src={setting} className="text-lg" />
                )}

                <p>Settings</p>
              </div>
              {/* end of a single link item */}
              {/* start of a single item */}
              {/* <div className={current === 7 ? styles.active : styles.inactive}>
                <FiCreditCard className="text-lg" />
                <p>Payment</p>
              </div> */}
              {/* end of a single link item */}
              {/* start of a single item */}
              <div
                className={current === 8 ? styles.active : styles.inactive}
                onMouseEnter={handleMouseEnter7}
                onMouseLeave={handleMouseLeave7}
                onClick={() => {
                  // navigate("/admin/account");
                  handleToggle7();
                }}
              >
                {toggle7 && current !== 7 ? (
                  isHovered7 ? (
                    <img alt="" src={user} />
                  ) : (
                    <img alt="" src={user2} className="text-lg" />
                  )
                ) : (
                  <img alt="" src={user} className="text-lg" />
                )}
                <p>Account</p>
              </div>
              {/* end of a single link item */}
              {/* start of a single item */}
              {/* <div className={current === 9 ? styles.active : styles.inactive}>
                <AiOutlineExclamationCircle className="text-lg" />
                <p>Help</p>
              </div> */}
              {/* end of a single link item */}
              {/* start of a single item */}
              <div
                className={current === 10 ? styles.active : styles.inactive}
                onMouseEnter={handleMouseEnter8}
                onMouseLeave={handleMouseLeave8}
                onClick={() => {
                  handleLogout();
                  handleToggle8();
                }}
              >
                {toggle8 && current !== 8 ? (
                  isHovered8 ? (
                    <img alt="" src={logOut} />
                  ) : (
                    <img alt="" src={logOut} className="text-lg" />
                  )
                ) : (
                  <img alt="" src={logOut} className="text-lg" />
                )}
                <p>Logout</p>
              </div>
              {/* end of a single link item */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
