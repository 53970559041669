import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardLayout from "../../Layouts/DashboardLayout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { NavigationArrow } from "../../assets/svgs/admin";
import copy from "../../assets/svgs/copy.svg";
import sentMark from "../../assets/svgs/checkmark.svg";
import { Btn } from "../../components/Button";
import { AdminInput } from "../../components/Input";
import ReUseModal from "../../components/Modal/ReUseModal";

import {
  generateNewInvitationCode,
  getInvitationCode,
  resetInvitation,
  sendInvitationCode,
} from "../../features/auth/AdminAuth";

const SendInvitation = () => {
  const dispatch = useAppDispatch();
  const adminAuth = useAppSelector((state) => state.adminAuth);

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [active, setActive] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    if (email || phone) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [email, phone]);

  useEffect(() => {
    dispatch(getInvitationCode());
  }, []);

  useEffect(() => {
    if (adminAuth.invitationCodeSuccess) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [adminAuth.invitationCodeSuccess]);

  const handleSendInvitation = () => {
    const data = {
      value: email || phone,
      type: "mail",
    };
    dispatch(sendInvitationCode(data));
  };

  const generate = () => {
    dispatch(generateNewInvitationCode());
  };

  const copyClipboard = () => {
    navigator.clipboard.writeText(adminAuth.invitationCode);
    toast.success("Copied to Clipboard");
  };
  return (
    <DashboardLayout current={1}>
      <div className="w-full flex flex-col bg-[#f4f4f4] h-full p-[30px] 2xl:p-8 2xl:pr-[50px] ">
        <div className="flex w-fit" onClick={() => navigate(-1)}>
          <NavigationArrow />
        </div>
        <div className="bg-white rounded-[20px] flex flex-col h-full mt-6 ">
          <div className="h-[51px] ">
            <h1 className="pl-5 text-customBlack text-sm mt-[22px] ">
              Send Invitation
            </h1>
          </div>
          {/* breaker line */}
          <div className="w-full h-[1px] bg-[#cfcfcf] " />
          {/* form session */}
          <div className="flex">
            {/* left session */}
            <div className="w-1/2 border-r-[#cfcfcf] border-r h-full min-h-[500px]">
              <p className="mt-3 text-neutral-600 pl-5">
                Fill in the details below to send invite to members
              </p>
              <div className="w-9/12 mt-[30px] flex flex-col pl-5">
                <AdminInput
                  placeholder="Enter your Email"
                  onChange={(e: any) => setEmail(e.target.value)}
                  label="Email Address"
                  type="email"
                  required
                />
                <p className="text-center my-4 text-neutral-600 text-sm">
                  -or-
                </p>

                <div className={`flex w-full flex-col `}>
                  <label className="text-neutral-400 text-sm mb-1 ">
                    Phone
                  </label>
                  <div className="flex space-x-4  ">
                    <div className="border border-border rounded-[5px]  h-[50px] bg-white w-3/12 ">
                      <input
                        type="tel"
                        name="email"
                        onChange={(e: any) => setEmail(e.target.value)}
                        required
                        id="email"
                        className="focus:ring-[#C6C6C6] focus:border-none outline-none  block w-full pl-4 sm:text-sm border-none bg-inherit rounded-md h-[48px]"
                        placeholder="+234"
                      />
                    </div>
                    <div className="border border-border rounded-[5px] w-10/12 h-[50px] bg-white ">
                      <input
                        type="email"
                        name="email"
                        onChange={(e: any) => setPhone(e.target.value)}
                        required
                        id="email"
                        className="focus:ring-[#C6C6C6] focus:border-none outline-none  block w-full pl-4 sm:text-sm border-none bg-inherit rounded-md h-[48px] "
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <Btn
                  active={active}
                  onClick={handleSendInvitation}
                  name="Send"
                  className="mt-12 font-semibold"
                  loading={adminAuth.loading}
                />
              </div>
            </div>
            {/* right session */}
            <div className="flex flex-col w-1/2">
              <p className="mt-12 pl-5">Generate Invitation Code</p>
              <div className="flex ml-5 h-[50px] items-center justify-between w-9/12 2xl:w-7/12 border border-border rounded-[10px] mt-3">
                <div className="w-7/12  flex items-center justify-between">
                  <p className=" text-customBlack text-sm px-4 font-[500]">
                    {adminAuth?.invitationCode}
                  </p>
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={copyClipboard}
                  >
                    <p className="text-[#197C34] text-xs">Copy</p>
                    <img src={copy} alt="" />
                  </div>
                </div>
                <div
                  className="w-4/12 bg-lemonGreen h-[50px] items-center rounded-[10px] flex justify-center cursor-pointer text-sm font-semibold text-white"
                  onClick={generate}
                >
                  Generate
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReUseModal open={openModal} setOpen={setOpenModal}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-cutomBlack text-[24px] font-bold text-center">
          Invitation Sent
        </h1>
        <p className="text-center w-10/12 mx-auto text-neutral-500 ">
          {`Invitation Sent Successful to ${email ? email : phone}`}
        </p>
        <Btn
          active={true}
          name="Continue"
          onClick={() => {
            setOpenModal(false);
            dispatch(resetInvitation());
          }}
          className="mt-[25px] mb-3"
        />
      </ReUseModal>
    </DashboardLayout>
  );
};

export default SendInvitation;
