import { useState, useEffect } from "react";
import DashboardLayout from "../../../Layouts/DashboardLayout";
import { NavigationArrow } from "../../../assets/svgs/admin";
import { useNavigate } from "react-router-dom";
import { Btn } from "../../../components/Button";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { createCompetition } from "../../../features/auth/TournamentSlice";
import { toast } from "react-toastify";
import { resetComptition } from "../../../features/auth/TournamentSlice";
import { AdminDateInput } from "../../../components/Input/DateInput";

const CreateCompetion = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tournament = useAppSelector((state) => state.tournament);
  useEffect(() => {
    dispatch(resetComptition());
  }, []);

  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [matchesNo, setMatchesNo] = useState("");
  const [active, setActive] = useState(false);
  const handleNext = () => {
    const data = {
      name: name,
      start: startDate,
      stop: startDate,
    };
    dispatch(createCompetition(data));
  };

  useEffect(() => {
    if (startDate && name && matchesNo) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [name, startDate, matchesNo]);

  useEffect(() => {
    if (tournament.createCompetionSuccess && tournament.createCompetitionData) {
      toast.success("Competition created successfully");
      dispatch(resetComptition());
      setTimeout(
        () =>
          navigate(
            `/admin/select-players/competition/${tournament.createCompetitionData?._id}`
          ),
        2000
      );
    }
  }, [tournament.createCompetionSuccess]);

  return (
    <DashboardLayout current={2}>
      <div className="w-full flex flex-col bg-[#f4f4f4] h-full p-[30px] 2xl:p-8 2xl:pr-[50px]">
        <div className="flex w-fit" onClick={() => navigate(-1)}>
          <NavigationArrow />
        </div>
        <div className="bg-white rounded-[20px] flex flex-col lg:min-h-screen 2xl:min-h-[90vh]  mt-6 relative">
          <div className="h-[51px] ">
            <h1 className="pl-5 text-customBlack font-semibold text-sm mt-[22px] ">
              Create Competition
            </h1>
          </div>
          {/* breaker line */}
          <div className="w-full h-[1px] bg-[#cfcfcf] " />
          {/* form session */}
          <div className="w-full  ">
            <p className="mt-3 text-neutral-600 pl-5">
              FIll in the details below to create a competition
            </p>
          </div>
          <div className="flex   mt-8  items-center pl-5">
            <p className="text-neutral-600 w-[200px]">Name of Tournament:</p>

            <input
              type="text"
              className="outline-none h-[30px] rounded-[5px] border border-neutral-400 w-[230px] px-2"
              value={name}
              onChange={(e: any) => {
                if (e.target.value.length <= 30) {
                  setName(e.target.value);
                }
                setName(e.target.value.substring(0, 30));
              }}
            />
          </div>
          <div className="flex   mt-5  items-center pl-5">
            <p className="text-neutral-600 w-[200px]">Competition Date:</p>

            <AdminDateInput
              date={startDate}
              setDate={setStartDate}
              className="h-[30px] w-[230px] min-w-[230px] max-w-[230px]"
            />
          </div>
          <div className="flex   mt-5  items-center pl-5">
            <p className="text-neutral-600 w-[200px]">Number of Matches:</p>

            <input
              type="number"
              className="outline-none h-[30px] rounded-[5px] border border-neutral-400 w-[230px] px-2"
              onChange={(e: any) => {
                setMatchesNo(e.target.value);
              }}
            />
          </div>
          <div className="w-3/12 mx-auto absolute left-0 right-0 bottom-8">
            <Btn
              name="Create Competition"
              active={active}
              onClick={handleNext}
              loading={tournament.loading}
              className=""
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CreateCompetion;
