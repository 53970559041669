import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { formatDate, sortByDateField } from "../../utils";
import { invitationsMockData } from "../../utils/sampData";
import { useEffect, useState } from "react";
import { getInvitationsUser } from "../../features/auth/ladder-slice";
import {
  getAllReceivedInvitation,
  getSentInvitation,
} from "../../features/auth/playerslice";
import { ClubBall } from "../../assets/svgs/club";
import LoadingComponent from "../../components/Loading/Loader";
const styles = {
  active:
    "bg-white text-sm text-customBlack font-[600] full flex items-center justify-center cursor-pointer h-[38px] active-t-btn  ",
  inactive:
    "text-sm text-[#afafaf] font-semibold flex items-center justify-center w-full  h-[42px]",
};

interface FormattedDateTime {
  date: string;
  time: string;
}
export function formatDateTime(inputDate: string): FormattedDateTime {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
  };
  const date: Date = new Date(inputDate);

  const dayMonthFormat: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
  };
  const formattedDate: string = date
    .toLocaleDateString("en-US", dayMonthFormat)
    .toUpperCase();

  const formattedTime: string = `${date.getHours()}:${
    date.getMinutes() < 10 ? "0" : ""
  }${date.getMinutes()}`;

  return { date: formattedDate, time: formattedTime };
}
const MatchInvite = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userSlice = useAppSelector((state) => state.auth);
  const player = useAppSelector((state) => state.player);
  const [sentInvites, setSentInvite] = useState([]);
  const [receivedInvite, setReceivedInvite] = useState([]);
  const [current, setCurrent] = useState("sent");
  useEffect(() => {
    dispatch(getSentInvitation());
    dispatch(getAllReceivedInvitation());
  }, []);

  useEffect(() => {
    setReceivedInvite(player?.allReceivedInvitation);
    setSentInvite(player?.allSentInvitation);
  }, [player.allSentInvitation, player.allReceivedInvitation]);

  return (
    <div className="w-full flex flex-col px-4 py-6 md:max-w-[428px] md:mx-auto md:h-screen">
      <div className="flex justify-between mt-6 items-center">
        <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
        <p className="text-[#303030] font-bold text-[15px] ">My Matches</p>
        <div className="more-shadow opacity-0"></div>
      </div>
      <div className="flex flex-row w-full tounament-header-bg  h-[47px] items-center px-1 mt-[30px] mb-10 ">
        <div className="w-1/2" onClick={() => setCurrent("sent")}>
          <p className={current === "sent" ? styles.active : styles.inactive}>
            Sent
          </p>
        </div>
        <div className="w-1/2 " onClick={() => setCurrent("received")}>
          <p
            className={
              current === "received" ? styles.active : ` ${styles.inactive}   `
            }
          >
            Received
          </p>
        </div>
      </div>

      <div className="w-full flex flex-col">
        {current === "sent" && (
          <>
            {player?.fetchLoading ? (
              <div className="w-full mt-8 px-4">
                <LoadingComponent />
              </div>
            ) : (
              <div className="w-full">
                {sentInvites?.length === 0 && (
                  <div className="w-full mt-8">
                    <p className="text-neutral-600  text-base text-center font-medium">
                      You don't have any sent invitation
                    </p>
                  </div>
                )}
                {sentInvites?.length !== 0 && (
                  <div className="w-full flex flex-col gap-4">
                    {sentInvites?.map((item: any, index: number) => {
                      return (
                        <div
                          className="w-full h-20 border-border border rounded-[16px] flex items-center justify-between px-4 cursor-pointer gap-4 "
                          key={index}
                        >
                          <div className="flex items-center space-x-3">
                            <ClubBall />
                            <div className="flex flex-col">
                              <p className="text-sm text-customBlack p-0 m-0 font-normal ">
                                You've sent a match
                              </p>
                              <p className="text-sm text-customBlack ">
                                invitation to{" "}
                                <span className="font-bold capitalize">
                                  {item?.challenged?.firstname +
                                    " " +
                                    item?.challenged?.lastname}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center">
                            {item?.invitation === "accepted" ? (
                              <p
                                className="text-[#197C34] underline-offset-6 min-w-max underline italic text-sm cursor-pointer"
                                onClick={() =>
                                  navigate(
                                    `/view-match-score/sent/${item?._id}`
                                  )
                                }
                              >
                                View Match
                              </p>
                            ) : (
                              <p
                                className="text-[#197C34] underline-offset-6 min-w-max underline italic text-sm cursor-pointer"
                                onClick={() =>
                                  navigate(
                                    `/view-match-detail/sent/${item?._id}`
                                  )
                                }
                              >
                                View Details
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {current === "received" && (
          <>
            {player?.fetchLoading ? (
              <div className="w-full mt-8 px-4">
                <LoadingComponent />
              </div>
            ) : (
              <div>
                {receivedInvite?.length === 0 && (
                  <div className="w-full mt-8">
                    <p className="text-neutral-600  text-base font-medium text-center">
                      You don't have any sent invitation
                    </p>
                  </div>
                )}
                {receivedInvite?.length !== 0 && (
                  <div className="w-full flex flex-col gap-4">
                    {receivedInvite?.map((item: any, index: number) => {
                      return (
                        <div
                          className="w-full h-20 border-border border rounded-[16px] flex items-center justify-between px-4 cursor-pointer gap-4 "
                          key={index}
                        >
                          <div className="flex items-center space-x-3">
                            <ClubBall />
                            <div className="flex flex-col">
                              <p className="text-sm text-customBlack p-0 m-0 font-normal ">
                                You've been invited to
                              </p>
                              <p className="text-sm text-customBlack ">
                                play with{" "}
                                <span className="font-bold capitalize">
                                  {item?.challenger?.firstname +
                                    " " +
                                    item?.challenger?.lastname}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center">
                            {item?.invitation === "accepted" ? (
                              <p
                                className="text-[#197C34] underline-offset-6 min-w-max underline italic text-sm cursor-pointer"
                                onClick={() =>
                                  navigate(
                                    `/view-match-score/received/${item?._id}`
                                  )
                                }
                              >
                                View Match
                              </p>
                            ) : (
                              <p
                                className="text-[#197C34] underline-offset-6 min-w-max underline italic text-sm cursor-pointer"
                                onClick={() =>
                                  navigate(
                                    `/view-match-detail/received/${item?._id}`
                                  )
                                }
                              >
                                View Details
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MatchInvite;
