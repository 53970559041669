import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useAppSelector } from "../../app/hooks";
import samp from "../../assets/png/samp-p-one.png";
import { capitalizeWords, convertDateFormat, filterByState } from "../../utils";
import receipt from "../../assets/svgs/receipt.svg";

const RejectedMembers = () => {
  const adminAuth = useAppSelector((state) => state.adminAuth);
  let membersArray = adminAuth.allClubMembers?.members;
  let memberState = "rejected";

  const rejectedArray = filterByState(membersArray, memberState);
  return (
    <div className="bg-white rounded-[20px] flex flex-col h-full mt-6 flow-hide relative pt-5">
      <h1 className="pl-5">Rejected Club Members</h1>
      <table className="w-full table-auto">
        <thead>
          <tr className="w-full bg-[#f4f4f4] bg-opacity-30 flex item-center h-[35px] pl-5">
            <th className="w-3/12 text-sm text-neutral-600 flex items-center ">
              Name of player
            </th>
            <th className="w-3/12 text-sm text-neutral-600 flex items-center">
              Email Address
            </th>
            <th className="w-2/12 text-sm text-neutral-600 flex items-center">
              Points
            </th>
            <th className="w-2/12 text-sm text-neutral-600 flex items-center">
              Date Joined
            </th>
            <th className="w-2/12 text-sm text-neutral-600 flex items-center">
              Status
            </th>
          </tr>
        </thead>
        {!rejectedArray?.length ? (
          <tbody>
            <tr>
              <td className="w-full flex flex-col justify-center items-center">
                <div className="mt-10 border-2.38 border-gray-600  flex flex-col items-center">
                  <img
                    src={receipt}
                    className="border-2.5 border-gray-600 w-38 h-38"
                    alt="empty reciept icon"
                  />
                  <span className="font-inter text-sm font-medium leading-15 tracking-wider w-106 h-15 text-center mt-5">
                    No Rejected Members
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <span>
            <tbody className="w-full flex flex-col space-y-4 mt-2">
              {rejectedArray?.map((item: any, index: number) => {
                let sampleData = {
                  firstname: "",
                  lastname: "",
                  email: "",
                  image: "",
                };

                const { email, firstname, updatedAt, lastname } =
                  item?.firstname !== undefined ? item : sampleData;

                return (
                  <tr key={index} className="w-full flex items-center pl-5">
                    <td className="w-3/12 flex items-center space-x-2">
                      <img src={samp} alt="sample" />
                      <p className="text-sm text-customBlack font-[500]">
                        {capitalizeWords(`${firstname} ${lastname}`)}
                      </p>
                    </td>
                    <td className="w-3/12 text-sm text-customBlack font-[500]">
                      {email}
                    </td>
                    <td className="w-2/12 text-sm text-customBlack font-[500]">
                      21,092,22
                    </td>
                    <td className="w-2/12 text-sm text-customBlack font-[500]">
                      {convertDateFormat(updatedAt)}
                    </td>
                    <td className="w-2/12 text-sm text-customBlack font-[500]">
                      {item.state === "accepted" && (
                        <p className="text-[#197C34] px-2 py-[6px] rounded-[8px] bg-[#D1E5D6] w-fit">
                          Accepted
                        </p>
                      )}
                      {item.state === "rejected" && (
                        <p className="text-[#B00020] px-2 py-[6px] rounded-[8px] bg-[#F7E6E9] w-fit">
                          {item.state}
                        </p>
                      )}
                      {item.state === "pending" && (
                        <p className="text-[#E48900] px-2 py-[6px] rounded-[8px] bg-[#FAE7CC] w-fit">
                          Pending
                        </p>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <div className="w-full h-[36px] items-center flex justify-between pl-4 pr-8 mb-0 mt-3">
              {/* pagination */}
              <div className="flex items-center">
                <p className="text-sm">Page</p>
                <p className="px-1 rounded-sm border border-border text-customBlack font-bold cursor-pointer text-sm mx-2 ">
                  1
                </p>
                <p className="text-sm text-customBlack">of 2</p>
                <div className="space-x-2 items-center ml-8 flex">
                  <span className="text-base px-1 bg-[#f4f4f4] text-[#cfcfcf] opacity-70 rounded-sm border border-border">
                    <IoIosArrowBack />
                  </span>
                  <span className="text-base px-1 bg-[#f4f4f4] text-customBlack opacity-70 rounded-sm border border-border">
                    <IoIosArrowForward />
                  </span>
                </div>
              </div>
              {/* enteries */}
              <div className="flex">
                <div className="mt-10 border-2.38 border-gray-600 w-38 h-38 flex flex-col items-center">
                  <img
                    src={receipt}
                    className="border-2.5 border-gray-600 w-38 h-38"
                    alt="empty receipt icon"
                  />
                  <h1 className="font-inter text-sm font-medium leading-15 tracking-wider w-106 h-15 text-center mt-5">
                    No of entries
                  </h1>
                </div>
                {/* <p>No of entries </p> */}
                <div>
                  <p className="px-1 rounded-sm border border-border text-customBlack font-bold cursor-pointer text-sm mx-2 ">
                    10 per page
                  </p>
                </div>
              </div>
            </div>
          </span>
        )}
      </table>
    </div>
  );
};

export default RejectedMembers;
