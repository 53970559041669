import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { IoIosArrowBack } from "react-icons/io";
import { More } from "../../assets/svgs/court";
import { useNavigate, useParams } from "react-router-dom";
interface FormattedDateTime {
  date: string;
  time: string;
}
const ViewSingleUpcomingMatch = () => {
  const user = useAppSelector((state) => state.auth);
  const ladder = useAppSelector((state) => state.ladder);
  const id = useParams();
  const item = user?.allMatches?.filter((item: any) => item?._id === id.id);

  const [court, setCourt] = useState("");
  console.log(item);
  const allCourts = ladder?.allCourts;
  const navigate = useNavigate();

  function formatDateTime(inputDate: string): FormattedDateTime {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
    };
    const date: Date = new Date(inputDate);

    const dayMonthFormat: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
    };
    const formattedDate: string = date
      .toLocaleDateString("en-US", dayMonthFormat)
      .toUpperCase();

    const formattedTime: string = `${date.getHours()}:${
      date.getMinutes() < 10 ? "0" : ""
    }${date.getMinutes()}`;

    return { date: formattedDate, time: formattedTime };
  }
  const formattedDateTime: FormattedDateTime = formatDateTime(item?.[0]?.time);
  const {
    challenged,
    challenger,

    challengedB,
    challengerB,
  } = item[0] || {};
  const info = item[0];

  const matchCourt = allCourts?.filter(
    (item: any) => item?._id === info?.court
  );

  return (
    <div className="w-full flex flex-col px-4 pb-10 relative h-screen">
      <div className="flex justify-between mt-6 items-center mb-8">
        <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
        <p className="text-[#303030] font-bold text-[15px] ">
          Challenge Invitation
        </p>
        <div className="more-shadow opacity-0">
          <More />
        </div>
      </div>
      <div className="w-full bg-[#FFA800] h-[250px] rounded-[24px] p-4 flex space-x-6 justify-between">
        <div className="flex flex-col">
          <img
            src={challenger?.image}
            alt="profile avatar"
            className="w-[120px] h-[120px] object-cover rounded-[20px]"
          />
          <p className="text-white text-base font-bold mb-2 mt-1 text-center capitalize">
            {challenger?.firstname}
            <br />
            {challenger?.lastname}
          </p>
          <div className="h-[29px] bg-white flex items-center justify-center px-4 rounded-3xl text-ash text-xs  ">
            {challenger?.rank}
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-base font-bold text-white mt-8">VS</p>
        </div>
        <div className="flex flex-col">
          <img
            src={challenged?.image}
            alt="profile avatar"
            className="w-[120px] h-[120px] object-cover rounded-[20px]"
          />
          <p className="text-white text-base font-bold mb-2 mt-1 text-center capitalize">
            {challenged?.firstname}
            <br />
            {challenged?.lastname}
          </p>
          <div className="h-[29px] bg-white flex items-center justify-center px-4 rounded-3xl text-ash text-xs  ">
            {challenged?.rank}
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-8 space-y-4">
        {/* start of partl */}
        <div className="flex flex-col">
          <p className="text-sm text-customBlack font-bold mb-4">
            Match Details
          </p>
        </div>

        {/* end of a pack */}

        {/* start of partl */}
        {/* <div className="flex justify-between items-center mb-3">
          <p className="text-sm text-[#5e5e5e] font-[400] ">Match Date </p>
          <p className="text-customBlack text-sm font-bold">
            {" "}
            {formattedDateTime?.date}
          </p>
        </div> */}
        <div className="w-full min-h-[50px] flex items-center border border-[#CFCFCF] px-6 justify-between rounded-[10px] mb-3">
          <p className=" text-xs text-[#5E5E5E]">Date</p>
          <p className="text-sm font-semibold text-customBlack">
            {formattedDateTime?.date}
          </p>
          <p></p>
        </div>
        <div className="w-full min-h-[50px] flex items-center border border-[#CFCFCF] px-6 justify-between rounded-[10px] mb-3">
          <p className=" text-xs text-[#5E5E5E]">Time</p>
          <p className="text-sm font-semibold text-customBlack">
            {formattedDateTime.time}
          </p>
          <p></p>
        </div>
        <div className="w-full min-h-[50px] flex items-center border border-[#CFCFCF] px-6 justify-between rounded-[10px] mb-3">
          <p className=" text-xs text-[#5E5E5E]">Court</p>
          <p className="text-sm font-semibold text-customBlack capitalize">
            {matchCourt[0]?.name}
          </p>
          <p></p>
        </div>

        {/* end of a pack */}
      </div>
    </div>
  );
};

export default ViewSingleUpcomingMatch;
