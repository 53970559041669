import { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Message } from "../../../assets/svgs/Icons";
import { Btn } from "../../../components/Button";
import { EmailInput } from "../../../components/Input";
import ReUseModal from "../../../components/Modal/ReUseModal";
import { BigText, ModalSub, Ptext } from "../../../components/Text";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  verifyForgotPasswordEmail,
  resetForgetEmailVerification,
} from "../../../features/auth/AuthSlice";
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);

  useEffect(() => {
    email ? setActive(true) : setActive(false);
  }, [email]);

  const handleReset = () => {
    let data = { email: email };
    dispatch(verifyForgotPasswordEmail(data));
  };

  useEffect(() => {
    if (auth.forgotEmailVerificationSuccess) {
      setOpen(true);
      setTimeout(() => {
        dispatch(resetForgetEmailVerification());
        navigate(`/verify-otp?email=${email}`);
      }, 2000);
    } else {
      setOpen(false);
    }
  }, [auth.forgotEmailVerificationSuccess]);

  return (
    <div className="w-full flex flex-col md:hidden p-4 relative h-screen">
      <AiOutlineArrowLeft
        onClick={() => navigate(-1)}
        className="mt-20 text-2xl"
      />
      <div className="flex flex-col">
        <BigText text="Forgot Password" className="mt-8" />
        <Ptext
          text="Enter the email address associated with your account to get a code to reset your password "
          className="mb-[37px] mt-2"
        />
        <EmailInput
          onChange={(e: any) => setEmail(e.target.value)}
          placeholder="Enter your Email"
          required={true}
        />
        <div className="fixed  bottom-20 left-0 right-0 p-4">
          <Btn
            name="Send Code"
            active={active}
            onClick={handleReset}
            loading={auth.loading}
          />
        </div>
      </div>
      <ReUseModal open={open} setOpen={setOpen}>
        <div className="w-full flex flex-col justify-center items-center py-8">
          <Message />
          <p className="text-customBlack font-bold text-lg text-center">
            Check your email
          </p>
          <ModalSub
            className="text-center mt-1"
            text="An Email has been sent to your provided email with password reset instructions "
          />
          <div className="w-6/12 mx-auto">
            <Btn
              active={true}
              onClick={() => {
                setOpen(false);
                navigate(`/verify-otp?email=${email}`);
              }}
              name="Close"
              className="mt-3 "
            />
          </div>
        </div>
      </ReUseModal>
    </div>
  );
};

export default ForgotPassword;
