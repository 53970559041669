import { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { Mark } from "../../../assets/svgs/Icons";
import { Btn } from "../../../components/Button";
import ReUseModal from "../../../components/Modal/ReUseModal";
import { BigText, ModalSub, Ptext } from "../../../components/Text";
const EmailVerficationOtp = () => {
  const navigate = useNavigate();
  const adminAuth = useAppSelector((state) => state.adminAuth);
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    otp ? setActive(true) : setActive(false);
  }, [otp]);

  useEffect(() => {
    if (adminAuth.forgetToken) {
      setOpen(true);
      navigate("/admin/new-password");
    }
  }, [adminAuth.forgetToken]);

  return (
    <>
      {/* <div className=" flex-col lg:flex hidden mt-6 px-4 justify-center h-screen w-1/2 mx-auto">
        <h1 className="font-bold text-customBlack text-xl text-center mb-2 ">
          Oops, this page is only designed for mobile devices.
        </h1>
        <p className="text-neutral-700 text-base  ">
          Please switch to a smaller screen, such as a smartphone or tablet, to
          enjoy this feature. We appreciate your cooperation.
        </p>
      </div> */}
      <div className="w-full flex flex-col p-4 relative h-screen md:max-w-[428px] md:mx-auto md:h-screen">
        <AiOutlineArrowLeft
          onClick={() => navigate(-1)}
          className="mt-20 text-2xl"
        />
        <div className="flex flex-col">
          <BigText text="Verify Otp" className="mt-8" />
          <Ptext
            text="Enter the 4-digit OTP code sent to your email "
            className="mb-[37px] mt-2"
          />
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            containerStyle="w-8/12 mx-auto flex justify-between "
            renderInput={(props) => <input {...props} />}
            inputStyle="w-full min-w-[49px] min-h-[44px] rounded-[10px] border border-[#cfcfcf] "
          />
          <p className="text-sm text-[#666] text-center mt-9">
            Didn’t receive code?{" "}
            <span className="text-[#FFA800] font-bold"> Resend Code </span>
          </p>
          <div className="fixed  bottom-20 left-0 right-0 p-4">
            <Btn
              name="Send Code"
              active={active}
              onClick={() => setOpen(true)}
            />
          </div>
        </div>
        <ReUseModal open={open} setOpen={setOpen}>
          <div className="w-full flex flex-col justify-center items-center py-8">
            <Mark />
            <p className="text-customBlack font-bold text-lg text-center">
              Otp successful
            </p>
            <ModalSub
              className="text-center mt-1 w-full"
              text="Your email has been verified"
            />
            <ModalSub
              className="text-center opacity-0"
              text="We’ve sent an OTP code to your email to"
            />
            <div className="w-6/12 mx-auto">
              <Btn
                active={true}
                onClick={() => navigate("/new-password")}
                name="Continue"
                className="mt-3 "
              />
            </div>
          </div>
        </ReUseModal>
      </div>
    </>
  );
};

export default EmailVerficationOtp;
