import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  allBookings,
  resetAll,
  updateCourtBookingAdmin,
} from "../../../../features/auth/TournamentSlice";
import { convertDateFormat, convertTimeToAMPM } from "../../../../utils";
import receipt from "../../../../assets/svgs/receipt.svg";
import {
  updateCourtBooking,
  resetEditBookingSuccess,
} from "../../../../features/auth/TournamentSlice";
import { toast } from "react-toastify";
import ReUseModal from "../../../Modal/ReUseModal";
import { AdminTimeInput } from "../../../Input/DateInput";
import { CautionIcon, StopWatch } from "../../../../assets/svgs/Icons";
import Options from "../../../Selects/Options";
import { OutlinedBtn } from "../../../Button";
import LoadingComponent from "../../../Loading/Loader";
const AllPendingBookingTable = ({ className, setAllPendingCourtNo }: any) => {
  const dispatch = useAppDispatch();
  const tournament = useAppSelector((state) => state.tournament);
  const [allPending, setAllPending] = useState<any>([]);
  useEffect(() => {
    dispatch(allBookings());
  }, []);

  useEffect(() => {
    const allPendingBookings = tournament?.allBookings?.filter(
      (booking: any) => booking.state === "pending"
    );
    setAllPending(allPendingBookings);
    setAllPendingCourtNo(allPendingBookings?.length);
    if (tournament.editBookingSuccess) {
      toast.success("Update Successful");

      dispatch(resetEditBookingSuccess());
      dispatch(allBookings());
    }
    if (tournament.updateBookingSuccess) {
      toast.success("Update Successful");

      dispatch(resetAll());
      dispatch(allBookings());
    }
  }, [tournament.editBookingSuccess, tournament?.updateBookingSuccess]);

  if (tournament?.fetchLoading) {
    return (
      <div className="w-full 2xl:min-h-[50vh] lg:min-h-[60vh] px-6 mt-4">
        <LoadingComponent />
      </div>
    );
  }

  return (
    <div
      className={`w-full   h-auto  flex flex-col bg-white  rounded-[20px] ${className} `}
    >
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className="text-sm font-semibold text-customBlack">
          Pending Approval
        </h1>
      </div>
      {/* Table section starts from here  #################################################3*/}

      <table className="min-w-full w-full h-full 2xl:min-h-[50vh] lg:min-h-[60vh]  ">
        <thead>
          <tr className="w-full bg-[#f4f4f4] bg-opacity-30 flex item-center h-[35px] pl-5 ">
            <th className="w-3/12 text-xs text-neutral-600 flex items-center ">
              Court Name
            </th>
            <th className="w-2/12 text-xs text-neutral-600 flex items-center">
              Booked By
            </th>
            <th className="w-2/12 text-xs text-neutral-600 flex items-center">
              Location
            </th>
            <th className="w-2/12 text-xs text-neutral-600 flex items-center">
              Booked Date
            </th>
            <th className="w-1/12 text-xs text-neutral-600 flex items-center">
              Booked Time
            </th>
            <th className="w-2/12 text-xs text-neutral-600 flex items-center pl-4">
              Action
            </th>
            {/* <th className="w-1/12 text-xs text-neutral-600 flex items-center">
              Action
            </th> */}
          </tr>
        </thead>

        <tbody className="w-full  rounded-b-[20px] flex flex-col h-full ">
          {allPending.map((item: any, index: number) => {
            return <SingleRow item={item} key={index} index={index} />;
          })}
          {allPending.length === 0 && (
            <tr>
              <td className="w-full flex flex-col items-center justify-center">
                <div className="mt-10 border-2.38 border-gray-600  flex flex-col items-center">
                  <img
                    src={receipt}
                    className="border-2.5 border-gray-600 w-38 h-38"
                    alt=""
                  />
                  <h1 className="font-inter text-xs font-medium leading-15 tracking-wider w-106 h-15 text-center mt-5">
                    No Pending Bookings Yet.
                  </h1>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Table section Ends from here $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$4 */}
    </div>
  );
};

export default AllPendingBookingTable;

const SingleRow = ({ item, index }: any) => {
  const tournament = useAppSelector((state) => state.tournament);
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openConsent, setOpenConsent] = useState(false);
  let firsttime = item?.time?.split(" ");
  const [time, setTime] = useState(firsttime ? firsttime[0] : "0:00");

  const update = () => {
    const data = {
      id: item?._id,
      time: convertTimeToAMPM(time),
    };
    dispatch(updateCourtBooking(data));
  };
  useEffect(() => {
    if (tournament.editBookingSuccess) {
      setOpenModal(false);
    }
  }, [tournament.editBookingSuccess]);

  const handleApprove = () => {
    const data = { state: "approved" };
    const sentData = {
      data: data,
      id: item?._id,
    };
    dispatch(updateCourtBookingAdmin(sentData));
  };
  const handleReject = () => {
    const data = { state: "rejected" };
    const sentData = {
      data: data,
      id: item?._id,
    };
    dispatch(updateCourtBookingAdmin(sentData));
  };

  return (
    <>
      <tr className="flex  py-3  items-center pl-5" key={index}>
        <td className=" w-3/12 text-sm text-customBlack font-[500] text-start flex items-center gap-3 ">
          <span>
            <img
              src={item?._court?.image}
              alt=""
              className="w-7 h-7 rounded-full object-cover "
            />
          </span>
          <p className="text-sm text-customBlack capitalize">
            {item?._court?.name}
          </p>
        </td>
        <td className=" w-2/12 text-sm text-customBlack font-[500] text-start capitalize ">
          {item?._user?.firstname ?? "Admin"} {item?._user?.lastname ?? ""}
        </td>
        <td className=" w-2/12 text-sm text-customBlack font-[500] text-start capitalize ">
          {" "}
          {`${item?._court?.state ?? " "}`} {item?._court?.country}
        </td>
        <td className=" w-2/12 text-sm text-customBlack font-[500] text-start ">
          {convertDateFormat(item.date)}
        </td>
        <td className=" w-1/12 text-sm text-customBlack font-[500] text-start ">
          {item.time}
        </td>
        <td className=" w-2/12 text-sm text-customBlack font-[500] text-start pl-4 gap-6 flex items-center ">
          {tournament?.loading ? (
            <div className="bg-slate-200 h-[30px]  w-[100px] animate-pulse"></div>
          ) : (
            <button
              className=" text-[#197C34] text-sm underline font-medium  cursor-pointer"
              onClick={handleApprove}
              disabled={tournament?.loading}
            >
              Approve
            </button>
          )}

          <p
            className=" text-[#B00020] text-sm underline font-medium cursor-pointer "
            onClick={() => setOpenConsent(true)}
          >
            Reject
          </p>
          <Options isLast={false}>
            <div className="w-full flex flex-col ">
              <div
                className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                onClick={() => setOpenModal(true)}
              >
                <span>
                  <StopWatch />
                </span>
                <p className="text-customBlack text-sm">Change time slot</p>
              </div>
            </div>
          </Options>
        </td>
        {/* <td className=" w-1/12 text-sm text-neutral-600 text-start "></td> */}
      </tr>
      <ReUseModal open={openModal} setOpen={setOpenModal}>
        <div className="flex flex-col">
          <h1 className="text-base text-customBlack font-[500] ">
            Change Time Slot
          </h1>

          <div className="h-[2px] bg-[#CFCFCF] w-full mt-2 " />
          <div className="flex flex-col mt-[23px]">
            <label htmlFor="" className="text-sm text-neutral-600">
              Change Time
            </label>

            <AdminTimeInput time={time} setTime={setTime} height="h-[50px]" />
          </div>
          <div className="grid grid-cols-2 gap-x-3 mt-[46px]">
            <button
              className="w-full border border-lemonGreen h-[52px] flex items-center justify-center text-lemonGreen text-sm rounded-[16px] font-semibold cursor-pointer"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
            <button
              className="w-full border bg-lemonGreen h-[52px] flex items-center justify-center text-white text-sm font-semibold rounded-[16px] cursor-pointer "
              onClick={update}
              disabled={tournament?.loading}
            >
              {tournament?.loading ? "Loading..." : "Change"}
            </button>
          </div>
        </div>
      </ReUseModal>
      <ReUseModal open={openConsent} setOpen={setOpenConsent}>
        <div className="w-full flex flex-col">
          <span className="mx-auto mt-6">
            <CautionIcon />
          </span>

          <h1 className="text-customBlack text-[18px] font-bold text-center mt-6">
            Reject Court Booking
          </h1>
          <p className="text-center w-10/12 mx-auto text-neutral-500 text-sm ">
            Are you sure you want to reject all court booking?
          </p>
          <div className="flex justify-between items-center mt-4 gap-5">
            <span className="w-1/2">
              <OutlinedBtn
                active
                onClick={() => setOpenConsent(false)}
                name="No Cancel"
              />
            </span>
            <span className="w-1/2">
              <button
                className="h-[56px] w-full rounded-[16px] bg-[#B00020]  text-white font-semibold text-base  flex items-center justify-center cursor-pointer"
                onClick={handleReject}
              >
                {tournament?.loading ? "Loading..." : "Yes, Reject"}
              </button>
            </span>
          </div>
        </div>
      </ReUseModal>
    </>
  );
};
