import receipt from "../../../../assets/svgs/receipt.svg";
const LadderMatchesTables = ({ className }: any) => {
  return (
    <div
      className={`w-full  h-full min-h-[40vh] flex flex-col bg-white  rounded-[20px] ${className} `}
    >
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className="text-sm font-semibold text-customBlack">
          Ongoing Ladder Matches
        </h1>
      </div>
      <table className="min-w-full w-full h-full bg-[#fff] rounded-b-[20px]   ">
        <thead className=" bg-[#f4f4f4] bg-opacity-40 mb-2   ">
          <tr className="flex items-center h-[35px] pl-5">
            <th className="w-2/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-start ">
              {" "}
              Date & Time
            </th>
            <th className="w-2/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA ">
              {" "}
              Challenger
            </th>
            <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA ">
              {" "}
              VS
            </th>
            <th className="w-2/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA ">
              {" "}
              Challenged
            </th>
            <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA ">
              {" "}
              Court
            </th>
            <th className="w-2/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA ">
              {" "}
              Results
            </th>
            <th className="w-2/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA flex justify-center ">
              {" "}
              Action
            </th>
          </tr>
        </thead>
        <tbody className="w-full flex flex-col space-y-6 mb-4 mt-2">
          {/* {sampleData.map((item: any, index: number) => {
            return <SingleRow item={item} index={index} />;
          })} */}
          <tr>
            <td className="mt-10 border-2.38 border-gray-600 w-38 h-38 flex flex-col items-center">
              <img
                src={receipt}
                className="border-2.5 border-gray-600 w-38 h-38"
                alt=""
              />
              <span className="font-inter text-sm font-medium leading-15 tracking-wider w-106 h-15 text-center mt-5">
                No Ladder Matches
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="w-full h-[36px] items-center flex justify-between pl-4 pr-8  mt-3 mb-4">
        {/* pagination */}
        {/* <div className="flex items-center">
          <p className="text-sm">Page</p>
          <p className="px-1 rounded-sm border border-border text-customBlack font-bold cursor-pointer text-sm mx-2 ">
            1
          </p>
          <p className="text-sm text-customBlack">of 2</p>
          <div className="space-x-2 items-center ml-8 flex">
            <span className="text-base px-1 bg-[#f4f4f4] text-[#cfcfcf] opacity-70 rounded-sm border border-border">
              <IoIosArrowBack />
            </span>
            <span className="text-base px-1 bg-[#f4f4f4] text-customBlack opacity-70 rounded-sm border border-border">
              <IoIosArrowForward />
            </span>
          </div>
        </div> */}
        {/* enteries */}
        {/* <div className="flex">
          <p>No of entries </p>
          <div>
            <p className="px-1 rounded-sm border border-border text-customBlack font-bold cursor-pointer text-sm mx-2 ">
              10 per page
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default LadderMatchesTables;
