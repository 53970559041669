export const SolidTennisIcon = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="45"
        height="45"
        rx="22.5"
        fill="#7BC133"
        fill-opacity="0.08"
      />
      <g clip-path="url(#clip0_468_15832)">
        <path
          d="M28.8653 28.864C32.38 25.3492 32.38 19.6508 28.8653 16.136C25.3506 12.6213 19.6521 12.6213 16.1374 16.136C12.6227 19.6508 12.6227 25.3492 16.1374 28.864C19.6521 32.3787 25.3506 32.3787 28.8653 28.864Z"
          stroke="#7BC133"
          stroke-width="2.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.5234 22.0049C14.7858 21.9363 16.0484 22.1344 17.2291 22.5863C18.4097 23.0382 19.482 23.7337 20.3759 24.6277C21.2698 25.5216 21.9654 26.5938 22.4173 27.7745C22.8692 28.9551 23.0673 30.2178 22.9987 31.4801"
          stroke="#7BC133"
          stroke-width="2.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.0055 13.5199C21.9369 14.7823 22.1349 16.0449 22.5868 17.2256C23.0387 18.4062 23.7343 19.4785 24.6282 20.3724C25.5222 21.2663 26.5944 21.9619 27.7751 22.4138C28.9557 22.8657 30.2184 23.0638 31.4807 22.9952"
          stroke="#7BC133"
          stroke-width="2.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_468_15832">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(5.53125 22.5) rotate(-45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const RecordIcon = () => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21.5" cy="21.5" r="21.5" fill="#EB5757" fill-opacity="0.1" />
      <path
        d="M30 18.25V28C30 31 28.21 32 26 32H18C15.79 32 14 31 14 28V18.25C14 15 15.79 14.25 18 14.25C18 14.87 18.25 15.43 18.66 15.84C19.07 16.25 19.63 16.5 20.25 16.5H23.75C24.99 16.5 26 15.49 26 14.25C28.21 14.25 30 15 30 18.25Z"
        stroke="#EB5757"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26 14.25C26 15.49 24.99 16.5 23.75 16.5H20.25C19.63 16.5 19.07 16.25 18.66 15.84C18.25 15.43 18 14.87 18 14.25C18 13.01 19.01 12 20.25 12H23.75C24.37 12 24.93 12.25 25.34 12.66C25.75 13.07 26 13.63 26 14.25Z"
        stroke="#EB5757"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 23H22"
        stroke="#EB5757"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 27H26"
        stroke="#EB5757"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const TennisBallIcon = () => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21.5" cy="21.5" r="21.5" fill="#7BC133" fill-opacity="0.1" />
      <path
        d="M23.5912 23.5905L23.5911 23.5906C21.5179 25.6639 20.5834 28.4429 20.7705 31.1551C18.7688 30.8934 16.8995 29.9789 15.4598 28.5407C14.0209 27.1014 13.1061 25.2319 12.8449 23.2299C15.5571 23.417 18.3355 22.4825 20.4096 20.4094L20.4098 20.4093C22.4825 18.3366 23.417 15.5584 23.2306 12.8469C25.1764 13.1038 27.0525 13.9707 28.5411 15.4593C30.0298 16.948 30.8967 18.8235 31.1535 20.7698C28.4421 20.5834 25.6647 21.518 23.5912 23.5905Z"
        stroke="#7BC133"
        stroke-width="1.5"
      />
      <path
        d="M24.504 28.6716C24.8015 27.6878 25.3372 26.7927 26.0635 26.0656C26.7909 25.3392 27.6863 24.8034 28.6702 24.5058C29.4129 24.2812 30.1887 24.1974 30.9579 24.2565C30.5575 25.8732 29.7232 27.3556 28.5387 28.5401C27.3541 29.7247 25.8716 30.559 24.2549 30.9594C24.1957 30.1901 24.2793 29.4142 24.504 28.6716ZM19.4931 15.3284C19.1955 16.3123 18.6597 17.2075 17.9331 17.9347C17.2057 18.661 16.3103 19.1967 15.3264 19.4943C14.5836 19.7189 13.8076 19.8026 13.0382 19.7435C13.4387 18.1267 14.2731 16.6441 15.4578 15.4596C16.6425 14.275 18.1252 13.4407 19.7422 13.0405C19.8014 13.8098 19.7178 14.5857 19.4931 15.3284Z"
        stroke="#7BC133"
        stroke-width="1.5"
      />
    </svg>
  );
};
export const EmptyListIcon = () => {
  return (
    <svg
      width="122"
      height="81"
      viewBox="0 0 122 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1144_18908)">
        <path
          d="M25.0363 22.6685C23.7426 22.6685 22.6938 21.6197 22.6938 20.326C22.6938 19.0322 23.7426 17.9834 25.0363 17.9834C26.3301 17.9834 27.3789 19.0322 27.3789 20.326C27.3789 21.6197 26.3301 22.6685 25.0363 22.6685Z"
          fill="#7BC133"
        />
        <path
          d="M101.059 36.5667C100.455 36.5667 99.966 36.0773 99.966 35.4735C99.966 34.8698 100.455 34.3804 101.059 34.3804C101.663 34.3804 102.152 34.8698 102.152 35.4735C102.152 36.0773 101.663 36.5667 101.059 36.5667Z"
          fill="#7BC133"
        />
        <path
          d="M25.9031 62.206C25.1269 62.206 24.4976 61.5767 24.4976 60.8005C24.4976 60.0243 25.1269 59.395 25.9031 59.395C26.6793 59.395 27.3086 60.0243 27.3086 60.8005C27.3086 61.5767 26.6793 62.206 25.9031 62.206Z"
          fill="#E1E4E5"
        />
        <path
          d="M33.4392 13.3267C32.5858 13.3267 31.894 12.6349 31.894 11.7815C31.894 10.9281 32.5858 10.2363 33.4392 10.2363C34.2926 10.2363 34.9844 10.9281 34.9844 11.7815C34.9844 12.6349 34.2926 13.3267 33.4392 13.3267Z"
          fill="#E1E4E5"
        />
        <path
          d="M98.2041 24.3863C97.2554 24.3863 96.4863 23.6172 96.4863 22.6685C96.4863 21.7198 97.2554 20.9507 98.2041 20.9507C99.1528 20.9507 99.9219 21.7198 99.9219 22.6685C99.9219 23.6172 99.1528 24.3863 98.2041 24.3863Z"
          fill="#E1E4E5"
        />
        <path
          d="M23.1059 35.0831C23.3264 35.9061 22.8381 36.7519 22.0151 36.9724C21.1922 37.193 20.3463 36.7046 20.1258 35.8817C19.9053 35.0587 20.3937 34.2128 21.2166 33.9923C22.0395 33.7718 22.8854 34.2602 23.1059 35.0831Z"
          fill="#E1E4E5"
        />
        <path
          d="M96.5151 64.7586C95.6432 64.7586 94.9365 64.1932 94.9365 63.4957C94.9365 62.7983 95.6432 62.2329 96.5151 62.2329C97.387 62.2329 98.0938 62.7983 98.0938 63.4957C98.0938 64.1932 97.387 64.7586 96.5151 64.7586Z"
          fill="#E1E4E5"
        />
        <path
          d="M91.4704 9.95774C91.9293 11.6704 90.913 13.4308 89.2003 13.8897C87.4877 14.3486 85.7273 13.3322 85.2684 11.6196C84.8095 9.90692 85.8259 8.14654 87.5385 7.68764C89.2511 7.22874 91.0115 8.2451 91.4704 9.95774Z"
          fill="#E1E4E5"
        />
        <path
          opacity="0.4"
          d="M72.4429 13.8647H49.558C39.6175 13.8647 33.6914 19.7908 33.6914 29.7313V52.589C33.6914 62.5567 39.6175 68.4828 49.558 68.4828H72.4156C82.3561 68.4828 88.2822 62.5567 88.2822 52.6163V29.7313C88.3095 19.7908 82.3834 13.8647 72.4429 13.8647Z"
          fill="#7BC133"
        />
        <path
          d="M73.07 29.7313V52.6163C73.07 54.3641 71.65 55.7841 69.9022 55.7841C68.1271 55.7841 66.707 54.3641 66.707 52.6163V29.7313C66.707 27.9835 68.1271 26.5635 69.9022 26.5635C71.65 26.5635 73.07 27.9835 73.07 29.7313Z"
          fill="#7BC133"
        />
        <path
          d="M55.2927 43.7137V52.6165C55.2927 54.3643 53.8726 55.7843 52.0975 55.7843C50.3498 55.7843 48.9297 54.3643 48.9297 52.6165V43.7137C48.9297 41.966 50.3498 40.5459 52.0975 40.5459C53.8726 40.5459 55.2927 41.966 55.2927 43.7137Z"
          fill="#7BC133"
        />
      </g>
      <defs>
        <clipPath id="clip0_1144_18908">
          <rect
            width="121"
            height="80.6667"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SortIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 7H21"
        stroke="#5E5E5E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M6 12H18"
        stroke="#5E5E5E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M10 17H14"
        stroke="#5E5E5E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export const LeftArrow = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5003 21.17C15.3103 21.17 15.1203 21.1 14.9703 20.95L8.45027 14.43C7.39027 13.37 7.39027 11.63 8.45027 10.57L14.9703 4.05002C15.2603 3.76002 15.7403 3.76002 16.0303 4.05002C16.3203 4.34002 16.3203 4.82002 16.0303 5.11002L9.51027 11.63C9.03027 12.11 9.03027 12.89 9.51027 13.37L16.0303 19.89C16.3203 20.18 16.3203 20.66 16.0303 20.95C15.8803 21.09 15.6903 21.17 15.5003 21.17Z"
        fill="#7BC133"
      />
    </svg>
  );
};
