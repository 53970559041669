import { useState } from "react";
import { useAppSelector } from "../../app/hooks";
import closeCircle from "../../assets/svgs/close-circle.svg";
import receipt from "../../assets/svgs/receipt.svg";
import { convertDateFormat, convertMonthDayFormat } from "../../utils";
import ReUseModal from "../Modal/ReUseModal";
import Avatar from "../Others/Avatars";
const AllClubMembers = () => {
  const adminAuth = useAppSelector((state) => state.adminAuth);
  let membersArray = adminAuth.allClubMembers;

  return (
    <div className="bg-white rounded-[20px] flex flex-col h-full mt-6 flow-hide relative pt-5 ">
      <h1 className="pl-5">All Club Members</h1>
      <table className="w-full table-auto">
        <thead>
          <tr className="w-full bg-[#f4f4f4] bg-opacity-30 flex item-center h-[35px] pl-5">
            <th className="w-3/12 text-sm text-neutral-600 flex items-center ">
              Name of player
            </th>
            <th className="w-3/12 text-sm text-neutral-600 flex items-center">
              Email Address
            </th>
            <th className="w-2/12 text-sm text-neutral-600 flex items-center">
              Points
            </th>
            <th className="w-2/12 text-sm text-neutral-600 flex items-center">
              Date Joined
            </th>
            <th className="w-2/12 text-sm text-neutral-600 flex items-center">
              Status
            </th>
          </tr>
        </thead>
        <tbody className="w-full flex flex-col space-y-4 mt-2">
          {!membersArray?.length && (
            <tr>
              <td className="w-full flex flex-col items-center justify-center">
                <div className="mt-10 border-2.38 border-gray-600  flex flex-col items-center">
                  <img
                    src={receipt}
                    className="border-2.5 border-gray-600 w-38 h-38"
                    alt="receipt"
                  />
                  <h1 className="font-inter text-sm font-medium leading-15 tracking-wider w-106 h-15 text-center mt-5">
                    No Members Yet
                  </h1>
                </div>
              </td>
            </tr>
          )}
          {membersArray?.map((item: any, index: number) => {
            return <SingleMember item={item} key={index} index={index} />;
          })}
        </tbody>
      </table>
      <div className="w-full h-[36px] items-center flex justify-between pl-4 pr-8 mb-0 mt-3">
        {/* pagination */}
        {/* <div className="flex items-center">
          <p className="text-sm">Page</p>
          <p className="px-1 rounded-sm border border-border text-customBlack font-bold cursor-pointer text-sm mx-2 ">
            1
          </p>
          <p className="text-sm text-customBlack">of 2</p>
          <div className="space-x-2 items-center ml-8 flex">
            <span className="text-base px-1 bg-[#f4f4f4] text-[#cfcfcf] opacity-70 rounded-sm border border-border">
              <IoIosArrowBack />
            </span>
            <span className="text-base px-1 bg-[#f4f4f4] text-customBlack opacity-70 rounded-sm border border-border">
              <IoIosArrowForward />
            </span>
          </div>
        </div> */}
        {/* enteries */}
        {/* <div className="flex">
          <p>No of entries </p>
          <div>
            <p className="px-1 rounded-sm border border-border text-customBlack font-bold cursor-pointer text-sm mx-2 ">
              10 per page
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AllClubMembers;

const SingleMember = ({ item, index }: any) => {
  const [openModal, setOpenModal] = useState(false);
  const admin = useAppSelector((state) => state.adminAuth);
  const clubName = admin.clubDeatails?.name ? admin.clubDeatails?.name : "";
  const {
    email,
    firstname,
    request,
    lastname,
    image,
    thumbnail,
    rank,
    account_verify,
    dateOfBirth,
  } = item;

  let passImage = image
    ? image || thumbnail
    : "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";

  return (
    <>
      <tr key={index} className="w-full flex items-center pl-5">
        <td
          className="w-3/12 flex items-center space-x-2 cursor-pointer"
          onClick={() => setOpenModal(true)}
        >
          <img
            src={
              image
                ? image || thumbnail
                : "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            }
            alt="sample"
            className="w-[28px] h-[28px] rounded-full"
          />
          <p className="text-sm capitalize text-customBlack font-[500]">
            {firstname} {lastname !== null ? lastname : " "}
          </p>
        </td>
        <td className="w-3/12 text-sm text-customBlack font-[500] ">{email}</td>
        <td className="w-2/12 text-sm text-customBlack font-[500]">{rank}</td>
        <td className="w-2/12 text-sm text-customBlack font-[500]">
          {convertDateFormat(request)}
        </td>
        <td className="w-2/12 text-sm text-customBlack font-[500]">
          {item.state === "accepted" && (
            <p className="text-[#197C34] px-2 py-[6px] rounded-[8px] bg-[#D1E5D6] w-fit">
              Accepted
            </p>
          )}
          {item.state === "rejected" && (
            <p className="text-[#B00020] px-2 py-[6px] rounded-[8px] bg-[#F7E6E9] w-fit">
              {item.state}
            </p>
          )}
          {item.state === "pending" && (
            <p className="text-[#E48900] px-2 py-[6px] rounded-[8px] bg-[#FAE7CC] w-fit">
              Pending
            </p>
          )}
        </td>
      </tr>
      <ReUseModal open={openModal} setOpen={setOpenModal}>
        <div className="w-full flex flex-col ">
          <div className="flex justify-between items-center h-[55px] border-b border-b-[#cfcfcf] w-full">
            <p className="text-customBlack text-base font-[500]">
              Profile Details
            </p>

            <img
              src={closeCircle}
              alt="close"
              className="cursor-pointer "
              onClick={() => setOpenModal(false)}
            />
          </div>
          {/* end of header */}
          <div className="w-full flex flex-col justify-center items-center py-6 border-b border-b-[#cfcfcf] ">
            <Avatar image={passImage} verified={account_verify} />
            <p className="tex-center font-semibold text-base text-customBlack mt-2 capitalize">
              {firstname} ${lastname !== null ? lastname : " "}
            </p>
          </div>
          {/* SSSSSSSSSSSSSSSDSDSDRGFFFFFFFFFF */}
          <div className="flex flex-col mt-4 pb-[18px] border-b border-b-[#cfcfcf]">
            <p className="text-customBlack text-base font-[500]">
              Profile Details
            </p>
            {/* start of a single */}
            <div className="w-full flex mt-4">
              <p className="w-1/4 text-neutral-600 text-sm font-[500]">
                Full Name
              </p>
              <p className="text-customBlack text-sm font-[500] capitalize">
                {firstname} ${lastname !== null ? lastname : " "}
              </p>
            </div>
            {/* enf of a single */}
            {/* start of a single */}
            <div className="w-full flex mt-[18px]">
              <p className="w-1/4 text-neutral-600 text-sm font-[500]">
                Date of Birth
              </p>
              <p className="text-customBlack text-sm font-[500]">
                {convertMonthDayFormat(dateOfBirth)}
              </p>
            </div>
            {/* enf of a single */}
            {/* start of a single */}
            <div className="w-full flex mt-[18px]">
              <p className="w-1/4 text-neutral-600 text-sm font-[500]">Email</p>
              <p className="text-customBlack text-sm font-[500] ">
                {email ? email : ""}
              </p>
            </div>
            {/* enf of a single */}
          </div>
          {/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$4 */}
          {/* SSSSSSSSSSSSSSSDSDSDRGFFFFFFFFFF */}
          <div className="flex flex-col mt-4 pb-[18px] ">
            <p className="text-customBlack text-base font-[500]">
              Club Details
            </p>
            {/* start of a single */}
            <div className="w-full flex mt-4">
              <p className="w-1/4 text-neutral-600 text-sm font-[500]">
                Club Name
              </p>
              <p className="text-customBlack text-sm font-[500] capitalize">
                {clubName}
              </p>
            </div>
            {/* enf of a single */}
            {/* start of a single */}
            <div className="w-full flex mt-[18px]">
              <p className="w-1/4 text-neutral-600 text-sm font-[500]">
                Date Joined
              </p>
              <p className="text-customBlack text-sm font-[500]">
                {convertDateFormat(request)}
              </p>
            </div>
            {/* enf of a single */}
            {/* start of a single */}
            <div className="w-full flex mt-[18px] items-center">
              <p className="w-1/4 text-neutral-600 text-sm font-[500]">
                Status
              </p>
              {item.state === "accepted" && (
                <p className="text-[#197C34] px-2 py-[6px] rounded-[8px] bg-[#D1E5D6] w-fit text-sm">
                  Accepted
                </p>
              )}
              {item.state === "rejected" && (
                <p className="text-[#B00020] px-2 py-[6px] rounded-[8px] bg-[#F7E6E9] w-fit text-sm">
                  {item.state}
                </p>
              )}
              {item.state === "pending" && (
                <p className="text-[#E48900] px-2 py-[6px] rounded-[8px] bg-[#FAE7CC] w-fit text-sm">
                  Pending
                </p>
              )}
            </div>
            {/* enf of a single */}
          </div>
          {/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$4 */}
        </div>
      </ReUseModal>
    </>
  );
};
