import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import onA from "../../assets/png/on1.png";
import onB from "../../assets/png/on2.png";
import onC from "../../assets/png/on3.png";
import { Btn } from "../../components/Button";
import { Ptext } from "../../components/Text";

const Onboarding = () => {
  const navigate = useNavigate();
  const [present, setPresent] = useState(1);

  return (
    <>
      {/* <div className=" flex-col lg:flex hidden mt-6 px-4 justify-center h-screen w-1/2 mx-auto">
        <h1 className="font-bold text-customBlack text-xl text-center mb-2 ">
          Oops, this page is only designed for mobile devices.
        </h1>
        <p className="text-neutral-700 text-base  ">
          Please switch to a smaller screen, such as a smartphone or tablet, to
          enjoy this feature. We appreciate your cooperation.
        </p>
      </div> */}
      <div className="w-full flex flex-col md:max-w-[428px] md:mx-auto md:h-screen">
        {present === 1 && (
          <div className="flex flex-col">
            <img src={onA} alt="ona" className="w-full h-auto" />
            <div className="flex flex-col p-4 mt-6">
              <h1 className="text-customBlack font-bold text-[28px] text-center">
                Ace your Opponent <br /> with Powerful Features
              </h1>
              <p className="text-neutral-500 text-center text-sm mt-4">
                Track your progress, improve your skills, and dominate like
                never before
              </p>
              <div className="mt-[34px] flex justify-center item-center ">
                <div className="w-[27.87px] h-[7px] bg-primaryYellow rounded-[50px]"></div>
                <div className="w-[9.6px] h-[7px] bg-[#f0f0f0] mx-1 rounded-[50px]"></div>
                <div className="w-[9.6px] h-[7px] bg-[#f0f0f0]  rounded-[50px]"></div>
              </div>
              <Btn
                active={true}
                onClick={() => setPresent(2)}
                name={"Next"}
                className="mt-10 mb-12"
              />
            </div>
          </div>
        )}
        {present === 2 && (
          <div className="flex flex-col">
            <img src={onB} alt="ona" className="w-full h-auto" />
            <div className="flex flex-col p-4 mt-6">
              <h1 className="text-customBlack font-bold text-[28px] text-center">
                Track your Progress & Dominate the Court
              </h1>
              <p className="text-neutral-500 text-center text-sm mt-4">
                Track your progress, improve your skills, and dominate like
                never before
              </p>
              <div className="mt-[34px] flex justify-center item-center ">
                <div className="w-[9.6px] h-[7px] bg-[#f0f0f0]  rounded-[50px]"></div>
                <div className="w-[27.87px] h-[7px] bg-primaryYellow rounded-[50px] mx-1"></div>
                <div className="w-[9.6px] h-[7px] bg-[#f0f0f0]  rounded-[50px]"></div>
              </div>
              <Btn
                active={true}
                onClick={() => setPresent(3)}
                name={"Next"}
                className="mt-10 mb-12"
              />
            </div>
          </div>
        )}
        {present === 3 && (
          <div className="flex flex-col">
            <img src={onC} alt="ona" className="w-full h-auto" />
            <div className="flex flex-col p-4 mt-6">
              <h1 className="text-customBlack font-bold text-[28px] text-center">
                Track your Progress & Dominate the Court
              </h1>
              <p className="text-neutral-500 text-center text-sm mt-4">
                Track your progress, improve your skills, and dominate like
                never before
              </p>
              <div className="mt-[34px] flex justify-center item-center ">
                <div className="w-[9.6px] h-[7px] bg-[#f0f0f0]  rounded-[50px]"></div>
                <div className="w-[9.6px] h-[7px] bg-[#f0f0f0]  rounded-[50px] mx-1"></div>
                <div className="w-[27.87px] h-[7px] bg-primaryYellow rounded-[50px] "></div>
              </div>
              <Btn
                active={true}
                onClick={() => navigate("/new-register")}
                name={"Get Started"}
                className="mt-10"
              />
              <div className="flex items-center justify-center mt-4 z-5 mb-4">
                <Ptext text="Do you have an account? " />
                <Link
                  to="/login"
                  className="text-[14px] text-primaryYellow ml-1"
                >
                  sign in
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Onboarding;
