import DashboardLayout from "../../../Layouts/DashboardLayout";

import EditSingleLadderDetails from "../../../components/Others/Ladder/EditSingleLadderDetails";
const EditLadderTournament = () => {
  return (
    <DashboardLayout current={2}>
      <EditSingleLadderDetails />
    </DashboardLayout>
  );
};

export default EditLadderTournament;
