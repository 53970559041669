import React from "react";
import { convertDateTime } from "../../utils";
import { useNavigate } from "react-router-dom";
import { EmptyListIcon } from "../../assets/new-svg/icons";
interface fixtureCard {
  item: any;
  index: number;
}
const MatchFixtureCard = ({ item, index }: fixtureCard) => {
  const navigate = useNavigate();
  return (
    <div
      className="w-full flex flex-col bg-white py-3 rounded-[12px] fixture-card-shadow"
      key={index}
    >
      <p className="text-center text-xs capitalize text-[#AFAFAF] mb-3 ">
        {item?._tournament?.name ?? "No name"}
      </p>
      <div className="flex flex-col">
        {item?.matches?.length === 0 && (
          <div className="flex flex-col  items-center justify-center">
            <span>
              <EmptyListIcon />
            </span>
            <p className="mt-2 text-secondary-400 text-xs">
              All completed matches will be displayed here
            </p>
          </div>
        )}
        {item?.matches?.map((match: any, idx: number) => {
          const { challenged, challenger } = match;
          const realDayTime = convertDateTime(match?.time);
          return (
            <div
              className="flex  flex-col w-full border-b border-b-[#F4F4F4] cursor-pointer last:border-none  py-2  "
              key={idx}
              onClick={() =>
                navigate(
                  `/view-competition-match/${item?._tournament?._id}?matchId=${match?._id}`
                )
              }
            >
              <div className="flex justify-center">
                <div className="flex justify-between items-center gap-4 w-full">
                  <div className="flex-grow flex  w-6/12 justify-end items-center gap-2 ">
                    <img
                      src={challenger?.image}
                      alt="challenger"
                      className="w-[35px] h-[35px] rounded-full "
                    />
                    <div className="flex flex-col ">
                      <p className="text-sm font-medium text-customBlack capitalize text-right truncate max-w-[90px] ">
                        {challenger?.firstname && challenger?.firstname}{" "}
                        {challenger?.lastname && challenger?.lastname}
                      </p>
                      <p className="text-[10px]  font-normal text-[#AFAFAF]  text-right">
                        RK:
                        {challenger?.rank ?? 0}
                      </p>
                    </div>
                  </div>
                  <div className="w-[60px] min-w-[60px] max-w-[60px] items-center flex flex-col justify-center">
                    <p className="text-xs font-semibold text-[#7BC133] uppercase">
                      {match?.time ? realDayTime?.otherTime : "Nil"}
                    </p>
                    <p className="text-[10px] font-normal text-[#AFAFAF] uppercase">
                      {match?.time ? realDayTime?.otherDate : "Nil"}
                    </p>
                  </div>
                  <div className="flex-grow w-6/12 flex items-center gap-2">
                    <div className="flex flex-col">
                      <p className="text-sm font-medium text-customBlack capitalize">
                        {challenged?.firstname && challenged?.firstname}{" "}
                        {challenged?.lastname && challenged?.lastname}
                      </p>
                      <p className="text-[10px]  font-normal text-[#AFAFAF]  ">
                        RK:
                        {challenged?.rank ?? 0}
                      </p>
                    </div>
                    <img
                      src={challenged?.image}
                      alt=""
                      className="w-[35px] h-[35px] rounded-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MatchFixtureCard;
