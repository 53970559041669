import userSvg from "../../assets/svgs/dash-user.svg";
import tennisSvg from "../../assets/svgs/dash-tennis.svg";
import courtSvg from "../../assets/svgs/dash-court.svg";

interface overviewProps {
  courtNo?: any;
  usertNo?: any;
  adminNo?: any;
  clubNo?: any;
}
const DashboardOverview = ({ courtNo, usertNo, clubNo }: overviewProps) => {
  const data = [
    {
      image: userSvg,
      value: usertNo ? usertNo : 0,
      title: "No of Registered Members",
    },
    {
      image: tennisSvg,
      value: clubNo ? clubNo : 0,
      title: "No of Registered Club",
    },
    {
      image: courtSvg,
      value: courtNo ? courtNo : 0,
      title: "No of Registered Courts",
    },
  ];

  return (
    <div className="w-full">
      <div className="grid w-full grid-cols-4 gap-x-3 2xl:gap-x-5 px-6">
        {data.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className="flex items-center bg-white rounded-[20px] p-5 space-x-2 "
            >
              <img src={item.image} alt={item.title} />
              <div className="flex flex-col bg-white">
                <h1 className="text-customBlack text-2xl font-[600]">
                  {item.value}
                </h1>
                <p className="text-[#868686] text-xs font-[400]">
                  {item.title}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DashboardOverview;

export const ClubAdminOverview = ({
  courtNo,
  usertNo,
  clubNo,
}: overviewProps) => {
  const data = [
    {
      image: userSvg,
      value: usertNo ? usertNo : 0,
      title: "No of Registered Members",
    },
    {
      image: tennisSvg,
      value: clubNo ? clubNo : 0,
      title: "No of Ongoing Matches",
    },
    {
      image: courtSvg,
      value: courtNo ? courtNo : 0,
      title: "No of Registered Courts",
    },
  ];
  return (
    <div className="w-full">
      <div className="grid w-full grid-cols-3 gap-x-3 2xl:gap-x-5 ">
        {data.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className="flex items-center bg-white rounded-[20px] p-5 space-x-2 "
            >
              <img src={item.image} alt={item.title} />
              <div className="flex flex-col">
                <h1 className="text-customBlack text-2xl font-[600]">
                  {item.value}
                </h1>
                <p className="text-[#868686] text-xs font-[400]">
                  {item.title}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
