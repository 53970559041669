import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { FaChevronDown } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { Btn } from "../../components/Button";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  resetProfileUpdateSuccess,
  updateProfileDetails,
} from "../../features/auth/AuthSlice";
import { toast } from "react-toastify";
import { UserDateInput } from "../../components/Input/DateInput";
import { convertDateInput } from "../../utils";
import ReUseModal from "../../components/Modal/ReUseModal";
import { resetAllLadderState } from "../../features/auth/ladder-slice";
import sentMark from "../../assets/svgs/sent-mark.svg";
type Gender = {
  dateOfBirth: string;
  gender: string;
};

const CompleteProfile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth);
  const [pickGender, setPickGender] = useState(
    user?.userData?.user.gender || ""
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isFormDetailsFilled, setIsFormDetailsFilled] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [phone, setPhone] = useState(user?.userData?.user.phone || "");
  const [address, setAddress] = useState(user?.userData?.user.address || "");
  const [open, setOpen] = useState<boolean>(false);
  const [active, setActive] = useState(false);
  const [pick, setPick] = useState<Gender>({
    dateOfBirth: "",
    gender: pickGender,
  });

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (phone && address && dateOfBirth) {
      setActive(true);
    } else setActive(false);
  }, [phone, address, dateOfBirth, pick]);

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleGenderClick = (gender: string) => {
    setPick({ ...pick, gender });
    setDropdownOpen(false);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;
    setDateOfBirth(newDate);
    setPick({ ...pick, dateOfBirth: newDate });
  };

  useEffect(() => {
    // Check if all form fields are filled
    const filled = pick.gender !== "" && pick.dateOfBirth !== "";

    setIsFormDetailsFilled(filled);
  }, [pick]);

  const handleSubmit = () => {
    const data = {
      phone: phone,
      address: address,
      gender: pick?.gender,
      dateOfBirth: dateOfBirth,
    };
    dispatch(updateProfileDetails(data));
  };

  useEffect(() => {
    if (user?.profileUpdateSuccess) {
      setOpen(true);
      dispatch(resetProfileUpdateSuccess());
      setTimeout(() => {
        navigate("/profile");
      }, 3000);
    }
  }, [user?.profileUpdateSuccess]);

  return (
    <main className="px-6  md:max-w-[428px] md:mx-auto relative h-screen overflow-y-hidden">
      <div className="flex items-center justify-between mb-12 mt-12">
        <div>
          <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
        </div>
        <div>
          <h1 className="font-bold text-base text-[#303030] ">
            Complete Your Profile
          </h1>
        </div>
        <div></div>
      </div>
      <div className="w-full flex flex-col gap-4">
        <div className="relative">
          <input
            type="tel"
            name="tel"
            id="tel"
            required
            value={phone}
            onChange={(e: any) => setPhone(e.target.value)}
            placeholder="Mobile Number"
            className="outline-none py-3 px-4 block w-full shadow-none bg-neutral-200 rounded-[10px] h-[50px]  placeholder:text-deepAsh text-customBlack"
          />
        </div>
        <div className="relative">
          <input
            type="text"
            name="address"
            id="address"
            required
            value={address}
            onChange={(e: any) => setAddress(e.target.value)}
            placeholder="Address"
            className="outline-none py-3 px-4 block w-full shadow-none bg-neutral-200 rounded-[10px] h-[50px]  placeholder:text-deepAsh text-customBlack"
          />
        </div>
        <div className="relative">
          <UserDateInput
            date={
              dateOfBirth || convertDateInput(user?.userData?.user?.dateOfBirth)
            }
            setDate={setDateOfBirth}
            // onChange={(e: any) => setDateOfBirth(e.target.vlaue)}
          />
        </div>
        <div className=" relative">
          <input
            type="text"
            id="gender"
            name="gender"
            value={pick.gender}
            placeholder="Select Gender"
            readOnly
            onClick={() => setDropdownOpen((prevOpen) => !prevOpen)}
            className="outline-none py-3 px-4 block w-full shadow-none bg-neutral-200 rounded-[10px] h-[50px] pl-4 placeholder:text-deepAsh text-customBlack"
          />
          <FaChevronDown
            className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer text-deepAsh"
            onClick={() => setDropdownOpen((prevOpen) => !prevOpen)}
          />

          {dropdownOpen && (
            <div
              className="absolute z-10 top-full w-full bg-white rounded-xl shadow-lg py-3 border border-[#eaeaed]"
              ref={dropdownRef}
            >
              <div
                onClick={() => handleGenderClick("female")}
                className="px-4 py-2 cursor-pointer font-euclid text-sm font-normal text-customBlack"
              >
                Female
              </div>
              <div
                onClick={() => handleGenderClick("male")}
                className="px-4 py-2 cursor-pointer font-euclid text-sm font-normal text-customBlack"
              >
                Male
              </div>
              <div
                onClick={() => handleGenderClick("other")}
                className="px-4 py-2 cursor-pointer font-euclid text-sm font-normal text-customBlack"
              >
                Other
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="absolute bottom-12 left-0 right-0 px-4">
        <Btn
          name="Submit"
          active={active}
          onClick={handleSubmit}
          loading={user?.loading}
        />
      </div>
      <ReUseModal open={open} setOpen={setOpen}>
        <div className="w-full flex flex-col">
          <img src={sentMark} alt="sent" className="mx-auto mt-6" />
          <h1 className="text-customBlack text-[18px] font-bold text-center ">
            Profile Update Successful
          </h1>
          <p className="text-center w-10/12 mx-auto text-neutral-500 text-sm ">
            You have successfully update your profile details.
          </p>
          <Btn
            active={true}
            name="Close"
            onClick={() => {
              setOpen(false);

              navigate(`/profile`);
            }}
            className="mt-[25px] mb-3"
          />
        </div>
      </ReUseModal>
    </main>
  );
};

export default CompleteProfile;
