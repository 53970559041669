const styles = {
  parentContainer: "w-full h-full   overflow-x-hidden md:overflow-x-auto   ",
  container: "2xl:container 2xl:mx-auto ",
  chiefCenter: "max-w-[1650px] w-full   mx-auto hidden lg:flex ",
};
interface layoutprops {
  children: any;
}
const AdminAuthLayout = ({ children }: layoutprops) => {
  return (
    <div className={styles.parentContainer}>
      <div className={styles.container}>
        <div className="flex flex-col lg:hidden mt-6 px-4 justify-center h-screen">
          <h1 className="font-bold text-customBlack text-base text-center mb-2 ">
            Sorry, this page is not optimized for mobile devices.
          </h1>
          <p className="text-neutral-700 text-sm  ">
            Please try viewing it on a larger screen, such as a laptop or
            desktop computer, for a better experience.
          </p>
          <p className="text-neutral-700 text-sm  ">
            Thank you for your understanding. 😊
          </p>
        </div>

        <div className={styles.chiefCenter}>{children}</div>
      </div>
    </div>
  );
};

export default AdminAuthLayout;
