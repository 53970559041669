import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import DashboardLayout from "../../../Layouts/DashboardLayout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { NavigationArrow } from "../../../assets/svgs/admin";
import sentMark from "../../../assets/svgs/sent-mark.svg";
import { Btn } from "../../../components/Button";
import ReUseModal from "../../../components/Modal/ReUseModal";
import PrimarySelect from "../../../components/Selects/PrimarySelect";
import { bookCourt } from "../../../features/auth/TournamentSlice";
import { convertDateToNumeric } from "../../../utils";
import {
  AdminDateInput,
  AdminTimeInput,
} from "../../../components/Input/DateInput";

const BookType = [
  {
    name: "For Ladder Tournament",
    id: 1,
    type: "ladder",
  },
  {
    name: "For Individual Player",
    id: 1,
    type: "individual",
  },
  {
    name: "For a Group Tournament",
    id: 1,
    type: "group",
  },
];

const BookCourt = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tournament = useAppSelector((state) => state.tournament);
  let allCourt = tournament?.allCourts;
  const [selected, setSelected] = useState<any>({});
  const [selectedA, setSelectedA] = useState<any>({});
  const [date, setDate] = useState<any>("");
  const [time, setTime] = useState<any>("");
  const [open, setOpen] = useState(false);
  const handleSubmit = () => {
    const data = {
      type: selectedA?.type.toLowerCase(),
      date: convertDateToNumeric(date),
      time: time,
      court: selected._id,
    };

    dispatch(bookCourt(data));
  };

  useEffect(() => {
    if (tournament.bookCourtSuccess) {
      setOpen(true);
      navigate("/admin/courts");
    } else {
      setOpen(false);
    }
  }, [tournament.bookCourtSuccess]);

  return (
    <DashboardLayout current={3}>
      <div className="w-full pl-[30px] pr-[50px] flex flex-col">
        <div
          className="flex w-fit cursor-pointer mt-10"
          onClick={() => navigate(-1)}
        >
          <NavigationArrow />
        </div>

        <div className="w-full flex flex-col bg-white mt-[26px] h-[80vh] rounded-[5px] relative ">
          <div className="h-[51px] ">
            <h1 className="pl-5 text-customBlack font-semibold text-sm mt-[22px] ">
              Book a Court
            </h1>
          </div>
          {/* breaker line */}
          <div className="w-full h-[1px] bg-[#cfcfcf] " />
          <p className="mt-3 text-neutral-600 pl-5 ">
            Fill in the details below to book a court
          </p>
          {/* start of actions */}
          <div className="2xl:w-10/12 w-11/12 grid grid-cols-2 mt-[31px] gap-x-[60px] pl-5 gap-y-[30px]">
            <PrimarySelect
              label="Court"
              selected={selected}
              setSelected={setSelected}
              name="Select Court"
              data={allCourt}
            />
            <PrimarySelect
              label="Type of Booking"
              selected={selectedA}
              setSelected={setSelectedA}
              name="Type of Booking"
              data={BookType}
            />
            {/* start of date */}
            <div className="w-full flex flex-col">
              <label className="text-sm text-neutral-600 mb-[6px] cursor-pointer min-w-max ">
                Booked Date
              </label>
              <div className="w-full ">
                <AdminDateInput
                  date={date}
                  setDate={setDate}
                  className="h-[50px]"
                />
              </div>
            </div>
            {/* enf of date */}
            {/* start of date */}
            <div className="w-full flex flex-col">
              <label className="text-sm text-neutral-600 mb-[6px] cursor-pointer ">
                Booked Time
              </label>
              <div className="w-full ">
                <AdminTimeInput
                  time={time}
                  setTime={setTime}
                  height="h-[50px]"
                />
              </div>
            </div>
            {/* enf of date */}
          </div>
          <div className="flex w-4/12 mx-auto bottom-[73px] absolute left-0 right-0 ">
            <Btn
              active={true}
              onClick={handleSubmit}
              name="Send"
              loading={tournament.loading}
            />
          </div>
        </div>
      </div>
      <ReUseModal open={open} setOpen={setOpen}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-cutomBlack text-[22px] font-bold text-center">
          Court Booked
        </h1>
        <p className="text-center text-sm w-10/12 mx-auto text-neutral-500 ">
          You’ve successfully booked a court for a player
        </p>
        <Btn
          active={true}
          name="Continue"
          onClick={() => {
            setOpen(false);
          }}
          className="mt-[25px] mb-3"
        />
      </ReUseModal>
    </DashboardLayout>
  );
};

export default BookCourt;
