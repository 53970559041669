import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Award } from "../../assets/svgs/buddies";
const RecentMatches = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full md:hidden flex flex-col px-4 py-6">
      <div className="flex justify-between mt-4 items-center">
        <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
        <p className="text-[#303030] font-bold text-[15px] ">Recent Matches</p>
        <div className="more-shadow opacity-0"></div>
      </div>
      <div className="flex flex-col space-y-3 mt-8">
        {/* start of a border */}
        <div className="flex rounded-2xl border border-border p-4 flex-col">
          {/* head session */}
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <p className="flex text-[#2c2c2c] text-sm font-semibold ">
                Brender Miles{" "}
                <span>
                  <Award />
                </span>{" "}
              </p>
              <p className="text-[#acacac] text-[10px]">Challenger</p>
            </div>
            <div className="rounded-2xl h-[34px] px-4 text-[#7DB00F]  flex justify-center items-center bg-[#F1F5E9] text-xs">
              3 - 6, 6 -3, 6 -3
            </div>
          </div>
          {/* tail session */}
          <div className="flex items-center justify-between mt-2">
            <div className="flex flex-col">
              <p className="flex text-[#2c2c2c] text-sm font-semibold ">
                James Thompson
              </p>
              <p className="text-[#acacac] text-[10px]">Challenged</p>
            </div>
            <div className="flex flex-col">
              <p className="text-[10px] text-[#7B7B7B] font-semibold">
                Sun. 12 June 2022
              </p>
              <p className="text-[10px] text-[#7B7B7B] ">7:00pm wat</p>
            </div>
          </div>
        </div>

        {/* end of a border */}
        {/* start of a border */}
        <div className="flex rounded-2xl border border-border p-4 flex-col">
          {/* head session */}
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <p className="flex text-[#2c2c2c] text-sm font-semibold ">
                Brender Miles{" "}
                <span>
                  <Award />
                </span>{" "}
              </p>
              <p className="text-[#acacac] text-[10px]">Challenger</p>
            </div>
            <div className="rounded-2xl h-[34px] px-4 text-[#7DB00F]  flex justify-center items-center bg-[#F1F5E9] text-xs">
              3 - 6, 6 -3, 6 -3
            </div>
          </div>
          {/* tail session */}
          <div className="flex items-center justify-between mt-2">
            <div className="flex flex-col">
              <p className="flex text-[#2c2c2c] text-sm font-semibold ">
                James Thompson
              </p>
              <p className="text-[#acacac] text-[10px]">Challenged</p>
            </div>
            <div className="flex flex-col">
              <p className="text-[10px] text-[#7B7B7B] font-semibold">
                Sun. 12 June 2022
              </p>
              <p className="text-[10px] text-[#7B7B7B] ">7:00pm wat</p>
            </div>
          </div>
        </div>

        {/* end of a border */}
        {/* start of a border */}
        <div className="flex rounded-2xl border border-border p-4 flex-col">
          {/* head session */}
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <p className="flex text-[#2c2c2c] text-sm font-semibold ">
                Brender Miles{" "}
                <span>
                  <Award />
                </span>{" "}
              </p>
              <p className="text-[#acacac] text-[10px]">Challenger</p>
            </div>
            <div className="rounded-2xl h-[34px] px-4 text-[#7DB00F]  flex justify-center items-center bg-[#F1F5E9] text-xs">
              3 - 6, 6 -3, 6 -3
            </div>
          </div>
          {/* tail session */}
          <div className="flex items-center justify-between mt-2">
            <div className="flex flex-col">
              <p className="flex text-[#2c2c2c] text-sm font-semibold ">
                James Thompson
              </p>
              <p className="text-[#acacac] text-[10px]">Challenged</p>
            </div>
            <div className="flex flex-col">
              <p className="text-[10px] text-[#7B7B7B] font-semibold">
                Sun. 12 June 2022
              </p>
              <p className="text-[10px] text-[#7B7B7B] ">7:00pm wat</p>
            </div>
          </div>
        </div>

        {/* end of a border */}
        {/* start of a border */}
        <div className="flex rounded-2xl border border-border p-4 flex-col">
          {/* head session */}
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <p className="flex text-[#2c2c2c] text-sm font-semibold ">
                Brender Miles{" "}
                <span>
                  <Award />
                </span>{" "}
              </p>
              <p className="text-[#acacac] text-[10px]">Challenger</p>
            </div>
            <div className="rounded-2xl h-[34px] px-4 text-[#7DB00F]  flex justify-center items-center bg-[#F1F5E9] text-xs">
              3 - 6, 6 -3, 6 -3
            </div>
          </div>
          {/* tail session */}
          <div className="flex items-center justify-between mt-2">
            <div className="flex flex-col">
              <p className="flex text-[#2c2c2c] text-sm font-semibold ">
                James Thompson
              </p>
              <p className="text-[#acacac] text-[10px]">Challenged</p>
            </div>
            <div className="flex flex-col">
              <p className="text-[10px] text-[#7B7B7B] font-semibold">
                Sun. 12 June 2022
              </p>
              <p className="text-[10px] text-[#7B7B7B] ">7:00pm wat</p>
            </div>
          </div>
        </div>

        {/* end of a border */}
        {/* start of a border */}
        <div className="flex rounded-2xl border border-border p-4 flex-col">
          {/* head session */}
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <p className="flex text-[#2c2c2c] text-sm font-semibold ">
                Brender Miles{" "}
                <span>
                  <Award />
                </span>{" "}
              </p>
              <p className="text-[#acacac] text-[10px]">Challenger</p>
            </div>
            <div className="rounded-2xl h-[34px] px-4 text-[#7DB00F]  flex justify-center items-center bg-[#F1F5E9] text-xs">
              3 - 6, 6 -3, 6 -3
            </div>
          </div>
          {/* tail session */}
          <div className="flex items-center justify-between mt-2">
            <div className="flex flex-col">
              <p className="flex text-[#2c2c2c] text-sm font-semibold ">
                James Thompson
              </p>
              <p className="text-[#acacac] text-[10px]">Challenged</p>
            </div>
            <div className="flex flex-col">
              <p className="text-[10px] text-[#7B7B7B] font-semibold">
                Sun. 12 June 2022
              </p>
              <p className="text-[10px] text-[#7B7B7B] ">7:00pm wat</p>
            </div>
          </div>
        </div>

        {/* end of a border */}
        {/* start of a border */}
        <div className="flex rounded-2xl border border-border p-4 flex-col">
          {/* head session */}
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <p className="flex text-[#2c2c2c] text-sm font-semibold ">
                Brender Miles{" "}
                <span>
                  <Award />
                </span>{" "}
              </p>
              <p className="text-[#acacac] text-[10px]">Challenger</p>
            </div>
            <div className="rounded-2xl h-[34px] px-4 text-[#7DB00F]  flex justify-center items-center bg-[#F1F5E9] text-xs">
              3 - 6, 6 -3, 6 -3
            </div>
          </div>
          {/* tail session */}
          <div className="flex items-center justify-between mt-2">
            <div className="flex flex-col">
              <p className="flex text-[#2c2c2c] text-sm font-semibold ">
                James Thompson
              </p>
              <p className="text-[#acacac] text-[10px]">Challenged</p>
            </div>
            <div className="flex flex-col">
              <p className="text-[10px] text-[#7B7B7B] font-semibold">
                Sun. 12 June 2022
              </p>
              <p className="text-[10px] text-[#7B7B7B] ">7:00pm wat</p>
            </div>
          </div>
        </div>

        {/* end of a border */}
        {/* start of a border */}
        <div className="flex rounded-2xl border border-border p-4 flex-col">
          {/* head session */}
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <p className="flex text-[#2c2c2c] text-sm font-semibold ">
                Brender Miles{" "}
                <span>
                  <Award />
                </span>{" "}
              </p>
              <p className="text-[#acacac] text-[10px]">Challenger</p>
            </div>
            <div className="rounded-2xl h-[34px] px-4 text-[#7DB00F]  flex justify-center items-center bg-[#F1F5E9] text-xs">
              3 - 6, 6 -3, 6 -3
            </div>
          </div>
          {/* tail session */}
          <div className="flex items-center justify-between mt-2">
            <div className="flex flex-col">
              <p className="flex text-[#2c2c2c] text-sm font-semibold ">
                James Thompson
              </p>
              <p className="text-[#acacac] text-[10px]">Challenged</p>
            </div>
            <div className="flex flex-col">
              <p className="text-[10px] text-[#7B7B7B] font-semibold">
                Sun. 12 June 2022
              </p>
              <p className="text-[10px] text-[#7B7B7B] ">7:00pm wat</p>
            </div>
          </div>
        </div>

        {/* end of a border */}
        {/* start of a border */}
        <div className="flex rounded-2xl border border-border p-4 flex-col">
          {/* head session */}
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <p className="flex text-[#2c2c2c] text-sm font-semibold ">
                Brender Miles{" "}
                <span>
                  <Award />
                </span>{" "}
              </p>
              <p className="text-[#acacac] text-[10px]">Challenger</p>
            </div>
            <div className="rounded-2xl h-[34px] px-4 text-[#7DB00F]  flex justify-center items-center bg-[#F1F5E9] text-xs">
              3 - 6, 6 -3, 6 -3
            </div>
          </div>
          {/* tail session */}
          <div className="flex items-center justify-between mt-2">
            <div className="flex flex-col">
              <p className="flex text-[#2c2c2c] text-sm font-semibold ">
                James Thompson
              </p>
              <p className="text-[#acacac] text-[10px]">Challenged</p>
            </div>
            <div className="flex flex-col">
              <p className="text-[10px] text-[#7B7B7B] font-semibold">
                Sun. 12 June 2022
              </p>
              <p className="text-[10px] text-[#7B7B7B] ">7:00pm wat</p>
            </div>
          </div>
        </div>

        {/* end of a border */}
        {/* start of a border */}
        <div className="flex rounded-2xl border border-border p-4 flex-col">
          {/* head session */}
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <p className="flex text-[#2c2c2c] text-sm font-semibold ">
                Brender Miles{" "}
                <span>
                  <Award />
                </span>{" "}
              </p>
              <p className="text-[#acacac] text-[10px]">Challenger</p>
            </div>
            <div className="rounded-2xl h-[34px] px-4 text-[#7DB00F]  flex justify-center items-center bg-[#F1F5E9] text-xs">
              3 - 6, 6 -3, 6 -3
            </div>
          </div>
          {/* tail session */}
          <div className="flex items-center justify-between mt-2">
            <div className="flex flex-col">
              <p className="flex text-[#2c2c2c] text-sm font-semibold ">
                James Thompson
              </p>
              <p className="text-[#acacac] text-[10px]">Challenged</p>
            </div>
            <div className="flex flex-col">
              <p className="text-[10px] text-[#7B7B7B] font-semibold">
                Sun. 12 June 2022
              </p>
              <p className="text-[10px] text-[#7B7B7B] ">7:00pm wat</p>
            </div>
          </div>
        </div>

        {/* end of a border */}
      </div>
    </div>
  );
};

export default RecentMatches;
