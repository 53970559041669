import { useState, useEffect } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { BigText, ModalSub, Ptext } from "../../../components/Text";
import { NewPasswordInput } from "../../../components/Input";
import { Btn } from "../../../components/Button";
import ReUseModal from "../../../components/Modal/ReUseModal";
import { Mark } from "../../../assets/svgs/Icons";
import {
  verifyNewPassword,
  resetNewPasswordSuccess,
  resetForgetToken,
} from "../../../features/auth/AuthSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { toast } from "react-toastify";
const NewPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const [password, setPassword] = useState("");
  const [confrimPassword, setConfirmPassword] = useState("");
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    password && confrimPassword ? setActive(true) : setActive(false);
  }, [password, confrimPassword]);

  const handleSubmit = () => {
    if (password !== confrimPassword) {
      toast.error("Password doesnt match");
    } else {
      dispatch(verifyNewPassword({ password: password }));
    }
  };

  useEffect(() => {
    if (auth.newPasswordSuccess) {
      setOpen(true);
      setTimeout(() => {
        dispatch(resetNewPasswordSuccess());
        dispatch(resetForgetToken());
        navigate("/login");
      }, 1500);
    }
  }, [auth.newPasswordSuccess]);

  return (
    <>
      {/* <div className=" flex-col lg:flex hidden mt-6 px-4 justify-center h-screen w-1/2 mx-auto">
        <h1 className="font-bold text-customBlack text-xl text-center mb-2 ">
          Oops, this page is only designed for mobile devices.
        </h1>
        <p className="text-neutral-700 text-base  ">
          Please switch to a smaller screen, such as a smartphone or tablet, to
          enjoy this feature. We appreciate your cooperation.
        </p>
      </div> */}
      <div className="w-full flex flex-col p-4 relative h-screen md:max-w-[428px] md:mx-auto md:h-screen">
        <AiOutlineArrowLeft
          onClick={() => navigate(-1)}
          className="mt-20 text-2xl"
        />
        <div className="flex flex-col">
          <BigText text="Create New Password" className="mt-8" />
          <Ptext
            text="Your new password must be different from the one you used before "
            className="mb-[37px] mt-2"
          />
          <NewPasswordInput
            onChange={(e: any) => setPassword(e.target.value)}
            placeholder="Enter Password"
            classes="my-6"
            required
          />
          <NewPasswordInput
            onChange={(e: any) => setConfirmPassword(e.target.value)}
            placeholder="Re-enter Password"
            required
          />
          <div className="mt-12 p-4">
            <Btn
              name="Confirm"
              active={active}
              onClick={handleSubmit}
              loading={auth.loading}
            />
          </div>
        </div>
        <ReUseModal open={open} setOpen={setOpen}>
          <div className="w-full flex flex-col justify-center items-center py-8">
            <Mark />
            <p className="text-customBlack font-bold text-lg text-center">
              Password Updated
            </p>
            <ModalSub
              className="text-center mt-1"
              text="Your password has been successfully updated"
            />
            <div className="w-6/12 mx-auto">
              <Btn
                active={active}
                onClick={() => navigate("/login")}
                name="Continue"
                className="mt-3 py-8"
              />
            </div>
          </div>
        </ReUseModal>
      </div>
    </>
  );
};

export default NewPassword;
