import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../../Layouts/DashboardLayout";
import { NavigationArrow } from "../../../assets/svgs/admin";
import LadderTable from "../../../components/Tables/Ladder/LadderTable";

const LadderTournament = () => {
  const navigate = useNavigate();

  return (
    <div>
      <DashboardLayout current={1}>
        <div className="w-full flex flex-col bg-[#f4f4f4] h-full p-[30px] 2xl:p-8 2xl:pr-[50px] ">
          <div className="flex w-fit" onClick={() => navigate(-1)}>
            <NavigationArrow />
          </div>
          <LadderTable />
        </div>
      </DashboardLayout>
    </div>
  );
};

export default LadderTournament;
