import { StopWatch } from "../../assets/svgs/Icons";
import { CalenderIcon } from "../../assets/svgs/courts";
import React, { useState, useRef } from "react";
interface dateProps {
  date: string;
  setDate: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
  height?: string;
}
interface timeProps {
  time: string;
  setTime: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
  height?: string;
}
export const UserDateInput = ({ date, setDate, className }: dateProps) => {
  const inputRef = useRef<any>(null);
  const onCalenderIconClick = () => {
    if (inputRef?.current) {
      inputRef.current.focus();
      inputRef?.current?.showPicker();
    }
  };
  return (
    <div
      className={`relative flex items-center bg-neutral-200 h-[50px] rounded-[8px]  ${className}`}
    >
      <input
        type="date"
        name="date"
        className=" opacity-0  "
        value={date}
        onChange={(e: any) => setDate(e.target?.value)}
        ref={inputRef}
      />
      <div className="flex items-center  justify-between w-full px-6 absolute inset-0">
        <p className="text-[#afafaf] text-sm font-bold">
          {date ? date : "Select Date"}
        </p>
        <span className="cursor-pointer" onClick={onCalenderIconClick}>
          <CalenderIcon />
        </span>
      </div>
    </div>
  );
};
export const UserTimeInput = ({ time, setTime, className }: timeProps) => {
  const inputRef = useRef<any>(null);
  const onCalenderIconClick = () => {
    if (inputRef?.current) {
      inputRef.current.focus();
      inputRef?.current?.showPicker();
    }
  };
  return (
    <div
      className={`relative flex items-center bg-neutral-200 h-[50px] rounded-[8px]  ${className}`}
    >
      <input
        type="time"
        name="time"
        className=" opacity-0  "
        value={time}
        onChange={(e: any) => setTime(e.target?.value)}
        ref={inputRef}
      />
      <div className="flex items-center  justify-between w-full px-6 absolute inset-0">
        <p className="text-[#afafaf] text-sm font-bold">
          {time ? time : "Select Time"}
        </p>
        <span className="cursor-pointer" onClick={onCalenderIconClick}>
          <StopWatch />
        </span>
      </div>
    </div>
  );
};
export const AdminDateInput = ({
  date,
  setDate,
  className,
  height,
}: dateProps) => {
  const inputRef = useRef<any>(null);
  const onCalenderIconClick = () => {
    if (inputRef?.current) {
      inputRef.current.focus();
      inputRef?.current?.showPicker();
    }
  };
  return (
    <div
      className={`relative outline-none rounded-[5px] border border-[#AFAFAF] flex  items-center px-4  w-full  h-[30px]  placeholder:text-deepAsh text-customBlack  ${className} ${
        height ? height : "h-[30px]"
      }`}
    >
      <input
        type="date"
        name="date"
        className=" opacity-0  "
        value={date}
        onChange={(e: any) => setDate(e.target?.value)}
        ref={inputRef}
      />
      <div className="flex items-center  justify-between w-full px-3 absolute inset-0">
        <p
          className={` text-sm  ${
            date ? "text-customBlack" : "text-[#afafaf]"
          }`}
        >
          {date ? date : "Select Date"}
        </p>
        <span className="cursor-pointer" onClick={onCalenderIconClick}>
          <CalenderIcon />
        </span>
      </div>
    </div>
  );
};
export const AdminTimeInput = ({
  time,
  setTime,
  height,
  className,
}: timeProps) => {
  const inputRef = useRef<any>(null);
  const onCalenderIconClick = () => {
    if (inputRef?.current) {
      inputRef.current.focus();
      inputRef?.current?.showPicker();
    }
  };
  return (
    <div
      className={`relative outline-none rounded-[5px] border border-[#AFAFAF] flex  items-center px-4  w-full  h-[30px]  placeholder:text-deepAsh text-customBlack  ${className} ${
        height ? height : "h-[30px]"
      }`}
    >
      <input
        type="time"
        name="time"
        className=" opacity-0"
        value={time}
        onChange={(e: any) => setTime(e.target?.value)}
        ref={inputRef}
      />
      <div className="flex items-center  justify-between w-full px-3 absolute inset-0">
        <p
          className={` text-sm  ${
            time ? "text-customBlack" : "text-[#afafaf]"
          }`}
        >
          {time ? time : "Select Time"}
        </p>
        <span className="cursor-pointer" onClick={onCalenderIconClick}>
          <StopWatch />
        </span>
      </div>
    </div>
  );
};
export const AdminDateTimeInput = ({
  dateTime,
  setDateTime,
  className,
  onChange,
}: any) => {
  const inputRef = useRef<any>(null);
  const onCalenderIconClick = () => {
    if (inputRef?.current) {
      inputRef.current.focus();
      inputRef?.current?.showPicker();
    }
  };
  console.log({ dateTime });
  return (
    <div
      className={`relative outline-none rounded-[5px] border border-[#AFAFAF] flex  items-center px-4  w-full  h-[30px]  placeholder:text-deepAsh text-customBlack  ${className}`}
    >
      <input
        type="datetime-local"
        name="date"
        className=" opacity-0 "
        value={dateTime}
        onChange={onChange}
        ref={inputRef}
      />
      <div className="flex items-center  justify-between w-full px-3 absolute inset-0">
        <p
          className={` text-sm  ${
            dateTime ? "text-customBlack" : "text-[#afafaf]"
          }`}
        >
          {dateTime ? dateTime : "Select Date & Time"}
        </p>
        <span className="cursor-pointer" onClick={onCalenderIconClick}>
          <CalenderIcon />
        </span>
      </div>
    </div>
  );
};
export const UserDateTimeInput = ({
  dateTime,
  setDateTime,
  className,
  onChange,
}: any) => {
  const inputRef = useRef<any>(null);
  const onCalenderIconClick = () => {
    if (inputRef?.current) {
      inputRef.current.focus();
      inputRef?.current?.showPicker();
    }
  };
  return (
    <div
      className={`relative outline-none rounded-[12px] bg-[#f4f4f4]  flex  items-center px-4  w-full  h-[50px]  placeholder:text-deepAsh text-customBlack  ${className}`}
    >
      <input
        type="datetime-local"
        name="date"
        className=" opacity-0 "
        value={dateTime}
        onChange={onChange}
        ref={inputRef}
      />
      <div className="flex items-center  justify-between w-full px-3 absolute inset-0">
        <p
          className={` text-sm  ${
            dateTime ? "text-customBlack" : "text-[#afafaf]"
          }`}
        >
          {dateTime ? dateTime : "Select Date & Time"}
        </p>
        <span className="cursor-pointer" onClick={onCalenderIconClick}>
          <CalenderIcon />
        </span>
      </div>
    </div>
  );
};
