export const FourSquareIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#FFEECC" />
      <path
        d="M28.0039 14.95V25.05C27.8439 25.02 27.6739 25 27.5039 25C26.1239 25 25.0039 26.12 25.0039 27.5C25.0039 27.67 25.0239 27.84 25.0539 28H14.9539C14.9839 27.84 15.0039 27.67 15.0039 27.5C15.0039 26.12 13.8839 25 12.5039 25C12.3339 25 12.1639 25.02 12.0039 25.05V14.95C12.1639 14.98 12.3339 15 12.5039 15C13.8839 15 15.0039 13.88 15.0039 12.5C15.0039 12.33 14.9839 12.16 14.9539 12H25.0539C25.0239 12.16 25.0039 12.33 25.0039 12.5C25.0039 13.88 26.1239 15 27.5039 15C27.6739 15 27.8439 14.98 28.0039 14.95Z"
        stroke="#FFA800"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 12.502C15 13.882 13.88 15.002 12.5 15.002C12.33 15.002 12.16 14.982 12 14.952C10.86 14.722 10 13.712 10 12.502C10 11.122 11.12 10.002 12.5 10.002C13.71 10.002 14.72 10.862 14.95 12.002C14.98 12.162 15 12.332 15 12.502Z"
        stroke="#FFA800"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30 12.502C30 13.712 29.14 14.722 28 14.952C27.84 14.982 27.67 15.002 27.5 15.002C26.12 15.002 25 13.882 25 12.502C25 12.332 25.02 12.162 25.05 12.002C25.28 10.862 26.29 10.002 27.5 10.002C28.88 10.002 30 11.122 30 12.502Z"
        stroke="#FFA800"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 27.502C15 27.672 14.98 27.842 14.95 28.002C14.72 29.142 13.71 30.002 12.5 30.002C11.12 30.002 10 28.882 10 27.502C10 26.292 10.86 25.282 12 25.052C12.16 25.022 12.33 25.002 12.5 25.002C13.88 25.002 15 26.122 15 27.502Z"
        stroke="#FFA800"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30 27.502C30 28.882 28.88 30.002 27.5 30.002C26.29 30.002 25.28 29.142 25.05 28.002C25.02 27.842 25 27.672 25 27.502C25 26.122 26.12 25.002 27.5 25.002C27.67 25.002 27.84 25.022 28 25.052C29.14 25.282 30 26.292 30 27.502Z"
        stroke="#FFA800"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const ChallengeInvitationIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#FFEECC" />
      <g clip-path="url(#clip0_4067_57097)">
        <path
          d="M17 13H15C14.4696 13 13.9609 13.2107 13.5858 13.5858C13.2107 13.9609 13 14.4696 13 15V27C13 27.5304 13.2107 28.0391 13.5858 28.4142C13.9609 28.7893 14.4696 29 15 29H25C25.5304 29 26.0391 28.7893 26.4142 28.4142C26.7893 28.0391 27 27.5304 27 27V15C27 14.4696 26.7893 13.9609 26.4142 13.5858C26.0391 13.2107 25.5304 13 25 13H23"
          stroke="#FFA800"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21 11H19C17.8954 11 17 11.8954 17 13C17 14.1046 17.8954 15 19 15H21C22.1046 15 23 14.1046 23 13C23 11.8954 22.1046 11 21 11Z"
          stroke="#FFA800"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17 20H17.01"
          stroke="#FFA800"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21 20H23"
          stroke="#FFA800"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17 24H17.01"
          stroke="#FFA800"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21 24H23"
          stroke="#FFA800"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4067_57097">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const ActiveLadderIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#FFEECC" />
      <path
        d="M19.9997 10.668V14.168M15.333 10.668V17.668M24.6663 10.668V17.668"
        stroke="#FFA800"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 27.582L16.5 29.332L17.0833 25.2487L14.75 22.9154L18.25 22.332L20 18.832L21.75 22.332L25.25 22.9154L22.9167 25.2487L23.5 29.332L20 27.582Z"
        stroke="#FFA800"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const MatchScoreIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#FFEECC" />
      <g clip-path="url(#clip0_4067_56923)">
        <path
          d="M17 13H15C14.4696 13 13.9609 13.2107 13.5858 13.5858C13.2107 13.9609 13 14.4696 13 15V27C13 27.5304 13.2107 28.0391 13.5858 28.4142C13.9609 28.7893 14.4696 29 15 29H25C25.5304 29 26.0391 28.7893 26.4142 28.4142C26.7893 28.0391 27 27.5304 27 27V15C27 14.4696 26.7893 13.9609 26.4142 13.5858C26.0391 13.2107 25.5304 13 25 13H23"
          fill="#FFA800"
        />
        <path
          d="M17 13H15C14.4696 13 13.9609 13.2107 13.5858 13.5858C13.2107 13.9609 13 14.4696 13 15V27C13 27.5304 13.2107 28.0391 13.5858 28.4142C13.9609 28.7893 14.4696 29 15 29H25C25.5304 29 26.0391 28.7893 26.4142 28.4142C26.7893 28.0391 27 27.5304 27 27V15C27 14.4696 26.7893 13.9609 26.4142 13.5858C26.0391 13.2107 25.5304 13 25 13H23"
          stroke="#FFA800"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21 11H19C17.8954 11 17 11.8954 17 13C17 14.1046 17.8954 15 19 15H21C22.1046 15 23 14.1046 23 13C23 11.8954 22.1046 11 21 11Z"
          stroke="#FFE2AA"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17 20H17.01"
          stroke="#FFE2AA"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21 20H23"
          stroke="#FFE2AA"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17 24H17.01"
          stroke="#FFE2AA"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21 24H23"
          stroke="#FFE2AA"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4067_56923">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const MyMatchIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#FFEECC" />
      <g clip-path="url(#clip0_4067_56910)">
        <path
          d="M22.5218 18.3581C24.4782 18.3581 26.0642 16.7722 26.0642 14.8158C26.0642 12.8594 24.4782 11.2734 22.5218 11.2734C20.5655 11.2734 18.9795 12.8594 18.9795 14.8158C18.9795 16.7722 20.5655 18.3581 22.5218 18.3581Z"
          fill="#FFD480"
        />
        <path
          d="M17.209 25.001V23.2298C17.209 22.2904 17.5822 21.3893 18.2465 20.725C18.9108 20.0607 19.8118 19.6875 20.7513 19.6875H24.2937C25.2332 19.6875 26.1342 20.0607 26.7985 20.725C27.4628 21.3893 27.836 22.2904 27.836 23.2298V25.001"
          fill="#FFD480"
        />
        <path
          d="M17.8724 19.2581C20.3259 19.2581 22.3148 17.2692 22.3148 14.8158C22.3148 12.3623 20.3259 10.3734 17.8724 10.3734C15.419 10.3734 13.4301 12.3623 13.4301 14.8158C13.4301 17.2692 15.419 19.2581 17.8724 19.2581Z"
          fill="#FFA800"
          stroke="#FFEECC"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <mask
          id="path-5-outside-1_4067_56910"
          maskUnits="userSpaceOnUse"
          x="10.5596"
          y="17.6875"
          width="15"
          height="10"
          fill="black"
        >
          <rect fill="white" x="10.5596" y="17.6875" width="15" height="10" />
          <path d="M12.5596 25.001V23.2298C12.5596 22.2904 12.9328 21.3893 13.5971 20.725C14.2614 20.0607 15.1624 19.6875 16.1019 19.6875H19.6443C20.5837 19.6875 21.4847 20.0607 22.1491 20.725C22.8134 21.3893 23.1866 22.2904 23.1866 23.2298V25.001" />
        </mask>
        <path
          d="M12.5596 25.001V23.2298C12.5596 22.2904 12.9328 21.3893 13.5971 20.725C14.2614 20.0607 15.1624 19.6875 16.1019 19.6875H19.6443C20.5837 19.6875 21.4847 20.0607 22.1491 20.725C22.8134 21.3893 23.1866 22.2904 23.1866 23.2298V25.001"
          fill="#FFA800"
        />
        <path
          d="M10.7596 25.001C10.7596 25.9951 11.5655 26.801 12.5596 26.801C13.5537 26.801 14.3596 25.9951 14.3596 25.001H10.7596ZM16.1019 19.6875V17.8875V19.6875ZM19.6443 19.6875V17.8875V19.6875ZM23.1866 23.2298H24.9866H23.1866ZM21.3866 25.001C21.3866 25.9951 22.1925 26.801 23.1866 26.801C24.1807 26.801 24.9866 25.9951 24.9866 25.001H21.3866ZM14.3596 25.001V23.2298H10.7596V25.001H14.3596ZM14.3596 23.2298C14.3596 22.7677 14.5431 22.3246 14.8699 21.9978L12.3243 19.4522C11.3224 20.4541 10.7596 21.813 10.7596 23.2298H14.3596ZM14.8699 21.9978C15.1966 21.6711 15.6398 21.4875 16.1019 21.4875V17.8875C14.685 17.8875 13.3262 18.4504 12.3243 19.4522L14.8699 21.9978ZM16.1019 21.4875H19.6443V17.8875H16.1019V21.4875ZM19.6443 21.4875C20.1063 21.4875 20.5495 21.6711 20.8763 21.9978L23.4219 19.4522C22.42 18.4504 21.0611 17.8875 19.6443 17.8875V21.4875ZM20.8763 21.9978C21.203 22.3246 21.3866 22.7677 21.3866 23.2298H24.9866C24.9866 21.813 24.4237 20.4541 23.4219 19.4522L20.8763 21.9978ZM21.3866 23.2298V25.001H24.9866V23.2298H21.3866Z"
          fill="#FFEECC"
          mask="url(#path-5-outside-1_4067_56910)"
        />
      </g>
      <defs>
        <clipPath id="clip0_4067_56910">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const AllInvitationIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#FFEECC" />
      <path
        d="M27 13H13C11.8954 13 11 13.8954 11 15V25C11 26.1046 11.8954 27 13 27H27C28.1046 27 29 26.1046 29 25V15C29 13.8954 28.1046 13 27 13Z"
        fill="#FFE2AA"
        stroke="#FFA800"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 23L11 23L11 17L14 17L14 23Z"
        fill="#FFE2AA"
        stroke="#FFA800"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26 17H29V23H26V17Z"
        fill="#FFE2AA"
        stroke="#FFA800"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 13V27"
        stroke="#FFA800"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 23C21.6569 23 23 21.6569 23 20C23 18.3431 21.6569 17 20 17C18.3431 17 17 18.3431 17 20C17 21.6569 18.3431 23 20 23Z"
        fill="#FFE2AA"
      />
    </svg>
  );
};
