import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";

import { useNavigate } from "react-router-dom";
import { NameInput, EmailInput } from "../../../../components/Input";
import { Btn } from "../../../../components/Button";
import ReUseModal from "../../../../components/Modal/ReUseModal";

import sentMark from "../../../../assets/svgs/sent-mark.svg";
import {
  restoreDefault,
  sendEmailInvite,
} from "../../../../features/auth/playerslice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
const InvitePlayerExternal = () => {
  const navigate = useNavigate();
  const player = useAppSelector((state) => state?.player);
  const dispatch = useAppDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (name && email) {
      setActive(true);
    } else setActive(false);
  }, [name, email]);

  const handleSubmit = () => {
    const data = {
      name: name,
      email: email,
    };
    dispatch(sendEmailInvite(data));
  };
  useEffect(() => {
    if (player?.sendInvitationSuccess) {
      setOpen(true);
      dispatch(restoreDefault());
    }
  }, [player?.sendInvitationSuccess]);

  return (
    <div>
      <>
        {/* <div className=" flex-col lg:flex hidden mt-6 px-4 justify-center h-screen w-1/2 mx-auto">
          <h1 className="font-bold text-customBlack text-xl text-center mb-2 ">
            Oops, this page is only designed for mobile devices.
          </h1>
          <p className="text-neutral-700 text-base  ">
            Please switch to a smaller screen, such as a smartphone or tablet,
            to enjoy this feature. We appreciate your cooperation.
          </p>
        </div> */}
        <div className="w-full flex flex-col px-4 py-6 relative h-screen md:max-w-[428px] md:mx-auto md:h-screen">
          <div className="flex justify-between mt-6 items-center">
            <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
            <p className="text-[#303030] font-bold text-[15px] ">
              Invite a Friend
            </p>
            <div className=""></div>
          </div>
          <p className="text-neutral-500 text-sm mt-6 leading-[24px] max-w-[363px] ">
            Send a match invitation to a friend that is not in your club
          </p>
          <div className="flex flex-col mt-6 gap-6">
            <NameInput
              onChange={(e: any) => setName(e.target.value)}
              required
              placeholder="Full name"
            />

            <EmailInput
              onChange={(e: any) => setEmail(e.target.value)}
              required
              placeholder="Enter your Email"
            />
          </div>

          <div className="w-full absolute bottom-8 right-0 left-0 mx-auto px-4">
            <Btn
              active={active}
              name="Send Invite"
              loading={player?.loading}
              onClick={handleSubmit}
            />
          </div>
        </div>
        <ReUseModal open={open} setOpen={setOpen}>
          <img src={sentMark} alt="sent" className="mx-auto mt-6" />
          <h1 className="text-customBlack text-[24px] font-bold text-center mt-3 ">
            Match Invitation Sent
          </h1>
          <p className="text-center w-full mx-auto text-neutral-500 ">
            You’ve successfully sent a match invitation
          </p>
          <Btn
            active={true}
            name="Close"
            onClick={() => {
              navigate(`/user/allplayers`);
            }}
            className="mt-[25px] mb-3"
          />
        </ReUseModal>
      </>
    </div>
  );
};

export default InvitePlayerExternal;
