import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import addNewSet from "../../../assets/svgs/add-new-set.svg";
import sentMark from "../../../assets/svgs/sent-mark.svg";
import {
  getMatchInTournament,
  resetAll,
  resetScoreSuccess,
  updateCompetitionMatchScore,
  updateMatchScore,
} from "../../../features/auth/TournamentSlice";
import { Btn } from "../../Button";
import ReUseModal from "../../Modal/ReUseModal";
import PrimarySelect from "../../Selects/PrimarySelect";
interface IPayloadData {
  id: string;
  data: {
    winner: string | undefined;
    [key: string]:
      | { challenger: string; challenged: string }
      | string
      | undefined;
  };
}

const CompetitionScoringBoard = () => {
  const dispatch = useAppDispatch();
  const tournament = useAppSelector((state) => state.tournament);
  const id = useParams();
  const [openSuccessModal, setOpenSuccesModal] = useState(false);

  const matches = tournament?.matchInTournament?.match;
  console.log(matches);

  useEffect(() => {
    dispatch(getMatchInTournament({ id: id.id }));
    dispatch(resetAll());
    dispatch(resetScoreSuccess());
  }, []);

  useEffect(() => {
    if (tournament.updateCompetitionMatchScoreSuccess) {
      setOpenSuccesModal(true);

      dispatch(resetAll());
    }
  }, [tournament.updateCompetitionMatchScoreSuccess]);

  return (
    <div className="bg-white rounded-[20px] flex flex-col  h-screen  mt-6 flow-hide  ">
      <div className="flex-grow">
        <div className="">
          <div className="p-5 flex justify-between">
            <p className="font-medium text-sm sm:text-md">Competition Scores</p>
          </div>
        </div>
        {/* breaker line */}
        <div className="w-full h-[1px] bg-[#cfcfcf] " />

        <table className="w-full flex flex-col border border-border bg-white  ">
          <thead className="w-full bg-[#f4f4f4] bg-opacity-40 ">
            <tr className="flex items-center h-[35px] pl-5">
              <th className="w-1/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-center">
                SN
              </th>
              <th className="w-3/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left">
                Challenger
              </th>
              <th className="w-3/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left">
                Challenged
              </th>
              <th className="w-3/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left">
                Scores
              </th>
              <th className="w-3/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left">
                Winner
              </th>
              <th className="w-3/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {matches?.map((item: any, index: any) => {
              return <SingleForm key={index} item={item} index={index} />;
            })}
          </tbody>
        </table>
      </div>

      <ReUseModal open={openSuccessModal} setOpen={setOpenSuccesModal}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-cutomBlack text-[24px] font-bold text-center">
          Score Updated
        </h1>
        <p className="text-center w-10/12 mx-auto text-neutral-500 ">
          Match Score updated successfully
        </p>
        <Btn
          active={true}
          name="Close"
          onClick={() => {
            setOpenSuccesModal(false);
            dispatch(getMatchInTournament({ id: id.id }));
          }}
          className="mt-[49px] mb-3"
        />
      </ReUseModal>
    </div>
  );
};

export default CompetitionScoringBoard;

const SingleForm = ({ item, index }: any) => {
  const dispatch = useAppDispatch();
  const tournament = useAppSelector((state) => state.tournament);
  const {
    challenged,
    challenger,
    challengerB,
    isDouble,
    challengedB,
    winner,
    setA,
    setB,
    setC,
    setD,
    setE,
  } = item;
  const [openEdit, setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);

  const [selected, setSelected] = useState<any>({});
  const [matchScoreSet, setMatchScoreSet] = useState(
    parseInt(setC?.challenger) > 0 ? 3 : 2
  );
  const [errorMaxSet, setErrorMaxSet] = useState("");

  const listofMatchSets = Array.from(
    { length: matchScoreSet },
    (_, i) => i + 1
  );

  const selectData = [
    {
      name: challenger?.firstname,
      id: challenger?._id,
    },
    {
      name: challenged?.firstname,
      id: challenged?._id,
    },
  ];

  const [oneChallenged, setOneChallenged] = useState(
    !setA?.challenged ? "0" : `${setA?.challenged}`
  );
  const [oneChallenger, setOneChallenger] = useState(
    !setA?.challenger ? "0" : `${setA?.challenger}`
  );

  const [twoChallenged, setTwoChallenged] = useState(
    !setB?.challenged ? "0" : `${setB?.challenged}`
  );
  const [twoChallenger, setTwoChallenger] = useState(
    !setB?.challenger ? "0" : `${setB?.challenger}`
  );

  const [threeChallenged, setThreeChallenged] = useState(
    !setC?.challenged ? "0" : `${setC?.challenged}`
  );
  const [threeChallenger, setThreeChallenger] = useState(
    !setC?.challenger ? "0" : `${setC?.challenger}`
  );

  const [fourthChallenged, setFourthChallenged] = useState(
    !setD?.challenged ? "0" : `${setD?.challenged}`
  );
  const [fourthChallenger, setFourthChallenger] = useState(
    !setD?.challenger ? "0" : `${setD?.challenger}`
  );

  const [fifthChallenged, setFifthChallenged] = useState(
    !setE?.challenged ? "0" : `${setE?.challenged}`
  );
  const [fifthChallenger, setFifthChallenger] = useState(
    !setE?.challenger ? "0" : `${setE?.challenger}`
  );

  useEffect(() => {
    if (openEdit) {
      setOneChallenged(!setA?.challenged ? "0" : `${setA?.challenged}`);
      setOneChallenger(!setA?.challenger ? "0" : `${setA?.challenger}`);

      setTwoChallenged(!setB?.challenged ? "0" : `${setB?.challenged}`);
      setTwoChallenger(!setB?.challenger ? "0" : `${setB?.challenger}`);

      setThreeChallenged(!setC?.challenged ? "0" : `${setC?.challenged}`);
      setThreeChallenger(!setC?.challenger ? "0" : `${setC?.challenger}`);

      setFourthChallenged(!setD?.challenged ? "0" : `${setD?.challenged}`);
      setFourthChallenger(!setD?.challenger ? "0" : `${setD?.challenger}`);

      setFifthChallenged(!setE?.challenged ? "0" : `${setE?.challenged}`);
      setFifthChallenger(!setE?.challenger ? "0" : `${setE?.challenger}`);

      setMatchScoreSet(parseInt(setC?.challenger) > 0 ? 3 : 2);
    }
  }, [openEdit]);

  useEffect(() => {
    if (tournament.updateMatchScoreSuccess) {
      // toast.success("updateSUCCES")
      setOpenEdit(false);
    }
  }, [tournament.updateMatchScoreSuccess]);

  const UpdateScore = () => {
    const payLoaddata: IPayloadData = {
      id: item._id,
      data: {
        winner: selected?.id,
        setA: {
          challenger: oneChallenger,
          challenged: oneChallenged,
        },
        setB: {
          challenger: twoChallenger,
          challenged: twoChallenged,
        },
      },
    };
    if (threeChallenged !== "0" || threeChallenger !== "0") {
      payLoaddata.data.setC = {
        challenger: threeChallenger,
        challenged: threeChallenged,
      };
    }
    if (fourthChallenged !== "0" || fourthChallenger !== "0") {
      payLoaddata.data.setD = {
        challenger: fourthChallenger,
        challenged: fourthChallenged,
      };
    }
    if (fifthChallenged !== "0" || fifthChallenger !== "0") {
      payLoaddata.data.setE = {
        challenger: fifthChallenger,
        challenged: fifthChallenged,
      };
    }

    dispatch(updateCompetitionMatchScore(payLoaddata));
  };

  const addMatchSet = () => {
    if (matchScoreSet >= 5) {
      setErrorMaxSet("match set limit reached");
      setTimeout(function () {
        setErrorMaxSet("");
      }, 3000);
      return;
    }
    setMatchScoreSet(matchScoreSet + 1);
  };

  const handleChallengedOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    item: number
  ) => {
    const inputValue = e.target.value;
    if (inputValue.length > 1) {
      if (item === 1) {
        setOneChallenged(inputValue.toString().substring(0, 1));
      } else if (item === 2) {
        setTwoChallenged(inputValue.toString().substring(0, 1));
      } else if (item === 3) {
        setThreeChallenged(inputValue.toString().substring(0, 1));
      } else if (item === 4) {
        setFourthChallenged(inputValue.toString().substring(0, 1));
      } else if (item === 5) {
        setFifthChallenged(inputValue.toString().substring(0, 1));
      }
      return;
    }

    if (item === 1) {
      setOneChallenged(inputValue);
    }
    if (item === 2) {
      setTwoChallenged(inputValue);
    }
    if (item === 3) {
      setThreeChallenged(inputValue);
    }
    if (item === 4) {
      setFourthChallenged(inputValue);
    }
    if (item === 5) {
      setFifthChallenged(inputValue);
    }
  };
  const handleChallengerOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    item: number
  ) => {
    const inputValue = e.target.value;
    if (inputValue.length > 1) {
      if (item === 1) {
        setOneChallenger(inputValue.toString().substring(0, 1));
      } else if (item === 2) {
        setTwoChallenger(inputValue.toString().substring(0, 1));
      } else if (item === 3) {
        setThreeChallenger(inputValue.toString().substring(0, 1));
      } else if (item === 4) {
        setFourthChallenger(inputValue.toString().substring(0, 1));
      } else if (item === 5) {
        setFifthChallenger(inputValue.toString().substring(0, 1));
      }

      return;
    }
    if (item === 1) {
      setOneChallenger(inputValue);
    }
    if (item === 2) {
      setTwoChallenger(inputValue);
    }
    if (item === 3) {
      setThreeChallenger(inputValue);
    }
    if (item === 4) {
      setFourthChallenger(inputValue);
    }
    if (item === 5) {
      setFifthChallenger(inputValue);
    }
  };

  let scores = `${setA?.challenger ? setA?.challenger : "0"} - ${
    setA?.challenged ? setA?.challenged : "0"
  }, ${" "} ${setB?.challenger ? setB?.challenger : "0"} - ${
    setB?.challenged ? setB?.challenged : "0"
  }, ${" "} ${setC?.challenger ? setC?.challenger : "0"} - ${
    setC?.challenged ? setC?.challenged : "0"
  }`;

  return (
    <>
      <tr
        className="w-full flex items-center pl-5 h-[41px] border-b border-b-border "
        key={index}
      >
        <td className="w-1/12 text-customBlack text-xs font-[500] text-center ">
          {index + 1}
        </td>
        <td className="w-3/12 text-customBlack text-xs font-[500] text-left capitalize ">
          {challengerB?.firstname !== null &&
          challengerB?.firstname !== undefined
            ? `${challenger?.firstname} ${challenger?.lastname}/ ${challengerB?.firstname} ${challengerB?.lastname}`
            : `${
                challenger?.lastname
                  ? challenger?.firstname + " " + challenger?.lastname
                  : challenger?.firstname
              }`}
        </td>
        <td className="w-3/12 text-customBlack text-xs font-[500] text-left capitalize ">
          {challengedB?.firstname !== null &&
          challengedB?.firstname !== undefined
            ? `${challenged?.firstname}   ${challenged?.lastname} / ${challengedB?.firstname}  ${challengedB?.lastname}`
            : `${
                challenged?.lastname
                  ? challenged?.firstname + " " + challenged?.lastname
                  : challenged?.firstname
              }`}
        </td>
        <td className="w-3/12 text-customBlack text-xs font-[500] text-left capitalize ">
          {scores}
        </td>
        <td className="w-3/12 flex justify-start">
          <p className="capitalize text-customBlack text-xs font-[500] text-left">
            {winner !== null
              ? isDouble
                ? winner.firstname
                : winner.firstname + " " + winner?.lastname
              : "No winner"}
          </p>
        </td>

        <td className="w-3/12 flex justify-start space-x-4">
          <p
            className="text-primaryYellow font-medium text-sm underline cursor-pointer"
            onClick={() => setOpenEdit(true)}
          >
            Update Score
          </p>
        </td>
      </tr>
      <ReUseModal open={openEdit} setOpen={setOpenEdit}>
        <span className="w-full">
          <h1>Update Match Score</h1>
          {/* start of set */}
          <span className="border-t-2 border-neutral300-500 mt-7"></span>
          {listofMatchSets.map((item, index) => {
            return (
              <span className="flex flex-col" key={index}>
                <label
                  htmlFor=""
                  className="text-customBlack font-semibold mb-2 mt-6 text-base"
                >
                  SET {item}:
                </label>
                <span className="w-full flex flex-col space-y-4">
                  <span className="w-3/4 flex justify-between mb-2 mt-3">
                    <p className="text-base text-neutral-700 font-medium  capitalize">
                      {challengerB?.firstname !== null &&
                      challengerB?.firstname !== undefined
                        ? `${challenger?.firstname} ${challenger?.lastname}/ ${challengerB?.firstname} ${challengerB?.lastname}`
                        : `${
                            challenger?.lastname
                              ? challenger?.firstname +
                                " " +
                                challenger?.lastname
                              : challenger?.firstname
                          }`}
                    </p>
                    <input
                      type="number"
                      className="w-[40px] py-1 px-2 text-sm rounded-md small-input"
                      min={1}
                      max={10}
                      value={
                        item === 1
                          ? oneChallenger
                          : item === 2
                          ? twoChallenger
                          : item === 3
                          ? threeChallenger
                          : item === 4
                          ? fourthChallenger
                          : item === 5
                          ? fifthChallenger
                          : item
                      }
                      onChange={(e) => handleChallengerOnChange(e, item)}
                    ></input>
                  </span>
                  <span className="w-3/4 flex justify-between mb-2">
                    <p className=" text-neutral-700 font-medium text-sm capitalize">
                      {challengedB?.firstname !== null &&
                      challengedB?.firstname !== undefined
                        ? `${challenged?.firstname}   ${challenged?.lastname} / ${challengedB?.firstname}  ${challengedB?.lastname}`
                        : `${
                            challenged?.lastname
                              ? challenged?.firstname +
                                " " +
                                challenged?.lastname
                              : challenged?.firstname
                          }`}
                    </p>
                    <input
                      type="number"
                      className="w-[40px] py-1 px-2 text-sm rounded-md small-input"
                      min={1}
                      max={10}
                      value={
                        item === 1
                          ? oneChallenged
                          : item === 2
                          ? twoChallenged
                          : item === 3
                          ? threeChallenged
                          : item === 4
                          ? fourthChallenged
                          : item === 5
                          ? fifthChallenged
                          : item
                      }
                      onChange={(e) => handleChallengedOnChange(e, item)}
                    ></input>
                  </span>
                </span>
              </span>
            );
          })}
          <span className="w-full my-5 cursor-pointer" onClick={addMatchSet}>
            <img alt="" src={addNewSet} className="object-contain h-[30px]" />
          </span>
          <p className="text-xs text-red-600">{errorMaxSet}</p>
          <span className="w-10/12 mt-6 mb-10 flex flex-col">
            <PrimarySelect
              label={"Select the  Winner"}
              selected={selected}
              setSelected={setSelected}
              data={selectData}
            />
          </span>
          <span className="w-10/12 mt-10 mx-auto">
            <Btn
              name="update"
              active={true}
              onClick={UpdateScore}
              loading={tournament.loading}
            />
          </span>
        </span>
      </ReUseModal>
      <ReUseModal open={openView} setOpen={setOpenView}>
        <span className="w-full flex flex-col">
          <h1>View Match Score</h1>
          <span className="flex flex-col">
            <label
              htmlFor=""
              className="text-customBlack font-semibold mb-2 mt-6 text-base"
            >
              SET 1:
            </label>
            <span className="w-full flex flex-col">
              <span className="w-8/12 flex justify-between mb-2">
                <p className="text-sm text-neutral-700 capitalize">
                  {challenger?.firstname}
                </p>
                <p className="text-sm text-neutral-700 capitalize">
                  {challenged?.firstname}
                </p>
              </span>
              <span className="flex justify-between w-8/12">
                <p className="text-customBlack text-lg font-bold">
                  {setA?.challenger}
                </p>
                <p className="text-customBlack text-lg font-bold">
                  {setA?.challenged}
                </p>
              </span>
            </span>
          </span>
          <span className="flex flex-col">
            <label
              htmlFor=""
              className="text-customBlack font-semibold mb-2 mt-6 text-base "
            >
              SET 2:
            </label>
            <span className="w-full flex flex-col">
              <span className="w-8/12 flex justify-between mb-2">
                <p className="text-sm text-neutral-700 capitalize">
                  {challenger?.firstname}
                </p>
                <p className="text-sm text-neutral-700 capitalize">
                  {challenged?.firstname}
                </p>
              </span>
              <span className="flex justify-between w-8/12">
                <p className="text-customBlack text-lg font-bold">
                  {setB?.challenger}
                </p>
                <p className="text-customBlack text-lg font-bold">
                  {setB?.challenged}
                </p>
              </span>
            </span>
          </span>
          {/* enf pf set */}
          {/* start of set */}
          <span className="flex flex-col">
            <label
              htmlFor=""
              className="text-customBlack font-semibold mb-2 mt-6 text-base"
            >
              SET 3:
            </label>
            <span className="w-full flex flex-col">
              <span className="w-8/12 flex justify-between mb-2">
                <p className="text-sm text-neutral-700 capitalize">
                  {challenger?.firstname}
                </p>
                <p className="text- text-neutral-700 capitalize">
                  {challenged?.firstname}
                </p>
              </span>
              <span className="flex justify-between w-8/12">
                <p className="text-customBlack text-lg font-bold">
                  {setC?.challenger}
                </p>
                <p className="text-customBlack text-lg font-bold">
                  {setC?.challenged}
                </p>
              </span>
            </span>
          </span>
          {/* enf pf set */}

          <span className="w-10/12 mt-10 mx-auto ">
            <Btn name="Back" active={true} onClick={() => setOpenView(false)} />
          </span>
        </span>
      </ReUseModal>
    </>
  );
};
