import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import { UploadImage } from "../../assets/svgs";
import { storage } from "../../firebase";
import uploadIcon from "../../assets/png/upload-icon.png";

interface IFile {
  label?: string;
  getFileUrl: any;
  setPreviewing: any;
}
const UploadImageAdmin = ({ getFileUrl, setPreviewing }: IFile) => {
  const [, setFileUrl] = useState("");
  const [, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<Blob | MediaSource | any>();
  const [preview, setPreview] = useState("");
  const [success, setSuccess] = useState(false);

  const getFiles = (files: any) => {
    if (!files) return;
    setSelectedFile(files[0]);
    const objectUrl = URL?.createObjectURL(files[0]);
    setPreview(objectUrl);
    setPreviewing(objectUrl);
  };

  const uploadFile = (e: any) => {
    e.preventDefault();
    setLoading(true);
    const storageRef = ref(storage, `/files/${selectedFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      () => {
        setSuccess(false);
        setLoading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setFileUrl(url);
          getFileUrl(url); //whats this for?
          setSuccess(true);
          setSelectedFile("");
          setLoading(false);

          toast.success("Image Upload Successful");
          // setPreview("");
        });
      }
    );
  };

  const cancel = () => {
    setPreview("");
    setPreviewing("");
    setLoading(false);
  };

  return (
    <>
      {preview ? (
        <div className="flex flex-col relative h-auto min-h-[158px]  max-h-[158px] mt-2 border-dashed border-[1px] rounded-md">
          <img
            src={preview && preview}
            alt="preview"
            className="w-full h-full object-contain  overflow-hidden"
          />
          {preview && success ? null : (
            <div className="flex absolute justify-end items-center space-x-3 top-2 right-6 w-full ">
              <button
                className="flex justify-center items-center bg-lemonGreen text-white px-4 py-2 rounded-md cursor-pointer"
                onClick={uploadFile}
                disabled={loading}
              >
                {loading ? "Uploading..." : "Upload"}
              </button>
              <div
                onClick={cancel}
                className="w-8 h-auto cursor-pointer bg-red-700"
              >
                <FaTimesCircle className="text-white text-3xl" />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex   relative  ">
          <div className="w-full  flex-col flex items-center border border-dashed border-neutral-400 py-2 min-h-[158px] rounded-[12px] ">
            <input
              type="file"
              className="opacity-0 inset-0 z-20 absolute"
              onChange={(e) => getFiles(e.target.files)}
            />
            <img src={uploadIcon} alt="upload-icon" className="mx-auto" />
            <p className="text-sm font-medium text-neutral-700 mx-auto">
              click to replace{" "}
              <span className="text-neutral-400 font-normal">
                {" "}
                or drag and drop
              </span>
            </p>
            <p className="text-neutral-400 text-sm mx-auto">
              SVG, PNG, JPG or GIF (max 800 x 400ppx)
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default UploadImageAdmin;
