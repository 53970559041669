import { useState } from "react";
import { randomNames } from "../../../utils/laddersampdata";
interface Player {
  name: string;
  p: string;
  w: string;
  l: string;
  pa: string;
  wd: string;
  pts: string;
}
// import Pagination from "../../Pagination/Pagination";
const LadderTable = () => {
  const [currentData, setCurrentData] = useState<any>([]);
  const generateRandomPlayer = (): Player => {
    const randomName =
      randomNames[Math.floor(Math.random() * randomNames.length)];
    return {
      name: randomName,
      p: "0",
      w: "0",
      l: "0",
      pa: "0",
      wd: "0",
      pts: "0",
    };
  };

  const playersArray: Player[] = [];

  for (let i = 0; i < 20; i++) {
    playersArray.push(generateRandomPlayer());
  }
  return (
    <div className="bg-white rounded-[20px] flex flex-col h-full mt-6 flow-hide ">
      <h1 className="text-customBlack p-4 font-bold">Ladder Tournament</h1>

      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr className="flex items-center text-start h-[35px]">
            <th className=" head text-xs text-neutral-600 font-normal   w-1/12">
              SN
            </th>
            <th className=" head text-xs text-neutral-600 font-normal w-5/12 text-start ">
              Name
            </th>
            <th className=" head text-xs text-neutral-600 font-normal w-1/12 ">
              P
            </th>
            <th className=" head text-xs text-neutral-600 font-normal w-1/12 ">
              W
            </th>
            <th className=" head text-xs text-neutral-600 font-normal w-1/12 ">
              L
            </th>
            <th className=" head text-xs text-neutral-600 font-normal w-1/12 ">
              PA
            </th>
            <th className=" head text-xs text-neutral-600 font-normal w-1/12 ">
              WD
            </th>
            <th className=" head text-xs text-neutral-600 font-normal w-1/12 ">
              PTS
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {playersArray.map((item: any, index: number) => {
            return (
              <tr
                key={index}
                className={`w-full flex items-center h-10 ${
                  index < 10 ? "bg-[#EBF6D4]" : "bg-inherit"
                }`}
              >
                <td className="text-neutral-800 font-[500] text-sm w-1/12 text-center">
                  {index + 1}
                </td>
                <td className="text-neutral-800 font-[500] text-sm w-5/12">
                  {item.name}
                </td>
                <td className="text-neutral-800 font-[500] text-sm w-1/12   text-center">
                  {item.p}
                </td>
                <td className="text-neutral-800 font-[500] text-sm w-1/12  text-center">
                  {item.w}
                </td>
                <td className="text-neutral-800 font-[500] text-sm w-1/12  text-center">
                  {item.l}
                </td>
                <td className="text-neutral-800 font-[500] text-sm w-1/12  text-center">
                  {item.pa}
                </td>
                <td className="text-neutral-800 font-[500] text-sm w-1/12  text-center">
                  {item.wd}
                </td>
                <td className="text-neutral-800 font-[500] text-sm w-1/12  text-center">
                  {item.pts}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* <Pagination
        data={LadderTournamentData}
        currentData={currentData}
        setCurrentData={setCurrentData}
        itemsPerPage={5}
      /> */}
    </div>
  );
};

export default LadderTable;
