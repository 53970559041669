import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppScreen from "./pages/Home/AppScreen";
import MainHome from "./pages/Home/MainHome";

import Onboarding from "./pages/Onboarding";
import AdminHome from "./pages/admin/AdminHome";
import CreateClub from "./pages/auth/Admin/CreateClub";
import AdminLogin from "./pages/auth/Admin/AdminLogin";
import AdminRegister from "./pages/auth/Admin/AdminRegister";
import EmailVerification from "./pages/auth/User/EmailVerification";
import EmailVerficationOtp from "./pages/auth/User/EmailVerificationOtp";
import ForgotPassword from "./pages/auth/User/ForgotPassword";
import MainLogin from "./pages/auth/User/MainLogin";
import NewPassword from "./pages/auth/User/NewPassword";
import NewRegister from "./pages/auth/User/NewRegister";
import OtpPage from "./pages/auth/User/OtpPage";
import Signup from "./pages/auth/User";
import MyMatches from "./pages/matches/MyMatches";
import ForgotPasswordOtpPage from "./pages/auth/User/ForgotPasswordOtpPage";
import UserLogin from "./pages/auth/User/UserLogin";
import BookSingleCourt from "./pages/court/BookSingleCourt";
import Buddies from "./pages/buddies";
import Clubs from "./pages/clubs";
import ActiveLadders from "./pages/clubs/ActiveLadders";
import FindCourt from "./pages/court/FindCourt";
import MoreCourt from "./pages/court/MoreCourt";
import Matches from "./pages/matches";
import ChallengePlayer from "./pages/matches/ChallengePlayer";
import FavourtiteMatches from "./pages/matches/FavourtiteMatches";
import RecentMatches from "./pages/matches/RecentMatches";
import SelectedLadder from "./pages/matches/SelectedChallenge";
import UpcomingMatches from "./pages/matches/UpcomingMatches";
import WeekScoreBoard from "./pages/matches/WeekScoreBoard";
import JoinCompetition from "./pages/auth/User/JoinCompetition";
import Profile from "./pages/profile";
import SelectedProfile from "./pages/profile/SelectedProfile";
import AllInvitations from "./pages/auth/User/Invitations";
import ViewInvitation from "./pages/auth/User/Invitations/ViewInvitation";
import Competition from "./components/Others/Knockout/Competition";
import SendInvitation from "./pages/admin/SendInvitation";
import AdminEmailOtpVerfication from "./pages/auth/Admin/AdminEmailOtpVerfication";
import AdminForgotPassword from "./pages/auth/Admin/AdminForgotPassword";
import AdminNewPassword from "./pages/auth/Admin/AdminNewPassword";
import AdminOtp from "./pages/auth/Admin/AdminOtp";
import AdminVerifyEmail from "./pages/auth/Admin/AdminVerifyEmail";
import ViewSingleUpcomingMatch from "./pages/matches/view-single-upcoming-match";
import InviteSinglePlayer from "./pages/auth/User/PlayerInvitations/invite-single-player";
import MatchInvite from "./pages/matches/MatchInvite";
import RecommendedCourt from "./pages/court/RecommendedCourt";
import CompetitionPage from "./pages/competition";
import CompetitionMatchFixtures from "./pages/admin/Competition/competition-match-fixtures";
import AddCategoriesPage from "./pages/admin/Competition/AddCategoriesPage";
import ViewCompetitionMatchUser from "./pages/matches/competition-matches/view-competition-match-detail";

// ADMIN LINK AND PATHS *****************************************************************************************************************************
import CreateTournament from "./pages/admin/CreateTournament";
import LadderTournament from "./pages/admin/Tournaments/Ladder";
import KnockoutTournament from "./pages/admin/Tournaments/Knockout";
import ViewPlayersFirstCome from "./pages/admin/Tournaments/ViewPlayersFirstCome";
import Club from "./pages/admin/Club";
import ProtectedRoute from "./protectedRoute";
import CourtPage from "./pages/admin/Courts";
import CreateCourt from "./pages/admin/Courts/CreateCourt";
import BookCourt from "./pages/admin/Courts/BookCourt";
import TournamentPage from "./pages/admin/Tournaments";
import MatchesPage from "./pages/admin/AdminMatches";
import RedirectedHome from "./pages/admin/RedirectedHome";
import SelectPlayerPrefill from "./pages/admin/Tournaments/SelectPlayerPrefill";
import SelectPlayerCompetition from "./pages/admin/Tournaments/SelectPlayersCompetition";
import CreateCompetion from "./pages/admin/Tournaments/CreateCompetion";
import CompetitionScoringPage from "./pages/admin/Tournaments/ScoringCompetitionPage";
import TennisRank from "./pages/rank/RankYourTennis";
import EditLadderTournament from "./pages/admin/Tournaments/EditLadderTournament";
import ViewAllPlayersPointLadder from "./pages/admin/Tournaments/ViewAllPlayersPointLadder";
// USER URL AND PATHS ********************************************************************************************************************************
// SUPER ADMIN AND THE REST ********************************************************************************************************************************
import SuperAdminHome from "./pages/SuperAdmin/Home";
import AddNewAdmin from "./pages/SuperAdmin/AddNewAdmin";
import ManageAdmin from "./pages/SuperAdmin/ManageAdmin";
import SuperAdminLogin from "./pages/auth/Admin/SuperAdminLogin";
import ScoreKnockout from "./pages/admin/Tournaments/ScoreKnockout";
import RedirectedSuper from "./pages/SuperAdmin/RedirectedSuper";
import ScoringKnockout from "./pages/admin/Tournaments/ScoringBoardPage";
import Quote from "./pages/Quotes/TennisQuotes";
import AddNewMatch from "./components/Others/Knockout/AddNewMatch";
import ViewAllPlayersLadder from "./pages/admin/Tournaments/ViewAllPlayersLadder";
import SingleLadderMatch from "./pages/admin/Tournaments/SingleLadderMatch";
import ViewLadderInvitation from "./pages/auth/User/Invitations/ViewLadderInvite";
import ViewRules from "./pages/auth/User/Invitations/ViewRules";
import LadderBoard from "./pages/clubs/LadderBoard";
import AllChallengeInvitations from "./pages/clubs/AllChallengeInvitations";
import PendingInvite from "./pages/matches/PendingInvite";
import AcceptedInvite from "./pages/matches/AcceptedInvites";
import SentInvite from "./pages/matches/SentInvite";
import CompleteProfile from "./pages/CompleteProfile";
import ViewLadderDetailsPage from "./pages/admin/Tournaments/ViewLadderDetails";
import RankPlayerPosition from "./pages/admin/Tournaments/RankPlayerPosition";
import PointPlayerPosition from "./pages/admin/Tournaments/PointPlayerPosition";
import AllPlayersUser from "./pages/auth/User/PlayerInvitations";
import InvitePlayerExternal from "./pages/auth/User/PlayerInvitations/InvitePlayerExternal";
import EvaluatePlayerAdmin from "./pages/admin/Club/EvaluatePlayerAdmin";
import ViewSingleSentInvite from "./pages/matches/ViewSingleSentInvite";
import ViewSingleReceivedInvite from "./pages/matches/ViewSingleReceivedInvite";
import ViewSingleReceivedMatch from "./pages/matches/ViewSinlgeReceievedMatch";
import ViewSingleSentMatch from "./pages/matches/ViewSingleSentMatch";
import SingleCourtReservation from "./pages/court/SingleCourtReservation";
import AdminProfile from "./features/auth/AdminProfile";
import ArrangePlayer from "./pages/admin/Tournaments/ArrangePlayers";
import SelectedCompetition from "./pages/competition/selected-competition";
import AllCompetitionMatch from "./pages/competition/all-competition-match";
import SingleCompetitionMatchDetail from "./pages/competition/single-competiton-match-detail";
import TeamCompetitionScoringPage from "./pages/admin/Tournaments/ScoringTeamCompetitionPage";
import TeamCompetitionOverview from "./pages/admin/Tournaments/TeamCompetitionOverview";
import TeamMembersPage from "./pages/admin/Tournaments/TeamMembersPage";
import AssignPlayerPage from "./pages/admin/Competition/AssignPlayerPage";
import AssignPlayerCategoryPage from "./pages/admin/Competition/assign-player-to-categories";
import ScheduleCompetitionMatchPage from "./pages/admin/Competition/ScheduleCompetitionMatchPage";
import CompetitionMatchScore from "./pages/admin/Competition/competition-scores";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* USER URL AND PATHS ******************************************************************************************************************************** */}
        <Route path="/" element={<AppScreen />} />
        <Route path="/users" element={<Signup />} />
        <Route path="/login" element={<MainLogin />} />
        <Route path="/home" element={<MainHome />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/clubs" element={<Clubs />} />
        <Route path="/matches" element={<Matches />} />
        <Route path="/buddies" element={<Buddies />} />
        <Route path="/find-court" element={<FindCourt />} />
        <Route path="/more-court" element={<MoreCourt />} />
        <Route path="/competition" element={<CompetitionPage />} />
        <Route path="/challenge" element={<ChallengePlayer />} />
        <Route path="/selected-ladder/:id" element={<SelectedLadder />} />
        <Route
          path="/selected-competition/:id"
          element={<SelectedCompetition />}
        />
        <Route
          path="/competition-match-fixtures"
          element={<CompetitionMatchFixtures />}
        />
        <Route
          path="/competition-match-scores"
          element={<CompetitionMatchScore />}
        />
        <Route path="/players-list/:id" element={<LadderBoard />} />
        <Route path="/selected-profile/:id" element={<SelectedProfile />} />
        <Route path="/pending-ladder-invite/:id" element={<PendingInvite />} />
        <Route
          path="/accepted-ladder-invite/:id"
          element={<AcceptedInvite />}
        />
        <Route
          path="/single-match-detail/:id"
          element={<SingleCompetitionMatchDetail />}
        />
        <Route
          path="/view-competition-match/:id"
          element={<ViewCompetitionMatchUser />}
        />
        <Route path="/book-court/:id" element={<BookSingleCourt />} />
        <Route
          path="/court-reservation/:id"
          element={<SingleCourtReservation />}
        />
        <Route path="/sent-ladder-invite/:id" element={<SentInvite />} />
        <Route path="/active-ladders" element={<ActiveLadders />} />
        <Route path="/rank" element={<TennisRank />} />
        <Route path="/quote" element={<Quote />} />
        <Route path="/join" element={<JoinCompetition />} />
        <Route path="/complete-profile" element={<CompleteProfile />} />
        <Route path="/recommended-courts" element={<RecommendedCourt />} />

        <Route path="/week-score" element={<WeekScoreBoard />} />
        <Route path="/my-matches" element={<MyMatches />} />
        <Route
          path="/all-challenge-invitations"
          element={<AllChallengeInvitations />}
        />
        <Route
          path="/all-competition-matches"
          element={<AllCompetitionMatch />}
        />
        <Route path="/upcoming-matches" element={<UpcomingMatches />} />
        <Route path="/favourite-matches" element={<FavourtiteMatches />} />
        <Route path="/recent-matches" element={<RecentMatches />} />
        <Route path="/all-invitations" element={<AllInvitations />} />
        <Route path="/user/allplayers" element={<AllPlayersUser />} />
        <Route
          path="/invite-player/external"
          element={<InvitePlayerExternal />}
        />
        <Route path="/match-invite" element={<MatchInvite />} />
        <Route path="/view-invitation" element={<ViewInvitation />} />
        <Route
          path="/view-invitation/ladder/:id"
          element={<ViewLadderInvitation />}
        />
        <Route
          path="/view-match-detail/:id"
          element={<ViewSingleUpcomingMatch />}
        />
        <Route
          path="/view-match-detail/sent/:id"
          element={<ViewSingleSentInvite />}
        />
        <Route
          path="/view-match-detail/received/:id"
          element={<ViewSingleReceivedInvite />}
        />
        <Route
          path="/view-match-score/received/:id"
          element={<ViewSingleReceivedMatch />}
        />
        <Route
          path="/view-match-score/sent/:id"
          element={<ViewSingleSentMatch />}
        />
        <Route path="/match-invite/:id" element={<InviteSinglePlayer />} />
        <Route path="/view-rules/ladder/:id" element={<ViewRules />} />
        <Route path="/verify-otp" element={<ForgotPasswordOtpPage />} />
        <Route path="/user/login" element={<UserLogin />} />
        {/* <Route path="/update-password" element={<UpdatePassword />} /> */}
        {/* // ADMIN URL AND PATHS ******************************************************************************************************************************** */}
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin" element={<RedirectedHome />} />
        <Route path="/admin/register" element={<AdminRegister />} />
        <Route
          path="/admin/forgot-password"
          element={<AdminForgotPassword />}
        />
        <Route path="/admin/createclub" element={<CreateClub />} />
        <Route path="/admin/home" element={<AdminHome />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/otp" element={<OtpPage />} />
        <Route path="/update-password" element={<NewPassword />} />
        <Route path="/new-register" element={<NewRegister />} />
        <Route path="/register" element={<NewRegister />} />
        <Route path="/email-verification" element={<EmailVerification />} />
        <Route
          path="/admin-forgot-password-otp/:id"
          element={<EmailVerficationOtp />}
        />
        <Route path="/admin/email-verification-otp" element={<AdminOtp />} />
        <Route path="/admin/new-password" element={<AdminNewPassword />} />
        <Route path="/admin/send-invitation/:id" element={<SendInvitation />} />
        <Route
          path="/admin/email-verification/:id"
          element={<AdminVerifyEmail />}
        />
        <Route path="/admin/profile" element={<AdminProfile />} />
        <Route
          path="/admin/email-verification-otp/:id"
          element={<AdminEmailOtpVerfication />}
        />
        <Route
          path="/admin/view-players/rank/:id"
          element={<ViewAllPlayersLadder />}
        />
        <Route
          path="/admin/view-players/point/:id"
          element={<ViewAllPlayersPointLadder />}
        />
        {/* ADMIN OTHER PAGES */}

        <Route
          path="/admin/create-tournament"
          element={
            <ProtectedRoute>
              <CreateTournament />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/create-competition"
          element={
            <ProtectedRoute>
              <CreateCompetion />
            </ProtectedRoute>
          }
        />
        <Route path="/admin/tournament" element={<TournamentPage />} />
        <Route path="/admin/matches" element={<MatchesPage />} />
        <Route
          path="/admin/create-tournament/ladder"
          element={<LadderTournament />}
        />
        <Route
          path="/admin/view-players/first-come"
          element={<ViewPlayersFirstCome />}
        />
        <Route
          path="/admin/create-tournament/knockout/:id/:number"
          element={<KnockoutTournament />}
        />
        <Route
          path="/admin/select-players/knockout/:id"
          element={<SelectPlayerPrefill />}
        />
        <Route
          path="/admin/edit-ladder/:id"
          element={<EditLadderTournament />}
        />
        <Route
          path="/admin/rank-position/:id"
          element={<RankPlayerPosition />}
        />
        <Route path="/admin/arrange-players/:id" element={<ArrangePlayer />} />
        <Route
          path="/admin/point-position/:id"
          element={<PointPlayerPosition />}
        />
        <Route
          path="/admin/view-ladder-detail/:id"
          element={<ViewLadderDetailsPage />}
        />
        <Route path="/admin/ladder-match/:id" element={<SingleLadderMatch />} />
        <Route
          path="/admin/select-players/competition/:id"
          element={<SelectPlayerCompetition />}
        />
        <Route path="/admin/competition/:id" element={<Competition />} />
        <Route path="/admin/competition/add/:id" element={<AddNewMatch />} />
        <Route
          path="/admin/score-tournament/knockout/:id"
          element={<ScoreKnockout />}
        />
        <Route
          path="/admin/scoring/knockout/:id"
          element={<ScoringKnockout />}
        />
        <Route
          path="/admin/scoring/competition/:id"
          element={<CompetitionScoringPage />}
        />
        {/* <Route
          path="/admin/competition/assign-player/:id"
          element={<AssignPlayerPage />}
        /> */}

        <Route
          path="/admin/competition/add-categories/:id"
          element={<AddCategoriesPage />}
        />

        <Route
          path="/admin/competition/assign-player/:id"
          element={<AssignPlayerCategoryPage />}
        />
        <Route
          path="/admin/competition/schedule-match/:id"
          element={<ScheduleCompetitionMatchPage />}
        />
        <Route
          path="/admin/scoring/team-competition/:id"
          element={<TeamCompetitionScoringPage />}
        />
        <Route path="/admin/team-members/:id" element={<TeamMembersPage />} />
        <Route
          path="/admin/team-competition/overview/:id"
          element={<TeamCompetitionOverview />}
        />
        <Route path="/admin/club" element={<Club />} />
        <Route
          path="/admin/evaluate-player/:id"
          element={<EvaluatePlayerAdmin />}
        />
        <Route path="/admin/courts" element={<CourtPage />} />
        <Route path="/admin/courts/create" element={<CreateCourt />} />
        <Route path="/admin/courts/book" element={<BookCourt />} />
        {/* SUPER ADMIN PAGES */}
        <Route path="/super" element={<RedirectedSuper />} />
        <Route path="/super/home" element={<SuperAdminHome />} />
        <Route path="/super/login" element={<SuperAdminLogin />} />
        <Route path="/super/add" element={<AddNewAdmin />} />
        <Route path="/super/manage" element={<ManageAdmin />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
