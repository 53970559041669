import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import newtrash from "../../../assets/svgs/new-trash.svg";
import trash from "../../../assets/svgs/trash.svg";
import {
  deleteAdmin,
  resetDelete,
} from "../../../features/auth/SuperAdminSlice";
import ReUseModal from "../../Modal/ReUseModal";
import { ModalSub } from "../../Text";
import { capitalizeWords, convertDateFormat } from "../../../utils";
interface tableProps {
  className: string;
}
interface singleRowProps {
  handleDelete: any;
  item: any;
}

const ManageTable = ({ className }: tableProps) => {
  const adminState = useAppSelector((state) => state.superAdminAuth);
  const dispatch = useAppDispatch();
  const handleDelete = (item: string) => {
    dispatch(deleteAdmin(item));
  };
  useEffect(() => {
    if (adminState.deleteAdminSuccess) {
      toast.success("Admin Deleted Successfully");
      dispatch(resetDelete());

      //  setOpen(false);
    } else {
      //  setOpen(false);
    }
  }, [adminState.deleteAdminSuccess]);

  return (
    <div
      className={`w-full  h-full flex flex-col bg-white  rounded-[20px] ${className} `}
    >
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className="text-sm font-semibold text-customBlack">Admin</h1>
      </div>
      {/* Table section starts from here  #################################################3*/}
      <table className="min-w-full w-full h-full border-b border-b-border  ">
        <thead className="">
          <tr className="grid grid-cols-6  h-[35px] items-center px-4">
            <th className="  text-xs text-neutral-600 text-start ">
              Date Registered
            </th>
            <th className="  text-xs text-neutral-600 text-start ">
              Name Of Admin
            </th>

            <th className="  text-xs text-neutral-600 text-start ">Email</th>
            <th className="  text-xs text-neutral-600 text-start ">
              Number of Court
            </th>
            <th className="  text-xs text-neutral-600 text-start ">
              Club’s Location
            </th>
            <th className="  text-xs text-neutral-600 text-start ">Action</th>
          </tr>
        </thead>
        <tbody className="w-full  rounded-b-[20px] flex flex-col h-full px-4 ">
          {adminState.allAdmin.slice(0, 12).map((item: any, index: number) => {
            return (
              <SingleRow key={index} item={item} handleDelete={handleDelete} />
            );
          })}
        </tbody>
      </table>
      {/* Table section Ends from here $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$4 */}
      <div className="w-full h-[61px] items-center flex justify-between pl-4 pr-8 mb-4">
        {/* pagination */}
        <div className="flex items-center">
          <p className="text-xs">Page</p>
          <p className="px-1 rounded-sm border border-border text-customBlack font-bold cursor-pointer text-sm mx-2 ">
            1
          </p>
          <p className="text-sm text-customBlack">of 2</p>
          <div className="space-x-2 items-center ml-8 flex">
            <span className="text-base px-1 bg-[#f4f4f4] text-[#cfcfcf] opacity-70 rounded-sm border border-border">
              <IoIosArrowBack />
            </span>
            <span className="text-base px-1 bg-[#f4f4f4] text-customBlack opacity-70 rounded-sm border border-border">
              <IoIosArrowForward />
            </span>
          </div>
        </div>
        {/* enteries */}
        <div className="flex">
          <p>No of entries </p>
          <div>
            <p className="px-1 rounded-sm border border-border text-customBlack font-bold cursor-pointer text-sm mx-2 ">
              10 per page
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageTable;

const SingleRow = ({ item, handleDelete }: singleRowProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <tr className="grid grid-cols-6  py-3  items-center">
        <td className="  text-sm text-neutral-600 text-start ">
          {" "}
          {convertDateFormat(item.createdAt)}
        </td>
        <td className="  text-sm text-neutral-600 text-start ">
          {capitalizeWords(item.firstname + " " + item.lastname)}
        </td>
        <td className="  text-sm text-neutral-600 text-start ">{item.email}</td>
        <td className=" text-sm text-neutral-600 text-start ">0</td>
        <td className="  text-sm text-neutral-600 text-start ">Nigeria</td>
        <td
          className="  text-sm text-neutral-600 text-start flex items-center space-x-2 cursor-pointer"
          onClick={() => setOpen(true)}
        >
          <img src={trash} alt="trash" />
          <p className="text-[#B00020]">Remove</p>
        </td>
      </tr>
      <ReUseModal open={open} setOpen={setOpen}>
        <div className="w-full flex flex-col justify-center items-center py-8">
          <img src={newtrash} alt="trash icon" className="mx-auto" />
          <p className="text-customBlack font-bold text-lg text-center">
            Remove Admin
          </p>
          <ModalSub
            className="text-center mt-1"
            text="Are you sure you want to remove this admin?"
          />
          <div className="w-11/12 mx-auto grid grid-cols-2 gap-x-4 items-center mt-16">
            <div
              className="flex w-full rounded-[16px] border border-lemonGreen items-center justify-center cursor-pointer text-sm text-lemonGreen h-[52px] "
              onClick={() => setOpen(false)}
            >
              No
            </div>
            <div
              className=" bg-[#B00020] flex w-full rounded-[16px]  items-center justify-center text-sm text-white cursor-pointer  h-[52px] "
              // onClick={handleDelete(item._id)}
              onClick={() => handleDelete(item._id)}
            >
              Yes
            </div>
          </div>
        </div>
      </ReUseModal>
    </>
  );
};
