import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { IoIosArrowBack } from "react-icons/io";
import { More } from "../../assets/svgs/court";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllCourtUser,
  restoreDefault,
} from "../../features/auth/ladder-slice";
import sentMark from "../../assets/svgs/sent-mark.svg";
import { Btn, RedOutlinedBtn } from "../../components/Button";
import ReUseModal from "../../components/Modal/ReUseModal";
import { CautionIcon } from "../../assets/svgs/Icons";
import { manageInvite } from "../../features/auth/playerslice";
interface FormattedDateTime {
  date: string;
  time: string;
}
const ViewSingleReceivedInvite = () => {
  const user = useAppSelector((state) => state.auth);
  const ladder = useAppSelector((state) => state.ladder);
  const player = useAppSelector((state) => state.player);
  const [details, setDetails] = useState<any>({});
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openConsent, setOpenConsent] = useState(false);
  const [openRejected, setOpenRejected] = useState(false);
  const [picked, setPicked] = useState("");
  const dispatch = useAppDispatch();
  const [court, setCourt] = useState("");

  const id = useParams();
  const item = player?.allReceivedInvitation?.filter(
    (item: any) => item?._id === id.id
  );
  useEffect(() => {
    dispatch(getAllCourtUser());
    setDetails(item[0]);
  }, []);

  useEffect(() => {
    if (ladder?.allCourts?.length !== 0) {
      const matchCourt = ladder?.allCourts?.filter(
        (item: any) => item?._id === details?.court
      );
      setCourt(matchCourt[0]?.name || "");
    }
  }, [ladder?.allCourts]);

  const navigate = useNavigate();

  function formatDateTime(inputDate: string): FormattedDateTime {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
    };
    const date: Date = new Date(inputDate);

    const dayMonthFormat: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
    };
    const formattedDate: string = date
      .toLocaleDateString("en-US", dayMonthFormat)
      .toUpperCase();

    const formattedTime: string = `${date.getHours()}:${
      date.getMinutes() < 10 ? "0" : ""
    }${date.getMinutes()}`;

    return { date: formattedDate, time: formattedTime };
  }
  const formattedDateTime: FormattedDateTime = formatDateTime(details?.time);
  const {
    challenged,
    challenger,

    challengedB,
    challengerB,
  } = item?.[0] || {};
  const handleReject = () => {
    setPicked("rejected");
    const data = {
      state: "rejected",
    };
    dispatch(manageInvite({ id: id.id, data: data }));
  };
  const handleAccept = () => {
    setPicked("accepted");
    const data = {
      state: "accepted",
    };
    dispatch(manageInvite({ id: id.id, data: data }));
  };

  useEffect(() => {
    if (player?.manageSuccess && picked === "accepted") {
      setOpenSuccess(true);
      dispatch(restoreDefault());
    } else if (player?.manageSuccess && picked === "rejected") {
      setOpenRejected(true);
      setOpenConsent(false);
      dispatch(restoreDefault());
    }
  }, [player?.manageSuccess]);

  return (
    <div className="w-full flex flex-col px-4 pb-10 relative h-screen">
      <div className="flex justify-between mt-6 items-center mb-8">
        <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
        <p className="text-[#303030] font-bold text-[15px] ">Match Invite</p>
        <div className="more-shadow opacity-0">
          <More />
        </div>
      </div>
      <div className="w-full bg-[#FFA800] h-[250px] rounded-[24px] p-4 flex space-x-6 justify-between">
        <div className="flex flex-col">
          <img
            src={user?.userData?.user?.image}
            alt="profile avatar"
            className="w-[120px] h-[120px] object-cover rounded-[20px]"
          />
          <p className="text-white text-base font-bold mb-2 mt-1 text-center capitalize">
            {user?.userData?.user?.firstname}
            <br />
            {user?.userData?.user?.lastname}
          </p>
          <div className="h-[29px] bg-white flex items-center justify-center px-4 rounded-3xl text-ash text-xs  ">
            {user?.userData?.user?.rank}
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-base font-bold text-white mt-8">VS</p>
        </div>
        <div className="flex flex-col">
          <img
            src={details?.challenger?.image}
            alt="profile avatar"
            className="w-[120px] h-[120px] object-cover rounded-[20px]"
          />
          <p className="text-white text-base font-bold mb-2 mt-1 text-center capitalize">
            {details?.challenger?.firstname}
            <br />
            {details?.challenger?.lastname}
          </p>
          <div className="h-[29px] bg-white flex items-center justify-center px-4 rounded-3xl text-ash text-xs  ">
            {details?.challenger?.rank}
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-8 space-y-4">
        {/* start of partl */}
        <div className="flex flex-col">
          <p className="text-sm text-customBlack font-bold mb-4">
            Match Details
          </p>
        </div>

        {/* end of a pack */}

        {/* start of partl */}
        {/* <div className="flex justify-between items-center mb-3">
          <p className="text-sm text-[#5e5e5e] font-[400] ">Match Date </p>
          <p className="text-customBlack text-sm font-bold">
            {" "}
            {formattedDateTime?.date}
          </p>
        </div> */}
        <div className="w-full min-h-[50px] flex items-center border border-[#CFCFCF] px-6 justify-between rounded-[10px] mb-3">
          <p className=" text-xs text-[#5E5E5E]">Date</p>
          <p className="text-sm font-semibold text-customBlack">
            {formattedDateTime?.date},2024
          </p>
          <p></p>
        </div>
        <div className="w-full min-h-[50px] flex items-center border border-[#CFCFCF] px-6 justify-between rounded-[10px] mb-3">
          <p className=" text-xs text-[#5E5E5E]">Time</p>
          <p className="text-sm font-semibold text-customBlack">
            {formattedDateTime.time}pm
          </p>
          <p></p>
        </div>
        <div className="w-full min-h-[50px] flex items-center border border-[#CFCFCF] px-6 justify-between rounded-[10px] mb-3">
          <p className=" text-xs text-[#5E5E5E]">Court</p>
          <p className="text-sm font-semibold text-customBlack capitalize">
            {court}
          </p>
          <p></p>
        </div>
        {/* end of a pack */}
      </div>
      <div className="absolute bottom-8 w-full px-4 flex items-center justify-center right-0 left-0  flex-col gap-4 ">
        <Btn
          onClick={handleAccept}
          active
          name={"Accept "}
          loading={player?.loading}
        />
        <RedOutlinedBtn
          onClick={() => setOpenConsent(true)}
          active
          name={"Reject"}
        />
      </div>
      <ReUseModal open={openSuccess} setOpen={setOpenSuccess}>
        <div className="w-full flex flex-col">
          <img src={sentMark} alt="sent" className="mx-auto mt-6" />
          <h1 className="text-customBlack text-[18px] font-bold text-center ">
            Match Invitation Accepted
          </h1>
          <p className="text-center w-10/12 mx-auto text-neutral-500 text-sm ">
            You’ve successfully accepted the match invitation
          </p>
          <Btn
            active={true}
            name="Close"
            onClick={() => {
              setOpenSuccess(false);
              dispatch(restoreDefault());
              navigate(`/matches`);
            }}
            className="mt-[25px] mb-3"
          />
        </div>
      </ReUseModal>
      <ReUseModal open={openRejected} setOpen={setOpenRejected}>
        <div className="w-full flex flex-col">
          <img src={sentMark} alt="sent" className="mx-auto mt-6" />
          <h1 className="text-customBlack text-[18px] font-bold text-center ">
            Match Invitation Rejected
          </h1>
          <p className="text-center w-10/12 mx-auto text-neutral-500 text-sm ">
            You’ve successfully rejected this match invitation
          </p>
          <Btn
            active={true}
            name="Close"
            onClick={() => {
              setOpenRejected(false);
              dispatch(restoreDefault());
              navigate(`/matches`);
            }}
            className="mt-[25px] mb-3"
          />
        </div>
      </ReUseModal>
      <ReUseModal open={openConsent} setOpen={setOpenConsent}>
        <div className="w-full flex flex-col">
          <div className="flex items-center justify-center mt-10">
            <CautionIcon />
          </div>
          <h1 className="text-center mt-2 font-bold text-[22px] leading-[24px] text-customBlack ">
            Reject Match Invitation
          </h1>
          <p className="text-[#868686] mt-1 text-sm text-center max-w-[340px] mx-auto">
            Are you sure you want to reject this match?
          </p>
          <div className="w-full flex items-center justify-center  gap-6 mt-16">
            <button
              className="w-full max-w-[174px] rounded-[16px] border border-[#9cd326] h-[52px] flex items-center cursor-pointer justify-center text-sm font-semibold text-[#9CD326] "
              onClick={() => setOpenConsent(false)}
              disabled={ladder?.loading}
            >
              No Cancel
            </button>
            <button
              className="w-full max-w-[174px] rounded-[16px] bg-[#B00020] h-[52px] flex items-center cursor-pointer justify-center text-sm font-semibold text-white border-none outline-none"
              onClick={handleReject}
              disabled={ladder?.loading}
            >
              {player?.loading ? "Loading..." : "Yes Reject"}
            </button>
          </div>
        </div>
      </ReUseModal>
    </div>
  );
};

export default ViewSingleReceivedInvite;
