interface textSize {
  text: string;
  className?: string;
}
export const BigText = ({ text, className }: textSize) => {
  return (
    <h1
      className={`text-[24px] font-[700] leading-[29px] text-customBlack ${className} `}
    >
      {text}
    </h1>
  );
};

export const Ptext = ({ text, className }: textSize) => {
  return (
    <p
      className={`text-[14px]  font-normal leading-[24px] text-[#868686] ${className} `}
    >
      {text}
    </p>
  );
};

export const Subtitle = ({ text, className }: textSize) => {
  return (
    <h1
      className={`text-base font-[400] leading-[20px] text-[#5E5E5E] font-Circular ${className} `}
    >
      {text}
    </h1>
  );
};

export const ModalSub = ({ text, className }: textSize) => {
  return (
    <h1
      className={`text-base font-[400] leading-[20px] text-[#5E5E5E]  ${className} `}
    >
      {text}
    </h1>
  );
};

export const ModalTitle = ({ text, className }: textSize) => {
  return (
    <h1
      className={`text-[24px] leading-6 font-[700]  text-[#03132B]  ${className} `}
    >
      {text}
    </h1>
  );
};
export const AuthTitle = ({ text, className }: textSize) => {
  return (
    <h1
      className={`text-[45px] leading-[54px] font-[700]  text-customBlack  ${className} `}
    >
      {text}
    </h1>
  );
};
export const AuthSubTitle = ({ text, className }: textSize) => {
  return (
    <h1
      className={`text-base leading-[24px] font-[400]  text-[#868686]  ${className} `}
    >
      {text}
    </h1>
  );
};
