import GeneralLayout from "../../Layouts/GeneralLayout";
import { EditProfile, Logout } from "../../assets/svgs";

import { BiCalendarWeek, BiEnvelope } from "react-icons/bi";
import { FiPhoneCall } from "react-icons/fi";
import { GrLocation, GrMapLocation } from "react-icons/gr";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import pImg from "../../assets/png/profile-img.png";
import {
  getProfile,
  resetAll,
  updateUserData,
} from "../../features/auth/AuthSlice";
import { capitalizeWords } from "../../utils";
import { useEffect } from "react";
import { formatDate } from "../../utils";
import LoadingComponent from "../../components/Loading/Loader";
const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state) => state.auth);
  const auth = useAppSelector((state) => state.auth);

  let sampleData = {
    firstname: "",
    lastname: "",
    email: "",
    image: "",
    rank: 0,
    address: "",
    phone: "",
    dateOfBirth: "",
    assigned_points: "",
  };
  useEffect(() => {
    if (userData?.success === undefined) {
      navigate("/login");
    }
  }, []);

  const {
    firstname,
    lastname,
    email,
    thumbnail,
    address,
    phone,
    dateOfBirth,
    rank,
    assigned_points,
  } = userData?.user !== undefined ? userData?.user : sampleData;

  const handleLogout = () => {
    dispatch(resetAll());

    navigate("/users");
  };

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    if (auth?.profileData?._id) {
      const data = {
        user: auth?.profileData,
        success: true,
      };
      dispatch(updateUserData(data));
    }
  }, []);
  return (
    <GeneralLayout active={5} name="My Profile">
      {auth.fetchLoading ? (
        <div className="md:w-[450px] h-screen w-full px-4">
          <LoadingComponent />
        </div>
      ) : (
        <div className="w-full flex flex-col px-4 pb-40 md:w-[450px]">
          <div className="profile-hero-bg flex flex-col justify-center px-6 min-w-full py-8 rounded-[24px] ">
            <div className="flex space-x-4">
              <img
                src={thumbnail ? thumbnail : pImg}
                alt="profile avatar"
                className="w-[96px] h-[96px] rounded-2xl"
              />
              <div className="flex flex-col">
                <p className="text-white text-base font-bold mb-1 capitalize">
                  {lastname ? `${firstname} ${lastname}` : `${firstname}`}
                </p>
                <p className="text-xs text-white mb-2">
                  Points: {assigned_points ? assigned_points : rank}
                </p>
                <span
                  className="cursor-pointer"
                  onClick={() => navigate("/complete-profile")}
                >
                  <EditProfile />
                </span>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div className="w-full home-option flex flex-col justify-center">
              <p className="text-lemonGreen font-bold text-3xl text-center">
                {auth?.userWin}
              </p>
              <p className="text-xs text-center mt-1"> Matches Won</p>
            </div>
            <div className="w-full home-option flex flex-col justify-center">
              <p className="text-lemonGreen font-bold text-3xl text-center">
                {auth?.userLoss}
              </p>
              <p className="text-xs text-center mt-1">Matches Lost</p>
            </div>
          </div>

          <div className="mt-12">
            <p className="text-md font-semibold text-[#2c2c2c] mb-4">
              Basic Details
            </p>
            <div className="flex flex-col">
              {/* start of a pack */}
              <div className="flex h-14 items-center w-full cursor-pointer space-x-6">
                <BiEnvelope className="text-[#303030] font-bold text-2xl" />
                <div className="items-center flex h-14 border-t border-b border-border w-full">
                  <p className="text-sm">{email ? email : "No email"}</p>
                </div>
              </div>
              {/* end of the pack */}
              {/* start of a pack */}
              <div className="flex cursor-pointer h-14 items-center w-full space-x-6">
                <FiPhoneCall className="text-[#303030] font-bold text-2xl" />

                <div className="items-center flex h-14 border-t border-b border-border w-full">
                  <p className="text-sm">{phone ? phone : "N/A"}</p>
                </div>
              </div>
              {/* end of the pack */}
              {/* start of a pack */}
              <div className="flex h-14 items-center w-full space-x-6">
                <GrLocation className="text-[#303030] font-bold text-2xl" />
                <div className="items-center flex h-14 border-t border-b border-border w-full">
                  <p className="text-sm">{address ? address : "N/A"}</p>
                </div>
              </div>
              {/* end of the pack */}
              {/* start of a pack */}
              <div className="flex h-14 items-center w-full space-x-6">
                <BiCalendarWeek className="text-[#303030] font-bold text-2xl" />
                <div className="items-center flex h-14 border-t border-b border-border w-full">
                  <p className="text-sm">{formatDate(dateOfBirth)}</p>
                </div>
              </div>
              {/* end of the pack */}
            </div>
            <div className="mt-12">
              <p className="text-md font-semibold text-[#2c2c2c] mb-4">
                Match Preference
              </p>
              <div className="flex flex-col">
                {/* start of a pack */}
                <div className="flex h-14 items-center w-full space-x-6">
                  <GrMapLocation className="text-[#303030] font-bold text-2xl" />
                  <div className="items-center flex h-14 border-t border-b border-border w-full">
                    <p className="text-sm">Nearby Court</p>
                  </div>
                </div>
                {/* end of the pack */}
                {/* start of a pack */}
                <div className="flex h-14 items-center w-full space-x-6">
                  <FiPhoneCall className="text-[#303030] font-bold text-2xl" />
                  <div className="items-center flex h-14 border-t border-b border-border w-full">
                    <p className="text-sm">10:00am, 3:00pm, 5:00pm</p>
                  </div>
                </div>
                {/* end of the pack */}
              </div>
            </div>
            <div
              className="w-full h-14 border border-border flex items-center justify-center space-x-1 rounded-md mt-12 mb-6"
              onClick={handleLogout}
            >
              <Logout />
              <p className="text-[#FF0000] font-bold">Logout</p>
            </div>

            <div className="h-20 w-full"></div>
          </div>
        </div>
      )}
    </GeneralLayout>
  );
};

export default Profile;
