import { useEffect, useState } from "react";
import DashboardLayout from "../../../Layouts/DashboardLayout";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { NavigationArrow } from "../../../assets/svgs/admin";

import SingleGroupPlayers from "../../../components/Others/competition/single-group-players";
import { Btn } from "../../../components/Button";
import {
  assignPlayerToCategories,
  assignTeamPlayers,
  getSingleCompetitionDetailAdmin,
  resetAll,
} from "../../../features/auth/TournamentSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ReUseModal from "../../../components/Modal/ReUseModal";
import sentMark from "../../../assets/svgs/checkmark.svg";
import LoadingComponent from "../../../components/Loading/Loader";
import ColouredSelect from "../../../components/Selects/ColouredSelect";
import { categoriesNumber, playersNumber } from "../../../utils/sampData";
import { EmptyListIcon } from "../../../assets/new-svg/icons";
import defaultImage from "../../../assets/png/default-png.png";
import SingleTeamPlayers from "../../../components/Others/competition/single-team-player";
export interface Player {
  _id: string;
  firstname: string;
  lastname: string;
  image: string;
  rank: number;
}

export interface Team {
  _id: string;
  name: string;
}

interface PlayerAssignment {
  player_id: string;
  group_id: string;
}

interface PlayerAssignmentTeam {
  player_id: string;
  team_id: string;
}

const AssignPlayerCategoryPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tournament = useAppSelector((state) => state.tournament);
  const id = useParams();
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");
  const [present, setPresent] = useState(0);
  const [players, setPlayers] = useState<Player[]>([]);
  const [allPlayers, setAllPlayers] = useState<PlayerAssignment[]>([]);
  const [allGroupedPlayers, setAllGroupedPlayers] = useState<any>([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [allPlayersTeam, setAllPlayersTeam] = useState<PlayerAssignmentTeam[]>(
    []
  );
  const [openCat, setOpenCat] = useState<boolean>(false);

  const [teams, setTeams] = useState<Team[]>([]);

  useEffect(() => {
    dispatch(getSingleCompetitionDetailAdmin({ id: id.id }));
  }, []);
  const details = tournament?.competitionDetailAdmin;

  const [openTeam, setOpenTeam] = useState<boolean>(false);
  const handlePlayerSelect = (teamId: string, player: Player): void => {
    setAllPlayersTeam((prevAllPlayers) => [
      ...prevAllPlayers,
      { player_id: player._id, team_id: teamId },
    ]);

    // Remove selected player from the available players list
    setAllGroupedPlayers((prevPlayers:any) =>
      prevPlayers.filter((p:any) => p._id !== player._id)
    );
  };
  const handlePlayerSelectGroup = (groupId: string, player: Player): void => {
    // Add player to selected players
    setAllPlayers((prevAllPlayers) => [
      ...prevAllPlayers,
      { player_id: player._id, group_id: groupId },
    ]);

    // Remove selected player from the available players list
    setPlayers((prevPlayers) =>
      prevPlayers.filter((p) => p._id !== player._id)
    );
  };
console.log(allGroupedPlayers)
  const handleSubmit = (): void => {
    const data = {
      players: allPlayers,
    };
    const sentData = {
      id: id.id,
      data: data,
    };
    dispatch(assignPlayerToCategories(sentData));
  };

  useEffect(() => {
    if (tournament?.competitionDetailAdmin?.players?.length > 0) {
      const cleanedPlayers = details?.players?.map((item: any) => item?._id);

      setPlayers(cleanedPlayers);
      const cleanedPlayersGroup = details?.players?.map((item: any) => {
        const group = details.groups.find(
          (group: any) => group._id === item.group
        );

        return {
          ...item._id,
          groupName: group?.name || "Unknown Group",
          group_id: group?._id || "Unknown ID",
        };
      });

      setAllGroupedPlayers(cleanedPlayersGroup);
    }
    if (tournament?.competitionDetailAdmin?.teams?.length > 0) {
      setTeams(details?.teams);
    }
    if (tournament?.assignTeamPlayerSuccess) {
      setOpenTeam(true);
      dispatch(resetAll());
      setTimeout(() => {
        setOpenTeam(false);
        navigate("/admin/tournament");
      }, 3000);
    }
    if (tournament?.assignPlayerCategoriesSuccess) {
      setOpenCat(true);
      dispatch(resetAll());
      setTimeout(() => {
        setOpenCat(false);
        window.location.replace(
          `/admin/competition/assign-player/${id.id}?step=team`
        );
        dispatch(getSingleCompetitionDetailAdmin({ id: id.id }));
      }, 3000);
    }
  }, [
    tournament?.assignPlayerCategoriesSuccess,
    tournament?.competitionDetailAdmin,
    tournament?.assignTeamPlayerSuccess,
  ]);
  const handleSubmitTeam = (): void => {
    const data = {
      players: allPlayersTeam,
    };
    const sentData = {
      id: id.id,
      data: data,
    };
    dispatch(assignTeamPlayers(sentData));
  };

  useEffect(() => {
    if (step === "team") {
      setPresent(2);
    }
  }, [step]);

  return (
    <DashboardLayout current={3}>
      <div className="w-full flex flex-col bg-[#f4f4f4] h-full p-[30px] 2xl:p-8 2xl:pr-[50px] ">
        <div className="flex w-fit" onClick={() => navigate(-1)}>
          <NavigationArrow />
        </div>
        {present === 0 && (
          <div className="bg-white rounded-[20px] flex flex-col  h-screen  mt-6 flow-hide  ">
            <div className="flex-grow">
              <div className="">
                <div className="pl-5 pb-3 pt-5 flex justify-between">
                  <p className="font-semibold text-sm text-customBlack">
                    Assign Players
                  </p>
                </div>
              </div>
              {/* breaker line */}
              <div className="w-full h-[1px] bg-[#cfcfcf] " />
              <div className="w-full h-[35px] flex items-center pl-5 bg-[#F4F4F4]  ">
                <p className="text-neutral-600 text-xs font-medium w-12">S/N</p>
                <p className="text-neutral-600 text-xs font-medium min-w-[350px]  ">
                  Names of Accepted Players
                </p>
                <p className="text-neutral-600 text-xs font-medium  ">
                  Club Name
                </p>
              </div>

              <div className="pl-5">
                {players?.length === 0 && (
                  <div className="flex mt-6 items-center justify-center gap-[10px] flex-col">
                    <span className="mx-auto">
                      <EmptyListIcon />
                    </span>
                    <p className="text-sm text-neutral-500 text-center">
                      No Player have joined
                    </p>
                  </div>
                )}
                {players?.length > 0 && (
                  <div className="w-full flex flex-col gap-4 pt-[22px]">
                    {players?.map((item: any, index: number) => {
                      return (
                        <div key={index} className="flex items-center  w-full ">
                          <p className="text-sm font-medium text-customBlack w-12">
                            {index + 1}
                          </p>
                          <span className="flex items-center gap-1 min-w-[350px] ">
                            <img
                              src={item?.image}
                              alt=""
                              onError={(e: any) => {
                                e.target.onerror = null; // Prevent looping
                                e.target.src = defaultImage;
                              }}
                              className="w-7 h-7 rounded-full "
                            />
                            <p className="text-sm font-medium text-customBlack  capitalize  ">
                              {item?.firstname} {item?.lastname ?? ""}
                            </p>
                          </span>
                          <p className="text-sm font-medium text-customBlack capitalize">
                            {item?._club?.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}

                <div
                  className={` ${
                    players.length < 7 ? "absolute bottom-0 left-0 right-0" : ""
                  } w-full flex justify-center mt-8 mx-auto max-w-[300px] pb-10`}
                >
                  <Btn
                    onClick={() => setPresent(1)}
                    active={players?.length > 0}
                    name={"Proceed"}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {/* CONTENT SESSION */}
        {present === 1 && (
          <div className="bg-white rounded-[20px] flex flex-col  h-screen  mt-6 flow-hide  ">
            <div className="flex-grow">
              <div className="">
                <div className="pl-5 pb-3 pt-5 flex justify-between">
                  <p className="font-semibold text-sm text-customBlack">
                    Assign Players
                  </p>
                </div>
              </div>
              {/* breaker line */}
              <div className="w-full h-[1px] bg-[#cfcfcf] " />
              <div className="w-full pl-5 pt-3">
                <p className="text-neutral-600 text-sm">
                  Kindly select the players and assign them to different
                  category
                </p>

                {tournament?.fetchLoading ? (
                  <div className="p-5">
                    <LoadingComponent />
                  </div>
                ) : (
                  <div className="w-full flex flex-col gap-[30px] mt-6">
                    {details?.groups?.map((team: any, index: number) => (
                      <SingleGroupPlayers
                        key={team._id}
                        item={team}
                        index={index}
                        playersList={players}
                        handlePlayerSelect={handlePlayerSelectGroup}
                      />
                    ))}
                  </div>
                )}

                <div className="w-full flex justify-center mt-8 mx-auto max-w-[300px] pb-10">
                  <Btn
                    onClick={handleSubmit}
                    active={allPlayers?.length > 0}
                    name={"Assign Players"}
                    loading={tournament?.loading}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* END OF CONTENT SESSION */}
        {present === 2 && (
          <div className="bg-white rounded-[20px] flex flex-col  h-screen  mt-6 flow-hide  ">
            <div className="flex-grow">
              <div className="">
                <div className="pl-5 pb-3 pt-5 flex justify-between">
                  <p className="font-semibold text-sm text-customBlack">
                    Assign Players
                  </p>
                </div>
              </div>
              {/* breaker line */}
              <div className="w-full h-[1px] bg-[#cfcfcf] " />
              <div className="w-full pl-5 pt-3">
                <p className="text-neutral-600 text-sm">
                  Kindly select the players and assign them to different teams
                </p>

                <div className="w-full flex flex-col gap-[30px] mt-6">
                  {teams?.map((team, index) => (
                    <SingleTeamPlayers
                      key={team._id}
                      item={team}
                      index={index}
                      playersList={allGroupedPlayers}
                      handlePlayerSelect={handlePlayerSelect}
                    />
                  ))}
                </div>

                <div className="w-full flex justify-center mt-8 mx-auto max-w-[300px] pb-10">
                  <Btn
                    onClick={handleSubmitTeam}
                    active={allPlayersTeam?.length > 0}
                    name={"Assign Players"}
                    loading={tournament?.loading}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ReUseModal open={openCat} setOpen={setOpenCat}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-customBlack space-y-1 text-[24px] font-bold text-center">
          Players Assigned
        </h1>
        <p className="text-center w-10/12 max-w-[331px] mx-auto text-neutral-500 text-sm">
          You’ve successfully assigned players to Categories
        </p>
        <Btn
          active={true}
          name=" Assigning Players to Teams"
          onClick={() => {
            dispatch(resetAll());
            setOpenCat(false);
            window.location.replace(
              `/admin/competition/assign-player/${id.id}?step=team`
            );
            dispatch(getSingleCompetitionDetailAdmin({ id: id.id }));
            setPresent(2);
          }}
          className="mt-[49px] mb-3"
        />
      </ReUseModal>
      <ReUseModal open={openTeam} setOpen={setOpenTeam}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-customBlack space-y-1 text-[24px] font-bold text-center">
          Players Assigned
        </h1>
        <p className="text-center w-10/12 max-w-[331px] mx-auto text-neutral-500 text-sm">
          You’ve successfully assigned players
        </p>
        <Btn
          active={true}
          name="Continue"
          onClick={() => {
            dispatch(resetAll());
            setOpenTeam(false);
            navigate("/admin/tournament");
          }}
          className="mt-[49px] mb-3"
        />
      </ReUseModal>
    </DashboardLayout>
  );
};

export default AssignPlayerCategoryPage;
