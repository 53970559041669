import receipt from "../../../assets/svgs/receipt.svg";
interface tableProps {
  className: string;
}

const ReservedCourtTable = ({ className }: tableProps) => {
  return (
    <div
      className={`w-691 h-322 top-619 left-262 rounded-20 w-full  h-full flex flex-col bg-white  rounded-[20px] ${className} `}
    >
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className=" font-inter text-xs font-medium leading-15 tracking-wider text-left text-customBlack">
          Reserved Courts
        </h1>
        {/* <p className="text-primaryYellow text-sm font-[500]">View All</p> */}
      </div>
      {/* Table section starts from here  #################################################3*/}
      <table className="min-w-full w-full h-full bg-[#FFFFFF]  rounded-b-[20px]  ">
        <thead className="w-691 h-35 top-55 opacity-30 bg-[#F4F4F4]">
          <tr className="grid grid-cols-12  h-[35px] items-center">
            <th className=" w-86 h-15 top-65 left-16 font-inter text-xs font-medium leading-15 tracking-wider text-left col-start-1 col-end-5 text-[#5E5E5E]  pl-4 ">
              Name of Court
            </th>
            <th className=" w-63 h-15 top-65 left-233 font-inter text-xs font-medium leading-15 tracking-wider text-left col-start-5 col-end-8 text-[#5E5E5E]  ">
              Booked By
            </th>

            <th className=" w-51 h-15 top-65 left-388 font-inter text-xs font-medium leading-15 tracking-wider text-left col-start-8 col-end-10 text-[#5E5E5E] ">
              Location
            </th>
            <th className="w-109 h-15 top-65 left-504 font-inter text-xs font-medium leading-15 tracking-wider text-left col-start-10 col-end-13 text-[#5E5E5E] ">
              Booked Date/Time
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="w-full ">
            <td className="w-full flex flex-col items-center justify-center h-full">
              <div className="  border-2.38 border-gray-600  flex items-center justify-center">
                <img
                  src={receipt}
                  className=" border-2.5 border-gray-600 w-38 h-38w-38 h-38"
                  alt=""
                />
              </div>
              <span className="font-inter text-xs font-medium leading-15 tracking-wider w-106 h-15 text-center mt-8">
                No reserved courts
              </span>
            </td>
          </tr>
        </tbody>

        {/* <tbody className="w-full  rounded-b-[20px] flex flex-col h-full   ">
          {data.slice(0, 6).map((item: any, index: number) => {
            return (
              <tr className="grid grid-cols-12  py-3  items-center" key={index}>
                <td className=" col-start-1 col-end-5 text-sm text-customBlack font-[500] text-center flex items-center space-x-2 pl-4 ">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="w-[26px] h-[26px] rounded-full"
                  />
                  <p>{item.name}</p>
                </td>
                <td className=" col-start-5 col-end-8 text-sm text-customBlack font-[500]  text-center ">
                  {item.bookedBy}
                </td>

                <td className=" col-start-8 col-end-10 text-sm text-customBlack font-[500]  text-center ">
                  {item.location}
                </td>
                <td className=" col-start-10 col-end-13 text-sm text-customBlack font-[500]  text-center ">
                  {item.bookDT}
                </td>
              </tr>
            );
          })}
        </tbody> */}
      </table>
      {/* Table section Ends from here $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$4 */}
    </div>
  );
};

export default ReservedCourtTable;
