import { TbBallTennis } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { Logo } from "../../assets/svgs";
import { restoreDefault } from "../../features/auth/SuperAdminSlice";

import { AiOutlineLogout, AiOutlineUser } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import { RxDashboard } from "react-icons/rx";
const styles = {
  active:
    "bg-[#EBF6D4] h-[42px] py-3 px-5 gap-[10px] border-r-[4px] rounded-l-[5px] border-r-[#9CD326] flex items-center text-[#82B020] text-sm  cursor-pointer ",
  inactive:
    "flex items-center hover:bg-[#EBF6D4] py-3 px-5 gap-[10px] text-sm text-[#868686] rounded-l-[5px] hover:text-[#82B020] cursor-pointer hover:border-r-[#9CD326] hover:border-r-[4px] ",
};
interface sidebarProps {
  current: number;
}
const SuperAdminSidebar = ({ current }: sidebarProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(restoreDefault());
    navigate("/super/login");
  };
  return (
    <div className="w-full fixed top-0 left-0 bottom-0 h-screen flex flex-col max-w-[250px]  border-r bg-white  ">
      <div className="w-full flex justify-center mt-12">
        <Logo width="100" height="73" />
      </div>
      <div className="flex flex-col pl-4">
        <p className="text-[#082431] text-xs opacity-50 my-6 pl-6">MENU</p>
        <div className="flex flex-col space-y-1">
          {/* start of a single item */}
          <div
            className={current === 1 ? styles.active : styles.inactive}
            onClick={() => navigate("/super/home")}
          >
            <RxDashboard className="text-lg" />
            <p>Dashboard</p>
          </div>
          {/* end of a single link item */}
          {/* start of a single item */}
          <div
            className={current === 2 ? styles.active : styles.inactive}
            onClick={() => navigate("/super/manage")}
          >
            <TbBallTennis className="text-lg" />
            <p>Manage Admin</p>
          </div>
          {/* end of a single link item */}
        </div>
      </div>
      {/* END OF THE UPPER SESSION OF THE SIDE BAR ############################################################################# */}
      <div className="flex flex-col pl-4 mt-12">
        <p className="text-[#082431] text-xs opacity-50 my-6 pl-6">OTHERS</p>
        <div className="flex flex-col space-y-1">
          {/* start of a single item */}
          <div className={current === 5 ? styles.active : styles.inactive}>
            <FiSettings className="text-lg" />
            <p>Settings</p>
          </div>
          {/* end of a single link item */}

          {/* start of a single item */}
          <div className={current === 7 ? styles.active : styles.inactive}>
            <AiOutlineUser className="text-lg" />
            <p>Account</p>
          </div>
          {/* end of a single link item */}

          {/* start of a single item */}
          <div
            className={current === 8 ? styles.active : styles.inactive}
            onClick={handleLogout}
          >
            <AiOutlineLogout className="text-lg" />
            <p>Logout</p>
          </div>
          {/* end of a single link item */}
        </div>
      </div>
    </div>
  );
};

export default SuperAdminSidebar;
