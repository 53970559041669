import { IoIosArrowForward } from "react-icons/io";
import { SolidTennisIcon } from "../../assets/new-svg/icons";
import { formatDate } from "../../utils";

interface cardProps {
  name: string;
  start: string;
  onClick: any;
  index: number;
}
const CompetitionCard = ({ onClick, index, name, start }: cardProps) => {
  return (
    <div
      className="w-full min-h-[69px] border-border border rounded-[12px] flex items-center justify-between px-4 cursor-pointer py-2 competition-card-shadow "
      key={index}
      onClick={onClick}
    >
      <div className="flex items-center gap-2">
        <SolidTennisIcon />
        <div className="flex flex-col">
          <p className="text-[14px] text-[#0E0E0E] p-0 m-0 font-semibold capitalize leading-[20px] truncate">
            {name}
          </p>
          <p className="text-[12px] text-[#898B86] leading-[16px]">
            Starts {formatDate(start)}
          </p>
        </div>
      </div>
      <div className="flex items-center">
        <IoIosArrowForward className="text-2xl text-lemonGreen" />
      </div>
    </div>
  );
};

export default CompetitionCard;
