import { useState } from "react";

import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import { LocationIcon } from "../../assets/svgs/court";

import { testData } from "./FindCourt";

const RecommendedCourt = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="w-full flex flex-col px-4 py-6 md:max-w-[428px] md:mx-auto md:h-screen">
        <div className="flex justify-between mt-6 items-center">
          <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
          <p className="text-[#303030] font-bold text-[15px] ">
            Recommended Courts
          </p>
          {/* <div className="more-shadow" onClick={() => navigate("/more-court")}>
            <More />
          </div> */}
          <span></span>
        </div>

        <div className="w-full grid grid-cols-2 mt-6 gap-6 ">
          {testData.map((item, index) => {
            return (
              <div className="w-full rc-shadow rounded-[20px]" key={index}>
                <div className="w-full flex flex-col relative h-full ">
                  <div className="w-full h-[121px]">
                    <img
                      src={item?.img}
                      alt=""
                      className="w-full h-[121px] object-cover rounded-t-[20px]"
                    />
                  </div>

                  <div className="flex flex-col text-[#303030] px-3 py-5">
                    <p className="font-bold text-[14px] ">{item?.name}</p>
                    <div className="flex items-center gap-1 mt-1">
                      <span>
                        <LocationIcon />
                      </span>
                      <p className="text-[12px]">{item?.location}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* end of the Destinations */}
      </div>
    </>
  );
};

export default RecommendedCourt;
