import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Btn } from "../../../components/Button";

import { toast } from "react-toastify";
import AdminAuthLayout from "../../../Layouts/AdminAuthLayout";
import logo from "../../../assets/svgs/love-logo.svg";
import { AdminPasswordInput } from "../../../components/Input";
import { AuthSubTitle, AuthTitle } from "../../../components/Text";
import {
  adminResetPassword,
  restoreDefault,
} from "../../../features/auth/AdminAuth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../firebase";
const AdminNewPassword = () => {
  const adminAuth = useAppSelector((state) => state.adminAuth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [active, setActive] = useState(false);
  useEffect(() => {
    dispatch(restoreDefault());
  }, []);
  useEffect(() => {
    if (confirmPassword && password) setActive(true);
    else setActive(false);
  }, [confirmPassword, password]);

  const handleSubmit = () => {
    const data = {
      password: password,
    };
    if (password === confirmPassword) {
      dispatch(adminResetPassword(data));
    } else {
      toast.error("Passwords do not match");
    }
    // the logic will be replaced here once we have the endpoint
  };

  useEffect(() => {
    navigate("/admin/login");
  }, [adminAuth?.resetPasswordSuccessful]);

  return (
    <div className="w-full ">
      <AdminAuthLayout>
        <div className="w-full flex h-full justify-center ">
          {/* left side  */}
          <div className="w-1/2 bg-white flex flex-col">
            <div className="mx-auto mt-8">
              <img src={logo} alt="logo" className="h-auto" />
            </div>
            <div className="flex flex-col justify-center items-center mt-28">
              <AuthTitle text="Create New Password" className="text-center" />
              <AuthSubTitle
                text="Your new password must be different from the one you used before."
                className="mt-[10px]"
              />
              <div className="flex flex-col lg:w-8/12 mx-auto xl:w-7/12 2xl:w-7/12 mt-10">
                <div className="flex flex-col mt-4">
                  <label className="text-neutral-400 text-sm mb-1 ">
                    Password
                  </label>
                  <AdminPasswordInput
                    placeholder="Enter your password"
                    classes=""
                    required
                    onChange={(e: any) => setPassword(e.target.value)}
                  />
                </div>
                <div className="flex flex-col mt-4">
                  <label className="text-neutral-400 text-sm mb-1 ">
                    Re-enter Password
                  </label>
                  <AdminPasswordInput
                    placeholder="Re-enter Password"
                    classes=""
                    required
                    onChange={(e: any) => setConfirmPassword(e.target.value)}
                  />
                </div>

                {/* button session */}
                <Btn
                  name="Reset"
                  className="w-full mt-12"
                  onClick={handleSubmit}
                  // Note here that the proper logic will be done once we have the endpoint
                  active={active}
                  loading={adminAuth.loading}
                />
                {/* end of button session */}
              </div>
            </div>
          </div>
          {/* end ofleft side  */}
          {/* right side  */}
          {/* <div className={`w-1/2 ${stages[current]} relative`}>
            <div className="absolute bottom-28 w-full flex justify-center left-0 right-0 px-16">
              <GlassProgress onClick={handleNext} current={current} />
            </div>
          </div> */}
          {/* end of the right hand side */}
        </div>
      </AdminAuthLayout>
    </div>
  );
};

export default AdminNewPassword;
