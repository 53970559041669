import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import useA from "../../assets/png/use1.jpg";
import useB from "../../assets/png/use2.jpg";
import useC from "../../assets/png/use3.jpg";
import useD from "../../assets/png/use4.jpg";
import { SearchIcon } from "../../assets/svgs";
import { LightLocationIcon, LocationIcon } from "../../assets/svgs/court";
import LoadingComponent from "../../components/Loading/Loader";
import {
  getAllBookedCourtUser,
  getAllCourtUser,
  getAllPendingCourtUser,
} from "../../features/auth/TournamentSlice";

export const testData = [
  {
    name: "Victory Court Limted",
    price: "N18,000",
    img: useA,
    location: "Ikeja Lagos",
  },
  {
    name: "Victory Court Limted",
    price: "N18,000",
    img: useB,
    location: "Ikeja Lagos",
  },
  {
    name: "Victory Court Limted",
    price: "N18,000",
    img: useC,
    location: "Ikeja Lagos",
  },
  {
    name: "Victory Court Limted",
    price: "N18,000",
    img: useD,
    location: "Ikeja Lagos",
  },
];
const styles = {
  active:
    "bg-white text-sm text-customBlack font-[600] border-[#0000000A] border-[1px] full flex items-center justify-center cursor-pointer h-[43px] active-t-btn  ",
  inactive:
    "text-sm text-[#afafaf] font-semibold flex items-center justify-center w-full  h-[25px] cursor-pointer",
};

const FindCourt = () => {
  const tournament = useAppSelector((state) => state.tournament);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [current, setCurrent] = useState<string>("all");
  useEffect(() => {
    dispatch(getAllCourtUser());
    dispatch(getAllBookedCourtUser());
    dispatch(getAllPendingCourtUser());
  }, []);

  if (tournament?.fetchLoading) {
    return (
      <div className="w-full md:max-w-[428px] mt-8 px-4 md:mx-auto ">
        <LoadingComponent />
      </div>
    );
  }
  return (
    <>
      <div className="w-full flex flex-col px-4 py-6 md:max-w-[428px] md:mx-auto md:h-screen">
        <div className="flex justify-between mt-6 items-center">
          <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
          <p className="text-[#303030] font-bold text-[15px] ">Find a Court</p>
          {/* <div className="more-shadow" onClick={() => navigate("/more-court")}>
            <More />
          </div> */}
          <span></span>
        </div>

        {/* start of search icon */}
        <div className="w-full rounded-[16px] flex space-x-3  px-6 py-4 mt-4  items-center bg-[#f4f4f4] h-[50px] ">
          <SearchIcon />
          <input
            type="text"
            className="w-10/12 bg-inherit placeholder:text-[#c6c6c6] text-[14px] placeholder:text-xs outline-none  "
            placeholder="Search Court"
          />
        </div>
        {/* end of search Icon */}

        {/* recoomended */}
        <div className="flex justify-between items-center mt-12">
          <p className="text-sm font-bold ">Recommended Court</p>
          <p
            className="underline text-primaryYellow font-semibold text-sm underline-offset-4 cursor-pointer  "
            onClick={() => navigate("/recommended-courts")}
          >
            See all
          </p>
        </div>
        <div className="w-full flow-hide-x mt-6 ">
          <div className="w-full flex items-center  ">
            {tournament?.allCourtUser.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  className="imag-shad-big relative"
                  style={{
                    width: "221px",
                    height: "180px",
                    minWidth: "221px",
                    minHeight: "180px",
                    marginRight: "16px",
                    marginBottom: "16px",
                    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${item?.image})`,
                    backgroundSize: "cover",
                  }}
                >
                  <div className="w-full flex flex-col relative h-full">
                    {/* <div className="flex justify-end pr-4 pt-8">
                      <div className="court-p-shad px-4 py-3 text-white font-bold text-[14px] ">
                        {item.price}
                      </div>
                    </div> */}
                    <div className=" flex justify-start pl-4 bottom-6 left-0 absolute ">
                      <div className="flex flex-col text-white">
                        <p className="font-bold text-[14px] capitalize truncate ">
                          {item?.name}
                        </p>
                        <div className="flex items-center gap-1 mt-1">
                          <span>
                            <LightLocationIcon />
                          </span>
                          <p className="text-[12px]">
                            {item?.state + ", " + item?.country}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* end of the recommended */}
        {/* START OF A FILTER PAGE */}
        <div className="flex flex-row w-full tounament-header-bg  h-[47px] items-center px-1 mt-[30px] mb-4 rounded-[9px] ">
          <div className="w-1/3" onClick={() => setCurrent("all")}>
            <p className={current === "all" ? styles.active : styles.inactive}>
              All Courts
            </p>
          </div>
          <div className="w-1/3 " onClick={() => setCurrent("booked")}>
            <p
              className={
                current === "booked"
                  ? styles.active
                  : ` ${styles.inactive} border-r border-r-[#0000000A] border-l border-l-[#0000000A] `
              }
            >
              Booked
            </p>
          </div>
          <div className="w-1/3" onClick={() => setCurrent("pending")}>
            <p
              className={
                current === "pending" ? styles.active : styles.inactive
              }
            >
              Pending
            </p>
          </div>
        </div>

        {/* END OF A FILTER PAGE */}
        {/* Top reccomendation */}

        {current === "all" && (
          <div className="w-full flex flex-col gap-5">
            {tournament?.allCourtUser?.map((item: any, index: number) => {
              return (
                <div
                  className="flex items-center cursor-pointer space-x-4 w-1/2"
                  key={index}
                  onClick={() => navigate(`/book-court/${item?._id}`)}
                >
                  <img
                    src={item?.image}
                    alt="use"
                    className="w-[70px] h-[70px] image-shad min-w-[70px] min-h-[70px]"
                  />
                  <div className="flex flex-col">
                    <p className="truncate text-[14px] font-bold capitalize text-[#303030]   ">
                      {item?.name}
                    </p>
                    <div className="flex gap-1 items-center w-full">
                      <span>
                        <LocationIcon />
                      </span>
                      <p className="text-[#303030] text-[12px] min-w-max ">
                        {item?.state + ", " + item?.country}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {current === "booked" && (
          <div className="w-full flex flex-col gap-5">
            {tournament?.allApprovedBookingsUser?.length === 0 && (
              <div>
                <p>No Court Approved yet</p>
              </div>
            )}
            {tournament?.allApprovedBookingsUser?.map(
              (item: any, index: number) => {
                return (
                  <div
                    className="flex items-center space-x-4 w-1/2"
                    key={index}
                  >
                    <img
                      src={item?._court?.image}
                      alt="use"
                      className="w-[70px] h-[70px] image-shad min-w-[70px] min-h-[70px]"
                    />
                    <div className="flex flex-col">
                      <p className="truncate text-[14px] font-bold capitalize text-[#303030]  ">
                        {item?._court?.name}
                      </p>
                      <div className="flex gap-1 items-center">
                        <span>
                          <LocationIcon />
                        </span>
                        <p className="text-[#303030] text-[12px] ">
                          {item?._court?.state + ", " + item?._court?.country}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        )}
        {current === "pending" && (
          <div className="w-full flex flex-col gap-5">
            {tournament?.allPendingBookingsUser?.length === 0 && (
              <div>
                <p className="text-base text-neutral-700 mt-5">
                  No Pending Court Bookings yet
                </p>
              </div>
            )}
            {tournament?.allPendingBookingsUser?.map(
              (item: any, index: number) => {
                return (
                  <div
                    className="flex items-center cursor-pointer space-x-4 w-1/2"
                    key={index}
                    onClick={() => navigate(`/court-reservation/${item?._id}`)}
                  >
                    <img
                      src={item?._court?.image}
                      alt="use"
                      className="w-[70px] h-[70px] image-shad min-w-[70px] min-h-[70px]"
                    />
                    <div className="flex flex-col">
                      <p className="truncate text-[14px] font-bold capitalize text-[#303030]  ">
                        {item?._court?.name}
                      </p>
                      <div className="flex gap-1 items-center">
                        <span>
                          <LocationIcon />
                        </span>
                        <p className="text-[#303030] text-[12px] ">
                          {item?._court?.state + ", " + item?._court?.country}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        )}

        {/* end of the Destinations */}
      </div>
    </>
  );
};

export default FindCourt;
