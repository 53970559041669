import { useState, useEffect, ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import receipt from "../../../../assets/svgs/receipt.svg";
import { EmptyIcon } from "../../../../assets/svgs/tournament";
import { useParams } from "react-router-dom";
import {
  getSingleInvitationUser,
  getSingleLadderDetail,
  resetAllLadderState,
  updateMatchScoreLadder,
} from "../../../../features/auth/ladder-slice";
import { updateMatchScore } from "../../../../features/auth/TournamentSlice";
import addNewSet from "../../../../assets/svgs/add-new-set.svg";
import { Btn } from "../../../Button";
import ReUseModal from "../../../Modal/ReUseModal";
import PrimarySelect from "../../../Selects/PrimarySelect";
import sentMark from "../../../../assets/svgs/sent-mark.svg";
import LoadingComponent from "../../../Loading/Loader";
interface IPayloadData {
  id: any;
  data: {
    winner: string | undefined;
    walkover: boolean;
    [key: string]:
      | { challenger: string; challenged: string }
      | string
      | boolean
      | undefined;
  };
}
const SingleLadderMatchTable = ({ className }: any) => {
  const dispatch = useAppDispatch();
  const ladder = useAppSelector((state) => state?.ladder);
  const [matches, setMatches] = useState<any>([]);
  const [openSuccess, setOpenSuccess] = useState(false);

  const id = useParams();
  useEffect(() => {
    dispatch(getSingleLadderDetail({ id: id.id }));
  }, []);

  useEffect(() => {
    if (ladder?.singleLadderMatches?.length > 0) {
      setMatches(ladder.singleLadderMatches);
    }
  }, [ladder?.singleLadderMatches, ladder?.singleLadderDetail]);

  useEffect(() => {
    if (ladder?.scoreMatchSuccess) {
      setOpenSuccess(true);
    }
  }, [ladder?.scoreMatchSuccess]);

  if (ladder?.loading) {
    return <LoadingComponent />;
  }
  return (
    <div
      className={`w-full  h-full min-h-[533px] flex flex-col bg-white  rounded-[20px] ${className} `}
    >
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className="text-sm font-semibold text-customBlack capitalize">
          Matches - {ladder?.singleLadder?.name}
        </h1>
      </div>
      <table className="min-w-full w-full h-full bg-[#fff] rounded-b-[20px]   ">
        <thead className=" bg-[#f4f4f4] bg-opacity-40 mb-2   ">
          <tr className="flex items-center h-[35px] pl-5">
            <th className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-start ">
              Challenge Date and Time
            </th>
            <th className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-playerA ">
              {" "}
              Challenger
            </th>

            <th className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-playerA ">
              {" "}
              Challenged
            </th>
            <th className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-playerA ">
              {" "}
              Score
            </th>
            <th className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-playerA ">
              Winner
            </th>
            <th className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-playerA ">
              Status
            </th>
          </tr>
        </thead>
        <tbody className="w-full  mb-4 mt-2">
          {matches?.length > 0 && (
            <>
              {matches?.map((item: any, index: any) => {
                return <SingleForm index={index} item={item} />;
              })}
            </>
          )}
          {matches?.length === 0 && (
            <tr>
              <td className="w-full flex items-center justify-center h-full">
                <div className="justify-center flex flex-col items-center">
                  <span>
                    <EmptyIcon />
                  </span>
                  <span className="font-inter text-xs font-medium leading-15 tracking-wider w-106 h-15 text-center mt-5">
                    No match has been played
                  </span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="w-full h-[36px] items-center flex justify-between pl-4 pr-8  mt-3 mb-4">
        {/* pagination */}
        {/* <div className="flex items-center">
          <p className="text-xs">Page</p>
          <p className="px-1 rounded-sm border border-border text-customBlack font-bold cursor-pointer text-sm mx-2 ">
            1
          </p>
          <p className="text-sm text-customBlack">of 2</p>
          <div className="space-x-2 items-center ml-8 flex">
            <span className="text-base px-1 bg-[#f4f4f4] text-[#cfcfcf] opacity-70 rounded-sm border border-border">
              <IoIosArrowBack />
            </span>
            <span className="text-base px-1 bg-[#f4f4f4] text-customBlack opacity-70 rounded-sm border border-border">
              <IoIosArrowForward />
            </span>
          </div>
        </div> */}
        {/* enteries */}
        {/* <div className="flex">
          <p>No of entries </p>
          <div>
            <p className="px-1 rounded-sm border border-border text-customBlack font-bold cursor-pointer text-sm mx-2 ">
              10 per page
            </p>
          </div>
        </div> */}
      </div>
      <ReUseModal open={openSuccess} setOpen={setOpenSuccess}>
        <div className="w-full flex flex-col">
          <img src={sentMark} alt="sent" className="mx-auto mt-6" />
          <h1 className="text-customBlack text-[18px] font-bold text-center ">
            Match Scored
          </h1>
          <p className="text-center w-10/12 mx-auto text-neutral-500 text-sm ">
            You’ve successfully scored this match
          </p>
          <Btn
            active={true}
            name="Close"
            onClick={() => {
              setOpenSuccess(false);
              dispatch(resetAllLadderState());
              dispatch(getSingleLadderDetail({ id: id.id }));
            }}
            className="mt-[25px] mb-3"
          />
        </div>
      </ReUseModal>
    </div>
  );
};

export default SingleLadderMatchTable;

const SingleForm = ({ item, index }: any) => {
  const dispatch = useAppDispatch();
  const ladder = useAppSelector((state) => state.ladder);
  const [isWalkOver, setIsWalkOver] = useState<boolean>(false);

  const {
    challenged,
    challenger,
    challengerB,
    challengedB,
    winner,
    setA,
    setB,
    setC,
    setD,
    setE,
    round,
    invitation,
    time,
  } = item;
  function formatDateTime(dateTimeString: string) {
    if (!dateTimeString) return "No Date & Time";
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateTimeString);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const meridian = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12 || 12;

    return `${day}-${month}-${year}, ${hours}:${minutes} ${meridian}`;
  }

  const [openEdit, setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);

  const [selected, setSelected] = useState<any>({});
  const [matchScoreSet, setMatchScoreSet] = useState(
    parseInt(setC?.challenger) > 0 ? 3 : 2
  );
  const [errorMaxSet, setErrorMaxSet] = useState("");

  const listofMatchSets = Array.from(
    { length: matchScoreSet },
    (_, i) => i + 1
  );

  const selectData = [
    {
      name: challenger?.firstname,
      id: challenger?._id,
    },
    {
      name: challenged?.firstname,
      id: challenged?._id,
    },
  ];

  const [oneChallenged, setOneChallenged] = useState(
    !setA?.challenged ? "0" : `${setA?.challenged}`
  );
  const [oneChallenger, setOneChallenger] = useState(
    !setA?.challenger ? "0" : `${setA?.challenger}`
  );

  const [twoChallenged, setTwoChallenged] = useState(
    !setB?.challenged ? "0" : `${setB?.challenged}`
  );
  const [twoChallenger, setTwoChallenger] = useState(
    !setB?.challenger ? "0" : `${setB?.challenger}`
  );

  const [threeChallenged, setThreeChallenged] = useState(
    !setC?.challenged ? "0" : `${setC?.challenged}`
  );
  const [threeChallenger, setThreeChallenger] = useState(
    !setC?.challenger ? "0" : `${setC?.challenger}`
  );

  const [fourthChallenged, setFourthChallenged] = useState(
    !setD?.challenged ? "0" : `${setD?.challenged}`
  );
  const [fourthChallenger, setFourthChallenger] = useState(
    !setD?.challenger ? "0" : `${setD?.challenger}`
  );

  const [fifthChallenged, setFifthChallenged] = useState(
    !setE?.challenged ? "0" : `${setE?.challenged}`
  );
  const [fifthChallenger, setFifthChallenger] = useState(
    !setE?.challenger ? "0" : `${setE?.challenger}`
  );

  useEffect(() => {
    if (openEdit) {
      setOneChallenged(!setA?.challenged ? "0" : `${setA?.challenged}`);
      setOneChallenger(!setA?.challenger ? "0" : `${setA?.challenger}`);

      setTwoChallenged(!setB?.challenged ? "0" : `${setB?.challenged}`);
      setTwoChallenger(!setB?.challenger ? "0" : `${setB?.challenger}`);

      setThreeChallenged(!setC?.challenged ? "0" : `${setC?.challenged}`);
      setThreeChallenger(!setC?.challenger ? "0" : `${setC?.challenger}`);

      setFourthChallenged(!setD?.challenged ? "0" : `${setD?.challenged}`);
      setFourthChallenger(!setD?.challenger ? "0" : `${setD?.challenger}`);

      setFifthChallenged(!setE?.challenged ? "0" : `${setE?.challenged}`);
      setFifthChallenger(!setE?.challenger ? "0" : `${setE?.challenger}`);

      setMatchScoreSet(parseInt(setC?.challenger) > 0 ? 3 : 2);
    }
  }, [openEdit]);

  useEffect(() => {
    if (ladder.scoreMatchSuccess) {
      // toast.success("updateSUCCES")
      setOpenEdit(false);
    }
  }, [ladder?.scoreMatchSuccess]);
  const id = useParams();
  const UpdateScore = () => {
    const payLoaddata: IPayloadData = {
      id: item?._id,
      data: {
        winner: selected?.id,
        walkover: isWalkOver,
        setA: {
          challenger: oneChallenger,
          challenged: oneChallenged,
        },
        setB: {
          challenger: twoChallenger,
          challenged: twoChallenged,
        },
      },
    };
    if (threeChallenged !== "0" || threeChallenger !== "0") {
      payLoaddata.data.setC = {
        challenger: threeChallenger,
        challenged: threeChallenged,
      };
    }
    if (fourthChallenged !== "0" || fourthChallenger !== "0") {
      payLoaddata.data.setD = {
        challenger: fourthChallenger,
        challenged: fourthChallenged,
      };
    }
    if (fifthChallenged !== "0" || fifthChallenger !== "0") {
      payLoaddata.data.setE = {
        challenger: fifthChallenger,
        challenged: fifthChallenged,
      };
    }

    dispatch(updateMatchScoreLadder(payLoaddata));
  };

  const addMatchSet = () => {
    if (matchScoreSet >= 5) {
      setErrorMaxSet("match set limit reached");
      setTimeout(function () {
        setErrorMaxSet("");
      }, 3000);
      return;
    }
    setMatchScoreSet(matchScoreSet + 1);
  };

  const handleChallengedOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    item: number
  ) => {
    const inputValue = e.target.value;
    if (inputValue.length > 1) {
      if (item === 1) {
        setOneChallenged(inputValue.toString().substring(0, 1));
      } else if (item === 2) {
        setTwoChallenged(inputValue.toString().substring(0, 1));
      } else if (item === 3) {
        setThreeChallenged(inputValue.toString().substring(0, 1));
      } else if (item === 4) {
        setFourthChallenged(inputValue.toString().substring(0, 1));
      } else if (item === 5) {
        setFifthChallenged(inputValue.toString().substring(0, 1));
      }
      return;
    }

    if (item === 1) {
      setOneChallenged(inputValue);
    }
    if (item === 2) {
      setTwoChallenged(inputValue);
    }
    if (item === 3) {
      setThreeChallenged(inputValue);
    }
    if (item === 4) {
      setFourthChallenged(inputValue);
    }
    if (item === 5) {
      setFifthChallenged(inputValue);
    }
  };
  const handleChallengerOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    item: number
  ) => {
    const inputValue = e.target.value;
    if (inputValue.length > 1) {
      if (item === 1) {
        setOneChallenger(inputValue.toString().substring(0, 1));
      } else if (item === 2) {
        setTwoChallenger(inputValue.toString().substring(0, 1));
      } else if (item === 3) {
        setThreeChallenger(inputValue.toString().substring(0, 1));
      } else if (item === 4) {
        setFourthChallenger(inputValue.toString().substring(0, 1));
      } else if (item === 5) {
        setFifthChallenger(inputValue.toString().substring(0, 1));
      }

      return;
    }
    if (item === 1) {
      setOneChallenger(inputValue);
    }
    if (item === 2) {
      setTwoChallenger(inputValue);
    }
    if (item === 3) {
      setThreeChallenger(inputValue);
    }
    if (item === 4) {
      setFourthChallenger(inputValue);
    }
    if (item === 5) {
      setFifthChallenger(inputValue);
    }
  };
  let scores = `${setA?.challenger ? setA?.challenger : "0"} - ${
    setA?.challenged ? setA?.challenged : "0"
  }, ${" "} ${setB?.challenger ? setB?.challenger : "0"} - ${
    setB?.challenged ? setB?.challenged : "0"
  }, ${" "} ${setC?.challenger ? setC?.challenger : "0"} - ${
    setC?.challenged ? setC?.challenged : "0"
  }`;

  return (
    <>
      <tr className="flex items-center h-[35px] pl-5">
        <td className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-start ">
          {formatDateTime(time)}
        </td>
        <td className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-playerA  capitalize flex justify-center">
          {challenger?.firstname + " "}{" "}
          {challenger?.lastname && challenger?.lastname}
        </td>

        <td className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-playerA capitalize flex justify-center ">
          {challenged?.firstname + " "}{" "}
          {challenged?.lastname && challenged?.lastname}
        </td>
        <td className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-playerA capitalize flex justify-center ">
          {scores}
        </td>
        <td className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-playerA  flex justify-center capitalize ">
          {winner === challenger?._id && (
            <p>
              {challenger?.firstname + " "}{" "}
              {challenger?.lastname && challenger?.lastname}
            </p>
          )}
          {winner === challenged?._id && (
            <p>
              {challenged?.firstname + " "}{" "}
              {challenged?.lastname && challenged?.lastname}
            </p>
          )}
          {winner === null && <p> - </p>}
        </td>
        <td className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-playerA  flex justify-center">
          {invitation === "accepted" && (
            <div className="flex flex-col">
              {winner === null && (
                <p
                  className="text-primaryYellow font-medium text-sm underline cursor-pointer"
                  onClick={() => setOpenEdit(true)}
                >
                  Update Score
                </p>
              )}
              {winner !== null && (
                <p
                  className="text-primaryYellow font-medium text-sm underline cursor-pointer"
                  onClick={() => setOpenView(true)}
                >
                  View Score
                </p>
              )}
            </div>
          )}
        </td>
      </tr>
      <ReUseModal open={openEdit} setOpen={setOpenEdit}>
        <span className="w-full">
          <h1>Update Match Score</h1>
          {/* start of set */}
          <span className="border-t-2 border-neutral300-500 mt-7"></span>
          {listofMatchSets.map((item) => {
            return (
              <span
                className={`flex flex-col ${
                  isWalkOver ? "opacity-30 cursor-not-allowed" : "opacity-100"
                }`}
              >
                <label
                  htmlFor=""
                  className="text-customBlack font-semibold mb-2 mt-6 text-base"
                >
                  SET {item}:
                </label>
                <span className="w-full flex flex-col space-y-4">
                  <span className="w-3/4 flex justify-between mb-2 mt-3">
                    <p className="text-base text-neutral-700 font-medium capitalize">
                      {challenger?.firstname || "BYE"}
                    </p>
                    <input
                      type="number"
                      className="w-[40px] py-1 px-2 text-sm rounded-md small-input"
                      min={1}
                      max={10}
                      value={
                        item === 1
                          ? oneChallenger
                          : item === 2
                          ? twoChallenger
                          : item === 3
                          ? threeChallenger
                          : item === 4
                          ? fourthChallenger
                          : item === 5
                          ? fifthChallenger
                          : item
                      }
                      onChange={(e) => handleChallengerOnChange(e, item)}
                    ></input>
                  </span>
                  <span className="w-3/4 flex justify-between mb-2">
                    <p className="text-base text-neutral-700 font-medium capitalize">
                      {challenged?.firstname || "BYE"}
                    </p>
                    <input
                      type="number"
                      className="w-[40px] py-1 px-2 text-sm rounded-md small-input"
                      min={1}
                      max={10}
                      value={
                        item === 1
                          ? oneChallenged
                          : item === 2
                          ? twoChallenged
                          : item === 3
                          ? threeChallenged
                          : item === 4
                          ? fourthChallenged
                          : item === 5
                          ? fifthChallenged
                          : item
                      }
                      onChange={(e) => handleChallengedOnChange(e, item)}
                    ></input>
                  </span>
                </span>
              </span>
            );
          })}
          <span className="w-full my-5 cursor-pointer" onClick={addMatchSet}>
            <img src={addNewSet} className="object-contain h-[30px]" alt="" />
          </span>
          <p className="text-xs text-red-600">{errorMaxSet}</p>
          <div className="w-full gap-5 items-center flex mt-5">
            <input
              type="checkbox"
              name=""
              id=""
              className="accent-lemonGreen w-5 h-5"
              onClick={() => setIsWalkOver(!isWalkOver)}
              checked={isWalkOver}
            />
            <p className="text-sm text-neutral-700">Mark as walkover</p>
          </div>
          <span className="w-10/12 mt-6 mb-10 flex flex-col">
            <PrimarySelect
              label={"Select the  Winner"}
              selected={selected}
              setSelected={setSelected}
              data={selectData}
            />
          </span>
          <span className="w-10/12 mt-10 mx-auto">
            <Btn
              name="Update"
              active={true}
              onClick={UpdateScore}
              loading={ladder?.loading}
            />
          </span>
        </span>
      </ReUseModal>
      <ReUseModal open={openView} setOpen={setOpenView}>
        <span className="w-full flex flex-col">
          <h1>View Match Score</h1>
          <span className="flex flex-col">
            <label
              htmlFor=""
              className="text-customBlack font-semibold mb-2 mt-6 text-base"
            >
              SET 1:
            </label>
            <span className="w-full flex flex-col">
              <span className="w-8/12 flex justify-between mb-2">
                <p className="text-sm text-neutral-700 capitalize">
                  {challenger?.firstname}
                </p>
                <p className="text-sm text-neutral-700 capitalize">
                  {challenged?.firstname}
                </p>
              </span>
              <span className="flex justify-between w-8/12">
                <p className="text-customBlack text-lg font-bold">
                  {setA?.challenger}
                </p>
                <p className="text-customBlack text-lg font-bold">
                  {setA?.challenged}
                </p>
              </span>
            </span>
          </span>
          <span className="flex flex-col">
            <label
              htmlFor=""
              className="text-customBlack font-semibold mb-2 mt-6 text-base "
            >
              SET 2:
            </label>
            <span className="w-full flex flex-col">
              <span className="w-8/12 flex justify-between mb-2">
                <p className="text-sm text-neutral-700 capitalize">
                  {challenger?.firstname}
                </p>
                <p className="text-sm text-neutral-700 capitalize">
                  {challenged?.firstname}
                </p>
              </span>
              <span className="flex justify-between w-8/12">
                <p className="text-customBlack text-lg font-bold">
                  {setB?.challenger}
                </p>
                <p className="text-customBlack text-lg font-bold">
                  {setB?.challenged}
                </p>
              </span>
            </span>
          </span>
          {/* enf pf set */}
          {/* start of set */}
          <span className="flex flex-col">
            <label
              htmlFor=""
              className="text-customBlack font-semibold mb-2 mt-6 text-base"
            >
              SET 3:
            </label>
            <span className="w-full flex flex-col">
              <span className="w-8/12 flex justify-between mb-2">
                <p className="text-sm text-neutral-700 capitalize">
                  {challenger?.firstname}
                </p>
                <p className="text- text-neutral-700 capitalize">
                  {challenged?.firstname}
                </p>
              </span>
              <span className="flex justify-between w-8/12">
                <p className="text-customBlack text-lg font-bold">
                  {setC?.challenger}
                </p>
                <p className="text-customBlack text-lg font-bold">
                  {setC?.challenged}
                </p>
              </span>
            </span>
          </span>
          {/* enf pf set */}

          <span className="w-10/12 mt-10 mx-auto ">
            <Btn name="Back" active={true} onClick={() => setOpenView(false)} />
          </span>
        </span>
      </ReUseModal>
    </>
  );
};
