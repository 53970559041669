import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";

import { dummyTeamData } from "../../../../utils/laddersampdata";
import { useParams } from "react-router-dom";
import { getSingleCompetitionDetailAdmin } from "../../../../features/auth/TournamentSlice";
import LoadingComponent from "../../../Loading/Loader";
import { EmptyIcon } from "../../../../assets/svgs/tournament";

const TeamMembersTable = () => {
  const tournament = useAppSelector((state) => state.tournament);
  const dispatch = useAppDispatch();
  const id = useParams();

  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    _id: "",
  });
  const [playerGroup, setPlayerGroup] = useState([]);

  useEffect(() => {
    dispatch(getSingleCompetitionDetailAdmin({ id: id.id }));
  }, []);

  const details = tournament?.competitionDetailAdmin;
  const categories = tournament?.competitionDetailAdmin?.groups;

  // Initialize selectedCategory with the first category
  useEffect(() => {
    if (categories && categories.length > 0) {
      setSelectedCategory(categories[0]);
    }
  }, [categories]);

  // Compute groupedData based on the current selectedCategory
  const groupedData = useMemo(() => {
    return details?.teams.map((team: { _id: any; name: any }) => {
      const teamPlayers = details.players.filter(
        (player: { team: any; group: string }) =>
          player.team === team._id && player.group === selectedCategory._id
      );
      return {
        name: team.name,
        players: teamPlayers,
      };
    });
  }, [details, selectedCategory]);

  // Update playerGroup whenever selectedCategory changes
  useEffect(() => {
    setPlayerGroup(groupedData);
  }, [groupedData]);
  console.log(playerGroup);

  // You can now map playerGroup in your JSX

  if (tournament?.fetchLoading) {
    return (
      <div className="w-full p-5">
        <LoadingComponent />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="w-full flex items-center flex-wrap gap-2 mb-[6px] mt-6  ">
        {categories?.map((item: any, index: number) => {
          return (
            <div
              className={`${
                selectedCategory?._id === item?._id
                  ? "bg-[#EFFAE3] border border-[#E4E4E7] rounded-md"
                  : "hover:bg-[#fff] hover:border hover:border-[#E4E4E7] rounded-md"
              } px-4 h-9 flex items-center justify-center cursor-pointer  `}
              key={index}
              onClick={() => setSelectedCategory(item)}
            >
              {item?.name}
            </div>
          );
        })}
      </div>
      {playerGroup?.map((item: any, index: number) => {
        return (
          <div
            className="bg-white rounded-[20px] flex flex-col    mt-6 flow-hide  "
            key={index}
          >
            <div className="flex-grow">
              <div className="">
                <div className="p-5 flex justify-between">
                  <p className="font-medium text-sm sm:text-md">
                    {" "}
                    {item?.name} Team
                  </p>
                </div>
              </div>
              {/* breaker line */}
              <div className="w-full h-[1px] bg-[#cfcfcf] " />

              <table className="w-full flex flex-col border border-border bg-white  ">
                <thead className="w-full bg-[#f4f4f4] bg-opacity-40 ">
                  <tr className="flex items-center h-[35px] pl-5">
                    <th className="w-2/12 flex flex-grow theader text-neutral-700 text-xs font-[500] tracking-[0.12px] gap-6 items-center">
                      <span>Names</span>
                    </th>
                    <th className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left ">
                      Email Address
                    </th>
                    <th className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left">
                      Categories
                    </th>
                    <th className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left">
                      Points
                    </th>
                    <th className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left">
                      Matches
                    </th>

                    <th className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {item?.players?.length === 0 && (
                    <tr className="flex items-center h-[150px] justify-center ">
                      <td className="flex flex-col items-center">
                        <span>
                          <EmptyIcon />
                        </span>
                        <p className="text-center text-xs text-neutral-600">
                          No Player in this category
                        </p>
                      </td>
                    </tr>
                  )}
                  {item?.players?.length > 0 &&
                    item?.players?.map((opt: any, index: any) => {
                      return (
                        <tr
                          className="h-[49px] items-center flex  border-b border-b-border "
                          key={index}
                        >
                          <td className="w-2/12 flex flex-grow   items-center pl-5 ">
                            <p className="text-customBlack text-sm font-medium capitalize">
                              {opt?._id?.firstname} {opt?._id?.lastname ?? ""}
                            </p>
                          </td>
                          <td className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left pl-2">
                            <p className="text-customBlack text-sm font-medium truncate">
                              {opt?._id?.email}
                            </p>
                          </td>
                          <td className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left pl-2">
                            <p className="text-customBlack text-sm font-medium truncate capitalize">
                              {selectedCategory?.name ?? ""}
                            </p>
                          </td>
                          <td className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left pl-2">
                            <p className="text-customBlack text-sm font-medium">
                              {Number(opt?.win * 3)}
                            </p>
                          </td>
                          <td className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left pl-2">
                            <p className="text-customBlack text-sm font-medium">
                              {opt?.matches}
                            </p>
                          </td>
                          <td className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-left pl-2">
                            <p className="text-[#B00020] underline text-sm font-medium">
                              Remove Player
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TeamMembersTable;
