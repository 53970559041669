import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { IoIosArrowBack } from "react-icons/io";
import { More } from "../../../assets/svgs/court";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getAllCourtUser } from "../../../features/auth/ladder-slice";
import { Btn } from "../../../components/Button";
import {
  getSingleCompetitionDetailUser,
  getSingleCompetitionMatchUser,
} from "../../../features/auth/TournamentSlice";
import LoadingComponent from "../../../components/Loading/Loader";
import { convertDateTime } from "../../../utils";
interface FormattedDateTime {
  date: string;
  time: string;
}
const ViewCompetitionMatchUser = () => {
  const user = useAppSelector((state) => state.auth);
  const ladder = useAppSelector((state) => state.ladder);
  const tournament = useAppSelector((state) => state.tournament);
  const player = useAppSelector((state) => state.player);
  const [details, setDetails] = useState<any>({});
  const [tournamentInfo, setTournamentInfo] = useState<any>({});
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [court, setCourt] = useState("");

  const [searchParams] = useSearchParams();
  const matchId = searchParams.get("matchId");

  const id = useParams();
  const item = player?.allSentInvitation?.filter(
    (item: any) => item?._id === id.id
  );
  useEffect(() => {
    dispatch(getAllCourtUser());
    dispatch(getSingleCompetitionMatchUser({ id: id.id, matchId: matchId }));
    dispatch(getSingleCompetitionDetailUser({ id: id.id }));
  }, []);

  useEffect(() => {
    if (ladder?.allCourts?.length !== 0) {
      const matchCourt = ladder?.allCourts?.filter(
        (item: any) => item?._id === details?.court
      );
      setCourt(matchCourt[0]?.name || "");
    }
    setDetails(tournament?.singleCompetitionMatchDetailUser);
    setTournamentInfo(tournament?.competitionDetailUser);
  }, [ladder?.allCourts, tournament.singleCompetitionMatchDetailUser]);

  function getTeamName(playerId: any, tournamentInfo: any) {
    const player = tournamentInfo.players?.find(
      (p: any) => p?._id?._id === playerId
    );
    if (player) {
      const team = tournamentInfo?.teams?.find(
        (t: any) => t?._id === player.team
      );
      return team ? team?.name : null;
    }
    return null;
  }

  function getChallengerAndChallengedTeamNames(
    detail: any,
    tournamentInfo: any
  ) {
    const challengerTeamName = getTeamName(
      detail?.challenger?._id,
      tournamentInfo
    );
    const challengedTeamName = getTeamName(
      detail?.challenged?._id,
      tournamentInfo
    );
    return { challengerTeamName, challengedTeamName };
  }
  const challengerAndChallenged = getChallengerAndChallengedTeamNames(
    details,
    tournamentInfo
  );

  function formatDateTime(inputDate: string): FormattedDateTime {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
    };
    const date: Date = new Date(inputDate);

    const dayMonthFormat: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
    };
    const formattedDate: string = date
      .toLocaleDateString("en-US", dayMonthFormat)
      .toUpperCase();

    const formattedTime: string = `${date.getHours()}:${
      date.getMinutes() < 10 ? "0" : ""
    }${date.getMinutes()}`;

    return { date: formattedDate, time: formattedTime };
  }

  const realDayTime = convertDateTime(details?.time);

  if (tournament?.fetchLoading) {
    return (
      <div className="w-full md:max-w-[428px] md:mx-auto mt-4 px-4">
        <LoadingComponent />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col px-4 pb-10 relative h-screen md:max-w-[428px] md:mx-auto">
      <div className="flex justify-between mt-6 items-center mb-8">
        <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
        <p className="text-[#303030] font-bold text-[15px] ">Match Invite</p>
        <div className="more-shadow opacity-0">
          <More />
        </div>
      </div>
      <div className="w-full bg-[#FFA800] h-[250px] rounded-[24px] p-4 flex space-x-6 justify-between">
        <div className="flex flex-col">
          <img
            src={details?.challenger?.image}
            alt="profile avatar"
            className="w-[120px] h-[120px] object-cover rounded-[20px]"
          />
          <p className="text-white text-base font-bold mb-2 mt-1 text-center capitalize">
            {details?.challenger?.firstname}
            <br />
            {details?.challenger?.lastname}
          </p>
          <div className="h-[29px] bg-white flex items-center justify-center px-4 rounded-3xl text-ash text-xs  ">
            {challengerAndChallenged?.challengerTeamName}
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-base font-bold text-white mt-8">VS</p>
        </div>
        <div className="flex flex-col">
          <img
            src={details?.challenged?.image}
            alt="profile avatar"
            className="w-[120px] h-[120px] object-cover rounded-[20px]"
          />
          <p className="text-white text-base font-bold mb-2 mt-1 text-center capitalize">
            {details?.challenged?.firstname}
            <br />
            {details?.challenged?.lastname}
          </p>
          <div className="h-[29px] bg-white flex items-center justify-center px-4 rounded-3xl text-ash text-xs  ">
            {challengerAndChallenged?.challengedTeamName}
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-8 space-y-4">
        {/* start of partl */}
        <div className="flex flex-col">
          <p className="text-sm text-customBlack font-bold mb-4">
            Match Details
          </p>
        </div>

        {/* end of a pack */}

        <div className="w-full  flex items-center   justify-between rounded-[10px] mb-4">
          <p className=" text-sm text-[#5E5E5E]"> Match Date</p>
          <p className="text-sm font-bold text-customBlack">
            {realDayTime?.otherDate}
          </p>
        </div>
        <div className="w-full  flex items-center justify-between mb-4">
          <p className=" text-sm text-[#5E5E5E]">Time</p>
          <p className="text-sm font-bold text-customBlack">
            {realDayTime?.time}
          </p>
        </div>
        <div className="w-full  flex items-center justify-between  mb-4">
          <p className=" text-sm text-[#5E5E5E]">Court</p>
          <p className="text-sm font-bold text-customBlack capitalize">
            {court}
          </p>
        </div>
        <div className="w-full  flex items-center justify-between  mb-3">
          <p className=" text-sm text-[#5E5E5E]">Type of Competition</p>
          <p className="text-sm font-bold text-customBlack capitalize">
            {tournament?.competitionDetailUser?.team === true
              ? "Team Competition"
              : "Single Competition"}
          </p>
        </div>
        {/* end of a pack */}
      </div>
    </div>
  );
};

export default ViewCompetitionMatchUser;
