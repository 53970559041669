import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSimplifiedError } from "../../utils";
import { APIService, nAPIService } from "../../utils/APIServices";
import { url } from "../../utils/endpoints";
export interface TournamentState {
  loading: boolean;
  error: any;
  createCourtSuccess: boolean;
  fetchLoading: boolean;
  bookCourtSuccess: boolean;
  allCourts: any;
  allBookings: any;
  editBookingSuccess: boolean;
  editCourtSuccess: boolean;
  deleteCourtSuccess: boolean;
  createFirstComeSuccess: boolean;
  createPrefillSuccess: boolean;
  allTournament: any;
  allAcceptedPlayers: any;
  allActivePlayers: any;
  allMatches: any;
  editTournamentSuccess: boolean;
  deleteTournamentSuccess: boolean;
  startTournamentSuccess: boolean;
  tournamentParams: string;
  matchInTournament: any;
  matchInTournamentLoading: boolean;
  updateMatchScoreSuccess: boolean;
  updateCompetitionMatchScoreSuccess: boolean;
  nextRoundSuccess: boolean;
  startNextRoundSuccess: boolean;
  tournamentStatus: string;
  prefillAddSuccess: boolean;
  createCompetionSuccess: boolean;
  addCompetionPlayersSuccess: boolean;
  startCompetionSuccess: boolean;
  createCompetitionData: any;
  competitionId: string;
  addMatchSuccess: boolean;
  deleteMatchSuccess: boolean;
  allEvaluationsAdmin: any;
  rankPlayerSuccess: boolean;
  allCourtUser: object[];
  bookCourtSuccessUser: boolean;
  allBookingsUser: object[];
  singleCourtBookingUser: any;
  singleCourtDetailUser: any;
  cancelBookingSuccess: boolean;
  allPendingBookingsUser: object[];
  allApprovedBookingsUser: object[];
  lockPlayerSuccess: boolean;
  updateBookingSuccess: boolean;
  allCompetitionsAdmin: any;
  competitionDetailUser: any;
  competitionDetailAdmin: any;
  joinedCompetitionUser: any;
  assignTeamPlayerSuccess: boolean;
  addCompetitionMatchSuccess: boolean;
  deleteCompetitionSuccess: boolean;
  allCompetitionMatches: any;
  addCategoriesSuccess: boolean;
  assignPlayerCategoriesSuccess: boolean;
  singleCompetitionMatchDetailUser: any;
  allCategories: { name: string; _id: string }[];
  dummyCompetitionDetail: any;
}

const initialState: TournamentState = {
  loading: false,
  error: {},

  createCourtSuccess: false,
  bookCourtSuccess: false,
  allCourts: [],
  allBookings: [],
  editBookingSuccess: false,
  editCourtSuccess: false,
  deleteCourtSuccess: false,
  createFirstComeSuccess: false,
  createPrefillSuccess: false,
  allTournament: [],
  allAcceptedPlayers: [],
  allActivePlayers: [],
  allMatches: [],
  editTournamentSuccess: false,
  deleteTournamentSuccess: false,
  startTournamentSuccess: false,
  startNextRoundSuccess: false,
  tournamentParams: "",
  matchInTournament: [],
  matchInTournamentLoading: false,
  updateMatchScoreSuccess: false,
  updateCompetitionMatchScoreSuccess: false,
  nextRoundSuccess: false,
  tournamentStatus: "",
  prefillAddSuccess: false,
  createCompetionSuccess: false,
  addCompetionPlayersSuccess: false,
  startCompetionSuccess: false,
  createCompetitionData: {},
  competitionId: "",
  addMatchSuccess: false,
  deleteMatchSuccess: false,
  allEvaluationsAdmin: [],
  rankPlayerSuccess: false,
  allCourtUser: [],
  bookCourtSuccessUser: false,
  allBookingsUser: [],
  singleCourtBookingUser: {},
  cancelBookingSuccess: false,
  fetchLoading: false,
  allPendingBookingsUser: [],
  allApprovedBookingsUser: [],
  singleCourtDetailUser: {},
  lockPlayerSuccess: false,
  updateBookingSuccess: false,
  allCompetitionsAdmin: [],
  competitionDetailUser: {},
  competitionDetailAdmin: {},
  joinedCompetitionUser: [],
  assignTeamPlayerSuccess: false,
  addCompetitionMatchSuccess: false,
  deleteCompetitionSuccess: false,
  allCompetitionMatches: [],
  allCategories: [],
  dummyCompetitionDetail: [],
  addCategoriesSuccess: false,
  assignPlayerCategoriesSuccess: false,
  singleCompetitionMatchDetailUser: {},
};

export const TourtnamentSlice = createSlice({
  name: "tournament",
  initialState,
  reducers: {
    resetAll: (state) => {
      state.loading = false;
      state.createCourtSuccess = false;
      state.bookCourtSuccess = false;
      state.createFirstComeSuccess = false;
      state.createPrefillSuccess = false;
      state.updateMatchScoreSuccess = false;
      state.updateCompetitionMatchScoreSuccess = false;
      state.deleteCourtSuccess = false;
      state.nextRoundSuccess = false;
      state.deleteTournamentSuccess = false;
      state.allActivePlayers = [];
      state.allAcceptedPlayers = [];
      state.startNextRoundSuccess = false;
      state.startTournamentSuccess = false;
      state.prefillAddSuccess = false;
      state.rankPlayerSuccess = false;
      state.bookCourtSuccessUser = false;
      state.cancelBookingSuccess = false;
      state.lockPlayerSuccess = false;
      state.updateBookingSuccess = false;
      state.assignTeamPlayerSuccess = false;
      state.addCompetitionMatchSuccess = false;
      state.deleteCompetitionSuccess = false;
      state.startCompetionSuccess = false;
      state.addCategoriesSuccess = false;
      state.assignPlayerCategoriesSuccess = false;
      state.singleCompetitionMatchDetailUser = {};
    },
    resetCourtSuccess: (state) => {
      state.createCourtSuccess = false;
    },

    resetplayerAdding: (state) => {
      state.addCompetionPlayersSuccess = false;
      state.prefillAddSuccess = false;
    },
    resetComptition: (state) => {
      state.createCompetionSuccess = false;
      state.addCompetionPlayersSuccess = false;
      state.startCompetionSuccess = false;

      state.createCompetitionData = {};
      state.addMatchSuccess = false;
    },
    resetEditBookingSuccess: (state) => {
      state.editBookingSuccess = false;
    },
    resetDeleteCourtSuccess: (state) => {
      state.deleteCourtSuccess = false;
    },
    resetEditCourtSuccess: (state) => {
      state.editCourtSuccess = false;
    },
    resetScoreSuccess: (state) => {
      state.updateMatchScoreSuccess = false;
    },
    resetDeleteMatch: (state) => {
      state.deleteMatchSuccess = false;
    },
    resetNextRoundSuccess: (state) => {
      state.nextRoundSuccess = false;
    },
    setTournamentParams: (state, action: PayloadAction<string>) => {
      state.tournamentParams = action.payload;
    },
    setCategories: (
      state,
      action: PayloadAction<{ name: string; _id: string }[]>
    ) => {
      state.allCategories = action.payload;
    },
    setDummyCompetitionData: (state, action: PayloadAction<any>) => {
      state.dummyCompetitionDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCourt.pending, (state) => {
        state.loading = true;
        state.bookCourtSuccess = false;
      })
      .addCase(createCourt.fulfilled, (state) => {
        state.loading = false;

        state.createCourtSuccess = true;
      })
      .addCase(createCourt.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createCourtSuccess = false;
      })
      .addCase(getAllCourt.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(getAllCourt.fulfilled, (state, { payload }) => {
        state.fetchLoading = false;

        state.allCourts = payload.data;
      })
      .addCase(getAllCourt.rejected, (state, { payload }) => {
        state.fetchLoading = false;
        state.error = payload;
        state.allCourts = [];
      })
      .addCase(allBookings.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(allBookings.fulfilled, (state, { payload }) => {
        state.fetchLoading = false;
        state.allBookings = payload.data;
      })
      .addCase(allBookings.rejected, (state, { payload }) => {
        state.fetchLoading = false;
        state.error = payload;
        state.allBookings = [];
      })
      .addCase(bookCourt.pending, (state) => {
        state.loading = true;
      })
      .addCase(bookCourt.fulfilled, (state) => {
        state.loading = false;
        state.bookCourtSuccess = true;
      })
      .addCase(bookCourt.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.bookCourtSuccess = false;
      })
      .addCase(updateCourtBooking.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCourtBooking.fulfilled, (state) => {
        state.loading = false;
        state.editBookingSuccess = true;
      })
      .addCase(updateCourtBooking.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.editBookingSuccess = false;
      })
      .addCase(updateCourtDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCourtDetails.fulfilled, (state) => {
        state.loading = false;
        state.editCourtSuccess = true;
      })
      .addCase(updateCourtDetails.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.editCourtSuccess = false;
      })
      .addCase(deleteCourt.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCourt.fulfilled, (state) => {
        state.loading = false;
        state.deleteCourtSuccess = true;
      })
      .addCase(deleteCourt.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.deleteCourtSuccess = false;
      })
      .addCase(rankPlayer.pending, (state) => {
        state.loading = true;
      })
      .addCase(rankPlayer.fulfilled, (state) => {
        state.loading = false;
        state.rankPlayerSuccess = true;
      })
      .addCase(rankPlayer.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(createFirstCome.pending, (state) => {
        state.loading = true;
      })
      .addCase(createFirstCome.fulfilled, (state) => {
        state.loading = false;
        state.createFirstComeSuccess = true;
      })
      .addCase(createFirstCome.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createFirstComeSuccess = false;
      })
      .addCase(createCompetition.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCompetition.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.createCompetionSuccess = true;
        state.createCompetitionData = payload.data;
        state.competitionId = payload?.data?._id;
      })
      .addCase(createCompetition.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createCompetionSuccess = false;
        state.createCompetitionData = {};
        state.competitionId = "";
      })
      .addCase(createPrefill.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPrefill.fulfilled, (state) => {
        state.loading = false;
        state.createPrefillSuccess = true;
      })
      .addCase(createPrefill.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createPrefillSuccess = false;
      })
      .addCase(getAllTournaments.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTournaments.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allTournament = payload.data;
      })
      .addCase(getAllTournaments.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.allTournament = [];
      })
      .addCase(getAllAcceptedPlayer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAcceptedPlayer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allAcceptedPlayers = payload.data;
      })
      .addCase(getAllAcceptedPlayer.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.allAcceptedPlayers = [];
      })
      .addCase(getAllActiveplayer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllActiveplayer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allActivePlayers = payload.data;
      })
      .addCase(getAllActiveplayer.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.allActivePlayers = [];
      })
      .addCase(getAllEvaluations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllEvaluations.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allEvaluationsAdmin = payload.data;
      })
      .addCase(getAllEvaluations.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getAllMatches.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMatches.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allMatches = payload.match;
      })
      .addCase(getAllMatches.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.allMatches = [];
      })
      .addCase(startTournament.pending, (state) => {
        state.loading = true;
      })
      .addCase(startTournament.fulfilled, (state) => {
        state.loading = false;
        state.startTournamentSuccess = true;
      })
      .addCase(startTournament.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.startTournamentSuccess = false;
      })
      .addCase(startNextRound.pending, (state) => {
        state.loading = true;
      })
      .addCase(startNextRound.fulfilled, (state) => {
        state.loading = false;
        state.startNextRoundSuccess = true;
      })
      .addCase(startNextRound.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.startNextRoundSuccess = false;
      })
      .addCase(editTournament.pending, (state) => {
        state.loading = true;
      })
      .addCase(editTournament.fulfilled, (state) => {
        state.loading = false;
        state.editTournamentSuccess = true;
      })
      .addCase(editTournament.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.editTournamentSuccess = false;
      })
      .addCase(getMatchInTournament.pending, (state) => {
        state.matchInTournamentLoading = true;
      })
      .addCase(getMatchInTournament.fulfilled, (state, { payload }) => {
        state.matchInTournamentLoading = false;
        state.matchInTournament = payload;
      })
      .addCase(getMatchInTournament.rejected, (state, { payload }) => {
        state.matchInTournamentLoading = false;
        state.error = payload;
        state.matchInTournament = [];
      })
      .addCase(updateMatchScore.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMatchScore.fulfilled, (state) => {
        state.updateMatchScoreSuccess = true;
        state.loading = false;
      })
      .addCase(updateMatchScore.rejected, (state, { payload }) => {
        state.updateMatchScoreSuccess = false;
        state.error = payload;
        state.loading = false;
      })
      .addCase(updateCompetitionMatchScore.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCompetitionMatchScore.fulfilled, (state) => {
        state.updateCompetitionMatchScoreSuccess = true;
        state.loading = false;
      })
      .addCase(updateCompetitionMatchScore.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(gotoNextRound.pending, (state) => {
        state.loading = true;
      })
      .addCase(gotoNextRound.fulfilled, (state) => {
        state.nextRoundSuccess = true;
        state.loading = false;
      })
      .addCase(gotoNextRound.rejected, (state, { payload }) => {
        state.nextRoundSuccess = false;
        state.error = payload;
        state.loading = false;
      })
      .addCase(prefillAddPlayers.pending, (state) => {
        state.loading = true;
      })
      .addCase(prefillAddPlayers.fulfilled, (state) => {
        state.prefillAddSuccess = true;
        state.loading = false;
      })
      .addCase(prefillAddPlayers.rejected, (state, { payload }) => {
        state.prefillAddSuccess = false;
        state.error = payload;
        state.loading = false;
      })
      .addCase(competitionAddPlayers.pending, (state) => {
        state.loading = true;
      })
      .addCase(competitionAddPlayers.fulfilled, (state) => {
        state.addCompetionPlayersSuccess = true;
        state.loading = false;
      })
      .addCase(competitionAddPlayers.rejected, (state, { payload }) => {
        state.addCompetionPlayersSuccess = false;
        state.error = payload;
        state.loading = false;
      })
      .addCase(startCompetion.pending, (state) => {
        state.loading = true;
      })
      .addCase(startCompetion.fulfilled, (state) => {
        state.startCompetionSuccess = true;
        state.loading = false;
      })
      .addCase(startCompetion.rejected, (state, { payload }) => {
        state.startCompetionSuccess = false;
        state.error = payload;
        state.loading = false;
      })
      .addCase(startTeamCompetition.pending, (state) => {
        state.loading = true;
      })
      .addCase(startTeamCompetition.fulfilled, (state) => {
        state.startCompetionSuccess = true;
        state.loading = false;
      })
      .addCase(startTeamCompetition.rejected, (state, { payload }) => {
        state.startCompetionSuccess = false;
        state.error = payload;
        state.loading = false;
      })
      .addCase(addMatch.pending, (state) => {
        state.loading = true;
      })
      .addCase(addMatch.fulfilled, (state) => {
        state.addMatchSuccess = true;
        state.loading = false;
      })
      .addCase(addMatch.rejected, (state, { payload }) => {
        state.addMatchSuccess = false;
        state.error = payload;
        state.loading = false;
      })
      .addCase(deleteMatch.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteMatch.fulfilled, (state) => {
        state.deleteMatchSuccess = true;
        state.loading = false;
      })
      .addCase(deleteMatch.rejected, (state, { payload }) => {
        state.deleteMatchSuccess = false;
        state.error = payload;
        state.loading = false;
      })
      .addCase(bookCourtUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(bookCourtUser.fulfilled, (state) => {
        state.bookCourtSuccessUser = true;
        state.loading = false;
      })
      .addCase(bookCourtUser.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(cancelCourtBookingUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(cancelCourtBookingUser.fulfilled, (state) => {
        state.cancelBookingSuccess = true;
        state.loading = false;
      })
      .addCase(cancelCourtBookingUser.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(getAllCourtUser.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(getAllCourtUser.fulfilled, (state, { payload }) => {
        state.allCourtUser = payload?.data;
        state.fetchLoading = false;
      })
      .addCase(getAllCourtUser.rejected, (state, { payload }) => {
        state.error = payload;
        state.fetchLoading = false;
      })
      .addCase(getAllPendingCourtUser.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(getAllPendingCourtUser.fulfilled, (state, { payload }) => {
        state.allPendingBookingsUser = payload?.data;
        state.fetchLoading = false;
      })
      .addCase(getAllPendingCourtUser.rejected, (state, { payload }) => {
        state.error = payload;
        state.fetchLoading = false;
      })
      .addCase(getAllBookedCourtUser.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(getAllBookedCourtUser.fulfilled, (state, { payload }) => {
        state.allApprovedBookingsUser = payload?.data;
        state.fetchLoading = false;
      })
      .addCase(getAllBookedCourtUser.rejected, (state, { payload }) => {
        state.error = payload;
        state.fetchLoading = false;
      })
      .addCase(getSingleCourtDetailUser.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(getSingleCourtDetailUser.fulfilled, (state, { payload }) => {
        state.singleCourtDetailUser = payload?.data;
        state.fetchLoading = false;
      })
      .addCase(getSingleCourtDetailUser.rejected, (state, { payload }) => {
        state.error = payload;
        state.fetchLoading = false;
      })
      .addCase(greyPlayer.pending, (state) => {
        state.loading = true;
      })
      .addCase(greyPlayer.fulfilled, (state, { payload }) => {
        state.lockPlayerSuccess = true;
        state.loading = false;
      })
      .addCase(greyPlayer.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(updateCourtBookingAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCourtBookingAdmin.fulfilled, (state, { payload }) => {
        state.updateBookingSuccess = true;
        state.loading = false;
      })
      .addCase(updateCourtBookingAdmin.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(getAllCompetitionsAdmin.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(getAllCompetitionsAdmin.fulfilled, (state, { payload }) => {
        state.allCompetitionsAdmin = payload?.data;
        state.fetchLoading = false;
      })
      .addCase(getAllCompetitionsAdmin.rejected, (state, { payload }) => {
        state.error = payload;
        state.fetchLoading = false;
      })
      .addCase(getSingleCompetitionDetailUser.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(
        getSingleCompetitionDetailUser.fulfilled,
        (state, { payload }) => {
          state.competitionDetailUser = payload?.data;
          state.fetchLoading = false;
        }
      )
      .addCase(
        getSingleCompetitionDetailUser.rejected,
        (state, { payload }) => {
          state.error = payload;
          state.fetchLoading = false;
        }
      )
      .addCase(getSingleCompetitionDetailAdmin.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(
        getSingleCompetitionDetailAdmin.fulfilled,
        (state, { payload }) => {
          state.competitionDetailAdmin = payload?.data;
          state.fetchLoading = false;
        }
      )
      .addCase(
        getSingleCompetitionDetailAdmin.rejected,
        (state, { payload }) => {
          state.error = payload;
          state.fetchLoading = false;
        }
      )
      .addCase(getAllJoinedCompetition.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(getAllJoinedCompetition.fulfilled, (state, { payload }) => {
        state.joinedCompetitionUser = payload?.data;
        state.fetchLoading = false;
      })
      .addCase(getAllJoinedCompetition.rejected, (state, { payload }) => {
        state.error = payload;
        state.fetchLoading = false;
      })
      .addCase(assignTeamPlayers.pending, (state) => {
        state.loading = true;
      })
      .addCase(assignTeamPlayers.fulfilled, (state) => {
        state.assignTeamPlayerSuccess = true;
        state.loading = false;
      })
      .addCase(assignTeamPlayers.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(addCompetitionMatch.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCompetitionMatch.fulfilled, (state) => {
        state.addCompetitionMatchSuccess = true;
        state.loading = false;
      })
      .addCase(addCompetitionMatch.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(deleteCompetition.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCompetition.fulfilled, (state) => {
        state.deleteCompetitionSuccess = true;
        state.loading = false;
      })
      .addCase(deleteCompetition.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(getAllCompetitionMatches.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(getAllCompetitionMatches.fulfilled, (state, { payload }) => {
        state.allCompetitionMatches = payload?.data;
        state.fetchLoading = false;
      })
      .addCase(getAllCompetitionMatches.rejected, (state, { payload }) => {
        state.error = payload;
        state.fetchLoading = false;
      })
      .addCase(addCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCategories.fulfilled, (state, { payload }) => {
        state.addCategoriesSuccess = true;
        state.loading = false;
      })
      .addCase(addCategories.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(assignPlayerToCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(assignPlayerToCategories.fulfilled, (state, { payload }) => {
        state.assignPlayerCategoriesSuccess = true;
        state.loading = false;
      })
      .addCase(assignPlayerToCategories.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(getSingleCompetitionMatchUser.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(
        getSingleCompetitionMatchUser.fulfilled,
        (state, { payload }) => {
          state.singleCompetitionMatchDetailUser = payload?.data;
          state.fetchLoading = false;
        }
      )
      .addCase(getSingleCompetitionMatchUser.rejected, (state, { payload }) => {
        state.error = payload;
        state.fetchLoading = false;
      });
  },
});

export const tournamentSelector = (state: any) => state.tournament;

export const getSingleCompetitionDetailUser = createAsyncThunk(
  "getSingleCompetitionDetailUser",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.competition}/${payload?.id}/detail`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getSingleCompetitionDetailAdmin = createAsyncThunk(
  "getSingleCompetitionDetailAdmin",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.competition}/${payload?.id}/detail`,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getSingleCompetitionMatchUser = createAsyncThunk(
  "getSingleCompetitionMatchUser",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.competition}/${payload?.id}/match/${payload?.matchId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const addCompetitionMatch = createAsyncThunk(
  "addCompetitionMatch",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.competition}/${payload?.id}/match`,
        payload?.data,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const addCategories = createAsyncThunk(
  "addCategories",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.competition}/${payload?.id}/group`,
        payload?.data,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const assignPlayerToCategories = createAsyncThunk(
  "assignPlayersToCategories",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.competition}/${payload?.id}/set/group`,
        payload?.data,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const getAllCompetitionMatches = createAsyncThunk(
  "getAllCompetitionMatches",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();
    try {
      let req = {
        method: "get",
        address: `${url.competition}/matches/all`,
        header: `Authorization: Bearer ${auth?.token}`,
      };
      const { data } = await nAPIService(req.method, req.address, req.header);

      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const createCourt = createAsyncThunk(
  "createCourt",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(`${url.createCourt}`, payload, {
        headers: {
          Authorization: `Bearer ${adminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const bookCourtUser = createAsyncThunk(
  "bookCourtUser",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(`${url.bookCourtUser}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const getAllEvaluations = createAsyncThunk(
  "getAllEvaluations",
  async (_, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.getEvaluation}`, {
        headers: {
          Authorization: `Bearer ${adminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getAllCourt = createAsyncThunk(
  "getAllCourts",
  async (_, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.getAllCourt}`, {
        headers: {
          Authorization: `Bearer ${adminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getAllCourtUser = createAsyncThunk(
  "getAllCourtUser",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.getAllCourtUser}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getAllPendingCourtUser = createAsyncThunk(
  "getAllPendingCourtUser",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.allBookingsUser}?state=pending`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getAllBookedCourtUser = createAsyncThunk(
  "getAllApprovedCourtUser",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.allBookingsUser}?state=approved`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getSingleCourtDetailUser = createAsyncThunk(
  "getSingleCourtDetailUser",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.courtDetailUser}/${payload?.id}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const cancelCourtBookingUser = createAsyncThunk(
  "cancelCourtBookingUser",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.delete(
        `${url.allBookingsUser}/${payload?.id}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const allBookings = createAsyncThunk(
  "allBookings",
  async (_, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.allBookings}`, {
        headers: {
          Authorization: `Bearer ${adminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const bookCourt = createAsyncThunk(
  "bookCourt",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(`${url.bookCourt}`, payload, {
        headers: {
          Authorization: `Bearer ${adminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const updateCourtBooking = createAsyncThunk(
  "updateCourtBooking",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.put(
        `${url.editBooking}${payload.id}`,
        { time: payload.time },
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const greyPlayer = createAsyncThunk(
  "greyPlayer",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.put(
        `${url.ladder}/${payload.id}/lock`,
        { user: payload.user },
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const rankPlayer = createAsyncThunk(
  "rankPlayer",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.put(
        `${url.rankPlayer}/${payload.id}`,
        { input: payload.input },
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const updateCourtDetails = createAsyncThunk(
  "updateCourtDetails",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.put(
        `${url.editCourtDetails}${payload.id}`,
        { name: payload.name, type: payload.type },
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const deleteCourt = createAsyncThunk(
  "deleteCourt",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.delete(
        `${url.deleteCourt}${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const deleteMatch = createAsyncThunk(
  "deleteMatch",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.delete(
        `${url.deleteMatch}/${payload.id}/delete`,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const getAllTournaments = createAsyncThunk(
  "getAllTournaments",
  async (_, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.getAllTournaments}`, {
        headers: {
          Authorization: `Bearer ${adminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const gotoNextRound = createAsyncThunk(
  "gotoNextRound",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      let req = {
        method: "post",
        address: `${url.nextRound}${payload.id}/next`,
        header: `Authorization: Bearer ${adminAuth?.token}`,
      };
      const { data } = await nAPIService(req.method, req.address, req.header);

      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const getAllCompetitionsAdmin = createAsyncThunk(
  "getAllCompetitionsAdmin",
  async (_, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      let req = {
        method: "get",
        address: `${url.competition}`,
        header: `Authorization: Bearer ${adminAuth?.token}`,
      };
      const { data } = await nAPIService(req.method, req.address, req.header);

      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getAllJoinedCompetition = createAsyncThunk(
  "getAllJoinedCompetition",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.competition}/join`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const assignTeamPlayers = createAsyncThunk(
  "assignTeamPlayers",
  async (payload: any, { rejectWithValue, getState, dispatch }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.competition}/${payload?.id}/set`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const updateCourtBookingAdmin = createAsyncThunk(
  "updateCourtBookingAdmin",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      let req = {
        method: "put",
        address: `${url.updateBooking}/${payload.id}`,
        header: `Authorization: Bearer ${adminAuth?.token}`,
        data: payload?.data,
      };
      const { data } = await nAPIService(
        req.method,
        req.address,
        req.header,
        req.data
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const createFirstCome = createAsyncThunk(
  "createFirstCome",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.createFirstCome}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const createCompetition = createAsyncThunk(
  "createCompetition",
  async (payload: any, { rejectWithValue, getState, dispatch }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.createCompetition}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      setTimeout(() => {
        dispatch(resetComptition());
      }, 3000);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const competitionAddPlayers = createAsyncThunk(
  "competitionAddPlayers",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.createCompetition}/${payload.id}/add`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const createPrefill = createAsyncThunk(
  "createPrefill",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(`${url.createPrefill}`, payload, {
        headers: {
          Authorization: `Bearer ${adminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getAllAcceptedPlayer = createAsyncThunk(
  "getAllAcceptedPlayer",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.getAllAcceptedPlayers}${payload.id}/players`,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getAllActiveplayer = createAsyncThunk(
  "getAllActivePlayer",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.getAllAcceptedPlayers}${payload.id}/active`,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getMatchInTournament = createAsyncThunk(
  "getMatchInTournament",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.get(
        `${url.matchInTournament}${payload.id}/matches`,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const startTournament = createAsyncThunk(
  "startTournament",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.startTournament}${payload.id}/start`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const startCompetion = createAsyncThunk(
  "startCompetition",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.startCompetition}${payload.id}/start`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const startTeamCompetition = createAsyncThunk(
  "startTeamCompetition",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      let req = {
        method: "put",
        address: `${url.competition}/${payload?.id}/start`,
        header: `Authorization: Bearer ${adminAuth?.token}`,
      };
      const { data } = await nAPIService(req.method, req.address, req.header);

      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const addMatch = createAsyncThunk(
  "addMatch",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.put(
        `${url.startCompetition}${payload.id}/add`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const prefillAddPlayers = createAsyncThunk(
  "prefillAddPlayers",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.prefillAddPlayers}${payload.id}/add`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const startNextRound = createAsyncThunk(
  "startNextRound",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.nextRound}${payload.id}/start/next`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const updateMatchScore = createAsyncThunk(
  "updateMatchScore",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.put(
        `${url.updateMatchScore}${payload.id}/score`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const updateCompetitionMatchScore = createAsyncThunk(
  "updateCompetitionMatchScore",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.competition}/${payload.id}/match/score`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const editTournament = createAsyncThunk(
  "editTournament",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.put(
        `${url.editTournament}${payload.id}`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const deleteTournament = createAsyncThunk(
  "deleteTournament",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.delete(
        `${url.deleteTournament}${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const deleteCompetition = createAsyncThunk(
  "deleteCompetition",
  async (payload: any, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.delete(
        `${url.competition}/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${adminAuth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const getAllMatches = createAsyncThunk(
  "allMacthes",
  async (_, { rejectWithValue, getState }) => {
    const { adminAuth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.allMatchesAdmin}`, {
        headers: {
          Authorization: `Bearer ${adminAuth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const {
  resetAll,
  resetCourtSuccess,
  resetEditBookingSuccess,
  resetDeleteCourtSuccess,
  resetEditCourtSuccess,
  setTournamentParams,
  resetScoreSuccess,
  resetComptition,
  resetplayerAdding,
  resetDeleteMatch,
  setCategories,
  setDummyCompetitionData,
} = TourtnamentSlice.actions;
export default TourtnamentSlice.reducer;
