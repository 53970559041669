import {
  Action,
  ThunkAction,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";

import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import adminAuthSlice from "../features/auth/AdminAuth";
import AuthSlice from "../features/auth/AuthSlice";
import SuperAdminSlice from "../features/auth/SuperAdminSlice";
import TournamentSlice from "../features/auth/TournamentSlice";
import utilitySlice from "../features/auth/UtilitySlice";
import counterReducer from "../features/counter/counterSlice";
import ladderSlice from "../features/auth/ladder-slice";
import playerslice from "../features/auth/playerslice";
const reducer = combineReducers({
  counter: counterReducer,
  auth: AuthSlice,
  adminAuth: adminAuthSlice,
  superAdminAuth: SuperAdminSlice,
  tournament: TournamentSlice,
  utility: utilitySlice,
  ladder: ladderSlice,
  player: playerslice,
});
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducer);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefualtMiddleware) =>
    getDefualtMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
