import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Btn } from "../../../components/Button";
import { ModalSub } from "../../../components/Text";

import AdminAuthLayout from "../../../Layouts/AdminAuthLayout";
import logo from "../../../assets/svgs/love-logo.svg";
import { AdminInput } from "../../../components/Input";
import { AuthSubTitle, AuthTitle } from "../../../components/Text";
import {
  adminForgotPassword,
  restoreDefault,
  setForgetEmail,
} from "../../../features/auth/AdminAuth";

import { Message } from "../../../assets/svgs/Icons";
import ReUseModal from "../../../components/Modal/ReUseModal";
const AdminForgotPassword = () => {
  const navigate = useNavigate();
  const adminAuth = useAppSelector((state) => state.adminAuth);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const [email, setEmail] = useState("");

  const [active, setActive] = useState(false);
  useEffect(() => {
    dispatch(restoreDefault());
  }, []);
  useEffect(() => {
    if (email) setActive(true);
    else setActive(false);
  }, [email]);

  const handleSubmit = () => {
    const data = {
      email: email,
    };
    dispatch(setForgetEmail(email));
    dispatch(adminForgotPassword(data));
  };

  useEffect(() => {
    if (adminAuth.forgetOtpSent) {
      toast.success("Otp Sent Successfully");
      navigate("/admin/email-verification-otp");
    }
  }, [adminAuth.forgetOtpSent]);

  return (
    <div className="w-full ">
      <AdminAuthLayout>
        <div className="w-full flex h-full justify-center ">
          {/* left side  */}
          <div className="w-1/2 bg-white flex flex-col">
            <div className="mx-auto mt-8">
              <img src={logo} alt="logo" className="h-auto" />
            </div>
            <div className="flex flex-col justify-center items-center mt-8">
              <AuthTitle text="Forgot Password" className="text-center" />
              <div className="w-8/12 mx-auto">
                <AuthSubTitle
                  text="Enter the email address associated with your account to get a code to reset your password "
                  className="mt-[10px]"
                />
              </div>
              <div className="flex flex-col lg:w-8/12 mx-auto xl:w-7/12 2xl:w-7/12 mt-10">
                <AdminInput
                  placeholder="Enter your Email"
                  onChange={(e: any) => setEmail(e.target.value)}
                  label="Email Address"
                  required={true}
                />

                {/* button session */}
                <Btn
                  name="Send Code"
                  className="w-full mt-12"
                  onClick={handleSubmit}
                  active={active}
                  loading={adminAuth.loading}
                />
                {/* end of button session */}
              </div>
            </div>
          </div>
          {/* end ofleft side  */}
          {/* right side  */}
          {/* <div className={`w-1/2 ${stages[current]} relative`}>
            <div className="absolute bottom-28 w-full flex justify-center left-0 right-0 px-16">
              <GlassProgress onClick={handleNext} current={current} />
            </div>
          </div> */}
          {/* end of the right hand side */}
        </div>
      </AdminAuthLayout>
      <ReUseModal open={open} setOpen={setOpen}>
        <div className="w-full flex flex-col justify-center items-center py-8">
          <Message />
          <p className="text-customBlack font-bold text-lg text-center">
            Check your email
          </p>
          <ModalSub
            className="text-center mt-1"
            text="We’ve sent an OTP code to your email to reset your password"
          />
          <div className="w-6/12 mx-auto">
            <Btn
              active={true}
              onClick={() => navigate("/admin/email-verification-otp")}
              name="Continue"
              className="mt-3 "
            />
          </div>
        </div>
      </ReUseModal>
    </div>
  );
};

export default AdminForgotPassword;
