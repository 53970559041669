import { useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { More } from "../../../../assets/svgs/court";
import { Btn, RedOutlinedBtn } from "../../../../components/Button";
import {
  joinTournament,
  leaveTournament,
  resetInvitation,
} from "../../../../features/auth/AuthSlice";
const ViewInvitation = () => {
  const navigate = useNavigate();
  const userSlice = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(resetInvitation());
  }, []);

  const [searchParams] = useSearchParams();
  const tid = searchParams.get("tid");
  const uid = searchParams.get("uid");
  const limit = searchParams.get("limit");

  const handleAccept = () => {
    const data = {
      t_id: tid,
      u_id: uid,
    };
    dispatch(joinTournament(data));
  };

  const handleReject = () => {
    const data = {
      t_id: tid,
      u_id: uid,
    };
    dispatch(leaveTournament(data));
  };

  useEffect(() => {
    if (userSlice.joinTournamentSuccess) {
      toast.success("You have Joined Successfully");
      setTimeout(() => {
        navigate("/home");
      }, 2000);
    }
  }, [userSlice.joinTournamentSuccess]);

  useEffect(() => {
    if (userSlice.leaveTournamentSuccess) {
      toast.success("Tournament Rejected Successfully");
      setTimeout(() => {
        navigate("/home");
      }, 2000);
    }
  }, [userSlice.leaveTournamentSuccess]);

  return (
    <>
      {/* <div className=" flex-col lg:flex hidden mt-6 px-4 justify-center h-screen w-1/2 mx-auto">
        <h1 className="font-bold text-customBlack text-xl text-center mb-2 ">
          Oops, this page is only designed for mobile devices.
        </h1>
        <p className="text-neutral-700 text-base  ">
          Please switch to a smaller screen, such as a smartphone or tablet, to
          enjoy this feature. We appreciate your cooperation.
        </p>
      </div> */}
      <div className="w-full flex flex-col px-4 py-6 h-screen relative md:max-w-[428px] md:mx-auto md:h-screen">
        <div className="flex justify-between mt-6 items-center">
          <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
          <p className="text-[#303030] font-bold text-[15px] ">
            Knockout Tournament Invitation
          </p>
          <div className="more-shadow opacity-0">
            <More />
          </div>
        </div>
        <div className="mt-8 flex flex-col ">
          <p className="text-sm text-neutral-700">
            You’ve been invited to a knockout tournament. For this tournament,
            it is based on First-Come First-Serve basis. The first people to
            accept the invitation, will compete in the Knockout Tournament. For
            this tournament,{" "}
            <span className="text-customBlack font-bold">{limit}</span> people
            will be selected for this tournament.
          </p>
          <p className="text-neutral-700 text-sm mt-4">
            Click the <span className="text-customBlack font-bold">accept</span>{" "}
            button to join the tournament or reject button to not join the
            tournament.
          </p>
        </div>
        {/* button */}
        <div className="flex flex-col absolute bottom-20 left-0 space-y-5 w-full px-4">
          <Btn
            name="Accept"
            onClick={handleAccept}
            active
            loading={userSlice.loading}
          />
          <RedOutlinedBtn
            name="Reject"
            onClick={handleReject}
            loading={userSlice.loading}
            active
          />
        </div>
      </div>
    </>
  );
};

export default ViewInvitation;
