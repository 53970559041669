import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../assets/svgs";
import { useAppSelector } from "../../app/hooks";
import { useAppDispatch } from "../../app/hooks";
import { restoreDefault } from "../../features/auth/AuthSlice";
const AppScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  useEffect(() => {
    dispatch(restoreDefault());
    setTimeout(() => {
      navigate("/users");
    }, 3000);
  }, []);

  // useEffect(() => {
  //   if (auth.token) {
  //     setTimeout(() => {
  //       window.location.replace("/home");
  //     }, 2000);
  //   } else {
  //     setTimeout(() => {
  //       navigate("/users");
  //     }, 2000);
  //   }
  // }, []);

  return (
    <div className="w-full bg-secondaryGreen h-screen grid place-items-center">
      <Logo />
    </div>
  );
};

export default AppScreen;
