import { useState, useEffect } from "react";
import PrimarySelect from "../../Selects/PrimarySelect";
import { Btn } from "../../Button";
import { AdminDateInput, AdminTimeInput } from "../../Input/DateInput";

const styles = {
  activeLink:
    "text-[#82B020] border-b-[#82B020] border-b-[3px] text-base pb-[10px] cursor-pointer",
  inActiveLink: "text-neutral-400 text-base pb-[10px] cursor-pointer",
};

const selectData = [
  { name: "Agege" },
  { name: "Agege" },
  { name: "Agege" },
  { name: "Agege" },
  { name: "Agege" },
  { name: "Agege" },
  { name: "Agege" },
];

const Prefill = () => {
  const [playerNo, setPlayerNo] = useState<number>(0);
  const [present, setPresent] = useState(1);
  const [array, setArray] = useState<any>([]);
  const [scoreArray, setScoreArray] = useState<any>([]);
  useEffect(() => {
    let actual = Number(playerNo) / 2;
    let arrays = [];
    for (let i = 0; i < actual; i++) {
      arrays.push(i);
    }
    setArray(arrays);
  }, [playerNo]);
  useEffect(() => {
    let actual = Number(playerNo);
    let arrays = [];
    for (let i = 0; i < actual; i++) {
      arrays.push(i);
    }
    setScoreArray(arrays);
  }, [playerNo]);

  return (
    <div className="w-full flex flex-col">
      <div className="flex  space-x-[50px] items-center border-b border-b-[#cfcfcf] mt-10 ">
        <p
          className={present === 1 ? styles.activeLink : styles.inActiveLink}
          onClick={() => setPresent(1)}
        >
          Schedule Players
        </p>
        <p
          className={present === 2 ? styles.activeLink : styles.inActiveLink}
          onClick={() => setPresent(2)}
        >
          Scoring
        </p>
      </div>
      {present === 1 && (
        <div className="bg-white rounded-[20px] flex flex-col h-full mt-6 flow-hide  ">
          <div className="h-[51px] ">
            <h1 className="pl-5 text-customBlack font-semibold text-sm mt-[22px] ">
              Schedule Players
            </h1>
          </div>
          {/* breaker line */}
          <div className="w-full h-[1px] bg-[#cfcfcf] " />
          <div className="w-full flex flex-col pl-5">
            <p className="mt-3 text-neutral-600 ">
              Select the number of players and fill in the name of the players
              with the number of court
            </p>
            {/* start of function session */}
            <div className="flex space-x-3  mt-8  items-center">
              <p className="text-neutral-600">Number of Players:</p>

              <div className="w-[200px]">
                {/* <PrimarySelect
              selected={selected}
              setSelected={setSelected}
              name="Number"
              data={data}
              height="h-[30px] flex items-center bg-[#EBF6D4] "
              label=""
            /> */}
                <input
                  type="number"
                  className="border border-[#333333] border-opacity-40 rounded-[5px] h-[35px] w-[200px] px-4 text-sm "
                  onChange={(e: any) => setPlayerNo(e.target.value)}
                />
              </div>
            </div>
            {/* form area */}
            <div className="w-full flex flex-col">
              <p className="text-base text-neutral-600 mb-3">Round 1</p>
              {array?.map((item: any, index: number) => {
                return <SingleItem key={index} />;
              })}
            </div>
          </div>
        </div>
      )}
      {present === 2 && <ScoreBoard scoreArray={scoreArray} />}
    </div>
  );
};

export default Prefill;

const SingleItem = () => {
  const [selectedCourt, setSelectedCourt] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  return (
    <div className="flex flex-col pb-6  border-dashed pt-6 border-border border-b-[2px]">
      {/* upper sessiom */}
      <div className="flex space-x-6 items-center">
        {/* side a */}
        <div className="flex items-center space-x-3">
          <p className="text-neutral-600">
            {" "}
            Player <span className="font-bold text-customBlack">A</span>:
          </p>
          <input
            type="text"
            className="outline-none h-[30px] rounded-[5px] border border-border w-[230px] px-2 "
          />
        </div>
        {/* sideb */}
        <p className="text-xs text-customBlack">VS</p>
        <div className="flex items-center space-x-3">
          <p className="text-neutral-600">
            {" "}
            Player <span className="font-bold text-customBlack">B</span>:
          </p>
          <input
            type="text"
            className="outline-none h-[30px] rounded-[5px] border border-border w-[230px] px-2 "
          />
        </div>
      </div>
      {/* lower session ###################3 */}
      <div className="flex space-x-6 items-center mt-4">
        {/* start of a sec */}
        <div className="flex items-center space-x-3">
          <p className="text-neutral-600">Match Date:</p>
          <AdminDateInput
            date={date}
            setDate={setDate}
            className="h-[30px] w-[230px] min-w-[230px] max-w-[230px]"
          />
        </div>

        {/* enf of a select */}
        {/* start of a sec */}
        <div className="flex items-center space-x-3">
          <p className="text-neutral-600 min-w-max">Match Time:</p>

          <AdminTimeInput
            time={time}
            setTime={setTime}
            className="h-[30px] w-[230px] min-w-[230px] max-w-[230px]"
          />
        </div>

        {/* enf of a select */}
        {/* start of a sec */}
        <div className="flex items-center space-x-3">
          <p className="text-neutral-600">Court:</p>
          <div className="w-[230px]">
            <PrimarySelect
              selected={selectedCourt}
              setSelected={setSelectedCourt}
              name="Select"
              data={selectData}
              height="h-[30px] flex items-center bg-[#ffffff]"
              label=""
            />
          </div>
        </div>

        {/* enf of a select */}
      </div>
    </div>
  );
};

const ScoreBoard = ({ scoreArray }: any) => {
  return (
    <div className="bg-white rounded-[20px] flex flex-col h-full min-h-[80vh] mt-6 flow-hide  ">
      <div className="h-[51px] ">
        <h1 className="pl-5 text-customBlack font-semibold text-sm mt-[22px] ">
          Scores
        </h1>
      </div>
      {/* breaker line */}
      <div className="w-full h-[1px] bg-[#cfcfcf] " />
      <div className="w-full 2xl:w-10/12 xl:w-11/12">
        <table className="w-full flex flex-col border border-border bg-white  ">
          <thead className="w-full bg-[#f4f4f4] bg-opacity-40 ">
            <tr className="flex items-center h-[35px] pl-5">
              <th className="w-1/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-start">
                SN
              </th>
              <th className="w-3/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px]">
                Player1
              </th>
              <th className="w-3/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px]">
                Player2
              </th>
              <th className="w-1/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-start">
                Set1
              </th>
              <th className="w-1/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-start">
                Set2
              </th>
              <th className="w-1/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-start">
                Set3
              </th>
              <th className="w-2/12 theader text-neutral-700 text-xs font-[500] tracking-[0.12px] text-start">
                Winner
              </th>
            </tr>
          </thead>
          <tbody>
            {scoreArray.map((item: any, index: any) => {
              return <SingleForm index={index} item={item} />;
            })}
          </tbody>
        </table>
        <div className="w-4/12 mx-auto absolute bottom-8 left-0 right-0">
          <Btn
            active={true}
            name="Updated Scores"
            onClick={() => console.log("hehre")}
          />
        </div>
      </div>
    </div>
  );
};

const SingleForm = ({ index }: any) => {
  return (
    <tr
      className="w-full flex items-center pl-5 h-[41px] border-b border-b-border "
      key={index}
    >
      <td className="w-1/12 text-customBlack text-sm font-[500]text-center ">
        {index + 1}
      </td>
      <td className="w-3/12 text-customBlack text-sm font-[500] text-center ">
        Aniedi Udo Obong
      </td>
      <td className="w-3/12 text-customBlack text-sm font-[500] text-center ">
        Ayeni Daniel
      </td>
      <td className="w-1/12 flex justify-center">
        <input
          type="text"
          className="w-full border-none outline-none placeholder:text-placeholder placeholder:text-sm"
          placeholder="0-0"
        />
      </td>
      <td className="w-1/12 flex justify-center">
        <input
          type="text"
          className="w-full border-none outline-none placeholder:text-placeholder placeholder:text-sm"
          placeholder="0-0"
        />
      </td>
      <td className="w-1/12 flex justify-center">
        <input
          type="text"
          className="w-full border-none outline-none placeholder:text-placeholder placeholder:text-sm"
          placeholder="0-0"
        />
      </td>
      <td className="w-2/12 flex justify-center">
        <input
          type="text"
          className="w-full border-none outline-none placeholder:text-placeholder placeholder:text-sm"
          placeholder="Winner here"
        />
      </td>
    </tr>
  );
};
