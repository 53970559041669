import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { convertDateFormat, capitalizeWords } from "../../../utils";
interface tableProps {
  className: string;
  data: any;
}

const SuperAdminTable = ({ className, data }: tableProps) => {
  return (
    <div
      className={`w-full  h-full flex flex-col bg-white  rounded-[20px] ${className} `}
    >
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className="text-sm font-semibold text-customBlack">Admin</h1>
      </div>
      {/* Table section starts from here  #################################################3*/}
      <table className="min-w-full w-full h-full border-b border-b-border  ">
        <thead className="">
          <tr className="grid grid-cols-5  h-[35px] items-center px-4">
            <th className="  text-xs text-neutral-600 text-start ">
              Date Registered
            </th>
            <th className="  text-xs text-neutral-600 text-start ">
              Name Of Admin
            </th>

            <th className="  text-xs text-neutral-600 text-start ">Email</th>
            <th className="  text-xs text-neutral-600 text-start ">
              Number of Court
            </th>
            <th className="  text-xs text-neutral-600 text-start ">
              Club’s Location
            </th>
          </tr>
        </thead>
        <tbody className="w-full  rounded-b-[20px] flex flex-col h-full px-4 ">
          {data.slice(0, 12).map((item: any, index: number) => {
            return (
              <tr className="grid grid-cols-5  py-3  items-center" key={index}>
                <td className="  text-sm text-neutral-600 text-start ">
                  {convertDateFormat(item.createdAt)}
                </td>
                <td className="  text-sm text-neutral-600 text-start ">
                  {capitalizeWords(item.firstname + " " + item.lastname)}
                </td>
                <td className="  text-sm text-neutral-600 text-start ">
                  {item.email}
                </td>
                <td className=" text-sm text-neutral-600 text-start ">0</td>
                <td className="  text-sm text-neutral-600 text-start ">
                  Nigeria
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Table section Ends from here $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$4 */}
      <div className="w-full h-[61px] items-center flex justify-between pl-4 pr-8 mb-4">
        {/* pagination */}
        <div className="flex items-center">
          <p className="text-xs">Page</p>
          <p className="px-1 rounded-sm border border-border text-customBlack font-bold cursor-pointer text-sm mx-2 ">
            1
          </p>
          <p className="text-sm text-customBlack">of 2</p>
          <div className="space-x-2 items-center ml-8 flex">
            <span className="text-base px-1 bg-[#f4f4f4] text-[#cfcfcf] opacity-70 rounded-sm border border-border">
              <IoIosArrowBack />
            </span>
            <span className="text-base px-1 bg-[#f4f4f4] text-customBlack opacity-70 rounded-sm border border-border">
              <IoIosArrowForward />
            </span>
          </div>
        </div>
        {/* enteries */}
        <div className="flex">
          <p>No of entries </p>
          <div>
            <p className="px-1 rounded-sm border border-border text-customBlack font-bold cursor-pointer text-sm mx-2 ">
              10 per page
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminTable;
