import playerPicture from "../../../assets/png/player-pic.png";
import { capitalizeWords } from "../../../utils";
import receipt from "../../../assets/svgs/receipt.svg";

const InvitationRequestTable = (pendings: any) => {
  const { pendingArray } = pendings;

  return (
    <div className="w-417 h-243 top-698 left-973 rounded-lg w-full flex flex-col bg-white h-[300px] ">
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className="w-125 h-17 top-20 left-16 font-inter font-semibold text-14 leading-17 tracking-tighter text-left text-sm text-[#0E0E0E]">
          Invitation Request
        </h1>
        {/* <a href="/admin/club" className="text-primaryYellow text-sm font-[500]">View All</a> */}
      </div>
      {!pendingArray?.length && (
        <div className=" mt-20 border-2.38 border-gray-600 w-38 h-38 flex items-center justify-center">
          <img
            src={receipt}
            alt=""
            className=" border-2.5 border-gray-600 w-38 h-38w-38 h-38"
          />
        </div>
      )}

      {!pendingArray?.length ? (
        <h1 className="font-inter text-xs font-medium leading-15 tracking-wider w-106 h-15 text-center mt-5">
          No invitation request
        </h1>
      ) : (
        <div className="flex flex-col rounded-b-[20px] bg-white pb-4">
          {pendingArray?.map((item: any, index: number) => {
            return (
              <div
                className="w-full h-[35px] flex pl-4  justify-between items-center"
                key={index}
              >
                <div className="flex items-center space-x-[17px] w-7/12 ">
                  <div className="flex  items-center space-x-3 ">
                    <img
                      src={playerPicture}
                      alt="user avatar"
                      className="rounded-full mx-2"
                    />
                    <p className="text-neutral-600 font-[500] text-xs  ">
                      {capitalizeWords(
                        `${item?.firstname} ${
                          item?.lastname ? item?.lastname : ""
                        }`
                      )}
                    </p>
                  </div>
                </div>
                <div className=" w-5/12 h-[35px] items-center justify-end pr-6 flex space-x-[21px] ">
                  <p className="text-[#197C34] border-b-[#197C34] border-b text-xs font-[400] cursor-pointer pb-1">
                    Accept
                  </p>
                  <p className="text-[#B00020] border-b-[#B00020] border-b text-xs font-[400] pb-1 cursor-pointer">
                    Reject
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default InvitationRequestTable;
