import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../Layouts/DashboardLayout";
import { NavigationArrow } from "../../assets/svgs/admin";
import sentMark from "../../assets/svgs/checkmark.svg";
import { Btn, OutlinedBtn } from "../../components/Button";
import ReUseModal from "../../components/Modal/ReUseModal";
import PrimarySelect from "../../components/Selects/PrimarySelect";
import Toggler from "../../components/Togglers";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Radio from "../../components/CustomRadio";
import {
  addCategories,
  createCompetition,
  createFirstCome,
  createPrefill,
  resetAll,
  resetComptition,
  setCategories,
} from "../../features/auth/TournamentSlice";
import { convertDateToFormat } from "../../utils";
import { toast } from "react-toastify";
import {
  ShareFacebook,
  ShareInstagram,
  ShareLinkedIn,
  ShareTwitter,
} from "../../assets/svgs/Icons";
import ColouredSelect from "../../components/Selects/ColouredSelect";
import {
  playersNumber,
  expiryTimes,
  categoriesNumber,
} from "../../utils/sampData";
import {
  createLadder,
  resetAllLadderState,
} from "../../features/auth/ladder-slice";
import { AdminDateInput } from "../../components/Input/DateInput";

const selectedData = [
  {
    name: "Knockout Tournament",
    id: 1,
    url: "/admin/create-tournament/knockout",
    isKnockout: true,
    openLadder: false,
  },
  {
    name: "Ladder Tournament",
    id: 2,
    url: "/admin/create-tournament/ladder",
    isKnockout: false,
    openLadder: true,
  },
  {
    name: "Single Day Competition",
    id: 3,
    url: "",
    isKnockout: false,
    openLadder: false,
  },
];
const KnockoutData = [
  {
    name: "Pre-fill the participants",
    id: 1,
    url: "prefill",
  },
  {
    name: "First-come, First-serve",
    id: 2,
    url: "firstcome",
  },
];
const ladderDataType = [
  {
    name: "Rank Ladder",
    id: 1,
    type: "rank",
  },
  {
    name: "Points Ladder",
    id: 2,
    type: "point",
  },
];

const CreateTournament = () => {
  const dispatch = useAppDispatch();
  const tournamentAuth = useAppSelector((state) => state.tournament);
  const ladder = useAppSelector((state) => state.ladder);
  const navigate = useNavigate();
  const [selected, setSelected] = useState<any>("");
  const [present, setPresent] = useState(1);
  const [selectedType, setSelectedType] = useState<any>("");
  const [, setActive] = useState<boolean>(false);
  const [showKnock, setShowKnock] = useState<boolean>(false);
  const [showLadder, setShowLadder] = useState<boolean>(false);
  const [ladderActive, setLadderActive] = useState<boolean>(false);
  const [, setShowCount] = useState<boolean>(false);
  const [playerNumber, setPlayerNumber] = useState<string>("");
  const [activeB, setActiveB] = useState<boolean>(false);
  const [multipleChallenge, setMultipleChallenge] = useState<boolean>(false);
  const [competitionName, setCompetitionName] = useState<string>("");
  const [competitionDate, setCompetitionDate] = useState<string>("");
  const [competitionActive, setCompetitionActive] = useState<boolean>(false);
  const [openCompetition, setOpenCompetition] = useState<boolean>(false);
  const [competitionState, setCompetitionState] = useState<number>(-1);
  const [ladderType, setLadderType] = useState<{
    name: string;
    id: number;
    type: string;
  }>({ name: "", id: 0, type: "" });
  const [isPrivate, setIsPrivate] = useState(false);

  // activeB is for the second Button which is used for first come first serve.
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [ladderStartDate, setladderStartDate] = useState<string>("");
  const [ladderEndDate, setladderEndDate] = useState<string>("");
  const [openRules, setOpenRules] = useState<boolean>(false);
  const [openLmodal, setOpenLmodal] = useState<boolean>(false);
  const [isTeam, setIsTeam] = useState<boolean>(false);
  const [overflowNum, setOverflowNum] = useState<number>(0);
  const [name, setName] = useState<string>();
  const [rules, setRules] = useState<string>("");
  const [showknockSecondScreen, setShowknockSecondScreen] =
    useState<boolean>(false);
  const [showCompetitionSecondScreen, setShowCompetitionSecondScreen] =
    useState<boolean>(false);
  const [showCompetitionThirdScreen, setShowCompetitionThirdScreen] =
    useState<boolean>(false);
  const [deadline, setDeadline] = useState<string>("");
  const [genderType, setGenderType] = useState<string>("all");
  const [playerNo, setPlayerNo] = useState<{
    value: number;
  }>({ value: 2 });
  const [teamNo, setTeamNo] = useState<{
    value: number;
  }>({ value: 2 });
  const [pointsNo, setPointsNo] = useState<{
    value: number;
  }>({ value: 5 });
  const [lossNo, setLossNo] = useState<{
    value: number;
  }>({ value: 1 });
  const [passNo, setPassNo] = useState<{
    value: number;
  }>({ value: 2 });
  const [walkOverNo, setWalkOverNo] = useState<{
    value: number;
  }>({ value: 3 });
  const [expiryTime, setExpiryTime] = useState<{
    value: string;
    time: number;
  }>({ value: "1 day", time: 24 });
  const [showRankModal, setShowRankModal] = useState<boolean>(false);
  const [showPointModal, setShowPointModal] = useState<boolean>(false);
  const [teamNames, setTeamNames] = useState<string[]>(Array(2).fill(""));
  const handleNumTeamsChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newNumTeams = parseInt(e.target.value, 10);
    setTeamNo({ value: newNumTeams });
    setTeamNames(Array(newNumTeams).fill("")); // Reset the team names array
  };

  const [catNo, setCatNo] = useState<{
    value: number;
  }>({ value: 2 });

  const [openCat, setOpenCat] = useState<boolean>(false);

  const [catNames, setCatNames] = useState<string[]>(Array(2).fill(""));
  const handleCatNameChange = (index: number, name: string) => {
    const newCatNames = [...catNames];
    newCatNames[index] = name;
    setCatNames(newCatNames);
  };
  const handleSave = () => {
    const data = catNames?.map((item: string, index: number) => {
      return { name: item, _id: `${index}` };
    });
    dispatch(setCategories(data));
    setOpenCat(true);
  };

  const handleTeamNameChange = (index: number, name: string) => {
    const newTeamNames = [...teamNames];
    newTeamNames[index] = name;
    setTeamNames(newTeamNames);
  };
  useEffect(() => {
    if (competitionState === 0) {
      setIsPrivate(false);
    }
    if (competitionState === 1) {
      setIsPrivate(true);
    }
  }, [competitionState]);

  useEffect(() => {
    if (competitionDate && competitionName) {
      setCompetitionActive(true);
    } else {
      setCompetitionActive(false);
    }
  }, [competitionDate, competitionName]);
  const handleCreateCompetition = () => {
    const teams = teamNames?.map((team) => {
      return { name: team };
    });
    const data = {
      name: competitionName,
      start: competitionDate,
      stop: competitionDate,
      mode: isPrivate ? "private" : "public",
      team: isTeam,
      teams: isTeam ? teams : [],
    };
    dispatch(createCompetition(data));
  };
  const handleLadderNext = () => {
    setOpenLmodal(true);
    setOpenModal(false);
  };
  const handleNext = () => {
    const data = {
      limit: Number(playerNumber),
      overflow: multipleChallenge,
      overflowNum: overflowNum,
      name: name,
      start: startDate ? convertDateToFormat(startDate) : "",
      stop: endDate ? convertDateToFormat(endDate) : "",
      rules: rules ? rules : "",
    };
    dispatch(createPrefill(data));
  };
  useEffect(() => {
    dispatch(resetAll());
    dispatch(resetComptition());
    dispatch(resetAllLadderState());
  }, []);
  useEffect(() => {
    if (
      tournamentAuth.createCompetionSuccess &&
      tournamentAuth.createCompetitionData &&
      tournamentAuth?.competitionId
    ) {
      setShowCompetitionThirdScreen(true);
      setShowCompetitionSecondScreen(false);
      // setOpenCompetition(true);
    }
  }, [tournamentAuth.createCompetionSuccess]);
  const handleAddCategories = () => {
    const data = {
      id: tournamentAuth?.competitionId,
      data: { names: catNames },
    };
    dispatch(addCategories(data));
  };
  useEffect(() => {
    if (tournamentAuth?.addCategoriesSuccess) {
      setOpenCompetition(true);
      dispatch(resetAll());
    }
  }, [tournamentAuth?.addCategoriesSuccess]);

  const continueCompetition = () => {
    if (isTeam) {
      setShowCompetitionSecondScreen(false);
      setOpenCompetition(false);
      setShowCompetitionThirdScreen(true);
      dispatch(resetComptition());
    } else {
      dispatch(resetComptition());
      navigate(`/admin/tournament`);
    }
    // navigate(`/admin/competition/${tournamentAuth?.competitionId}`);
  };

  const handleCreate = () => {
    const data = {
      limit: Number(playerNumber),
      overflow: multipleChallenge,
      overflowNum: overflowNum,
      name: name,
      start: startDate ? convertDateToFormat(startDate) : "",
      stop: endDate ? convertDateToFormat(endDate) : "",
      rules: rules ? rules : "",
    };
    dispatch(createFirstCome(data));
  };
  useEffect(() => {
    if (tournamentAuth.createFirstComeSuccess) {
      setOpenRules(false);
      setOpenModal(true);
    }
  }, [tournamentAuth.createFirstComeSuccess]);
  useEffect(() => {
    if (tournamentAuth.createPrefillSuccess) {
      setOpenRules(false);
      setOpenModal(true);
    }
  }, [tournamentAuth.createPrefillSuccess]);

  useEffect(() => {
    selected ? setActive(true) : setActive(false);
    selected.openLadder ? setShowLadder(true) : setShowLadder(false);
    selected.openLadder ? setPresent(3) : setPresent(2);
  }, [selected]);

  //This controls the ladder Modal
  useEffect(() => {
    if (ladderStartDate && ladderEndDate) {
      setLadderActive(true);
    } else setLadderActive(false);
  }, [ladderStartDate, ladderEndDate]);

  useEffect(() => {
    selected ? setActive(true) : setActive(false);
    selected.isKnockout ? setShowKnock(true) : setShowKnock(false);
  }, [selected]);
  useEffect(() => {
    selectedType.id ? setShowKnock(true) : setShowCount(false);
    selectedType.id === 2 ? setPresent(1) : setPresent(2);
    //selectedType.id === 2 ? setShowCount(true) : setShowCount(true);
  }, [selectedType, showKnock]);

  useEffect(() => {
    playerNumber ? setActiveB(true) : setActiveB(false);
  }, [playerNumber]);

  const handleContinue = () => {
    setOpenModal(false);
    setShowRankModal(false);
    setShowPointModal(false);
    dispatch(resetAllLadderState());
    navigate("/admin/tournament");
  };

  const copyClipboard = () => {
    const word = `${process.env.REACT_APP_LIVE_URL}/join?id=${tournamentAuth?.competitionId}`;
    navigator.clipboard.writeText(word);
    toast.success("Copied to Clipboard");
  };
  const handleCreateLadder = () => {
    const data = {
      name: competitionName,
      type: ladderType?.type,
      start: ladderStartDate ? convertDateToFormat(ladderStartDate) : "",
      stop: ladderEndDate ? convertDateToFormat(ladderEndDate) : "",
      deadline: deadline ? convertDateToFormat(deadline) : "",
      challenge_timeout: expiryTime?.time,
      challenge_power: playerNo?.value ? playerNo?.value : 2,
      gender: genderType,
      limit: 8,
      regulations: "",
      requirements: "",
      points: pointsNo?.value ? pointsNo?.value : 5,
      pass: passNo?.value ? passNo?.value : 2,
      loss: lossNo?.value ? lossNo?.value : 1,
      walkover: walkOverNo?.value ? walkOverNo?.value : 1,
      multiple_challenge: ladderType?.id === 1 ? null : multipleChallenge,
      mode: "ladder",
    };
    dispatch(createLadder(data));
  };

  useEffect(() => {
    if (ladder?.createLadderSuccess) {
      if (ladderType?.id === 1) {
        setShowRankModal(true);
        setTimeout(() => {
          dispatch(resetAllLadderState());
          navigate(`/admin/tournament`);
        }, 3000);
      } else {
        setShowPointModal(true);
        setTimeout(() => {
          dispatch(resetAllLadderState());
          navigate(`/admin/tournament`);
        }, 3000);
      }
    }
  }, [ladder?.createLadderSuccess]);

  return (
    <DashboardLayout current={3}>
      <div className="w-full flex flex-col bg-[#f4f4f4] h-full min-h-max  p-[30px] 2xl:p-8 2xl:pr-[50px]">
        <div className="flex w-fit" onClick={() => navigate(-1)}>
          <NavigationArrow />
        </div>
        <div
          className={`bg-white rounded-[20px] flex flex-col  ${
            showLadder && ladderType?.id > 0 ? "" : "min-h-screen"
          }  mt-6 relative`}
        >
          {!showknockSecondScreen &&
            !showCompetitionSecondScreen &&
            !showCompetitionThirdScreen && (
              <div className="">
                <div className="h-[51px]">
                  <h1 className="pl-5 text-customBlack font-semibold text-sm mt-[22px] ">
                    Create Tournament
                  </h1>
                </div>
                {/* breaker line */}
                <div className="w-full h-[1px] bg-[#cfcfcf] " />
                {/* form session */}
                <div className="w-full ">
                  <p className="mt-3 text-neutral-700 pl-5 text-sm">
                    Select the type of tournament you want to create
                  </p>
                  <div className="w-10/12 mt-[30px] flex flex-col pl-5">
                    <div className="w-5/12 max-w-[442px]">
                      <PrimarySelect
                        selected={selected}
                        setSelected={setSelected}
                        label="Type of Tournament"
                        data={selectedData}
                        name="Select"
                      />
                    </div>
                    {selected?.id === 3 && (
                      <div className="flex flex-col">
                        <div className="flex   mt-8  items-center ">
                          <p className="text-neutral-700 w-[200px] text-sm">
                            Name of Competition:
                          </p>

                          <input
                            type="text"
                            className="outline-none h-[30px] rounded-[5px] border border-[#afafaf] w-[230px] px-2 focus:outline-none"
                            value={competitionName}
                            onChange={(e: any) => {
                              if (e.target.value.length <= 30) {
                                setCompetitionName(e.target.value);
                              }
                              setCompetitionName(
                                e.target.value.substring(0, 30)
                              );
                            }}
                          />
                        </div>
                        <div className="flex   mt-5  items-center ">
                          <p className="text-neutral-700 w-[200px] text-sm">
                            Competition Date:
                          </p>

                          <AdminDateInput
                            date={competitionDate}
                            setDate={setCompetitionDate}
                            className="h-[30px] w-[230px] min-w-[230px] max-w-[230px]"
                          />
                        </div>
                        <div className="flex items-center mt-[27px] ">
                          <p className="text-neutral-700 w-[200px] text-sm ">
                            Type of Competition:
                          </p>
                          <div className="flex space-x-8">
                            <span className="flex space-x-2 ">
                              <span>
                                <Radio
                                  active={competitionState === 0}
                                  onClick={() => setCompetitionState(0)}
                                />
                              </span>
                              <p className="text-neutral-700  text-sm ">
                                Public Competition
                              </p>
                            </span>
                            <span className="flex space-x-2 ">
                              <Radio
                                active={competitionState === 1}
                                onClick={() => setCompetitionState(1)}
                              />
                              <p className="text-neutral-700  text-sm ">
                                Private Competition
                              </p>
                            </span>
                          </div>
                        </div>
                        <div className="flex   mt-8  items-center justify-between w-fit">
                          <p className="text-neutral-700 w-[200px] text-sm ">
                            Team Competition:
                          </p>

                          <Toggler enabled={isTeam} setEnabled={setIsTeam} />
                        </div>
                      </div>
                    )}
                    <div className="w-5/12 mt-4 max-w-[442px]">
                      {showKnock && !showLadder && selected?.id !== 3 && (
                        <PrimarySelect
                          selected={selectedType}
                          setSelected={setSelectedType}
                          label="Type of Knockout Tournament"
                          data={KnockoutData}
                          name="Select"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {selected?.id === 3 && (
                  <div className="absolute bottom-5 left-0 right-0 flex items-center justify-center w-3/12 mx-auto mt-8 ">
                    {selected?.id === 3 && !isTeam ? (
                      <Btn
                        name="Create Competition"
                        active={competitionActive}
                        onClick={handleCreateCompetition}
                        loading={tournamentAuth.loading}
                        className=""
                      />
                    ) : (
                      <Btn
                        name="Next"
                        active={competitionActive}
                        onClick={() => setShowCompetitionSecondScreen(true)}
                        // loading={tournamentAuth.loading}
                        className=""
                      />
                    )}
                  </div>
                )}

                <div className="absolute bottom-5 left-0 right-0 flex items-center justify-center w-3/12 mx-auto mt-8 ">
                  {selected?.id === 1 && (
                    <Btn
                      name=" Continue"
                      active={selectedType}
                      onClick={
                        selectedType
                          ? () => setShowknockSecondScreen(true)
                          : () => {}
                      }
                      loading={tournamentAuth.loading}
                      className=""
                    />
                  )}
                </div>
              </div>
            )}
          {/* KNOCKOUT SCREEN */}
          <div className="pl-5">
            {showknockSecondScreen && (
              <div className="flex flex-col">
                <div className="h-[51px] ">
                  <h1 className="text-customBlack font-semibold text-sm mt-[22px] ">
                    Select the number of players
                  </h1>
                </div>
                {/* breaker line */}
                <div className="w-full h-[1px] bg-[#cfcfcf] " />
                <p className="mt-3 text-neutral-700 font-normal ">
                  Fill in the details to create a tournament
                </p>
                {/* start of function session */}
                <div className="flex space-x-3  mt-8  items-center">
                  <p className="text-neutral-700">Name of Tournament:</p>

                  <input
                    type="text"
                    className="outline-none h-[30px] rounded-[5px] border border-neutral-400 w-[230px] px-2"
                    value={name}
                    onChange={(e: any) => {
                      if (e.target.value.length <= 30) {
                        setName(e.target.value);
                      }
                      setName(e.target.value.substring(0, 30));
                    }}
                  />
                </div>
                {/* start of function session */}
                <div className="flex   mt-8  items-center">
                  <p className="text-neutral-700">Number of Players:</p>

                  <input
                    type="number"
                    className="outline-none h-[30px] rounded-[5px] border border-neutral-400 w-[230px] px-2 ml-8"
                    onChange={(e: any) => setPlayerNumber(e.target.value)}
                  />
                </div>
                {/* start of function session */}
                <div className="flex   mt-8  items-center justify-between w-[383px]">
                  <p className="text-neutral-700">Allow Overflow</p>

                  <Toggler
                    enabled={multipleChallenge}
                    setEnabled={setMultipleChallenge}
                  />
                </div>
                {multipleChallenge && (
                  <div className="flex mt-8  items-center mb-10">
                    <p className="text-neutral-700">Number of Overflow:</p>

                    <input
                      type="number"
                      className="outline-none h-[30px] rounded-[5px] border border-neutral-400 w-[230px] px-2 ml-6"
                      onChange={(e: any) => setOverflowNum(e.target.value)}
                    />
                  </div>
                )}
                <div className="flex flex-col w-full mt-4">
                  <p className="mt-3 text-customBlack font-semibold ">
                    Set the tournament start date and end date
                  </p>
                  {/* start of function session */}
                  <div className="flex space-x-4 items-center w-full mt-8 ">
                    <div className="flex space-x-3    items-center">
                      <p className="text-neutral-700 min-w-max">
                        Tournament Start Date:
                      </p>

                      <AdminDateInput
                        date={startDate}
                        setDate={setStartDate}
                        className="h-[30px] w-[230px] min-w-[230px]"
                      />
                    </div>
                    <p>To</p>
                    <div className="flex space-x-3    items-center">
                      <p className="text-neutral-700 min-w-max">
                        Tournament End Date:
                      </p>

                      <AdminDateInput
                        date={endDate}
                        setDate={setEndDate}
                        className="h-[30px] w-[230px] min-w-[230px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="w-full mt-0 lg:w-10/12">
              {showLadder && (
                <div className="w-5/12  max-w-[442px]">
                  <PrimarySelect
                    selected={ladderType}
                    setSelected={setLadderType}
                    label="Type of Ladder Tournament"
                    data={ladderDataType}
                    name="Select"
                  />
                </div>
              )}
              {showLadder && ladderType?.id > 0 && (
                <div className="flex flex-col w-full ">
                  {/* start of function session */}
                  <div className="flex items-center gap-[19px] mt-6">
                    <p className="text-neutral-700 text-sm">
                      Name of Tournament:
                    </p>
                    <input
                      type="text"
                      className="outline-none h-[30px] rounded-[5px] border border-[#afafaf]  w-[230px] px-3 focus:outline-none"
                      value={competitionName}
                      onChange={(e: any) => {
                        if (e.target.value.length <= 30) {
                          setCompetitionName(e.target.value);
                        }
                        setCompetitionName(e.target.value.substring(0, 30));
                      }}
                    />
                  </div>
                  <div className="flex space-x-4 items-center w-full mt-6">
                    <div className="flex space-x-3    items-center">
                      <p className="text-neutral-700 text-sm min-w-max">
                        Tournament Start Date:
                      </p>

                      <AdminDateInput
                        date={ladderStartDate}
                        setDate={setladderStartDate}
                        className="h-[30px] w-[230px] min-w-[230px]"
                      />
                    </div>
                    <p className="text-customBlack text-xs">to</p>
                    <div className="flex space-x-3    items-center">
                      <p className="text-neutral-700 text-sm min-w-max">
                        Tournament End Date:
                      </p>

                      <AdminDateInput
                        date={ladderEndDate}
                        setDate={setladderEndDate}
                        className="h-[30px] w-[230px] min-w-[230px]"
                      />
                    </div>
                  </div>
                  <div className="flex gap-[22px]    items-center mt-6">
                    <p className="text-neutral-700 text-sm min-w-max">
                      Registration Deadline:
                    </p>

                    <AdminDateInput
                      date={deadline}
                      setDate={setDeadline}
                      className="h-[30px] w-[230px] max-w-[230px]"
                    />
                  </div>
                  <div className="flex items-center gap-[68px] mt-6">
                    <p className="text-neutral-700 text-sm">Gender Based:</p>
                    <div className="flex gap-6">
                      <span className="flex items-center">
                        <Radio
                          active={genderType === "all"}
                          onClick={() => setGenderType("all")}
                        />
                        <p className="text-sm text-customBlack ml-1">
                          All Club Members
                        </p>
                      </span>
                      <span className="flex items-center">
                        <Radio
                          active={genderType === "male"}
                          onClick={() => setGenderType("male")}
                        />
                        <p className="text-sm text-customBlack ml-1">Male</p>
                      </span>
                      <span className="flex items-center">
                        <Radio
                          active={genderType === "female"}
                          onClick={() => setGenderType("female")}
                        />
                        <p className="text-sm text-customBlack ml-1">Female</p>
                      </span>
                    </div>
                  </div>
                  {ladderType?.id === 2 && (
                    <div className="flex   mt-8  items-center min-w-[490px] max-w-[500px] justify-between ">
                      <p className="text-neutral-700">
                        Allow Multiple challenges
                      </p>

                      <Toggler
                        enabled={multipleChallenge}
                        setEnabled={setMultipleChallenge}
                      />
                    </div>
                  )}

                  <div className="mt-6 flex items-center gap-4 min-w-[490px] max-w-[500px] justify-between  ">
                    <p className="text-sm text-customBlack ml-1">
                      Number of steps:
                    </p>
                    <div className="w-16">
                      <ColouredSelect
                        data={playersNumber}
                        name="2"
                        selected={playerNo}
                        setSelected={setPlayerNo}
                      />
                    </div>
                  </div>
                  {ladderType?.id === 2 && (
                    <div className="mt-6 flex items-center gap-4 min-w-[490px] max-w-[500px] justify-between ">
                      <p className="text-sm text-customBlack ml-1">
                        Number of points earned for winning a challenge:
                      </p>
                      <div className="w-16">
                        <ColouredSelect
                          data={playersNumber}
                          name="5"
                          selected={pointsNo}
                          setSelected={setPointsNo}
                        />
                      </div>
                    </div>
                  )}
                  {ladderType?.id === 2 && (
                    <div className="flex flex-col w-full">
                      <div className="mt-6 flex items-center gap-4 min-w-[490px] max-w-[500px] justify-between ">
                        <p className="text-sm text-customBlack ml-1">
                          Number of points earned for losing a challenge:
                        </p>
                        <div className="w-16">
                          <ColouredSelect
                            data={playersNumber}
                            name="1"
                            selected={lossNo}
                            setSelected={setLossNo}
                          />
                        </div>
                      </div>
                      <div className="mt-6 flex items-center gap-4 min-w-[490px] max-w-[500px] justify-between ">
                        <p className="text-sm text-customBlack ml-1">
                          Number of points earned for winning a match by pass
                        </p>
                        <div className="w-16">
                          <ColouredSelect
                            data={playersNumber}
                            name="2"
                            selected={passNo}
                            setSelected={setPassNo}
                          />
                        </div>
                      </div>
                      <div className="mt-6 flex items-center gap-4 min-w-[490px] max-w-[500px] justify-between ">
                        <p className="text-sm text-customBlack ml-1">
                          Number of points earned for winning a match by walk
                          over
                        </p>
                        <div className="w-16">
                          <ColouredSelect
                            data={playersNumber}
                            name="3"
                            selected={walkOverNo}
                            setSelected={setWalkOverNo}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="mt-6 flex items-center gap-4 min-w-[490px] max-w-[500px] justify-between  ">
                    <p className="text-sm text-customBlack ml-1">
                      Expiration period for a challenge invitation:
                    </p>
                    <div className="w-28">
                      <ColouredSelect
                        data={expiryTimes}
                        name="1 day"
                        selected={expiryTime}
                        setSelected={setExpiryTime}
                      />
                    </div>
                  </div>

                  <div className="w-5/12 mx-auto mt-20 mb-8">
                    {ladderType?.id === 1 ? (
                      <Btn
                        name="Continue"
                        active={ladderStartDate && ladderEndDate ? true : false}
                        onClick={handleCreateLadder}
                        loading={ladder?.loading}
                        className=""
                      />
                    ) : (
                      <Btn
                        name={ladder?.loading ? "Creating..." : "Continue"}
                        active={ladderStartDate && ladderEndDate ? true : false}
                        onClick={handleCreateLadder}
                        loading={ladder?.loading}
                        className=""
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* END OF KOCKOUT SCREEN */}
          {/* Competition SCREEN */}
          <div className="pl-5">
            {showCompetitionSecondScreen && (
              <div className="flex flex-col">
                <div className="h-[51px] ">
                  <h1 className="text-customBlack font-semibold text-sm mt-[22px] ">
                    Team based competition
                  </h1>
                </div>
                {/* breaker line */}
                <div className="w-full h-[1px] bg-[#cfcfcf] " />
                <p className="mt-3 text-neutral-700 font-normal ">
                  Select the number of teams and provide the name of each teams
                </p>
                {/* start of function session */}

                <div className="mt-6 flex items-center gap-4 min-w-[383px] max-w-[385px] justify-between  ">
                  <p className="text-sm text-customBlack ">Number of teams:</p>
                  <div className="w-16">
                    <ColouredSelect
                      data={playersNumber}
                      name="2"
                      selected={teamNo}
                      setSelected={setTeamNo}
                    />
                  </div>
                </div>
                <p className="text-customBlack text-sm font-medium mt-[30px] mb-4">
                  Name of Teams
                </p>
                {Array.from({ length: teamNo.value }, (_, index) => (
                  <div
                    className="flex  items-center mb-4 max-w-[393px] justify-between"
                    key={index}
                  >
                    <p className="text-neutral-700">Team {index + 1}:</p>
                    <input
                      type="text"
                      className="outline-none h-[30px] rounded-[5px] border border-neutral-400 w-[230px] px-2 ml-6"
                      value={teamNames[index]}
                      onChange={(e) =>
                        handleTeamNameChange(index, e.target.value)
                      }
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* END OF competition SCREEN */}
          {/* START OF CATEGORY */}

          {showCompetitionThirdScreen && (
            <div className="">
              <div className="flex flex-col">
                <div className="h-[51px] ">
                  <h1 className="text-customBlack font-semibold text-sm mt-[22px] pl-5 ">
                    Assign Players to Categories
                  </h1>
                </div>
                {/* breaker line */}
                <div className="w-full h-[1px] bg-[#cfcfcf] " />
                <p className="mt-3 text-neutral-700 font-normal pl-5">
                  Select the number of categories for this competition and
                  assign players
                </p>
                {/* start of function session */}
                <div className="mt-6 flex items-center gap-4 min-w-[383px] max-w-[385px] justify-between pl-5  ">
                  <p className="text-sm text-customBlack ">
                    Number of Categories:
                  </p>
                  <div className="w-16">
                    <ColouredSelect
                      data={categoriesNumber}
                      name="2"
                      selected={catNo}
                      setSelected={setCatNo}
                    />
                  </div>
                </div>
                <p className="text-customBlack text-sm font-medium mt-[30px] mb-4 pl-5">
                  Name of Categories
                </p>
                <div className="flex flex-col pb-8">
                  {Array.from({ length: catNo.value }, (_, index) => (
                    <div
                      className="flex  items-center mb-4 max-w-[393px] justify-between pl-5"
                      key={index}
                    >
                      <p className="text-neutral-700">Category {index + 1}:</p>
                      <input
                        type="text"
                        className="outline-none h-[30px] rounded-[5px] border border-neutral-400 w-[230px] px-2 ml-6"
                        value={catNames[index]}
                        onChange={(e) =>
                          handleCatNameChange(index, e.target.value)
                        }
                      />
                    </div>
                  ))}
                </div>
                <div
                  className={` flex items-center justify-center max-w-[413px] w-5/12 mx-auto mt-8 ${
                    catNo?.value > 8
                      ? "flex"
                      : "absolute bottom-5 left-0 right-0"
                  } `}
                >
                  <Btn
                    name=" Continue"
                    active={true}
                    className=""
                    onClick={handleAddCategories}
                  />
                </div>
              </div>
            </div>
          )}
          {/* START OF CATEGORY */}

          <div>
            {showknockSecondScreen && (
              <div className="absolute bottom-3 left-0 right-0 items-center justify-center w-3/12 mx-auto mt-8 ">
                <Btn
                  name="Continue"
                  active={activeB}
                  onClick={() => setOpenRules(true)}
                  className=""
                />

                {/* {showCount ? (
                <Btn
                  name="Create"
                  active={activeB}
                  onClick={handleCreate}
                  className=""
                />
              ) : (
                <Btn
                  name="Create"
                  active={active}
                  onClick={handleNext}
                  className=""
                />
              )} */}
                <div className="h-8 bg-[#fff] w-full"></div>
              </div>
            )}
            {showCompetitionSecondScreen && selected?.id === 3 && (
              <div className="absolute bottom-3 left-0 right-0 items-center justify-center w-3/12 mx-auto mt-8 ">
                <Btn
                  name="Continue"
                  active
                  onClick={handleCreateCompetition}
                  className=""
                />

                <div className="h-8 bg-[#fff] w-full"></div>
              </div>
            )}
          </div>
        </div>
        {/*nSecond secreen  */}
      </div>
      <ReUseModal open={openModal} setOpen={setOpenModal}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-customBlack space-y-1 text-[24px] font-bold text-center">
          Invitation Sent
        </h1>
        <p className="text-center w-10/12 mx-auto text-neutral-500 text-sm">
          A knockout tournament has been created and invitation has been sent to
          all the club members.
        </p>
        <Btn
          active={true}
          name="Continue"
          onClick={handleContinue}
          className="mt-[49px] mb-3"
        />
      </ReUseModal>
      <ReUseModal open={showRankModal} setOpen={setShowRankModal}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-customBlack space-y-1 text-[24px] font-bold text-center">
          Tournament Created
        </h1>
        <p className="text-center w-10/12 max-w-[331px] mx-auto text-neutral-500 text-sm">
          A rank ladder tournament has been created. All the players will be
          notified
        </p>
        <Btn
          active={true}
          name="Continue"
          onClick={handleContinue}
          className="mt-[49px] mb-3"
        />
      </ReUseModal>
      <ReUseModal open={showPointModal} setOpen={setShowPointModal}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-customBlack space-y-1 text-[24px] font-bold text-center">
          Tournament Created
        </h1>
        <p className="text-center w-10/12 max-w-[331px] mx-auto text-neutral-500 text-sm">
          A point ladder tournament has been created. All the players will be
          notified
        </p>
        <Btn
          active={true}
          name="Continue"
          onClick={handleContinue}
          className="mt-[49px] mb-3"
        />
      </ReUseModal>
      <ReUseModal open={openCompetition} setOpen={setOpenCompetition}>
        <div className="flex flex-col">
          <h1 className="text-customBlack text-[22px] font-bold">
            Copy and Share Competition Link
          </h1>
          <div className="flex mt-8 mb-6 border-border border h-[52px] rounded-[16px] items-center justify-between pl-4 pr-2 space-x-2">
            <p className="text-customBlack text-sm font-medium  truncate">
              {`${process.env.REACT_APP_LIVE_URL}/join?id=${tournamentAuth?.competitionId}`}
            </p>
            <button
              className="h-[48px] rounded-[12px] bg-[#9CD326] justify-center text-white flex text-[12px] items-center min-w-[60px] border-none outline-none"
              onClick={copyClipboard}
            >
              copy
            </button>
          </div>
          <div className="w-full space-x-6 flex items-center ">
            <span>
              <ShareFacebook />
            </span>
            <span>
              <ShareTwitter />
            </span>
            <span>
              <ShareLinkedIn />
            </span>
            <span>
              <ShareInstagram />
            </span>
          </div>
        </div>
        <div className="flex justify-center mt-[50px] mb-3 space-x-8">
          <span className="w-6/12">
            <OutlinedBtn
              active={true}
              name="Back"
              onClick={() => {
                dispatch(resetComptition());
                setOpenCompetition(false);
              }}
              className="h-[49px] max-h-[49px]"
            />
          </span>
          <span className="w-6/12">
            <Btn
              active={true}
              name="Continue"
              onClick={() => navigate("/admin/tournament")}
              className=""
            />
          </span>
        </div>
      </ReUseModal>

      {/* LADDDER MODALS */}
      <ReUseModal open={openRules} setOpen={setOpenRules}>
        <h1 className="text-customBlack font-bold text-center">
          Regulations and Requirement
        </h1>
        <p className="text-center w-full mx-auto text-neutral-500 ">
          Please fill in the requirement for the tournament
        </p>
        <p className="text-neutral-500 mt-8 ">Regulations</p>
        <textarea
          className="border-border border-[2px] flex items-baseline  h-[142px] w-full placeholder:text-placeholder p-2 rounded-[5px]"
          placeholder="Write a message.."
          onChange={(e: any) => setRules(e.target.value)}
        />
        <p className="text-neutral-500 mt-6 ">Requirements</p>
        <textarea
          className="border-border border-[2px] h-[142px] w-full placeholder:text-placeholder p-2 rounded-[5px]"
          placeholder="Write a message.."
        />
        {present === 1 && (
          <Btn
            name="Send Invitation"
            active={activeB}
            onClick={handleCreate}
            loading={tournamentAuth.loading}
            className="mt-[72px]"
          />
        )}
        {present === 2 && !ladderActive && (
          <Btn
            name="Send Invitation"
            active={activeB}
            onClick={handleNext}
            loading={tournamentAuth.loading}
            className="mt-[72px]"
          />
        )}
        {(present === 3 || ladderActive) && (
          <Btn
            name="Send to all Players"
            active={rules ? true : false}
            onClick={handleLadderNext}
            className="mt-[72px]"
            loading={false}
          />
        )}
      </ReUseModal>
      <ReUseModal open={openCat} setOpen={setOpenCat}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-customBlack space-y-1 text-[24px] font-bold text-center">
          Categories Added
        </h1>
        <p className="text-center w-10/12 mx-auto text-neutral-500 text-sm">
          You have successfully added the categories for the team competition.
        </p>
        <Btn
          active={true}
          name="Continue"
          onClick={() => navigate("/admin/tournament")}
          className="mt-[49px] mb-3"
        />
      </ReUseModal>
      <ReUseModal open={openLmodal} setOpen={setOpenLmodal}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-cutomBlack text-[24px] font-bold text-center">
          Tournament Created
        </h1>
        <p className="text-center w-10/12 mx-auto text-neutral-500 ">
          A {ladderActive ? " Ladder " : " knockout "} tournament has been
          created. All the players will receive the notification for the matches
        </p>
        <Btn
          active={true}
          name="Continue"
          onClick={() => {
            setOpenRules(false);
            setOpenLmodal(false);
            navigate("/admin/create-tournament/ladder");
          }}
          className="mt-[25px] mb-3"
        />
      </ReUseModal>
    </DashboardLayout>
  );
};

export default CreateTournament;
