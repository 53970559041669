import { useNavigate } from "react-router-dom";
import { BackBtn } from "../../assets/svgs/Icons";

const Back = ({ className }: { className?: string }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`w-fit cursor-pointer ${className}`}
      onClick={() => navigate(-1)}
    >
      <BackBtn />
    </div>
  );
};

export default Back;
