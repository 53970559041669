import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import receipt from "../../../../assets/svgs/receipt.svg";

import Options from "../../../Selects/Options";
import sentMark from "../../../../assets/svgs/checkmark.svg";
import {
  TrashIcon,
  SingleUserIcon,
  TennisBall,
  EditPencil,
} from "../../../../assets/svgs/Icons";
import ReUseModal from "../../../Modal/ReUseModal";
import { DeleteIcon } from "../../../../assets/svgs/tournament";
import { Btn } from "../../../Button";
import {
  deleteLadder,
  getAllLadder,
  getSingleLadderDetail,
  resetAllLadderState,
  restoreSingleLadder,
  setSelectedLadder,
} from "../../../../features/auth/ladder-slice";
import { formatDate } from "../../../../utils";

const AllLadderTables = ({ className }: any) => {
  const dispatch = useAppDispatch();
  const ladder = useAppSelector((state) => state.ladder);

  useEffect(() => {
    dispatch(restoreSingleLadder());
    dispatch(getAllLadder({ page: 1, size: 20 }));
  }, []);

  const allLadders = ladder?.allLadders ? ladder?.allLadders : [];

  useEffect(() => {
    if (ladder?.ladderDeleteSuccess) {
      dispatch(resetAllLadderState());
      dispatch(getAllLadder({ page: 1, size: 20 }));
    }
  }, [ladder?.ladderDeleteSuccess]);

  return (
    <div
      className={`w-full  h-full min-h-[40vh] flex flex-col bg-white  rounded-[20px] ${className} `}
    >
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className="text-sm font-semibold text-customBlack">
          Ladder Tournament
        </h1>
      </div>
      <table className="min-w-full w-full h-full bg-[#fff] rounded-b-[20px]   ">
        <thead className=" bg-[#f4f4f4] bg-opacity-40 mb-2">
          <tr className="flex items-center h-[35px] pl-5 gap-3">
            <th className="w-3/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-start ">
              {" "}
              Name of Tournament
            </th>

            <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-start ">
              {" "}
              Type
            </th>
            <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-start ">
              {" "}
              Start Date
            </th>
            <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-start ">
              {" "}
              End Date
            </th>
            <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-center ">
              {" "}
              No of Players
            </th>
            <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-center ">
              Matches
            </th>
            <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-start ">
              {" "}
              Status
            </th>
            <th className="w-3/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-start flex justify-start ">
              {" "}
              Action
            </th>
          </tr>
        </thead>
        <tbody className="w-full flex flex-col space-y-6 mb-4 mt-2">
          {allLadders?.map((item: any, index: number) => {
            return (
              <SingleRow
                item={item}
                index={index}
                key={index}
                total={allLadders?.length}
              />
            );
          })}
          {allLadders?.length === 0 && (
            <div className="mt-10 border-2.38 border-gray-600 w-38 h-38 flex flex-col items-center">
              <img
                src={receipt}
                className="border-2.5 border-gray-600 w-38 h-38"
                alt=""
              />
              <h1 className="font-inter text-sm font-medium leading-15 tracking-wider w-106 h-15 text-center mt-5">
                No Ladder Matches
              </h1>
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AllLadderTables;

const SingleRow = ({ item, index, total }: any) => {
  const ladder = useAppSelector((state) => state?.ladder);
  const dispatch = useAppDispatch();
  const { players, action, matches, name, _type, start, stop, status, _id } =
    item;
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteSuccess, setOpenDeleteSuccess] = useState(false);
  const checkIsStarted = (startDate: string): boolean => {
    const currentDate = new Date();
    const start = new Date(startDate);

    // Ensure we compare only the date parts, ignoring the time parts
    currentDate.setHours(0, 0, 0, 0);
    start.setHours(0, 0, 0, 0);

    return currentDate >= start;
  };

  const isStarted = checkIsStarted(start);

  const navigate = useNavigate();
  const handleDelete = () => {
    dispatch(deleteLadder({ id: _id }));
  };

  useEffect(() => {
    if (ladder?.ladderDeleteSuccess) {
      setOpenDelete(false);
      setOpenDeleteSuccess(true);
    }
  }, [ladder?.ladderDeleteSuccess]);
  const isLastfour = total > 4 ? index >= total - 4 : false;

  return (
    <>
      <tr className="w-full flex items-center pl-5 gap-3 " key={index}>
        <td
          className="text-start text-sm text-customBlack capitalize w-3/12 cursor-pointer"
          onClick={() => navigate(`/admin/view-ladder-detail/${_id}`)}
        >
          {name}
        </td>

        <td
          className="text-start text-sm text-customBlack capitalize w-1/12 curor-pointer"
          onClick={() => navigate(`/admin/view-ladder-detail/${_id}`)}
        >
          {_type}
        </td>
        <td
          className="text-start text-sm text-customBlack capitalize w-1/12 cursor-pointer"
          onClick={() => navigate(`/admin/view-ladder-detail/${_id}`)}
        >
          {formatDate(start)}
        </td>
        <td
          className="text-start text-sm text-customBlack capitalize w-1/12 cursor-pointer"
          onClick={() => navigate(`/admin/view-ladder-detail/${_id}`)}
        >
          {formatDate(stop)}
        </td>
        <td
          className=" text-sm text-customBlack capitalize w-1/12 text-center cursor-pointer"
          onClick={() => navigate(`/admin/view-ladder-detail/${_id}`)}
        >
          {players?.length}
        </td>
        <td
          className="text-center text-sm text-customBlack capitalize w-1/12 cursor-pointer"
          onClick={() => navigate(`/admin/view-ladder-detail/${_id}`)}
        >
          0
        </td>
        <td className="text-start text-sm text-customBlack capitalize w-1/12">
          {status !== "ongoing" ? (
            <p className="bg-[#F7F7F7] text-[#E48900] text-sm p-[6px] rounded-[8px] flex items-center justify-center w-fit capitalize  ">
              {status}
            </p>
          ) : (
            <p className="bg-[#D1E5D6] text-[#197C34] text-sm p-[6px] rounded-[8px] flex items-center justify-center w-fit ">
              Started
            </p>
          )}
        </td>
        <td className="text-start text-sm text-customBlack capitalize w-3/12 flex justify-start">
          <div className="w-full max-w-[250px] flex items-center justify-between">
            {_type.toLowerCase() === "rank" ? (
              <p
                className=" text-[#E48900] text-sm font-[500] underline cursor-pointer"
                onClick={() => navigate(`/admin/rank-position/${_id}`)}
              >
                View Ranking
              </p>
            ) : (
              <p
                className=" text-[#E48900] text-sm font-[500] underline cursor-pointer"
                onClick={() => navigate(`/admin/point-position/${_id}`)}
              >
                View Tournament
              </p>
            )}
            <span>
              <Options isLast={isLastfour}>
                <div className="w-full flex flex-col ">
                  <div
                    className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                    onClick={() => {
                      dispatch(getSingleLadderDetail({ id: _id }));
                      navigate(`/admin/edit-ladder/${_id}`);
                    }}
                  >
                    <span>
                      <EditPencil />
                    </span>
                    <p className="text-customBlack text-sm">
                      Edit Ladder Tournament
                    </p>
                  </div>

                  <div
                    className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                    onClick={() => navigate(`/admin/ladder-match/${_id}`)}
                  >
                    <span>
                      <TennisBall />
                    </span>
                    <p className="text-customBlack text-sm">View Matches</p>
                  </div>
                  <div
                    className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                    onClick={() => {
                      dispatch(setSelectedLadder(item));
                      _type === "rank"
                        ? navigate(`/admin/rank-position/${_id}`)
                        : navigate(`/admin/point-position/${_id}`);
                    }}
                  >
                    <span>
                      <SingleUserIcon />
                    </span>
                    <p className="text-customBlack text-sm">View Players</p>
                  </div>
                  {!isStarted && (
                    <div
                      className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                      onClick={() => {
                        dispatch(setSelectedLadder(item));
                        _type === "rank"
                          ? navigate(`/admin/arrange-players/${_id}`)
                          : navigate(`/admin/arrange-players/${_id}`);
                      }}
                    >
                      <span>
                        <SingleUserIcon />
                      </span>
                      <p className="text-customBlack text-sm">
                        Arrange Players
                      </p>
                    </div>
                  )}
                  <div
                    className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                    onClick={() => setOpenDelete(true)}
                  >
                    <span>
                      <TrashIcon />
                    </span>
                    <p className="text-[#B00020] text-sm">Delete Tournament</p>
                  </div>
                </div>
              </Options>
            </span>
          </div>
        </td>
      </tr>
      <ReUseModal open={openDelete} setOpen={setOpenDelete}>
        <div className="w-full flex flex-col">
          <div className="flex items-center justify-center mt-10">
            <DeleteIcon />
          </div>
          <h1 className="text-center mt-2 font-bold text-[22px] leading-[24px] text-customBlack ">
            Delete Tournament
          </h1>
          <p className="text-[#868686] mt-1 text-sm max-w-[340px] mx-auto">
            Are you sure you want to delete this tournament?
          </p>
          <div className="w-full flex items-center justify-center  gap-6 mt-16">
            <button
              className="w-full max-w-[174px] rounded-[16px] border border-[#9cd326] h-[52px] flex items-center cursor-pointer justify-center text-sm font-semibold text-[#9CD326] "
              onClick={() => setOpenDelete(false)}
              disabled={ladder?.loading}
            >
              No Cancel
            </button>
            <button
              className="w-full max-w-[174px] rounded-[16px] bg-[#B00020] h-[52px] flex items-center cursor-pointer justify-center text-sm font-semibold text-white border-none outline-none"
              onClick={handleDelete}
              disabled={ladder?.loading}
            >
              {ladder?.loading ? "Loading..." : "Yes Delete"}
            </button>
          </div>
        </div>
      </ReUseModal>
      <ReUseModal open={openDeleteSuccess} setOpen={setOpenDeleteSuccess}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-customBlack space-y-1 text-[24px] font-bold text-center">
          Tournament Deleted
        </h1>
        <p className="text-center w-10/12 max-w-[331px] mx-auto text-neutral-500 text-sm">
          You’ve successfully deleted this ladder tournament
        </p>
        <Btn
          active={true}
          name="Close"
          onClick={() => setOpenDeleteSuccess(false)}
          className="mt-[49px] mb-3"
        />
      </ReUseModal>
    </>
  );
};
