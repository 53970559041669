import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { allBookings } from "../../../../features/auth/TournamentSlice";
import { convertDateFormat, convertTimeToAMPM } from "../../../../utils";
import receipt from "../../../../assets/svgs/receipt.svg";
import { updateCourtBooking } from "../../../../features/auth/TournamentSlice";
import LoadingComponent from "../../../Loading/Loader";
const AllRejectedCourtTable = ({ className, setAllRejectedCourtNo }: any) => {
  const dispatch = useAppDispatch();
  const tournament = useAppSelector((state) => state.tournament);
  const [allRejected, setAllRejected] = useState<any>([]);
  useEffect(() => {
    dispatch(allBookings());
  }, []);
  const allBooks = tournament?.allBookings;

  useEffect(() => {
    const allRejectedBookings = tournament?.allBookings?.filter(
      (booking: any) => booking.state === "rejected"
    );
    setAllRejected(allRejectedBookings);
    setAllRejectedCourtNo(allRejectedBookings?.length);
  }, []);

  if (tournament?.fetchLoading) {
    return (
      <div className="w-full 2xl:min-h-[50vh] lg:min-h-[60vh] px-6 mt-4">
        <LoadingComponent />
      </div>
    );
  }

  return (
    <div
      className={`w-full   h-auto  flex flex-col bg-white  rounded-[20px] ${className} `}
    >
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className="text-sm font-semibold text-customBlack">
          Rejected Bookings
        </h1>
      </div>
      {/* Table section starts from here  #################################################3*/}
      <table className="min-w-full w-full h-full 2xl:min-h-[50vh] lg:min-h-[60vh]  ">
        <thead>
          <tr className="w-full bg-[#f4f4f4] bg-opacity-30 flex item-center h-[35px] pl-5">
            <th className="w-3/12 text-xs text-neutral-600 flex items-center ">
              Court Name
            </th>
            <th className="w-2/12 text-xs text-neutral-600 flex items-center">
              Booked By
            </th>
            <th className="w-2/12 text-xs text-neutral-600 flex items-center">
              Location
            </th>
            <th className="w-2/12 text-xs text-neutral-600 flex items-center">
              Booked Date
            </th>
            <th className="w-2/12 text-xs text-neutral-600 flex items-center">
              Booked Time
            </th>
            <th className="w-1/12 text-xs text-neutral-600 flex items-center">
              Status
            </th>
            {/* <th className="w-1/12 text-xs text-neutral-600 flex items-center">
              Action
            </th> */}
          </tr>
        </thead>

        <tbody className="w-full  rounded-b-[20px] flex flex-col h-full ">
          {allRejected?.map((item: any, index: number) => {
            return <SingleRow item={item} key={index} index={index} />;
          })}
          {allRejected.length === 0 && (
            <tr>
              <td className="w-full flex flex-col items-center justify-center">
                <div className="mt-10 border-2.38 border-gray-600  flex flex-col items-center">
                  <img
                    src={receipt}
                    className="border-2.5 border-gray-600 w-38 h-38"
                    alt=""
                  />
                  <h1 className="font-inter text-xs font-medium leading-15 tracking-wider w-106 h-15 text-center mt-5">
                    No Rejected Bookings Yet.
                  </h1>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* Table section Ends from here $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$4 */}
    </div>
  );
};

export default AllRejectedCourtTable;

const SingleRow = ({ item, index }: any) => {
  const tournament = useAppSelector((state) => state.tournament);
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  let firsttime = item?.time?.split(" ");
  const [time, setTime] = useState(firsttime ? firsttime[0] : "0:00");

  const update = () => {
    const data = {
      id: item?._id,
      time: convertTimeToAMPM(time),
    };
    dispatch(updateCourtBooking(data));
  };
  useEffect(() => {
    if (tournament.editBookingSuccess) {
      setOpenModal(false);
    }
  }, [tournament.editBookingSuccess]);

  return (
    <>
      <tr className="flex  py-3  items-center pl-5" key={index}>
        <td className=" w-3/12 text-sm text-customBlack font-[500] text-start flex items-center gap-3 ">
          <span>
            <img
              src={item?._court?.image}
              alt=""
              className="w-7 h-7 rounded-full object-cover "
            />
          </span>
          <p className="text-sm text-customBlack capitalize">
            {item?._court?.name}
          </p>
        </td>
        <td className=" w-2/12 text-sm text-customBlack font-[500] text-start capitalize ">
          {item?._user?.firstname ?? "Admin"} {item?._user?.lastname ?? ""}
        </td>
        <td className=" w-2/12 text-sm text-customBlack font-[500] text-start capitalize ">
          {" "}
          {`${item?._court?.state ?? " "}`} {item?._court?.country}
        </td>
        <td className=" w-2/12 text-sm text-customBlack font-[500] text-start ">
          {convertDateFormat(item.date)}
        </td>
        <td className=" w-2/12 text-sm text-customBlack font-[500] text-start ">
          {item.time}
        </td>
        <td className=" w-1/12 text-sm text-customBlack font-[500] text-start ">
          <p className="bg-[#F7E6E9] text-[#B00020] text-xs p-[6px] rounded-[8px] flex items-center justify-center w-fit ">
            Rejected
          </p>
        </td>
        {/* <td className=" w-1/12 text-sm text-neutral-600 text-start "></td> */}
      </tr>
    </>
  );
};
