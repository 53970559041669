import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardLayout from "../../../Layouts/DashboardLayout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Btn } from "../../../components/Button";
import { prefillAddPlayers } from "../../../features/auth/TournamentSlice";
import DropDownMenu from "./DropDownMenu";

const SelectPlayerPrefill = () => {
  return (
    <DashboardLayout current={3}>
      <ViewAllPlayer />
    </DashboardLayout>
  );
};

export default SelectPlayerPrefill;

const ViewAllPlayer = () => {
  const navigate = useNavigate();
  const tournament = useAppSelector((state) => state.tournament);
  const admin = useAppSelector((state) => state.adminAuth);
  const [playersList, setPlayersList] = useState([]);
  const dispatch = useAppDispatch();
  const numbers = useParams();
  let filterId = numbers.id;

  //   useEffect(() => {
  //     dispatch(getAllActiveplayer({ id: filterId }));
  //   }, []);

  let playersArray = admin.allClubMembers;

  const handleAddPlayers = () => {
    let data = {
      id: filterId,
      data: {
        incoming: playersList,
      },
    };
    if (!data) {
      return null;
    } else {
      dispatch(prefillAddPlayers(data));
    }
  };

  useEffect(() => {
    if (tournament.prefillAddSuccess) {
      toast.success("Successfully added selected player to the tournament");
      setTimeout(() => {
        navigate("/admin/tournament");
      }, 1000);
    }
  }, [tournament.prefillAddSuccess, navigate]);

  //Pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [array, setArray] = useState<any>([]);
  const [numberPerPage, setNumberPerPage] = useState<number>(10);
  const lastPageIndex: number = currentPage * numberPerPage;
  const firstPageIndex: number = lastPageIndex - numberPerPage;
  const records = playersArray.slice(firstPageIndex, lastPageIndex);
  const nPage = Math.ceil(playersArray.length / numberPerPage);

  useEffect(() => {
    const paginationNumbers = [];
    for (let i = 1; i <= nPage; i++) {
      paginationNumbers.push(i);
    }
    setArray(paginationNumbers);
  }, [nPage]);

  function nextPage() {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1);
    }
  }
  const changePage = (id: any): void => {
    setCurrentPage(id);
  };
  function prevPage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  return (
    <div className="bg-white rounded-[20px] flex flex-col h-full mt-6 flow-hide relative ">
      <div className="h-[51px] ">
        <h1 className="pl-5 text-customBlack font-semibold text-sm mt-[22px] ">
          All Available Players
        </h1>
      </div>
      <div className="w-full h-[35px] flex items-center space-x-[43px] border border-border bg-[#f4f4f4] bg-opacity-30 pl-5">
        <p className="text-xs text-neutral-700">S/N</p>
        <p className="text-xs text-neutral-700">Names of Available Players</p>
      </div>
      <div className="w-full flex flex-col space-y-4 ">
        {records?.map((item: any, index: number) => {
          return (
            <SinglePlayer
              playersList={playersList}
              setPlayersList={setPlayersList}
              index={index}
              item={item}
              key={index}
            />
          );
        })}
      </div>
      {records.length > 0 && (
        <nav className="flex justify-center space-x-7 items-center mt-4">
          <button
            onClick={prevPage}
            className="hover:bg-lemonGreen p-3 rounded transition ease-in-out delay-1s border-solid border-2"
          >
            Prev
          </button>
          {array.map((item: any, index: number) => {
            return (
              <div key={index}>
                <button
                  onClick={() => changePage(item)}
                  className={`hover:bg-lemonGreen p-2 rounded transition ease-in-out delay-1s border-solid ${
                    currentPage === item ? "bg-[#A8d841] text-[#FFF]" : ""
                  }`}
                >
                  {item}
                </button>
              </div>
            );
          })}
          <button
            onClick={nextPage}
            className="hover:bg-lemonGreen p-3 rounded transition ease-in-out delay-1s border-solid border-2"
          >
            Next
          </button>
          <DropDownMenu
            setNumberPerPage={setNumberPerPage}
            numberPerPage={numberPerPage}
          />
        </nav>
      )}
      {records.length > 0 && (
        <div className="mt-8 mb-10 flex justify-center">
          <div className="w-3/12 flex justify-center">
            <Btn
              name="Proceed"
              loading={tournament.loading}
              active={true}
              onClick={handleAddPlayers}
              className="mx-auto"
            />
          </div>
        </div>
      )}
      {records.length === 0 && (
        <div className="mt-4 w-full flex justify-center">
          <h1 className="text-neutral-700 pl-6 font-bold">
            No Players Available yet
          </h1>
        </div>
      )}
    </div>
  );
};

const SinglePlayer = ({ item, playersList, setPlayersList, index }: any) => {
  const [active, setActive] = useState(false);
  const id = item?._id;
  const addItem = (id: any) => {
    if (playersList.includes(id)) {
      let servicesList = playersList.filter((e: any) => e !== id);
      setPlayersList(servicesList);
      setActive(false);
    } else {
      let serviceList = [...playersList, id];
      setPlayersList(serviceList);
      setActive(true);
    }
  };
  return (
    <div className="w-full h-[35px] flex items-center space-x-[43px] pl-5 mt-6 justify-between pr-8">
      <div className="flex items-center space-x-6">
        <p className="text-customBlack capitalize">{index + 1}</p>
        <div className="flex items-center space-x-4">
          <img
            src={
              item?.image
                ? item?.image
                : "https://img.freepik.com/premium-vector/avatar-profile-icon_188544-4755.jpg"
            }
            alt=""
            className="w-[35x] h-[35px] rounded-full"
          />
          <p className="text-md font-[500] capitalize">
            {item?.lastname
              ? item.firstname + " " + item.lastname
              : item.firstname}{" "}
          </p>
        </div>
      </div>
      <div>
        <p
          className={
            active
              ? "text-red-500 font-bold cursor-pointer hover:bg-neutral600 hover:text-[white] p-3 rounded transition ease-in-out delay-1s border-solid border-2"
              : "text-lemonGreen font-bold cursor-pointer hover:bg-lemonGreen hover:text-[white] p-3 rounded transition ease-in-out delay-1s border-solid border-2"
          }
          onClick={() => addItem(id)}
        >
          {active ? "Remove Player" : "Add Player"}
        </p>
      </div>
    </div>
  );
};
