import { useState, useEffect } from "react";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { TbBuildingStore } from "react-icons/tb";
import { BsArrowRight } from "react-icons/bs";
import invitation from "../../assets/svgs/invitaiton.svg";
import users from "../../assets/svgs/users.svg";
import scoreboard from "../../assets/svgs/scoreboard.svg";
import { HiSwitchHorizontal } from "react-icons/hi";
import "../../index.css";
import user from "../../assets/svgs/profile.svg";
import axios from "axios";
import GeneralLayout from "../../Layouts/GeneralLayout";
import { useAppSelector } from "../../app/hooks";
export const dataList = [
  {
    id: 1,
    playerName: "Brenda Miles",
    playerRank: "RK: 45,093,334",
    time: "19:00",
    date: "12 JUN",
    opponentName: "James Thompson",
    opponentRank: "RK: 45,093,334",
  },
  {
    id: 2,
    playerName: "Brenda Miles",
    playerRank: "RK: 45,093,334",
    time: "19:00",
    date: "12 JUN",
    opponentName: "James Thompson",
    opponentRank: "RK: 45,093,334",
  },
  {
    id: 3,
    playerName: "Brenda Miles",
    playerRank: "RK: 45,093,334",
    time: "19:00",
    date: "12 JUN",
    opponentName: "James Thompson",
    opponentRank: "RK: 45,093,334",
  },
];
const Quote = () => {
  const auth = useAppSelector((state) => state.auth);
  const username = auth?.userData?.user?.firstname;
  const userClub = auth?.clubDetail?._club?.name;

  const [quote, setQuote] = useState([]);

  useEffect(() => {
    axios.get("https://apis.tennislove.app/api/v1/quote").then((res: any) => {
      console.log(res?.data);
      return setQuote(res?.data.data);
    });
  }, []);

  return (
    <>
      <GeneralLayout
        name=""
        active={1}
        greetings="welcome"
        showTopSection={false}
        childContainerClass="mt-0"
      >
        <main className="px-6 my-12">
          <div className="flex justify-between">
            <div>
              <h1 className="font-bold text-customBlack text-2xl">
                Hi, {username}
              </h1>
              <p className="font-normal text-neutral-500 text-sm mt-2">
                Good morning, enjoy your games
              </p>
            </div>
            <div className="flex">
              <TbBuildingStore className="text-lemonGreen rounded-xl p-2 text-[2.5rem] bg-white mr-2 shadow-[0_9px_30px_rgba(0,0,0,0.07)]" />
              <MdOutlineNotificationsActive className="text-lemonGreen rounded-xl p-2 text-[2.5rem] bg-white shadow-[0_9px_30px_rgba(0,0,0,0.07)]" />
            </div>
          </div>
          <div className="bg-primaryYellow h-36 tennis-bg my-8 rounded-3xl">
            <div className="ml-6 pt-8">
              <p className="font-bold text-base text-white">{userClub}</p>
              <button className=" flex items-center bg-[#805400] bg-opacity-30 text-white p-2 rounded-lg font-normal text-[10px] mt-4">
                <HiSwitchHorizontal className="text-xl mr-1" />
                Switch Club
              </button>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="bg-white mr-2 shadow-[0_9px_30px_rgba(0,0,0,0.07)] p-4 rounded-lg">
              <img src={invitation} alt="invitation icon" className="mx-auto" />
              <p className="text-sm font-medium text-neutral-700 mt-3">
                All Invitations
              </p>
            </div>
            <div className="bg-white mr-2 shadow-[0_9px_30px_rgba(0,0,0,0.07)] p-4 rounded-lg">
              <img src={users} alt="user icon" className="mx-auto" />
              <p className="text-sm font-medium text-neutral-700 mt-3">
                My Matches
              </p>
            </div>
            <div className="bg-white shadow-[0_9px_30px_rgba(0,0,0,0.07)] p-4 rounded-lg">
              <img src={scoreboard} alt="scoreboard icon" className="mx-auto" />
              <p className="text-sm font-medium text-neutral-700 mt-3">
                Match Score
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center border border-neutral-200 my-8 p-3 rounded-2xl">
            <div className="flex items-center">
              <img
                src={user}
                alt="User Icon"
                className="p-2 rounded-3xl bg-[#ffeecc]"
              />
              <p className="text-sm font-medium text-customBlack pl-3">
                Complete Profile
              </p>
            </div>
            <div>
              <BsArrowRight />
            </div>
          </div>
          <h2 className="mb-4 font-semibold text-neutral-700 text-sm">
            Upcoming Matches
          </h2>
          <div className="mb-8">
            {dataList.length === 0 ? (
              <div className="border border-neutral-200 p-4">
                <p className="text-sm font-semibold text-customBlack">
                  No matches available
                </p>
              </div>
            ) : (
              dataList.map((item, index) => (
                <div
                  key={index}
                  className="flex justify-evenly items-center border border-neutral-200 mb-4 p-4 rounded-xl"
                >
                  <div>
                    <p className="text-sm font-semibold text-customBlack">
                      {item.playerName}
                    </p>
                    <p className="font-medium text-[10px] text-neutral-400">
                      {item.playerRank}
                    </p>
                  </div>
                  <div>
                    <p className="text-xs font-bold text-primaryYellow">
                      {item.time}
                    </p>
                    <p className="font-bold text-[10px] text-primaryYellow">
                      {item.date}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-semibold text-customBlack">
                      {item.opponentName}
                    </p>
                    <p className="font-medium text-[10px] text-neutral-400">
                      {item.opponentRank}
                    </p>
                  </div>
                </div>
              ))
            )}
          </div>
          <h2 className="mb-4 font-semibold text-customBlack text-sm">
            Tennis Quote
          </h2>
          <div className="border-2 rounded-xl border-primaryYellow p-5 mb-20">
            {quote.length > 0 ? (
              quote.map((item: any, index: number) => (
                <div key={index}>
                  <p className="text-xs font-regular leading-6 text-neutral-600">
                    {item.message}
                  </p>
                  <p className="text-sm font-medium text-neutral-600">
                    - {item.author}{" "}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-xs font-regular leading-6 text-neutral-600">
                No quotes available
              </p>
            )}
          </div>
        </main>
      </GeneralLayout>
    </>
  );
};

export default Quote;
