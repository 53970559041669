import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import sentMark from "../../../../assets/svgs/sent-mark.svg";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { More } from "../../../../assets/svgs/court";
import { Btn } from "../../../../components/Button";
import { UserDateTimeInput } from "../../../../components/Input/DateInput";
import ReUseModal from "../../../../components/Modal/ReUseModal";
import UserSelect from "../../../../components/Selects/UserSelect";
import { getAllCourtUser } from "../../../../features/auth/ladder-slice";
import {
  invitePlayer,
  restoreDefault,
  viewPlayer,
} from "../../../../features/auth/playerslice";
import LoadingComponent from "../../../../components/Loading/Loader";

const InviteSinglePlayer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [present, setPresent] = useState(1);
  const [playerDetail, setPlayerDetail] = useState<any>({});
  const ladder = useAppSelector((state) => state.ladder);
  const player = useAppSelector((state) => state.player);
  const auth = useAppSelector((state) => state.auth);
  const [selectedCourt, setSelectedCourt] = useState<any>({});
  const [date, setDate] = useState("");
  const userData = auth?.userData?.user;
  const [openModal, setOpenModal] = useState(false);
  const [active, setActive] = useState(false);

  const id = useParams();

  useEffect(() => {
    dispatch(getAllCourtUser());
    dispatch(viewPlayer(id.id));
  }, []);
  useEffect(() => {
    if (selectedCourt && date) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [selectedCourt, date]);

  useEffect(() => {
    if (player?.invitePlayerSuccess) {
      setOpenModal(true);
      dispatch(restoreDefault());
    }
  }, [player?.invitePlayerSuccess]);
  useEffect(() => {
    setPlayerDetail(player?.singlePlayer);
  }, [player?.singlePlayer]);

  const allCourts = ladder?.allCourts;

  const handleInvite = () => {
    const data = {
      user: id.id,
      court: selectedCourt?._id,
      time: date,
    };
    dispatch(invitePlayer(data));
  };

  if (player?.fetchLoading) {
    return (
      <div className="w-full mt-8 px-4 md:max-w-[428px] md:mx-auto">
        <LoadingComponent />
      </div>
    );
  }
  return (
    <>
      {present === 1 && (
        <div className="w-full flex flex-col px-4 pb-10 relative h-screen md:max-w-[428px] md:mx-auto">
          <div className="flex justify-between mt-6 items-center mb-8">
            <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
            <p className="text-[#303030] font-bold text-[15px] ">
              Match Invite
            </p>
            <div className="more-shadow opacity-0">
              <More />
            </div>
          </div>
          <div className="w-full bg-[#FFA800] h-[250px] rounded-[24px] p-4 flex space-x-6 justify-between">
            <div className="flex flex-col">
              <img
                src={userData?.image}
                alt="profile avatar"
                className="w-[120px] h-[120px] object-cover rounded-[20px]"
              />
              <p className="text-white text-base font-bold mb-2 mt-1 text-center capitalize">
                {`${userData?.firstname}`}
                <br />
                {userData?.lastname}
              </p>
              <div className="h-[29px] bg-white flex items-center justify-center px-4 rounded-3xl text-ash text-xs  ">
                {userData?.rank}
              </div>
            </div>
            <div className="flex flex-col">
              <p className="text-base font-bold text-white mt-8">VS</p>
            </div>
            <div className="flex flex-col">
              <img
                src={playerDetail?.image}
                alt="profile avatar"
                className="w-[120px] h-[120px] object-cover rounded-[20px]"
              />
              <p className="text-white text-base font-bold mb-2 mt-1 text-center capitalize">
                {playerDetail?.firstname}
                <br />
                {playerDetail?.lastname}
              </p>
              <div className="h-[29px] bg-white flex items-center justify-center px-4 rounded-3xl text-ash text-xs  ">
                {playerDetail?.rank}
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-8 space-y-6">
            {/* start of partl */}
            <div className="flex flex-col">
              <p className="text-xs text-ash font-[500] mb-1">Choose Court</p>
              <UserSelect
                setSelected={setSelectedCourt}
                selected={selectedCourt}
                name="Select Court"
                label={""}
                data={allCourts}
                height="h-[50px] bg-[#F4F4F4]"
              />
            </div>

            {/* end of a pack */}
            {/* start of partl */}
            <div className="flex flex-col ">
              <p className="text-xs text-ash font-[500] mb-1">
                Match Date & Time
              </p>

              <UserDateTimeInput
                onChange={(e: any) => setDate(e.target.value)}
                dateTime={date}
              />
            </div>
            {/* end of a pack */}
          </div>

          <div className="w-full absolute   bottom-12 px-4 left-0 right-0 md:mt-16  md:mx-auto md:pb-8">
            <Btn
              active={player?.loading ? false : active}
              onClick={handleInvite}
              name={"Send Invite"}
              loading={player?.loading}
            />
          </div>
        </div>
      )}

      <ReUseModal open={openModal} setOpen={setOpenModal}>
        <div className="w-full flex flex-col">
          <img src={sentMark} alt="sent" className="mx-auto mt-6" />
          <h1 className="text-customBlack text-[18px] font-bold text-center ">
            Match Invitation Sent
          </h1>
          <p className="text-center w-10/12 mx-auto text-neutral-500 text-sm ">
            You’ve successfully sent a match invitation
          </p>
          <Btn
            active={true}
            name="Close"
            onClick={() => {
              setOpenModal(false);
              dispatch(restoreDefault());
              navigate(`/matches`);
            }}
            className="mt-[25px] mb-3"
          />
        </div>
      </ReUseModal>
    </>
  );
};

export default InviteSinglePlayer;
