import icon from "../../../assets/svgs/verification-icon.svg";
const Avatar = ({ verified, image }: any) => {
  return (
    <>
      <span className="inline-block relative">
        <img className="h-[100px] w-[100px] rounded-full" src={image} alt="" />
        {verified && (
          <span className="absolute bottom-0 right-0 block h-6 w-6">
            <img src={icon} alt="icons" className="w-full h-auto" />
          </span>
        )}
      </span>
    </>
  );
};

export default Avatar;
