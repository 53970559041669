import { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import logsuccess from "../../../assets/png/log-success.png";
import teno from "../../../assets/png/teno.png";
import { Message } from "../../../assets/svgs/Icons";
import { Btn } from "../../../components/Button";
import {
  EmailInput,
  NameInput,
  NewPasswordInput,
  NormalInput,
} from "../../../components/Input";
import CustomSelect from "../../../components/Input/CustomSelect";
import ReUseModal from "../../../components/Modal/ReUseModal";
import { BigText, ModalSub, Ptext } from "../../../components/Text";
import UploadPhoto from "../../../components/upload/UploadImage";
import { registerUser } from "../../../features/auth/AuthSlice";
const NewRegister = () => {
  const [searchParams] = useSearchParams();
  const clubIdParams = searchParams.get("clubid");
  const inviteCodeParams = searchParams.get("invitecode");

  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [present, setPresent] = useState(1);
  const [successful, setSuccessful] = useState(false);
  const [active] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [inviteCode, setInviteCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [value, setValue] = useState<any>();
  const [checked, setChecked] = useState(false);
  const [skipInvite, setSkipInvite] = useState<boolean>(false);

  useEffect(() => {
    if (auth.googleAuthData?.email && clubIdParams && inviteCodeParams) {
      setPresent(4);
    } else if (auth.googleAuthData?.email && !clubIdParams) {
      setPresent(3);
    } else if (clubIdParams && inviteCodeParams) {
      setSkipInvite(true);
    } else {
      setPresent(1);
    }
  }, []);

  // birth: convertDate(date),
  const handleSubmit = () => {
    let data = {};
    if (auth.googleAuthData?.uid) {
      const { googleAuthData } = auth;
      data = {
        firstname: googleAuthData?.displayName?.split(" ")[0],
        lastname: googleAuthData?.displayName?.split(" ")[1],
        email: googleAuthData?.email,
        uid: googleAuthData?.uid,
        image: googleAuthData?.photoURL,
        thumbnail: googleAuthData?.photoURL,
        birth: "946684800000",
        club: clubIdParams ? clubIdParams : value?.id,
        invitecode: inviteCodeParams ? inviteCodeParams : inviteCode,
      };
    } else {
      data = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        password: password,
        image: imageUrl
          ? imageUrl
          : "https://cdn-icons-png.flaticon.com/512/1077/1077114.png",
        thumbnail: "https://cdn-icons-png.flaticon.com/512/1077/1077114.png",
        club: clubIdParams ? clubIdParams : value?.id,
        birth: "946684800000",
        invitecode: inviteCodeParams ? inviteCodeParams : inviteCode,
      };
    }
    dispatch(registerUser(data));
  };

  useEffect(() => {
    if (auth.userData?.success) {
      toast.success("Registration Successful");
      setSuccessful(true);
      setTimeout(() => {
        navigate("/home");
      }, 2000);
    }
  }, [auth.registerSuccess]);

  return (
    <div className="flex flex-col">
      {/* <div className=" flex-col lg:flex hidden mt-6 px-4 justify-center h-screen w-1/2 mx-auto">
        <h1 className="font-bold text-customBlack text-xl text-center mb-2 ">
          Oops, this page is only designed for mobile devices.
        </h1>
        <p className="text-neutral-700 text-base  ">
          Please switch to a smaller screen, such as a smartphone or tablet, to
          enjoy this feature. We appreciate your cooperation.
        </p>
      </div> */}
      {successful ? (
        <div className="w-full px-8 flex flex-col ">
          <div className="flex flex-col justify-center items-center h-[75vh] ">
            <div className="w-7/12 mx-auto">
              <img src={logsuccess} alt="" className="w-full h-auto" />
              <BigText
                text="Account Created
Successfully"
                className="text-center"
              />
              <Ptext
                className="text-center"
                text="Kindly proceed to your dashboard"
              />
            </div>
          </div>
          <Btn
            onClick={() => navigate("/home")}
            name="Proceed to Dashboard"
            active={true}
            className="mt-4"
          />
        </div>
      ) : (
        <div className="flex flex-col md:max-w-[428px] md:mx-auto md:h-screen">
          {present === 1 && (
            <div className="flex relative h-full bg-white">
              <div className="fixed  right-0 top-0 z-0">
                <img src={teno} alt="tenis" className="w-fit h-auto" />
              </div>
              <div className="w-full flex flex-col md:hidden p-4 relative h-screen">
                <AiOutlineArrowLeft
                  onClick={() => navigate(-1)}
                  className="mt-12 text-2xl"
                />
                <div className="flex flex-col">
                  <BigText text="Create Account" className="mt-8" />
                  <Ptext
                    text="Create an account so you can enjoy outstanding features this app offers. "
                    className="mb-[37px] mt-2"
                  />
                  <div className="flex flex-col space-y-3">
                    <NameInput
                      onChange={(e: any) => setFirstName(e.target.value)}
                      required
                      placeholder="First Name"
                    />
                    <NameInput
                      onChange={(e: any) => setLastName(e.target.value)}
                      required
                      placeholder="Last Name"
                    />

                    <EmailInput
                      onChange={(e: any) => setEmail(e.target.value)}
                      required
                      placeholder="Enter your Email"
                    />

                    <NewPasswordInput
                      onChange={(e: any) => setPassword(e.target.value)}
                      required
                      placeholder="Password"
                    />
                    <NewPasswordInput
                      onChange={(e: any) => setPassword(e.target.value)}
                      required
                      placeholder=" confirm Password"
                    />
                  </div>
                  <div className="mt-4 p-4">
                    <Btn
                      name="Next"
                      active={active}
                      onClick={() => {
                        if (skipInvite) {
                          setPresent(4);
                        } else setPresent(2);
                      }}
                    />
                  </div>
                  <div className="flex items-center justify-center mt-4 z-5">
                    <Ptext text="Already have an account " />
                    <Link
                      to="/users"
                      className="text-[14px] text-primaryYellow ml-1"
                    >
                      sign in
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
          {present === 2 && (
            <div className="flex relative h-full bg-white">
              <div className="fixed  right-0 top-0 z-0">
                <img src={teno} alt="tenis" className="w-fit h-auto" />
              </div>
              <div className="w-full flex flex-col md:hidden p-4 relative h-screen">
                <AiOutlineArrowLeft
                  onClick={() => setPresent(1)}
                  className="mt-20 text-2xl"
                />
                <div className="flex flex-col">
                  <BigText text="Upload Photo " className="mb-2" />
                  <Ptext
                    text="Please upload your profile photo, and we are
                ready to go."
                    className="mb-40"
                  />
                  {/* <div className="flex   relative  ">
                <div className="w-full  absolute flex justify-center z-10">
                <input
                type="file"
                className="opacity-0 inset-0 z-20 absolute"
                />

                <UploadImage />
                </div>
              </div> */}
                  <UploadPhoto getFileUrl={setImageUrl} />
                  <div className="mt-[260px] flex justify-center item-center ">
                    <div className="w-[9.6px] h-[7px] bg-[#f0f0f0]  rounded-[50px]"></div>
                    <div className="w-[9.6px] h-[7px] bg-[#f0f0f0] mx-1  rounded-[50px]"></div>
                    <div className="w-[27.87px] h-[7px] bg-primaryYellow rounded-[50px] "></div>
                  </div>
                  <div className="fixed  bottom-10 left-0 right-0 p-4">
                    <Btn
                      name="Next"
                      active={active}
                      onClick={() => setPresent(3)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {present === 3 && (
            <div className="flex relative h-full bg-white">
              <div className="fixed  right-0 top-0 z-0">
                <img src={teno} alt="tenis" className="w-fit h-auto" />
              </div>
              <div className="w-full flex flex-col md:hidden p-4 relative h-screen">
                <AiOutlineArrowLeft
                  onClick={() => setPresent(1)}
                  className="mt-20 text-2xl"
                />
                <div className="flex flex-col">
                  <BigText text="Create Account" className="mt-8" />
                  <Ptext
                    text="Create an account so you can enjoy outstanding features this app offers. "
                    className="mb-[37px] mt-2"
                  />
                  <div className="flex flex-col space-y-3">
                    <CustomSelect
                      setSelected={setValue}
                      selected={value}
                      name="Select Club"
                    />

                    <NormalInput
                      onChange={(e: any) => setInviteCode(e.target.value)}
                      required
                      placeholder="Enter invitation Code"
                    />
                  </div>

                  <div className="mt-20 p-4">
                    <Btn
                      name="Next"
                      active={active}
                      onClick={() => setPresent(4)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {present === 4 && (
            <div className="flex relative h-full bg-white">
              <div className="fixed  right-0 top-0 z-0">
                <img src={teno} alt="tenis" className="w-fit h-auto" />
              </div>
              <div className="w-full flex flex-col md:hidden p-4 relative h-screen">
                <AiOutlineArrowLeft
                  onClick={() => setPresent(3)}
                  className="mt-20 text-2xl"
                />
                <div className="flex flex-col">
                  <BigText
                    text="Terms and Condition"
                    className="mt-8 text-center"
                  />
                  <Ptext
                    text="I agree to all the terms and condition of the clubI agree to all the terms and condition of the clubI agree to all the terms and condition of the club
I agree to all the terms and condition of the clubI agree to all the terms and condition of the club
I agree to all the terms and condition of the clubI agree to all the terms and condition of the club
I agree to all the terms and condition of the clubI agree to all the terms and condition of the club
I agree to all the terms and condition of the clubI agree to all the terms and condition of the club
I agree to all the terms and condition of the clubI agree to all the terms and condition of the club
I agree to all the terms and condition of the clubI agree to all the terms and condition of the club
I agree to all the terms and condition of the clubI agree to all the terms and condition of the club
I agree to all the terms and condition of the clubI agree to all the terms and condition of the club
I agree to all the terms and condition of the clubI agree to all the terms and condition of the club "
                    className="mb-[37px] mt-2"
                  />
                  <div className=" flex space-x-3 items-center">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                      className="accent-yellow text-yellow bg-primaryYellow w-5 h-5"
                    />
                    <p className="text-neutral-700 text-sm font-normal">
                      I agree to all the terms and condition of the club.
                    </p>
                  </div>
                  <div className="mt-[49px] mb-12 p-4">
                    <Btn
                      name="Create Account"
                      active={checked}
                      loading={auth.loading}
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <ReUseModal open={openModal} setOpen={setOpenModal}>
            <div className="w-full flex flex-col justify-center items-center py-8">
              <Message />
              <p className="text-customBlack font-bold text-lg text-center">
                Invitation Request Sent
              </p>
              <ModalSub
                className="text-center mt-1"
                text="You’ll be notified once your request has been approved"
              />
              <div className="w-6/12 mx-auto">
                <Btn
                  active={true}
                  onClick={() => setOpenModal(false)}
                  name="Continue"
                  className="mt-3 "
                />
              </div>
            </div>
          </ReUseModal>
        </div>
      )}
    </div>
  );
};

export default NewRegister;
