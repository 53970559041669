import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { More } from "../../../../assets/svgs/court";
import { Btn, RedOutlinedBtn } from "../../../../components/Button";
import {
  joinTournament,
  leaveTournament,
} from "../../../../features/auth/AuthSlice";
import sentMark from "../../../../assets/svgs/checkmark.svg";
import {
  joinLadder,
  leaveLadder,
  getSingleLadderDetailUser,
  resetAllLadderState,
  getAllLadderUser,
} from "../../../../features/auth/ladder-slice";
import ReUseModal from "../../../../components/Modal/ReUseModal";
import { formatDate } from "../../../../utils";
import { DownwardArrow } from "../../../../assets/svgs/courts";
const ViewLadderInvitation = () => {
  const navigate = useNavigate();
  const ladder = useAppSelector((state) => state.ladder);
  const [open, setOpen] = useState(false);
  const id = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getSingleLadderDetailUser({ id: id.id }));
  }, []);

  const handleAccept = () => {
    const data = {
      id: id.id,
    };
    dispatch(joinLadder(data));
  };

  const handleReject = () => {
    const data = {
      id: id.id,
    };
    dispatch(leaveLadder(data));
  };

  useEffect(() => {
    if (ladder.joinLadderSuccess) {
      // toast.success("You have Joined Successfully");
      setOpen(true);
      dispatch(getAllLadderUser());
      setTimeout(() => {
        dispatch(resetAllLadderState());
        navigate("/clubs");
      }, 2000);
    }
  }, [ladder.joinLadderSuccess]);

  useEffect(() => {
    if (ladder.leaveLadderSuccess) {
      toast.success("Tournament Rejected Successfully");
      setTimeout(() => {
        dispatch(resetAllLadderState());
        navigate("/clubs");
      }, 2000);
    }
  }, [ladder.leaveLadderSuccess]);
  const details = ladder?.singleLadderDetail;
  // const {  start, stop, name, challenge_power, deadline, challenge_timeout, points } = details;

  return (
    <>
      <div className="w-full flex flex-col px-4 py-6 h-screen relative md:max-w-[428px] md:mx-auto md:h-screen">
        <div className="flex justify-between mt-6 items-center">
          <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
          <p className="text-[#303030] font-bold text-[15px] ">
            Ladder Tournament Invitation
          </p>
          <div className="more-shadow opacity-0">
            <More />
          </div>
        </div>
        <div className="mt-8 flex flex-col ">
          <p className="text-sm text-neutral-700">
            The
            <span className="text-customBlack font-bold mx-2">{`${details?._type}`}</span>
            ladder tournament starts on{" "}
            <span className="customBlack font-bold">
              {" "}
              {formatDate(details?.start)}
            </span>{" "}
            and ends on{" "}
            <span className="customBlack font-bold">
              {" "}
              {formatDate(details?.stop)}
            </span>{" "}
            . Registration deadline{" "}
            <span className="customBlack font-bold">
              {" "}
              {formatDate(details?.deadline)}
            </span>
            .
          </p>
          <p className="mt-6 text-neutral-700 text-sm">Ladder Rules</p>
          <ul className="list-disc pl-5">
            <li>
              <p className="text-sm text-neutral-700">
                The number of players you can challenge above you is{" "}
                <span className="text-cutomBlack text-sm font-bold ml-1">
                  {details?.challenge_power}
                </span>
                .
              </p>
            </li>
            <li>
              <p className="text-sm text-neutral-700">
                Expiration period for a challenge invitation is
                <span className=" ml-1 text-cutomBlack text-sm font-bold">
                  {details?.challenge_timeout} hours
                </span>
                .
              </p>
            </li>
            <li className="mt-2">
              <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-600">
                All financial member of the club are eligible to participate.
              </p>
            </li>
            <li className="mt-2">
              <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-700">
                Players cannot challenge the same players challenged in the
                previous week. This can only happen if any other person does not
                challenge the player and will also attract a fee of
                <span className="text-customBlack font-bold ml-1">
                  N10,000.
                </span>
              </p>
            </li>
            <li className="mt-2">
              <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-700">
                Failure to honour challenges four consecutive times can result
                in removing a person’s name from the ladder board.
              </p>
            </li>
            <li className="mt-2">
              <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-700">
                The tennis captain or the assistant captain will assign the
                court and umpire for each game
              </p>
            </li>
            <li className="mt-2">
              <p className="text-sm leading-[24px] tracking-[0.14px] text-neutral-700">
                Players have a right to direct complaints to the tennis captain
                of the tournament director if they have any.
              </p>
            </li>
          </ul>
        </div>
        {/* button */}
        <div className="flex flex-col mt-8 space-y-5 pb-5 w-full px-4">
          <Btn
            name="Accept"
            onClick={handleAccept}
            active
            loading={ladder.loading}
          />
          {/* <RedOutlinedBtn
            name="Reject"
            onClick={handleReject}
            loading={ladder.loading}
            active
          /> */}
        </div>
      </div>
      <ReUseModal open={open} setOpen={setOpen}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-cutomBlack text-[24px] font-bold text-center">
          Joined Successfully
        </h1>
        <p className="text-center w-10/12 mx-auto text-neutral-500 ">
          Congratulations you have successfully joined the tournament
        </p>
        <Btn
          active={true}
          name="Continue"
          onClick={() => {
            setOpen(false);

            navigate("/clubs");
          }}
          className="mt-[25px] mb-3"
        />
      </ReUseModal>
    </>
  );
};

export default ViewLadderInvitation;
