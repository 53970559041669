import axios from "axios";
import { baseUrl } from "./endpoints";

export const APIService = axios.create({
  baseURL: baseUrl,
});

export const nAPIService = (
  method: any,
  address: any,
  header: any,
  data?: any
) => {
  return axios({
    method: method,
    url: `${baseUrl}/${address}`,
    headers: header,
    data: data,
  });
};

// export default { APIService, nAPIService };
