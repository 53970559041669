import { useState } from "react";
import DashboardLayout from "../../../Layouts/DashboardLayout";
import {
  AllClubMembers,
  ClubOverview,
  PendingMembers,
  RejectedMembers,
} from "../../../components/Club";
import RankMemberTable from "../../../components/Club/RankMemberTable";
const styles = {
  inactive:
    "text-neutral-400 font-normal text-base tracking-[0.5px] cursor-pointer",
  active:
    "text-lemonGreen cursor-pointer font-normal text-base tracking-[0.5px] border-b-[5px]  border-b-lemonGreen",
};
const Club = () => {
  const [present, setPresent] = useState(0);
  let yam =
    "text-lemonGreen font-semibold underline underline-offset-8 cursor-pointer";

  return (
    <DashboardLayout current={5}>
      <div className="w-full flex flex-col bg-[#f4f4f4] h-full p-[30px] 2xl:p-8 2xl:pr-[50px]">
        <h1 className="text-customBlack text-lg font-semibold mt-6 ">Club</h1>
        <div className="flex w-full border-b-neutral-300 border-b mt-5   space-x-[50px] ">
          <p
            className={present === 0 ? styles.active : styles.inactive}
            onClick={() => setPresent(0)}
          >
            Club Details
          </p>

          <p
            className={present === 4 ? styles.active : styles.inactive}
            onClick={() => setPresent(4)}
          >
            Rank Members
          </p>

          <p
            className={present === 1 ? styles.active : styles.inactive}
            onClick={() => setPresent(1)}
          >
            All Club Members
          </p>
          <p
            className={present === 2 ? styles.active : styles.inactive}
            onClick={() => setPresent(2)}
          >
            Pending Members
          </p>
          <p
            className={present === 3 ? styles.active : styles.inactive}
            onClick={() => setPresent(3)}
          >
            Rejected Members
          </p>
        </div>
        <div className="mt-0">
          {present === 0 && <ClubOverview />}
          {present === 1 && <AllClubMembers />}
          {present === 2 && <PendingMembers />}
          {present === 3 && <RejectedMembers />}
          {present === 4 && <RankMemberTable />}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Club;
