import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import axios from "axios";
import { AiOutlineArrowLeft } from "react-icons/ai";
import ReUseModal from "../../components/Modal/ReUseModal";
import { Btn } from "../../components/Button";
import sentMark from "../../assets/svgs/checkmark.svg";
import { useNavigate } from "react-router-dom";
import {
  evaluatePlayer,
  resetEvaluationSuccess,
} from "../../features/auth/AuthSlice";

export const quizData = [
  {
    id: 1,
    name: "skills",
    question:
      "On a scale of beginner to advanced, where do you believe your tennis skills currently stand?",
    options: ["Beginner", "Intermediate", "Advanced"],
    selected: 1,
  },
  {
    id: 2,
    name: "year",
    question: "How many years have you been playing tennis?",
    options: [1, 2, 3, 4],
    selected: 3,
  },
  {
    id: 3,
    name: "execution",
    question:
      "How well do you execute basic strokes such as forehand, backhand, volley, and serve?",
    options: ["Fair", "Good", "Very Good", "Extremely Good"],
    selected: 2,
  },
  {
    id: 4,
    name: "consistency",
    question:
      "Are you able to consistently hit the ball over the net and keep it in playing during rallies?",
    options: ["Yes", "No"],
    selected: 1,
  },
  {
    id: 5,
    name: "control",
    question:
      "How effectively do you move and cover the court, both offensively and defensively?",
    options: ["Fair", "Good", "Very Good", "Extremely Good"],
    selected: 1,
  },
  {
    id: 6,
    selected: 3,
    name: "effectively",
    question: "How much force and control do you generate in your shots?",
    options: [
      "Not effectively",
      "Effectively",
      "Very effectively",
      "Extremely effectively",
    ],
  },
  {
    id: 7,
    selected: 1,
    name: "shots",
    question:
      "Do you make appropriate shot selections based on the situation, opponent, and court position?",
    options: ["Yes", "No"],
  },
  {
    id: 8,
    name: "performance",
    selected: 1,
    question:
      "How well do you perform in a match play against opponents of a similar skill level?",
    options: ["Fair", "Good", "Very Good", "Extremely Good"],
  },
  {
    id: 9,
    selected: 1,
    name: "familiarity",
    question:
      "How Familiar are you with the rule, strategies, and tactics of the game?",
    options: ["Fair", "Good", "Very Good", "Extremely Good"],
  },
  {
    id: 10,
    selected: 0,
    name: "participation",
    question: "Have you participated in any tennis tournaments?",
    options: ["Yes", "No"],
  },
];

const questionsPerPage = 3;

const TennisRank = () => {
  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  // State to control the modal
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    if (auth?.evalutionSuccess) {
      setOpenModal(true);
      dispatch(resetEvaluationSuccess());
      // your modal opening code here
      setTimeout(() => {
        navigate("/home");
      }, 3000);
    }
  }, [auth.evalutionSuccess]);

  const [currentPage, setCurrentPage] = useState(0);
  const totalQuestions = quizData.length;
  const remainingQuestions = totalQuestions - currentPage * questionsPerPage;
  const questionsToDisplay =
    remainingQuestions > questionsPerPage
      ? questionsPerPage
      : remainingQuestions;

  // const [selectedOptions, setSelectedOptions] = useState<number[]>(
  //   new Array(totalQuestions).fill(-1)
  // );

  const [selectedOptions, setSelectedOptions] = useState(
    new Array(10).fill(-1)
  );

  let newAnswers = quizData.map((item) => item.options);

  const handleSubmit = () => {
    const data = {
      skills: newAnswers[0][Number(selectedOptions[0])],
      year: newAnswers[1][Number(selectedOptions[1])],
      execution: newAnswers[2][Number(selectedOptions[2])],
      consistency: newAnswers[3][Number(selectedOptions[3])],
      control: newAnswers[4][Number(selectedOptions[4])],
      effectively: newAnswers[5][Number(selectedOptions[5])],
      shots: newAnswers[6][Number(selectedOptions[6])],
      performance: newAnswers[7][Number(selectedOptions[7])],
      familiarity: newAnswers[8][Number(selectedOptions[8])],
      participation: newAnswers[9][Number(selectedOptions[9])],
    };
    dispatch(evaluatePlayer(data));
  };

  const isLastPage =
    currentPage === Math.floor(totalQuestions / questionsPerPage);

  const handleOptionSelect = (questionIndex: number, optionIndex: number) => {
    const updatedSelectedOptions = [...selectedOptions];
    updatedSelectedOptions[questionIndex] = optionIndex;
    setSelectedOptions(updatedSelectedOptions);
  };

  const handleNextPage = () => {
    if (
      selectedOptions
        .slice(
          currentPage * questionsPerPage,
          (currentPage + 1) * questionsPerPage
        )
        .includes(-1)
    ) {
      return;
    }

    if (!isLastPage) {
      setCurrentPage(currentPage + 1);
    } else {
      // Open the modal when on the last page and click "Finish"
      // setOpenModal(true);
      handleSubmit();
    }
  };

  const handlePreviousPage = () => {
    if (currentPage === 0) {
      navigate("/home");
    } else if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const buttonDisabled = selectedOptions
    .slice(currentPage * questionsPerPage, (currentPage + 1) * questionsPerPage)
    .includes(-1);

  const renderOptions = (questionIndex: number) => {
    return quizData[questionIndex].options.map((option, optionIndex) => (
      <label
        key={optionIndex}
        className="py-3 border-b last:border-none border-neutral300 mx-auto w-[90%]"
      >
        <div className="flex items-center">
          <input
            type="radio"
            name={`question-${questionIndex}`}
            checked={selectedOptions[questionIndex] === optionIndex}
            onChange={() => handleOptionSelect(questionIndex, optionIndex)}
          />
          <span className="ps-3 text-sm font-medium text-[#0e0e0e]">
            {option}
          </span>
        </div>
      </label>
    ));
  };

  const renderQuestions = () => {
    const pageStart = currentPage * questionsPerPage;
    const pageEnd = pageStart + questionsToDisplay;

    return Array.from({ length: questionsToDisplay }, (_, index) => (
      <ol key={pageStart + index} className="rank-list">
        <li className="text-neutral-700 mt-4 text-sm">
          {`${pageStart + index + 1}. ${quizData[pageStart + index].question}`}
        </li>
        <form className="border border-neutral300 accent-black  my-5 flex flex-col rounded-lg">
          {renderOptions(pageStart + index)}
        </form>
      </ol>
    ));
  };

  return (
    <main className="px-6 my-12 w-full md:max-w-[428px] md:mx-auto">
      <div className="flex items-center justify-between">
        <div>
          <AiOutlineArrowLeft
            className="text-[#1d1e25]"
            onClick={handlePreviousPage}
          />
        </div>
        <div>
          <h1 className="font-bold text-base">Rank Your Tennis</h1>
        </div>
        <div></div>
      </div>
      <p className="text-neutral-500 mt-4 text-sm">
        In order to join a tournament you need to meet the requirement and self
        rate yourself
      </p>
      {renderQuestions()}
      <button
        onClick={handleNextPage}
        className={`${
          buttonDisabled ? "bg-lemonGreen opacity-40" : "bg-lemonGreen"
        } text-white py-2 px-4 rounded-md focus:outline-none w-full`}
        disabled={buttonDisabled}
      >
        {isLastPage && !auth.loading
          ? "Finish"
          : "Continue" && isLastPage && auth.loading
          ? "Loading..."
          : "Continue"}
      </button>
      <ReUseModal open={openModal} setOpen={setOpenModal}>
        <img src={sentMark} alt="sent" className="mx-auto my-6" />
        <h3 className="text-center text-black font-bold text-lg">
          Self Rating Complete
        </h3>
        <p className="text-center text-neutral-500 mb-4 text-sm font-normal">
          You’ve successfully completed your self rating. You’ll be notified
          once it is complete
        </p>
        <Btn
          active={true}
          name="Continue"
          onClick={() => navigate("/home")}
          className="mb-8 w-[60%] mx-auto text-base"
        />
      </ReUseModal>
    </main>
  );
};

export default TennisRank;
