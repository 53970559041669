import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import sentMark from "../../../assets/svgs/sent-mark.svg";
import {
  startNextRound,
  startTournament,
} from "../../../features/auth/TournamentSlice";
import {
  closestPowerOf2,
  convertDateFormatInput,
  getRandomIndex,
  getRandomNumber,
} from "../../../utils";
import { isPowOf2 } from "../../../utils/nigeriaStates";
import { Btn } from "../../Button";
import ReUseModal from "../../Modal/ReUseModal";
import PrimarySelect from "../../Selects/PrimarySelect";
import SearchSelect from "../../Selects/SearchSelect";
const AutoSchedule = ({ autoSchedule, setAutoSchedule }: any) => {
  const navigate = useNavigate();
  const tournament = useAppSelector((state) => state.tournament);
  const dispatch = useAppDispatch();
  const [openRules, setOpenRules] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  let [playersList, setPlayerList] = useState<any>([]);
  let [playersTestList, setPlayerTestList] = useState<any>([]);
  const [allMatching, setAllMatching] = useState<any[]>([]);
  const id = useParams();
  const [searchParams] = useSearchParams();
  const currentRound = searchParams.get("round");
  let realRound = Number(currentRound);

  let playersArray = realRound
    ? tournament.allActivePlayers
    : tournament.allAcceptedPlayers;

  let roundName =
    playersArray?.length === 8
      ? "Quater Final"
      : playersArray?.length === 4
      ? "Semi Final"
      : playersArray?.length === 2
      ? "FINAL"
      : "Round " + currentRound;

  const startMatch = () => {
    const dataPassed = {
      id: id.number,
      data: {
        match: allMatching,
      },
    };

    dispatch(startTournament(dataPassed));
  };

  useEffect(() => {
    let idealPlayers = closestPowerOf2(playersArray?.length);

    if (playersArray?.length !== idealPlayers) {
      let nPlayersList = [
        ...playersArray,
        {
          _id: "BYE",
          firstname: "BYE",
          image: "https://cdn-icons-png.flaticon.com/512/1077/1077114.png",
          lastname: "",
        },
      ];
      setPlayerList(nPlayersList);
    } else {
      setPlayerList(playersArray);
    }
  }, [playersArray]);

  useEffect(() => {
    let idealPlayers = closestPowerOf2(playersArray?.length);

    if (playersArray?.length !== idealPlayers) {
      let nPlayersList = [
        ...playersArray,
        {
          _id: "BYE",
          firstname: "BYE",
          image: "https://cdn-icons-png.flaticon.com/512/1077/1077114.png",
          lastname: "",
        },
      ];
      setPlayerTestList(nPlayersList);
    } else {
      setPlayerTestList(playersArray);
    }
  }, []);

  const handleNextRound = () => {
    const dataPassed = {
      id: id.number,
      data: {
        match: allMatching,
      },
    };

    dispatch(startNextRound(dataPassed));
  };
  useEffect(() => {
    if (tournament.startTournamentSuccess) {
      setOpenModal(true);
      setTimeout(() => {
        navigate("/admin/tournament");
      }, 1000);
    } else {
      setOpenModal(false);
    }
  }, [tournament.startTournamentSuccess]);
  useEffect(() => {
    if (tournament.startNextRoundSuccess) {
      setOpenModal(true);
      setTimeout(() => {
        navigate("/admin/tournament");
      }, 1000);
    } else {
      setOpenModal(false);
    }
  }, [tournament.startNextRoundSuccess]);

  const [arrays, setArrays] = useState<any>([]);

  let idealPlayers = closestPowerOf2(playersArray?.length);

  useEffect(() => {
    let actual = idealPlayers / 2;
    let arrays = [];
    for (let i = 0; i < actual; i++) {
      arrays.push([]);
    }
    setArrays(arrays);
  }, [playersArray]);

  useEffect(() => {
    for (let i in arrays) {
      for (let j in arrays[i]) {
        const data = playersList.filter(
          (item: any) => item._id !== arrays[i][j]
        );

        setPlayerList(data);
      }
    }
  }, [arrays]);

  // START OF AUTOMAGIC
  const [sortedMatch, setSortedMatch] = useState<any>([]);
  const Arr = playersArray.map((item: any) => {
    return { ...item, rank: getRandomNumber() };
  });

  const MakeToPow2 = (arrInput: any) => {
    let bool = isPowOf2(arrInput.length);

    if (!bool) {
      let nArray = [...arrInput];
      let numbersByetoAdd = closestPowerOf2(arrInput.length) - arrInput.length;
      for (let i = 0; i < numbersByetoAdd; i++) {
        nArray.push({
          _id: "BYE",
          firstname: "BYE",
          image: "https://cdn-icons-png.flaticon.com/512/1077/1077114.png",
          lastname: "",
          rank: 0,
        });
      }

      //   MakeToPow2(arrInput);
      return nArray;
    } else {
      return arrInput;
    }
  }; // O(1)

  let allCourtsArray = tournament?.allCourts;
  useEffect(() => {
    let newDate = new Date();
    const adjustedArray = MakeToPow2(Arr);
    let allCourt = tournament?.allCourts;

    let Rev_Match: any = [];

    let midPoint = idealPlayers / 2;
    const forRev = midPoint * 2 - 1;
    for (let i = 0; i < midPoint; i++) {
      let time = 30 * 60 * 1000 * (i + 1);
      let realIndex = getRandomIndex(allCourt?.length);

      const rev_data = {
        player1: adjustedArray[i],
        player2: adjustedArray[forRev - i],
        date: new Date(newDate.getTime() + time),
        courtIndex: realIndex,
      };

      // Match.push(data);

      Rev_Match.push(rev_data);
    }
    setSortedMatch(Rev_Match);
  }, []);

  // END OF A AUTOMAGIC
  useEffect(() => {
    let newScheduled = sortedMatch?.map((item: any, index: number) => {
      let data = {
        challenger: item?.player1?._id,
        challenged: item?.player2?._id,
        court: allCourtsArray[item.courtIndex]?._id,
        time: convertDateFormatInput(item?.date),
        id: index,
      };
      return data;
    });
    setAllMatching(newScheduled);
  }, [sortedMatch]);

  return (
    <div className="bg-white rounded-[20px] flex flex-col h-full mt-6 flow-hide  ">
      <div className="flex justify-between items-center h-[80px] ">
        <div className="flex flex-col">
          <h1 className="pl-5 text-customBlack font-semibold text-sm  ">
            Create Tournament
          </h1>
          <h1 className="pl-5 text-customBlack font-semibold text-sm mt-[12px] ">
            Type: Auto Schedule
          </h1>
        </div>
        <div className="w-3/12 pr-5 ">
          {autoSchedule ? (
            <Btn
              onClick={() => setAutoSchedule(false)}
              name="Manual Schedule"
              active={true}
            />
          ) : (
            <Btn
              onClick={() => setAutoSchedule(true)}
              name="Auto Schedule"
              active={true}
            />
          )}
        </div>
      </div>
      {/* breaker line */}
      <div className="w-full h-[1px] bg-[#cfcfcf] " />
      <div className="w-full flex flex-col pl-5">
        <p className="mt-3 text-neutral-600 ">
          Select the number of players and fill in the name of the players with
          the number of court
        </p>

        {/* form area */}
        <div className="w-full flex flex-col">
          <p className="text-base text-neutral-600 mb-3 font-bold mt-4">
            {currentRound ? ` ${roundName}` : "Round 1"}
          </p>
          {sortedMatch?.map((item: any, index: number) => {
            return (
              <SingleItem
                item={item}
                playersList={playersTestList}
                key={index}
                allMatching={allMatching}
                setAllMatching={setAllMatching}
                index={index}
                // setSingleItemData={(id: string) => handleChange(id)}
              />
            );
          })}
        </div>
        {/* end of form area */}
        <div className="w-4/12 mt-12 mx-auto mb-12">
          {realRound > 1 ? (
            <Btn
              active={true}
              name="Send"
              onClick={handleNextRound}
              loading={tournament.loading}
            />
          ) : (
            <Btn
              active={true}
              name="Send"
              onClick={startMatch}
              loading={tournament.loading}
            />
          )}
          {/* <Btn
            active={true}
            name="Send"
            onClick={startMatch}
            loading={tournament.loading}
          /> */}
        </div>
        <ReUseModal open={openRules} setOpen={setOpenRules}>
          <h1 className="text-customBlack font-bold text-center">
            Regulations and Requirement
          </h1>
          <p className="text-center w-full mx-auto text-neutral-500 ">
            A knockout tournament has been created and invitation has been sent
            to all the club members.
          </p>
          <p className="text-neutral-500 mt-8 ">Regulations</p>
          <textarea
            className="border-border border-[2px] flex items-baseline  h-[142px] w-full placeholder:text-placeholder p-2 rounded-[5px]"
            placeholder="Write a message.."
          />
          <p className="text-neutral-500 mt-6 ">Requirements</p>
          <textarea
            className="border-border border-[2px] h-[142px] w-full placeholder:text-placeholder p-2 rounded-[5px]"
            placeholder="Write a message.."
          />
          <Btn
            name="Send to all Players"
            active={true}
            onClick={() => setOpenModal(true)}
            className="mt-[72px]"
          />
        </ReUseModal>
        <ReUseModal open={openModal} setOpen={setOpenModal}>
          <img src={sentMark} alt="sent" className="mx-auto mt-6" />
          <h1 className="text-cutomBlack text-[24px] font-bold text-center">
            Tournament Created
          </h1>
          <p className="text-center w-10/12 mx-auto text-neutral-500 ">
            A knockout tournament has been created. All the players will receive
            the notification for the matches
          </p>
          <Btn
            active={true}
            name="Continue"
            onClick={() => {
              setOpenRules(false);
              setOpenModal(false);
              navigate(`/admin/tournament`);
            }}
            className="mt-[25px] mb-3"
          />
        </ReUseModal>
      </div>
    </div>
  );
};

export default AutoSchedule;

const SingleItem = ({
  allMatching,
  setAllMatching,
  index,
  playersList,

  item,
}: {
  allMatching: any;
  index: number;
  playersList: any[];

  item: any;
  setAllMatching: React.Dispatch<React.SetStateAction<any[]>>;
}) => {
  const tournament = useAppSelector((state) => state.tournament);
  let allCourt = tournament?.allCourts;

  const [selectedCourt, setSelectedCourt] = useState<any>(
    allCourt[item.courtIndex]
  );
  const [selectedPerson, setSelectedPerson] = useState<any>(item?.player1);
  const [selectedPersonB, setSelectedPersonB] = useState<any>(item?.player2);
  const [date, setDate] = useState(convertDateFormatInput(item?.date));

  let data = {
    challenger: selectedPerson?._id,
    challenged: selectedPersonB?._id,
    court: selectedCourt?._id,
    time: date,
    id: index,
  };

  const saveNow = () => {
    if (selectedCourt && date && selectedPerson && selectedPersonB) {
      let findResult = allMatching.find((_: any, index: any) => {
        return index === data.id;
      });
      if (findResult) {
        let editData = allMatching.map((item: any, index: number) => {
          if (index === data.id) {
            return {
              challenger: data.challenger,
              challenged: data.challenged,
              court: data.court,
              time: data.time,
              id: data.id,
            };
          } else return item;
        });
        setAllMatching(editData);
      }
    } else {
      toast.error("Please input all fields");
    }
  };

  //     useEffect(() => {
  //       console.log({ selectedCourt, date, selectedPerson, selectedPersonB });
  //     if (selectedCourt && date && selectedPerson && selectedPersonB) {

  //       saveNow();
  //     }
  //   }, [selectedCourt, date, selectedPerson, selectedPersonB]);
  useEffect(() => {
    if (selectedCourt && date && selectedPerson && selectedPersonB) {
      saveNow();
    }
  }, [selectedCourt, date, selectedPerson, selectedPersonB]);

  return (
    <div className="flex flex-col pb-6  border-dashed pt-6 border-border border-b-[2px]">
      {/* upper sessiom */}
      <div className="flex space-x-6 items-center">
        {/* side a */}
        <div className="flex items-center space-x-3">
          <p className="text-neutral-600">
            {" "}
            Player <span className="font-bold text-customBlack">A</span>:
          </p>
          {/* <p className="outline-none h-[30px] rounded-[5px] border border-border w-[230px] px-2 ">
            {playerA ? playerA : "Ayeni Daniel"}
          </p> */}
          <SearchSelect
            options={playersList}
            setSelectedPerson={(e: any) => {
              setSelectedPerson(e);
              //   setSingleItemData(e._id);
            }}
            selectedPerson={selectedPerson}
          />
        </div>
        {/* sideb */}
        <p className="text-xs text-customBlack">VS</p>
        <div className="flex items-center space-x-3">
          <p className="text-neutral-600">
            {" "}
            Player <span className="font-bold text-customBlack">B</span>:
          </p>
          <SearchSelect
            options={playersList}
            setSelectedPerson={(opt: any) => {
              setSelectedPersonB(opt);
              //   setSingleItemData(opt._id);
            }}
            selectedPerson={selectedPersonB}
          />
        </div>
      </div>
      {/* lower session ###################3 */}
      <div className="flex space-x-6 items-center mt-4">
        {/* start of a sec */}
        <div className="flex items-center space-x-3">
          <p className="text-neutral-600">Match Date:</p>
          <input
            type="datetime-local"
            onChange={(e: any) => setDate(e.target.value)}
            className="outline-none h-[30px] rounded-[5px] border border-border w-[230px] px-2 "
            value={date}
          />
        </div>

        {/* enf of a select */}

        {/* start of a sec */}
        <div className="flex items-center space-x-3">
          <p className="text-neutral-600">Court:</p>
          <div className="w-[230px]">
            <PrimarySelect
              selected={selectedCourt}
              setSelected={setSelectedCourt}
              name="Select"
              data={allCourt}
              height="h-[30px] flex items-center bg-[#ffffff]"
              label=""
            />
          </div>
        </div>

        {/* enf of a select */}
        {/* <p className="text-customBlack bg-lemonGreen px-4 rounded-lg " onClick={saveNow}>
          save now
        </p> */}
      </div>
    </div>
  );
};
