import { IoIosArrowBack } from "react-icons/io";
import { More } from "../../assets/svgs/court";
import { Btn } from "../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getSingleInvitationUser } from "../../features/auth/ladder-slice";
import { useEffect, useState } from "react";

import LoadingComponent from "../../components/Loading/Loader";
import { convertDateTime } from "../../utils";
const AcceptedInvite = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ladder = useAppSelector((state) => state.ladder);
  const auth = useAppSelector((state) => state.auth);
  const [currentData, setCurrentData] = useState<any>({});
  const [present, setPresent] = useState<number>(1);

  const id = useParams();
  useEffect(() => {
    dispatch(getSingleInvitationUser({ id: id.id }));
  }, []);
  useEffect(() => {
    if (ladder?.singleInvitation.length > 0) {
      const filterItem = ladder?.singleInvitation?.filter(
        (item: any) => item?._id === id?.id
      );
      setCurrentData(filterItem[0]);
    }
  }, [ladder?.singleInvitation]);
  const allCourts = ladder?.allCourts;

  const matchCourt = allCourts?.filter(
    (item: any) => item?._id === currentData?.court
  );

  const realDayTime = convertDateTime(currentData?.time);
  if (ladder?.fetchLoading) {
    return (
      <div className="w-full px-4 mt-8 md:max-w-[428px] md:mx-auto md:h-screen">
        <LoadingComponent />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col px-4 pb-10 relative h-screen md:max-w-[428px] md:mx-auto md:h-screen">
      <div className="flex justify-between mt-4 items-center mb-4">
        <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
        <p className="text-[#303030] font-bold text-[15px] ">
          Challenge Invitation
        </p>
        <div className="more-shadow opacity-0">
          <More />
        </div>
      </div>
      <div className="w-full bg-[#FFA800] h-[250px] rounded-[24px] p-4 flex space-x-6 justify-between">
        <div className="flex flex-col">
          <img
            src={currentData?.challenger?.image}
            alt="profile avatar"
            className="w-[120px] h-[120px] object-cover rounded-[20px]"
          />
          <p className="text-white text-base font-bold mb-2 mt-1 text-center capitalize">
            {currentData?.challenger?.firstname}
            <br />
            {currentData?.challenger?.lastname}
          </p>
          <div className="h-[29px] bg-white flex items-center justify-center px-4 rounded-3xl text-ash text-xs  ">
            {currentData?.challenger?.rank}
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-base font-bold text-white mt-8">VS</p>
        </div>
        <div className="flex flex-col">
          <img
            src={currentData?.challenged?.image}
            alt="profile avatar"
            className="w-[120px] h-[120px] object-cover rounded-[20px]"
          />
          <p className="text-white text-base font-bold mb-2 mt-1 text-center capitalize">
            {currentData?.challenged?.firstname}
            <br />
            {currentData?.challenged?.lastname}
          </p>
          <div className="h-[29px] bg-white flex items-center justify-center px-4 rounded-3xl text-ash text-xs  ">
            {currentData?.challenged?.rank}
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-8 space-y-4">
        {/* start of partl */}
        <div className="flex flex-col">
          <p className="text-sm text-customBlack font-bold mb-4">
            Match Details
          </p>
        </div>

        {/* end of a pack */}
        {/* start of partl */}

        {present === 1 && (
          <div className="flex flex-col">
            <div className="w-full min-h-[50px] flex items-center border border-[#CFCFCF] px-6 justify-between rounded-[10px] mb-3">
              <p className=" text-xs text-[#5E5E5E]">Date</p>
              <p className="text-sm font-semibold text-customBlack">
                {realDayTime?.otherDate}
              </p>
              <p></p>
            </div>
            <div className="w-full min-h-[50px] flex items-center border border-[#CFCFCF] px-6 justify-between rounded-[10px] mb-3">
              <p className=" text-xs text-[#5E5E5E]">Time</p>
              <p className="text-sm font-semibold text-customBlack">
                {realDayTime?.otherTime}
              </p>
              <p></p>
            </div>
            <div className="w-full min-h-[50px] flex items-center border border-[#CFCFCF] px-6 justify-between rounded-[10px] mb-3">
              <p className=" text-xs text-[#5E5E5E]">Court</p>
              <p className="text-sm font-semibold text-customBlack capitalize">
                {matchCourt[0]?.name}
              </p>
              <p></p>
            </div>
          </div>
        )}
      </div>
      {present === 1 && (
        <div className="w-full flex flex-col gap-4 mt-8 pb-6 px-4 left-0 right-0 md:mt-16 md:max-w-[400px] md:mx-auto md:pb-8">
          <Btn active={false} onClick={() => console.log("")} name="Accepted" />
        </div>
      )}
    </div>
  );
};

export default AcceptedInvite;
