import { useState } from "react";
import DashboardLayout from "../../../Layouts/DashboardLayout";
import { AddCircle } from "../../../assets/svgs/courts";
import AssignedCourtTable from "../../../components/Tables/AdminTables/CourtsTables/AssignedCourtTable";
import AllApprovedCourtTable from "../../../components/Tables/AdminTables/CourtsTables/AllApprovedCourtTable";
import { useNavigate } from "react-router-dom";
import { resetCourtSuccess } from "../../../features/auth/TournamentSlice";
import { resetAll } from "../../../features/auth/TournamentSlice";
import { useAppDispatch } from "../../../app/hooks";
import AllCourtTable from "../../../components/Tables/AdminTables/CourtsTables/AllCourtTable";
import AllPendingBookingTable from "../../../components/Tables/AdminTables/CourtsTables/AllPendingBookingTable";
import AllRejectedCourtTable from "../../../components/Tables/AdminTables/CourtsTables/AllRejectedBookingTable";

const styles = {
  active: "text-base font-semibold text-secondary700 cursor-pointer",
  inactive: "text-base  text-neutral-400 cursor-pointer",
};

const CourtPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState<string>("all");
  const [allCourtNo, setAllCourtNo] = useState<number>(0);
  const [allPendingCourtNo, setAllPendingCourtNo] = useState<number>(0);
  const [allApprovedCourtNo, setAllApprovedCourtNo] = useState<number>(0);
  const [allRejectedCourtNo, setAllRejectedCourtNo] = useState<number>(0);

  return (
    <DashboardLayout current={4}>
      <div className="w-full flex flex-col bg-[#f4f4f4] pl-[30px] pr-[50px] ">
        <div className="w-full flex justify-between items-center mt-14  ">
          <h1 className="text-lg font-semibold text-customBlack">Courts</h1>
          <div className="w-auto flex items-center space-x-[30px]  ">
            <div
              className="bg-white cursor-pointer flex items-center space-x-[11px] rounded-[5px] p-[10px] w-auto"
              onClick={() => {
                dispatch(resetCourtSuccess());
                navigate("/admin/courts/create");
              }}
            >
              <AddCircle />
              <p className="text-customBlack text-sm font-[500] ">
                Onboard a court
              </p>
            </div>
            <div className="bg-white cursor-pointer flex items-center space-x-[11px] rounded-[5px] p-[10px] w-auto">
              <AddCircle />
              <p className="text-customBlack text-sm font-[500] ">
                Assign a court
              </p>
            </div>
            <div
              className="bg-white cursor-pointer flex items-center space-x-[11px] rounded-[5px] p-[10px] w-auto"
              onClick={() => {
                dispatch(resetAll());
                navigate("/admin/courts/book");
              }}
            >
              <AddCircle />
              <p className="text-customBlack text-sm font-[500] ">
                Book a court
              </p>
            </div>
          </div>
        </div>
        <div className="w-full flex items-center mt-6 border-b border-b-[#cfcfcf] 2xl:gap-[50px] lg:gap-5 ">
          <div
            className="flex flex-col cursor-pointer"
            onClick={() => setSelectedTab("all")}
          >
            <p
              className={
                selectedTab === "all" ? styles.active : styles.inactive
              }
            >
              {`All Courts (${allCourtNo})`}
            </p>
            <span
              className={`bg-[#9CD326] rounded-[10px]  flex flex-grow h-[3px] mt-[7px] ${
                selectedTab === "all" ? "opacity-100" : "opacity-0"
              }`}
            ></span>
          </div>
          {/* start of a tab */}
          <div
            className="flex flex-col cursor-pointer"
            onClick={() => setSelectedTab("pending")}
          >
            <p
              className={
                selectedTab === "pending" ? styles.active : styles.inactive
              }
            >
              {`Pending Approval (${allPendingCourtNo})`}
            </p>
            <span
              className={`bg-[#9CD326] rounded-[10px]  flex flex-grow h-[3px] mt-[7px] ${
                selectedTab === "pending" ? "opacity-100" : "opacity-0"
              }`}
            ></span>
          </div>
          {/* end of a tab */}
          {/* start of a tab */}
          <div
            className="flex flex-col cursor-pointer"
            onClick={() => setSelectedTab("approved")}
          >
            <p
              className={
                selectedTab === "approved" ? styles.active : styles.inactive
              }
            >
              {`Approved Booking (${allApprovedCourtNo})`}
            </p>
            <span
              className={`bg-[#9CD326] rounded-[10px]  flex flex-grow h-[3px] mt-[7px] ${
                selectedTab === "approved" ? "opacity-100" : "opacity-0"
              }`}
            ></span>
          </div>
          {/* end of a tab */}
          {/* start of a tab */}
          <div
            className="flex flex-col cursor-pointer"
            onClick={() => setSelectedTab("rejected")}
          >
            <p
              className={
                selectedTab === "rejected" ? styles.active : styles.inactive
              }
            >
              {`Rejected Booking (${allRejectedCourtNo})`}
            </p>
            <span
              className={`bg-[#9CD326] rounded-[10px]  flex flex-grow h-[3px] mt-[7px] ${
                selectedTab === "rejected" ? "opacity-100" : "opacity-0"
              }`}
            ></span>
          </div>
          {/* end of a tab */}
          {/* start of a tab */}

          {/* end of a tab */}
        </div>
        {selectedTab === "all" && (
          <AllCourtTable setAllCourtNo={setAllCourtNo} className="mt-8" />
        )}

        {selectedTab === "approved" && (
          <AllApprovedCourtTable
            className="mt-4"
            setAllApprovedCourtNo={setAllApprovedCourtNo}
          />
        )}
        {selectedTab === "pending" && (
          <AllPendingBookingTable
            className="mt-4"
            setAllPendingCourtNo={setAllPendingCourtNo}
          />
        )}
        {selectedTab === "rejected" && (
          <AllRejectedCourtTable
            className="mt-4"
            setAllRejectedCourtNo={setAllRejectedCourtNo}
          />
        )}

        <AssignedCourtTable className="mt-10" />
      </div>
    </DashboardLayout>
  );
};

export default CourtPage;
