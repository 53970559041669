import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSimplifiedError } from "../../utils";
import { APIService } from "../../utils/APIServices";
import { url } from "../../utils/endpoints";
export interface UtilityState {
  loading: boolean;
  currentUserData: any;
}

const initialState: UtilityState = {
  loading: false,
  currentUserData: {},
};

export const utilitySlice = createSlice({
  name: "Utility manager",
  initialState,
  reducers: {
    restoreDefault: (state) => {
      state.loading = false;
    },
    setCurrentUser: (state, action: PayloadAction<any>) => {
      state.currentUserData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUser.pending, (state) => {
        state.loading = false;
      })
      .addCase(fetchAllUser.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(fetchAllUser.rejected, (state, { payload }) => {
        state.loading = false;
      });
  },
});

export const fetchAllUser = createAsyncThunk(
  "allClubs",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.get(`${url.clubs}`);
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const authSelector = (state: any) => state.auth;

export const { restoreDefault, setCurrentUser } = utilitySlice.actions;
export default utilitySlice.reducer;
