import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { setSingleUpcomingMatch } from "../../features/auth/AuthSlice";
import { sortByDateField } from "../../utils";
interface FormattedDateTime {
  date: string;
  time: string;
}
const UpcomingMatches = () => {
  const user = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const [ongoingMatch, setOngoingMatch] = useState<any>([]);
  const [upcomingMatches, setUpcomingMatches] = useState([]);

  let allMatches = user?.allMatches;
  const filterNullAndUndefinedWinners = (arr: any) => {
    return arr.filter(
      (obj: { winner: null | undefined }) =>
        obj.winner === null || obj.winner === undefined
    );
  };

  const sortedArray = sortByDateField(allMatches, {
    field: "time",
  });
  useEffect(() => {
    const onGoingMatch = filterNullAndUndefinedWinners(sortedArray);
    const groupedMatch = onGoingMatch.reduce((result: any, item: any) => {
      const tournamentId = item?._tournament?._id ?? "no_tournament_id";

      result[tournamentId] = result[tournamentId] || [];

      result[tournamentId].push(item);

      return result;
    }, {});

    const groupedData = Object.entries(groupedMatch).map(([id, group]) => ({
      id,
      group,
    }));
    setOngoingMatch(groupedData);
  }, []);

  const upcoming = ongoingMatch.length > 0 ? ongoingMatch[0]?.group : [];
  function formatDateTime(inputDate: string): FormattedDateTime {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
    };
    const date: Date = new Date(inputDate);

    const dayMonthFormat: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
    };
    const formattedDate: string = date
      .toLocaleDateString("en-US", dayMonthFormat)
      .toUpperCase();

    const formattedTime: string = `${date.getHours()}:${
      date.getMinutes() < 10 ? "0" : ""
    }${date.getMinutes()}`;

    return { date: formattedDate, time: formattedTime };
  }
  const filterItemsByTime = () => {
    // Get current date and time
    const currentDate = new Date();

    // Filter out items whose deadline has not passed
    const filteredItems = upcoming.filter(
      (item: { time: string | number | Date }) => {
        // Convert item deadline string to Date object
        const matchDate = new Date(item.time);
        // Check if current date is before the item's deadline
        return currentDate <= matchDate;
      }
    );

    setUpcomingMatches(filteredItems);
  };

  useEffect(() => {
    if (upcoming?.length > 0) {
      filterItemsByTime();
    }
  }, [user?.allMatches]);

  return (
    <div className="w-full flex flex-col px-4 py-6 md:max-w-[428px] md:mx-auto md:h-screen">
      <div className="flex justify-between mt-4 items-center">
        <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
        <p className="text-[#303030] font-bold text-[15px] ">
          Upcoming Matches
        </p>
        <div className="more-shadow opacity-0"></div>
      </div>
      <div className="mb-8 mt-8">
        {upcomingMatches?.length === 0 ? (
          <div className="border border-neutral-200 p-4 flex flex-col">
            <p className="text-sm font-semibold text-customBlack">
              There are no upcoming matches
            </p>
          </div>
        ) : (
          upcomingMatches?.map((item: any, index: number) => {
            const formattedDateTime: FormattedDateTime = formatDateTime(
              item?.time
            );
            const {
              challenged,
              challenger,

              challengedB,
              challengerB,
            } = item;

            return (
              <div
                key={index}
                className="flex justify-between items-center border border-neutral-200 mb-4 p-4 rounded-[16px] gap-4 cursor-pointer w-full"
                onClick={() => {
                  setSingleUpcomingMatch(item);
                  setTimeout(() => {
                    navigate(`/view-match-detail/${item._id}`);
                  }, 1000);
                }}
              >
                <div className="flex-grow  w-6/12">
                  <p className="text-sm font-semibold text-customBlack capitalize text-right">
                    {challengerB?.firstname
                      ? `${challenger?.firstname}/${challengerB?.firstname}`
                      : challenger?.lastname
                      ? challenger?.firstname + " " + challenger?.lastname
                      : challenger?.firstname
                      ? challenger?.firstname
                      : "BYE"}
                  </p>
                  {/* <p className="font-medium text-[10px] text-neutral-400">
                            {item.playerRank}
                          </p> */}
                </div>
                <div className="w-[60px] max-w-[60px] min-w-[60px]">
                  <p className="text-xs font-bold text-primaryYellow">
                    {formattedDateTime.time}
                  </p>
                  <p className="font-bold text-[10px] text-primaryYellow">
                    {formattedDateTime.date}
                  </p>
                </div>
                <div className="flex-grow  w-6/12">
                  <p className="text-sm font-semibold text-customBlack capitalize">
                    {challengedB?.firstname
                      ? `${challenged?.firstname}/${challengedB?.firstname}`
                      : challenged?.lastname
                      ? challenged?.firstname + " " + challenged?.lastname
                      : challenged?.firstname
                      ? challenged?.firstname
                      : "BYE"}
                  </p>
                  {/* <p className="font-medium text-[10px] text-neutral-400">
                            {item.opponentRank}
                          </p> */}
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default UpcomingMatches;
