import { useEffect, useState } from "react";
import { FiPhoneCall } from "react-icons/fi";
import { GrLocation, GrMapLocation } from "react-icons/gr";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import sentMark from "../../assets/svgs/sent-mark.svg";
import { More, Taptotext } from "../../assets/svgs/court";
import { Btn } from "../../components/Button";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  challengePlayer,
  getAllCourtUser,
  getSinglePlayerDetail,
  resetAllLadderState,
} from "../../features/auth/ladder-slice";

import ReUseModal from "../../components/Modal/ReUseModal";
import LoadingComponent from "../../components/Loading/Loader";

const SelectedProfile = () => {
  const [present, setPresent] = useState(1);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ladder = useAppSelector((state) => state.ladder);
  const auth = useAppSelector((state) => state.auth);
  const [selectedCourt, setSelectedCourt] = useState<any>({});
  const userData = auth?.userData?.user;
  const [date, setDate] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [active, setActive] = useState(false);

  const id = useParams();

  useEffect(() => {
    dispatch(getSinglePlayerDetail({ id: id.id }));
    dispatch(getAllCourtUser());
  }, []);
  useEffect(() => {
    if (selectedCourt && date) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [selectedCourt, date]);

  useEffect(() => {
    if (ladder?.challengePlayerSuccess) {
      setOpenModal(true);
      setTimeout(() => {
        dispatch(resetAllLadderState());
        setOpenModal(false);
        navigate("/clubs");
      }, 3000);
    }
  }, [ladder?.challengePlayerSuccess]);

  const detail = ladder?.singlePlayerDetail?.data;

  const ladderId = ladder?.singleLadderDetail?._id;
  const allCourts = ladder?.allCourts;

  const handleChallenge = () => {
    const data = {
      user: id.id,
    };
    dispatch(challengePlayer({ id: ladderId, data: data }));
  };

  const handleClick = () => {
    const formattedNumber = detail?.phone.replace(/\D/g, ""); // Remove non-digit characters
    const whatsappUrl = `https://wa.me/+234${formattedNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <>
      {present === 1 && (
        <div className="w-full flex flex-col px-4 pb-10 md:h-screen md:max-w-[428px] md:mx-auto">
          <div className="flex justify-between mt-6 items-center mb-4">
            <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
            <p className="text-[#303030] font-bold text-[15px] ">
              Challenge a Player
            </p>
            <div className="more-shadow opacity-0">
              <More />
            </div>
          </div>

          {ladder?.fetchLoading ? (
            <div className="w-full px-4 mt-8">
              <LoadingComponent />
            </div>
          ) : (
            <div className="w-full flex flex-col">
              <div className="profile-hero-bg rounded-[24px] flex flex-col justify-center px-6 min-w-full py-8 ">
                <div className="flex space-x-4">
                  <img
                    src={detail?.image}
                    alt="profile avatar"
                    className="w-[100px] h-[100px] rounded-[20px] "
                  />
                  <div className="flex flex-col">
                    <p className="text-white text-base font-bold mb-1 capitalize">
                      {detail?.firstname + " " + detail?.lastname}
                    </p>
                    <p className="text-xs text-white mb-2">
                      Points: {detail?.rank}
                    </p>
                    <p className="text-xs capitalize text-white mb-2 truncate">
                      Ladder: {ladder?.singleLadderDetail?.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="w-full home-option flex flex-col justify-center">
                  <p className="text-lemonGreen font-bold text-3xl text-center">
                    {ladder?.singlePlayerDetail?.win}
                  </p>
                  <p className="text-xs text-center mt-1">Total Won Matches</p>
                </div>
                <div className="w-full home-option flex flex-col justify-center">
                  <p className="text-lemonGreen font-bold text-3xl text-center">
                    {ladder?.singlePlayerDetail?.loss}
                  </p>
                  <p className="text-xs text-center mt-1">Total Lost Matches</p>
                </div>
              </div>

              <div className="mt-12 pb-6">
                <p className="text-md font-semibold text-[#2c2c2c] mb-4">
                  Basic Details
                </p>
                <div className="flex flex-col">
                  {/* start of a pack */}
                  <div className="flex h-14 items-center w-full space-x-6">
                    <FiPhoneCall className="text-[#303030] font-bold text-2xl" />
                    <div className="items-center flex h-14 border-t border-b border-border w-full justify-between">
                      <p className="text-sm">{detail?.phone}</p>
                      <span onClick={handleClick}>
                        <Taptotext />
                      </span>
                    </div>
                  </div>
                  {/* end of the pack */}
                  {/* start of a pack */}
                  <div className="flex h-14 items-center w-full space-x-6">
                    <GrLocation className="text-[#303030] font-bold text-2xl" />
                    <div className="items-center flex h-14 border-t border-b border-border w-full">
                      <p className="text-sm">{detail?.address}</p>
                    </div>
                  </div>
                  {/* end of the pack */}
                </div>
                <div className="mt-8">
                  <p className="text-md font-semibold text-[#2c2c2c] mb-4">
                    Match Preference
                  </p>
                  <div className="flex flex-col">
                    {/* start of a pack */}
                    <div className="flex h-14 items-center w-full space-x-6">
                      <GrMapLocation className="text-[#303030] font-bold text-2xl" />
                      <div className="items-center flex h-14 border-t border-b border-border w-full">
                        <p className="text-sm">Nearby Court</p>
                      </div>
                    </div>
                    {/* end of the pack */}
                    {/* start of a pack */}
                    <div className="flex h-14 items-center w-full space-x-6">
                      <FiPhoneCall className="text-[#303030] font-bold text-2xl" />
                      <div className="items-center flex h-14 border-t border-b border-border w-full">
                        <p className="text-sm">10:00am, 3:00pm, 5:00pm</p>
                      </div>
                    </div>
                    {/* end of the pack */}
                  </div>
                </div>

                <div className="w-full mt-12">
                  <Btn
                    active
                    onClick={handleChallenge}
                    name={ladder?.loading ? "Loading" : "Challenge Player"}
                    loading={ladder?.loading}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <ReUseModal open={openModal} setOpen={setOpenModal}>
        <div className="w-full flex flex-col">
          <img src={sentMark} alt="sent" className="mx-auto mt-6" />
          <h1 className="text-customBlack text-[18px] font-bold text-center ">
            Player Challenged Successfully
          </h1>
          <p className="text-center w-10/12 mx-auto text-neutral-500 text-sm ">
            Your match invite has been sent successfully to your opponent.
            Kindly wait for approval.
          </p>
          <Btn
            active={true}
            name="Close"
            onClick={() => {
              setOpenModal(false);
              dispatch(resetAllLadderState());
              navigate(`/clubs`);
            }}
            className="mt-[25px] mb-3"
          />
        </div>
      </ReUseModal>
    </>
  );
};

export default SelectedProfile;
