import { useState, useEffect } from "react";
import teno from "../../../assets/png/teno.png";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Btn } from "../../../components/Button";
import { EmailInput } from "../../../components/Input";
import { BigText, ModalSub, Ptext } from "../../../components/Text";
import { useNavigate } from "react-router-dom";
import { Message } from "../../../assets/svgs/Icons";
import ReUseModal from "../../../components/Modal/ReUseModal";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { verifyUserEmail } from "../../../features/auth/AuthSlice";

const EmailVerification = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userAuth = useAppSelector((state) => state.auth);
  const [email, setEmail] = useState("");
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    email ? setActive(true) : setActive(false);
  }, [email]);
  useEffect(() => {
    if (userAuth.emailVerificationSuccess) {
      setOpen(true);
    } else setOpen(false);
  }, [userAuth.emailVerificationSuccess]);

  const handleSend = () => {
    dispatch(verifyUserEmail({ email: email }));
  };

  return (
    <>
      {/* <div className=" flex-col lg:flex hidden mt-6 px-4 justify-center h-screen w-1/2 mx-auto">
        <h1 className="font-bold text-customBlack text-xl text-center mb-2 ">
          Oops, this page is only designed for mobile devices.
        </h1>
        <p className="text-neutral-700 text-base  ">
          Please switch to a smaller screen, such as a smartphone or tablet, to
          enjoy this feature. We appreciate your cooperation.
        </p>
      </div> */}
      <div className="flex flex-col p-4 md:max-w-[428px] md:mx-auto md:h-screen">
        <div className="flex relative h-full bg-white">
          <div className="fixed  right-0 top-0 z-0">
            <img src={teno} alt="tenis" className="w-fit h-auto" />
          </div>
        </div>
        <AiOutlineArrowLeft
          onClick={() => navigate(-1)}
          className="mt-20 text-2xl"
        />
        <div className="flex flex-col">
          <BigText text="Confirm Email Address" className="mt-8" />
          <Ptext
            text="Kindly confirm your email address for verification "
            className="mb-[37px] mt-2"
          />
          <EmailInput
            required
            onChange={(e: any) => setEmail(e.target.value)}
            placeholder="Enter your Email"
          />
          <div className="fixed  bottom-20 left-0 right-0 p-4">
            <Btn
              name="Send Code"
              active={active}
              onClick={handleSend}
              loading={userAuth.loading}
            />
          </div>
        </div>
        <ReUseModal open={open} setOpen={setOpen}>
          <div className="w-full flex flex-col justify-center items-center py-8">
            <Message />
            <p className="text-customBlack font-bold text-lg text-center">
              Check your email
            </p>
            <ModalSub
              className="text-center mt-1"
              text="We’ve sent an OTP code to your email to reset your password"
            />
            <div className="w-6/12 mx-auto">
              <Btn
                active={true}
                onClick={() => navigate("/otp")}
                name="Continue"
                loading={userAuth.loading}
                className="mt-3 "
              />
            </div>
          </div>
        </ReUseModal>
      </div>
    </>
  );
};

export default EmailVerification;
