import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { More } from "../../assets/svgs/court";
import { Btn, OutlinedBtn, RedOutlinedBtn } from "../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  acceptChallengeInvite,
  getAllAuditTrail,
  getSingleInvitationUser,
  rejectChallengeInvite,
  resetAllLadderState,
  updateMatchTime,
} from "../../features/auth/ladder-slice";
import noDateIcon from "../../assets/png/Calendar.png";

import LoadingComponent from "../../components/Loading/Loader";
import { convertDateTime } from "../../utils";
import { UserDateTimeInput } from "../../components/Input/DateInput";
import UserSelect from "../../components/Selects/UserSelect";
import ReUseModal from "../../components/Modal/ReUseModal";
import sentMark from "../../assets/svgs/sent-mark.svg";
import { CautionIcon } from "../../assets/svgs/tournament";
const SentInvite = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ladder = useAppSelector((state) => state.ladder);
  const auth = useAppSelector((state) => state.auth);
  const [currentData, setCurrentData] = useState<any>({});
  const [present, setPresent] = useState<number>(1);

  const [selectedCourt, setSelectedCourt] = useState<any>({});

  const [modifySuccess, setModifySuccess] = useState<boolean>(false);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openConsentModal, setOpenConsentModal] = useState(false);
  const [date, setDate] = useState("");
  const id = useParams();
  useEffect(() => {
    dispatch(getSingleInvitationUser({ id: id.id }));
    dispatch(getAllAuditTrail({ id: id?.id }));
  }, []);
  const audit = ladder?.allAuditTrail?.audit;
  useEffect(() => {
    if (ladder?.singleInvitation.length > 0) {
      const filterItem = ladder?.singleInvitation?.filter(
        (item: any) => item?._id === id?.id
      );
      setCurrentData(filterItem[0]);
    }
  }, [ladder?.singleInvitation]);
  const allCourts = ladder?.allCourts;
  const details = ladder?.singleInvitation;

  const matchCourt = allCourts?.filter(
    (item: any) => item?._id === currentData?.court
  );
  const handleAccepted = () => {
    const sentData = {
      id: id?.id,
      data: { state: "accepted" },
    };
    dispatch(acceptChallengeInvite(sentData));
  };
  const handleRejected = () => {
    const sentData = {
      id: id?.id,
      data: { state: "rejected" },
    };
    dispatch(rejectChallengeInvite(sentData));
  };

  useEffect(() => {
    if (ladder?.acceptLadderInviteSuccess) {
      setOpenAcceptModal(true);
      dispatch(resetAllLadderState());
    }
  }, [ladder?.acceptLadderInviteSuccess]);

  useEffect(() => {
    if (ladder?.rejectLadderInviteSuccess) {
      setOpenConsentModal(false);
      setOpenRejectModal(true);
      dispatch(resetAllLadderState());
    }
  }, [ladder?.rejectLadderInviteSuccess]);
  const realDayTime = convertDateTime(currentData?.time);
  useEffect(() => {
    if (currentData?.time && currentData?.court) {
      setPresent(2);
    }
  }, [currentData]);

  const handleModifyData = () => {
    const sentData = {
      id: currentData?._id,
      data: {
        court: selectedCourt?._id,
        time: date,
      },
    };
    dispatch(updateMatchTime(sentData));
  };

  useEffect(() => {
    if (ladder?.updateMatchTimeSuccess) {
      setModifySuccess(true);
      dispatch(resetAllLadderState());
      setTimeout(() => {
        navigate("/all-challenge-invitations");
      }, 2000);
    }
  }, [ladder?.updateMatchTimeSuccess]);

  console.log(audit?.modify);

  if (ladder?.fetchLoading) {
    return (
      <div className="w-full px-4 mt-8 md:max-w-[428px] md:mx-auto md:h-screen">
        <LoadingComponent />
      </div>
    );
  }
  return (
    <div className="w-full flex flex-col px-4 pb-10 relative h-screen md:max-w-[428px] md:mx-auto md:h-screen">
      <div className="flex justify-between mt-4 items-center mb-4">
        <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
        <p className="text-[#303030] font-bold text-[15px] ">
          Challenge Invitation
        </p>
        <div className="more-shadow opacity-0">
          <More />
        </div>
      </div>
      <div className="w-full bg-[#FFA800] h-[250px] rounded-[24px] p-4 flex space-x-6 justify-between">
        <div className="flex flex-col">
          <img
            src={currentData?.challenger?.image}
            alt="profile avatar"
            className="w-[120px] h-[120px] object-cover rounded-[20px]"
          />
          <p className="text-white text-base font-bold mb-2 mt-1 text-center capitalize">
            {currentData?.challenger?.firstname}
            <br />
            {currentData?.challenger?.lastname}
          </p>
          <div className="h-[29px] bg-white flex items-center justify-center px-4 rounded-3xl text-ash text-xs  ">
            {currentData?.challenger?.rank}
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-base font-bold text-white mt-8">VS</p>
        </div>
        <div className="flex flex-col">
          <img
            src={currentData?.challenged?.image}
            alt="profile avatar"
            className="w-[120px] h-[120px] object-cover rounded-[20px]"
          />
          <p className="text-white text-base font-bold mb-2 mt-1 text-center capitalize">
            {currentData?.challenged?.firstname}
            <br />
            {currentData?.challenged?.lastname}
          </p>
          <div className="h-[29px] bg-white flex items-center justify-center px-4 rounded-3xl text-ash text-xs  ">
            {currentData?.challenged?.rank}
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-8 space-y-4">
        {/* start of partl */}
        <div className="flex flex-col">
          <p className="text-sm text-customBlack font-bold mb-4">
            Match Details
          </p>
        </div>

        {/* end of a pack */}
        {/* start of partl */}
        {present === 1 && !currentData?.time && !currentData?.court && (
          <div className="w-full flex justify-center">
            <img src={noDateIcon} alt="" />
          </div>
        )}
        {present === 1 && currentData?.time && currentData?.court && (
          <>
            <div className="flex justify-between items-center mb-3">
              <p className="text-sm text-[#5e5e5e] font-[400] ">Match Date </p>
              <p className="text-customBlack text-sm font-bold">
                {" "}
                {realDayTime?.otherDate}
              </p>
            </div>
            {/* end of a pack */}
            <div className="flex justify-between items-center mb-3">
              <p className="text-sm text-[#5e5e5e] font-[400] ">Match Time </p>
              <p className="text-customBlack text-sm font-bold">
                {" "}
                {realDayTime?.otherTime}
              </p>
            </div>

            <div className="flex justify-between items-center mb-3">
              <p className="text-sm text-[#5e5e5e] font-[400] ">Court </p>
              <p className="text-customBlack text-sm font-bold capitalize">
                {" "}
                {matchCourt[0]?.name}
              </p>
            </div>
          </>
        )}

        {present == 2 && (
          <div className="flex flex-col">
            <div className="w-full min-h-[50px] flex items-center border border-[#CFCFCF] px-6 justify-between rounded-[10px] mb-3">
              <p className=" text-xs text-[#5E5E5E]">Date</p>
              <p className="text-sm font-semibold text-customBlack">
                {realDayTime?.otherDate}
              </p>
              <p></p>
            </div>
            <div className="w-full min-h-[50px] flex items-center border border-[#CFCFCF] px-6 justify-between rounded-[10px] mb-3">
              <p className=" text-xs text-[#5E5E5E]">Time</p>
              <p className="text-sm font-semibold text-customBlack">
                {realDayTime?.otherTime}
              </p>
              <p></p>
            </div>
            <div className="w-full min-h-[50px] flex items-center border border-[#CFCFCF] px-6 justify-between rounded-[10px] mb-3">
              <p className=" text-xs text-[#5E5E5E]">Court</p>
              <p className="text-sm font-semibold text-customBlack capitalize">
                {matchCourt[0]?.name}
              </p>
              <p></p>
            </div>
          </div>
        )}
        {present == 3 && (
          <div className="flex flex-col mt-8 space-y-6">
            {/* start of partl */}
            <div className="flex flex-col">
              <p className="text-xs text-ash font-[500] mb-1">Choose Court</p>
              <UserSelect
                setSelected={setSelectedCourt}
                selected={selectedCourt}
                name={"Select Court"}
                label={""}
                data={allCourts}
                height="h-[50px] bg-[#F4F4F4]"
              />
            </div>

            {/* end of a pack */}
            {/* start of partl */}
            <div className="flex flex-col ">
              <p className="text-xs text-ash font-[500] mb-1">
                Match Date & Time
              </p>

              <UserDateTimeInput
                onChange={(e: any) => setDate(e.target.value)}
                dateTime={date}
              />
            </div>
            {/* end of a pack */}
          </div>
        )}
      </div>

      {present == 1 && (
        <div className="w-full flex flex-col gap-4 mt-8 px-4 left-0 right-0 md:mt-16 md:max-w-[400px] md:mx-auto md:pb-8">
          <Btn active={false} onClick={() => setPresent(2)} name="Pending" />
        </div>
      )}
      {present === 2 &&
        currentData?.invitation === "pending" &&
        audit?.modify?.length !== 2 && (
          <div className="w-full flex flex-col gap-4 mt-8 px-4 left-0 right-0 md:mt-16 md:max-w-[400px] md:mx-auto md:pb-8">
            <Btn active={true} onClick={handleAccepted} name="Accept" />
            {audit?.modify?.length && (
              <OutlinedBtn
                active={true}
                onClick={() => setPresent(3)}
                name="Modify Date"
              />
            )}

            <RedOutlinedBtn
              active={true}
              onClick={() => setOpenConsentModal(true)}
              name="Reject"
            />
          </div>
        )}
      {currentData?.invitation === "accepted" && (
        <div className="mt-8 pb-6 px-4 left-0 right-0 md:mt-16 md:max-w-[400px] md:mx-auto md:pb-8">
          <Btn active={false} onClick={() => console.log("")} name="Accepted" />
        </div>
      )}
      {present == 3 && (
        <div className="w-full flex flex-col gap-4 mt-8 pb-6 px-4 left-0 right-0 md:mt-16 md:max-w-[400px] md:mx-auto md:pb-8">
          <Btn
            active={date && selectedCourt}
            onClick={handleModifyData}
            name="Modify"
            loading={ladder?.loading}
          />
        </div>
      )}
      <ReUseModal open={openAcceptModal} setOpen={setOpenAcceptModal}>
        <div className="w-full flex flex-col ">
          <img src={sentMark} alt="sent" className="mx-auto mt-6" />
          <h1 className="text-customBlack text-[18px] font-bold text-center mt-6 ">
            Schedule Accepted
          </h1>
          <p className="text-center w-full mx-auto text-neutral-500 text-sm ">
            You’ve successfully accepted the scheduled time.
          </p>
          <Btn
            active={true}
            name="Close"
            onClick={() => {
              setOpenAcceptModal(false);
              dispatch(resetAllLadderState());
              navigate(`/all-challenge-invitations`);
            }}
            className="mt-[25px] mb-3"
          />
        </div>
      </ReUseModal>
      <ReUseModal open={modifySuccess} setOpen={setModifySuccess}>
        <div className="w-full flex flex-col ">
          <img src={sentMark} alt="sent" className="mx-auto mt-6" />
          <h1 className="text-customBlack text-[18px] font-bold text-center mt-6 ">
            Date Modified
          </h1>
          <p className="text-center w-full mx-auto text-neutral-500 text-sm ">
            You’ve successfully modified the date.
          </p>
          <Btn
            active={true}
            name="Close"
            onClick={() => {
              setModifySuccess(false);
              dispatch(resetAllLadderState());
              navigate(`/all-challenge-invitations`);
            }}
            className="mt-[25px] mb-3"
          />
        </div>
      </ReUseModal>
      <ReUseModal open={openConsentModal} setOpen={setOpenConsentModal}>
        <div className="w-full flex flex-col">
          <span className="mx-auto mt-6">
            <CautionIcon />
          </span>

          <h1 className="text-customBlack text-[18px] font-bold text-center mt-6">
            Reject Challenge
          </h1>
          <p className="text-center w-10/12 mx-auto text-neutral-500 text-sm ">
            If you reject this challenge, you will lose your position on the
            rank
          </p>
          <div className="flex justify-between items-center mt-4 gap-5">
            <span className="w-1/2">
              <OutlinedBtn
                active
                onClick={() => setOpenConsentModal(false)}
                name="No Cancel"
              />
            </span>
            <span className="w-1/2">
              <button
                className="h-[56px] w-full rounded-[16px] bg-[#B00020]  text-white font-semibold text-base  flex items-center justify-center cursor-pointer"
                onClick={handleRejected}
                disabled={ladder?.loading}
              >
                {ladder?.loading ? "Loading" : "Yes, Reject"}
              </button>
            </span>
          </div>
        </div>
      </ReUseModal>
      <ReUseModal open={openRejectModal} setOpen={setOpenRejectModal}>
        <div className="w-full flex flex-col ">
          <img src={sentMark} alt="sent" className="mx-auto mt-6" />
          <h1 className="text-customBlack text-[18px] font-bold text-center mt-6 ">
            Challenged Rejected
          </h1>
          <p className="text-center w-full mx-auto text-neutral-500 text-sm ">
            You’ve successfully rejected the challenge.
          </p>
          <span className="w-1/2 mx-auto">
            <Btn
              active={true}
              name="Close"
              onClick={() => {
                setOpenRejectModal(false);
              }}
              className="mt-[25px] mb-3"
            />
          </span>
        </div>
      </ReUseModal>
    </div>
  );
};

export default SentInvite;
