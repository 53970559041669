import { useNavigate } from "react-router-dom";
import GeneralLayout from "../../Layouts/GeneralLayout";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect } from "react";
import LinkCard from "../../components/cards/link-card";
import LoadingComponent from "../../components/Loading/Loader";
import {
  getAllCompetitionMatches,
  getAllJoinedCompetition,
} from "../../features/auth/TournamentSlice";
import {
  EmptyListIcon,
  RecordIcon,
  TennisBallIcon,
} from "../../assets/new-svg/icons";
import { SectionTitle } from "../../components/Text/new-text";
import CompetitionCard from "../../components/cards/competition-card";
const Competition = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const auth = useAppSelector((state) => state.auth);
  const tournament = useAppSelector((state) => state.tournament);

  useEffect(() => {
    dispatch(getAllJoinedCompetition());
    dispatch(getAllCompetitionMatches());
  }, []);

  return (
    <GeneralLayout active={4} name="Competition">
      <div className="w-full flow-hide flex flex-col px-4 pb-40 overflow-x-hidden md:w-[450px]">
        <div className="new-home-banner  rounded-[24px] flex flex-col justify-center px-6 min-w-full py-8 ">
          <div className="flex space-x-4">
            <div className="flex flex-col">
              <p className="text-white text-[30px] font-medium mb-1 capitalize">
                {auth?.userData?.user?._club?.name}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col  ">
          <SectionTitle text="Quick Actions" className="mt-6" />
          <div className="w-full gap-4 grid grid-cols-2 mt-4">
            <LinkCard
              name="All Matches"
              onClick={() => navigate("/competition-match-fixtures")}
              icon={<TennisBallIcon />}
              background="bg-[#7BC1330D] bg-opacity-5"
            />

            <LinkCard
              name="Match Score"
              onClick={() => navigate("/competition-match-scores")}
              icon={<RecordIcon />}
              background="bg-[#EB57570D] bg-opacity-5"
            />
          </div>
        </div>
        {tournament?.fetchLoading ? (
          <div className="px-4 mt-8">
            <LoadingComponent />
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="mt-[30px]">
              <SectionTitle text="Recent Competition" className="mb-3" />
              <div className="flex flex-col w-full gap-3">
                {tournament?.joinedCompetitionUser?.length === 0 && (
                  <div className="flex flex-col justify-center items-center">
                    <span>
                      <EmptyListIcon />
                    </span>
                    <p className="text-secondary-400 text-base font-medium mt-4">
                      All recent competitions will be displayed here
                    </p>
                  </div>
                )}
                {tournament?.joinedCompetitionUser?.length > 0 &&
                  tournament?.joinedCompetitionUser?.map(
                    (item: any, index: number) => {
                      return (
                        <CompetitionCard
                          name={item?.name}
                          onClick={() =>
                            navigate(`/selected-competition/${item._id}`)
                          }
                          key={index}
                          start={item?.start}
                          index={0}
                        />
                        // <div
                        //   className="w-full h-20 border-border border rounded-md flex items-center justify-between px-4 cursor-pointer "
                        //   key={index}

                        // >
                        //   <div className="flex items-center space-x-3">
                        //     <ClubBall />
                        //     <div className="flex flex-col">
                        //       <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                        //         {item?.name}
                        //       </p>
                        //       <p className="text-[12px] text-[#acacac]">
                        //         Starts {formatDate(item?.start)}
                        //       </p>
                        //     </div>
                        //   </div>
                        //   <div className="flex items-center">
                        //     <IoIosArrowForward className="text-2xl text-lemonGreen" />
                        //   </div>
                        // </div>
                      );
                    }
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    </GeneralLayout>
  );
};

export default Competition;
