import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import receipt from "../../../../assets/svgs/receipt.svg";
import {
  getAllAcceptedPlayer,
  getAllTournaments,
  getMatchInTournament,
  resetAll,
} from "../../../../features/auth/TournamentSlice";
import { formatDate } from "../../../../utils";

const AllKnockoutTables = ({ className }: any) => {
  const dispatch = useAppDispatch();
  const tournamentAuth = useAppSelector((state) => state.tournament);
  useEffect(() => {
    dispatch(getAllTournaments());
    dispatch(resetAll());
  }, []);

  const tableData = tournamentAuth.allTournament;
  const knockoutTournamentData = tableData.filter(
    (item: any) => item?.mode === "knockout"
  );

  return (
    <div
      className={`w-full  h-full min-h-[40vh] flex flex-col bg-white  rounded-[20px] ${className} `}
    >
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className="text-sm font-semibold text-customBlack">
          Knockout Tournament
        </h1>
      </div>
      <table className="min-w-full w-full h-full bg-[#fff] table-auto rounded-b-[20px]   ">
        <thead className=" bg-[#f4f4f4] bg-opacity-40 mb-2  whitespace-no-wrap ">
          <tr className="whitespace-no-wrap flex items-center h-[35px] pl-5">
            <th className="w-2/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-start ">
              {" "}
              Name of Tournament
            </th>
            <th className="w-[100px]  theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-start ">
              {" "}
              Type
            </th>
            <th className="w-2/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-center ">
              {" "}
              Start Date
            </th>
            <th className="w-[100px] theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-center ">
              {" "}
              Current Round
            </th>
            <th className="w-2/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-center whitespace-no-wrap ">
              {" "}
              No of Players
            </th>
            <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-start whitespace-no-wrap">
              {" "}
              Status
            </th>
            <th className="ml-10 w-2/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-start flex whitespace-no-wrap pl-5">
              {" "}
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="w-full flex flex-col space-y-6 mb-4 mt-2 min-h-[40vh]">
          {knockoutTournamentData.length ? (
            knockoutTournamentData?.map((item: any, index: number) => {
              return <SingleRow item={item} key={index} />;
            })
          ) : (
            <div className="mt-10 border-2.38 border-gray-600 w-38 h-38 flex flex-col items-center">
              <img
                src={receipt}
                className="border-2.5 border-gray-600 w-38 h-38"
                alt=""
              />
              <h1 className="font-inter text-sm font-medium leading-15 tracking-wider w-106 h-15 text-center mt-5">
                No Ongoing Knockout Matches
              </h1>
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AllKnockoutTables;

const SingleRow = ({ item, index }: any) => {
  const { limit, name, start, status, createdAt, _id, round, players, _type } =
    item;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <tr className="w-full flex items-center pl-5 " key={index}>
      <td className="text-left text-sm text-neutral-800 w-2/12 capitalize">
        {name ? name : "FirstCome"}
      </td>
      <td className="text-start text-sm text-neutral-800 w-[100px] capitalize">
        {_type === "prefill" ? "Pre-filled" : "First Come"}
      </td>
      <td className="text-center text-sm text-neutral-800 w-2/12">
        {formatDate(start || createdAt)}
      </td>
      <td className=" text-center text-sm text-neutral-800 w-1/12">
        {round ? round : "1"}
      </td>
      <td className="text-center text-sm text-neutral-800 w-2/12">
        {players?.length ? players.length : limit}
      </td>
      <td className="text-start text-sm text-neutral-800 w-[100px]">
        {status === "pending" ? (
          <p className="bg-[#F7F7F7] text-[#E48900] text-sm p-[6px] rounded-[8px] flex items-center justify-center ">
            {status === "ongoing" || status === "schedule"
              ? "In Progress"
              : status === "pending"
              ? "Not Started"
              : status === "finished"
              ? "Ended"
              : status}
          </p>
        ) : (
          <p className="bg-[#D1E5D6] text-[#197C34] text-sm px-[4px] py-[6px] rounded-[8px] flex items-center justify-center  ">
            {status === "ongoing" || status === "schedule"
              ? "In Progress"
              : status === "pending"
              ? "Not Started"
              : status === "finished"
              ? "Ended"
              : status}
          </p>
        )}
      </td>
      <td className="ml-10 text-left text-sm text-neutral-800 w-2/12 flex justify-start pl-5 space-x-[23px]">
        <div>
          {status === "pending" && _type !== "prefill" && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-start"
              onClick={() => {
                dispatch(getAllAcceptedPlayer({ id: _id }));
                if (round > 1) {
                  navigate(
                    `/admin/create-tournament/knockout/firstcome/${_id}?round=${round}`
                  );
                } else {
                  navigate(
                    `/admin/create-tournament/knockout/firstcome/${_id}`
                  );
                }
              }}
            >
              Start Scheduling
            </p>
          )}
          {status === "ongoing" && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-start"
              onClick={() => {
                dispatch(getMatchInTournament({ id: _id }));
                navigate(`/admin/score-tournament/knockout/${_id}`);
              }}
            >
              View Scheduling
            </p>
          )}
          {status === "schedule" && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-start"
              onClick={() => {
                dispatch(getMatchInTournament({ id: _id }));
                navigate(
                  `/admin/create-tournament/knockout/firstcome/${_id}?round=${round}`
                );
              }}
            >
              Start Scheduling
            </p>
          )}
          {status === "finished" && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-start"
              onClick={() => {
                dispatch(getMatchInTournament({ id: _id }));
                navigate(`/admin/score-tournament/knockout/${_id}`);
              }}
            >
              View Scheduling
            </p>
          )}
          {status === "pending" &&
            _type === "prefill" &&
            players?.length === 0 && (
              <p
                className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-right"
                onClick={() => {
                  navigate(`/admin/select-players/knockout/${_id}`);
                }}
              >
                Select Players
              </p>
            )}
          {status === "pending" &&
            _type === "prefill" &&
            players?.length !== 0 && (
              <p
                className=" text-[#E48900] text-sm font-[500] underline cursor-pointer "
                onClick={() => {
                  dispatch(getAllAcceptedPlayer({ id: _id }));
                  if (round > 1) {
                    navigate(
                      `/admin/create-tournament/knockout/firstcome/${_id}?round=${round}`
                    );
                  } else {
                    navigate(
                      `/admin/create-tournament/knockout/firstcome/${_id}`
                    );
                  }
                }}
              >
                Start Scheduling
              </p>
            )}
        </div>
        <div>
          {status === "pending" && _type !== "prefill" && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-start"
              onClick={() => {
                dispatch(getAllAcceptedPlayer({ id: _id }));
                navigate(`/admin/scoring/knockout/${_id}`);
              }}
            >
              {round > 1 ? "View Scores" : ""}
            </p>
          )}
          {status === "ongoing" && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-start"
              onClick={() => {
                dispatch(getMatchInTournament({ id: _id }));
                navigate(`/admin/scoring/knockout/${_id}`);
              }}
            >
              {round > 1 ? "View Scores" : "View Scores"}
            </p>
          )}
          {status === "schedule" && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-start"
              onClick={() => {
                dispatch(getMatchInTournament({ id: _id }));
                navigate(`/admin/scoring/knockout/${_id}`);
              }}
            >
              {round > 1 ? "View Scores" : ""}
            </p>
          )}
          {status === "finished" && (
            <p
              className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-start"
              onClick={() => {
                dispatch(getMatchInTournament({ id: _id }));
                navigate(`/admin/scoring/knockout/${_id}`);
              }}
            >
              View Scores
            </p>
          )}
          {status === "pending" &&
            _type === "prefill" &&
            players?.length === 0 && (
              <p
                className=" text-[#E48900] text-sm font-[500] underline cursor-pointer text-right"
                onClick={() => {
                  navigate(`/admin/scoring/knockout/${_id}`);
                }}
              >
                {round > 1 ? "View Scores" : ""}
              </p>
            )}
          {status === "pending" &&
            _type === "prefill" &&
            players?.length !== 0 && (
              <p
                className=" text-[#E48900] text-sm font-[500] underline cursor-pointer "
                onClick={() => {
                  dispatch(getAllAcceptedPlayer({ id: _id }));
                  navigate(`/admin/scoring/knockout/${_id}`);
                }}
              >
                {round > 1 ? "View Scores" : ""}
              </p>
            )}
        </div>
      </td>
    </tr>
  );
};
