import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSimplifiedError } from "../../utils";
import { APIService, nAPIService } from "../../utils/APIServices";
import { url } from "../../utils/endpoints";
export interface AuthState {
  loading: boolean;
  token: string;
  data: any;
  allClubs?: any;
  clubsLoading: boolean;
  error: any;
  userData: any;
  registerSuccess: boolean;
  googleAuthData: any;
  emailVerificationSuccess: boolean;
  otpSuccess: boolean;
  allInvitations: any;
  joinTournamentSuccess: boolean;
  leaveTournamentSuccess: boolean;
  allMatches: any;
  myMatches: any;
  clubDetail: any;
  forgotEmailVerificationSuccess: boolean;
  forgotPasswordOtpSuccess: boolean;
  forgetPasswordToken: string;
  newPasswordSuccess: boolean;
  seenSuccess: boolean;
  evalutionSuccess: boolean;
  redirectUrl: string;
  joinStatus: boolean;
  joinError: boolean;
  allQoutes: any;
  profileUpdateSuccess: boolean;
  profileData: any;
  singleUpcomingMatch: any;
  isEvaluated: boolean;
  userLoss: string | number;
  userWin: string | number;
  userMatches: string | number;
  fetchLoading: boolean;
}

const initialState: AuthState = {
  loading: false,
  token: "",
  data: [],
  allClubs: [],
  clubsLoading: false,
  error: {},
  userData: {},
  registerSuccess: false,
  googleAuthData: {},
  emailVerificationSuccess: false,
  otpSuccess: false,
  allInvitations: [],
  joinTournamentSuccess: false,
  leaveTournamentSuccess: false,
  allMatches: [],
  myMatches: [],
  clubDetail: {},
  forgotEmailVerificationSuccess: false,
  forgotPasswordOtpSuccess: false,
  newPasswordSuccess: false,
  forgetPasswordToken: "",
  seenSuccess: false,

  evalutionSuccess: false,

  redirectUrl: "",
  joinStatus: false,
  joinError: false,
  allQoutes: [],
  profileUpdateSuccess: false,
  profileData: {},
  singleUpcomingMatch: {},
  isEvaluated: false,
  userLoss: "",
  userWin: "",
  userMatches: "",
  fetchLoading: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    restoreDefault: (state) => {
      state.loading = false;
      state.registerSuccess = false;
      state.clubsLoading = false;
      state.emailVerificationSuccess = false;
      state.otpSuccess = false;
      state.userData = {};
      state.token = "";
      state.otpSuccess = false;
      state.isEvaluated = false;
      state.fetchLoading = false;
    },
    clearGoogleData: (state) => {
      state.googleAuthData = {};
    },
    resetError: (state) => {
      state.error = "";
      state.joinError = false;
    },
    resetInvitation: (state) => {
      state.joinTournamentSuccess = false;
      state.leaveTournamentSuccess = false;
    },
    setSingleUpcomingMatch: (state, action: PayloadAction<any>) => {
      state.singleUpcomingMatch = action.payload;
    },
    resetAll: (state) => {
      state.loading = false;
      state.registerSuccess = false;
      state.clubsLoading = false;
      state.userData = [];
      state.token = "";
    },
    resetRedirect: (state) => {
      state.loading = false;
      state.redirectUrl = "";
      state.joinStatus = false;
    },
    saveGoogleData: (state, action: PayloadAction<any>) => {
      state.googleAuthData = action.payload;
    },
    updateUserData: (state, action: PayloadAction<any>) => {
      state.userData = action.payload;
    },
    saveRedirectUrl: (state, action: PayloadAction<any>) => {
      state.redirectUrl = action.payload;
    },
    resetJoinStatus: (state) => {
      state.joinStatus = false;
    },
    resetEvaluationSuccess: (state) => {
      state.evalutionSuccess = false;
    },
    resetProfileUpdateSuccess: (state) => {
      state.profileUpdateSuccess = false;
    },
    resetForgetEmailVerification: (state) => {
      state.forgotEmailVerificationSuccess = false;
    },
    resetForgetToken: (state) => {
      state.forgetPasswordToken = "";
    },
    resetForgetPasswordOtpSuccess: (state) => {
      state.forgotPasswordOtpSuccess = false;
    },
    resetNewPasswordSuccess: (state) => {
      state.newPasswordSuccess = false;
    },
    setIsEvaluated: (state) => {
      state.isEvaluated = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClubs.pending, (state) => {
        state.clubsLoading = true;
      })
      .addCase(fetchClubs.fulfilled, (state, { payload }) => {
        state.clubsLoading = false;
        state.allClubs = payload?.data;
      })
      .addCase(fetchClubs.rejected, (state, { payload }) => {
        state.clubsLoading = false;
        state.error = payload;
      })
      .addCase(fetchQuotes.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchQuotes.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allQoutes = payload?.data;
      })
      .addCase(fetchQuotes.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userData = payload;
        state.token = payload.token;
        state.registerSuccess = true;
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.registerSuccess = false;
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userData = payload;
        state.token = payload.token;
        state.registerSuccess = true;
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.registerSuccess = false;
      })
      .addCase(verifyUserEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyUserEmail.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.emailVerificationSuccess = true;
      })
      .addCase(verifyUserEmail.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.emailVerificationSuccess = false;
      })
      .addCase(verifyUserOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyUserOtp.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.otpSuccess = true;
      })
      .addCase(verifyUserOtp.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.otpSuccess = false;
      })
      .addCase(getInvitations.pending, (state) => {
        state.loading = true;
        state.fetchLoading = true;
      })
      .addCase(getInvitations.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allInvitations = payload?.data;
        state.fetchLoading = false;
      })
      .addCase(getInvitations.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.allInvitations = [];
        state.fetchLoading = false;
      })
      .addCase(getAllMatches.pending, (state) => {
        state.loading = true;
        state.fetchLoading = true;
      })
      .addCase(getAllMatches.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allMatches = payload?.data;
        state.fetchLoading = false;
      })
      .addCase(getAllMatches.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.fetchLoading = false;
        state.allMatches = [];
      })
      .addCase(getMyMatches.pending, (state) => {
        state.loading = true;
        state.fetchLoading = true;
      })
      .addCase(getMyMatches.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.myMatches = payload?.data;
        state.fetchLoading = false;
      })
      .addCase(getMyMatches.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.myMatches = [];
        state.fetchLoading = false;
      })
      .addCase(getClubDetail.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.clubDetail = {};
      })
      .addCase(getClubDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClubDetail.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.clubDetail = payload?.data[0];
        state.profileData = payload?.data;
        state.userWin = payload?.win;
        state.userLoss = payload?.loss;
        state.userMatches = payload?.matches;
      })
      .addCase(joinTournament.pending, (state) => {
        state.loading = true;
      })
      .addCase(joinTournament.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.joinTournamentSuccess = true;
      })
      .addCase(joinTournament.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.joinTournamentSuccess = false;
      })
      .addCase(joinCompetition.pending, (state) => {
        state.loading = true;
      })
      .addCase(joinCompetition.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.joinStatus = true;
      })
      .addCase(joinCompetition.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.joinStatus = false;
        state.joinError = true;
      })
      .addCase(leaveTournament.pending, (state) => {
        state.loading = true;
      })
      .addCase(leaveTournament.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.leaveTournamentSuccess = true;
      })
      .addCase(leaveTournament.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.leaveTournamentSuccess = false;
      })
      .addCase(verifyForgotPasswordEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyForgotPasswordEmail.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.forgotEmailVerificationSuccess = true;
      })
      .addCase(verifyForgotPasswordEmail.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.forgotEmailVerificationSuccess = false;
      })
      .addCase(verifyForgotOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyForgotOtp.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.forgotPasswordOtpSuccess = true;
        state.forgetPasswordToken = payload?.token;
      })
      .addCase(verifyForgotOtp.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.forgotPasswordOtpSuccess = false;
        state.forgetPasswordToken = "";
      })
      .addCase(verifyNewPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyNewPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.newPasswordSuccess = true;
      })
      .addCase(verifyNewPassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.newPasswordSuccess = false;
      })
      .addCase(evaluatePlayer.pending, (state) => {
        state.loading = true;
      })
      .addCase(evaluatePlayer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.evalutionSuccess = true;
      })
      .addCase(evaluatePlayer.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.evalutionSuccess = false;
      })
      .addCase(updateProfileDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProfileDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.profileUpdateSuccess = true;
      })
      .addCase(updateProfileDetails.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.profileUpdateSuccess = false;
      })
      .addCase(getProfile.pending, (state) => {
        state.loading = true;
        state.fetchLoading = true;
      })
      .addCase(getProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.profileData = payload?.data;
        state.userWin = payload?.win;
        state.userLoss = payload?.loss;
        state.userMatches = payload?.matches;
        state.fetchLoading = false;
      })
      .addCase(getProfile.rejected, (state, { payload }) => {
        state.loading = false;
        state.fetchLoading = false;
      })
      .addCase(getEvaluationStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEvaluationStatus.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isEvaluated = true;
      })
      .addCase(getEvaluationStatus.rejected, (state, { payload }) => {
        state.loading = false;
        state.isEvaluated = false;
      });
  },
});

export const fetchClubs = createAsyncThunk(
  "allClubs",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await APIService.get(`${url.clubs}`);
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);
export const fetchQuotes = createAsyncThunk(
  "allQuotes",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await APIService.get(`${url.quote}`);
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const registerUser = createAsyncThunk(
  "registerUser",
  async (payload: any, { rejectWithValue }) => {
    try {
      if (payload.password && !payload.uid) {
        const { data } = await APIService.post(`${url.register}`, payload);
        return data;
      } else {
        const { data } = await APIService.post(
          `${url.googleRegister}`,
          payload
        );
        return data;
      }
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const loginUser = createAsyncThunk(
  "loginUser",
  async (payload: any, { rejectWithValue }) => {
    try {
      if (payload.password) {
        const { data } = await APIService.post(`${url.login}`, payload);
        return data;
      } else {
        const { data } = await APIService.post(`${url.googleLogin}`, payload);
        // if (data) {
        //   window.location.replace("/home"); //consider changing to useNavigate()
        // }
        return data;
      }
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const verifyUserEmail = createAsyncThunk(
  "verifyUserEmail",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.verifyUserEmail}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const verifyForgotOtp = createAsyncThunk(
  "verifyForgotOtp",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.forgetOtp}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const verifyForgotPasswordEmail = createAsyncThunk(
  "verifyForgotPasswordEmail",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.forgotPassword}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const verifyUserOtp = createAsyncThunk(
  "verifyUserOtp",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();
    try {
      const { data } = await APIService.post(
        `${url.userOtpVerification}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const verifyNewPassword = createAsyncThunk(
  "verifyNewPassword",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();
    try {
      const { data } = await APIService.put(`${url.newPassword}`, payload, {
        headers: {
          Authorization: `Bearer ${auth.forgetPasswordToken}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const verifyForgotPaswordOtp = createAsyncThunk(
  "verifyForgotPasswordOtp",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();
    try {
      const { data } = await APIService.post(
        `${url.userOtpVerification}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getInvitations = createAsyncThunk(
  "getInvitations",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();
    try {
      const { data } = await APIService.get(`${url.getInvitations}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getEvaluationStatus = createAsyncThunk(
  "getEvaluationStatus",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();
    try {
      const { data } = await APIService.get(`${url.evaluation}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getProfile = createAsyncThunk(
  "getProfile",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();
    try {
      const { data } = await APIService.get(`${url.profile}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getAllMatches = createAsyncThunk(
  "getAllMatches",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();
    try {
      const { data } = await APIService.get(`${url.allMatches}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const getMyMatches = createAsyncThunk(
  "getMyMatches",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();
    try {
      const { data } = await APIService.get(`${url.myMatches}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const getClubDetail = createAsyncThunk(
  "getClubDetail",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();
    try {
      const { data } = await APIService.get(`${url.profile}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const joinTournament = createAsyncThunk(
  "joinTournament",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();
    try {
      const { data } = await APIService.post(`${url.joinTournament}`, payload, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const joinCompetition = createAsyncThunk(
  "joinCompetion",
  async (payload: any, { rejectWithValue, getState, dispatch }) => {
    const { auth }: any = getState();

    try {
      let req = {
        method: "post",
        address: `${url.joinComp}/${payload.id}/join`,
        header: `Authorization: Bearer ${auth?.token}`,
      };
      const { data } = await nAPIService(req.method, req.address, req.header);

      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const leaveTournament = createAsyncThunk(
  "leaveTournament",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();
    try {
      const { data } = await APIService.post(
        `${url.leaveTournament}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const updateProfileDetails = createAsyncThunk(
  "updateProfileDetails",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();
    try {
      const { data } = await APIService.put(`${url.profileUpdate}`, payload, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const evaluatePlayer = createAsyncThunk(
  "evaluatePlayer",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();
    try {
      const { data } = await APIService.post(`${url.evaluation}`, payload, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const authSelector = (state: any) => state.auth;

export const {
  restoreDefault,
  resetAll,
  saveGoogleData,
  clearGoogleData,
  resetInvitation,
  saveRedirectUrl,
  resetRedirect,
  resetJoinStatus,
  resetError,
  resetEvaluationSuccess,
  resetForgetEmailVerification,
  resetForgetToken,
  resetNewPasswordSuccess,
  resetForgetPasswordOtpSuccess,
  resetProfileUpdateSuccess,
  updateUserData,
  setSingleUpcomingMatch,
  setIsEvaluated,
} = authSlice.actions;
export default authSlice.reducer;
