export const dummyTeamData = [
  {
    name: "Venus Team",
    members: [
      {
        name: "John Doe",
        email: "Johndoe@gmail.com",
        club: "Lagos club",
        pts: 80,
        matches: 12,
      },
      {
        name: "Jane Smith",
        email: "janesmith@gmail.com",
        club: "Lagos club",
        pts: 75,
        matches: 11,
      },
      {
        name: "Alice Johnson",
        email: "alicejohnson@gmail.com",
        club: "Lagos club",
        pts: 85,
        matches: 13,
      },
      {
        name: "Bob Brown",
        email: "bobbrown@gmail.com",
        club: "Lagos club",
        pts: 70,
        matches: 10,
      },
    ],
  },
  {
    name: "Mars Team",
    members: [
      {
        name: "Charlie Green",
        email: "charliegreen@gmail.com",
        club: "Abuja club",
        pts: 90,
        matches: 14,
      },
      {
        name: "Diana White",
        email: "dianawhite@gmail.com",
        club: "Abuja club",
        pts: 88,
        matches: 12,
      },
      {
        name: "Evan Black",
        email: "evanblack@gmail.com",
        club: "Abuja club",
        pts: 78,
        matches: 11,
      },
      {
        name: "Fiona Grey",
        email: "fionagrey@gmail.com",
        club: "Abuja club",
        pts: 85,
        matches: 13,
      },
    ],
  },
  {
    name: "Jupiter Team",
    members: [
      {
        name: "George King",
        email: "georgeking@gmail.com",
        club: "Kano club",
        pts: 95,
        matches: 15,
      },
      {
        name: "Helen Queen",
        email: "helenqueen@gmail.com",
        club: "Kano club",
        pts: 80,
        matches: 13,
      },
      {
        name: "Ian Prince",
        email: "ianprince@gmail.com",
        club: "Kano club",
        pts: 85,
        matches: 14,
      },
      {
        name: "Julia Knight",
        email: "juliaknight@gmail.com",
        club: "Kano club",
        pts: 75,
        matches: 12,
      },
    ],
  },
  {
    name: "Saturn Team",
    members: [
      {
        name: "Kevin Hill",
        email: "kevinhill@gmail.com",
        club: "Port Harcourt club",
        pts: 88,
        matches: 12,
      },
      {
        name: "Laura Valley",
        email: "lauravalley@gmail.com",
        club: "Port Harcourt club",
        pts: 92,
        matches: 14,
      },
      {
        name: "Mike Brook",
        email: "mikebrook@gmail.com",
        club: "Port Harcourt club",
        pts: 86,
        matches: 13,
      },
      {
        name: "Nina River",
        email: "ninariver@gmail.com",
        club: "Port Harcourt club",
        pts: 80,
        matches: 11,
      },
    ],
  },
  {
    name: "Neptune Team",
    members: [
      {
        name: "Oscar Lake",
        email: "oscarlake@gmail.com",
        club: "Enugu club",
        pts: 85,
        matches: 13,
      },
      {
        name: "Paula Cliff",
        email: "paulacliff@gmail.com",
        club: "Enugu club",
        pts: 87,
        matches: 12,
      },
      {
        name: "Quincy Beach",
        email: "quincybeach@gmail.com",
        club: "Enugu club",
        pts: 82,
        matches: 11,
      },
      {
        name: "Rachel Sand",
        email: "rachelsand@gmail.com",
        club: "Enugu club",
        pts: 90,
        matches: 14,
      },
    ],
  },
];

export const dummyTeamValues = [
  {
    name: "Mercury Team",
    pts: 100,
    wins: 10,
    losses: 0,
    matches: 10,
  },
  {
    name: "Venus Team",
    pts: 90,
    wins: 9,
    losses: 1,
    matches: 10,
  },
  {
    name: "Earth Team",
    pts: 80,
    wins: 8,
    losses: 2,
    matches: 10,
  },
  {
    name: "Mars Team",
    pts: 70,
    wins: 7,
    losses: 3,
    matches: 10,
  },
  {
    name: "Jupiter Team",
    pts: 60,
    wins: 6,
    losses: 4,
    matches: 10,
  },
];
export const randomNames = [
  "John Doe",
  "Jane Smith",
  "Michael Johnson",
  "Emily Brown",
  "David Davis",
  "Sarah Taylor",
  "James Wilson",
  "Jennifer Martinez",
  "Robert Anderson",
  "Mary Garcia",
  "William Hernandez",
  "Linda Jackson",
  "Christopher White",
  "Patricia Harris",
  "Daniel Thomas",
  "Elizabeth Clark",
  "Joseph Lewis",
  "Karen Young",
  "Steven Turner",
  "Nancy Rodriguez",
];

export const LadderTournamentData = [
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL AYENI",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL ",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL ",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
  {
    name: "DANIEL ",
    p: "0",
    w: "0",
    l: "0",
    pa: "0",
    wd: "0",
    pts: "0",
  },
];
