import { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { LoginSvg } from "../../../assets/svgs";
import { CustomLoginBtn, GoogleAuthBtn } from "../../../components/Button";
import { BigText, Ptext } from "../../../components/Text";
import { saveGoogleData } from "../../../features/auth/AuthSlice";
import { setCurrentUser } from "../../../features/auth/UtilitySlice";
import { signInWithGoogle } from "../../../firebase";
const SignupOptions = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const clubIdParams = searchParams.get("clubid");
  const inviteCodeParams = searchParams.get("invitecode");

  useEffect(() => {
    if (auth.userData?.success) {
      dispatch(setCurrentUser(auth.userData));
      if (auth.userData) toast.success("Login Successful");
      navigate("/home");
    } else navigate("/users");
  }, [auth.registerSuccess]);

  const customSignup = () => {
    // dispatch(clearGoogleData());
    window.alert(
      "this feature is currently not available please use the other authentication method"
    );
    // clubIdParams && inviteCodeParams
    //   ? navigate(
    //       `/new-register?clubid=${clubIdParams}&invitecode=${inviteCodeParams}`
    //     )
    //   : navigate("/new-register");
  };

  const googleSignUp = async () => {
    const response = await signInWithGoogle();

    if (response) {
      dispatch(saveGoogleData(response));
      clubIdParams && inviteCodeParams
        ? navigate(
            `/new-register?clubid=${clubIdParams}&invitecode=${inviteCodeParams}`
          )
        : navigate("/new-register");
    }
  };

  return (
    <>
      {/* <div className=" flex-col lg:flex hidden mt-6 px-4 justify-center h-screen w-1/2 mx-auto">
        <h1 className="font-bold text-customBlack text-xl text-center mb-2 ">
          Oops, this page is only designed for mobile devices.
        </h1>
        <p className="text-neutral-700 text-base  ">
          Please switch to a smaller screen, such as a smartphone or tablet, to
          enjoy this feature. We appreciate your cooperation.
        </p>
      </div> */}
      <div className="w-full h-screen relative bg-white flow-hide md:max-w-[428px] md:mx-auto md:h-screen">
        <div
          className="mt-[40px] flex flex-col px-8 md:w-96"
          style={{ zIndex: 10 }}
        >
          <div className="flex justify-center w-full">
            <LoginSvg />
          </div>
          <BigText text="Join TennisLove" className="text-center mt-12" />
          {/* <Ptext text="How would you want to Sign-up" className="text-center" /> */}

          <div className="flex items-center justify-center mt-8 z-5">
            <GoogleAuthBtn
              name="Sign up with Google"
              active={true}
              onClick={googleSignUp}
              className="z-5"
            />
          </div>
          <div className="flex items-center justify-center mt-4 z-5">
            <CustomLoginBtn
              name="Sign up with Email"
              active={true}
              onClick={customSignup}
              className="z-5"
            />
          </div>
          {/* Log In */}

          <div className="flex items-center justify-center mt-4 z-5">
            <hr className="w-64 h-px my-8 bg-text-gray-900 border dark:bg-neutral-400" />
          </div>

          {/* Sign Up */}
          {/* <div className="flex items-center justify-center mt-4 z-5">
            <CustomLoginBtn
              name="Log in with Email"
              active={true}
              onClick={customLogIn}
              className="z-5"
            />
          </div> */}

          {/* <div className="flex items-center justify-center mt-4 z-5">
            <GoogleAuthBtn
              name="Log in with Google"
              active={true}
              onClick={googleLogIn}
              className="z-5"
            />
          </div> */}

          <div className="flex items-center justify-center mt-0 z-5">
            <Ptext text="Already have an account? " />
            <Link
              to="/user/login"
              className="text-[14px] text-primaryYellow ml-1"
            >
              Log In
            </Link>
          </div>
          <div className="flex items-center justify-center mt-4 z-5 ">
            <Ptext text="Forgot email or trouble signin in? " />
            <Link to="/users" className="text-[14px] text-primaryYellow ml-1">
              Get help
            </Link>
          </div>
          <p className="text-[14px]  font-normal leading-[24px] text-[#868686] mt-8">
            By signing up, you agree to our{" "}
            <span className="text-primaryYellow underline">
              Terms of Service
            </span>{" "}
            and acknowledge that our{" "}
            <span className="text-primaryYellow underline">Privacy Policy</span>{" "}
            applies to you.
          </p>
        </div>

        {/* <div className="absolute -bottom-12 w-full  " style={{ zIndex: 0 }}>
          <img
            src={underlay}
            alt="underlay"
            className="w-full h-auto mx-auto opacity-20 "
          />
        </div> */}
      </div>
    </>
  );
};

export default SignupOptions;
