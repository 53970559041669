import { BiHomeSmile } from "react-icons/bi";
import { TbBallTennis } from "react-icons/tb";
import { FiUsers } from "react-icons/fi";
import { RiUser3Line } from "react-icons/ri";
import { TbSoccerField } from "react-icons/tb";
import { CiShop } from "react-icons/ci";
import { FaBirthdayCake } from "react-icons/fa";
import { IoMdNotificationsOutline } from "react-icons/io";
import { BigText, Ptext } from "../components/Text";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ReUseModal from "../components/Modal/ReUseModal";
import Canopy from "../assets/png/deco.png";
import Deco from "../assets/png/deco-2.png";
import Celebration from "../assets/png/celebration.gif";
import Cake from "../assets/png/cake.png";
import { setCookie, getCookie } from "../utils/cookieUtil";
import { useAppSelector } from "../app/hooks";
import { CompetitionIcon } from "../assets/svgs/club";
import { PageTitle } from "../components/Text/new-text";
interface GeneralLayoutProps {
  children: any;
  active?: number;
  name: string;
  greetings?: string;
  showTopSection?: boolean;
  childContainerClass?: string;
}
const GeneralLayout = ({
  children,
  active,
  name,
  greetings,
  showTopSection = true,
  childContainerClass = "flex h-[90vh] md:h-[75vh] flow-hide mt-28 md:mt-2",
}: GeneralLayoutProps) => {
  const user = useAppSelector((state) => state.auth);
  const dateOfBirth = user?.profileData?.dateOfBirth;

  const navigate = useNavigate();
  const [birthdayNotification, setBirthdayNotification] = useState(false);
  useEffect(() => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);

    const isBirthday =
      today.getDate() === birthDate.getUTCDate() &&
      today.getMonth() === birthDate.getUTCMonth();

    const hasSeenBirthdayModal = getCookie("birthdayModalSeen");

    if (isBirthday && !hasSeenBirthdayModal) {
      setBirthdayNotification(true);
      setCookie("birthdayModalSeen", "true", 1); // Cookie expires in 24 hours
    }
  }, []);
  return (
    <div className="flex flex-col ">
      <div className="w-full h-full relative overflow-y-hidden md:h-screen md:max-w-[428px] md:mx-auto ">
        {showTopSection && (
          <div className="fixed top-0 h-[15vh] max-h-28  right-0 left-0 flex items-center px-4 justify-between bg-white md:relative">
            <div className="flex flex-col">
              <PageTitle text={name} />
              {greetings && <Ptext text={`${greetings}`} className="mt-1" />}
            </div>
            <div className="flex items-center space-x-4">
              {/* <div className="not-box">
                <CiShop className="text-lemonGreen text-2xl" />
              </div> */}
              <div
                className="not-box"
                onClick={() => navigate("/all-invitations")}
              >
                <IoMdNotificationsOutline className="text-lemonGreen text-2xl" />
              </div>
            </div>
          </div>
        )}
        <div className={childContainerClass}>{children}</div>

        <div className="shade max-h-24 fixed bottom-0 right-0 left-0 h-[10vh] px-4 flex justify-between pt-3 bg-white md:relative">
          <div
            className="flex flex-col items-center "
            onClick={() => navigate("/home")}
          >
            <BiHomeSmile
              className={
                active === 1
                  ? "text-lemonGreen text-2xl "
                  : " text-2xl text-footer"
              }
            />
            <p
              className={
                active === 1
                  ? "text-footerText text-[12px]"
                  : "text-footer text-[12px]"
              }
            >
              Home
            </p>
          </div>
          {/* end of the home */}
          <div
            className="flex flex-col items-center "
            onClick={() => navigate("/matches")}
          >
            <TbBallTennis
              className={
                active === 2
                  ? "text-lemonGreen text-2xl "
                  : " text-2xl text-footer"
              }
            />
            <p
              className={
                active === 2
                  ? "text-footerText text-[12px]"
                  : "text-footer text-[12px]"
              }
            >
              Matches
            </p>
          </div>
          {/* end of the home */}
          <div
            className="flex flex-col items-center "
            onClick={() => navigate("/clubs")}
          >
            <TbSoccerField
              className={
                active === 3
                  ? "text-lemonGreen text-2xl "
                  : " text-2xl text-footer"
              }
            />
            <p
              className={
                active === 3
                  ? "text-footerText text-[12px]"
                  : "text-footer text-[12px]"
              }
            >
              Clubs
            </p>
          </div>
          {/* end of the home */}
          <div
            className="flex flex-col items-center "
            onClick={() => navigate("/competition")}
          >
            <CompetitionIcon color={active === 4 ? "#9CD326 " : " #cfcfcf"} />
            <p
              className={
                active === 4
                  ? "text-footerText text-[12px]"
                  : "text-footer text-[12px]"
              }
            >
              Competition
            </p>
          </div>
          {/* end of the home */}
          <div
            className="flex flex-col items-center "
            onClick={() => navigate("/profile")}
          >
            <RiUser3Line
              className={
                active === 5
                  ? "text-lemonGreen text-2xl "
                  : " text-2xl text-footer"
              }
            />
            <p
              className={
                active === 5
                  ? "text-footerText text-[12px]"
                  : "text-footer text-[12px]"
              }
            >
              Profile
            </p>
          </div>
          {/* end of the home */}
        </div>
      </div>
      <ReUseModal open={birthdayNotification} setOpen={setBirthdayNotification}>
        <div>
          <div
            style={{
              backgroundImage: `url(${Celebration})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div>
              <div className="absolute left-0 top-0">
                <img src={Canopy} alt="" />
              </div>
              <div className="absolute right-0 top-0">
                <img src={Deco} alt="" />
              </div>
              <div className="mt-8">
                <img src={Cake} alt="" className="mx-auto" />
                <h2 className="text-center font-bold text-lg mt-2.5">
                  Happy Birthday!
                </h2>
                <p className="text-sm font-normal text-neutral-500 text-center mt-1.5">
                  May all your wishes come true.
                </p>
              </div>
              <div className="my-3.5 text-center">
                <button
                  className="bg-lemonGreen outline-none text-white rounded-2xl font-semibold text-base px-16 py-3 z-10"
                  onClick={() => setBirthdayNotification(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </ReUseModal>
    </div>
  );
};

export default GeneralLayout;
