import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { ClubBall } from "../../assets/svgs/club";
import { Btn } from "../../components/Button";
import { BigText } from "../../components/Text";
const ChallengePlayer = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <div className=" flex-col lg:flex hidden mt-6 px-4 justify-center h-screen w-1/2 mx-auto">
        <h1 className="font-bold text-customBlack text-xl text-center mb-2 ">
          Oops, this page is only designed for mobile devices.
        </h1>
        <p className="text-neutral-700 text-base  ">
          Please switch to a smaller screen, such as a smartphone or tablet, to
          enjoy this feature. We appreciate your cooperation.
        </p>
      </div> */}
      <div className="w-full h-full min-h-screen flex items-center justify-center md:max-w-[428px] md:mx-auto md:h-screen">
        <div className="w-9/12 flex flex-col">
          <BigText
            text="Select Ladder to
          Challenge from"
          />
          <div className="flex flex-col w-full space-y-3 mt-4">
            <div
              className="w-full h-20 border-border border rounded-md flex items-center justify-between px-4 "
              onClick={() => navigate("/selected")}
            >
              <div className="flex items-center space-x-3">
                <ClubBall />
                <div className="flex flex-col">
                  <p className="text-[14px] text-black p-0 m-0 font-normal">
                    Mens Championship
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <IoIosArrowForward className="text-2xl text-lemonGreen" />
              </div>
            </div>
            <div
              className="w-full h-20 border-border border rounded-md flex items-center justify-between px-4 "
              onClick={() => navigate("/selected")}
            >
              <div className="flex items-center space-x-3">
                <ClubBall />
                <div className="flex flex-col">
                  <p className="text-[14px] text-black p-0 m-0 font-normal">
                    Mens Championship
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <IoIosArrowForward className="text-2xl text-lemonGreen" />
              </div>
            </div>
            <div
              className="w-full h-20 border-border border rounded-md flex items-center justify-between px-4 "
              onClick={() => navigate("/selected")}
            >
              <div className="flex items-center space-x-3">
                <ClubBall />
                <div className="flex flex-col">
                  <p className="text-[14px] text-black p-0 m-0 font-normal">
                    Mens Championship
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <IoIosArrowForward className="text-2xl text-lemonGreen" />
              </div>
            </div>
          </div>
          <Btn
            name="close"
            active={true}
            onClick={() => navigate(-1)}
            className="mt-10"
          />
        </div>
      </div>
    </>
  );
};

export default ChallengePlayer;
