import { ClubBall, JoinClub } from "../../assets/svgs/club";
import { formatDate } from "../../utils";
import { useNavigate } from "react-router-dom";
interface singleCard {
  item: any;
  isJoin: boolean;
}
const SingleCardUser = ({ item, isJoin }: singleCard) => {
  const navigate = useNavigate();
  const hasDeadlinePassed = (deadline: string) => {
    // Convert deadline string to Date object
    const deadlineDate = new Date(deadline);
    // Get current date and time
    const currentDate = new Date();

    // Check if current date is after the deadline
    return currentDate > deadlineDate;
  };

  return (
    <div className="w-full h-20 border-border border rounded-md flex items-center justify-between px-4 ">
      <div className="flex items-center space-x-3">
        <ClubBall />
        <div className="flex flex-col">
          <p className="text-[14px] text-black p-0 m-0 font-normal truncate capitalize">
            {item?.name}
          </p>
          <p className="text-[12px] text-[#acacac]">
            Starts {formatDate(item?.start)}
          </p>
        </div>
      </div>
      {hasDeadlinePassed(item?.deadline) ? (
        <div></div>
      ) : (
        <div
          className="flex items-center"
          onClick={() => navigate(`/view-invitation/ladder/${item?._id}`)}
        >
          <JoinClub />
        </div>
      )}
    </div>
  );
};

export default SingleCardUser;
