import { BsBellFill } from "react-icons/bs";
import ProfileDropdown from "../Navbars/ProfileDropdown";
const HeadNav = () => {
  return (
    <div className="w-full h-[63px] sticky top-0 flex items-center justify-between bg-white px-6 py-6">
      {/* <div className="flex items-center space-x-4 h-[32px] rounded-xl  bg-[#f4f4f4] px-4 py-2 w-4/12  ">
        <input
          type="text"
          className="flex-grow border-none outline-none  text-base  bg-inherit  placeholder:text-sm placeholder:text-[#866666] text-customBlack "
          placeholder="Search"
        />
        <FiSearch className="text-[#868686]" />
      </div> */}

      {/* add absolute top-5 right-5 ml-96 to the profile drop down   */}
      <div className="flex absolute top-5 right-5 ml-96 items-center w-4/12 justify-end space-x-8">
        <ProfileDropdown />
        <div className="flex">
          <span className="inline-block relative cursor-pointer">
            <BsBellFill className="text-[#B0C3CC] text-2xl" />
            <span className="absolute top-0 right-1 block h-2 w-2 rounded-full ring-2 ring-white bg-red-500"></span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default HeadNav;
