import DashboardLayout from "../../../Layouts/DashboardLayout";
import { AddCircle } from "../../../assets/svgs/tournament";
import AllCompetitionTables from "../../../components/Tables/AdminTables/TournamentTables/AllCompetitionTable";
import AllKnockoutTables from "../../../components/Tables/AdminTables/TournamentTables/AllKnockoutTables";
import AllLadderTables from "../../../components/Tables/AdminTables/TournamentTables/AllLadderTable";
import { useNavigate } from "react-router-dom";
const TournamentPage = () => {
  const navigate = useNavigate();
  return (
    <DashboardLayout current={3}>
      <div className="w-full bg-[#f4f4f4] flex flex-col p-[30px] 2xl:p-8">
        <div className="flex w-full justify-between items-center">
          <h1 className="text-lg font-[600] text-customBlack">Tournament</h1>
          <div
            className="flex items-center space-x-2 cursor-pointer bg-white p-[10px] rounded-[5px]"
            onClick={() => navigate("/admin/create-tournament")}
          >
            <AddCircle />

            <p className="text-sm text-customBlack font-[500]">
              Create new tournament
            </p>
          </div>
        </div>
        <AllCompetitionTables className="mt-6" />
        <AllKnockoutTables className="mt-6" />
        <AllLadderTables className="mt-10" />
      </div>
    </DashboardLayout>
  );
};

export default TournamentPage;
