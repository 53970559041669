import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import GeneralLayout from "../../Layouts/GeneralLayout";
import clubT from "../../assets/png/club-t.png";
import { ChallengePlayer, ClubBall } from "../../assets/svgs/club";
import {
  ActiveLadderIcon,
  AllInvitationIcon,
  ChallengeInvitationIcon,
} from "../../assets/svgs/card-icons";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAllLadderUser } from "../../features/auth/ladder-slice";
import { useEffect, useState } from "react";
import { formatDate } from "../../utils";
import SingleCardUser from "../../components/Club/SingleCardUser";
import LinkCard from "../../components/cards/link-card";
import LoadingComponent from "../../components/Loading/Loader";
const Clubs = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ladder = useAppSelector((state) => state.ladder);
  const auth = useAppSelector((state) => state.auth);

  const [allLadders, setAllLadders] = useState(ladder?.allLaddersUser);
  const [activeLadders, setActiveLadders] = useState<any[]>([]);
  const [availableLadders, setAvailableLadders] = useState<any[]>([]);
  const [availableTournaments, setAvailableTournaments] = useState<any[]>([]);
  const userId = auth?.userData?.user?._id;

  useEffect(() => {
    dispatch(getAllLadderUser());
  }, []);

  useEffect(() => {
    const userId = auth?.userData?.user?._id;
    const active: any[] = [];
    const available: any[] = [];

    allLadders?.forEach((ladder: any) => {
      if (ladder?.players?.includes(userId)) {
        active.push(ladder);
      } else {
        available.push(ladder);
      }
    });

    setActiveLadders(active);
    setAvailableLadders(available);
  }, [allLadders, ladder?.allLadders, userId]);

  const filterItemsByDeadline = () => {
    // Get current date and time
    const currentDate = new Date();

    // Filter out items whose deadline has not passed
    const filteredItems = availableLadders.filter((item) => {
      // Convert item deadline string to Date object
      const deadlineDate = new Date(item.deadline);
      // Check if current date is before the item's deadline
      return currentDate <= deadlineDate;
    });

    setAvailableTournaments(filteredItems);
  };

  useEffect(() => {
    if (availableLadders?.length > 0) {
      filterItemsByDeadline();
    }
  }, [availableLadders]);

  return (
    <GeneralLayout active={3} name="My Club">
      <div className="w-full flow-hide flex flex-col px-4 pb-40 overflow-x-hidden md:w-[450px]">
        <div className="profile-hero-bg rounded-[24px] flex flex-col justify-center px-6 min-w-full py-8 ">
          <div className="flex space-x-4">
            <img src={clubT} alt="profile logo" />
            <div className="flex flex-col">
              <p className="text-white text-base font-bold mb-1 capitalize">
                {auth?.userData?.user?._club?.name}
              </p>
              {/* <p className="text-xs text-white mb-2">524 Members</p> */}

              <ChallengePlayer />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col  ">
          <h1 className="text-[#2C2C2C] text-sm mt-4 ">Quick Action</h1>
          <div className="w-full gap-4 grid grid-cols-2 mt-4">
            <LinkCard
              name="Active Ladder"
              onClick={() => navigate("/active-ladders")}
              icon={<ActiveLadderIcon />}
            />
            <LinkCard
              name="Challenge Invitation"
              onClick={() => navigate("/all-challenge-invitations")}
              icon={<ChallengeInvitationIcon />}
            />
            <LinkCard
              name="Match Score"
              onClick={() => navigate("/week-score")}
              icon={<ChallengeInvitationIcon />}
            />
            <LinkCard
              name="Book a Court"
              onClick={() => navigate("/find-court")}
              icon={<AllInvitationIcon />}
            />
          </div>
        </div>
        {ladder?.fetchLoading ? (
          <div className="px-4 mt-8">
            <LoadingComponent />
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="mt-12">
              <p className="text-md font-semibold text-[#2c2c2c] mb-4">
                Active Ladders
              </p>
              <div className="flex flex-col w-full space-y-2">
                {activeLadders?.length === 0 && (
                  <p className="text-nuetral-700 text-sm font-medium">
                    No Active Ladders
                  </p>
                )}
                {activeLadders?.length > 0 &&
                  activeLadders?.map((item: any, index: number) => {
                    return (
                      <div
                        className="w-full h-20 border-border border rounded-md flex items-center justify-between px-4 cursor-pointer "
                        key={index}
                        onClick={() => navigate(`/selected-ladder/${item._id}`)}
                      >
                        <div className="flex items-center space-x-3">
                          <ClubBall />
                          <div className="flex flex-col">
                            <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                              {item?.name}
                            </p>
                            <p className="text-[12px] text-[#acacac]">
                              Starts {formatDate(item?.start)}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <IoIosArrowForward className="text-2xl text-lemonGreen" />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="mt-4">
              <p className="text-md font-semibold text-[#2c2c2c] mb-4">
                Available Ladders
              </p>
              {availableTournaments?.length === 0 && (
                <p className="text-neutral-600  text-sm font-medium">
                  No Available Ladders
                </p>
              )}
              {availableTournaments?.length > 0 &&
                availableTournaments?.map((item: any, index: number) => {
                  return <SingleCardUser item={item} isJoin key={index} />;
                })}

              <div className="h-20 mt-6 w-full"></div>
            </div>
          </div>
        )}
      </div>
    </GeneralLayout>
  );
};

export default Clubs;
