import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../app/hooks";
import PrimarySelect from "../../Selects/PrimarySelect";
import SearchSelect from "../../Selects/SearchSelect";
import Toggler from "../../Togglers";
import { AdminDateTimeInput } from "../../Input/DateInput";

const SingleMatchFixture = ({
  allMatching,
  setAllMatching,
  index,
  playersA,
  playersB,
}: any) => {
  const tournament = useAppSelector((state) => state.tournament);
  const [selectedCourt, setSelectedCourt] = useState<any>("");
  const [selectedPerson, setSelectedPerson] = useState<any>("");
  const [selectedPersonB, setSelectedPersonB] = useState<any>("");
  const [selectedPersonC, setSelectedPersonC] = useState<any>("");
  const [selectedPersonD, setSelectedPersonD] = useState<any>("");
  const [date, setDate] = useState("");

  const [showdouble, setShowDouble] = useState(false);
  let data = {
    challenger: selectedPerson?._id,
    challenged: selectedPersonB?._id,
    court: selectedCourt?._id,
    time: date,
    id: index,
    isDouble: showdouble,
    challengerB: selectedPersonC?._id,
    challengedB: selectedPersonD?._id,
  };

  const saveNow = () => {
    if (selectedCourt && date && selectedPerson && selectedPersonB) {
      let findResult = allMatching.find((_: any, index: any) => {
        return index === data.id;
      });
      if (findResult) {
        let editData = allMatching.map((item: any, index: number) => {
          if (index === data.id) {
            return {
              challenger: data.challenger,
              challenged: data.challenged,
              court: data.court,
              time: data.time,
              challengerB: data.challengerB,
              challengedB: data.challengedB,
              id: data.id,
            };
          } else return item;
        });
        setAllMatching(editData);
      } else {
        let newArray = [...allMatching, data];
        setAllMatching(newArray);
      }
    } else {
      toast.error("Please input all fields");
    }
  };

  useEffect(() => {
    if (selectedCourt && date && selectedPerson && selectedPersonB) {
      saveNow();
    }
  }, [
    selectedCourt,
    date,
    selectedPerson,
    selectedPersonB,
    selectedPersonC,
    selectedPersonD,
    showdouble,
  ]);
  let allCourt = tournament?.allCourts;

  return (
    <div className="flex flex-col pb-3  pt-6 w-full ">
      {/* upper sessiom */}
      <div className="flex gap-x-6 items-center">
        {/* side a */}
        <div className="flex items-center space-x-3">
          <p className="text-neutral-700 text-sm min-w-max"> Player 1:</p>
          {/* <p className="outline-none h-[30px] rounded-[5px] border border-border w-[230px] px-2 ">
            {playerA ? playerA : "Ayeni Daniel"}
          </p> */}
          <span className="w-full max-w-[230px]">
            <SearchSelect
              options={playersA}
              setSelectedPerson={(e: any) => {
                setSelectedPerson(e);
              }}
              selectedPerson={selectedPerson}
            />
          </span>
        </div>
        {/* sideb */}
        <p className="text-xs text-customBlack font-semibold">VS</p>
        <div className="flex items-center space-x-3">
          <p className="text-neutral-700 text-sm min-w-max">
            {" "}
            Player <span className="">2</span>:
          </p>
          <span className="w-full max-w-[230px]">
            <SearchSelect
              options={playersB}
              setSelectedPerson={(opt: any) => {
                setSelectedPersonB(opt);
              }}
              selectedPerson={selectedPersonB}
            />
          </span>
        </div>
      </div>

      {/* show double */}
      {showdouble && (
        <div className="flex space-x-6 items-center mt-3">
          {/* side a */}
          <div className="flex items-center space-x-3">
            <p className="text-neutral-700 text-sm"> Player 3:</p>
            {/* <p className="outline-none h-[30px] rounded-[5px] border border-border w-[230px] px-2 ">
            {playerA ? playerA : "Ayeni Daniel"}
          </p> */}
            <span className="w-full max-w-[230px]">
              <SearchSelect
                options={playersA}
                setSelectedPerson={(e: any) => {
                  setSelectedPersonC(e);
                }}
                selectedPerson={selectedPersonC}
              />
            </span>
          </div>
          {/* sideb */}
          <p className="text-xs text-customBlack opacity-0">VS</p>
          <div className="flex items-center space-x-3">
            <p className="text-neutral-700 text-sm min-w-max"> Player 4:</p>
            <span className="w-full max-w-[230px]">
              <SearchSelect
                options={playersB}
                setSelectedPerson={(opt: any) => {
                  setSelectedPersonD(opt);
                }}
                selectedPerson={selectedPersonD}
              />
            </span>
          </div>
        </div>
      )}
      {/* END OF double */}
      {/* lower session ###################3 */}
      <div className="flex space-x-6 items-center mt-4 ">
        {/* start of a sec */}
        <div className="flex items-center gap-[26px] w-full max-w-[310px] ">
          <p className="text-neutral-600">Time:</p>
          <span className="w-full max-w-[230px]">
            <AdminDateTimeInput
              dateTime={date}
              // setDateTime={setDate}
              onChange={(e: any) => setDate(e.target.value)}
            />
          </span>
          {/* <input
            type="datetime-local"
            onChange={(e: any) => setDate(e.target.value)}
            className="outline-none h-[30px] rounded-[5px] border border-border w-[230px] px-2 "
          /> */}
        </div>

        {/* enf of a select */}
        <p className="text-xs text-customBlack opacity-0">VS</p>
        {/* start of a sec */}
        <div className="flex items-center space-x-3">
          <p className="text-neutral-600">Court:</p>
          <div className="w-[230px]">
            <PrimarySelect
              selected={selectedCourt}
              setSelected={setSelectedCourt}
              name="Select"
              data={allCourt}
              height="h-[30px] flex items-center bg-[#ffffff]"
              label=""
            />
          </div>
        </div>

        {/* enf of a select */}
        {/* <p className="text-customBlack bg-lemonGreen px-4 rounded-lg " onClick={saveNow}>
          save now
        </p> */}
      </div>
      <div className="flex   mt-4  items-center justify-between w-[310px] pr-4">
        <p className="text-neutral-600">Switch to Double</p>

        <Toggler enabled={showdouble} setEnabled={setShowDouble} />
      </div>
    </div>
  );
};

export default SingleMatchFixture;
