import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getAllCompetitionMatches } from "../../../features/auth/TournamentSlice";
import {
  SortIcon,
  LeftArrow,
  EmptyListIcon,
} from "../../../assets/new-svg/icons";
import LoadingComponent from "../../../components/Loading/Loader";
import { useNavigate } from "react-router-dom";
import { SectionTitle } from "../../../components/Text/new-text";
import MatchScoreCardCompetition from "../../../components/cards/match-score-card-competition";
const styles = {
  active:
    "bg-white text-sm text-black font-medium full flex items-center justify-center cursor-pointer h-[48px] tab-shadow rounded-[7px]  ",
  inactive:
    "text-sm text-[#616161] font-normal flex items-center justify-center w-full  h-[48px] rounded-[7px]",
};
const CompetitionMatchScore = () => {
  const dispatch = useAppDispatch();
  const tournament = useAppSelector((state) => state.tournament);
  const navigate = useNavigate();

  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    dispatch(getAllCompetitionMatches());
  }, []);
  const allMatches = tournament?.allCompetitionMatches;
  const allCompetitionMatches = tournament?.allCompetitionMatches;
  const upcomingMatches: any = allCompetitionMatches.map(
    (competition: any) => ({
      ...competition,
      matches: competition.matches.filter(
        (match: any) => match.winner === null
      ),
    })
  );

  const completedMatches: any = allCompetitionMatches.map(
    (competition: any) => ({
      ...competition,
      matches: competition.matches.filter(
        (match: any) => match.winner !== null
      ),
    })
  );

  if (tournament?.fetchLoading) {
    return (
      <div className="w-full md:max-w-[428px] md:mx-auto mt-4 px-4">
        <LoadingComponent />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col px-4 py-6 md:max-w-[428px] md:mx-auto">
      <div className="flex justify-between mt-6 items-center">
        <span
          onClick={() => navigate(-1)}
          className="w-[45px] h-[45px] bg-white rounded-[12px] flex items-center justify-center cursor-pointer nav-shadow"
        >
          <LeftArrow />
        </span>

        <SectionTitle text="Competition Matches" />
        <span className="w-[45px] h-[45px] bg-white rounded-[12px] flex items-center justify-center cursor-pointer nav-shadow">
          <SortIcon />
        </span>
      </div>
      <div className="flex flex-row w-full tounament-header-bg  h-[52px] items-center  mt-[30px]  bg-[#F3F4F3] p-[2px] rounded-[7px] ">
        <div className="w-1/2" onClick={() => setCompleted(false)}>
          <p className={completed ? styles.inactive : styles.active}>Ongoing</p>
        </div>
        <div className="w-1/2" onClick={() => setCompleted(true)}>
          <p className={completed ? styles.active : styles.inactive}>
            Completed
          </p>
        </div>
      </div>
      {completed ? (
        <div className="w-full">
          {completedMatches?.length === 0 && (
            <div className="flex flex-col mt-32 items-center justify-center">
              <span>
                <EmptyListIcon />
              </span>
              <p className="mt-2 text-secondary-400">
                All completed matches will be displayed here
              </p>
            </div>
          )}
          <div className="mt-6 flex flex-col gap-6">
            {completedMatches?.map((item: any, index: number) => (
              <MatchScoreCardCompetition
                item={item}
                index={index}
                key={index}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="w-full">
          {upcomingMatches?.length === 0 && (
            <div className="flex flex-col mt-32 items-center justify-center">
              <span>
                <EmptyListIcon />
              </span>
              <p className="mt-2 text-secondary-400">
                All upcoming matches will be displayed here
              </p>
            </div>
          )}
          <div className="mt-6 flex flex-col gap-6">
            {upcomingMatches?.map((item: any, index: number) => (
              <MatchScoreCardCompetition
                item={item}
                index={index}
                key={index}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CompetitionMatchScore;
