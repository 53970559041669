import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Award } from "../../assets/svgs/buddies";
import { convertDateTime, sortByDateField } from "../../utils";
import { getAllMatches } from "../../features/auth/AuthSlice";
import LoadingComponent from "../../components/Loading/Loader";
import ComboSelect from "../../components/Selects/ComboSelect";

const styles = {
  active:
    "bg-white text-sm text-customBlack font-[600] full flex items-center justify-center cursor-pointer h-[38px] active-t-btn  ",
  inactive:
    "text-sm text-[#afafaf] font-semibold flex items-center justify-center w-full  h-[42px]",
};

const WeekScoreBoard = () => {
  const navigate = useNavigate();
  const userSlice = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [completed, setCompleted] = useState(false);
  const [completedMatch, setCompletedMatch] = useState<any>([]);
  const [ongoingMatch, setOngoingMatch] = useState<any>([]);

  let allMatches = userSlice?.allMatches;

  useEffect(() => {
    if (userSlice.userData.success === undefined || false) {
      navigate("/users");
    }
    dispatch(getAllMatches());
  }, [userSlice.userData]);
  const filterNullAndUndefinedWinners = (arr: any) => {
    return arr.filter(
      (obj: { winner: null | undefined }) => obj.winner === null
    );
  };
  const filterWinners = (arr: any) => {
    return arr.filter((obj: any) => obj.winner !== null);
  };

  const sortedArray = sortByDateField(allMatches, {
    field: "time",
  });

  useEffect(() => {
    const onGoingMatch = filterNullAndUndefinedWinners(sortedArray);
    const groupedMatch = onGoingMatch.reduce((result: any, item: any) => {
      const tournamentId = item?._tournament?._id ?? "no_tournament_id";

      result[tournamentId] = result[tournamentId] || [];

      result[tournamentId].push(item);

      return result;
    }, []);

    const groupedData = Object.entries(groupedMatch).map(([id, group]) => ({
      id,
      group,
    }));
    setOngoingMatch(groupedData);
  }, []);

  useEffect(() => {
    const completedMatches = filterWinners(sortedArray);
    const groupedMatch = completedMatches.reduce((result: any, item: any) => {
      const tournamentId = item?._tournament?._id ?? "no_tournament_id";
      result[tournamentId] = result[tournamentId] || [];
      result[tournamentId].push(item);
      return result;
    }, {});

    const groupedData = Object.entries(groupedMatch).map(([id, group]) => ({
      id,
      group,
    }));

    setCompletedMatch(groupedData);
  }, []);

  if (userSlice?.fetchLoading) {
    return (
      <div className="w-full md:max-w-[428px] md:mx-auto mt-4 px-4">
        <LoadingComponent />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col px-4 py-6 md:max-w-[428px] md:mx-auto">
      <div className="flex justify-between mt-6 items-center">
        <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
        <p className="text-customBlack text-[16px] font-bold ">Match Scores</p>
        <div className="more-shadow opacity-0"></div>
      </div>
      <div className="flex flex-row w-full tounament-header-bg  h-[47px] items-center px-1 mt-[30px] mb-10 ">
        <div className="w-1/2" onClick={() => setCompleted(false)}>
          <p className={completed ? styles.inactive : styles.active}>Ongoing</p>
        </div>
        <div className="w-1/2" onClick={() => setCompleted(true)}>
          <p className={completed ? styles.active : styles.inactive}>
            Completed
          </p>
        </div>
      </div>

      <div>
        <div className="flex flex-col space-y-3 mt-3">
          {completed ? (
            <CompletedMatch completedMatch={completedMatch} />
          ) : (
            <OnGoingMatch ongoingMatch={ongoingMatch} />
          )}
        </div>
      </div>
    </div>
  );
};

export default WeekScoreBoard;

const CompletedMatch = ({ completedMatch }: any) => {
  const [selected, setSelected] = useState<{ id: string; name: string }>({
    id: "",
    name: "",
  });
  const [optionList, setOptionList] = useState<any>([]);
  const [renderedList, setRenderedList] = useState<any>(completedMatch);
  useEffect(() => {
    if (completedMatch?.length) {
      const list = completedMatch?.map((item: any) => {
        const data = { id: item?.id, name: item?.group[0]?._tournament?.name };
        return data;
      });
      setOptionList(list);
    }
  }, [completedMatch]);

  useEffect(() => {
    if (selected?.name) {
      const filteredList = completedMatch?.filter(
        (item: any) => item?.id === selected?.id
      );
      setRenderedList(filteredList);
    }
  }, [selected]);

  return (
    <div className="flex flex-col space-y-3">
      {!renderedList.length ? (
        <h1 className="text-center text-neutral-700 text-base mt-[30px] ">
          Your club has no completed matches yet!
        </h1>
      ) : (
        <div className="flex flex-col">
          <div className="w-full mb-4 flex flex-col">
            <p className="text-xs text-neutral-400">Search for a tournament</p>
            <ComboSelect
              options={optionList}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
          {renderedList.map((item: any) => {
            return (
              <div className="mb-6">
                <p className="text-customBlack text-sm font-semibold capitalize mb-2">
                  {item?.group[0]?._tournament?.name}
                </p>
                <div className=" border-border border rounded-[16px] p-4">
                  {item?.group?.map((item: any) => {
                    const {
                      winner,
                      setA,
                      setB,
                      setC,
                      challenged,
                      challenger,
                      time,
                      challengedB,
                      challengerB,
                    } = item;

                    let realdate = convertDateTime(time).date;
                    let realTime = convertDateTime(time).time;

                    const challengerImage = challenger?.image
                      ? challenger?.image || challenger?.thumnail
                      : "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";
                    const challengedImage = challenged?.image
                      ? challenged?.image || challenged?.thumnail
                      : "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";
                    return (
                      <div className="flex  flex-col w-full border-b-[#E8EAED] border-b pb-3 mb-3">
                        <div className="flex justify-between items-center">
                          <p className="text-neutral-500 text-[10px] ">
                            {realdate}
                          </p>
                          <p className="text-neutral-500 text-[10px] ">
                            {realTime}{" "}
                          </p>
                        </div>
                        {/* head session */}
                        <div className="flex items-center justify-between mt-[7px]">
                          <div className="flex space-x-4 items-center">
                            <img
                              src={challengerImage}
                              alt="sample"
                              className="w-[24px] h-[24px] rounded-full"
                            />
                            <p className="flex text-[#2c2c2c] text-xs font-semibold capitalize items-center ">
                              1.{" "}
                              {challengerB?.firstname
                                ? `${challenger?.firstname}/${challengerB?.firstname}`
                                : challenger?.lastname
                                ? challenger?.firstname +
                                  " " +
                                  challenger?.lastname
                                : challenger?.firstname
                                ? challenger?.firstname
                                : "BYE"}
                              <span className="ml-1">
                                {winner === challenger?._id && <Award />}
                              </span>
                            </p>
                          </div>
                          <div className="flex space-x-4">
                            <p className="text-neutral-500 text-[12px]">
                              {!setA?.challenger && !setA?.challenged
                                ? "-"
                                : setA?.challenger
                                ? setA?.challenger
                                : 0}
                            </p>
                            <p className="text-neutral-500 text-[12px]">
                              {!setB?.challenger && !setB?.challenged
                                ? "-"
                                : setB?.challenger
                                ? setB?.challenger
                                : 0}
                            </p>
                            <p className="text-neutral-500 text-[12px]">
                              {/* {parseInt(setC?.challenged) !== 0 &&
                              !setC?.challenged &&
                              parseInt(setC?.challenger) !== 0 &&
                              !setC?.challenger
                                ? setC?.challenger
                                : ""} */}
                              {setC?.challenger ?? ""}
                            </p>
                          </div>
                        </div>
                        {/* tail session */}
                        <div className="flex items-center justify-between mt-2">
                          <div className="flex space-x-4 items-center">
                            <img
                              src={challengedImage}
                              alt="sample"
                              className="w-[24px] h-[24px] rounded-full"
                            />
                            <p className="flex text-[#2c2c2c] text-xs font-semibold capitalize items-center">
                              2.{" "}
                              {challengedB?.firstname
                                ? `${challenged?.firstname}/${challengedB?.firstname}`
                                : challenged?.lastname
                                ? challenged?.firstname +
                                  " " +
                                  challenged?.lastname
                                : challenged?.firstname
                                ? challenged?.firstname
                                : "BYE"}
                              <span className="ml-1">
                                {winner === challenged?._id && <Award />}
                              </span>
                            </p>
                          </div>
                          <div className="flex space-x-4">
                            <p className="text-neutral-500 text-[12px]">
                              {" "}
                              {!setA?.challenger && !setA?.challenged
                                ? "-"
                                : setA?.challenged
                                ? setA?.challenged
                                : 0}
                            </p>
                            <p className="text-neutral-500 text-[12px]">
                              {" "}
                              {!setB?.challenger && !setB?.challenged
                                ? "-"
                                : setB?.challenged
                                ? setB?.challenged
                                : 0}
                            </p>
                            <p className="text-neutral-500 text-[12px]">
                              {setC?.challenged ?? ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const OnGoingMatch = ({ ongoingMatch }: any) => {
  const [selected, setSelected] = useState<{ id: string; name: string }>({
    id: "",
    name: "",
  });
  const [optionList, setOptionList] = useState<any>([]);
  const [renderedList, setRenderedList] = useState<any>(ongoingMatch);
  useEffect(() => {
    if (ongoingMatch?.length) {
      const list = ongoingMatch?.map((item: any) => {
        const data = { id: item?.id, name: item?.group[0]?._tournament?.name };
        return data;
      });
      setOptionList(list);
    }
  }, [ongoingMatch]);

  useEffect(() => {
    if (selected?.name) {
      const filteredList = ongoingMatch?.filter(
        (item: any) => item?.id === selected?.id
      );
      setRenderedList(filteredList);
    }
  }, [selected]);

  return (
    <div className="flex flex-col space-y-3">
      {!renderedList.length ? (
        <h1 className="text-center text-neutral-700 text-base mt-[30px] ">
          Your club has no ongoing match yet
        </h1>
      ) : (
        <div className="w-full flex flex-col">
          <div className="w-full">
            <div className="w-full mb-4 flex flex-col">
              <p className="text-xs text-neutral-400">
                Search for a tournament
              </p>
              <ComboSelect
                options={optionList}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          </div>
          {renderedList.map((item: any) => {
            return (
              <div>
                <p className="text-customBlack text-sm font-semibold capitalize">
                  {item?.group[0]?._tournament?.name}
                </p>
                <div className=" border-border border rounded-[16px] p-4">
                  {item?.group?.map((item: any) => {
                    const {
                      setA,
                      setB,
                      setC,
                      challenged,
                      challenger,
                      time,
                      challengedB,
                      challengerB,
                    } = item;
                    let realdate = convertDateTime(time).date;
                    let realTime = convertDateTime(time).time;

                    let a1 = `${
                      setA?.challenger && parseInt(setA?.challenger) !== 0
                        ? setA?.challenger
                        : "-"
                    }`;

                    let a2 = `${
                      setB?.challenger && parseInt(setB?.challenger) !== 0
                        ? setB?.challenger
                        : "-"
                    }`;

                    let b1 = `${
                      setA?.challenged && parseInt(setA?.challenged) !== 0
                        ? setA?.challenged
                        : "-"
                    }`;
                    let b2 = `${
                      setB?.challenged && parseInt(setB?.challenged) !== 0
                        ? setB?.challenged
                        : "-"
                    }`;

                    const challengerImage = challenger?.image
                      ? challenger?.image || challenger?.thumnail
                      : "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";
                    const challengedImage = challenged?.image
                      ? challenged?.image || challenged?.thumnail
                      : "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";

                    return (
                      <div className="flex  flex-col w-full border-b-[#E8EAED] border-b pb-3 mb-3">
                        <div className="flex justify-between items-center">
                          <p className="text-neutral-500 text-[10px] ">
                            {realdate}
                          </p>
                          <p className="text-neutral-500 text-[10px] ">
                            {realTime}{" "}
                          </p>
                        </div>
                        {/* head session */}
                        <div className="flex items-center justify-between mt-[7px]">
                          <div className="flex space-x-4 items-center">
                            <img
                              src={challengerImage}
                              alt="sample"
                              className="w-[24px] h-[24px] rounded-full"
                            />
                            <p className="flex text-[#2c2c2c] text-xs font-semibold capitalize">
                              1.{" "}
                              {challengerB?.firstname
                                ? `${challenger?.firstname}/${challengerB?.firstname}`
                                : challenger?.lastname
                                ? challenger?.firstname +
                                  " " +
                                  challenger?.lastname
                                : challenger?.firstname
                                ? challenger?.firstname
                                : "BYE"}
                            </p>
                          </div>
                          <div className="flex space-x-4">
                            <p className="text-neutral-500 text-[12px]">{a1}</p>
                            <p className="text-neutral-500 text-[12px]">{a2}</p>
                            <p className="text-neutral-500 text-[12px]">
                              {parseInt(setC?.challenged) !== 0 &&
                              !setC?.challenged &&
                              parseInt(setC?.challenger) !== 0 &&
                              !setC?.challenger
                                ? setC?.challenger
                                : ""}
                            </p>
                          </div>
                        </div>
                        {/* tail session */}
                        <div className="flex items-center justify-between mt-2">
                          <div className="flex space-x-4 items-center">
                            <img
                              src={challengedImage}
                              alt="sample"
                              className="w-[24px] h-[24px] rounded-full"
                            />
                            <p className="flex text-[#2c2c2c] text-xs font-semibold capitalize">
                              2.{" "}
                              {challengedB?.firstname
                                ? `${challenged?.firstname}/${challengedB?.firstname}`
                                : challenged?.lastname
                                ? challenged?.firstname +
                                  " " +
                                  challenged?.lastname
                                : challenged?.firstname
                                ? challenged?.firstname
                                : "BYE"}
                            </p>
                          </div>
                          <div className="flex space-x-4">
                            <p className="text-neutral-500 text-[12px]">{b1}</p>
                            <p className="text-neutral-500 text-[12px]">{b2}</p>
                            <p className="text-neutral-500 text-[12px]">
                              {parseInt(setC?.challenged) !== 0 &&
                              !setC?.challenged &&
                              parseInt(setC?.challenger) !== 0 &&
                              !setC?.challenger
                                ? setC?.challenged
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
