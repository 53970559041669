import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import AdminAuthLayout from "../../../Layouts/AdminAuthLayout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import logo from "../../../assets/svgs/love-logo.svg";
import { Btn } from "../../../components/Button";
import { AdminInput, AdminPasswordInput } from "../../../components/Input";
import { AuthSubTitle, AuthTitle } from "../../../components/Text";
import {
  loginSuperAdmin,
  restoreDefault,
} from "../../../features/auth/SuperAdminSlice";
import { setCurrentUser } from "../../../features/auth/UtilitySlice";
const SuperAdminLogin = () => {
  const navigate = useNavigate();
  const superAdminAuth = useAppSelector((state) => state.superAdminAuth);
  useEffect(() => {
    if (superAdminAuth?.token) {
      window.location.replace("/super/home");
    }
  }, []);
  const dispatch = useAppDispatch();

  const { search } = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [active, setActive] = useState(false);
  useEffect(() => {
    dispatch(restoreDefault());
  }, []);
  const searchQuery = new URLSearchParams(search);
  const redirectParam = searchQuery.get("redirect");

  useEffect(() => {
    if (email && password) setActive(true);
    else setActive(false);
  }, [email, password]);

  const handleSubmit = () => {
    const data = {
      email: email,
      password: password,
    };
    dispatch(loginSuperAdmin(data));
  };

  useEffect(() => {
    if (superAdminAuth.superAdminData?.success) {
      dispatch(setCurrentUser(superAdminAuth.superAdminData));

      if (redirectParam) return navigate(redirectParam);
      navigate("/super/home");
    }
  }, [superAdminAuth.loginSuccess]);

  return (
    <div className="w-full ">
      <AdminAuthLayout>
        <div className="w-full flex h-full justify-center ">
          {/* left side  */}
          <div className="w-1/2 bg-white flex flex-col">
            <div className="mx-auto mt-8">
              <img src={logo} alt="logo" className="h-auto" />
            </div>
            <div className="flex flex-col justify-center items-center mt-8">
              <AuthTitle text="Welcome Back" className="text-center" />
              <AuthSubTitle
                text="Kindly enter your details to Login."
                className="mt-[10px]"
              />
              <div className="flex flex-col lg:w-8/12 mx-auto xl:w-7/12 2xl:w-7/12 mt-10">
                <AdminInput
                  placeholder="Enter your Email"
                  onChange={(e: any) => setEmail(e.target.value)}
                  label="Email Address"
                  required
                />
                <div className="flex flex-col mt-4">
                  <label className="text-neutral-400 text-sm mb-1 ">
                    Password
                  </label>
                  <AdminPasswordInput
                    placeholder="Enter your password"
                    classes=""
                    required
                    onChange={(e: any) => setPassword(e.target.value)}
                  />
                </div>
                {/* start of remeber me */}
                <div className="flex justify-between mt-4 items-center">
                  <div className=" flex space-x-3 items-center">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="accent-yellow text-yellow bg-primaryYellow"
                    />
                    <p className="text-neutral-500 text-sm font-normal">
                      Remember me
                    </p>
                  </div>
                  <Link
                    to="/admin/forgot-password/s"
                    className="text-primaryYellow text-sm "
                  >
                    Forgot password
                  </Link>
                </div>
                {/* end of remeber me */}
                {/* button session */}
                <Btn
                  name="Log In"
                  className="w-full mt-12"
                  onClick={handleSubmit}
                  active={active}
                  loading={superAdminAuth.loading}
                />
                {/* end of button session */}
              </div>
            </div>
          </div>
          {/* end ofleft side  */}
          {/* right side  */}
          {/* <div className={`w-1/2 ${stages[current]} relative`}>
            <div className="absolute bottom-28 w-full flex justify-center left-0 right-0 px-16">
              <GlassProgress onClick={handleNext} current={current} />
            </div>
          </div> */}
          {/* end of the right hand side */}
        </div>
      </AdminAuthLayout>
    </div>
  );
};

export default SuperAdminLogin;
