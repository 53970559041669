import { useEffect } from "react";
import DashboardLayout from "../../../Layouts/DashboardLayout";
import { useAppDispatch } from "../../../app/hooks";
import CompetitionMatchesTables from "../../../components/Tables/AdminTables/Matches/CompetitionMatches";
import KnockoutMatchesTables from "../../../components/Tables/AdminTables/Matches/KnockoutMatchesTables";
import LadderMatchesTables from "../../../components/Tables/AdminTables/Matches/LadderMatchTables";
import { getAllMatches } from "../../../features/auth/TournamentSlice";

const MatchesPage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllMatches());
  }, []);

  return (
    <DashboardLayout current={2}>
      <div className="w-full bg-[#f4f4f4] flex flex-col p-[30px] 2xl:p-8">
        <div className="flex w-full justify-between items-center">
          <h1 className="text-lg font-[600] text-customBlack">Matches</h1>
          {/* <div
            className="flex items-center space-x-2 cursor-pointer bg-white p-[10px] rounded-[5px]"
            onClick={() => navigate("/admin/create-competition")}
          >
            <AddCircle />

            <p className="text-sm text-customBlack font-[500]">
              Create Competition
            </p>
          </div> */}
        </div>
        <CompetitionMatchesTables className="mt-6" />
        <KnockoutMatchesTables className="mt-6" />
        <LadderMatchesTables className="mt-10" />
      </div>
    </DashboardLayout>
  );
};

export default MatchesPage;
