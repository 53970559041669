import React from "react";
import { convertDateTime } from "../../utils";
import { Award } from "../../assets/svgs/buddies";
import { useNavigate } from "react-router-dom";
interface scoreProps {
  item: any;
  index: number;
}
const MatchScoreCardCompetition = ({ item, index }: scoreProps) => {
  const navigate = useNavigate();
  const findTeamName = (playerId: string) => {
    const player = item?._tournament?.players.find(
      (player: any) => player._id === playerId
    );
    const teamId = player?.team;
    const team = item?._tournament?.teams.find(
      (team: any) => team._id === teamId
    );
    return team?.name || "Unknown Team";
  };
  return (
    <div className="flex flex-col" key={index}>
      <p className="text-customBlack text-sm font-semibold capitalize mb-2">
        {item?._tournament?.name ?? "No Name"}
      </p>
      <div className=" border-border border rounded-[20px] px-4 py-3">
        {item?.matches?.map((match: any) => {
          const {
            setA,
            setB,
            setC,
            challenged,
            challenger,
            time,
            challengedB,
            challengerB,
            winner,
          } = match;
          let realdate = convertDateTime(time).date;
          let realTime = convertDateTime(time).time;
          const challengerTeam = findTeamName(challenger?._id);
          const challengedTeam = findTeamName(challenged?._id);
          let a1 = `${
            setA?.challenger && parseInt(setA?.challenger) !== 0
              ? setA?.challenger
              : "-"
          }`;

          let a2 = `${
            setB?.challenger && parseInt(setB?.challenger) !== 0
              ? setB?.challenger
              : "-"
          }`;

          let b1 = `${
            setA?.challenged && parseInt(setA?.challenged) !== 0
              ? setA?.challenged
              : "-"
          }`;
          let b2 = `${
            setB?.challenged && parseInt(setB?.challenged) !== 0
              ? setB?.challenged
              : "-"
          }`;
          let c1 = `${
            setC?.challenged && parseInt(setC?.challenged) !== 0
              ? setC?.challenged
              : "-"
          }`;
          let c2 = `${
            setC?.challenged && parseInt(setC?.challenged) !== 0
              ? setC?.challenged
              : "-"
          }`;

          const challengerImage = challenger?.image
            ? challenger?.image || challenger?.thumnail
            : "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";
          const challengedImage = challenged?.image
            ? challenged?.image || challenged?.thumnail
            : "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";

          return (
            <div
              className="flex  flex-col w-full border-b-[#E8EAED] border-b py-4  last:border-none"
              onClick={() =>
                navigate(
                  `/view-competition-match/${match?._tournament?._id}?matchId=${match?._id}`
                )
              }
            >
              <div className="flex justify-between items-center">
                <p className="text-[#AFAFAF] text-[10px] ">{realdate}</p>
                <p className="text-[#AFAFAF] text-[10px] ">{realTime} </p>
              </div>
              {/* head session */}
              <div className="flex items-center justify-between mt-2">
                <div className="flex gap-2 items-center">
                  <img
                    src={challengerImage}
                    alt="sample"
                    className="w-[35px] h-[35px] object-cover rounded-full"
                  />
                  <div className="flex flex-col">
                    <p className="flex text-customBlack text-sm font-medium  capitalize leading-[20px]">
                      1.{" "}
                      {challengerB?.firstname
                        ? `${challenger?.firstname}/${challengerB?.firstname}`
                        : challenger?.lastname
                        ? challenger?.firstname + " " + challenger?.lastname
                        : challenger?.firstname
                        ? challenger?.firstname
                        : "BYE"}
                      <span className="ml-1">
                        {winner === challenger?._id && <Award />}
                      </span>
                    </p>
                    <p className="text-[10px] text-[#AFAFAF] ">
                      {challengerTeam}
                    </p>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <p className=" bg-[#F7F7F7] w-[34px] h-[34px] flex items-center rounded-full justify-center text-customBlack text-[14px]">
                    {a1}
                  </p>
                  <p className=" bg-[#F7F7F7] w-[34px] h-[34px] flex items-center rounded-full justify-center text-customBlack text-[14px]">
                    {a2}
                  </p>
                  <p className=" bg-[#F7F7F7] w-[34px] h-[34px] flex items-center rounded-full justify-center text-customBlack text-[14px]">
                    {c1}
                  </p>
                </div>
              </div>
              {/* tail session */}
              <div className="flex items-center justify-between mt-2">
                <div className="flex gap-2 items-center">
                  <img
                    src={challengedImage}
                    alt="sample"
                    className="w-[35px] h-[35px] rounded-full"
                  />
                  <div className="flex flex-col">
                    <p className="flex text-customBlack text-sm font-medium capitalize leading-[20px]">
                      2.{" "}
                      {challengedB?.firstname
                        ? `${challenged?.firstname}/${challengedB?.firstname}`
                        : challenged?.lastname
                        ? challenged?.firstname + " " + challenged?.lastname
                        : challenged?.firstname
                        ? challenged?.firstname
                        : "BYE"}
                      <span className="ml-1">
                        {winner === challenged?._id && <Award />}
                      </span>
                    </p>
                    <p className="text-[10px] text-[#AFAFAF] leading-[16px] ">
                      {challengedTeam}
                    </p>
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <p className=" bg-[#F7F7F7] w-[34px] h-[34px] flex items-center rounded-full justify-center text-customBlack text-[14px]">
                    {b1}
                  </p>
                  <p className=" bg-[#F7F7F7] w-[34px] h-[34px] flex items-center rounded-full justify-center text-customBlack text-[14px]">
                    {b2}
                  </p>
                  <p className=" bg-[#F7F7F7] w-[34px] h-[34px] flex items-center rounded-full justify-center text-customBlack text-[14px]">
                    {/* {parseInt(setC?.challenged) !== 0 &&
                    !setC?.challenged &&
                    parseInt(setC?.challenger) !== 0 &&
                    !setC?.challenger
                      ? setC?.challenged
                      : "-"} */}
                    {c2}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MatchScoreCardCompetition;
