import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { DeleteIcon, EmptyIcon } from "../../../../assets/svgs/tournament";
import {
  editUserRank,
  getSingleLadderDetail,
  removePlayer,
  resetAllLadderState,
} from "../../../../features/auth/ladder-slice";
import LoadingComponent from "../../../Loading/Loader";
import { Btn } from "../../../Button";
import ReUseModal from "../../../Modal/ReUseModal";
import sentMark from "../../../../assets/svgs/checkmark.svg";
import { TrashIcon } from "@heroicons/react/solid";
import { SingleUserIcon } from "../../../../assets/svgs/Icons";
import Options from "../../../Selects/Options";
import { toast } from "react-toastify";
import { resetAll } from "../../../../features/auth/AdminAuth";
import { greyPlayer } from "../../../../features/auth/TournamentSlice";

const RankPositionTable = ({ className }: any) => {
  const dispatch = useAppDispatch();
  const ladder = useAppSelector((state) => state?.ladder);
  const tournament = useAppSelector((state) => state?.tournament);
  const [details, setDetails] = useState<any>([]);
  const [ladderName, setLadderName] = useState("");
  const id = useParams();
  useEffect(() => {
    dispatch(getSingleLadderDetail({ id: id.id }));
    if (tournament?.lockPlayerSuccess) {
      toast.success("Player's Availability  Updated ");
      dispatch(resetAll());
    }
  }, [tournament?.lockPlayerSuccess]);
  useEffect(() => {
    if (ladder?.singleLadder?.leaderboard?.length > 0) {
      setDetails(ladder.singleLadder?.leaderboard);
      setLadderName(ladder?.singleLadder?.name);
    }
  }, [ladder?.singleLadder]);
  useEffect(() => {
    if (ladder?.playerRemoveSuccess) {
      dispatch(getSingleLadderDetail({ id: id.id }));
    }
  }, [ladder?.playerRemoveSuccess]);
  useEffect(() => {
    if (ladder?.editRankSuccess) {
      dispatch(getSingleLadderDetail({ id: id.id }));
    }
  }, [ladder?.editRankSuccess]);

  const moveItemToPosition = (itemId: string, newPosition: number): any => {
    const newArray = [...details]; // Create a copy of the original array

    // Find the index of the item with the given itemId
    const index = newArray.findIndex((item) => item._id._id === itemId);

    if (index === -1) {
      // If the item is not found, return the original array
      return newArray;
    }

    // Remove the item from its current position
    const item = newArray.splice(index, 1)[0];

    newPosition = Math.min(Math.max(newPosition, 0), newArray.length);
    newArray.splice(newPosition, 0, item);

    return newArray;
  };

  // Function to handle moving the item to a new position
  const handleMoveItem = (itemId: string, newPosition: number) => {
    const newArray = moveItemToPosition(itemId, newPosition - 1);
    const sentData = {
      id: id.id,
      data: {
        leaderboard: newArray,
      },
    };
    dispatch(editUserRank(sentData));
  };

  if (ladder?.fetchLoading) {
    return (
      <div className="mt-8">
        <LoadingComponent />
      </div>
    );
  }

  return (
    <div
      className={`w-full  h-full min-h-[533px] flex flex-col bg-white  rounded-[20px] ${className} `}
    >
      <div className="flex justify-between item-center pt-[22px] px-4 pb-5  border-b-[1px] border-b-[#f4f4f4]  ">
        <h1 className="text-sm font-semibold text-customBlack capitalize">
          Rank - {ladderName}
        </h1>
      </div>
      <table className="min-w-full w-full h-full bg-[#fff] rounded-b-[20px]   ">
        <thead className=" bg-[#f4f4f4] bg-opacity-40 mb-2   ">
          <tr className="flex items-center h-[35px] pl-5">
            <th className="w-auto flex-grow theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-start flex items-center gap-6 ">
              <span>SN</span>
              <span>Names</span>
            </th>
            {/* <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA ">
              Rank
            </th> */}

            <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA ">
              Matches
            </th>
            <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA ">
              Wins
            </th>
            <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA ">
              Losses
            </th>
            <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA ">
              Wins(%)
            </th>
            <th className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA ">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="w-full  mb-4 mt-2">
          {details?.length > 0 &&
            details.map((item: any, index: number) => {
              return (
                <SingleRow
                  item={item}
                  index={index}
                  key={index}
                  total={details?.length}
                  handleEditRank={handleMoveItem}
                />
              );
            })}
          {details?.length === 0 && (
            <tr>
              <td className="w-full flex items-center justify-center h-full mt-10">
                <div className="justify-center flex flex-col items-center">
                  <span>
                    <EmptyIcon />
                  </span>
                  <span className="font-inter text-sm font-medium leading-15 tracking-wider w-106 h-15 text-center mt-5">
                    No Players yet
                  </span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="w-full h-[36px] items-center flex justify-between pl-4 pr-8  mt-3 mb-4"></div>
    </div>
  );
};

export default RankPositionTable;

const SingleRow = ({ item, index, total, handleEditRank }: any) => {
  const dispatch = useAppDispatch();
  const ladder = useAppSelector((state) => state?.ladder);
  const tournament = useAppSelector((state) => state?.tournament);
  const { _id } = item?._id;
  const [rank, setRank] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [openRank, setOpenRank] = useState(false);
  const [openRankSuccess, setOpenRankSuccess] = useState(false);
  const [openGrey, setOpenGrey] = useState(false);
  const [openDeleteSuccess, setOpenDeleteSuccess] = useState(false);
  const id = useParams();
  useEffect(() => {
    if (ladder?.playerRemoveSuccess) {
      setOpenDelete(false);
      setOpenDeleteSuccess(true);
      setTimeout(() => {
        dispatch(resetAllLadderState());
        setOpenDeleteSuccess(false);
      }, 2000);
    }
  }, [ladder?.playerRemoveSuccess]);
  const remove = () => {
    const datasent = {
      id: id.id,
      data: {
        id: item?._id,
        alert: true,
      },
    };
    dispatch(removePlayer(datasent));
  };
  function calculatePercentage(value: number, total: number): number {
    if (total === 0) return 0;

    return Math.floor((value / total) * 100);
  }
  const winPercentage = calculatePercentage(
    Number(item.win),
    Number(item.matches)
  );
  const isLastfour = total > 4 ? index >= total - 4 : false;

  useEffect(() => {
    if (ladder?.editRankSuccess) {
      setOpenRank(false);
      setOpenRankSuccess(true);
      dispatch(resetAllLadderState());
      setTimeout(() => {
        setOpenRankSuccess(false);
      }, 2000);
    }
  }, [ladder?.editRankSuccess]);
  const handleGreyPlayer = () => {
    const data = {
      id: id.id,
      user: _id,
    };
    dispatch(greyPlayer(data));
  };

  return (
    <tr
      className={`flex items-center h-[35px] pl-5 ${
        item?.locked ? "bg-[#CFCFCF]" : ""
      }`}
    >
      <td className="w-auto flex-grow theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-start flex items-center gap-6 capitalize ">
        <span>{index + 1}</span>
        <div className="flex items-center gap-2">
          <img
            src={item?._id?.image}
            alt="samp"
            className="w-6 h-auto rounded-full"
          />
          <span>
            {item?._id?.firstname +
              " " +
              `${item?._id?.lastname !== null ? item?._id?.lastname : ""}`}
          </span>
        </div>
      </td>
      {/* <td className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA text-center ">
        {index + 1}
      </td> */}

      <td className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA text-center ">
        {item?.matches}
      </td>
      <td className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA text-center ">
        {item?.win}
      </td>
      <td className="w-1/12 theader text-neutral-700 text-sm font-[500] tracking-[0.12px] text-playerA text-center ">
        {item?.loss}
      </td>
      <td className="w-1/12 theader text-neutral-700 text-center text-sm font-[500] tracking-[0.12px] text-playerA ">
        {winPercentage}(%)
      </td>
      <td className="w-1/12 theader text-neutral-700 text-center text-sm font-[500] tracking-[0.12px] text-playerA ">
        <span>
          <Options isLast={isLastfour}>
            <div className="w-full flex flex-col ">
              <div
                className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                onClick={() => setOpenRank(true)}
              >
                <span>
                  <SingleUserIcon />
                </span>
                <p className="text-customBlack text-sm">Move player to</p>
              </div>
              <div
                className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                onClick={() => setOpenDelete(true)}
              >
                <span>
                  <TrashIcon />
                </span>
                <p className="text-[#B00020] text-sm">Remove Player</p>
              </div>
              <div
                className="px-5 bg-white hover:bg-[#EBF6D4] h-[41px] flex items-center gap-2  cursor-pointer"
                onClick={() => setOpenGrey(true)}
              >
                <span>
                  <SingleUserIcon />
                </span>
                <p className="text-customBlack text-sm">
                  {" "}
                  {item?.locked ? "Mark as Available" : "Mark as Unavailable"}
                </p>
              </div>
            </div>
          </Options>
        </span>
      </td>
      <ReUseModal open={openDelete} setOpen={setOpenDelete}>
        <div className="w-full flex flex-col">
          <div className="flex items-center justify-center mt-10">
            <DeleteIcon />
          </div>
          <h1 className="text-center mt-2 font-bold text-[22px] leading-[24px] text-customBlack ">
            Remove Player
          </h1>
          <p className="text-[#868686] mt-1 text-sm text-center max-w-[340px] mx-auto">
            Are you sure you want to remove this player from the ladder?
          </p>
          <div className="w-full flex items-center justify-center  gap-6 mt-16">
            <button
              className="w-full max-w-[174px] rounded-[16px] border border-[#9cd326] h-[52px] flex items-center cursor-pointer justify-center text-sm font-semibold text-[#9CD326] "
              onClick={() => setOpenDelete(false)}
              disabled={ladder?.loading}
            >
              No Cancel
            </button>
            <button
              className="w-full max-w-[174px] rounded-[16px] bg-[#B00020] h-[52px] flex items-center cursor-pointer justify-center text-sm font-semibold text-white border-none outline-none"
              onClick={remove}
              disabled={ladder?.loading}
            >
              {ladder?.loading ? "Loading..." : "Yes Remove"}
            </button>
          </div>
        </div>
      </ReUseModal>
      <ReUseModal open={openDeleteSuccess} setOpen={setOpenDeleteSuccess}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-customBlack space-y-1 text-[24px] font-bold text-center">
          Player Removed
        </h1>
        <p className="text-center w-10/12 max-w-[331px] mx-auto text-neutral-500 text-sm">
          You’ve successfully removed the player from this tournament
        </p>
        <Btn
          active={true}
          name="Close"
          onClick={() => setOpenDeleteSuccess(false)}
          className="mt-[49px] mb-3"
        />
      </ReUseModal>
      <ReUseModal open={openRankSuccess} setOpen={setOpenRankSuccess}>
        <img src={sentMark} alt="sent" className="mx-auto mt-6" />
        <h1 className="text-customBlack space-y-1 text-[24px] font-bold text-center">
          Points Assigned
        </h1>
        <p className="text-center w-10/12 max-w-[331px] mx-auto text-neutral-500 text-sm">
          You’ve successfully assigned points to the player
        </p>
        <Btn
          active={true}
          name="Close"
          onClick={() => setOpenRankSuccess(false)}
          className="mt-[49px] mb-3"
        />
      </ReUseModal>
      <ReUseModal open={openRank} setOpen={setOpenRank}>
        <div className="w-full flex flex-col">
          <h1 className="text-customBlack space-y-1 text-base font-bold ">
            Assign New Rank
          </h1>
          <hr className="bg-[#cfcfcf] w-full mt-3" />
          <div className="w-full flex mt-5 items-center justify-between mb-12">
            <p className="text-sm font-medium text-customBlack">
              New Rank Position
            </p>

            <span>
              <input
                type="text"
                placeholder="-"
                value={rank}
                onChange={(e: any) => setRank(e.target.value)}
                className="w-[38px] rounded-[10px] h-[38px] border-[0.5px] border-[#e5e5e5] outline-none focus:outline-none  placeholder:text-[#e5e5e5] flex items-center justify-center placeholder:text-[30px] placeholder:leading-[0] placeholder:text-center placeholder:font-bold text-customBlack text-sm text-center "
              />
            </span>
          </div>
          <div className="w-10/12 mx-auto">
            <Btn
              active={rank ? true : false}
              name="Update"
              onClick={() => handleEditRank(item?._id?._id, Number(rank))}
              loading={ladder?.loading}
              className="mt-[49px] mb-3 "
            />
          </div>
        </div>
      </ReUseModal>
      <ReUseModal open={openGrey} setOpen={setOpenGrey}>
        <div className="w-full flex flex-col">
          <h1 className="text-customBlack space-y-1 text-base font-bold ">
            Change Player Availability
          </h1>
          <hr className="bg-[#cfcfcf] w-full mt-3" />
          <div className="w-full flex mt-5 items-center justify-between mb-12">
            <p className="text-sm font-medium text-customBlack">
              You are about to make this player{" "}
              {item?.locked ? "Available" : "Unavailable "}
            </p>
          </div>
          <div className="w-10/12 mx-auto">
            <Btn
              active={true}
              name="Proceed"
              onClick={handleGreyPlayer}
              loading={tournament?.loading}
              className="mt-[49px] mb-3 "
            />
          </div>
        </div>
      </ReUseModal>
    </tr>
  );
};
