import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { ClubBall } from "../../assets/svgs/club";
import { More } from "../../assets/svgs/court";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAllLadderUser } from "../../features/auth/ladder-slice";
import { formatDate } from "../../utils";

const ActiveLadders = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ladder = useAppSelector((state) => state.ladder);
  const auth = useAppSelector((state) => state.auth);

  const [allLadders, setAllLadders] = useState(ladder?.allLaddersUser);
  const [activeLadders, setActiveLadders] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getAllLadderUser());
  }, []);

  useEffect(() => {
    const userId = auth?.userData?.user?._id;
    const active: any[] = [];
    const available: any[] = [];

    allLadders?.forEach((ladder: any) => {
      if (ladder?.players?.includes(userId)) {
        active.push(ladder);
      } else {
        available.push(ladder);
      }
    });

    setActiveLadders(active);
  }, [allLadders, ladder?.allLadders]);

  return (
    <>
      <div className="w-full flex flex-col px-4 py-6 md:max-w-[428px] md:mx-auto ">
        <div className="flex justify-between mt-6 items-center">
          <IoIosArrowBack className="text-2xl" onClick={() => navigate(-1)} />
          <p className="text-[#303030] font-bold text-[15px] ">
            Active Ladders
          </p>
          <div className="more-shadow opacity-0">
            <More />
          </div>
        </div>
        {/* END OF THE ARROW SIDE */}
        <div className="flex flex-col mt-8">
          {activeLadders?.length === 0 && (
            <p className="text-nuetral-700 text-sm font-medium">
              No Active Ladders yet
            </p>
          )}
          {activeLadders?.length > 0 &&
            activeLadders?.map((item: any, index: number) => {
              return (
                <div
                  className="w-full h-20 border-border border rounded-md flex items-center justify-between px-4 mb-4 "
                  key={index}
                  onClick={() => navigate(`/selected-ladder/${item._id}`)}
                >
                  <div className="flex items-center space-x-3">
                    <ClubBall />
                    <div className="flex flex-col">
                      <p className="text-[14px] text-black p-0 m-0 font-normal capitalize">
                        {item?.name}
                      </p>
                      <p className="text-[12px] text-[#acacac]">
                        Starts {formatDate(item?.start)}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <IoIosArrowForward className="text-2xl text-lemonGreen" />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default ActiveLadders;
